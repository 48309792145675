// Database implementation
import { openDB } from 'idb/with-async-ittr'
import { createId } from '@paralleldrive/cuid2'
import { translit } from './transliteration'
import validators from '../schemas/validatorAddress'

const tables = [
  'responses',
  'deleted',
  'address',
  'activity',
  'metadata',
  'photo',
  'assessment',
  'stateQuestions',
  'polygons',
  'user',
  'organization'
]

const indices = {
  address: [
    'pCode1',
    'pCode2',
    'pCode3',
    'pCode4',
    'synced',
    ['pCode4', 'name'],
    ['pCode4', 'nameUkr'],
    ['pCode4', 'name'],
    ['pCode4', 'buildingType'],
    ['pCode4', 'assessmentNumber'],
    ['pCode4', 'activityNumber']
  ],
  activity: ['addressId', 'synced'],
  metadata: ['addressId', 'synced'],
  photo: ['addressId', 'synced'],
  assessment: ['addressId', 'synced'],
  stateQuestions: ['addressId', 'synced'],
  user: [],
  organization: ['organizarionIdentifier', 'name', 'fullName', 'type', 'synced']
}

/* Helpers */

const streetTypeDict = {
  street: 'вулиця',
  alley: 'алея',
  avenue: 'проспект',
  boulevard: 'бульвар',
  square: 'площа',
  lane: 'провулок',
  harbor: 'набережна',
  highway: 'шосе',
  bypass: 'об\'їзд',
  path: 'шлях',
  breakthrough: 'просіка',
  descent: 'узвіз',
  impasse: 'тупик',
  road: 'дорога',
  station: 'станція',
  thoroughfare: 'проїзд',
  passage: 'проїзд',
  maidan: 'майдан'
}

function capitalizeFirst (s) {
  return s.replace(/^[A-zА-яЄІЇҐЁєіїґё]/, letter => letter.toUpperCase())
}

const enrich = (table, entity) => {
  if (table === 'address') {
    if (entity.streetUkr) entity.streetUkr = capitalizeFirst(entity.streetUkr.trim())
    if (entity.streetUkr) entity.street = translit(entity.streetUkr)
    if ([entity.street, entity.house].filter(Boolean).length === 2) {
      entity.name = entity.street + ' ' + entity.streetType + ', ' + translit(entity.house)
      if (entity.corpus) entity.name = `${entity.name} (block ${entity.corpus})`
    }
    if ([entity.streetUkr, entity.house].filter(Boolean).length === 2) {
      entity.streetTypeUkr = streetTypeDict[entity.streetType] ? streetTypeDict[entity.streetType] : 'вулиця'
      entity.nameUkr = entity.streetUkr + ' ' + entity.streetTypeUkr + ', ' + entity.house
      if (entity.corpus) entity.nameUkr = `${entity.nameUkr} (корп. ${entity.corpus})`
    }
  }
  return entity
}

// List if indexNames to lookup while querying the data
const indexNames = Object.fromEntries(
  Object.entries(indices).map(([key, values]) => {
    let indexName = values.map(value => Array.isArray(value) ? value.join('-') : value)
    return [key, indexName]
  })
)

const version = 2

const dbConnection = openDB('data', version, {
  upgrade (db) {
    for (let table of tables) {
      if (!db.objectStoreNames.contains(table)) {
        // Create store
        const store = db.createObjectStore(table, { keyPath: '_id' })
        // If the indices are not define, then continue
        if (!indices[table]) continue
        for (let index of indices[table]) {
          // If there is a compound index, we'll create a proper index name
          let indexName = Array.isArray(index) ? index.join('-') : index
          if (!store.indexNames.contains(indexName)) store.createIndex(indexName, index, { unique: false })
        }
      }
    }
  }
})

/* Database helpers */

async function get (table, _id) {
  let db = await dbConnection
  return await db.get(table, _id)
}

async function clear(table) {
  let db = await dbConnection
  if (db.objectStoreNames.contains(table)){
    await db.clear(table)
  }
}

async function addMany (table, arr, synced = true) {
  try {
    let db = await dbConnection
    const tx = db.transaction(table, 'readwrite')
    const store = tx.store
    // It is expected that we work with existing data
    let entities = arr.map(el => { return store.put(Object.assign({}, el, { synced: synced ? 1 : 0 })) })
    entities.push(tx.done)
    await Promise.all([entities])
    return true
  } catch (error) {
    console.log('arr', arr, 'error:', error);
    
    return false
  }
}

async function put (table, obj, synced = true) {
  let newObj = {}
  if (!Array.isArray(obj)) {
    // If the object is new, then create UID
    if (!obj._id) obj._id = createId()
    newObj = Object.assign({}, obj, { synced: synced ? 1 : 0 })
    // Get the respective store
  } else {
    newObj = obj
  }
  newObj = enrich(table, newObj)
  let db = await dbConnection
  let result = await db.put(table, newObj).catch(err => { return { err } })
  if (result.err) return console.log(result.err)
  return newObj
}

async function remove (table, _id) {
  try {    
    let db = await dbConnection
    let entity = await db.get(table, _id)
    console.log('entity', entity);
    
    if (entity?.synced) await db.put('deleted', { _id, table })
    await db.delete(table, _id)
  } catch (error) {
    console.log('error', error);
    console.log('table:', table);
  }
}

async function getCurentUser () {
  let db = await dbConnection
  let user = await db.get('responses', '/me')

  if (!user) return {}
  user._id = user.userId
  return user
}

const ignoreForever = ['/ping', '/auth', '/export', '/refresh', '/auth/logout']
const ignoreForeverPattern = new RegExp(ignoreForever.join('|'), 'gi')
const ignoreResponse = ['/aggs', '/stats']
const ignoreResponsePattern = new RegExp(ignoreResponse.join('|'), 'gi')
const ignoreGet = ['/photo']
const ignoreGetPattern = new RegExp('^(' + ignoreGet.join('|') + ')', 'gi')
const ignorePost = ['/user-schema', '/user']
const ignorePostPattern = new RegExp('^(' + ignorePost.join('|') + ')', 'gi')

const savePolygons = async (response) => {
  let data = response.data
  data._id = response.config.url
  await put('polygons', data)
}

const saveByPath = async (response) => {
  let data = JSON.parse(JSON.stringify(response.data))
  // Special case - '/me' path
  if (response.config.url === '/me') data.userId = data._id
  data._id = response.config.url
  await put('responses', data)
}

const saveByTable = async (table, data) => {
  data._id = table
  await put('responses', data)
}

const findIndex = ({ table, cursorSource, sort, sortOrder, filter }) => {
  // If the table does not have indices, then skip
  if (!indexNames[table]) return cursorSource
  // Handle the situation with filters only
  if (!sort && filter) {
    if (indexNames[table].includes(filter.key)) {
      return cursorSource.index(filter.key).openCursor()
    }
  }

  // Handle the situation with sorting only
  if (sort && !filter) {
    if (indexNames[table].includes(sort)) {
      // return cursorSource.index(sort).openCursor(undefined, sortOrder === 'DESC' ? 'prev' : 'next')
      return cursorSource.index(sort).openCursor(undefined, sortOrder)
    }
  }

  // Handle the situation with sorting only
  if (sort && filter) {
    if (indexNames[table].includes(filter.key + '-' + sort)) {
      return cursorSource.index(filter.key + '-' + sort).
        openCursor(undefined, sortOrder === 'DESC' ? 'prev' : 'next')
    }
  }
  return cursorSource.openCursor()
}

const processSearch = async ({ table, offset = 1, limit = 25, searchField, searchValue, filters, sort, sortType, map }) => {
  let db = await dbConnection
  let filter = filters ? filters[0] : undefined
  if (!filter && typeof filters === 'object') {
    filter = { key: Object.keys(filters)[0], value: Object.values(filters)[0] }
  }

  // Initial object with response
  let data = { totalRows: 0, values: [] }
  // Hacks to comply with IndexedDB
  if (map) limit = Number.MAX_SAFE_INTEGER
  let sortOrder = sortType === '&desc' ? 'prev' : 'next'
  
  // Init the proper store
  let cursorSource = db.transaction(table).store
  let cursor = await findIndex({ table, cursorSource, sort, sortOrder, filter })

  let values = []
  let pattern = searchValue ? new RegExp(searchValue.replace(/[[\]()+*?.<>]/g, ''), 'gi') : null
  while (cursor) {
    let { value } = cursor
    cursor = await cursor.continue()
    // Skip if the value does not contain important fields
    if (!value) continue
    if (pattern && !value[searchField]) continue
    if (filter && !value[filter.key]) continue
    if (filter && value[filter.key] !== filter.value) continue
    if (pattern && !value[searchField].match(pattern)) continue
    values.push(value)
  }
  let totalRows = values.length
  // Skip and limit rows
  if (!map) {
    values = values.filter((el, index) => {
      if (index < (offset - 1) * limit) return false
      if (index >= (offset * limit) - 1) return false
      return true
    })
  }
  data.values = values
  data.totalRows = totalRows
  return data
}

const getTags = async (searchValue) => {
  let { values } = await processSearch({ table: 'address', searchField: 'streetUkr', searchValue, map: true })
  values = values.map(el => el['streetUkr'])
  return values
}

const statsDict = {
  addresses: 'address',
  assessments: 'assessment',
  activities: 'activity',
  metadatas: 'metadata'
}

const detectFilter = body => {
  let key, value
  if (body.pCode1) { key = 'pCode1'; value = body.pCode1 }
  if (body.pCode2) { key = 'pCode2'; value = body.pCode2 }
  if (body.pCode3) { key = 'pCode3'; value = body.pCode3 }
  return { key, value }
}

const getStats = async (table, body) => {
  let db = await dbConnection
  if (!body.country) body = body.address
  let filter = detectFilter(body)
  table = statsDict[table]
  let cursor = await db.transaction(table).store.openCursor()
  let result = { total: 0, value: 0 }
  while (cursor) {
    let { value } = cursor
    cursor = await cursor.continue()
    if (filter.key && value[filter.key] !== filter.value) continue
    result.value++
    result.total++
  }
  return result
}

const getAggs = async (table, body) => {
  let db = await dbConnection
  if (!body.country) body = body.address
  let filter = detectFilter(body)
  let cursor = await db.transaction(table).store.openCursor()
  let level = body.pCode3 ? 'pCode4' : body.pCode2 ? 'pCode3' : body.pCode1 ? 'pCode2' : 'pCode1'
  let totals = {}
  let assessments = {}
  let activities = {}
  while (cursor) {
    let { value } = cursor
    cursor = await cursor.continue()
    if (filter.key && value[filter.key] !== filter.value) continue
    let assessmentNumber = value.assessmentNumber ?? 0
    let activityNumber = value.activityNumber ?? 0
    if (!totals[value[level]]) totals[value[level]] = {
      [level]: value[level],
      Measure: 'Total',
      'Multi-storey': 0,
      'Private estates': 0
    }
    totals[value[level]][value.buildingType] = totals[value[level]][value.buildingType] + 1

    if (!assessments[value[level]]) assessments[value[level]] = {
      [level]: value[level],
      Measure: 'Assessments number',
      'Multi-storey': 0,
      'Private estates': 0
    }
    assessments[value[level]][value.buildingType] = assessments[value[level]][value.buildingType] + assessmentNumber

    if (!activities[value[level]]) activities[value[level]] = {
      [level]: value[level],
      Measure: 'Activities number',
      'Multi-storey': 0,
      'Private estates': 0
    }
    activities[value[level]][value.buildingType] = activities[value[level]][value.buildingType] + activityNumber
  }
  let result = [...Object.values(totals),...Object.values(assessments),...Object.values(activities)]
  result.sort((a, b) => b[level].localeCompare(a[level]))
  return result
}

const fileToDataURI = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => { resolve(reader.result) }
    reader.onerror = (error) => reject(error)
  })
}

const dataURIToFile = (dataURI, name) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1])
  // Separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
  let blob = new Blob([ab], { type: mimeString, name })
  blob.name = name
  return blob
}

const syncData = async (axios, table) => {
  // Get unsynced data
  console.log('syncData')
  let db = await dbConnection
  let index  = db.transaction(table).store.index('synced')

  // Get the data to sync
  let data = []

  // Retrieve all unsynced data and close the connection
  for await (const cursor of index.iterate(0)) data.push(cursor.value)

  // Send data to the server
  for (let d of data) {
    let response = await axios.post(`/${table}`, d).catch(error => { return error.response })
    if (!response) continue
    // Set the data to synced
    await put(table, d)
  }

  // Process the deleted data
  index = db.transaction('deleted').store
  data = []
  for await (const cursor of index.iterate()) data.push(cursor.value)
  for (let d of data) {
    if (d.table !== table) continue
    let response = await axios.delete(`/${table}/${d._id}`).catch(error => { return error.response })
    if (!response) continue
    await remove('deleted', d._id)
  }
}

const syncPhotos = async (axios) => {
  let db = await dbConnection

  // Get unsynced data
  let data = []
  let index = db.transaction('photo').store.index('synced')
  for await (const cursor of index.iterate(0)) data.push(cursor.value)

  for (let d of data) {
    // Convert dataURI back to file
    let file = dataURIToFile(d.path, d.filename)

    // Create formdata
    let formData = new FormData()
    formData.append('files[0]', file, file.name)

    // Upload files
    const result = await axios.post(`/upload/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8' }
    }).catch(error => { console.log(error); return false })
    if (!result) continue

    // Set to synced
    await put('photo', d)
    let imageMetadata = result.data

    // Enrich entity with the actual metadata
    let newImage = Object.assign({}, d, imageMetadata[0])
    let response = await axios.post(`/photo`, newImage).catch(error => { return error.response })
    if (!response) return alert('Error')
  }

  // Process the deleted data
  index = db.transaction('deleted').store
  data = []
  for await (const cursor of index.iterate()) data.push(cursor.value)
  for (let d of data) {
    if (d.table !== 'photo') continue
    let response = await axios.delete(`/photo/${d._id}`).catch(error => { return error.response })
    if (!response) continue
    await remove('deleted', d._id)
  }

}

// Create a custom plugin with interceptors
const Plugin = {
  async install (Vue) {

    Vue.prototype.$network = Vue.observable({ online: true })
    Vue.prototype.$clearDB = clear

        // Reactive property to check if we online
    document.addEventListener("DOMContentLoaded", function () {
      Vue.prototype.$network.online = navigator.onLine
      window.addEventListener('online',  () => {
        Vue.prototype.$network.online = true
      })
      window.addEventListener('offline', () => { Vue.prototype.$network.online = false })
    })

    Vue.prototype.$watch('$network.online', async (online) => {
      // Data can by synced only if the user is authorized
      if (online && Vue.prototype.$auth) {
        await syncData(Vue.prototype.$axios, 'address')
        await syncData(Vue.prototype.$axios, 'assessment')
        await syncData(Vue.prototype.$axios, 'activity')
        await syncData(Vue.prototype.$axios, 'stateQuestions')
        await syncData(Vue.prototype.$axios, 'metadata')
        await syncPhotos(Vue.prototype.$axios)
      }
    })


    // Interceptors to save all responses while online
    Vue.prototype.$axios.interceptors.response.use(async (response) => {
      let { config } = response

      // If we offline, we don't need to cache responses
      if (!Vue.prototype.$network.online) return response

      // We don't need actions at all for these paths
      if (config.url.match(ignoreForeverPattern)) return response
      if (config.url.match(ignoreResponsePattern)) return response
      if (config.method === 'post' && config.url.match(ignorePostPattern)) return response
      if (config.method === 'get' && config.url.match(ignoreGetPattern)) return response

      // No need to react on syncing the data
      if (config.method === 'post' && config.data.synced === 0) return response

      // Process the URL
      let { groups } = config.url.match(/^\/(?<table>[^/]+)(\/(?<_id>[^/]+))?/)
      if (!groups) return response

      // Get the table and id from the url
      let { table, _id } = groups

      // Process all get requests
      if (config.method === 'get') {
        // If the data is polygon, we want to save it
        if (table === 'polygons') savePolygons(response)
        // Save general data except search results
        if (!tables.includes(table)) { saveByPath(response) }
        // Save single entities (dicts are processed as responses)
        if (!['dict', 'polygons', 'user'].includes(table) && _id) put(table, response.data)
      }

      // Process all post requests
      if (config.method === 'post') {
        // If we are actually add or update any entity
        if (table !== 'search' && tables.includes(table)) put(table, response.data)
        // Process of search
        if (table === 'search' && config.data) {
          let reqBody = JSON.parse(config.data)
          let searchTable = reqBody.table
          // Save dictionaries
          if (!tables.includes(searchTable)) saveByTable(searchTable, response.data)
          // Process search
          if (tables.includes(searchTable)) {
            if (response.data.totalRows) addMany(searchTable, response.data.values)
          }
        }
      }

      // Process all delete requests
      if (config.method === 'delete') {
        if (tables.includes(table), _id) remove(table, _id)
      }

      return response
    }, (error) => {
      return Promise.reject(error)
    })

    // Interceptors to work with the requests
    Vue.prototype.$axios.interceptors.request.use(async config => {

      // If we are currently online we don't need to do anything at all
      if (Vue.prototype.$network.online) return config

      // We don't need actions at all
      if (config.url.match(ignoreForeverPattern)) return config
      if (config.method === 'post' && config.url.match(ignorePostPattern)) return config
      if (config.method === 'get' && config.url.match(ignoreGetPattern)) return config

      // Process the URL
      let { groups } = config.url.match(/^\/(?<table>[^/]+)(\/(?<_id>[^/]+))?/)
      if (!groups) return config
      // Get the table and id from the url
      let { table, _id } = groups

      let data = {}
      // Process all GET requests
      if (config.method === 'get') {
        // Save general data except search results
        if (!['search', 'me', ...tables].includes(table)) data = await get('responses', config.url)
        // If the data is polygon, return the polygon from the cache
        if (table === 'polygons') data = await get(table, config.url)
        // Get users data
        if (table === 'me') data = await getCurentUser()
        // Fetch single entities
        if (!['me', 'polygons', 'search', 'dict'].includes(table) && _id) data = await get(table, _id)
      }

      // Process all POST requests
      if (config.method === 'post') {
        // Process of search
        if (table === 'search' && config.data) {
          let reqBody = config.data
          let searchTable = reqBody.table
          // Get dictionaries
          if (!tables.includes(searchTable)) data = await get('responses', searchTable)
          // Process search
          if (tables.includes(searchTable) && searchTable !== 'user' ) data = await processSearch(reqBody)
          // We do not want to sync users
          if (searchTable === 'user' ) data = { values: [], totalRows: 0 }
        }
        if (table === 'tags') data = await getTags(config.data.searchValue)
        // Get locally saved stats
        if (table === 'stats') data = await getStats(_id, config.data)
        // Get locally saved aggs
        if (table === 'aggs') data = await getAggs(_id, config.data)

        // Handle uploads
        if (table === 'upload') {
          let file = config.data.get('files[0]')
          let uri = await fileToDataURI(file)
          data = [{
            filename: file.name,
            path: uri,
            mimetype: file.type,
            size: file.size
          }]
        }

        // If we are actually add or update any entity
        if (tables.includes(table) && table !== 'user') {
          if (validators[table]) {
            const validated = validators[table].validate(config.data, { abortEarly: false } )
            if (validated.error) {
              let messages = validated.error.details.map(el => el.message)
              // Create a custom adapter to return JSON from the cache
              config.adapter = (conf) => {
                return new Promise((resolve) => {
                  resolve({
                    __cached: true,
                    data: { data: { messages } },
                    status: 400,
                    statusText: 'OK',
                    headers: config.headers,
                    config: conf,
                    request: conf
                  })
                })
              }
              config.offline = true
              return config
            }
          }
          data = await put(table, config.data, false)
        }
      }

      // Process all delete requests
      if (config.method === 'delete') {
        if (tables.includes(table), _id) remove(table, _id)
      }
      // Create a custom adapter to return JSON from the cache
      config.adapter = (conf) => {
        return new Promise((resolve) => {
          resolve({
            __cached: true,
            data,
            status: 200,
            statusText: 'OK',
            headers: config.headers,
            config: conf,
            request: conf
          })
        })
      }
      config.offline = true
      return config
    })
  }
}

export default Plugin
