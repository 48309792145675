const uk = {
  'IMPORT_WAITING_MESSAGE': 'Запасіться терпінням, поки ми імпортуємо та порівнюємо адреси з наявними записами. Цей процес гарантує точну інтеграцію ваших даних у нашу систему.',
  'Welcome to SIDAR': 'Ласкаво просимо до SIDAR',
  'Offline mode': 'Оффлайн режим',
  'Oblast': 'Область',
  'Raion': 'Район',
  'Hromada': 'Громада',
  'Settlement': 'Населений пункт',
  'oblast': 'область',
  'raion': 'район',
  'hromada': 'громада',
  'settlement': 'населений пункт',
  'Home': 'Головна',
  'Find addresses': 'Знайти адреси',
  'Reports': 'Звіти',
  'User guide': 'Інструкція',
  'About': 'Про додаток',
  'Current user': 'Поточний користувач',
  'Users': 'Користувачі',
  'Trainings': 'Тренінги',
  'Map': 'Карта',
  'Damaged Property': 'Пошкоджене майно',
  'Property': 'Майно',
  'Ukraine': 'Україна',
  'Damage Assessments': 'Оцінка пошкоджень',
  'Data source': 'Джерело даних',
  'Color': 'Колір',
  'Palette': 'Кольорова гамма',
  'Size': 'Розмір',
  'Close': 'Закрити',
  'Personal data': 'Особисті дані',
  'Contacts': 'Контакти',
  'Save contacts': 'Зберегти контакти',
  'Change password': 'Змінити пароль',
  'Save password': 'Зберегти пароль',
  'Offline data': 'Оффлайн дані',
  'Your current database size is': 'Розмір вашої бази даних',
  'Free space': 'Вільне місце',
  'Clear addresses': 'Видалити адреси',
  'Clear assessments': 'Видалити оцінки',
  'Clear activities': 'Видалити активності',
  'Clear custom data': 'Видалити довільні дані',
  'Clear photos': 'Видалити фото',
  'Clear all': 'Видалити все',
  '(Blank)': 'Немає значення',
  '# of assessments': '# оцінок',
  '# of activities': '# дій',
  'Partner organization': 'Партнерська організація',
  'Building type': 'Тип будинку',
  'Address': 'Адреса',
  'Activity': 'Активність',
  'Assessment': 'Оцінка',
  'address': 'Адреса',
  'activity': 'Активність',
  'assessment': 'Оцінка',
  'Default': 'За замовчанням',
  'Activity date': 'Дата активності',
  'Activity status': 'Статус активності',
  'Reds': 'Червоний',
  'Browns': 'Коричневий',
  'Greens': 'Зелений',
  'Yellows': 'Жовтий',
  'Blues': 'Синій',
  'Light Blues': 'Блакитний',
  'Green to Red': 'Зелений -> Червоний',
  'Blue to Red': 'Синій -> Червоний',
  'Random': 'Випадковий',
  'Same size': 'Однаковий',
  'Organization Name': 'Назва організації',
  'Total area (m²)': 'Площа (м²)',
  'Area of damage (m²)': 'Площа пошкоджень (м²)',
  'Level of damage (%)': 'Рівень пошкоджень (%)',
  'Type of damage': 'Тип пошкоджень',
  'Common area': 'Спільна власність',
  'Damage Cause': 'Причина пошкоджень',
  '# of residents': '# мешканців',
  '# of floors': '# поверхів',
  '# of apartments': '# квартир',
  'Add assessment': 'Додати оцінку',
  'There were no damage assessments added yet': 'Жодної оцінки пошкоджень не додано',
  'Loading...': 'Завантаження...',
  'Damage type': 'Тип пошкодження',
  'Roof damage': 'Пошкодження даху',
  'Window damage': 'Пошкодження вікна',
  'Façade damage': 'Пошкодження фасаду',
  'Entrance damage': 'Пошкодження входу',
  'External - Electrical network': 'Зовнішнє - Електрична мережа',
  'External - Gas': 'Зовнішнє - Газ',
  'External - Water': 'Зовнішнє - Вода',
  'External - Sewage': 'Зовнішнє - Каналізація',
  'External - Heat Supply': 'Зовнішнє - Теплопостачання',
  'Internal - Electrical network': 'Внутрішнє - Електрична мережа',
  'Internal - Gas': 'Внутрішнє - Газ',
  'Internal - Water': 'Внутрішнє - Вода',
  'Internal - Sewage': 'Внутрішнє - Каналізація',
  'Internal - Heat Supply': 'Внутрішнє - Теплопостачання',
  'Internal - Elevators': 'Внутрішнє - Ліфти',
  'Highest priroty': 'Найвищий пріоритет',
  'High priroty': 'Високий пріоритет',
  'Normal priroty': 'Нормальний пріоритет',
  'Low priroty': 'Низький пріоритет',
  'Damage size': 'Розмір пошкодження',
  'Mining date': 'Дата мінування',
  'Rubble date': 'Дата завалів',
  'BUILDING DESCRIPTION': 'ОПИС БУДІВЛІ',
  'OVERALL RESIDENCE DESCRIPTION': 'ЗАГАЛЬНИЙ ОПИС БУДІВЛІ',
  'Wooden': 'Дерев\'янна будівля',
  'Brick pre-revolution': 'Дореволюційна з цегли',
  'Tsarski / stalinka (usually from brick)': 'Царська / Сталінка (зазвичай з цегли)',
  '"Khruschevka"': '"Хрущівка"',
  'Roughly 1958-1964, 3-5 floors, no lift, usually from concrete prefabricate panels': 'Приблизно 1958-1964 року будівництва, 3-5 поверхів, без ліфтів, зазвичай з бетонних збірних панелей',
  '"Bregnevka"': '"Брежнівка"',
  'Roughly 1964-1982, more than 8-12 floors, lift, garbage chute, usually from concrete prefabricate panels': 'Приблизно 1964-1982 року будівництва, 8-12 поверхів, наявний ліфт, сміттєпровід, зазвичай з бетонних збірних панелей',
  'Concrete': 'Бетонна будівля',
  'Brick modern building (roughly from 1985 and newer, monolith skeleton, foamed concrete, gasbeton /solid-cast)': 'Сучасна будівля, побудована у 1985 році або пізніше, монолітно-скелетна будівля, пінобетон, газобетон/суцільнолитий',
  'Year when the building was built': 'Рік будівництва',
  'Add common area': 'Додати місце загального користування',
  'Place': 'Місце',
  'Number of residents': 'Кількість жителів',
  'Number of floors': 'Кількість поверхів',
  'Number of apartments': 'Кількість квартир',
  'DAMAGE DETAILS': 'ОПИС ПОШКОДЖЕНЬ',
  'ADDITIONAL INFO': 'ДОДАТКОВА ІНФОРМАЦІЯ',
  'Building is restored': 'Будівля відреставрована',
  'Building working': 'Будівельні роботи',
  'Fast recovery programme': 'Учасник програми швидкого відновлення',
  'Humanitarian Activity': 'Гуманітарна активність',
  'Start activity': 'Розпочати активність',
  'There are no activity provided yet': 'Активність ще не розпочата',
  'Add activity': 'Додати активність',
  'of': 'з',
  'by': 'від',
  'Export': 'Експорт даних',
  'provided': 'наданих',
  'Assessments': 'Оцінки',
  'Activities': 'Активності',
  'Custom data': 'Довільні дані',
  'records by your organization': 'записів у вашій організації',
  'by your organization of': 'від вашої організації з',
  'assessed': '',
  'Submitted by': 'Відправив:',
  'Add photo': 'Додати фото',
  'There were no photos uploaded yet': 'Ще не завантажено жодної фотографії',
  'Add custom data': 'Додати довільні дані',
  'Your organization has not added custom data yet': 'Ваша організація не додала жодних довільних даних',
  'Custom data for the object': 'Налаштування довільних даних щодо об\'єкту',
  'Date of picture': 'Дата фотографії',
  'Category of picture': 'Категорія фотографії',
  'Description': 'Опис',
  'Please, provide the description in English': 'Будь ласка, зазначайте опис англійською мовою',
  'Delete': 'Видалити',
  'Photos': 'Фото',
  'Type of view': 'Тип вигляду',
  'Action': 'Дія',
  'Value': 'Значення',
  'Unit': 'Одиниці виміру',
  'The expected date of the end of this assessment is': 'Очікувана дата завершення оцінювання - ',
  'This address is currenty being assessed by': 'Ця будівля проходить оцінювання',
  'Go to address': 'Перейти до адреси',
  'DATES': 'ДАТИ',
  'Order': 'Порядок',
  'Icon': 'Піктограма',
  'Code': 'Код',
  'Label': 'Заголовок',
  'Options': 'Опції',
  'Add field': 'Додати поле',
  'Field name': 'Назва поля',
  'Field label': 'Заголовок поля',
  'Field order': 'Порядок поля',
  'Field type': 'Тип поля',
  'FIELD OPTIONS': 'ОПЦІЇ ПОЛЯ',
  'You haven\'t added any option yet': 'Ви ще не додали жодної опції',
  'Add option': 'Додати опцію',
  'Fields': 'Поля',
  'Planned start date': 'Запланована дата початку',
  'Planned date': 'Запланована дата',
  'Actual start date': 'Фактична дата початку',
  'Start date': 'Дата початку',
  'Planned completion date': 'Запланована дата завершення',
  'Completion date': 'Дата завершення',
  'Actual completion date': 'Фактична дата завершення',
  'Edit assessment': 'Редагувати оцінку',
  'DAMAGE INFORMATION': 'ІНФОРМАЦІЯ ПРО ПОШКОДЖЕННЯ',
  'Damage date': 'Дата пошкоджень',
  'Damage cause': 'Причина пошкоджень',
  'Level of damage': 'Рівень пошкоджень',
  'Total Area': 'Загальна площа',
  'Damaged area': 'Площа пошкоджень',
  'LIST OF DAMAGES': 'ПЕРЕЛІК ПОШКОДЖЕНЬ',
  'RESIDENCE DESCRIPTION': 'ОПИС МІСЦЯ ПРОЖИВАННЯ',
  'POSSIBLE ASSISTANCE': 'МОЖЛИВА ДОПОМОГА',
  'Reconstruction possible': 'Можлива реконструкція',
  'Mining Date': 'Дата мінування',
  'Removal of rubble date': 'Видалення завалів',
  'Building restored': 'Будівля відновлена',
  'Part of the fast-recovery programme': 'Частина програми швидкого відновлення',
  'Save': 'Зберегти',
  'Cancel': 'Скасувати',
  'Edit activity': 'Редагувати активність',
  'Activity type': 'Вид активності',
  'Recovery stage': 'Стадія відновлення',
  'Manage addresses': 'Керування адресами',
  'Edit': 'Редагувати',
  'Name': 'Ім\'я/Назва',
  'Apartments': 'Квартири',
  'Building Type': 'Тип будівлі',
  'Accuracy': 'Точність',
  '# of Assessments': 'Кількість оцінок',
  '# of Activities': 'Кількість активностей',
  'Status': 'Статус',
  'Damaged': 'Пошкоджено',
  'Date': 'Дата',
  'Lon': 'Довгота',
  'Lat': 'Широта',
  'Error': 'Помилка',
  'Error! Current address is not available': 'Помилка! Поточна адреса недоступна',
  'Filter by the settlement': 'Фільтрувати за населеним пунктом',
  'Count of all records': 'Загальна кількість',
  'Statistics': 'Статистика',
  'Search': 'Пошук',
  'Addresses': 'Адреси',
  'Add address': 'Додати адресу',
  'No data found': 'Даних не знайдено',
  'Edit address': 'Редагувати адресу',
  'Save & Assess': 'Зберегти та оцінити',
  'Your session is expired!': 'Ваш сеанс закінчився!',
  'Use your email and password to login': 'Використовуйте свою електронну адресу та пароль для входу',
  'User Email': 'Електронна пошта користувача',
  'Recover password': 'Відновити пароль',
  'Login': 'Увійти',
  'Provide your email and we will sent you the link to reset your account password.': 'Введіть свою електронну адресу, і ми надішлемо вам посилання для скидання пароля облікового запису.',
  'Back to sign in': 'Назад до входу',
  'Recover': 'Відновити',
  'Set password': 'Встановити пароль',
  'Set a new password for your account and enter to the system': 'Встановіть новий пароль для свого облікового запису та увійдіть у систему',
  'Confirm Password': 'Підтвердьте пароль',
  'Enter': 'Увійти',
  'Manage users': 'Керувати користувачами',
  'Add user': 'Додати користувача',
  'Edit user': 'Редагувати користувача',
  'User Name': 'Ім\'я користувача',
  'Email': 'Електронна пошта',
  'Confirm password': 'Підтвердьте пароль',
  'User status': 'Статус користувача',
  'User role': 'Роль користувача',
  'Organization': 'Організація',
  'Organization code': 'Код організації',
  'Role': 'Роль',
  'Phone': 'Телефон',
  'Enter 3 or more symbols to search among options': 'Введіть 3 або більше символів для пошуку серед варіантів',
  'No results matching': 'Немає відповідних результатів',
  'Press': 'Натисніть',
  'to create a new one': 'щоб створити новий об\'єкт',
  'Male': 'Чоловік',
  'Female': 'Жінка',
  'Transgender': 'Трансгендер',
  'Non-conforming/Non-variant': 'Невідповідний/неваріантний',
  'Other': 'Інший',
  'Do not want to disclose': 'Не хочу розголошувати',
  'Password': 'Пароль',
  'Logout': 'Вийти',
  'Delete data': 'Видалити дані',
  'Are you sure that you want to delete this data? This action can\'t be reverted!': 'Ви впевнені, що хочете видалити ці дані? Їх більше не можна буде відновити.',
  'Manage Users': 'Керувати користувачами',
  'Custom data settings': 'Налаштування довільних даних',
  'Admin': 'Адміністратор',
  'Staff Member': 'Працівник ООН',
  'Ministry Representative': 'Представник Міністерства',
  'Oblast Administration': 'Обласна адміністрація',
  'Raion Administration': 'Районна адміністрація',
  'Local Authorities': 'Місцеве самоврядування',
  'Local authorities': 'Місцеве самоврядування',
  'Partner Organization': 'Партнерська організація',
  'Partner Manager': 'Адміністратор партнера',
  'Donor': 'Донорська організація',
  'Restrict to oblasts': 'Обмежити областями',
  'Active': 'Активний',
  'Blocked': 'Заблокований',
  'Phone Number': 'Телефонний номер',
  'Add phone': 'Додати телефон',
  'No phones are added': 'Не додано жодного номера',
  'Address Management': 'Керування адресами',
  'Residential buildings (private estates)': 'Приватний житловий будинок',
  'Residential buildings (multi-storey)': 'Приватний багатоквартирний будинок',
  'Region statistics': 'Місцева статистика',
  'Items per page': 'Кількість елементів',
  'Apts': 'Квартири',
  'Corpus': 'Корпус',
  'Building': '№ будинку',
  'Street': 'Вулиця',
  'Type': 'Тип',
  'Enter admin4 level title': 'Введіть назву АТО 4 рівня',
  'Enter admin3 level title': 'Введіть назву АТО 3 рівня',
  'Enter admin2 level title': 'Введіть назву АТО 2 рівня',
  'Enter admin1 level title': 'Введіть назву АТО 1 рівня',
  'Get location and address': 'Визначити місце та адресу',
  'Longitude': 'Довгота',
  'Latitude': 'Широта',
  'Geocode address': 'Геокодувати',
  'street': 'вулиця',
  'alley': 'алея',
  'avenue': 'проспект',
  'boulevard': 'бульвар',
  'square': 'площа',
  'lane': 'провулок',
  'harbor': 'набережна',
  'highway': 'шосе',
  'bypass': 'об\'їзд',
  'path': 'шлях',
  'breakthrough': 'просіка',
  'descent': 'узвіз',
  'impasse': 'тупик',
  'road': 'дорога',
  'station': 'станція',
  'passage': 'проїзд',
  'maidan': 'майдан',
  'Address Review': 'Переглянути адресу',
  'MISCELLANEUOUS': 'ІНШЕ',
  'Other action of Russian army': 'Інші дії російської армії',
  'Ammunition': 'Пошкодження снарядом',
  'Missile strike': 'Ракетний удар',
  'Recovery priority': 'Пріоритет відновлення',
  'Provision of building materials': 'Надання будівельних матеріалів',
  'Yes': 'Так',
  'No': 'Ні',
  'm²': 'м²',
  'at': 'від',
  'Reported by': 'Автор звіту:',
  'Subversive Groups': 'Диверсійна діяльність',
  'Shelling': 'Обстріли',
  'Financial assistance': 'Фінансова підтримка',
  'Partial repair kits': 'Надання набору для ремонту',
  'Building restoration': 'Реставрація будівлі',
  'Expertise': 'Необхідна експертиза',
  'Measure': 'Одиниці виміру',
  'Total': 'Всього',
  'Activity number': 'Кількість активностей',
  'Assessments number': 'Кількість оцінок',
  'Activity Type': 'Тип активності',
  'Login page': 'Сторінка входу',
  'Sign in': 'Увійти',
  'Recover Password': 'Відновити пароль',
  'Ok': 'Ок',
  'What`s New in SIDAR': 'Що нового в системі SIDAR',
  'Training mode': 'Навчальний режим',
  'Hide measures': 'Приховати дії',
  'Programming partner': 'Програмний партнер',
  'Updates for version': 'Оновлення для версії',
  'GENERAL DISCRIPTION': 'ЗАГАЛЬНИЙ ОПИС',
  'Issue:': 'Виклик:',
  'Description:': 'Опис:',
  'Fix:': 'Виправлення:',
  'Pages:': 'Сторінки',
  'Fill in the "Measures taken" block to complete your activity': 'Заповніть блок «Вжиті заходи», щоб завершити свою активність',
  'Fill in the "Measures tken" block to save your activity': 'Заповніть блок «Вжиті заходи», щоб зберегти вашу активність',
  'available of total': 'доступно з загальної кількості',
  'required for a total of': 'потрібно для загальної кількості',
  'Edit password': 'Змінити пароль',
  'Generate password': 'Згенерувати пароль',

  // Activity Types
  'Emergency Shelter Kit': 'Набір для екстренного ремонту житла',
  'Refurbishment of CCs': 'Реконструкція колективного центру',
  'Compensation information (65 questions)': 'Опитування щодо компенсації',

  // Types of problems
  'Roof': 'Дах',
  'Windows': 'Вікна',
  'Doors': 'Двері',
  'Walls': 'Стіни',
  'Floor': 'Підлога',

  // Vulnerabilities
  'Persons with disabilities and/or chronic disease': 'Люди з обмеженними можливостями або хронічними захворюваннями',
  'Elderly dependents (60+)': 'Літні утриманці (60+)',
  'People with economic hardship': 'Люди з матеріальними труднощами',
  'Head of a household is a single woman': 'Голова сім\'ї - самотня жінка',
  'Head of household does not have necessary documentation': 'Голова домогосподарства не має необхідних документів',

  // User roles detailed
  'executive body of local authorities': 'виконавчий орган ОМС',
  'military administration': 'військова адміністрація',
  'Ministry of Infrastructure': 'Мінінфраструктури',
  'Ministry of Reintegration': 'Мінреінтеграції',
  'State Emergency Service of Ukraine': 'ДСНС',
  'Ministry of Regional Development': 'Мінрегіон',
  'Ministry of Culture': 'Мінкультури',
  'Ministry of Justice': 'Мін\'юст',
  'State Property Fund of Ukraine': 'Фонд державного майна',
  'local state administration': 'місцева держадміністрація',
  'administrative service center': 'центр надання адміністративних послуг',
  'social service body': 'орган соціального захисту населення',
  'notary': 'нотаріус',
  'builder':'виконавець робіт (послуг), пов\'язаний із створенням об\'єктів архітектури',
  'property appraiser': 'оцінювач майна',
  'utility service provider': 'виконавець комунальних послуг',
  'housing association': 'ОСББ',
  'another state body':'інший державний орган',

  // Types of fields
  'Text': 'Короткий текст',
  'Multi-line text': 'Розгорнутий текст',
  'Number': 'Номер',
  'Select': 'Вибір',
  'Combobox': 'Вибір із підказками',
  'Multiple select': 'Множинний вибір',
  'Boolean': 'Чекбокс', 

  // Activity status
  'Completed': 'Завершено',
  'Canceled': 'Скасовано',
  'Ongoing': 'В роботі',

  // Cancelation reasons
  'Beneficiary refused assistance': 'Бенефіціар відмовся від отримання допомоги',
  'Beneficiary is unreachable': 'З бенефіціаром неможливо зв\'язатись',
  'Property is not the primary residence of the beneficiary': 'Житло не є основним місцем проживання бенефіціара',
  'Out of financial capability of the organization': 'Поза фінансовими можливостями організації',
  'Another organization provides the assistance': 'Допомогу надає інша організація',
  'Structural damage': 'Структурні пошкодження',
  'Damage has been fixed': 'Пошкодження усунено',
  'Act of destruction is unavailable': 'Відсутній акт пошкодження',

  // DICT - Object type
  'Multi-storey': 'Багатоквартирний будинок',
  'Private estates': 'Приватний будинок',

  // DICT - Type of damage
  'Partial damage': 'Частково пошкоджено',
  'Significant damage': 'Значні пошкодження',
  'Completely destroyed': 'Повністю зруйновано',
  
  // Validation errors
  'Street name must be filled in Ukrainian. Do not use latin characters.': 'Назва вулиці має бути заповнена українською та не містити латинки.',
  'Street name is a mandatory field': 'Назва вулиці - це обов\'язкове поле для заповення',
  'P-code4 is a mandatory field': 'Код КОАТТГ 4 рівня є обов\'язковим для заповнення',
  'P-code4 must follow the pattern "UA\\d{10}".': 'Код КОАТТГ 4 рівня має відповідати паттерну "UA\\d{10}".',
  'This street type does not exist': 'Зазначений тип вулиці не існує',
  'Street type is a mandatory field': 'Тип вулиці - це обов\'язкове поле для заповення',
  'House number is a mandatory field': 'Номер будинку - це обов\'язкове поле для заповення',
  'Building type is a mandatory field': 'Тип будинку - це обов\'язкове поле для заповення',
  'House number can\'t be less than 1 symbol': 'Номер будинку не може бути менше 1 символу',
  'House number cam\'t be more than 6 symbols': 'Номер будинку не може бути більше 6 символів',
  'Photo category is a mandatory field': 'Ви маєте обов\'язково зазначити категорію фотографії',
  'Path for the photo is not specified. Please, upload your photo again.': 'Шлях до фотографії не збережено. Спробуйте завантажити ваше фото ще раз.',
  'Description must be a string': 'Опис має бути текстом',
  'Stage is a mandatory field': 'Етап надання допомоги є обов\'язковим полем',
  'Activity type is a mandatory field': 'Тип активності - це обов\'язкове поле для заповення',
  'Date of damage is a mandatory field': 'Дата пошкоджень - це обов\'язкове поле для заповення',
  'Date of damage must be provided in ISO-8601 format "YYYY-MM-DD"': 'Дата пошкоджень має бути заповнена за стандартом ISO-8601 у форматі "YYYY-MM-DD"',
  'Damage cause is a mandatory field': 'Причина пошкоджень - це обов\'язкове поле для заповення',
  'Area can\'t be less or equal to zero': 'Площа приміщення не може бути меншою або дорівнювати 0',
  'Area is a mandatory field': 'Площа приміщення - це обов\'язкове поле для заповення',
  'Damaged area can\'t be less or equal to zero': 'Площа пошкоджень не може бути меншою або дорівнювати 0',
  'Damaged area is a mandatory field': 'Площа пошкоджень - це обов\'язкове поле для заповення',
  'Level of damage can\'t be less or equal to zero': 'Рівень пошкоджень не може бути меншою або дорівнювати 0',
  'Level of damage can\'t ecxeed 100': 'Рівень пошкоджень не може бути більшим за 100',
  'Level of damage is a mandatory field': 'Рівень пошкоджень - це обов\'язкове поле для заповення',
  'Damage type is a mandatory field': 'Тип пошкоджень - це обов\'язкове поле для заповення',
  'Possibility of the reconstruction is a mandatory field': 'Можливість реконструкції - це обов\'язкове поле для заповення',
  'Level of priority is wrong': 'Пріоритет відновлення визначено невірно',
  'Level of priority is a mandatory field': 'Пріоритет відновлення - це обов\'язкове поле для заповення',
  'Date of assessment is a mandatory field': 'Дата проведення оцінки - це обов\'язкове поле для заповення',
  'Date of assessment must be provided in ISO-8601 format "YYYY-MM-DD"': 'Дата проведення оцінки має бути заповнена за стандартом ISO-8601 у форматі "YYYY-MM-DD"',

  // Import data
  'Import data': 'Імпорт даних',
  'Organizations': 'Організації',
  'Close file': 'Закрити файл',
  'Validate data': 'Перевірити дані',

  // User page
  'Staff members': 'Працівник кластера',
  'Partner organization member': 'Член партнерської організації',
  'RESTRICT USER': 'ОБМЕЖИТИ КОРИСТУВАЧА',
  'Job title': 'Посада',
  'User Name in Ukrainian': 'Ім\'я українською',
  'Restricted to': 'Обмеження',
  'Restriction': 'Обмеження',
  'Partner organization manager': 'Менеджер партнерської організації',
  'Add restriction': 'Додати обмеження',
  'You are not allowed to edit this user': 'Ви не можете редагувати цього користувача',
  'Email address is wrong': 'Електронна адреса невірна',
  'This user email is already registered': 'Користувач з такою поштою вже зареєстрований',
  'Password and confirmation are not equal': 'Пароль та його повторне введення не відповідають один одному',

  // Metadata
  'Multiple Select': 'Множинний вибір',

  // Assessments and activities
  'Comment': 'Коментар',
  'Cancelled': 'Скасовано',
  'Light repairs': 'Легкий ремонт',
  'Medium repairs': 'Середній ремонт',
  'Heavy repairs': 'Значний ремонт',
  'Compensated by the state': 'Компенсовано державою',
  'Fixed': 'Відремонтовано',
  'There are no activities provided yet': 'Ще не проведено жодного заходу',
  'Report date': 'Дата звіту',
  'Vulnerable residents': 'Вразливі групи',
  'Act of destruction': 'Акт про пошкодження',
  'VULNERABLE RESIDENTS': 'ВРАЗЛИВІ ГРУПИ',
  'Add any additional information for other partners': 'Додайте будь-яку інформацію для інших партнерів',
  'Number of ESK': 'Кількість ESK',
  'Apartment': 'Квартира',
  'MODALITY OF ACTIVITY': 'МОДАЛЬНІСТЬ ЗАХОДУ',
  'TIME RELATED DATA': 'ДАТИ',
  'Cancellation reason': 'Причина скасування',
  'Thermal envelope': 'Тепловий контур',
  'MEASURES TAKEN': 'ВЧИНЕНІ ДІЇ',
  'Measures taken': 'Вчинені дії',
  'Add apartment': 'Додати квартиру',
  'Apartment number': 'Номер квартири',
  'Assessment by': 'Автор звіту',
  'COMMENT': 'КОМЕНТАР',
  'Show all': 'Показати всі',
  'External view': 'Зовнішній вигляд',
  'Add comment': 'Додати комент',
  'Photo of external damage': 'Фото зовнішніх пошкоджень',
  'Repair works completed': 'Фото завершеного ремонту',
  'Photo of internal damage': 'Фото внутрішніх пошкоджень',
  'Filter': 'Фільтр',
  'sq. meters': 'м.кв.',
  'sq. meters (only the area with damage is calculated)': 'м.кв. (тільки пошкоджена площа)',
  'linear meters': 'метри погонні',
  'sq. meters of roof surfaces': 'м. кв. поверхні даху',
  'pieces': 'штуки',
  'blocks': 'блок',
  'cubic meters': 'м. куб.',
  'one place': 'одне місце',
  

  // Import page
  'Import': 'Імпорт',
  'Upload Data': 'Завантажити',
  'UPLOAD EXCEL FILE': 'ЗАВАНТАЖИТИ EXCEL ФАЙЛ',
  'Initial settings': 'Початкові налаштування',
  'You have not uploaded a file with data yet': 'Ви ще не завантажили файл',
  'Select the spreadsheet containing your data': 'Оберіть лист, який містить ваші дані',
  'What do you use as an identifier for your record?': 'Визначіть ідентифікатор для вашого запису',
  'What data would you like to import?': 'Які дані ви хочете імпортувати?',
  'Import mappings': 'Відповідність даних',
  'Select the proper fields mapping': 'Оберіть відповідність полів у вашому файлі',
  'Select the fields mapping for locations': 'Оберіть поля для імпорту адрес',
  'Select the fields mapping for assessments': 'Оберіть поля для імпорту оцінок',
  'Select the fields mapping for activites': 'Оберіть поля для імпорту заходів',
  'Data validation': 'Валідація даних',
  'To get the validation results click the "Validate data" button above': 'Для валідації даних натисніть кнопку "Валідація даних" вище',
  'Your data were successfully validated': 'Ваші дані були успішно валідовані',
  'Import results': 'Результати імпорту',
  'Created by user': 'Створено користувачем',
  'Source column': 'Джерело',
  'Target column': 'Результат',
  'Created by organization': 'Створено організацією',
  'Number of apartments (for residential buildings)': 'Кількість квартир (для багатоквартирних будинків)',
  'Damage - Roof': 'Пошкодження - дах',
  'Damage - Windows': 'Пошкодження - вікна',
  'Damage - Doors': 'Пошкодження - двері',
  'Damage - Walls': 'Пошкодження - вікна',
  'Damage - Floor': 'Пошкодження - стеля',
  'Vulnerable people are presented': 'Наявність вразливих груп',
  'Vulnerability - Persons with disabilities and/or chronic disease': 'Вразливість - Люди з обмеженними можливостями або хронічними захворюваннями',
  'Vulnerability - Elderly dependents (60+)': 'Вразливість - Літні утриманці (60+)',
  'Vulnerability - People with economic hardship': 'Вразливість - Люди з матеріальними труднощами',
  'Vulnerability - Head of a household is a single woman': 'Вразливість - Голова сім\'ї - самотня жінка',
  'Vulnerability - Head of household does not have necessary documentation': 'Вразливість - Голова домогосподарства не має необхідних документів',
  'Comment (Assessment)': 'Коментар (оцінка)',
  'Activity ID': 'Ідентифікатор заходу',
  'Modality - In-kind (materials)': 'Модальність - У натуральній формі (матеріали)',
  'Modality - In-kind (works)': 'Модальність - В натуральній формі (послуги)',
  'Modality - Cash': 'Модальність - Гроші',
  'Modality - Voucher': 'Модальність - Ваучер',
  'Thermal Envelope': 'Тепловий контур',
  'ESK amount': 'Кількість ESK',
  'Number of the property in DRRP': 'Номер майна у РПЗМ',
  'Number of the application in rddpId': 'Номер заявки в РПЗМ',
  'Apartment Number': 'Номер квартири',
  'Comment (Activity)': 'Коментар (активність)',
  'Assessment ID': 'Ідентифікатор оцінки',
  'Report Date': 'Дата звіту',
  'Damage Type': 'Тип пошкоджень',
  'Total area (m2)': 'Загальна площа (m2)',
  'Area of damage (m2)': 'Площа пошкоджень (m2)',
  'Spreadsheet name': 'Назва листа',
  'Identifier type': 'Тип ідентифікатора',
  'Import locations': 'Імпорт адрес',
  'Import assessments': 'Імпорт оцінок',
  'Import activities': 'Імпорт заходів',
  'Address ID': 'Ідентифікатор адреси',
  'Full Address': 'Повна адреса',
  'Splitted Address': 'Структурована адреса',
  'is required and is not mapped': 'є обов\'язковим, але не зазначене',
  'on the row': 'в рядку',
  'must be a string': 'має бути строкою',
  'must contain 24 latin characters or digits': 'має містити 24 латинських символи або цифри',
  'must start with "UA" and contain 10 digits': 'має починатися з "UA" та містити 10 цифр',
  'must not contain latin characters': 'не має містити символи латинки',
  'must contain latin characters only': 'має містити тільки латинку',
  'must contain a number': 'має містити цифру',
  'must be a number': 'має бути цифрою',
  'must be a valid date': 'має бути датою',
  'Full address is a mandatory field': 'Повна адреса є обов\'язковим полем',
  'Full address must be filled in Ukrainian. Do not use latin characters.': 'Повна адреса має бути заповнена українською мовою та не містити символи латинкою',
  'Act of destruction can have value "Yes" or "No"': 'Акт пошкоджень має відповідати значенням "Так" або "Ні"',
  'Status is a mandatory field': 'Статус є обов\'язковим полем',
  'Modality type must be a string': 'Модальність має бути строкою',
  'Cancellation reason is required for cancelled activities': 'Причина скасування є обов\'язковим полем для скасованих заходів',
  'must contain 24 latin characters or digits, but has the wrong value': 'має містити 24 латинських символи або цифри',
  'must be a string, but has the wrong value': 'має бути строкою',
  'is required, but it is absent': 'є обов\'язковим полем',
  'Administrative code 4': 'КОАТТГ 4 рівня',
  'House number': 'Номер будівлі',
  'Street name (ukr)': 'Назва вулиці українською',
  'is required': 'є обов\'язковим полем',
  'required': 'є обов\'язковим',
  'Activities number': 'Кількість активностей',

  // Addresses
  'UA0102003001': 'Ароматне',
  'UA0102003003': 'Маловидне',
  'UA0102003004': 'Рєпіне',
  'UA0102003005': 'Розове',
  'UA0102003002': 'Вікторівка',
  'UA0102005001': 'Бахчисарай',
  'UA0102005002': 'Научний',
  'UA0102015001': 'Долинне',
  'UA0102015002': 'Фурмановка',
  'UA0102015003': 'Новеньке',
  'UA0102013002': 'Аромат',
  'UA0102013003': 'Багата Ущелина',
  'UA0102013001': 'Голубинка',
  'UA0102013005': 'Новопілля',
  'UA0102013004': 'Нижня Голубинка',
  'UA0102013006': 'Поляна',
  'UA0102013007': 'Путилівка',
  'UA0102013008': 'Соколине',
  'UA0102013009': 'Сонячносілля',
  'UA0102025001': 'Каштани',
  'UA0102025003': 'Кочергіне',
  'UA0102025004': 'Шевченкове',
  'UA0102025002': 'Відрадне',
  'UA0102027003': 'Ходжа Сала',
  'UA0102027004': 'Холмівка',
  'UA0102027001': 'Красний Мак',
  'UA0102027002': 'Залісне',
  'UA0102029001': 'Куйбишеве',
  'UA0102029004': 'Мале Садове',
  'UA0102029005': 'Новоульянівка',
  'UA0102029006': 'Танкове',
  'UA0102029002': 'Велике Садове',
  'UA0102029003': 'Високе',
  'UA0102033002': 'Берегове',
  'UA0102033001': 'Піщане',
  'UA0102035002': 'Брянське',
  'UA0102035004': 'Дібрівка',
  'UA0102035005': 'Дорожнє',
  'UA0102035003': 'Гірка',
  'UA0102035001': 'Плодове',
  'UA0102037004': 'Казанки',
  'UA0102037005': 'Малинівка',
  'UA0102037007': 'Новопавлівка',
  'UA0102037006': 'Нововасилівка',
  'UA0102037001': 'Поштове',
  'UA0102037008': 'Приятне Свідання',
  'UA0102037009': 'Ростуче',
  'UA0102037010': 'Самохвалове',
  'UA0102037011': 'Севастянівка',
  'UA0102037013': 'Стальне',
  'UA0102037012': 'Тополі',
  'UA0102037002': 'Завітне',
  'UA0102037003': 'Зубакіне',
  'UA0102001001': 'Андріївка',
  'UA0102001002': 'Сонячний',
  'UA0102007002': 'Дальнє',
  'UA0102007006': 'Фронтове',
  'UA0102007007': 'Фруктове',
  'UA0102007003': 'Камишли',
  'UA0102007005': 'Поворотне',
  'UA0102007004': 'Пироговка',
  'UA0102007001': 'Верхньосадове',
  'UA0102021001': 'Інкерман',
  'UA0102023001': 'Кача',
  'UA0102023003': 'Орлівка',
  'UA0102023004': 'Осипенко',
  'UA0102023005': 'Полюшко',
  'UA0102023002': 'Вишневе',
  'UA0102031002': 'Гончарне',
  'UA0102031013': 'Колхозне',
  'UA0102031003': 'Кизилове',
  'UA0102031004': 'Новобобрівське',
  'UA0102031001': 'Орлине',
  'UA0102031005': 'Озерне',
  'UA0102031006': 'Павлівка',
  'UA0102031007': 'Передове',
  'UA0102031008': 'Підгірне',
  'UA0102031009': 'Резервне',
  'UA0102031010': 'Родниківське',
  'UA0102031011': 'Розсошанка',
  'UA0102031014': 'Широке',
  'UA0102031012': 'Тилове',
  'UA0102043002': 'Рідне',
  'UA0102043001': 'Тернівка',
  'UA0102039002': 'Глибокий Яр',
  'UA0102039003': 'Прохладне',
  'UA0102039001': 'Скалисте',
  'UA0102039004': 'Трудолюбівка',
  'UA0102041001': 'Табачне',
  'UA0102045002': 'Айвове',
  'UA0102045003': 'Красна Зоря',
  'UA0102045004': 'Некрасовка',
  'UA0102045005': 'Суворове',
  'UA0102045001': 'Тінисте',
  'UA0102047001': 'Углове',
  'UA0102009002': 'Баштанівка',
  'UA0102009003': 'Кудрине',
  'UA0102009004': 'Машине',
  'UA0102009005': 'Передущельне',
  'UA0102009006': 'Синапне',
  'UA0102009001': 'Верхоріччя',
  'UA0102011002': 'Розсадне',
  'UA0102011001': 'Віліне',
  'UA0102017002': 'Білокам\'яне',
  'UA0102017003': 'Дачне',
  'UA0102017004': 'Мостове',
  'UA0102017005': 'Річне',
  'UA0102017007': 'Сирень',
  'UA0102017006': 'Тургенєвка',
  'UA0102017001': 'Залізничне',
  'UA0102019002': 'Богатир',
  'UA0102019003': 'Многоріччя',
  'UA0102019004': 'Нагірне',
  'UA0102019005': 'Плотинне',
  'UA0102019006': 'Щасливе',
  'UA0102019001': 'Зелене',
  'UA0104001001': 'Ароматне',
  'UA0104001002': 'Красногірське',
  'UA0104001003': 'Курортне',
  'UA0104003001': 'Багате',
  'UA0104003007': 'Черемисівка',
  'UA0104003002': 'Горлинка',
  'UA0104003003': 'Красна Слобода',
  'UA0104003004': 'Мелехове',
  'UA0104003005': 'Поворотне',
  'UA0104003006': 'Руське',
  'UA0104005001': 'Білогірськ',
  'UA0104071001': 'Чкалове',
  'UA0104071004': 'Коврове',
  'UA0104071005': 'Лугове',
  'UA0104071006': 'Степанівка',
  'UA0104071002': 'Великосілля',
  'UA0104071003': 'Заливне',
  'UA0104073001': 'Чорнопілля',
  'UA0104073002': 'Дозорне',
  'UA0104073003': 'Кизилівка',
  'UA0104073004': 'Ульяновка',
  'UA0104011001': 'Дрофине',
  'UA0104011002': 'Стрепетове',
  'UA0104011003': 'Яструбки',
  'UA0104027001': 'Іванівка',
  'UA0104027003': 'Тамбовка',
  'UA0104027004': 'Тарасівка',
  'UA0104027002': 'Заріччя',
  'UA0104029001': 'Ізобільне',
  'UA0104031002': 'Фрунзе',
  'UA0104031001': 'Кісточківка',
  'UA0104033001': 'Кримська Роза',
  'UA0104033002': 'Вишневе',
  'UA0104035002': 'Алексєєвка',
  'UA0104035003': 'Голованівка',
  'UA0104035004': 'Карасівка',
  'UA0104035006': 'Красноселівка',
  'UA0104035001': 'Криничне',
  'UA0104035005': 'Кирпичне',
  'UA0104035007': 'Яблучне',
  'UA0104037001': 'Курське',
  'UA0104037002': 'Тополівка',
  'UA0104039002': 'Кирсанівка',
  'UA0104039001': 'Листвинне',
  'UA0104039003': 'Цвітуще',
  'UA0104041001': 'Мельничне',
  'UA0104041002': 'Ударне',
  'UA0104047002': 'Лічебне',
  'UA0104047001': 'Мічурінське',
  'UA0104049002': 'Дивне',
  'UA0104049005': 'Хлібне',
  'UA0104049003': 'Кривцове',
  'UA0104049001': 'Муромське',
  'UA0104049004': 'Сінне',
  'UA0104045002': 'Кунцеве',
  'UA0104045001': 'Михайлівка',
  'UA0104045003': 'Уютне',
  'UA0104043002': 'Буревісник',
  'UA0104043005': 'Червоне',
  'UA0104043001': 'Митрофанівка',
  'UA0104043003': 'Плодове',
  'UA0104043004': 'Розливи',
  'UA0104053003': 'Корінне',
  'UA0104053001': 'Новогригорівка',
  'UA0104053002': 'Владиславівка',
  'UA0104055002': 'Аннівка',
  'UA0104055003': 'Новоолександрівка',
  'UA0104055001': 'Новожилівка',
  'UA0104055004': 'Тургенєве',
  'UA0104051003': 'Лінійне',
  'UA0104051001': 'Нижньогірський',
  'UA0104051002': 'Зелене',
  'UA0104059001': 'Охотське',
  'UA0104059002': 'Родники',
  'UA0104057001': 'Омелянівка',
  'UA0104061002': 'Дворове',
  'UA0104061003': 'Любимівка',
  'UA0104061001': 'Пшеничне',
  'UA0104061004': 'Слив\'янка',
  'UA0104063002': 'Лугове',
  'UA0104063001': 'Русаківка',
  'UA0104065002': 'Кукурудзяне',
  'UA0104065001': 'Садове',
  'UA0104065003': 'Сєрове',
  'UA0104069002': 'Долинівка',
  'UA0104069001': 'Цвіточне',
  'UA0104067002': 'Новоіванівка',
  'UA0104067003': 'Сім\'яне',
  'UA0104067001': 'Уварівка',
  'UA0104007002': 'Малинівка',
  'UA0104007003': 'Некрасове',
  'UA0104007004': 'Павлівка',
  'UA0104007005': 'Пролом',
  'UA0104007006': 'Сєверне',
  'UA0104007001': 'Василівка',
  'UA0104009002': 'Біла Скеля',
  'UA0104009003': 'Миронівка',
  'UA0104009001': 'Вишенне',
  'UA0104075002': 'Дворіччя',
  'UA0104075003': 'Лужки',
  'UA0104075001': 'Якимівка',
  'UA0104017002': 'Балки',
  'UA0104017003': 'Міжгір\'я',
  'UA0104017004': 'Новогригорівка',
  'UA0104017005': 'Новокленове',
  'UA0104017007': 'Олександрівка',
  'UA0104017006': 'Овражки',
  'UA0104017008': 'Пасічне',
  'UA0104017009': 'Пчолине',
  'UA0104017010': 'Яковлівка',
  'UA0104017001': 'Зеленогірське',
  'UA0104019002': 'Оленівка',
  'UA0104019003': 'Опитне',
  'UA0104019004': 'Радісне',
  'UA0104019005': 'Родники',
  'UA0104019006': 'Синьокам\'янка',
  'UA0104019007': 'Учебне',
  'UA0104019001': 'Земляничне',
  'UA0104013002': 'Ломоносове',
  'UA0104013001': 'Желябовка',
  'UA0104015002': 'Піни',
  'UA0104015003': 'Прирічне',
  'UA0104015001': 'Жемчужина',
  'UA0104023002': 'Межове',
  'UA0104023003': 'Ніжинське',
  'UA0104023004': 'Широке',
  'UA0104023001': 'Зоркіне',
  'UA0104025002': 'Баланове',
  'UA0104025003': 'Барабанове',
  'UA0104025006': 'Литвиненкове',
  'UA0104025007': 'Нижні Орішники',
  'UA0104025008': 'Петрове',
  'UA0104025009': 'Українка',
  'UA0104025004': 'Верхні Орішники',
  'UA0104025005': 'Володимирівка',
  'UA0104025001': 'Зуя',
  'UA0104021002': 'Мельники',
  'UA0104021001': 'Зибини',
  'UA0106001001': 'Азовське',
  'UA0106051001': 'Чайкине',
  'UA0106051002': 'Мисове',
  'UA0106005001': 'Джанкой',
  'UA0106013002': 'Дмитрівка',
  'UA0106013001': 'Ізумрудне',
  'UA0106013003': 'Калинівка',
  'UA0106013004': 'Мічурінівка',
  'UA0106013005': 'Новостепове',
  'UA0106013006': 'Овочеве',
  'UA0106015004': 'Дорожнє',
  'UA0106015001': 'Кіндратове',
  'UA0106015003': 'Рубинівка',
  'UA0106015002': 'Совєтське',
  'UA0106017001': 'Кримка',
  'UA0106019001': 'Лобанове',
  'UA0106019003': 'Мар\'їне',
  'UA0106019004': 'Орденоносне',
  'UA0106019005': 'Ясне',
  'UA0106019002': 'Жилине',
  'UA0106021003': 'Ковильне',
  'UA0106021001': 'Луганське',
  'UA0106021004': 'Пробудження',
  'UA0106021005': 'Тутове',
  'UA0106021002': 'Ударне',
  'UA0106023003': 'Ближнє',
  'UA0106023004': 'Ларине',
  'UA0106023001': 'Майське',
  'UA0106023002': 'Октябр',
  'UA0106023005': 'Польове',
  'UA0106025003': 'Комсомольське',
  'UA0106025001': 'Маслове',
  'UA0106025002': 'Суботник',
  'UA0106027001': 'Медведівка',
  'UA0106027002': 'Передмістне',
  'UA0106027003': 'Тургенєве',
  'UA0106029002': 'Дніпровка',
  'UA0106029003': 'Костянтинівка',
  'UA0106029001': 'Мирнівка',
  'UA0106029004': 'Рисакове',
  'UA0106029005': 'Тимофіївка',
  'UA0106031002': 'Істочне',
  'UA0106031001': 'Новокримське',
  'UA0106031003': 'Павлівка',
  'UA0106033001': 'Пахарівка',
  'UA0106033002': 'Випасне',
  'UA0106035002': 'Нове Життя',
  'UA0106035001': 'Побєдне',
  'UA0106035003': 'Тарасівка',
  'UA0106037002': 'Антонівка',
  'UA0106037003': 'Апрелівка',
  'UA0106037004': 'Бородіно',
  'UA0106037005': 'Нижні Отрожки',
  'UA0106037001': 'Просторне',
  'UA0106037006': 'Слов\'янське',
  'UA0106037007': 'Стефанівка',
  'UA0106041002': 'Ближньогородське',
  'UA0106041003': 'Краснодольне',
  'UA0106041004': 'Озерне',
  'UA0106041001': 'Рощине',
  'UA0106041005': 'Сєрноводське',
  'UA0106039001': 'Розкішне',
  'UA0106039002': 'Зернове',
  'UA0106045002': 'Многоводне',
  'UA0106045005': 'Новофедорівка',
  'UA0106045003': 'Новокостянтинівка',
  'UA0106045004': 'Новопавлівка',
  'UA0106045006': 'Озерки',
  'UA0106045007': 'Прозрачне',
  'UA0106045008': 'Рідне',
  'UA0106045001': 'Стальне',
  'UA0106043002': 'Благодатне',
  'UA0106043001': 'Світле',
  'UA0106047003': 'Федорівка',
  'UA0106047004': 'Хлібне',
  'UA0106047002': 'Новосільцеве',
  'UA0106047001': 'Табачне',
  'UA0106049002': 'Колоски',
  'UA0106049003': 'Томашівка',
  'UA0106049001': 'Цілинне',
  'UA0106003001': 'Вільне',
  'UA0106053001': 'Ярке',
  'UA0106053002': 'Яструбці',
  'UA0106055002': 'Арбузівка',
  'UA0106055008': 'Дібрівка',
  'UA0106055005': 'Нахідка',
  'UA0106055006': 'Слов\'янка',
  'UA0106055007': 'Тімірязєве',
  'UA0106055003': 'Веселе',
  'UA0106055004': 'Відрадне',
  'UA0106055001': 'Ярке Поле',
  'UA0106057002': 'Рюмшине',
  'UA0106057003': 'Володине',
  'UA0106057001': 'Яснополянське',
  'UA0106007003': 'Копані',
  'UA0106007007': 'Островське',
  'UA0106007004': 'Придорожнє',
  'UA0106007005': 'Солоне Озеро',
  'UA0106007006': 'Стовпове',
  'UA0106007002': 'Вітвисте',
  'UA0106007001': 'Єрмакове',
  'UA0106011002': 'Армійське',
  'UA0106011003': 'Болотне',
  'UA0106011007': 'Митюрине',
  'UA0106011004': 'Низинне',
  'UA0106011005': 'Перепілкине',
  'UA0106011006': 'Суміжне',
  'UA0106011001': 'Зарічне',
  'UA0106009003': 'Мартинівка',
  'UA0106009004': 'Мілководне',
  'UA0106009005': 'Пушкіне',
  'UA0106009006': 'Солонцеве',
  'UA0106009001': 'Завіт-Ленінський',
  'UA0106009002': 'Зелений Яр',
  'UA0118001001': 'Абрикосівка',
  'UA0118001002': 'Бабенкове',
  'UA0118001003': 'Кринички',
  'UA0118001004': 'Матросівка',
  'UA0118003001': 'Берегове',
  'UA0118003002': 'Степове',
  'UA0118073001': 'Чапаєвка',
  'UA0118073006': 'Хлібне',
  'UA0118073002': 'Коломенське',
  'UA0118073003': 'Миколаївка',
  'UA0118073005': 'Новоселівка',
  'UA0118073004': 'Новий Мир',
  'UA0118075002': 'Алмазне',
  'UA0118075001': 'Чорноземне',
  'UA0118075003': 'Дем\'янівка',
  'UA0118075004': 'Корніївка',
  'UA0118075005': 'Роздольне',
  'UA0118011001': 'Дачне',
  'UA0118011002': 'Лісне',
  'UA0118013001': 'Дмитрівка',
  'UA0118013002': 'Ровенка',
  'UA0118071001': 'Феодосія',
  'UA0118009001': 'Грушівка',
  'UA0118009003': 'Холодівка',
  'UA0118009002': 'Перевалівка',
  'UA0118021004': 'Дятлівка',
  'UA0118021003': 'Георгіївка',
  'UA0118021001': 'Іллічеве',
  'UA0118021005': 'Надежда',
  'UA0118021006': 'Річне',
  'UA0118021007': 'Шахтине',
  'UA0118021002': 'Восточне',
  'UA0118023001': 'Кіровське',
  'UA0118025001': 'Коктебель',
  'UA0118025002': 'Нанікове',
  'UA0118029001': 'Краснофлотське',
  'UA0118029003': 'Лебединка',
  'UA0118029004': 'Маркове',
  'UA0118029002': 'Варварівка',
  'UA0118027001': 'Красногвардійське',
  'UA0118027002': 'Лохівка',
  'UA0118027003': 'Лучове',
  'UA0118031002': 'Добролюбовка',
  'UA0118031003': 'Долинне',
  'UA0118031001': 'Льговське',
  'UA0118031004': 'Пруди',
  'UA0118033001': 'Міжріччя',
  'UA0118033002': 'Ворон',
  'UA0118035002': 'Громівка',
  'UA0118035001': 'Морське',
  'UA0118037002': 'Ближнє',
  'UA0118037001': 'Насипне',
  'UA0118037005': 'Підгірне',
  'UA0118037004': 'Піонерське',
  'UA0118037006': 'Сонячне',
  'UA0118037003': 'Виноградне',
  'UA0118037007': 'Южне',
  'UA0118039001': 'Некрасовка',
  'UA0118039002': 'Октябрське',
  'UA0118041001': 'Новий Світ',
  'UA0118043001': 'Орджонікідзе',
  'UA0118045001': 'Партизани',
  'UA0118045002': 'Спасівка',
  'UA0118047005': 'Ізюмівка',
  'UA0118047004': 'Ізобільне',
  'UA0118047006': 'Ключове',
  'UA0118047001': 'Первомайське',
  'UA0118047007': 'Садове',
  'UA0118047002': 'Відважне',
  'UA0118047003': 'Жемчужина Криму',
  'UA0118053001': 'Пруди',
  'UA0118053002': 'Привільне',
  'UA0118051001': 'Приморський',
  'UA0118049002': 'Айвазовське',
  'UA0118049001': 'Привітне',
  'UA0118055002': 'Маківка',
  'UA0118055001': 'Пушкіне',
  'UA0118077003': 'Краснокам\'янка',
  'UA0118077002': 'Курортне',
  'UA0118077001': 'Щебетовка',
  'UA0118061002': 'Багатівка',
  'UA0118061003': 'Миндальне',
  'UA0118061004': 'Прибережне',
  'UA0118061001': 'Сонячна Долина',
  'UA0118059001': 'Совєтський',
  'UA0118063001': 'Старий Крим',
  'UA0118065001': 'Судак',
  'UA0118057003': 'Краснівка',
  'UA0118057001': 'Синицине',
  'UA0118057002': 'Василькове',
  'UA0118067002': 'Шубине',
  'UA0118067001': 'Токарєве',
  'UA0118069002': 'Присивашне',
  'UA0118069001': 'Урожайне',
  'UA0118005001': 'Веселе',
  'UA0118007001': 'Владиславівка',
  'UA0118007002': 'Вузлове',
  'UA0118079002': 'Красносільське',
  'UA0118079003': 'Новофедорівка',
  'UA0118079004': 'Оріхівка',
  'UA0118079005': 'Софіївка',
  'UA0118079006': 'Трудолюбівка',
  'UA0118079001': 'Ярке Поле',
  'UA0118017002': 'Пчільники',
  'UA0118017001': 'Завітне',
  'UA0118015003': 'Маківське',
  'UA0118015004': 'Новопокровка',
  'UA0118015005': 'Тутівка',
  'UA0118015002': 'Видне',
  'UA0118015001': 'Журавки',
  'UA0118019002': 'Відродження',
  'UA0118019001': 'Золоте Поле',
  'UA0110001001': 'Багерове',
  'UA0110001002': 'Іванівка',
  'UA0110003001': 'Батальне',
  'UA0110003003': 'Ячмінне',
  'UA0110003002': 'Южне',
  'UA0110005001': 'Бєлінське',
  'UA0110005005': 'Нововідрадне',
  'UA0110005004': 'Нижньозаморське',
  'UA0110005006': 'Станційне',
  'UA0110005002': 'Верхньозаморське',
  'UA0110005003': 'Золоте',
  'UA0110051001': 'Челядінове',
  'UA0110051002': 'Огоньки',
  'UA0110053001': 'Чистопілля',
  'UA0110053003': 'Лібкнехтівка',
  'UA0110053004': 'Тасунове',
  'UA0110053002': 'Затишне',
  'UA0110011001': 'Глазівка',
  'UA0110011002': 'Осовини',
  'UA0110011003': 'Юркине',
  'UA0110013001': 'Горностаївка',
  'UA0110017001': 'Іллічеве',
  'UA0110019001': 'Калинівка',
  'UA0110021001': 'Керч',
  'UA0110023001': 'Кірове',
  'UA0110023002': 'Вулканівка',
  'UA0110023003': 'Ярке',
  'UA0110025002': 'Корольове',
  'UA0110025001': 'Красногірка',
  'UA0110027001': 'Леніне',
  'UA0110029002': 'Фонтан',
  'UA0110029001': 'Ленінське',
  'UA0110031001': 'Лугове',
  'UA0110031002': 'Єрофєєве',
  'UA0110035001': 'Марфівка',
  'UA0110035002': 'Новоселівка',
  'UA0110035003': 'Прудникове',
  'UA0110033002': 'Борисівка',
  'UA0110033001': 'Мар\'ївка',
  'UA0110033004': 'Пташкине',
  'UA0110033003': 'В\'язникове',
  'UA0110037002': 'Азовське',
  'UA0110037001': 'Мисове',
  'UA0110037004': 'Семенівка',
  'UA0110037003': 'Заводське',
  'UA0110039001': 'Новомиколаївка',
  'UA0110041001': 'Октябрське',
  'UA0110043001': 'Останіне',
  'UA0110043003': 'Пісочне',
  'UA0110043002': 'Зелений Яр',
  'UA0110045001': 'Приозерне',
  'UA0110047006': 'Фронтове',
  'UA0110047002': 'Кам\'янське',
  'UA0110047003': 'Львове',
  'UA0110047004': 'Петрове',
  'UA0110047001': 'Семисотка',
  'UA0110047005': 'Соляне',
  'UA0110055001': 'Щолкіне',
  'UA0110049001': 'Уварове',
  'UA0110009002': 'Бондаренкове',
  'UA0110009003': 'Курортне',
  'UA0110009001': 'Войкове',
  'UA0110009004': 'Єгорове',
  'UA0110007002': 'Романове',
  'UA0110007001': 'Виноградне',
  'UA0110015002': 'Костиріне',
  'UA0110015003': 'Набережне',
  'UA0110015004': 'Яковенкове',
  'UA0110015001': 'Завітне',
  'UA0112001001': 'Абрикосове',
  'UA0112003001': 'Амурське',
  'UA0112003002': 'Іскра',
  'UA0112003004': 'Новоіванівка',
  'UA0112003005': 'Новоолексієвка',
  'UA0112003003': 'Новозуївка',
  'UA0112003006': 'Цвіткове',
  'UA0112071001': 'Чернове',
  'UA0112071003': 'Каштанівка',
  'UA0112071002': 'Свердловське',
  'UA0112011002': 'Фрунзе',
  'UA0112011001': 'Гришине',
  'UA0112011003': 'Випасне',
  'UA0112009002': 'Братське',
  'UA0112009001': 'Гвардійське',
  'UA0112009003': 'Оленівка',
  'UA0112015001': 'Калініне',
  'UA0112015003': 'Комунари',
  'UA0112015005': 'Побєдине',
  'UA0112015002': 'Вишняківка',
  'UA0112017001': 'Калініне',
  'UA0112017004': 'Левітанівка',
  'UA0112019002': 'Карпівка',
  'UA0112019001': 'Клепиніне',
  'UA0112019003': 'Яструбівка',
  'UA0112021002': 'Докучаєве',
  'UA0112021001': 'Колодязне',
  'UA0112021003': 'Пологи',
  'UA0112023002': 'Чапаєве',
  'UA0112023001': 'Кормове',
  'UA0112023003': 'Тихонівка',
  'UA0112025002': 'Дібрівське',
  'UA0112025001': 'Котельникове',
  'UA0112025003': 'Машине',
  'UA0112027001': 'Красногвардійське',
  'UA0112027002': 'Видне',
  'UA0112029001': 'Краснознам\'янка',
  'UA0112029002': 'Радужне',
  'UA0112029003': 'Рогове',
  'UA0112029004': 'Симоненко',
  'UA0112029006': 'Трактове',
  'UA0112029005': 'Тимошенко',
  'UA0112031001': 'Крестянівка',
  'UA0112031002': 'Нова Деревня',
  'UA0112033001': 'Ленінське',
  'UA0112033003': 'Пряме',
  'UA0112033002': 'Звіздне',
  'UA0112035001': 'Мар\'янівка',
  'UA0112035003': 'Щербакове',
  'UA0112035002': 'Ульяновка',
  'UA0112037001': 'Найдьонівка',
  'UA0112037003': 'Орлівка',
  'UA0112037002': 'Золоте',
  'UA0112039002': 'Красна Долина',
  'UA0112039004': 'Мускатне',
  'UA0112039003': 'Миронівка',
  'UA0112039005': 'Невське',
  'UA0112039006': 'Новодолинка',
  'UA0112039001': 'Новопокровка',
  'UA0112039007': 'Проточне',
  'UA0112041001': 'Октябрське',
  'UA0112043002': 'Кам\'янка',
  'UA0112043001': 'Октябрське',
  'UA0112045002': 'Краснодарка',
  'UA0112045001': 'Олександрівка',
  'UA0112045003': 'Тимашівка',
  'UA0112047001': 'Олексіївка',
  'UA0112047002': 'Привільне',
  'UA0112049002': 'Мельничне',
  'UA0112049001': 'Островське',
  'UA0112049003': 'Снігурівка',
  'UA0112053002': 'Макарівка',
  'UA0112053001': 'Первомайське',
  'UA0112053003': 'Пшеничне',
  'UA0112053004': 'Упорне',
  'UA0112055002': 'Ближнє',
  'UA0112055003': 'Ізвесткове',
  'UA0112055004': 'Красна Поляна',
  'UA0112055005': 'Кремнівка',
  'UA0112055006': 'Миролюбівка',
  'UA0112055007': 'Новоестонія',
  'UA0112055001': 'Петрівка',
  'UA0112055008': 'Пушкіне',
  'UA0112051002': 'Азов',
  'UA0112051006': 'Холмове',
  'UA0112051004': 'Менделєєве',
  'UA0112051001': 'П\'ятихатка',
  'UA0112051005': 'Салгирка',
  'UA0112051003': 'Зарічне',
  'UA0112057002': 'Комарівка',
  'UA0112057003': 'Курганне',
  'UA0112057001': 'Полтавка',
  'UA0112059002': 'Арбузове',
  'UA0112059003': 'Матвіївка',
  'UA0112059001': 'Правда',
  'UA0112061002': 'Молочне',
  'UA0112061003': 'Некрасове',
  'UA0112061004': 'Новомикільське',
  'UA0112061001': 'Рівне',
  'UA0112063001': 'Сари-Баш',
  'UA0112065002': 'Дальнє',
  'UA0112065001': 'Стахановка',
  'UA0112067002': 'Криловка',
  'UA0112067001': 'Степне',
  'UA0112069002': 'Панфіловка',
  'UA0112069003': 'Рівне',
  'UA0112069001': 'Сусаніне',
  'UA0112005003': 'Дмитрівка',
  'UA0112005002': 'Відкрите',
  'UA0112005001': 'Войкове',
  'UA0112007008': 'Чапаєве',
  'UA0112007003': 'Доходне',
  'UA0112007006': 'Климове',
  'UA0112007007': 'Нахімове',
  'UA0112007009': 'Новосільці',
  'UA0112007010': 'Плодородне',
  'UA0112007002': 'Володимирове',
  'UA0112007001': 'Восход',
  'UA0112007004': 'Знам\'янка',
  'UA0112007005': 'Зоря',
  'UA0112073003': 'Григорівка',
  'UA0112073002': 'Красний Партизан',
  'UA0112073004': 'Удачне',
  'UA0112073001': 'Янтарне',
  'UA0112013002': 'Новокатеринівка',
  'UA0112013001': 'Зернове',
  'UA0114001001': 'Армянськ',
  'UA0114003001': 'Березівка',
  'UA0114003002': 'Нива',
  'UA0114003003': 'Ульяновка',
  'UA0114005001': 'Ботанічне',
  'UA0114005003': 'Червоне',
  'UA0114005002': 'Кумове',
  'UA0114007001': 'Братське',
  'UA0114007002': 'Полтавське',
  'UA0114007003': 'Сватове',
  'UA0114053001': 'Чернишове',
  'UA0114053002': 'Кропоткіне',
  'UA0114053003': 'Портове',
  'UA0114051001': 'Філатівка',
  'UA0114051002': 'Карпова Балка',
  'UA0114015001': 'Іллінка',
  'UA0114015003': 'Курганне',
  'UA0114015004': 'Трактове',
  'UA0114015002': 'Воронцівка',
  'UA0114017001': 'Ішунь',
  'UA0114017003': 'Новорибацьке',
  'UA0114017002': 'Пролетарка',
  'UA0114017004': 'Танкове',
  'UA0114019001': 'Ковильне',
  'UA0114019004': 'Молочне',
  'UA0114019005': 'Сінокісне',
  'UA0114019002': 'Вітрянка',
  'UA0114019003': 'Волочаївка',
  'UA0114021001': 'Красноармійське',
  'UA0114021002': 'Надеждине',
  'UA0114021003': 'Смушкине',
  'UA0114023001': 'Красноперекопськ',
  'UA0114025001': 'Кукушкіне',
  'UA0114025002': 'Вогні',
  'UA0114027002': 'Богачівка',
  'UA0114027001': 'Магазинка',
  'UA0114027003': 'Новоіванівка',
  'UA0114027004': 'Новоолександрівка',
  'UA0114029002': 'Долинка',
  'UA0114029001': 'Новопавлівка',
  'UA0114029003': 'Привільне',
  'UA0114031001': 'Новоселівське',
  'UA0114031002': 'Сєверне',
  'UA0114033003': 'Новомиколаївка',
  'UA0114033001': 'Орлівське',
  'UA0114033004': 'Шатри',
  'UA0114033002': 'Знам\'янка',
  'UA0114035002': 'П\'ятихатка',
  'UA0114035001': 'Почетне',
  'UA0114037001': 'Роздольне',
  'UA0114039006': 'Федорівка',
  'UA0114039002': 'Городнє',
  'UA0114039003': 'Комунарне',
  'UA0114039004': 'Комишне',
  'UA0114039005': 'Максимівка',
  'UA0114039001': 'Руч\'ї',
  'UA0114041002': 'Бахчівка',
  'UA0114041006': 'Чехове',
  'UA0114041003': 'Каштанівка',
  'UA0114041004': 'Орлівка',
  'UA0114041001': 'Серебрянка',
  'UA0114041005': 'Соколи',
  'UA0114043002': 'Котовське',
  'UA0114043003': 'Рилєєвка',
  'UA0114043001': 'Славне',
  'UA0114043004': 'Стерегуще',
  'UA0114045002': 'Аврора',
  'UA0114045001': 'Слов\'янське',
  'UA0114047002': 'Рисове',
  'UA0114047001': 'Совхозне',
  'UA0114047003': 'Таврійське',
  'UA0114049003': 'Перекоп',
  'UA0114049001': 'Суворове',
  'UA0114049002': 'Волошине',
  'UA0114011002': 'Істочне',
  'UA0114011001': 'Воїнка',
  'UA0114009003': 'Кріпке',
  'UA0114009004': 'Уткіне',
  'UA0114009001': 'Вишнівка',
  'UA0114009002': 'Зелена Нива',
  'UA0114013003': 'Красноармійське',
  'UA0114013004': 'Овражне',
  'UA0114013002': 'Воронки',
  'UA0114013001': 'Зимине',
  'UA0116043001': 'Чистеньке',
  'UA0116043007': 'Фонтани',
  'UA0116043002': 'Комишинка',
  'UA0116043003': 'Левадки',
  'UA0116043004': 'Новозбур\'ївка',
  'UA0116043006': 'Трудолюбове',
  'UA0116043005': 'Трипрудне',
  'UA0116005002': 'Андрусове',
  'UA0116005012': 'Чайковське',
  'UA0116005001': 'Добре',
  'UA0116005011': 'Ферсманове',
  'UA0116005005': 'Краснолісся',
  'UA0116005006': 'Лозове',
  'UA0116005007': 'Мраморне',
  'UA0116005008': 'Перевальне',
  'UA0116005009': 'Петропавлівка',
  'UA0116005003': 'Піонерське',
  'UA0116005010': 'Привільне',
  'UA0116005004': 'Зарічне',
  'UA0116007007': 'Давидове',
  'UA0116007003': 'Дмитрове',
  'UA0116007001': 'Донське',
  'UA0116007004': 'Кленівка',
  'UA0116007005': 'Нижньокурганне',
  'UA0116007006': 'Спокійне',
  'UA0116007002': 'Верхньокурганне',
  'UA0116003002': 'Аерофлотський',
  'UA0116003004': 'Бітумне',
  'UA0116003001': 'Гресівський',
  'UA0116003003': 'Комсомольське',
  'UA0116001001': 'Гвардійське',
  'UA0116001002': 'Красна Зорька',
  'UA0116001003': 'Маленьке',
  'UA0116001004': 'Новий Сад',
  'UA0116001005': 'Софіївка',
  'UA0116011001': 'Кольчугине',
  'UA0116011002': 'Прудове',
  'UA0116011003': 'Рівнопілля',
  'UA0116013002': 'Краснівка',
  'UA0116013003': 'Лісносілля',
  'UA0116013001': 'Мазанка',
  'UA0116013004': 'Опушки',
  'UA0116013005': 'Солов\'ївка',
  'UA0116019001': 'Молодіжне',
  'UA0116019002': 'Сонячне',
  'UA0116015003': 'Ключове',
  'UA0116015001': 'Миколаївка',
  'UA0116015004': 'Олександрівка',
  'UA0116015005': 'Петрівка',
  'UA0116015006': 'Роздолля',
  'UA0116015007': 'Теплівка',
  'UA0116015002': 'Вінницьке',
  'UA0116017002': 'Білоглинка',
  'UA0116017003': 'Грушеве',
  'UA0116017001': 'Мирне',
  'UA0116021003': 'Харитонівка',
  'UA0116021001': 'Новоандріївка',
  'UA0116021002': 'Сухоріччя',
  'UA0116023001': 'Новоселівка',
  'UA0116027003': 'Дубки',
  'UA0116027005': 'Каштанове',
  'UA0116027008': 'Ключі',
  'UA0116027007': 'Клинівка',
  'UA0116027009': 'Костянтинівка',
  'UA0116027006': 'Кизилове',
  'UA0116027010': 'Молочне',
  'UA0116027011': 'Новомиколаївка',
  'UA0116027012': 'Обрив',
  'UA0116027013': 'Партизанське',
  'UA0116027001': 'Перове',
  'UA0116027014': 'Тепле',
  'UA0116027015': 'Топольне',
  'UA0116027016': 'Українка',
  'UA0116027002': 'Веселе',
  'UA0116027004': 'Залісся',
  'UA0116025003': 'Чайкине',
  'UA0116025002': 'Красне',
  'UA0116025001': 'Первомайське',
  'UA0116029003': 'Дем\'янівка',
  'UA0116029004': 'Лікарственне',
  'UA0116029001': 'Пожарське',
  'UA0116029002': 'Водне',
  'UA0116031002': 'Аркадієвка',
  'UA0116031003': 'Кубанське',
  'UA0116031004': 'Курганне',
  'UA0116031005': 'Новий Мир',
  'UA0116031001': 'Родникове',
  'UA0116031006': 'Шафранне',
  'UA0116047001': 'Шкільне',
  'UA0116045002': 'Дивне',
  'UA0116045003': 'Купріне',
  'UA0116045004': 'Пролітне',
  'UA0116045001': 'Широке',
  'UA0116033002': 'Аграрне',
  'UA0116033001': 'Сімферополь',
  'UA0116035002': 'Колодязне',
  'UA0116035003': 'Міжгірне',
  'UA0116035004': 'Передове',
  'UA0116035001': 'Скворцове',
  'UA0116037009': 'Айкаван',
  'UA0116037002': 'Акрополіс',
  'UA0116037003': 'Ана-Юрт',
  'UA0116037005': 'Денисівка',
  'UA0116037006': 'Дружне',
  'UA0116037007': 'Іванівка',
  'UA0116037004': 'Лазарівка',
  'UA0116037008': 'Строгонівка',
  'UA0116037001': 'Трудове',
  'UA0116039002': 'Совхозне',
  'UA0116039001': 'Укромне',
  'UA0116041001': 'Урожайне',
  'UA0116041002': 'Живописне',
  'UA0116009002': 'Сторожеве',
  'UA0116009003': 'Сумське',
  'UA0116009001': 'Журавлівка',
  'UA0120001001': 'Алупка',
  'UA0120003001': 'Алушта',
  'UA0120029001': 'Форос',
  'UA0120029003': 'Олива',
  'UA0120029002': 'Санаторне',
  'UA0120005001': 'Гаспра',
  'UA0120007003': 'Данилівка',
  'UA0120007001': 'Гурзуф',
  'UA0120007002': 'Краснокам\'янка',
  'UA0120007004': 'Лінійне',
  'UA0120007005': 'Партизанське',
  'UA0120009001': 'Ізобільне',
  'UA0120009003': 'Нижня Кутузовка',
  'UA0120009004': 'Розовий',
  'UA0120009002': 'Верхня Кутузовка',
  'UA0120011001': 'Кореїз',
  'UA0120013006': 'Гірне',
  'UA0120013007': 'Куйбишеве',
  'UA0120013003': 'Курпати',
  'UA0120013001': 'Лівадія',
  'UA0120013008': 'Охотниче',
  'UA0120013004': 'Ореанда',
  'UA0120013002': 'Виноградне',
  'UA0120013005': 'Високогірне',
  'UA0120015002': 'Лаванда',
  'UA0120015001': 'Лучисте',
  'UA0120015003': 'Семидвір\'я',
  'UA0120017009': 'Бондаренкове',
  'UA0120017011': 'Чайка',
  'UA0120017004': 'Кипарисне',
  'UA0120017005': 'Лаврове',
  'UA0120017006': 'Лазурне',
  'UA0120017001': 'Малий Маяк',
  'UA0120017007': 'Нижнє Запрудне',
  'UA0120017008': 'Пушкіне',
  'UA0120017010': 'Утьос',
  'UA0120017002': 'Виноградний',
  'UA0120017003': 'Запрудне',
  'UA0120019002': 'Генеральське',
  'UA0120019001': 'Малоріченське',
  'UA0120019003': 'Рибаче',
  'UA0120019004': 'Сонячногірське',
  'UA0120021001': 'Масандра',
  'UA0120021005': 'Нікіта',
  'UA0120021004': 'Совєтське',
  'UA0120021002': 'Відрадне',
  'UA0120021003': 'Восход',
  'UA0120023001': 'Партеніт',
  'UA0120025001': 'Привітне',
  'UA0120025002': 'Зеленогір\'я',
  'UA0120027002': 'Берегове',
  'UA0120027003': 'Голуба Затока',
  'UA0120027004': 'Кацівелі',
  'UA0120027007': 'Оползневе',
  'UA0120027005': 'Паркове',
  'UA0120027006': 'Понизівка',
  'UA0120027001': 'Сімеїз',
  'UA0120031001': 'Ялта',
  'UA0108077001': 'Чорноморське',
  'UA0108011001': 'Далеке',
  'UA0108011005': 'Сєверне',
  'UA0108011002': 'Володимирівка',
  'UA0108011003': 'Журавлівка',
  'UA0108011004': 'Зоряне',
  'UA0108013001': 'Добрушине',
  'UA0108013003': 'Ізвесткове',
  'UA0108013005': 'Шалаші',
  'UA0108013004': 'Солдатське',
  'UA0108013002': 'Єлизаветове',
  'UA0108075001': 'Фрунзе',
  'UA0108009001': 'Геройське',
  'UA0108009002': 'Ярке',
  'UA0108021001': 'Іванівка',
  'UA0108021002': 'Жайворонки',
  'UA0108023002': 'Дозорне',
  'UA0108023001': 'Кіровське',
  'UA0108023004': 'Низівка',
  'UA0108023003': 'Задорне',
  'UA0108025001': 'Кольцове',
  'UA0108025003': 'Нива',
  'UA0108025002': 'Вогневе',
  'UA0108027001': 'Крайнє',
  'UA0108027003': 'Трудове',
  'UA0108027002': 'Вершинне',
  'UA0108031001': 'Красноярське',
  'UA0108031002': 'Ленське',
  'UA0108029001': 'Красна Поляна',
  'UA0108029003': 'Кузнецьке',
  'UA0108029002': 'Внукове',
  'UA0108033003': 'Ігорівка',
  'UA0108033001': 'Кримське',
  'UA0108033004': 'Степове',
  'UA0108033002': 'Валентинове',
  'UA0108035003': 'Гаршине',
  'UA0108035004': 'Куликівка',
  'UA0108035001': 'Ліснівка',
  'UA0108035005': 'Прибережне',
  'UA0108035002': 'Володимирівка',
  'UA0108037001': 'Медведеве',
  'UA0108037002': 'Озерівка',
  'UA0108043001': 'Міжводне',
  'UA0108043004': 'Новоульяновка',
  'UA0108043005': 'Сніжне',
  'UA0108043002': 'Водопійне',
  'UA0108043003': 'Зайцеве',
  'UA0108045002': 'Абрикосівка',
  'UA0108045001': 'Молочне',
  'UA0108045003': 'Вітине',
  'UA0108039001': 'Мирний',
  'UA0108041002': 'Долинка',
  'UA0108041004': 'Листове',
  'UA0108041001': 'Митяєве',
  'UA0108041005': 'Шовковичне',
  'UA0108041003': 'Журавлі',
  'UA0108053001': 'Новофедорівка',
  'UA0108047002': 'Хмельове',
  'UA0108047001': 'Новоіванівка',
  'UA0108049001': 'Новоозерне',
  'UA0108051002': 'Артемівка',
  'UA0108051001': 'Новосільське',
  'UA0108061002': 'Громовка',
  'UA0108061003': 'Кар\'єрне',
  'UA0108061004': 'Наумівка',
  'UA0108061001': 'Охотникове',
  'UA0108061005': 'Орлянка',
  'UA0108061006': 'Рунне',
  'UA0108055002': 'Громове',
  'UA0108055004': 'Красносільське',
  'UA0108055005': 'Мар\'їне',
  'UA0108055001': 'Окунівка',
  'UA0108055003': 'Знам\'янське',
  'UA0108057002': 'Калинівка',
  'UA0108057003': 'Маяк',
  'UA0108057001': 'Оленівка',
  'UA0108059003': 'Червоне',
  'UA0108059004': 'Чоботарка',
  'UA0108059002': 'Михайлівка',
  'UA0108059001': 'Оріхове',
  'UA0108063002': 'Колоски',
  'UA0108063001': 'Ромашкине',
  'UA0108065001': 'Саки',
  'UA0108079005': 'Хуторок',
  'UA0108079002': 'Криловка',
  'UA0108079003': 'Попівка',
  'UA0108079004': 'Привітне',
  'UA0108079001': 'Штормове',
  'UA0108069002': 'Лушине',
  'UA0108069001': 'Стовпове',
  'UA0108071004': 'Каменоломня',
  'UA0108071005': 'Лиманне',
  'UA0108071006': 'Побєдне',
  'UA0108071001': 'Суворовське',
  'UA0108071007': 'Тунельне',
  'UA0108071002': 'Велике',
  'UA0108071003': 'Жовтокам\'янка',
  'UA0108067003': 'Іллінка',
  'UA0108067004': 'Лугове',
  'UA0108067001': 'Сизівка',
  'UA0108067002': 'Водопійне',
  'UA0108073001': 'Уютне',
  'UA0108001002': 'Глінка',
  'UA0108001001': 'Вересаєве',
  'UA0108003003': 'Наташине',
  'UA0108003004': 'Порфирівка',
  'UA0108003001': 'Веселівка',
  'UA0108003002': 'Владне',
  'UA0108007002': 'Фурманове',
  'UA0108007004': 'Шаумян',
  'UA0108007003': 'Шишкине',
  'UA0108007001': 'Воробйове',
  'UA0108005002': 'Вітрівка',
  'UA0108005001': 'Виноградове',
  'UA0108015001': 'Євпаторія',
  'UA0108017001': 'Заозерне',
  'UA0108019002': 'Низинне',
  'UA0108019001': 'Зернове',
  'UA7108001001': 'Балаклея',
  'UA7108001002': 'Будки',
  'UA7108001003': 'Костянтинівка',
  'UA7108001004': 'Мале Старосілля',
  'UA7108001005': 'Плоске',
  'UA7108001006': 'Теклине',
  'UA7108003001': 'Березняки',
  'UA7108003003': 'Плескачівка',
  'UA7108003005': 'Шевченка',
  'UA7108003004': 'Сунки',
  'UA7108003002': 'Велика Яблунівка',
  'UA7108005003': 'Баси',
  'UA7108005001': 'Білозір\'я',
  'UA7108005002': 'Ірдинь',
  'UA7108007002': 'Бересняги',
  'UA7108007001': 'Бобриця',
  'UA7108007003': 'Бучак',
  'UA7108007022': 'Черниші',
  'UA7108007004': 'Глинча',
  'UA7108007005': 'Григорівка',
  'UA7108007006': 'Грищинці',
  'UA7108007007': 'Іваньків',
  'UA7108007008': 'Ковалі',
  'UA7108007009': 'Козарівка',
  'UA7108007010': 'Курилівка',
  'UA7108007011': 'Лазірці',
  'UA7108007013': 'Луковиця',
  'UA7108007012': 'Литвинець',
  'UA7108007023': 'Орловець',
  'UA7108007015': 'Потапці',
  'UA7108007016': 'Пшеничники',
  'UA7108007014': 'Пищальники',
  'UA7108007024': 'Райок',
  'UA7108007018': 'Студенець',
  'UA7108007017': 'Синявка',
  'UA7108007019': 'Трахтемирів',
  'UA7108007021': 'Трощин',
  'UA7108007020': 'Тростянець',
  'UA7108009001': 'Будище',
  'UA7108009003': 'Лозівок',
  'UA7108009004': 'Новоселівка',
  'UA7108009006': 'Сокирна',
  'UA7108009005': 'Свидівок',
  'UA7108009002': 'Єлизаветівка',
  'UA7108049001': 'Черкаси',
  'UA7108049002': 'Оршанець',
  'UA7108047001': 'Червона Слобода',
  'UA7108047004': 'Хутори',
  'UA7108047003': 'Нечаївка',
  'UA7108047002': 'Вергуни',
  'UA7108051021': 'Бурякове',
  'UA7108051025': 'Чернече',
  'UA7108051020': 'Чмирівка',
  'UA7108051001': 'Чигирин',
  'UA7108051005': 'Галаганівка',
  'UA7108051022': 'Гненне',
  'UA7108051006': 'Іванівка',
  'UA7108051007': 'Красносілля',
  'UA7108051023': 'Кудашеве',
  'UA7108051008': 'Матвіївка',
  'UA7108051009': 'Новоселиця',
  'UA7108051010': 'Погорільці',
  'UA7108051011': 'Полуднівка',
  'UA7108051012': 'Рацеве',
  'UA7108051013': 'Розсошинці',
  'UA7108051014': 'Рублівка',
  'UA7108051024': 'Скаржинка',
  'UA7108051015': 'Стецівка',
  'UA7108051016': 'Суботів',
  'UA7108051017': 'Тарасо-Григорівка',
  'UA7108051018': 'Тіньки',
  'UA7108051019': 'Трушівці',
  'UA7108051002': 'Вдовичине',
  'UA7108051003': 'Вершаці',
  'UA7108051004': 'Вітове',
  'UA7108011004': 'Дирдин',
  'UA7108011001': 'Городище',
  'UA7108011005': 'Калинівка',
  'UA7108011010': 'Хлистунівка',
  'UA7108011006': 'Ксаверове',
  'UA7108011007': 'Набоків',
  'UA7108011008': 'Орловець',
  'UA7108011009': 'Петропавлівка',
  'UA7108011002': 'Цвіткове',
  'UA7108011003': 'Валява',
  'UA7108013002': 'Баландине',
  'UA7108013015': 'Богданівське',
  'UA7108013004': 'Грушківка',
  'UA7108013016': 'Калинівка',
  'UA7108013001': 'Кам\'янка',
  'UA7108013005': 'Катеринівка',
  'UA7108013007': 'Коханівка',
  'UA7108013017': 'Копійчана',
  'UA7108013006': 'Косарі',
  'UA7108013008': 'Лебедівка',
  'UA7108013009': 'Лузанівка',
  'UA7108013010': 'Олянине',
  'UA7108013011': 'Радиванівка',
  'UA7108013012': 'Телепине',
  'UA7108013013': 'Тимошівка',
  'UA7108013003': 'Вербівка',
  'UA7108013014': 'Юрчиха',
  'UA7108015002': 'Бабичі',
  'UA7108015003': 'Гамарня',
  'UA7108015001': 'Канів',
  'UA7108015009': 'Хмільна',
  'UA7108015010': 'Хутір-Хмільна',
  'UA7108015004': 'Кононча',
  'UA7108015005': 'Лука',
  'UA7108015006': 'Межиріч',
  'UA7108015007': 'Михайлівка',
  'UA7108015008': 'Пекарі',
  'UA7108015011': 'Яблунів',
  'UA7108017010': 'Берлютине',
  'UA7108017003': 'Гарбузин',
  'UA7108017001': 'Корсунь-Шевченківський',
  'UA7108017004': 'Моринці',
  'UA7108017005': 'Нехворощ',
  'UA7108017006': 'Пішки',
  'UA7108017007': 'Саморідня',
  'UA7108017009': 'Сотники',
  'UA7108017008': 'Ситники',
  'UA7108017002': 'Виграїв',
  'UA7108017011': 'Зелена Діброва',
  'UA7108019004': 'Чорнявка',
  'UA7108019005': 'Чубівка',
  'UA7108019002': 'Думанці',
  'UA7108019003': 'Худяки',
  'UA7108019001': 'Леськи',
  'UA7108021002': 'Келеберда',
  'UA7108021001': 'Ліпляве',
  'UA7108021003': 'Озерище',
  'UA7108021004': 'Прохорівка',
  'UA7108021005': 'Сушки',
  'UA7108023008': 'Буда',
  'UA7108023003': 'Деменці',
  'UA7108023002': 'Головківка',
  'UA7108023005': 'Івківці',
  'UA7108023007': 'Худоліївка',
  'UA7108023001': 'Медведівка',
  'UA7108023006': 'Мельники',
  'UA7108023009': 'Скелівка',
  'UA7108023004': 'Зам\'ятниця',
  'UA7108027002': 'Будо-Орловецька',
  'UA7108027001': 'Мліїв',
  'UA7108027003': 'Старосілля',
  'UA7108029002': 'Байбузи',
  'UA7108029003': 'Березняки',
  'UA7108029004': 'Гута Межиріцька',
  'UA7108029005': 'Гута Станіславчицька',
  'UA7108029011': 'Хрещатик',
  'UA7108029006': 'Кумейки',
  'UA7108029001': 'Мошни',
  'UA7108029007': 'Первомайське',
  'UA7108029012': 'Шелепухи',
  'UA7108029008': 'Софіївка',
  'UA7108029009': 'Станіславчик',
  'UA7108029010': 'Тубільці',
  'UA7108029013': 'Яснозір\'я',
  'UA7108029014': 'Закревки',
  'UA7108025009': 'Флярківка',
  'UA7108025011': 'Грекове',
  'UA7108025003': 'Куликівка',
  'UA7108025012': 'Лісове',
  'UA7108025004': 'Лубенці',
  'UA7108025001': 'Михайлівка',
  'UA7108025005': 'Пляківка',
  'UA7108025006': 'Райгород',
  'UA7108025007': 'Ребедайлівка',
  'UA7108025008': 'Ревівка',
  'UA7108025013': 'Сокирне',
  'UA7108025010': 'Ярове',
  'UA7108025002': 'Жаботин',
  'UA7108031002': 'Бровахи',
  'UA7108031003': 'Буда-Бровахівська',
  'UA7108031013': 'Червоне',
  'UA7108031004': 'Деренковець',
  'UA7108031005': 'Драбівка',
  'UA7108031007': 'Корнилівка',
  'UA7108031006': 'Кичинці',
  'UA7108031008': 'Мірошниківка',
  'UA7108031001': 'Набутів',
  'UA7108031009': 'Нетеребка',
  'UA7108031010': 'Паськів',
  'UA7108031011': 'Сахнівка',
  'UA7108031012': 'Сахнівське',
  'UA7108033002': 'Буда-Макіївка',
  'UA7108033003': 'Ковалиха',
  'UA7108033004': 'Куцівка',
  'UA7108033005': 'Ленське',
  'UA7108033006': 'Макіївка',
  'UA7108033007': 'Мельниківка',
  'UA7108033008': 'Носачів',
  'UA7108033001': 'Ротмістрівка',
  'UA7108033009': 'Самгородок',
  'UA7108033010': 'Санжариха',
  'UA7108033013': 'Степок',
  'UA7108033011': 'Ташлик',
  'UA7108033012': 'Вовківка',
  'UA7108035003': 'Дубіївка',
  'UA7108035002': 'Геронимівка',
  'UA7108035001': 'Руська Поляна',
  'UA7108037002': 'Боровиця',
  'UA7108037001': 'Сагунівка',
  'UA7108037003': 'Топилівка',
  'UA7108039002': 'Ірдинівка',
  'UA7108039001': 'Сміла',
  'UA7108041002': 'Беркозівка',
  'UA7108041003': 'Буда-Горобіївська',
  'UA7108041006': 'Дарівка',
  'UA7108041004': 'Голяки',
  'UA7108041005': 'Горобіївка',
  'UA7108041007': 'Ключники',
  'UA7108041008': 'Копіювата',
  'UA7108041020': 'Копіювате',
  'UA7108041009': 'Лізки',
  'UA7108041010': 'Малий Ржавець',
  'UA7108041011': 'Мартинівка',
  'UA7108041012': 'Мельники',
  'UA7108041013': 'Новоукраїнка',
  'UA7108041014': 'Павлівка',
  'UA7108041016': 'Полствин',
  'UA7108041017': 'Попівка',
  'UA7108041018': 'Поташня',
  'UA7108041015': 'Пилява',
  'UA7108041021': 'Степанецьке',
  'UA7108041001': 'Степанці',
  'UA7108041019': 'Таганча',
  'UA7108043002': 'Бузуків',
  'UA7108043003': 'Голов\'ятине',
  'UA7108043004': 'Гуляйгородок',
  'UA7108043007': 'Хацьки',
  'UA7108043006': 'Малий Бузуків',
  'UA7108043001': 'Степанки',
  'UA7108043005': 'Залевки',
  'UA7108045007': 'Богунове',
  'UA7108045009': 'Червоне',
  'UA7108045008': 'Холоднянське',
  'UA7108045002': 'Мала Смілянка',
  'UA7108045003': 'Миколаївка',
  'UA7108045004': 'Пастирське',
  'UA7108045005': 'Попівка',
  'UA7108045006': 'Сердюківка',
  'UA7108045001': 'Тернівка',
  'UA7106001002': 'Аполянка',
  'UA7106001001': 'Бабанка',
  'UA7106001005': 'Дубова',
  'UA7106001007': 'Коржова Слобода',
  'UA7106001006': 'Коржова',
  'UA7106001008': 'Коржовий Кут',
  'UA7106001009': 'Оксанина',
  'UA7106001010': 'Острівець',
  'UA7106001011': 'Рогова',
  'UA7106001012': 'Свинарка',
  'UA7106001003': 'Вільшана-Слобідка',
  'UA7106001004': 'Вільшанка',
  'UA7106003001': 'Баштечки',
  'UA7106003002': 'Королівка',
  'UA7106003008': 'Костянтинівка',
  'UA7106003003': 'Нагірна',
  'UA7106003004': 'Охматів',
  'UA7106003005': 'Павлівка',
  'UA7106003006': 'Побійна',
  'UA7106003007': 'Тинівка',
  'UA7106005002': 'Багва',
  'UA7106005001': 'Буки',
  'UA7106005008': 'Червоний Кут',
  'UA7106005004': 'Кути',
  'UA7106005003': 'Кислин',
  'UA7106005005': 'Нова Гребля',
  'UA7106005006': 'Русалівка',
  'UA7106005007': 'Улянівка',
  'UA7106007001': 'Дмитрушки',
  'UA7106007004': 'Доброводи',
  'UA7106007002': 'Гереженівка',
  'UA7106007003': 'Гродзеве',
  'UA7106007006': 'Косенівка',
  'UA7106007007': 'Пугачівка',
  'UA7106007008': 'Собківка',
  'UA7106007009': 'Старі Бабани',
  'UA7106007010': 'Степківка',
  'UA7106007011': 'Сушківка',
  'UA7106007012': 'Танське',
  'UA7106007005': 'Заячківка',
  'UA7106011002': 'Березівка',
  'UA7106011005': 'Чорна Кам\'янка',
  'UA7106011001': 'Іваньки',
  'UA7106011003': 'Крачківка',
  'UA7106011004': 'Тимошівка',
  'UA7106011006': 'Юрпіль',
  'UA7106023003': 'Багачівка',
  'UA7106023004': 'Ботвинівка',
  'UA7106023027': 'Чайківка',
  'UA7106023010': 'Гребля',
  'UA7106023013': 'Івангород',
  'UA7106023031': 'Іванівка',
  'UA7106023033': 'Хасанівка',
  'UA7106023001': 'Христинівка',
  'UA7106023026': 'Христинівка',
  'UA7106023014': 'Козаче',
  'UA7106023015': 'Кузьмина Гребля',
  'UA7106023016': 'Ліщинівка',
  'UA7106023017': 'Мала Іванівка',
  'UA7106023018': 'Мала Севастянівка',
  'UA7106023019': 'Орадівка',
  'UA7106023020': 'Осітна',
  'UA7106023021': 'Пеніжкове',
  'UA7106023022': 'Розсішки',
  'UA7106023028': 'Шельпахівка',
  'UA7106023029': 'Шукайвода',
  'UA7106023032': 'Сичі',
  'UA7106023023': 'Сичівка',
  'UA7106023024': 'Талалаївка',
  'UA7106023025': 'Углуватка',
  'UA7106023005': 'Велика Севастянівка',
  'UA7106023006': 'Вербувата',
  'UA7106023002': 'Верхнячка',
  'UA7106023007': 'Веселівка',
  'UA7106023008': 'Вікторівка',
  'UA7106023009': 'Вільшанка',
  'UA7106023030': 'Ягубець',
  'UA7106023034': 'Яри',
  'UA7106023011': 'Заячківка',
  'UA7106023012': 'Зоряне',
  'UA7106013007': 'Фурманка',
  'UA7106013002': 'Городниця',
  'UA7106013003': 'Колодисте',
  'UA7106013001': 'Ладижинка',
  'UA7106013011': 'Малий Затишок',
  'UA7106013005': 'Ропотуха',
  'UA7106013004': 'Рижавка',
  'UA7106013008': 'Шарин',
  'UA7106013006': 'Текуча',
  'UA7106013009': 'Ятранівка',
  'UA7106013010': 'Затишок',
  'UA7106015003': 'Добра',
  'UA7106015002': 'Дзензелівка',
  'UA7106015015': 'Філіція',
  'UA7106015016': 'Харківка',
  'UA7106015005': 'Кривець',
  'UA7106015004': 'Кищенці',
  'UA7106015006': 'Мала Маньківка',
  'UA7106015001': 'Маньківка',
  'UA7106015007': 'Молодецьке',
  'UA7106015008': 'Нестерівка',
  'UA7106015009': 'Паланочка',
  'UA7106015010': 'Подібна',
  'UA7106015011': 'Поминик',
  'UA7106015012': 'Попівка',
  'UA7106015013': 'Поташ',
  'UA7106015014': 'Роги',
  'UA7106015019': 'Рудка',
  'UA7106015018': 'Зелений Гай',
  'UA7106015017': 'Жолудькове',
  'UA7106017004': 'Антоніна',
  'UA7106017003': 'Аврамівка',
  'UA7106017005': 'Бачкурине',
  'UA7106017006': 'Бубельня',
  'UA7106017008': 'Дібрівка',
  'UA7106017009': 'Долинка',
  'UA7106017012': 'Івахни',
  'UA7106017033': 'Халаїдове',
  'UA7106017034': 'Хейлове',
  'UA7106017013': 'Княжа Криниця',
  'UA7106017014': 'Княжики',
  'UA7106017015': 'Копіювата',
  'UA7106017016': 'Коритня',
  'UA7106017040': 'Кудинів Ліс',
  'UA7106017017': 'Леськове',
  'UA7106017018': 'Летичівка',
  'UA7106017019': 'Лукашівка',
  'UA7106017020': 'Матвіїха',
  'UA7106017001': 'Монастирище',
  'UA7106017021': 'Нове Місто',
  'UA7106017022': 'Новосілка',
  'UA7106017023': 'Панський Міст',
  'UA7106017041': 'Покровка',
  'UA7106017024': 'Половинчик',
  'UA7106017025': 'Попудня',
  'UA7106017026': 'Сарни',
  'UA7106017027': 'Сатанівка',
  'UA7106017035': 'Шабастівка',
  'UA7106017036': 'Шарнопіль',
  'UA7106017028': 'Степівка',
  'UA7106017029': 'Тарасівка',
  'UA7106017030': 'Тарнава',
  'UA7106017031': 'Теолин',
  'UA7106017032': 'Терлиця',
  'UA7106017002': 'Цибулів',
  'UA7106017037': 'Вільна',
  'UA7106017007': 'Владиславчик',
  'UA7106017038': 'Володимирівка',
  'UA7106017039': 'Забіляни',
  'UA7106017010': 'Зарубинці',
  'UA7106017011': 'Зюбриха',
  'UA7106019002': 'Антонівка',
  'UA7106019003': 'Берестівець',
  'UA7106019016': 'Черповоди',
  'UA7106019004': 'Городецьке',
  'UA7106019005': 'Громи',
  'UA7106019006': 'Іванівка',
  'UA7106019007': 'Кочержинці',
  'UA7106019008': 'Кочубіївка',
  'UA7106019009': 'Краснопілка',
  'UA7106019010': 'Максимівка',
  'UA7106019001': 'Паланка',
  'UA7106019011': 'Піківець',
  'UA7106019012': 'Посухівка',
  'UA7106019013': 'Родниківка',
  'UA7106019014': 'Синиця',
  'UA7106019015': 'Томашівка',
  'UA7106019018': 'Яроватка',
  'UA7106019017': 'Юрківка',
  'UA7106021002': 'Полянецьке',
  'UA7106021001': 'Умань',
  'UA7106009028': 'Адамівка',
  'UA7106009002': 'Безпечна',
  'UA7106009003': 'Бузівка',
  'UA7106009026': 'Хижня',
  'UA7106009008': 'Конела',
  'UA7106009009': 'Конельська Попівка',
  'UA7106009010': 'Конельські Хутори',
  'UA7106009011': 'Кривчунка',
  'UA7106009012': 'Леміщиха',
  'UA7106009013': 'Литвинівка',
  'UA7106009014': 'Марійка',
  'UA7106009015': 'Медувата',
  'UA7106009016': 'Одай',
  'UA7106009017': 'Олександрівка',
  'UA7106009018': 'Острожани',
  'UA7106009019': 'Пугачівка',
  'UA7106009020': 'Сабадаш',
  'UA7106009027': 'Шуляки',
  'UA7106009021': 'Скибин',
  'UA7106009022': 'Соколівка',
  'UA7106009023': 'Сорокотяга',
  'UA7106009024': 'Тетерівка',
  'UA7106009025': 'Тихий Хутір',
  'UA7106009004': 'Вільшанка',
  'UA7106009005': 'Вороне',
  'UA7106009007': 'Зелений Ріг',
  'UA7106009001': 'Жашків',
  'UA7106009006': 'Житники',
  'UA7104019002': 'Бакаєве',
  'UA7104019003': 'Богодухівка',
  'UA7104019001': 'Чорнобай',
  'UA7104019022': 'Франківка',
  'UA7104019008': 'Григорівка',
  'UA7104019025': 'Іванівка',
  'UA7104019023': 'Хрестителеве',
  'UA7104019009': 'Красенівка',
  'UA7104019010': 'Лукашівка',
  'UA7104019011': 'Мала Бурімка',
  'UA7104019012': 'Малі Канівці',
  'UA7104019013': 'Мар\'янівка',
  'UA7104019015': 'Мохнач',
  'UA7104019014': 'Михайлівка',
  'UA7104019016': 'Нове Життя',
  'UA7104019017': 'Новоселиця',
  'UA7104019018': 'Новоукраїнка',
  'UA7104019026': 'Привітне',
  'UA7104019019': 'Савківка',
  'UA7104019020': 'Старий Мохнач',
  'UA7104019021': 'Тарасівка',
  'UA7104019004': 'Велика Бурімка',
  'UA7104019005': 'Великі Канівці',
  'UA7104019007': 'Веселий Хутір',
  'UA7104019006': 'Веселий Поділ',
  'UA7104019024': 'Вишнівка',
  'UA7104007002': 'Білоусівка',
  'UA7104007003': 'Бойківщина',
  'UA7104007001': 'Драбів',
  'UA7104007017': 'Драбове-Барятинське',
  'UA7104007006': 'Козаче',
  'UA7104007018': 'Козорізи',
  'UA7104007007': 'Криштопівка',
  'UA7104007008': 'Левченкове',
  'UA7104007010': 'Михайлівка',
  'UA7104007009': 'Митлашівка',
  'UA7104007011': 'Нехайки',
  'UA7104007012': 'Олімпіадівка',
  'UA7104007013': 'Павлівщина',
  'UA7104007014': 'Перервинці',
  'UA7104007015': 'Привітне',
  'UA7104007016': 'Тополі',
  'UA7104007004': 'Жорнокльови',
  'UA7104007005': 'Золотоношка',
  'UA7104005002': 'Безпальче',
  'UA7104005003': 'Богдани',
  'UA7104005004': 'Броварки',
  'UA7104005001': 'Гельмязів',
  'UA7104005005': 'Каленики',
  'UA7104005007': 'Коврай Другий',
  'UA7104005006': 'Коврай',
  'UA7104005010': 'Ковтунівка',
  'UA7104005011': 'Малинівщина',
  'UA7104005008': 'Підставки',
  'UA7104005009': 'Плешкані',
  'UA7104005012': 'Шкодунівка',
  'UA7104013002': 'Баталий',
  'UA7104013024': 'Чехівка',
  'UA7104013022': 'Червоногірка',
  'UA7104013023': 'Червонохижинці',
  'UA7104013001': 'Іркліїв',
  'UA7104013008': 'Кліщинці',
  'UA7104013009': 'Коврай',
  'UA7104013010': 'Крутьки',
  'UA7104013012': 'Лящівка',
  'UA7104013011': 'Лихоліти',
  'UA7104013013': 'Мельники',
  'UA7104013014': 'Москаленки',
  'UA7104013026': 'Мирне',
  'UA7104013015': 'Першотравневе',
  'UA7104013016': 'Придніпровське',
  'UA7104013017': 'Ревбинці',
  'UA7104013018': 'Скородистик',
  'UA7104013019': 'Старий Коврай',
  'UA7104013020': 'Степове',
  'UA7104013021': 'Тимченки',
  'UA7104013003': 'Васютинці',
  'UA7104013004': 'Вереміївка',
  'UA7104013005': 'Воронинці',
  'UA7104013007': 'Загородище',
  'UA7104013006': 'Жовнине',
  'UA7104013025': 'Журавлине',
  'UA7104015002': 'Антипівка',
  'UA7104015003': 'Бакаївка',
  'UA7104015017': 'Дібрівка',
  'UA7104015005': 'Дмитрівка',
  'UA7104015006': 'Домантове',
  'UA7104015007': 'Драбівці',
  'UA7104015018': 'Холодне',
  'UA7104015008': 'Ковтуни',
  'UA7104015009': 'Львівка',
  'UA7104015010': 'Маркізівка',
  'UA7104015011': 'Матвіївка',
  'UA7104015012': 'Мелесівка',
  'UA7104015013': 'Мицалівка',
  'UA7104015001': 'Нова Дмитрівка',
  'UA7104015014': 'Подільське',
  'UA7104015015': 'Сеньківці',
  'UA7104015016': 'Скориківка',
  'UA7104015004': 'Вільхи',
  'UA7104017002': 'Бубнівська Слобідка',
  'UA7104017003': 'Гладківщина',
  'UA7104017008': 'Гладківщина',
  'UA7104017004': 'Коврайські Хутори',
  'UA7104017005': 'Нова Гребля',
  'UA7104017001': 'Піщане',
  'UA7104017007': 'Шабельники',
  'UA7104017006': 'Софіївка',
  'UA7104021020': 'Бондарівка',
  'UA7104021002': 'Бирлівка',
  'UA7104021023': 'Червона Дача',
  'UA7104021005': 'Демки',
  'UA7104021003': 'Гай',
  'UA7104021004': 'Гречанівка',
  'UA7104021006': 'Кантакузівка',
  'UA7104021018': 'Хомівщина',
  'UA7104021009': 'Коломиці',
  'UA7104021010': 'Кононівка',
  'UA7104021022': 'Кононівка',
  'UA7104021007': 'Ковалівка',
  'UA7104021008': 'Ковтунівка',
  'UA7104021011': 'Мойсівка',
  'UA7104021012': 'Новомиколаївка',
  'UA7104021013': 'Остапівка',
  'UA7104021014': 'Перше Травня',
  'UA7104021015': 'Погреби',
  'UA7104021001': 'Шрамківка',
  'UA7104021017': 'Степанівка',
  'UA7104021016': 'Свічківка',
  'UA7104021021': 'Вишневе',
  'UA7104021019': 'Яворівка',
  'UA7104001004': 'Ашанівка',
  'UA7104001002': 'Безбородьки',
  'UA7104001005': 'Рецюківщина',
  'UA7104001003': 'Рождественське',
  'UA7104001001': 'Великий Хутір',
  'UA7104003002': 'Богуславець',
  'UA7104003005': 'Канівщина',
  'UA7104003006': 'Пальміра',
  'UA7104003003': 'Привітне',
  'UA7104003007': 'Роздол',
  'UA7104003008': 'Степове',
  'UA7104003004': 'Синьооківка',
  'UA7104003001': 'Вознесенське',
  'UA7104009002': 'Благодатне',
  'UA7104009003': 'Деньги',
  'UA7104009013': 'Гришківка',
  'UA7104009005': 'Кедина Гора',
  'UA7104009011': 'Хвильово-Сорочин',
  'UA7104009006': 'Комарівка',
  'UA7104009007': 'Коробівка',
  'UA7104009008': 'Кропивна',
  'UA7104009009': 'Крупське',
  'UA7104009010': 'Маліївка',
  'UA7104009012': 'Щербинівка',
  'UA7104009014': 'Снігурівка',
  'UA7104009015': 'Ярки',
  'UA7104009004': 'Згар',
  'UA7104009001': 'Золотоноша',
  'UA7104011002': 'Богданівка',
  'UA7104011008': 'Чернещина',
  'UA7104011007': 'Хрущівка',
  'UA7104011004': 'Кривоносівка',
  'UA7104011009': 'Квітневе',
  'UA7104011005': 'Лукашівка',
  'UA7104011006': 'Мехедівка',
  'UA7104011003': 'Вершина-Згарська',
  'UA7104011001': 'Зорівка',
  'UA7102001001': 'Бужанка',
  'UA7102001008': 'Дубина',
  'UA7102001003': 'Кам\'яний Брід',
  'UA7102001004': 'Кучківка',
  'UA7102001005': 'Погибляк',
  'UA7102001006': 'Тихонівка',
  'UA7102001007': 'Яблунівка',
  'UA7102001002': 'Жаб\'янка',
  'UA7102015002': 'Бродецьке',
  'UA7102015005': 'Гончариха',
  'UA7102015006': 'Грушківка',
  'UA7102015007': 'Гуляйполе',
  'UA7102015008': 'Кайтанівка',
  'UA7102015001': 'Катеринопіль',
  'UA7102015009': 'Кобилянка',
  'UA7102015011': 'Луківка',
  'UA7102015010': 'Лисича Балка',
  'UA7102015012': 'Новоселиця',
  'UA7102015021': 'Новоукраїнка',
  'UA7102015013': 'Пальчик',
  'UA7102015014': 'Петраківка',
  'UA7102015015': 'Потоки',
  'UA7102015017': 'Ромейково',
  'UA7102015016': 'Розсохуватка',
  'UA7102015019': 'Шостакове',
  'UA7102015018': 'Стійкове',
  'UA7102015003': 'Вербовець',
  'UA7102015004': 'Вікнине',
  'UA7102015020': 'Ямпіль',
  'UA7102017002': 'Антонівка',
  'UA7102017005': 'Глиняна Балка',
  'UA7102017006': 'Кавунівка',
  'UA7102017008': 'Коротине',
  'UA7102017007': 'Козачани',
  'UA7102017001': 'Лип\'янка',
  'UA7102017009': 'Маслове',
  'UA7102017010': 'Межигірка',
  'UA7102017011': 'Нечаєве',
  'UA7102017012': 'Нова Ярославка',
  'UA7102017003': 'Веселий Кут',
  'UA7102017004': 'Витязеве',
  'UA7102017013': 'Ярославка',
  'UA7102019002': 'Боярка',
  'UA7102019003': 'Будище',
  'UA7102019017': 'Чаплинка',
  'UA7102019018': 'Чеснівка',
  'UA7102019006': 'Дашуківка',
  'UA7102019007': 'Дібрівка',
  'UA7102019005': 'Ганжалівка',
  'UA7102019016': 'Хижинці',
  'UA7102019001': 'Лисянка',
  'UA7102019009': 'Орли',
  'UA7102019010': 'Петрівка-Попівка',
  'UA7102019011': 'Петрівська Гута',
  'UA7102019013': 'Почапинці',
  'UA7102019012': 'Писарівка',
  'UA7102019014': 'Семенівка',
  'UA7102019019': 'Шестеринці',
  'UA7102019020': 'Шушківка',
  'UA7102019015': 'Смільчинці',
  'UA7102019004': 'Верещаки',
  'UA7102019008': 'Журжинці',
  'UA7102021001': 'Матусів',
  'UA7102021002': 'Станіславчик',
  'UA7102023009': 'Червоний Брід',
  'UA7102023003': 'Киселівка',
  'UA7102023004': 'Любисток',
  'UA7102023001': 'Мокра Калигірка',
  'UA7102023005': 'Надвисся',
  'UA7102023006': 'Ступичне',
  'UA7102023007': 'Суха Калигірка',
  'UA7102023008': 'Ярошівка',
  'UA7102023002': 'Єлизаветка',
  'UA7102025016': 'Черепин',
  'UA7102025003': 'Глушки',
  'UA7102025004': 'Гута-Селицька',
  'UA7102025007': 'Карашина',
  'UA7102025009': 'Кошмак',
  'UA7102025008': 'Квітки',
  'UA7102025010': 'Листвина',
  'UA7102025011': 'Миропілля',
  'UA7102025012': 'Петрушки',
  'UA7102025001': 'Селище',
  'UA7102025013': 'Сухини',
  'UA7102025014': 'Тараща',
  'UA7102025015': 'Тихі Верби',
  'UA7102025002': 'Вільхівчик',
  'UA7102025006': 'Завадівка',
  'UA7102025005': 'Журавка',
  'UA7102031002': 'Боровикове',
  'UA7102031003': 'Будище',
  'UA7102031004': 'Демкове',
  'UA7102031007': 'Кононове-Івасів',
  'UA7102031005': 'Пединівка',
  'UA7102031008': 'Шампанія',
  'UA7102031001': 'Шевченкове',
  'UA7102031006': 'Тарасівка',
  'UA7102031009': 'Юркове',
  'UA7102033002': 'Бурти',
  'UA7102033005': 'Георгіївка',
  'UA7102033007': 'Іскрене',
  'UA7102033008': 'Кам\'януватка',
  'UA7102033009': 'Капустине',
  'UA7102033022': 'Ховківка',
  'UA7102033010': 'Кримки',
  'UA7102033011': 'Лебедин',
  'UA7102033012': 'Лозуватка',
  'UA7102033013': 'Мар\'янівка',
  'UA7102033014': 'Надточаївка',
  'UA7102033015': 'Сердегівка',
  'UA7102033001': 'Шпола',
  'UA7102033017': 'Скотареве',
  'UA7102033018': 'Соболівка',
  'UA7102033016': 'Сигнаївка',
  'UA7102033019': 'Терешки',
  'UA7102033021': 'Топильна',
  'UA7102033020': 'Товмач',
  'UA7102033003': 'Васильків',
  'UA7102033004': 'Водяне',
  'UA7102033006': 'Журавка',
  'UA7102027003': 'Дацьки',
  'UA7102027002': 'Гута-Стеблівська',
  'UA7102027016': 'Хлерівка',
  'UA7102027013': 'Хильки',
  'UA7102027005': 'Комарівка',
  'UA7102027006': 'Миколаївка',
  'UA7102027007': 'Нова Буда',
  'UA7102027008': 'Переможинці',
  'UA7102027009': 'Прутильці',
  'UA7102027014': 'Шендерівка',
  'UA7102027011': 'Склименці',
  'UA7102027012': 'Скрипчинці',
  'UA7102027001': 'Стеблів',
  'UA7102027010': 'Сидорівка',
  'UA7102027015': 'Яблунівка',
  'UA7102027004': 'Заріччя',
  'UA7102029002': 'Антонівка',
  'UA7102029003': 'Білашки',
  'UA7102029033': 'Червоне',
  'UA7102029034': 'Чеснопіль',
  'UA7102029036': 'Добрянка',
  'UA7102029009': 'Довгеньке',
  'UA7102029006': 'Глибочок',
  'UA7102029007': 'Гордашівка',
  'UA7102029008': 'Гуляйка',
  'UA7102029013': 'Кобринова Гребля',
  'UA7102029014': 'Кобринове',
  'UA7102029012': 'Кобиляки',
  'UA7102029015': 'Колодисте',
  'UA7102029016': 'Корсунка',
  'UA7102029017': 'Криві Коліна',
  'UA7102029018': 'Лащова',
  'UA7102029019': 'Легедзине',
  'UA7102029038': 'Левада',
  'UA7102029020': 'Лісове',
  'UA7102029021': 'Лоташеве',
  'UA7102029022': 'Майданецьке',
  'UA7102029023': 'Мошурів',
  'UA7102029039': 'Нова Павлівка',
  'UA7102029040': 'Новомайданецьке',
  'UA7102029024': 'Онопріївка',
  'UA7102029027': 'Папужинці',
  'UA7102029025': 'Павлівка Друга',
  'UA7102029026': 'Павлівка Перша',
  'UA7102029028': 'Піщана',
  'UA7102029029': 'Поташ',
  'UA7102029030': 'Романівка',
  'UA7102029043': 'Шалаське',
  'UA7102029035': 'Шаулиха',
  'UA7102029031': 'Соколівочка',
  'UA7102029041': 'Степне',
  'UA7102029032': 'Тальянки',
  'UA7102029001': 'Тальне',
  'UA7102029042': 'Тарасівка',
  'UA7102029004': 'Веселий Кут',
  'UA7102029005': 'Вишнопіль',
  'UA7102029010': 'Заліське',
  'UA7102029037': 'Здобуток',
  'UA7102029011': 'Зеленьків',
  'UA7102003004': 'Чичиркозівка',
  'UA7102003002': 'Скаливатка',
  'UA7102003003': 'Стецівка',
  'UA7102003001': 'Ватутіне',
  'UA7102003005': 'Юрківка',
  'UA7102007005': 'Дмитрове',
  'UA7102007010': 'Ільченкове',
  'UA7102007017': 'Хрестівка',
  'UA7102007011': 'Кличкове',
  'UA7102007012': 'Кудинівка',
  'UA7102007013': 'Моргунове',
  'UA7102007007': 'Петрики',
  'UA7102007014': 'Сагайдачне',
  'UA7102007008': 'Сегединці',
  'UA7102007015': 'Стадниця',
  'UA7102007009': 'Товста',
  'UA7102007016': 'Трихуторівка',
  'UA7102007002': 'Вербівка',
  'UA7102007004': 'В\'язівок',
  'UA7102007001': 'Вільшана',
  'UA7102007003': 'Воронівка',
  'UA7102007006': 'Зелена Діброва',
  'UA7102009002': 'Барвінок',
  'UA7102009010': 'Чемериське',
  'UA7102009011': 'Чижівка',
  'UA7102009003': 'Кобиляки',
  'UA7102009013': 'Михайлівка',
  'UA7102009004': 'Мизинівка',
  'UA7102009014': 'Олександрівка',
  'UA7102009005': 'Озірна',
  'UA7102009006': 'Попівка',
  'UA7102009007': 'Рижанівка',
  'UA7102009008': 'Ризине',
  'UA7102009012': 'Шубині Стави',
  'UA7102009009': 'Стара Буда',
  'UA7102009001': 'Водяники',
  'UA7102005002': 'Босівка',
  'UA7102005008': 'Федюківка',
  'UA7102005009': 'Мар\'янівка',
  'UA7102005004': 'Ріпки',
  'UA7102005005': 'Розкошівка',
  'UA7102005006': 'Рубаний Міст',
  'UA7102005007': 'Товсті Роги',
  'UA7102005003': 'Вотилівка',
  'UA7102005001': 'Виноград',
  'UA7102011003': 'Радчиха',
  'UA7102011001': 'Єрки',
  'UA7102011002': 'Залізнячка',
  'UA7102013002': 'Багачівка',
  'UA7102013004': 'Гнилець',
  'UA7102013005': 'Гудзівка',
  'UA7102013006': 'Гусакове',
  'UA7102013016': 'Хлипнівка',
  'UA7102013007': 'Княжа',
  'UA7102013008': 'Козацьке',
  'UA7102013009': 'Майданівка',
  'UA7102013011': 'Моринці',
  'UA7102013012': 'Мурзинці',
  'UA7102013010': 'Михайлівка',
  'UA7102013013': 'Неморож',
  'UA7102013014': 'Павлівка',
  'UA7102013015': 'Стебне',
  'UA7102013003': 'Вільховець',
  'UA7102013001': 'Звенигородка',
  'UA7410001001': 'Березна',
  'UA7410001002': 'Бігач',
  'UA7410001017': 'Домниця',
  'UA7410001003': 'Гориця',
  'UA7410001004': 'Гребля',
  'UA7410001005': 'Гусавка',
  'UA7410001006': 'Кам\'янка',
  'UA7410001007': 'Климентинівка',
  'UA7410001008': 'Локнисте',
  'UA7410001009': 'Лугове',
  'UA7410001011': 'Мощне',
  'UA7410001012': 'Мурівка',
  'UA7410001010': 'Миколаївка',
  'UA7410001013': 'Подин',
  'UA7410001014': 'Сахнівка',
  'UA7410001015': 'Святі Гори',
  'UA7410001016': 'Яськове',
  'UA7410039001': 'Чернігів',
  'UA7410007002': 'Бір',
  'UA7410007001': 'Десна',
  'UA7410007004': 'Карпилівка',
  'UA7410007005': 'Короп\'є',
  'UA7410007006': 'Косачівка',
  'UA7410007007': 'Лошакова Гута',
  'UA7410007008': 'Лутава',
  'UA7410007009': 'Морівськ',
  'UA7410007010': 'Отрохи',
  'UA7410007011': 'Рудня',
  'UA7410007012': 'Сорокошичі',
  'UA7410007013': 'Тужар',
  'UA7410007003': 'Виповзів',
  'UA7410009002': 'Аткильня',
  'UA7410009021': 'Чижівка',
  'UA7410009001': 'Добрянка',
  'UA7410009005': 'Горностаївка',
  'UA7410009006': 'Грибова Рудня',
  'UA7410009007': 'Гута-Ткачова',
  'UA7410009010': 'Клубівка',
  'UA7410009009': 'Киселівка',
  'UA7410009011': 'Лизунова Рудня',
  'UA7410009012': 'Нова Папірня',
  'UA7410009013': 'Нові Яриловичі',
  'UA7410009015': 'Олександрівка Друга',
  'UA7410009014': 'Олександрівка',
  'UA7410009016': 'Олешня',
  'UA7410009018': 'Скиток',
  'UA7410009019': 'Старі Яриловичі',
  'UA7410009020': 'Строївка',
  'UA7410009017': 'Сиделівка',
  'UA7410009003': 'Вербівка',
  'UA7410009004': 'Вир',
  'UA7410009008': 'Заводське',
  'UA7410003002': 'Боровики',
  'UA7410003003': 'Будище',
  'UA7410003001': 'Гончарівське',
  'UA7410003016': 'Хатилова Гута',
  'UA7410003007': 'Козероги',
  'UA7410003008': 'Лебедівка',
  'UA7410003009': 'Ліски',
  'UA7410003010': 'Лісне',
  'UA7410003012': 'Максим',
  'UA7410003011': 'Мажугівка',
  'UA7410003013': 'Слабин',
  'UA7410003014': 'Смолин',
  'UA7410003015': 'Соколівка',
  'UA7410003004': 'Василева Гута',
  'UA7410003005': 'Ворохівка',
  'UA7410003017': 'Якубівка',
  'UA7410003006': 'Жеведь',
  'UA7410005003': 'Альошинське',
  'UA7410005004': 'Андріївка',
  'UA7410005002': 'Автуничі',
  'UA7410005005': 'Барабанівське',
  'UA7410005006': 'Берилівка',
  'UA7410005007': 'Ближнє',
  'UA7410005008': 'Будище',
  'UA7410005009': 'Бутівка',
  'UA7410005057': 'Черецьке',
  'UA7410005016': 'День-Добрий',
  'UA7410005017': 'Деревини',
  'UA7410005019': 'Дібрівне',
  'UA7410005020': 'Дроздовиця',
  'UA7410005018': 'Диханівка',
  'UA7410005013': 'Гасичівка',
  'UA7410005014': 'Гніздище',
  'UA7410005001': 'Городня',
  'UA7410005015': 'Горошківка',
  'UA7410005023': 'Ільмівка',
  'UA7410005024': 'Карпівка',
  'UA7410005025': 'Картовецьке',
  'UA7410005054': 'Хоробичі',
  'UA7410005055': 'Хотівля',
  'UA7410005056': 'Хрипівка',
  'UA7410005026': 'Конотоп',
  'UA7410005028': 'Кусії',
  'UA7410005027': 'Кузничі',
  'UA7410005029': 'Лемешівка',
  'UA7410005030': 'Лютіж',
  'UA7410005031': 'Мальча',
  'UA7410005033': 'Моложава',
  'UA7410005035': 'Мощенка',
  'UA7410005034': 'Мости',
  'UA7410005032': 'Минаївщина',
  'UA7410005036': 'Невкля',
  'UA7410005037': 'Павло-Іванівське',
  'UA7410005038': 'Пекурівка',
  'UA7410005039': 'Перепис',
  'UA7410005040': 'Перерост',
  'UA7410005041': 'Півнівщина',
  'UA7410005042': 'Полісся',
  'UA7410005043': 'Політрудня',
  'UA7410005060': 'Рубіж',
  'UA7410005045': 'Сеньківка',
  'UA7410005046': 'Слобода',
  'UA7410005047': 'Смичин',
  'UA7410005048': 'Солонівка',
  'UA7410005049': 'Старосілля',
  'UA7410005050': 'Стовпівка',
  'UA7410005051': 'Студенець',
  'UA7410005052': 'Сутоки',
  'UA7410005044': 'Світанок',
  'UA7410005053': 'Травневе',
  'UA7410005010': 'Ваганичі',
  'UA7410005058': 'Вершини',
  'UA7410005011': 'Вокзал-Городня',
  'UA7410005012': 'Володимирівка',
  'UA7410005061': 'Ясенівка',
  'UA7410005021': 'Залісся',
  'UA7410005022': 'Здрягівка',
  'UA7410005059': 'Зелене',
  'UA7410011002': 'Анисів',
  'UA7410011003': 'Буди',
  'UA7410011005': 'Драчівщина',
  'UA7410011006': 'Друцьке',
  'UA7410011001': 'Іванівка',
  'UA7410011009': 'Количівка',
  'UA7410011010': 'Красне',
  'UA7410011011': 'Ладинка',
  'UA7410011012': 'Лукашівка',
  'UA7410011013': 'Підгірне',
  'UA7410011014': 'Піски',
  'UA7410011015': 'Скорінець',
  'UA7410011016': 'Слобода',
  'UA7410011004': 'Вікторівка',
  'UA7410011017': 'Ягідне',
  'UA7410011007': 'Єньків',
  'UA7410011008': 'Золотинка',
  'UA7410017002': 'Борсуків',
  'UA7410017017': 'Чемер',
  'UA7410017006': 'Димерка',
  'UA7410017004': 'Гайове',
  'UA7410017005': 'Гальчин',
  'UA7410017016': 'Хрещате',
  'UA7410017001': 'Кіпті',
  'UA7410017007': 'Копачів',
  'UA7410017008': 'Красилівка',
  'UA7410017009': 'Надинівка',
  'UA7410017010': 'Новий Шлях',
  'UA7410017011': 'Олбин',
  'UA7410017012': 'Підлісне',
  'UA7410017019': 'Прогрес',
  'UA7410017013': 'Розівка',
  'UA7410017015': 'Самійлівка',
  'UA7410017014': 'Савинка',
  'UA7410017018': 'Шпаків',
  'UA7410017003': 'Вовчок',
  'UA7410019002': 'Берлози',
  'UA7410019003': 'Білейки',
  'UA7410019004': 'Блудше',
  'UA7410019005': 'Бобруйки',
  'UA7410019006': 'Боярівка',
  'UA7410019007': 'Бригинці',
  'UA7410019008': 'Будище',
  'UA7410019009': 'Булахів',
  'UA7410019049': 'Часнівці',
  'UA7410019014': 'Данівка',
  'UA7410019010': 'Гарбузин',
  'UA7410019011': 'Гладке',
  'UA7410019012': 'Гламазди',
  'UA7410019013': 'Горбачі',
  'UA7410019054': 'Калитянське',
  'UA7410019018': 'Карасинівка',
  'UA7410019019': 'Карпоки',
  'UA7410019020': 'Корніїв',
  'UA7410019001': 'Козелець',
  'UA7410019021': 'Кривицьке',
  'UA7410019022': 'Курганське',
  'UA7410019023': 'Лемеші',
  'UA7410019024': 'Лихолітки',
  'UA7410019026': 'Мостище',
  'UA7410019025': 'Мирне',
  'UA7410019027': 'Нічогівка',
  'UA7410019028': 'Нова Гребля',
  'UA7410019029': 'Новики',
  'UA7410019031': 'Олексіївщина',
  'UA7410019032': 'Омелянів',
  'UA7410019033': 'Опеньки',
  'UA7410019030': 'Озерне',
  'UA7410019034': 'Патюти',
  'UA7410019037': 'Пісоцьке',
  'UA7410019036': 'Пізнє',
  'UA7410019038': 'Привітне',
  'UA7410019039': 'Пушкарі',
  'UA7410019035': 'Пилятин',
  'UA7410019040': 'Риків',
  'UA7410019041': 'Савин',
  'UA7410019050': 'Шами',
  'UA7410019051': 'Шапіхи',
  'UA7410019052': 'Шолойки',
  'UA7410019053': 'Шуляки',
  'UA7410019044': 'Скрипчин',
  'UA7410019045': 'Сокирин',
  'UA7410019046': 'Ставиське',
  'UA7410019043': 'Сираї',
  'UA7410019042': 'Сивухи',
  'UA7410019047': 'Тарасів',
  'UA7410019048': 'Тополі',
  'UA7410019015': 'Єрків',
  'UA7410019017': 'Закревське',
  'UA7410019016': 'Жеребецьке',
  'UA7410021002': 'Авдіївка',
  'UA7410021003': 'Бакланова Муравійка',
  'UA7410021004': 'Будище',
  'UA7410021012': 'Дрімайлівка',
  'UA7410021013': 'Дроздівка',
  'UA7410021009': 'Глузди',
  'UA7410021010': 'Горбове',
  'UA7410021011': 'Грабівка',
  'UA7410021023': 'Хибалівка',
  'UA7410021015': 'Кладьківка',
  'UA7410021017': 'Кошарище',
  'UA7410021016': 'Ковчин',
  'UA7410021001': 'Куликівка',
  'UA7410021018': 'Орлівка',
  'UA7410021019': 'Пенязівка',
  'UA7410021020': 'Салтикова Дівиця',
  'UA7410021021': 'Уборки',
  'UA7410021022': 'Українське',
  'UA7410021005': 'Вересоч',
  'UA7410021006': 'Вершинова Муравійка',
  'UA7410021007': 'Веселе',
  'UA7410021008': 'Виблі',
  'UA7410021014': 'Жуківка',
  'UA7410013002': 'Гущин',
  'UA7410013001': 'Киїнка',
  'UA7410013004': 'Павлівка',
  'UA7410013006': 'Шестовиця',
  'UA7410013005': 'Трисвятська Слобода',
  'UA7410013003': 'Жавинка',
  'UA7410015002': 'Березанка',
  'UA7410015003': 'Боромики',
  'UA7410015004': 'Брусилів',
  'UA7410015006': 'Кобилянка',
  'UA7410015001': 'Киселівка',
  'UA7410015007': 'Малинівка',
  'UA7410015008': 'Моргуличі',
  'UA7410015009': 'Новоселівка',
  'UA7410015010': 'Петрове',
  'UA7410015011': 'Петрушин',
  'UA7410015012': 'Снов\'янка',
  'UA7410015013': 'Стаси',
  'UA7410015014': 'Терехівка',
  'UA7410015015': 'Товстоліс',
  'UA7410015005': 'Вознесенське',
  'UA7410023002': 'Березівка',
  'UA7410023043': 'Чисті Лужі',
  'UA7410023013': 'Довгуни',
  'UA7410023014': 'Духанки',
  'UA7410023006': 'Галків',
  'UA7410023046': 'Гірки',
  'UA7410023007': 'Голубівка',
  'UA7410023008': 'Грабівка',
  'UA7410023009': 'Губарі',
  'UA7410023010': 'Губичі',
  'UA7410023011': 'Гуньківка',
  'UA7410023012': 'Гута',
  'UA7410023015': 'Клонів',
  'UA7410023016': 'Комарівка',
  'UA7410023017': 'Коробки',
  'UA7410023018': 'Корольча',
  'UA7410023019': 'Кукарі',
  'UA7410023047': 'Левичівка',
  'UA7410023020': 'Лісківка',
  'UA7410023001': 'Любеч',
  'UA7410023021': 'Малий Зліїв',
  'UA7410023022': 'Малинівка',
  'UA7410023023': 'Маньки',
  'UA7410023024': 'Мекшунівка',
  'UA7410023027': 'Мохначі',
  'UA7410023026': 'Мокрі Велички',
  'UA7410023025': 'Миси',
  'UA7410023028': 'Неданчичі',
  'UA7410023029': 'Нова Рудня',
  'UA7410023030': 'Павлівка',
  'UA7410023048': 'Пересаж',
  'UA7410023031': 'Петрики',
  'UA7410023033': 'Пушкіне',
  'UA7410023032': 'Пищики',
  'UA7410023034': 'Редьківка',
  'UA7410023035': 'Розсудів',
  'UA7410023036': 'Рудня',
  'UA7410023037': 'Семаки',
  'UA7410023044': 'Шкуранка',
  'UA7410023045': 'Шумани',
  'UA7410023038': 'Скитьки',
  'UA7410023039': 'Смолигівка',
  'UA7410023040': 'Тараса Шевченка',
  'UA7410023041': 'Тулія',
  'UA7410023042': 'Углова Рудня',
  'UA7410023003': 'Великий Зліїв',
  'UA7410023004': 'Вертеча',
  'UA7410023005': 'Вороб\'їв',
  'UA7410025002': 'Андріївка',
  'UA7410025003': 'Антоновичі',
  'UA7410025004': 'Бірки',
  'UA7410025008': 'Дніпровське',
  'UA7410025006': 'Гірманка',
  'UA7410025007': 'Глядин',
  'UA7410025014': 'Кархівка',
  'UA7410025037': 'Храпате',
  'UA7410025015': 'Ковпита',
  'UA7410025016': 'Кругле',
  'UA7410025019': 'Леньків Круг',
  'UA7410025017': 'Левковичі',
  'UA7410025018': 'Левоньки',
  'UA7410025021': 'Льгів',
  'UA7410025022': 'Льгівка',
  'UA7410025020': 'Линея',
  'UA7410025023': 'Малійки',
  'UA7410025024': 'Мньов',
  'UA7410025025': 'Москалі',
  'UA7410025001': 'Михайло-Коцюбинське',
  'UA7410025026': 'Пакуль',
  'UA7410025027': 'Папірня',
  'UA7410025029': 'Пльохів',
  'UA7410025030': 'Повідов',
  'UA7410025031': 'Прохорів',
  'UA7410025032': 'Пустинки',
  'UA7410025028': 'Пильня',
  'UA7410025041': 'Ревунів Круг',
  'UA7410025033': 'Рудня',
  'UA7410025034': 'Семенягівка',
  'UA7410025039': 'Шмаївка',
  'UA7410025040': 'Шульгівка',
  'UA7410025038': 'Шибиринівка',
  'UA7410025035': 'Скугарі',
  'UA7410025036': 'Старик',
  'UA7410025042': 'Центральне',
  'UA7410025005': 'Ведильці',
  'UA7410025012': 'Загатка',
  'UA7410025013': 'Зайці',
  'UA7410025011': 'Завод',
  'UA7410025010': 'Жукотки',
  'UA7410025009': 'Жидиничі',
  'UA7410027002': 'Деснянка',
  'UA7410027003': 'Довжик',
  'UA7410027019': 'Халявин',
  'UA7410027020': 'Хмільниця',
  'UA7410027004': 'Кошівка',
  'UA7410027005': 'Кувечичі',
  'UA7410027006': 'Мохнатин',
  'UA7410027001': 'Новий Білоус',
  'UA7410027007': 'Полуботки',
  'UA7410027008': 'Редьківка',
  'UA7410027014': 'Рябці',
  'UA7410027010': 'Рівнопілля',
  'UA7410027011': 'Рогощі',
  'UA7410027012': 'Роїще',
  'UA7410027013': 'Рудка',
  'UA7410027009': 'Рижики',
  'UA7410027015': 'Селянська Слобода',
  'UA7410027021': 'Шевченка',
  'UA7410027016': 'Старий Білоус',
  'UA7410027017': 'Табаївка',
  'UA7410027018': 'Унучки',
  'UA7410027022': 'Юр\'ївка',
  'UA7410029002': 'Коростень',
  'UA7410029001': 'Олишівка',
  'UA7410029003': 'Серединка',
  'UA7410029004': 'Сіножацьке',
  'UA7410029005': 'Смолянка',
  'UA7410029006': 'Топчіївка',
  'UA7410031002': 'Бабарики',
  'UA7410031003': 'Беремицьке',
  'UA7410031004': 'Білики',
  'UA7410031005': 'Бірки',
  'UA7410031007': 'Дешки',
  'UA7410031012': 'Кошани',
  'UA7410031011': 'Котів',
  'UA7410031014': 'Крехаїв',
  'UA7410031013': 'Крені',
  'UA7410031015': 'Любечанинів',
  'UA7410031016': 'Набільське',
  'UA7410031017': 'Одинці',
  'UA7410031001': 'Остер',
  'UA7410031018': 'Пархимів',
  'UA7410031019': 'Поліське',
  'UA7410031020': 'Романьки',
  'UA7410031021': 'Самсони',
  'UA7410031022': 'Туманська Гута',
  'UA7410031006': 'Волевачі',
  'UA7410031008': 'Євминка',
  'UA7410031010': 'Жуківщина',
  'UA7410031009': 'Жилин Млинок',
  'UA7410033004': 'Бахани',
  'UA7410033006': 'Буянки',
  'UA7410033005': 'Бихольцохівка',
  'UA7410033047': 'Чудівка',
  'UA7410033048': 'Чумак',
  'UA7410033016': 'Даничі',
  'UA7410033012': 'Глиненка',
  'UA7410033013': 'Голубичі',
  'UA7410033014': 'Грабів',
  'UA7410033015': 'Гучин',
  'UA7410033020': 'Кам\'янка',
  'UA7410033022': 'Корчев\'я',
  'UA7410033023': 'Красківське',
  'UA7410033024': 'Кратинь',
  'UA7410033021': 'Кислі',
  'UA7410033026': 'Лопатні',
  'UA7410033025': 'Ловинь',
  'UA7410033027': 'Малий Листвен',
  'UA7410033028': 'Молочки',
  'UA7410033029': 'Мутичів',
  'UA7410033030': 'Новосілки',
  'UA7410033031': 'Новоукраїнське',
  'UA7410033032': 'Переділ',
  'UA7410033033': 'Петруші',
  'UA7410033035': 'Пізнопали',
  'UA7410033036': 'Плехтіївка',
  'UA7410033037': 'Присторонь',
  'UA7410033038': 'Пушкарі',
  'UA7410033034': 'Пилипча',
  'UA7410033003': 'Радуль',
  'UA7410033039': 'Рашкова Слобода',
  'UA7410033001': 'Ріпки',
  'UA7410033040': 'Сенюки',
  'UA7410033042': 'Суличівка',
  'UA7410033043': 'Суслівка',
  'UA7410033041': 'Сибереж',
  'UA7410033044': 'Тамарівка',
  'UA7410033045': 'Трудове',
  'UA7410033046': 'Убіжичі',
  'UA7410033007': 'Велика Вісь',
  'UA7410033008': 'Великі Осняки',
  'UA7410033009': 'Вербичі',
  'UA7410033011': 'Вишневе',
  'UA7410033010': 'Високинь',
  'UA7410033049': 'Ямище',
  'UA7410033017': 'Задеріївка',
  'UA7410033002': 'Замглай',
  'UA7410033019': 'Зубахи',
  'UA7410033018': 'Звеничів',
  'UA7410035007': 'Черниш',
  'UA7410035003': 'Клочків',
  'UA7410035004': 'Лашуки',
  'UA7410035005': 'Макишин',
  'UA7410035006': 'Малий Дирчин',
  'UA7410035008': 'Нове',
  'UA7410035001': 'Седнів',
  'UA7410035002': 'Великий Дирчин',
  'UA7410037002': 'Безиків',
  'UA7410037003': 'Бурівка',
  'UA7410037006': 'Довге',
  'UA7410037007': 'Івашківка',
  'UA7410037008': 'Куликівка',
  'UA7410037009': 'Перше Травня',
  'UA7410037010': 'Розвинівка',
  'UA7410037011': 'Тополівка',
  'UA7410037001': 'Тупичів',
  'UA7410037004': 'Великий Листвен',
  'UA7410037005': 'Вихвостів',
  'UA7402009002': 'Березова Роща',
  'UA7402009003': 'Бобрик',
  'UA7402009004': 'Борок',
  'UA7402009014': 'Ченчики',
  'UA7402009005': 'Дачне',
  'UA7402009015': 'Довжик',
  'UA7402009007': 'Камка',
  'UA7402009001': 'Холми',
  'UA7402009008': 'Козилівка',
  'UA7402009009': 'Кучугури',
  'UA7402009010': 'Олешня',
  'UA7402009011': 'Радомка',
  'UA7402009012': 'Тихонівське',
  'UA7402009013': 'Уріччя',
  'UA7402009006': 'Жукля',
  'UA7402001002': 'Андроники',
  'UA7402001003': 'Баляси',
  'UA7402001004': 'Бешківка',
  'UA7402001005': 'Білошицька Слобода',
  'UA7402001006': 'Богдалівка',
  'UA7402001007': 'Бреч',
  'UA7402001008': 'Буда',
  'UA7402001009': 'Будище',
  'UA7402001010': 'Бурківка',
  'UA7402001016': 'Домашлин',
  'UA7402001066': 'Довга Гребля',
  'UA7402001065': 'Голубівщина',
  'UA7402001014': 'Гуринівка',
  'UA7402001015': 'Гутище',
  'UA7402001062': 'Хотіївка',
  'UA7402001061': 'Ховдіївка',
  'UA7402001001': 'Корюківка',
  'UA7402001022': 'Костянтинівка',
  'UA7402001021': 'Костючки',
  'UA7402001023': 'Кугуки',
  'UA7402001020': 'Кирилівка',
  'UA7402001024': 'Лебіддя',
  'UA7402001025': 'Лісове',
  'UA7402001026': 'Лубенець',
  'UA7402001027': 'Луковець',
  'UA7402001028': 'Лупасове',
  'UA7402001029': 'Майбутнє',
  'UA7402001030': 'Маховики',
  'UA7402001031': 'Наумівка',
  'UA7402001032': 'Нова Буда',
  'UA7402001033': 'Нова Гуринівка',
  'UA7402001034': 'Новоселівка',
  'UA7402001038': 'Охрамієвичі',
  'UA7402001036': 'Олександрівка',
  'UA7402001037': 'Олійники',
  'UA7402001035': 'Озереди',
  'UA7402001039': 'Парастовське',
  'UA7402001040': 'Переділ',
  'UA7402001041': 'Перелюб',
  'UA7402001042': 'Петрова Слобода',
  'UA7402001043': 'Піски',
  'UA7402001044': 'Прибинь',
  'UA7402001045': 'Рейментарівка',
  'UA7402001047': 'Романівська Буда',
  'UA7402001048': 'Рудня',
  'UA7402001046': 'Рибинськ',
  'UA7402001052': 'Сахутівка',
  'UA7402001050': 'Самотуги',
  'UA7402001051': 'Самсонівка',
  'UA7402001049': 'Савинки',
  'UA7402001063': 'Шишка',
  'UA7402001064': 'Шишківка',
  'UA7402001056': 'Сядрине',
  'UA7402001053': 'Соснівка',
  'UA7402001054': 'Спичувате',
  'UA7402001055': 'Стопилка',
  'UA7402001057': 'Тельне',
  'UA7402001060': 'Тютюнниця',
  'UA7402001058': 'Трудовик',
  'UA7402001059': 'Турівка',
  'UA7402001011': 'Верхолісся',
  'UA7402001013': 'Воловики',
  'UA7402001012': 'Високе',
  'UA7402001018': 'Забарівка',
  'UA7402001019': 'Заляддя',
  'UA7402001017': 'Журавлева Буда',
  'UA7402003003': 'Бірківка',
  'UA7402003004': 'Блистова',
  'UA7402003036': 'Чорногорці',
  'UA7402003010': 'Данилівка',
  'UA7402003011': 'Дерепівка',
  'UA7402003014': 'Дягова',
  'UA7402003012': 'Дібрівка',
  'UA7402003013': 'Дмитрівка',
  'UA7402003035': 'Феськівка',
  'UA7402003009': 'Городище',
  'UA7402003017': 'Комарівка',
  'UA7402003018': 'Куковичі',
  'UA7402003037': 'Куковицьке',
  'UA7402003016': 'Киселівка',
  'UA7402003019': 'Лазарівка',
  'UA7402003020': 'Ліски',
  'UA7402003021': 'Луки',
  'UA7402003022': 'Майське',
  'UA7402003002': 'Макошине',
  'UA7402003023': 'Максаки',
  'UA7402003001': 'Мена',
  'UA7402003024': 'Нові Броди',
  'UA7402003027': 'Осьмаки',
  'UA7402003026': 'Остапівка',
  'UA7402003025': 'Овчарівка',
  'UA7402003028': 'Покровське',
  'UA7402003038': 'Прогрес',
  'UA7402003039': 'Садове',
  'UA7402003029': 'Семенівка',
  'UA7402003031': 'Слобідка',
  'UA7402003032': 'Степанівка',
  'UA7402003033': 'Стольне',
  'UA7402003030': 'Синявка',
  'UA7402003034': 'Ушня',
  'UA7402003005': 'Величківка',
  'UA7402003006': 'Веселе',
  'UA7402003007': 'Вільне',
  'UA7402003008': 'Волосківці',
  'UA7402003015': 'Загорівка',
  'UA7402005002': 'Безуглівка',
  'UA7402005054': 'Чепелів',
  'UA7402005051': 'Філонівка',
  'UA7402005005': 'Гірськ',
  'UA7402005006': 'Глибокий Ріг',
  'UA7402005007': 'Гута-Студенецька',
  'UA7402005004': 'Гвоздиківка',
  'UA7402005016': 'Ількуча',
  'UA7402005015': 'Іванівка',
  'UA7402005017': 'Камка',
  'UA7402005052': 'Хотуничі',
  'UA7402005053': 'Хрінівка',
  'UA7402005018': 'Клюси',
  'UA7402005019': 'Крестопівщина',
  'UA7402005021': 'Кучинівка',
  'UA7402005020': 'Куропіївка',
  'UA7402005024': 'Лютівка',
  'UA7402005023': 'Лосєва Слобода',
  'UA7402005057': 'Лука',
  'UA7402005022': 'Липівка',
  'UA7402005027': 'Містки',
  'UA7402005028': 'Млинок',
  'UA7402005025': 'Михайлівка',
  'UA7402005026': 'Мишине',
  'UA7402005030': 'Нові Боровичі',
  'UA7402005031': 'Нові Млини',
  'UA7402005029': 'Низківка',
  'UA7402005032': 'Петрівка',
  'UA7402005033': 'Піщанка',
  'UA7402005034': 'Пльохів',
  'UA7402005035': 'Попільня',
  'UA7402005036': 'Привільне',
  'UA7402005037': 'Радвине',
  'UA7402005038': 'Рогізки',
  'UA7402005039': 'Руда',
  'UA7402005040': 'Сальне',
  'UA7402005041': 'Селище',
  'UA7402005056': 'Щокоть',
  'UA7402005055': 'Шкробове',
  'UA7402005042': 'Слава',
  'UA7402005043': 'Смяч',
  'UA7402005001': 'Сновськ',
  'UA7402005044': 'Сновське',
  'UA7402005045': 'Софіївка',
  'UA7402005046': 'Стара Рудня',
  'UA7402005047': 'Старі Боровичі',
  'UA7402005048': 'Суничне',
  'UA7402005050': 'Тур\'я',
  'UA7402005049': 'Тихоновичі',
  'UA7402005003': 'Великий Щимель',
  'UA7402005008': 'Єліне',
  'UA7402005009': 'Єнькова Рудня',
  'UA7402005012': 'Загребельна Слобода',
  'UA7402005013': 'Займище',
  'UA7402005014': 'Заріччя',
  'UA7402005010': 'Жовідь',
  'UA7402005011': 'Журавок',
  'UA7402007002': 'Бондарівка',
  'UA7402007003': 'Бутівка',
  'UA7402007039': 'Чорнотичі',
  'UA7402007011': 'Долинське',
  'UA7402007037': 'Філонівка',
  'UA7402007007': 'Гай',
  'UA7402007008': 'Ганнівка',
  'UA7402007009': 'Гапішківка',
  'UA7402007010': 'Гутище',
  'UA7402007038': 'Хлоп\'яники',
  'UA7402007015': 'Кнути',
  'UA7402007017': 'Конятин',
  'UA7402007018': 'Костирів',
  'UA7402007016': 'Козляничі',
  'UA7402007019': 'Кудрівка',
  'UA7402007020': 'Купчичі',
  'UA7402007014': 'Киріївка',
  'UA7402007021': 'Лави',
  'UA7402007023': 'Ляшківці',
  'UA7402007022': 'Лозова',
  'UA7402007041': 'Мала Бондарівка',
  'UA7402007024': 'Мале Устя',
  'UA7402007025': 'Масалаївка',
  'UA7402007026': 'Матвіївка',
  'UA7402007027': 'Пекарів',
  'UA7402007028': 'Полісся',
  'UA7402007029': 'Польове',
  'UA7402007030': 'Прогони',
  'UA7402007031': 'Рудня',
  'UA7402007034': 'Соснівка',
  'UA7402007001': 'Сосниця',
  'UA7402007035': 'Спаське',
  'UA7402007036': 'Старобутівка',
  'UA7402007032': 'Свірок',
  'UA7402007033': 'Синютин',
  'UA7402007004': 'Велике Устя',
  'UA7402007005': 'Вільшане',
  'UA7402007006': 'Волинка',
  'UA7402007040': 'Якличі',
  'UA7402007012': 'Загребелля',
  'UA7402007013': 'Змітнів',
  'UA7404003001': 'Бахмач',
  'UA7404003002': 'Бахмач',
  'UA7404003003': 'Безпечне',
  'UA7404003004': 'Біловежі Другі',
  'UA7404003005': 'Біловежі Перші',
  'UA7404003039': 'Черемушки',
  'UA7404003033': 'Фастівці',
  'UA7404003010': 'Глибоке',
  'UA7404003012': 'Грушівка',
  'UA7404003011': 'Григорівка',
  'UA7404003017': 'Кальчинівка',
  'UA7404003016': 'Калинівка',
  'UA7404003034': 'Халимонове',
  'UA7404003018': 'Кошмалів',
  'UA7404003019': 'Красилівка',
  'UA7404003020': 'Кулішове',
  'UA7404003021': 'Курінь',
  'UA7404003022': 'Мовчинів',
  'UA7404003023': 'Олексіївка',
  'UA7404003024': 'Ополонське',
  'UA7404003026': 'Острів',
  'UA7404003025': 'Осинівка',
  'UA7404003027': 'Пашків',
  'UA7404003038': 'Перемога',
  'UA7404003028': 'Перше Травня',
  'UA7404003029': 'Піски',
  'UA7404003035': 'Шевченка',
  'UA7404003036': 'Шумейків',
  'UA7404003030': 'Стрільники',
  'UA7404003031': 'Тиниця',
  'UA7404003032': 'Українське',
  'UA7404003006': 'Варварівка',
  'UA7404003037': 'Веселе',
  'UA7404003007': 'Вишневе',
  'UA7404003008': 'Вишневе',
  'UA7404003009': 'Вишнівське',
  'UA7404003013': 'Запорізьке',
  'UA7404003014': 'Зарукавне',
  'UA7404003015': 'Зеленівка',
  'UA7404001001': 'Батурин',
  'UA7404001002': 'Бондарі',
  'UA7404001021': 'Часниківка',
  'UA7404001023': 'Голубів',
  'UA7404001006': 'Городище',
  'UA7404001007': 'Карпенкове',
  'UA7404001024': 'Каціри',
  'UA7404001008': 'Красне',
  'UA7404001009': 'Лісова Поляна',
  'UA7404001025': 'Лопатин',
  'UA7404001010': 'Матіївка',
  'UA7404001012': 'Мости',
  'UA7404001011': 'Митченки',
  'UA7404001013': 'Нове Полісся',
  'UA7404001014': 'Обірки',
  'UA7404001015': 'Обмачів',
  'UA7404001016': 'Осіч',
  'UA7404001017': 'Пальчики',
  'UA7404001026': 'Прохори',
  'UA7404001018': 'Пирогівка',
  'UA7404001027': 'Шумейкине',
  'UA7404001022': 'Шумин',
  'UA7404001019': 'Слобідка',
  'UA7404001020': 'Тасуїв',
  'UA7404001003': 'Вербівка',
  'UA7404001004': 'Веселе',
  'UA7404001005': 'Веселівка',
  'UA7404005001': 'Бобровиця',
  'UA7404005002': 'Браниця',
  'UA7404005003': 'Бригинці',
  'UA7404005004': 'Буглаки',
  'UA7404005007': 'Гарт',
  'UA7404005006': 'Гаврилівка',
  'UA7404005008': 'Горбачі',
  'UA7404005012': 'Катеринівка',
  'UA7404005038': 'Хомівці',
  'UA7404005013': 'Кобижча',
  'UA7404005015': 'Коношівка',
  'UA7404005014': 'Козацьке',
  'UA7404005016': 'Лідин',
  'UA7404005017': 'Майнівка',
  'UA7404005018': 'Макарівка',
  'UA7404005019': 'Марківці',
  'UA7404005021': 'Молодіжне',
  'UA7404005020': 'Миколаїв',
  'UA7404005041': 'Мирне',
  'UA7404005022': 'Наумівка',
  'UA7404005024': 'Олександрівка',
  'UA7404005026': 'Осокорівка',
  'UA7404005025': 'Осовець',
  'UA7404005023': 'Озеряни',
  'UA7404005027': 'Петрівка',
  'UA7404005028': 'Піски',
  'UA7404005029': 'Плуг',
  'UA7404005030': 'Рудьківка',
  'UA7404005039': 'Щаснівка',
  'UA7404005032': 'Стара Басань',
  'UA7404005033': 'Сухиня',
  'UA7404005031': 'Свидовець',
  'UA7404005034': 'Татарівка',
  'UA7404005035': 'Травкине',
  'UA7404005036': 'Українка',
  'UA7404005037': 'Урожайне',
  'UA7404005005': 'Вишневе',
  'UA7404005040': 'Ярославка',
  'UA7404005009': 'Запоріжжя',
  'UA7404005010': 'Затишшя',
  'UA7404005011': 'Зелене',
  'UA7404007002': 'Адамівка',
  'UA7404007001': 'Борзна',
  'UA7404007019': 'Червоне Озеро',
  'UA7404007004': 'Гришівка',
  'UA7404007008': 'Іванівка',
  'UA7404007010': 'Красностав',
  'UA7404007009': 'Кинашівка',
  'UA7404007011': 'Любомудрівка',
  'UA7404007012': 'Мала Загорівка',
  'UA7404007013': 'Миколаївка',
  'UA7404007014': 'Оленівка',
  'UA7404007015': 'Острів Надії',
  'UA7404007016': 'Прачі',
  'UA7404007017': 'Сапонівка',
  'UA7404007020': 'Шаповалівка',
  'UA7404007018': 'Суховодка',
  'UA7404007003': 'Вольниця',
  'UA7404007022': 'Ядути',
  'UA7404007021': 'Юрківщина',
  'UA7404007007': 'Забілівщина Друга',
  'UA7404007006': 'Забілівщина',
  'UA7404007005': 'Жданів',
  'UA7404013001': 'Дмитрівка',
  'UA7404013003': 'Гайворон',
  'UA7404013004': 'Голінка',
  'UA7404013007': 'Ковальове',
  'UA7404013008': 'Кропивне',
  'UA7404013009': 'Нечаїв',
  'UA7404013015': 'Нове',
  'UA7404013010': 'Рубанка',
  'UA7404013014': 'Щуча Гребля',
  'UA7404013013': 'Шевченкове',
  'UA7404013011': 'Смолове',
  'UA7404013012': 'Терешиха',
  'UA7404013002': 'Восьме Березня',
  'UA7404013005': 'Заболоття',
  'UA7404013006': 'Залісся',
  'UA7404015003': 'Берестовець',
  'UA7404015002': 'Березівка',
  'UA7404015006': 'Іллінці',
  'UA7404015013': 'Ховми',
  'UA7404015001': 'Комарівка',
  'UA7404015007': 'Красносільське',
  'UA7404015008': 'Линівка',
  'UA7404015009': 'Прохори',
  'UA7404015014': 'Шевченка',
  'UA7404015011': 'Смоляж',
  'UA7404015012': 'Степанівка',
  'UA7404015010': 'Сидорівка',
  'UA7404015004': 'Воловиця',
  'UA7404015005': 'Ворона',
  'UA7404015015': 'Запоріжжя',
  'UA7404017002': 'Бакланове',
  'UA7404017003': 'Бурківка',
  'UA7404017005': 'Діброва',
  'UA7404017014': 'Хороше Озеро',
  'UA7404017001': 'Крути',
  'UA7404017006': 'Омбиш',
  'UA7404017007': 'Остер',
  'UA7404017008': 'Пам\'ятне',
  'UA7404017010': 'Печі',
  'UA7404017009': 'Перебудова',
  'UA7404017012': 'Почечине',
  'UA7404017011': 'Поляна',
  'UA7404017013': 'Українка',
  'UA7404017004': 'Валентіїв',
  'UA7404019002': 'Богданівка',
  'UA7404019016': 'Чистий Колодязь',
  'UA7404019006': 'Данина',
  'UA7404019004': 'Галиця',
  'UA7404019005': 'Гармащина',
  'UA7404019007': 'Ковтунівка',
  'UA7404019008': 'Леонідівка',
  'UA7404019001': 'Лосинівка',
  'UA7404019020': 'Мирне',
  'UA7404019009': 'Перемога',
  'UA7404019010': 'Погребець',
  'UA7404019011': 'Садове',
  'UA7404019012': 'Сальне',
  'UA7404019017': 'Шатура',
  'UA7404019018': 'Шняківка',
  'UA7404019013': 'Станція Лосинівська',
  'UA7404019014': 'Степ',
  'UA7404019021': 'Світанок',
  'UA7404019015': 'Терешківка',
  'UA7404019003': 'Вікторівка',
  'UA7404019019': 'Яхнівка',
  'UA7404021007': 'Ганнівка',
  'UA7404021008': 'Григорівка',
  'UA7404021009': 'Калинівка',
  'UA7404021010': 'Карабинівка',
  'UA7404021011': 'Кленове',
  'UA7404021012': 'Коломійцівка',
  'UA7404021001': 'Макіївка',
  'UA7404021013': 'Мильники',
  'UA7404021014': 'Платонівка',
  'UA7404021015': 'Пустотине',
  'UA7404021016': 'Рівчак-Степанівка',
  'UA7404021017': 'Софіївка',
  'UA7404021018': 'Степове',
  'UA7404021019': 'Степові Хутори',
  'UA7404021002': 'Вербове',
  'UA7404021003': 'Веселе',
  'UA7404021006': 'Відрадне',
  'UA7404021004': 'Вили',
  'UA7404021005': 'Вишневе',
  'UA7404023007': 'Хотинівка',
  'UA7404023002': 'Киселівка',
  'UA7404023003': 'Лихачів',
  'UA7404023001': 'Мрин',
  'UA7404023004': 'Плоске',
  'UA7404023005': 'Роздольне',
  'UA7404023006': 'Селище',
  'UA7404025002': 'Кунашівка',
  'UA7404025003': 'Наумівське',
  'UA7404025001': 'Ніжин',
  'UA7404025004': 'Паливода',
  'UA7404025005': 'Переяслівка',
  'UA7404029002': 'Адамівка',
  'UA7404029003': 'Андріївка',
  'UA7404029006': 'Дебреве',
  'UA7404029007': 'Держанівка',
  'UA7404029008': 'Дослідне',
  'UA7404029009': 'Іржавець',
  'UA7404029010': 'Кобилещина',
  'UA7404029012': 'Коробчине',
  'UA7404029011': 'Козари',
  'UA7404029013': 'Криниця',
  'UA7404029014': 'Лісові Хутори',
  'UA7404029015': 'Лукашівка',
  'UA7404029001': 'Носівка',
  'UA7404029016': 'Підгайне',
  'UA7404029017': 'Ставок',
  'UA7404029018': 'Сулак',
  'UA7404029019': 'Тертишники',
  'UA7404029004': 'Ведмедівка',
  'UA7404029005': 'Володькова Дівиця',
  'UA7404029020': 'Яблунівка',
  'UA7404029021': 'Ясна Зірка',
  'UA7404027002': 'Білоцерківці',
  'UA7404027003': 'Бірки',
  'UA7404027013': 'Чистопілля',
  'UA7404027006': 'Красне',
  'UA7404027007': 'Мочалище',
  'UA7404027001': 'Нова Басань',
  'UA7404027009': 'Новоселиця',
  'UA7404027008': 'Новий Биків',
  'UA7404027010': 'Рокитне',
  'UA7404027011': 'Соколівка',
  'UA7404027012': 'Старий Биків',
  'UA7404027004': 'Веприк',
  'UA7404027005': 'Вороньки',
  'UA7404031003': 'Махнівка',
  'UA7404031001': 'Плиски',
  'UA7404031005': 'Степ',
  'UA7404031004': 'Сиволож',
  'UA7404031002': 'Велика Загорівка',
  'UA7404033002': 'Безуглівка',
  'UA7404033003': 'Бідин',
  'UA7404033005': 'Довге',
  'UA7404033006': 'Калинівка',
  'UA7404033015': 'Хомівка',
  'UA7404033014': 'Хвилівка',
  'UA7404033007': 'Кравчиха',
  'UA7404033008': 'Кропивне',
  'UA7404033009': 'Курилівка',
  'UA7404033010': 'Лустівка',
  'UA7404033011': 'Ніжинське',
  'UA7404033012': 'Пашківка',
  'UA7404033013': 'Синдаревське',
  'UA7404033001': 'Талалаївка',
  'UA7404033004': 'Велика Дорога',
  'UA7404009002': 'Березанка',
  'UA7404009003': 'Бобрик',
  'UA7404009022': 'Черняхівка',
  'UA7404009005': 'Дуболугівка',
  'UA7404009008': 'Каблуки',
  'UA7404009009': 'Кардаші',
  'UA7404009020': 'Холявки',
  'UA7404009021': 'Хомине',
  'UA7404009010': 'Колісники',
  'UA7404009011': 'Кукшин',
  'UA7404009013': 'Лісове',
  'UA7404009012': 'Липів Ріг',
  'UA7404009014': 'Мала Кошелівка',
  'UA7404009015': 'Мильники',
  'UA7404009016': 'Низи',
  'UA7404009017': 'Переходівка',
  'UA7404009018': 'Стодоли',
  'UA7404009019': 'Титівка',
  'UA7404009004': 'Велика Кошелівка',
  'UA7404009001': 'Вертіївка',
  'UA7404009023': 'Яблуневе',
  'UA7404009024': 'Юність',
  'UA7404009006': 'Заньки',
  'UA7404009007': 'Зруб',
  'UA7404011014': 'Червона Гірка',
  'UA7404011005': 'Добропілля',
  'UA7404011003': 'Галайбине',
  'UA7404011004': 'Головеньки',
  'UA7404011006': 'Кербутівка',
  'UA7404011015': 'Клипин',
  'UA7404011007': 'Купченків',
  'UA7404011008': 'Мала Доч',
  'UA7404011009': 'Маличина Гребля',
  'UA7404011011': 'Носелівка',
  'UA7404011010': 'Нові Млини',
  'UA7404011012': 'Паристівка',
  'UA7404011013': 'Тростянка',
  'UA7404011002': 'Велика Доч',
  'UA7404011001': 'Високе',
  'UA7406001002': 'Атюша',
  'UA7406001004': 'Борисове',
  'UA7406001005': 'Будище',
  'UA7406001006': 'Бужанка',
  'UA7406001003': 'Билка',
  'UA7406001045': 'Черешеньки',
  'UA7406001046': 'Чорнявка',
  'UA7406001013': 'Дрібці',
  'UA7406001009': 'Галичівка',
  'UA7406001010': 'Городище',
  'UA7406001011': 'Горохове',
  'UA7406001048': 'Груди',
  'UA7406001012': 'Гута',
  'UA7406001016': 'Карацюбине',
  'UA7406001017': 'Карильське',
  'UA7406001001': 'Короп',
  'UA7406001018': 'Краснопілля',
  'UA7406001019': 'Кучі',
  'UA7406001020': 'Лебедин',
  'UA7406001021': 'Лубенець',
  'UA7406001022': 'Лукнів',
  'UA7406001050': 'Лиса Гора',
  'UA7406001023': 'Мохове',
  'UA7406001024': 'Накот',
  'UA7406001026': 'Нехаївка',
  'UA7406001025': 'Некрите',
  'UA7406001028': 'Нориця',
  'UA7406001027': 'Новоселиця',
  'UA7406001029': 'Оболоння',
  'UA7406001030': 'Подоляки',
  'UA7406001032': 'Поляна',
  'UA7406001031': 'Поліське',
  'UA7406001033': 'Придеснянське',
  'UA7406001034': 'Пуста Гребля',
  'UA7406001035': 'Райгородок',
  'UA7406001036': 'Ранок',
  'UA7406001039': 'Рождественське',
  'UA7406001040': 'Рубанників',
  'UA7406001037': 'Риботин',
  'UA7406001038': 'Рижки',
  'UA7406001047': 'Шабалинів',
  'UA7406001042': 'Сохачі',
  'UA7406001043': 'Станове',
  'UA7406001041': 'Синявка',
  'UA7406001044': 'Тарасівка',
  'UA7406001008': 'Вільне',
  'UA7406001007': 'Вишеньки',
  'UA7406001014': 'Єгорівка',
  'UA7406001049': 'Зайцеве',
  'UA7406001015': 'Жернівка',
  'UA7406003002': 'Араповичі',
  'UA7406003003': 'Аршуки',
  'UA7406003004': 'Березова Гать',
  'UA7406003006': 'Блистова',
  'UA7406003007': 'Богданове',
  'UA7406003011': 'Бучки',
  'UA7406003010': 'Будо-Вороб\'ївська',
  'UA7406003009': 'Будище',
  'UA7406003008': 'Бугринівка',
  'UA7406003005': 'Бирине',
  'UA7406003078': 'Чайкине',
  'UA7406003079': 'Чернацьке',
  'UA7406003080': 'Чулатів',
  'UA7406003024': 'Діброва',
  'UA7406003025': 'Дігтярівка',
  'UA7406003026': 'Дробишів',
  'UA7406003075': 'Фаївка',
  'UA7406003076': 'Форостовичі',
  'UA7406003077': 'Фурсове',
  'UA7406003018': 'Гай',
  'UA7406003019': 'Гірки',
  'UA7406003020': 'Гнатівка',
  'UA7406003021': 'Горбове',
  'UA7406003022': 'Городище',
  'UA7406003023': 'Грем\'яч',
  'UA7406003028': 'Кам\'янська Слобода',
  'UA7406003027': 'Камінь',
  'UA7406003029': 'Карабани',
  'UA7406003031': 'Клевин',
  'UA7406003033': 'Колос',
  'UA7406003034': 'Комань',
  'UA7406003032': 'Ковпинка',
  'UA7406003085': 'Красна Гірка',
  'UA7406003035': 'Красний Хутір',
  'UA7406003036': 'Кремський Бугор',
  'UA7406003037': 'Кролевець-Слобідка',
  'UA7406003038': 'Кудлаївка',
  'UA7406003039': 'Кузьминське',
  'UA7406003030': 'Киселівка',
  'UA7406003040': 'Ларинівка',
  'UA7406003041': 'Леньків',
  'UA7406003043': 'Лісконоги',
  'UA7406003044': 'Ломанка',
  'UA7406003045': 'Лоска',
  'UA7406003042': 'Лизунівка',
  'UA7406003046': 'Мамекине',
  'UA7406003049': 'Мовчанів',
  'UA7406003050': 'Муравейник',
  'UA7406003051': 'Мурав\'ї',
  'UA7406003047': 'Михайлівка',
  'UA7406003048': 'Михальчина-Слобода',
  'UA7406003052': 'Новеньке',
  'UA7406003001': 'Новгород-Сіверський',
  'UA7406003053': 'Новоселівка',
  'UA7406003054': 'Об\'єднане',
  'UA7406003055': 'Орлівка',
  'UA7406003056': 'Осове',
  'UA7406003057': 'Печенюги',
  'UA7406003058': 'Підгірне',
  'UA7406003059': 'Полюшкине',
  'UA7406003060': 'Попівка',
  'UA7406003061': 'Прокопівка',
  'UA7406003063': 'Пушкарі',
  'UA7406003062': 'Путивськ',
  'UA7406003064': 'Рогівка',
  'UA7406003065': 'Роща',
  'UA7406003066': 'Сапожків Хутір',
  'UA7406003081': 'Шептаки',
  'UA7406003067': 'Слобідка',
  'UA7406003068': 'Смяч',
  'UA7406003069': 'Солов\'їв',
  'UA7406003070': 'Стахорщина',
  'UA7406003071': 'Студинка',
  'UA7406003072': 'Троїцьке',
  'UA7406003074': 'Ушівка',
  'UA7406003073': 'Узруй',
  'UA7406003012': 'Великий Гай',
  'UA7406003014': 'Внутрішній Бір',
  'UA7406003015': 'Володимирівка',
  'UA7406003016': 'Вороб\'ївка',
  'UA7406003017': 'Восточне',
  'UA7406003013': 'Вильчики',
  'UA7406003083': 'Ясна Поляна',
  'UA7406003084': 'Ясне',
  'UA7406003082': 'Юхнове',
  'UA7406005002': 'Авдіївка',
  'UA7406005005': 'Деснянське',
  'UA7406005007': 'Іваньків',
  'UA7406005008': 'Криски',
  'UA7406005009': 'Курилівка',
  'UA7406005010': 'Мезин',
  'UA7406005011': 'Осьмаки',
  'UA7406005012': 'Покошичі',
  'UA7406005001': 'Понорниця',
  'UA7406005013': 'Радичів',
  'UA7406005015': 'Розльоти',
  'UA7406005014': 'Рихли',
  'UA7406005017': 'Шаболтасівка',
  'UA7406005016': 'Сміле',
  'UA7406005003': 'Великий Ліс',
  'UA7406005004': 'Верба',
  'UA7406005006': 'Зелена Поляна',
  'UA7406007002': 'Архипівка',
  'UA7406007003': 'Баранівка',
  'UA7406007004': 'Березовий Гай',
  'UA7406007005': 'Блешня',
  'UA7406007006': 'Бобрик Другий',
  'UA7406007007': 'Брониви',
  'UA7406007064': 'Чорнозем',
  'UA7406007063': 'Чорний Ріг',
  'UA7406007013': 'Довжик',
  'UA7406007059': 'Ферубки',
  'UA7406007060': 'Фроли',
  'UA7406007010': 'Галаганівка',
  'UA7406007011': 'Гаті',
  'UA7406007067': 'Газопровідне',
  'UA7406007012': 'Грем\'ячка',
  'UA7406007022': 'Іванівка',
  'UA7406007069': 'Іванпуть',
  'UA7406007021': 'Іванине',
  'UA7406007023': 'Калинівське',
  'UA7406007024': 'Карасі',
  'UA7406007025': 'Карповичі',
  'UA7406007061': 'Хандобоківка',
  'UA7406007062': 'Хотіївка',
  'UA7406007027': 'Костобобрів',
  'UA7406007026': 'Козилівщина',
  'UA7406007028': 'Красні Лози',
  'UA7406007030': 'Кринички',
  'UA7406007031': 'Криульки',
  'UA7406007029': 'Кривуша',
  'UA7406007032': 'Кути Другі',
  'UA7406007033': 'Леонівка',
  'UA7406007034': 'Лісківщина',
  'UA7406007035': 'Лосевочка',
  'UA7406007036': 'Лосівка',
  'UA7406007038': 'Луб\'яне',
  'UA7406007037': 'Лубня',
  'UA7406007039': 'Максимихине',
  'UA7406007040': 'Марс',
  'UA7406007041': 'Машеве',
  'UA7406007042': 'Медведівка',
  'UA7406007044': 'Мхи',
  'UA7406007043': 'Миколаївка',
  'UA7406007045': 'Набережне',
  'UA7406007046': 'Олександрівка',
  'UA7406007047': 'Орликівка',
  'UA7406007048': 'Погорільці',
  'UA7406007049': 'Покровське',
  'UA7406007050': 'Прогрес',
  'UA7406007051': 'Ракути',
  'UA7406007052': 'Селище',
  'UA7406007001': 'Семенівка',
  'UA7406007053': 'Сергіївське',
  'UA7406007065': 'Шведчина',
  'UA7406007055': 'Східне',
  'UA7406007054': 'Стара Гутка',
  'UA7406007057': 'Тополівка',
  'UA7406007058': 'Турове',
  'UA7406007056': 'Тимоновичі',
  'UA7406007070': 'Угли',
  'UA7406007008': 'Вільхівка',
  'UA7406007009': 'Вільшанка',
  'UA7406007066': 'Янжулівка',
  'UA7406007014': 'Єршов',
  'UA7406007017': 'Залізний Міст',
  'UA7406007018': 'Заріччя',
  'UA7406007068': 'Зелена Роща',
  'UA7406007019': 'Зелений Гай',
  'UA7406007015': 'Жаданівка',
  'UA7406007016': 'Жадове',
  'UA7406007020': 'Зоря',
  'UA7408003004': 'Андріївка',
  'UA7408003003': 'Августівка',
  'UA7408003005': 'Бакаївка',
  'UA7408003006': 'Барбурське',
  'UA7408003007': 'Безбородьків',
  'UA7408003008': 'Безводівка',
  'UA7408003009': 'Більмачівка',
  'UA7408003010': 'Буди',
  'UA7408003011': 'Бурімка',
  'UA7408003050': 'Червоне',
  'UA7408003022': 'Дорогинка',
  'UA7408003021': 'Довбні',
  'UA7408003002': 'Дружба',
  'UA7408003020': 'Дзюбівка',
  'UA7408003015': 'Гейці',
  'UA7408003016': 'Гмирянка',
  'UA7408003017': 'Городня',
  'UA7408003018': 'Грабів',
  'UA7408003019': 'Гужівка',
  'UA7408003001': 'Ічня',
  'UA7408003026': 'Іржавець',
  'UA7408003027': 'Іценків',
  'UA7408003025': 'Івангород',
  'UA7408003049': 'Хаєнки',
  'UA7408003053': 'Коломійцеве',
  'UA7408003029': 'Комарівка',
  'UA7408003030': 'Коршаки',
  'UA7408003031': 'Крупичполе',
  'UA7408003054': 'Куликівка',
  'UA7408003028': 'Киколи',
  'UA7408003032': 'Лучківка',
  'UA7408003033': 'Максимівка',
  'UA7408003034': 'Монастирище',
  'UA7408003035': 'Нова Ольшана',
  'UA7408003036': 'Новий Поділ',
  'UA7408003037': 'Однольків',
  'UA7408003038': 'Ольшана',
  'UA7408003039': 'Пелюхівка',
  'UA7408003040': 'Припутні',
  'UA7408003041': 'Рожнівка',
  'UA7408003044': 'Селихів',
  'UA7408003043': 'Сезьки',
  'UA7408003052': 'Щурівка',
  'UA7408003051': 'Шиловичі',
  'UA7408003045': 'Ступаківка',
  'UA7408003042': 'Сваричівка',
  'UA7408003046': 'Тарасівка',
  'UA7408003048': 'Томашівка',
  'UA7408003047': 'Тишківка',
  'UA7408003012': 'Веприк',
  'UA7408003014': 'Воронівка',
  'UA7408003013': 'Вишнівка',
  'UA7408003023': 'Заудайка',
  'UA7408003024': 'Зінченкове',
  'UA7408005002': 'Голубівка',
  'UA7408005003': 'Івківці',
  'UA7408005004': 'Красляни',
  'UA7408005001': 'Ладан',
  'UA7408005005': 'Лиски',
  'UA7408005006': 'Подище',
  'UA7408005007': 'Рибці',
  'UA7408007002': 'Богданівка',
  'UA7408007003': 'Бубнівщина',
  'UA7408007005': 'Даньківка',
  'UA7408007004': 'Глинщина',
  'UA7408007006': 'Лутайка',
  'UA7408007001': 'Линовиця',
  'UA7408007008': 'Мохнівка',
  'UA7408007007': 'Мокляки',
  'UA7408007009': 'Нетягівщина',
  'UA7408007010': 'Нова Гребля',
  'UA7408007011': 'Онищенків',
  'UA7408007012': 'Полонки',
  'UA7408007013': 'Стасівщина',
  'UA7408007014': 'Тополя',
  'UA7408007015': 'Турківка',
  'UA7408007016': 'Удайці',
  'UA7408009003': 'Дмитрівка',
  'UA7408009001': 'Мала Дівиця',
  'UA7408009005': 'Новий Лад',
  'UA7408009006': 'Обичів',
  'UA7408009007': 'Перше Травня',
  'UA7408009008': 'Петрівка',
  'UA7408009009': 'Радьківка',
  'UA7408009012': 'Шевченка',
  'UA7408009010': 'Світанкове',
  'UA7408009011': 'Товкачівка',
  'UA7408009002': 'Велика Дівиця',
  'UA7408009004': 'Заудайка',
  'UA7408011002': 'Барвінкове',
  'UA7408011003': 'Бережівка',
  'UA7408011004': 'Боярщина',
  'UA7408011009': 'Іваниця',
  'UA7408011018': 'Качанівка',
  'UA7408011016': 'Хаїха',
  'UA7408011010': 'Ковтунівка',
  'UA7408011011': 'Купина',
  'UA7408011019': 'Лугове',
  'UA7408011012': 'Лисогори',
  'UA7408011013': 'Мартинівка',
  'UA7408011001': 'Парафіївка',
  'UA7408011014': 'Петрушівка',
  'UA7408011015': 'Проліски',
  'UA7408011022': 'Шевченка',
  'UA7408011020': 'Софіївка',
  'UA7408011021': 'Тростянець',
  'UA7408011005': 'Верескуни',
  'UA7408011006': 'Власівка',
  'UA7408011017': 'Южне',
  'UA7408011007': 'Загін',
  'UA7408011008': 'Зоцівка',
  'UA7408013001': 'Прилуки',
  'UA7408015003': 'Антішки',
  'UA7408015004': 'Артеменків',
  'UA7408015012': 'Дейманівка',
  'UA7408015002': 'Дігтярі',
  'UA7408015007': 'Галка',
  'UA7408015008': 'Гнатівка',
  'UA7408015009': 'Горобіївка',
  'UA7408015010': 'Гриціївка',
  'UA7408015011': 'Гурбинці',
  'UA7408015013': 'Іванківці',
  'UA7408015014': 'Калюжинці',
  'UA7408015015': 'Карпилівка',
  'UA7408015028': 'Харитонівка',
  'UA7408015029': 'Хукалівка',
  'UA7408015016': 'Кути',
  'UA7408015017': 'Лебединці',
  'UA7408015018': 'Лозове',
  'UA7408015019': 'Никонівка',
  'UA7408015020': 'Олексинці',
  'UA7408015021': 'Побочіївка',
  'UA7408015022': 'Поділ',
  'UA7408015023': 'Поетин',
  'UA7408015024': 'Савинці',
  'UA7408015025': 'Сокиринці',
  'UA7408015001': 'Срібне',
  'UA7408015026': 'Точене',
  'UA7408015027': 'Тростянець',
  'UA7408015006': 'Васюків',
  'UA7408015005': 'Васьківці',
  'UA7408017002': 'Бажанівка',
  'UA7408017003': 'Білещина',
  'UA7408017004': 'Білорічиця',
  'UA7408017005': 'Боротьба',
  'UA7408017006': 'Боршна',
  'UA7408017011': 'Дідівці',
  'UA7408017010': 'Димирівка',
  'UA7408017009': 'Густиня',
  'UA7408017018': 'Канівщина',
  'UA7408017019': 'Капустенці',
  'UA7408017020': 'Колісники',
  'UA7408017021': 'Кроти',
  'UA7408017022': 'Ладівщина',
  'UA7408017023': 'Левки',
  'UA7408017024': 'Лісове',
  'UA7408017025': 'Лісові Сорочинці',
  'UA7408017027': 'Малківка',
  'UA7408017028': 'Манжосівка',
  'UA7408017029': 'Маціївка',
  'UA7408017026': 'Мазки',
  'UA7408017031': 'Мільки',
  'UA7408017030': 'Миколаївка',
  'UA7408017032': 'Нетяжино',
  'UA7408017033': 'Нова Тарнавщина',
  'UA7408017035': 'Охіньки',
  'UA7408017034': 'Оникіївка',
  'UA7408017036': 'Переволочна',
  'UA7408017037': 'Петрівське',
  'UA7408017039': 'Піддубівка',
  'UA7408017040': 'Погреби',
  'UA7408017041': 'Покрівка',
  'UA7408017043': 'Пологи',
  'UA7408017042': 'Полова',
  'UA7408017045': 'Пручаї',
  'UA7408017044': 'Приозерне',
  'UA7408017038': 'Пирогівці',
  'UA7408017047': 'Ряшки',
  'UA7408017046': 'Рудівка',
  'UA7408017048': 'Смош',
  'UA7408017049': 'Стрільники',
  'UA7408017051': 'Сухоярівка',
  'UA7408017001': 'Сухополова',
  'UA7408017050': 'Сухоставець',
  'UA7408017052': 'Тарасівка',
  'UA7408017053': 'Тихе',
  'UA7408017007': 'Валки',
  'UA7408017008': 'Високе',
  'UA7408017054': 'Ярова Білещина',
  'UA7408017012': 'Єгорівка',
  'UA7408017013': 'Заїзд',
  'UA7408017014': 'Замістя',
  'UA7408017015': 'Зарудка',
  'UA7408017016': 'Заудаївське',
  'UA7408017017': 'Знам\'янка',
  'UA7408019002': 'Березівка',
  'UA7408019003': 'Березовиця',
  'UA7408019004': 'Болотниця',
  'UA7408019041': 'Чернецьке',
  'UA7408019009': 'Діброва',
  'UA7408019010': 'Довгалівка',
  'UA7408019005': 'Глибоке',
  'UA7408019006': 'Глинське',
  'UA7408019007': 'Грабщина',
  'UA7408019008': 'Грицівка',
  'UA7408019040': 'Харкове',
  'UA7408019013': 'Колядин',
  'UA7408019014': 'Корінецьке',
  'UA7408019015': 'Красний Колядин',
  'UA7408019016': 'Лавіркове',
  'UA7408019017': 'Левівщина',
  'UA7408019018': 'Липове',
  'UA7408019019': 'Макаренкове',
  'UA7408019020': 'Мигурів',
  'UA7408019021': 'Мирне',
  'UA7408019022': 'Нова',
  'UA7408019023': 'Новопетрівське',
  'UA7408019024': 'Новоселівка',
  'UA7408019025': 'Обухове',
  'UA7408019045': 'Основа',
  'UA7408019026': 'Петьків',
  'UA7408019027': 'Плугатар',
  'UA7408019028': 'Понори',
  'UA7408019029': 'Поповичка',
  'UA7408019031': 'Рябухи',
  'UA7408019030': 'Рубанів',
  'UA7408019042': 'Шевченка',
  'UA7408019032': 'Скороходове',
  'UA7408019033': 'Слобідка',
  'UA7408019034': 'Співакове',
  'UA7408019035': 'Стара Талалаївка',
  'UA7408019036': 'Степ',
  'UA7408019037': 'Степанівське',
  'UA7408019038': 'Степове',
  'UA7408019001': 'Талалаївка',
  'UA7408019039': 'Українське',
  'UA7408019043': 'Юрківці',
  'UA7408019012': 'Займище',
  'UA7408019044': 'Зелений Гай',
  'UA7408019011': 'Жолобок',
  'UA7408001002': 'Антонівка',
  'UA7408001003': 'Берізка',
  'UA7408001004': 'Богдани',
  'UA7408001005': 'Боханів',
  'UA7408001006': 'Брагинці',
  'UA7408001007': 'Булавівщина',
  'UA7408001011': 'Дащенки',
  'UA7408001009': 'Гнідинці',
  'UA7408001010': 'Григорівщина',
  'UA7408001013': 'Калиновиця',
  'UA7408001027': 'Хортиця',
  'UA7408001015': 'Кухарка',
  'UA7408001014': 'Кулишівка',
  'UA7408001016': 'Леляки',
  'UA7408001017': 'Макіївка',
  'UA7408001018': 'Макушиха',
  'UA7408001019': 'Мармизівка',
  'UA7408001020': 'Мудре',
  'UA7408001022': 'Остапівка',
  'UA7408001021': 'Озеряни',
  'UA7408001029': 'Рубанів',
  'UA7408001030': 'Саверське',
  'UA7408001025': 'Сіряківщина',
  'UA7408001024': 'Сіриків',
  'UA7408001023': 'Світличне',
  'UA7408001026': 'Тонка',
  'UA7408001001': 'Варва',
  'UA7408001008': 'Воскресенське',
  'UA7408001028': 'Ященків',
  'UA7408001012': 'Журавка',
  'UA7408021002': 'Білошапки',
  'UA7408021003': 'Дубовий Гай',
  'UA7408021005': 'Ковтунівка',
  'UA7408021006': 'Крутоярівка',
  'UA7408021007': 'Сергіївка',
  'UA7408021008': 'Сухоліски',
  'UA7408021001': 'Яблунівка',
  'UA7408021009': 'Яблунівське',
  'UA7408021004': 'Запереводське',
  'UA7306001002': 'Боянівка',
  'UA7306001001': 'Бояни',
  'UA7306001003': 'Гай',
  'UA7306001004': 'Припруття',
  'UA7306059001': 'Чагор',
  'UA7306059002': 'Кут',
  'UA7306059003': 'Луковиця',
  'UA7306059004': 'Молодія',
  'UA7306061001': 'Чернівці',
  'UA7306061003': 'Чорнівка',
  'UA7306061002': 'Коровія',
  'UA7306063002': 'Буденець',
  'UA7306063005': 'Череш',
  'UA7306063001': 'Чудей',
  'UA7306063004': 'Нова Красношора',
  'UA7306063003': 'Їжівці',
  'UA7306013002': 'Байраки',
  'UA7306013004': 'Дяківці',
  'UA7306013001': 'Герца',
  'UA7306013012': 'Хряцька',
  'UA7306013005': 'Куликівка',
  'UA7306013006': 'Лунка',
  'UA7306013007': 'Могилівка',
  'UA7306013008': 'Молниця',
  'UA7306013009': 'Петрашівка',
  'UA7306013010': 'Підвальне',
  'UA7306013011': 'Тернавка',
  'UA7306013003': 'Великосілля',
  'UA7306015009': 'Черепківці',
  'UA7306015008': 'Червона Діброва',
  'UA7306015002': 'Димка',
  'UA7306015001': 'Глибока',
  'UA7306015003': 'Михайлівка',
  'UA7306015004': 'Новий Вовчинець',
  'UA7306015005': 'Опришени',
  'UA7306015006': 'Слобідка',
  'UA7306015007': 'Стерче',
  'UA7306017001': 'Горішні Шерівці',
  'UA7306017004': 'Шубранець',
  'UA7306017002': 'Васловівці',
  'UA7306017003': 'Задубрівка',
  'UA7306021006': 'Чуньків',
  'UA7306021001': 'Кадубівці',
  'UA7306021005': 'Хрещатик',
  'UA7306021003': 'Кулівці',
  'UA7306021004': 'Репужинці',
  'UA7306021002': 'Василів',
  'UA7306023002': 'Багринівка',
  'UA7306023003': 'Біла Криниця',
  'UA7306023001': 'Кам\'янка',
  'UA7306023004': 'Старий Вовчинець',
  'UA7306025003': 'Дубове',
  'UA7306025002': 'Глибочок',
  'UA7306025001': 'Кам\'яна',
  'UA7306025005': 'Михальча',
  'UA7306025006': 'Спаська',
  'UA7306025007': 'Старі Бросківці',
  'UA7306025004': 'Заволока',
  'UA7306027002': 'Карапчів',
  'UA7306027001': 'Йорданешти',
  'UA7306029005': 'ДавидІвці',
  'UA7306029004': 'Гаврилівці',
  'UA7306029006': 'Іванківці',
  'UA7306029001': 'Кіцмань',
  'UA7306029007': 'Кліводин',
  'UA7306029008': 'Лашківка',
  'UA7306029009': 'Ошихліби',
  'UA7306029011': 'Шипинці',
  'UA7306029010': 'Суховерхів',
  'UA7306029002': 'Валява',
  'UA7306029003': 'Витилівка',
  'UA7306031001': 'Кострижівка',
  'UA7306031004': 'Прилипче',
  'UA7306031005': 'Степанівка',
  'UA7306031003': 'Йосипівка',
  'UA7306031002': 'Звенячин',
  'UA7306033001': 'Красноїльськ',
  'UA7306033002': 'Стара Красношора',
  'UA7306035002': 'Буда',
  'UA7306035001': 'Магала',
  'UA7306035003': 'Остриця',
  'UA7306035004': 'Прут',
  'UA7306035005': 'Рідківці',
  'UA7306037003': 'Біла',
  'UA7306037004': 'Бурдей',
  'UA7306037006': 'Драчинці',
  'UA7306037007': 'Дубівці',
  'UA7306037005': 'Глиниця',
  'UA7306037008': 'Коростувата',
  'UA7306037002': 'Лужани',
  'UA7306037001': 'Мамаївці',
  'UA7306037010': 'Нові Драчинці',
  'UA7306037009': 'Новий Киселів',
  'UA7306037011': 'Ревне',
  'UA7306037012': 'Стрілецький Кут',
  'UA7306039002': 'Берегомет',
  'UA7306039003': 'Клокічка',
  'UA7306039001': 'Неполоківці',
  'UA7306039004': 'Оршівці',
  'UA7306039005': 'П\'ядиківці',
  'UA7306039006': 'Реваківці',
  'UA7306041002': 'Берестя',
  'UA7306041004': 'Довжок',
  'UA7306041003': 'Динівці',
  'UA7306041006': 'Котелеве',
  'UA7306041007': 'Малинівка',
  'UA7306041008': 'Маршинці',
  'UA7306041001': 'Новоселиця',
  'UA7306041009': 'Ревківці',
  'UA7306041011': 'Рокитне',
  'UA7306041010': 'Рингач',
  'UA7306041014': 'Шишківці',
  'UA7306041012': 'Слобода',
  'UA7306041013': 'Строїнці',
  'UA7306041005': 'Зелений Гай',
  'UA7306043002': 'Банчени',
  'UA7306043004': 'Годинівка',
  'UA7306043005': 'Горбова',
  'UA7306043006': 'Луковиця',
  'UA7306043007': 'Мала Буда',
  'UA7306043009': 'Маморниця Вама',
  'UA7306043008': 'Маморниця',
  'UA7306043001': 'Остриця',
  'UA7306043010': 'Цурень',
  'UA7306043003': 'Велика Буда',
  'UA7306045002': 'Аршиця',
  'UA7306045003': 'Нижні Петрівці',
  'UA7306045001': 'Верхні Петрівці',
  'UA7306047003': 'Хлівище',
  'UA7306047002': 'Малятинці',
  'UA7306047004': 'Шишківці',
  'UA7306047001': 'Ставчани',
  'UA7306047005': 'Южинець',
  'UA7306049002': 'Банилів-Підгірний',
  'UA7306049003': 'Бобівці',
  'UA7306049004': 'Давидівка',
  'UA7306049005': 'Дібрівка',
  'UA7306049008': 'Комарівці',
  'UA7306049009': 'Косованка',
  'UA7306049010': 'Костинці',
  'UA7306049011': 'Нова Жадова',
  'UA7306049012': 'Нові Бросківці',
  'UA7306049013': 'Панка',
  'UA7306049014': 'Ропча',
  'UA7306049015': 'Слобода-Комарівці',
  'UA7306049016': 'Стара Жадова',
  'UA7306049001': 'Сторожинець',
  'UA7306049017': 'Ясени',
  'UA7306049006': 'Заболоття',
  'UA7306049007': 'Зруб-Комарівський',
  'UA7306051002': 'Корчівці',
  'UA7306051003': 'Купка',
  'UA7306051004': 'Петричанка',
  'UA7306051006': 'Просіка',
  'UA7306051005': 'Просикуряни',
  'UA7306051001': 'Сучевени',
  'UA7306053002': 'Поляна',
  'UA7306053003': 'Привороки',
  'UA7306053004': 'Станівці',
  'UA7306053001': 'Тарашани',
  'UA7306053005': 'Турятка',
  'UA7306055002': 'Буківка',
  'UA7306055004': 'Горбівці',
  'UA7306055005': 'Нижні Синівці',
  'UA7306055001': 'Тереблече',
  'UA7306055003': 'Верхні Синівці',
  'UA7306057002': 'Бочківці',
  'UA7306057003': 'Грозинці',
  'UA7306057004': 'Колінківці',
  'UA7306057001': 'Топорівці',
  'UA7306003009': 'Черленівка',
  'UA7306003003': 'Думени',
  'UA7306003008': 'Форосна',
  'UA7306003005': 'Костичани',
  'UA7306003006': 'Новоіванківці',
  'UA7306003010': 'Щербинці',
  'UA7306003007': 'Тарасівці',
  'UA7306003001': 'Ванчиківці',
  'UA7306003002': 'Ванчинець',
  'UA7306003004': 'Жилівка',
  'UA7306005002': 'Глибочок',
  'UA7306005003': 'Годилів',
  'UA7306005004': 'Снячів',
  'UA7306005005': 'Тисовець',
  'UA7306005001': 'Великий Кучурів',
  'UA7306007002': 'Бабин',
  'UA7306007003': 'Борівці',
  'UA7306007005': 'Киселів',
  'UA7306007006': 'Рудка',
  'UA7306007001': 'Веренчанка',
  'UA7306007004': 'Вимушів',
  'UA7306007007': 'Яблунівка',
  'UA7306009002': 'Брідок',
  'UA7306009003': 'Дорошівці',
  'UA7306009005': 'Мосорівка',
  'UA7306009004': 'Митків',
  'UA7306009006': 'Онут',
  'UA7306009007': 'Самушин',
  'UA7306009008': 'Товтри',
  'UA7306009001': 'Вікно',
  'UA7306011003': 'Грушівка',
  'UA7306011004': 'Круп\'янське',
  'UA7306011002': 'Валя Кузьмина',
  'UA7306011001': 'Волока',
  'UA7306065002': 'Баламутівка',
  'UA7306065003': 'Боянчук',
  'UA7306065008': 'Чорний Потік',
  'UA7306065005': 'Добринівці',
  'UA7306065004': 'Горошівці',
  'UA7306065006': 'Погорілівка',
  'UA7306065007': 'Ржавинці',
  'UA7306065001': 'Юрківці',
  'UA7306019003': 'Малий Кучурів',
  'UA7306019002': 'Вербівці',
  'UA7306019001': 'Заставна',
  'UA7304003002': 'Бабин',
  'UA7304003003': 'Бернове',
  'UA7304003004': 'Браїлівка',
  'UA7304003006': 'Бурдюг',
  'UA7304003005': 'Бузовиця',
  'UA7304003011': 'Дністрівка',
  'UA7304003010': 'Грушівці',
  'UA7304003012': 'Іванівці',
  'UA7304003001': 'Кельменці',
  'UA7304003013': 'Комарів',
  'UA7304003014': 'Коновка',
  'UA7304003015': 'Ленківці',
  'UA7304003016': 'Лукачівка',
  'UA7304003017': 'Майорка',
  'UA7304003018': 'Макарівка',
  'UA7304003019': 'Мошанець',
  'UA7304003020': 'Нагоряни',
  'UA7304003021': 'Нелипівці',
  'UA7304003022': 'Новоселиця',
  'UA7304003023': 'Перківці',
  'UA7304003024': 'Путрине',
  'UA7304003025': 'Росошани',
  'UA7304003026': 'Слобідка',
  'UA7304003007': 'Вартиківці',
  'UA7304003009': 'Вороновиця',
  'UA7304003008': 'Вовчинець',
  'UA7304019002': 'Анадоли',
  'UA7304019003': 'Атаки',
  'UA7304019004': 'Білівці',
  'UA7304019006': 'Данківці',
  'UA7304019007': 'Каплівка',
  'UA7304019001': 'Хотин',
  'UA7304019008': 'Круглик',
  'UA7304019009': 'Крутеньки',
  'UA7304019010': 'Пашківці',
  'UA7304019005': 'Ворничани',
  'UA7304019011': 'Ярівка',
  'UA7304005002': 'Блищадь',
  'UA7304005003': 'Гринячка',
  'UA7304005001': 'Клішківці',
  'UA7304005005': 'Корнешти',
  'UA7304005006': 'Малинці',
  'UA7304005007': 'Млинки',
  'UA7304005008': 'Перебиківці',
  'UA7304005009': 'Поляна',
  'UA7304005010': 'Рухотин',
  'UA7304005011': 'Санківці',
  'UA7304005012': 'Шилівці',
  'UA7304005004': 'Зелена Липа',
  'UA7304007003': 'Козиряни',
  'UA7304007004': 'Кроква',
  'UA7304007001': 'Лівинці',
  'UA7304007005': 'Михайлівка',
  'UA7304007006': 'Оселівка',
  'UA7304007007': 'Подвір\'ївка',
  'UA7304007002': 'Зелена',
  'UA7304009002': 'Балківці',
  'UA7304009003': 'Драниця',
  'UA7304009004': 'Кошуляни',
  'UA7304009001': 'Мамалига',
  'UA7304009005': 'Негринці',
  'UA7304009006': 'Несвоя',
  'UA7304009007': 'Подвірне',
  'UA7304009008': 'Стальнівці',
  'UA7304011003': 'Долиняни',
  'UA7304011005': 'Керстенці',
  'UA7304011001': 'Недобоївці',
  'UA7304011007': 'Ширівці',
  'UA7304011006': 'Ставчани',
  'UA7304011002': 'Владична',
  'UA7304011004': 'Зарожани',
  'UA7304013001': 'Новодністровськ',
  'UA7304015006': 'Чепоноси',
  'UA7304015002': 'Гордівці',
  'UA7304015003': 'Орестівка',
  'UA7304015004': 'Пригородок',
  'UA7304015005': 'Рашків',
  'UA7304015001': 'Рукшин',
  'UA7304017002': 'Білоусівка',
  'UA7304017003': 'Братанівка',
  'UA7304017007': 'Галиця',
  'UA7304017009': 'Грубна',
  'UA7304017008': 'Гвіздівці',
  'UA7304017010': 'Коболчин',
  'UA7304017011': 'Кормань',
  'UA7304017012': 'Кулішівка',
  'UA7304017013': 'Ломачинці',
  'UA7304017014': 'Лопатів',
  'UA7304017015': 'Михалкове',
  'UA7304017016': 'Непоротове',
  'UA7304017017': 'Новоолексіївка',
  'UA7304017019': 'Олексіївка',
  'UA7304017018': 'Ожеве',
  'UA7304017020': 'Покровка',
  'UA7304017022': 'Романківці',
  'UA7304017021': 'Розкопинці',
  'UA7304017023': 'Селище',
  'UA7304017024': 'Сербичани',
  'UA7304017025': 'Шебутинці',
  'UA7304017001': 'Сокиряни',
  'UA7304017004': 'Василівка',
  'UA7304017005': 'Вітрянка',
  'UA7304017006': 'Волошкове',
  'UA7304001002': 'Нова Слобода',
  'UA7304001004': 'Шишківці',
  'UA7304001003': 'Струмок',
  'UA7304001001': 'Вашківці',
  'UA7302001001': 'Банилів',
  'UA7302001002': 'Бережниця',
  'UA7302001003': 'Бережонка',
  'UA7302001004': 'Коритне',
  'UA7302003001': 'Берегомет',
  'UA7302003005': 'Долішній Шепіт',
  'UA7302003013': 'Фальків',
  'UA7302003007': 'Лекечі',
  'UA7302003009': 'Лопушна',
  'UA7302003010': 'Лукавці',
  'UA7302003008': 'Липовани',
  'UA7302003011': 'Майдан',
  'UA7302003012': 'Мигове',
  'UA7302003002': 'Вахнівці',
  'UA7302003003': 'Велике',
  'UA7302003004': 'Вовчинець',
  'UA7302003006': 'Заріччя',
  'UA7302005002': 'Брусенки',
  'UA7302005001': 'Брусниця',
  'UA7302005010': 'Чортория',
  'UA7302005005': 'Діброва',
  'UA7302005007': 'Кальнівці',
  'UA7302005008': 'Нижні Станівці',
  'UA7302005009': 'Остра',
  'UA7302005003': 'Верхні Станівці',
  'UA7302005004': 'Виноград',
  'UA7302005006': 'Зеленів',
  'UA7302011005': 'Довгопілля',
  'UA7302011003': 'Голошина',
  'UA7302011004': 'Греблина',
  'UA7302011001': 'Конятин',
  'UA7302011006': 'Плай',
  'UA7302011007': 'Плита',
  'UA7302011008': 'Самакова',
  'UA7302011009': 'Стебні',
  'UA7302011002': 'Великий Липовець',
  'UA7302011010': 'Яблуниця',
  'UA7302013005': 'Дихтинець',
  'UA7302013018': 'Фошки',
  'UA7302013003': 'Греблина',
  'UA7302013004': 'Гробище',
  'UA7302013007': 'Киселиці',
  'UA7302013008': 'Малий Дихтинець',
  'UA7302013009': 'Паркулина',
  'UA7302013010': 'Площі',
  'UA7302013011': 'Поляківське',
  'UA7302013001': 'Путила',
  'UA7302013013': 'Рипень',
  'UA7302013012': 'Рижа',
  'UA7302013014': 'Сергії',
  'UA7302013015': 'Соколій',
  'UA7302013016': 'Тесницька',
  'UA7302013017': 'Тораки',
  'UA7302013002': 'Випчина',
  'UA7302013006': 'Замогила',
  'UA7302015002': 'Андреківське',
  'UA7302015004': 'Галицівка',
  'UA7302015005': 'Лустун',
  'UA7302015006': 'Нижній Яловець',
  'UA7302015007': 'Плоска',
  'UA7302015008': 'Руська',
  'UA7302015009': 'Сарата',
  'UA7302015001': 'Селятин',
  'UA7302015010': 'Шепіт',
  'UA7302015003': 'Верхній Яловець',
  'UA7302017003': 'Бісків',
  'UA7302017002': 'Бисків',
  'UA7302017011': 'Хорови',
  'UA7302017004': 'Мариничі',
  'UA7302017005': 'Міжброди',
  'UA7302017006': 'Околена',
  'UA7302017007': 'Петраші',
  'UA7302017008': 'Підзахаричі',
  'UA7302017009': 'Розтоки',
  'UA7302017012': 'Шпетки',
  'UA7302017010': 'Товарниця',
  'UA7302017001': 'Усть-Путила',
  'UA7302017013': 'Ями',
  'UA7302007002': 'Бабине',
  'UA7302007006': 'Карапчів',
  'UA7302007007': 'Слобода-Банилів',
  'UA7302007003': 'Вали',
  'UA7302007001': 'Вашківці',
  'UA7302007004': 'Волока',
  'UA7302007005': 'Замостя',
  'UA7302009002': 'Багна',
  'UA7302009009': 'Черешенька',
  'UA7302009010': 'Чорногузи',
  'UA7302009004': 'Іспас',
  'UA7302009005': 'Кибаки',
  'UA7302009006': 'Майдан',
  'UA7302009007': 'Мілієве',
  'UA7302009008': 'Середній Майдан',
  'UA7302009003': 'Виженка',
  'UA7302009001': 'Вижниця',
  'UA1202033001': 'Чумаки',
  'UA1202033005': 'Іванівка',
  'UA1202033006': 'Маївка',
  'UA1202033008': 'Новоспаське',
  'UA1202033007': 'Нововасилівка',
  'UA1202033009': 'Приют',
  'UA1202033010': 'Тарасо-Шевченківка',
  'UA1202033002': 'Веселе',
  'UA1202033003': 'Виноградне',
  'UA1202033004': 'Вишневе',
  'UA1202033011': 'Зоря',
  'UA1202001002': 'Авіаторське',
  'UA1202001001': 'Дніпро',
  'UA1202003002': 'Кравцівка',
  'UA1202003001': 'Китайгород',
  'UA1202003004': 'Рудка',
  'UA1202003003': 'Рибалки',
  'UA1202003005': 'Щербинівка',
  'UA1202007002': 'Андріївка',
  'UA1202007001': 'Ляшківка',
  'UA1202007004': 'Лозуватка',
  'UA1202007005': 'Назаренки',
  'UA1202007006': 'Нетесівка',
  'UA1202007007': 'Орлівка',
  'UA1202007008': 'Помазанівка',
  'UA1202007009': 'Шарівка',
  'UA1202007003': 'Залелія',
  'UA1202005004': 'Діброва',
  'UA1202005001': 'Любимівка',
  'UA1202005005': 'Перше Травня',
  'UA1202005006': 'Придніпрянське',
  'UA1202005002': 'Веселе',
  'UA1202005003': 'Воронівка',
  'UA1202011004': 'Катеринівка',
  'UA1202011001': 'Могилів',
  'UA1202011012': 'Молодіжне',
  'UA1202011005': 'Новопідкряж',
  'UA1202011006': 'Новоселівка',
  'UA1202011007': 'Плавещина',
  'UA1202011008': 'Проточі',
  'UA1202011009': 'Салівка',
  'UA1202011010': 'Супина',
  'UA1202011011': 'Цибульківка',
  'UA1202011002': 'Єгорине',
  'UA1202011003': 'Зубківка',
  'UA1202009002': 'Благовіщенка',
  'UA1202009003': 'Долинське',
  'UA1202009009': 'Горького',
  'UA1202009001': 'Миколаївка',
  'UA1202009004': 'Нове',
  'UA1202009005': 'Новотаромське',
  'UA1202009006': 'Пашена Балка',
  'UA1202009010': 'Шевченко',
  'UA1202009007': 'Степове',
  'UA1202009008': 'Сурське',
  'UA1202013002': 'Братське',
  'UA1202013010': 'Червоний Садок',
  'UA1202013011': 'Чувилине',
  'UA1202013004': 'Дніпрове',
  'UA1202013005': 'Дороге',
  'UA1202013012': 'Дослідне',
  'UA1202013006': 'Кам\'янка',
  'UA1202013007': 'Майорка',
  'UA1202013001': 'Новоолександрівка',
  'UA1202013008': 'Ракшівка',
  'UA1202013009': 'Старі Кодаки',
  'UA1202013003': 'Волоське',
  'UA1202015002': 'Багате',
  'UA1202015003': 'Бутовичівка',
  'UA1202015009': 'Долинське',
  'UA1202015010': 'Дружелюбівка',
  'UA1202015006': 'Ганно-Мусіївка',
  'UA1202015007': 'Гаркушине',
  'UA1202015008': 'Григорівка',
  'UA1202015011': 'Іверське',
  'UA1202015012': 'Кашкарівка',
  'UA1202015014': 'Котлярівка',
  'UA1202015015': 'Кринички',
  'UA1202015013': 'Квітуче',
  'UA1202015016': 'Мала Калинівка',
  'UA1202015017': 'Малинове',
  'UA1202015021': 'Михайлівка',
  'UA1202015018': 'Миколо-Мусіївка',
  'UA1202015019': 'Мирне',
  'UA1202015020': 'Миропіль',
  'UA1202015022': 'Новоандріївка',
  'UA1202015001': 'Новопокровка',
  'UA1202015023': 'Олександрівка',
  'UA1202015024': 'Олександропіль',
  'UA1202015025': 'Осипенко',
  'UA1202015026': 'Павлівка',
  'UA1202015027': 'Петриківка',
  'UA1202015028': 'Пропашне',
  'UA1202015029': 'Розтання',
  'UA1202015030': 'Суданівка',
  'UA1202015031': 'Товариський Труд',
  'UA1202015032': 'Тракторне',
  'UA1202015033': 'Тихе',
  'UA1202015004': 'Вільне',
  'UA1202015005': 'Водяне',
  'UA1202017003': 'Горянівське',
  'UA1202017002': 'Миколаївка',
  'UA1202017001': 'Обухівка',
  'UA1202019018': 'Чаплинка',
  'UA1202019003': 'Гречане',
  'UA1202019005': 'Іванівка',
  'UA1202019017': 'Хутірське',
  'UA1202019006': 'Клешнівка',
  'UA1202019007': 'Кулішеве',
  'UA1202019008': 'Куліші',
  'UA1202019002': 'Курилівка',
  'UA1202019009': 'Лобойківка',
  'UA1202019010': 'Мала Петриківка',
  'UA1202019001': 'Петриківка',
  'UA1202019011': 'Плавещина',
  'UA1202019012': 'Радісне',
  'UA1202019019': 'Шульгівка',
  'UA1202019013': 'Сорочине',
  'UA1202019014': 'Сотницьке',
  'UA1202019015': 'Судівка',
  'UA1202019016': 'Улянівка',
  'UA1202019004': 'Єлизаветівка',
  'UA1202021002': 'Дмитрівка',
  'UA1202021005': 'Хуторо-Губиниха',
  'UA1202021003': 'Перемога',
  'UA1202021001': 'Підгородне',
  'UA1202021004': 'Спаське',
  'UA1202025002': 'Балівка',
  'UA1202025004': 'Олександрівка',
  'UA1202025005': 'Партизанське',
  'UA1202025001': 'Слобожанське',
  'UA1202025006': 'Степове',
  'UA1202025003': 'Василівка',
  'UA1202027002': 'Антонівка',
  'UA1202027003': 'Аполлонівка',
  'UA1202027004': 'Башмачка',
  'UA1202027005': 'Безбородькове',
  'UA1202027006': 'Богданівка',
  'UA1202027047': 'Червонокам\'яне',
  'UA1202027014': 'Дніпровське',
  'UA1202027015': 'Дороганівка',
  'UA1202027011': 'Гайдамацьке',
  'UA1202027012': 'Гончарка',
  'UA1202027013': 'Гроза',
  'UA1202027018': 'Калинівка',
  'UA1202027019': 'Кам\'яне',
  'UA1202027020': 'Кам\'яно-Зубилівка',
  'UA1202027021': 'Круте',
  'UA1202027023': 'Любов',
  'UA1202027022': 'Любимівка',
  'UA1202027026': 'Маяк',
  'UA1202027025': 'Малозахарине',
  'UA1202027024': 'Малинівка',
  'UA1202027027': 'Микільське',
  'UA1202027028': 'Микільське-на-Дніпрі',
  'UA1202027051': 'Надіївка',
  'UA1202027029': 'Новоселівка',
  'UA1202027030': 'Новотарасівка',
  'UA1202027031': 'Новотернуватка',
  'UA1202027032': 'Олексіївка',
  'UA1202027033': 'Оріхове',
  'UA1202027034': 'Панькове',
  'UA1202027035': 'Перше Травня',
  'UA1202027036': 'Петрівське',
  'UA1202027037': 'Петро-Свистунове',
  'UA1202027039': 'Привільне',
  'UA1202027040': 'Пшеничне',
  'UA1202027038': 'Письмечеве',
  'UA1202027041': 'Сергіївка',
  'UA1202027048': 'Шестипілля',
  'UA1202027049': 'Широке',
  'UA1202027050': 'Широкополе',
  'UA1202027001': 'Солоне',
  'UA1202027042': 'Сонячне',
  'UA1202027043': 'Стародніпровське',
  'UA1202027044': 'Сурсько-Михайлівка',
  'UA1202027046': 'Трудолюбівка',
  'UA1202027045': 'Тритузне',
  'UA1202027007': 'Василівка',
  'UA1202027009': 'Військове',
  'UA1202027010': 'Вовніги',
  'UA1202027008': 'Вишневе',
  'UA1202027016': 'Звонецьке',
  'UA1202027017': 'Звонецький Хутір',
  'UA1202029003': 'Новомиколаївка',
  'UA1202029004': 'Сурсько-Клевцеве',
  'UA1202029001': 'Сурсько-Литовське',
  'UA1202029002': 'Зелений Гай',
  'UA1202023002': 'Барвінок',
  'UA1202023003': 'Березнуватівка',
  'UA1202023019': 'Чернігівка',
  'UA1202023020': 'Чорнопарівка',
  'UA1202023006': 'Дальнє',
  'UA1202023004': 'Голубинівка',
  'UA1202023005': 'Грушівка',
  'UA1202023007': 'Карайкове',
  'UA1202023018': 'Хижине',
  'UA1202023008': 'Костянтинівка',
  'UA1202023009': 'Матросове',
  'UA1202023010': 'Межове',
  'UA1202023011': 'Наталівка',
  'UA1202023012': 'Незабудине',
  'UA1202023023': 'Незабудине',
  'UA1202023013': 'Новомар\'ївка',
  'UA1202023014': 'Орлове',
  'UA1202023015': 'Промінь',
  'UA1202023016': 'Рясне',
  'UA1202023021': 'Шульгівка',
  'UA1202023001': 'Святовасилівка',
  'UA1202023017': 'Томаківка',
  'UA1202023022': 'Якимівка',
  'UA1202031002': 'Бабайківка',
  'UA1202031022': 'Червона Орілька',
  'UA1202031005': 'Драгівка',
  'UA1202031006': 'Дубове',
  'UA1202031004': 'Гнатівка',
  'UA1202031008': 'Івано-Яризівка',
  'UA1202031009': 'Калинівка',
  'UA1202031010': 'Кущівка',
  'UA1202031011': 'Лисківка',
  'UA1202031012': 'Михайлівка',
  'UA1202031013': 'Ненадівка',
  'UA1202031014': 'Новостроївка',
  'UA1202031016': 'Преображенка',
  'UA1202031017': 'Прядівка',
  'UA1202031015': 'Пилипівка',
  'UA1202031018': 'Селянівка',
  'UA1202031019': 'Семенівка',
  'UA1202031020': 'Тарасівка',
  'UA1202031001': 'Царичанка',
  'UA1202031021': 'Турове',
  'UA1202031003': 'Вербове',
  'UA1202031023': 'Юр\'ївка',
  'UA1202031007': 'Заорілля',
  'UA1204001002': 'Адамівка',
  'UA1204001003': 'Андріївка',
  'UA1204001005': 'Благословенна',
  'UA1204001007': 'Болтишка',
  'UA1204001001': 'Божедарівка',
  'UA1204001006': 'Божедарівка',
  'UA1204001004': 'Бикове',
  'UA1204001040': 'Червоний Орлик',
  'UA1204001013': 'Грузинівка',
  'UA1204001017': 'Калинівка',
  'UA1204001018': 'Катеринівка',
  'UA1204001019': 'Катеринопіль',
  'UA1204001020': 'Ковалівка',
  'UA1204001021': 'Крута Балка',
  'UA1204001022': 'Кудашівка',
  'UA1204001023': 'Людмилівка',
  'UA1204001041': 'Милорадівка',
  'UA1204001024': 'Надія',
  'UA1204001025': 'Нова Праця',
  'UA1204001026': 'Новогурівка',
  'UA1204001028': 'Новомилорадівка',
  'UA1204001027': 'Новожитлівка',
  'UA1204001029': 'Олександрівка',
  'UA1204001030': 'Олексіївка',
  'UA1204001031': 'Павлівка',
  'UA1204001032': 'Покровка',
  'UA1204001033': 'Поляна',
  'UA1204001034': 'Потоки',
  'UA1204001035': 'Привілля',
  'UA1204001036': 'Саксаганське',
  'UA1204001037': 'Скелюватка',
  'UA1204001042': 'Сорокопанівка',
  'UA1204001038': 'Теплівка',
  'UA1204001039': 'Трудове',
  'UA1204001008': 'Василівка',
  'UA1204001009': 'Весела Долина',
  'UA1204001010': 'Веселе',
  'UA1204001011': 'Вільне',
  'UA1204001012': 'Водяне',
  'UA1204001014': 'Зелена Долина',
  'UA1204001015': 'Зелений Яр',
  'UA1204001016': 'Зоря',
  'UA1204015001': 'Кам\'янське',
  'UA1204015002': 'Карнаухівка',
  'UA1204015003': 'Світле',
  'UA1204017002': 'Аули',
  'UA1204017003': 'Барвінок',
  'UA1204017044': 'Чернече',
  'UA1204017043': 'Червоноіванівка',
  'UA1204017041': 'Червоний Став',
  'UA1204017042': 'Червоний Яр',
  'UA1204017009': 'Діброва',
  'UA1204017010': 'Дружба',
  'UA1204017008': 'Грушівка',
  'UA1204017007': 'Гримуче',
  'UA1204017013': 'Іллінка',
  'UA1204017014': 'Кам\'янчани',
  'UA1204017016': 'Коробчине',
  'UA1204017017': 'Котлярівка',
  'UA1204017015': 'Козодуб',
  'UA1204017001': 'Кринички',
  'UA1204017019': 'Любомирівка',
  'UA1204017018': 'Любимівка',
  'UA1204017021': 'Малярщина',
  'UA1204017020': 'Маломихайлівка',
  'UA1204017022': 'Мирне',
  'UA1204017023': 'Новокалинівка',
  'UA1204017024': 'Новопідгірне',
  'UA1204017025': 'Новопушкарівка',
  'UA1204017026': 'Новоселівка',
  'UA1204017027': 'Одарівка',
  'UA1204017028': 'Оленівка',
  'UA1204017029': 'Первозванівка',
  'UA1204017030': 'Підгірне',
  'UA1204017031': 'Прапор',
  'UA1204017032': 'Промінь',
  'UA1204017033': 'Рогівське',
  'UA1204017035': 'Семенівка',
  'UA1204017045': 'Шмакове',
  'UA1204017036': 'Степанівка',
  'UA1204017038': 'Сухий Хутір',
  'UA1204017037': 'Суворовське',
  'UA1204017034': 'Світлогірське',
  'UA1204017039': 'Українка',
  'UA1204017040': 'Улянівка',
  'UA1204017004': 'Весела Роща',
  'UA1204017006': 'Володимирівка',
  'UA1204017005': 'Вишневе',
  'UA1204017046': 'Яблуневе',
  'UA1204017011': 'Єгорівка',
  'UA1204017012': 'Зелений Кут',
  'UA1204019002': 'Байдаківка',
  'UA1204019003': 'Березняк',
  'UA1204019004': 'Біленщина',
  'UA1204019025': 'Червона Деріївка',
  'UA1204019024': 'Червона Гірка',
  'UA1204019007': 'Грамівка',
  'UA1204019008': 'Григорівка',
  'UA1204019011': 'Катеринівка',
  'UA1204019001': 'Лихівка',
  'UA1204019012': 'Липове',
  'UA1204019015': 'Михайлівка',
  'UA1204019013': 'Миколаївка',
  'UA1204019014': 'Миронівка',
  'UA1204019016': 'Новотроїцьке',
  'UA1204019017': 'Олександро-Григорівка',
  'UA1204019018': 'Плоске',
  'UA1204019019': 'Плоско-Таранівка',
  'UA1204019020': 'Райдужне',
  'UA1204019021': 'Степове',
  'UA1204019022': 'Троїцьке',
  'UA1204019023': 'Цвіле',
  'UA1204019005': 'Верхньокам\'яниста',
  'UA1204019006': 'Володимирівка',
  'UA1204019026': 'Яковлівка',
  'UA1204019009': 'Зелений Клин',
  'UA1204019010': 'Золотницьке',
  'UA1204021027': 'Авангард',
  'UA1204021002': 'Богдано-Надеждівка',
  'UA1204021026': 'Чистопіль',
  'UA1204021005': 'Дмитрівка',
  'UA1204021010': 'Івашинівка',
  'UA1204021011': 'Калинівка',
  'UA1204021012': 'Касинівка',
  'UA1204021013': 'Красна Воля',
  'UA1204021014': 'Красний Луг',
  'UA1204021015': 'Культура',
  'UA1204021031': 'Мирне',
  'UA1204021016': 'Миролюбівка',
  'UA1204021017': 'Нововасилівка',
  'UA1204021018': 'Новозалісся',
  'UA1204021019': 'Осикувате',
  'UA1204021020': 'Пальмирівка',
  'UA1204021021': 'Петрівка',
  'UA1204021001': 'П\'ятихатки',
  'UA1204021022': 'Полтавобоголюбівка',
  'UA1204021023': 'Ровеньки',
  'UA1204021024': 'Суханівка',
  'UA1204021025': 'Трудолюбівка',
  'UA1204021028': 'Вершинне',
  'UA1204021003': 'Веселий Поділ',
  'UA1204021004': 'Виноградівка',
  'UA1204021008': 'Запоріжжя',
  'UA1204021029': 'Зелене',
  'UA1204021009': 'Зелений Луг',
  'UA1204021006': 'Жовте',
  'UA1204021007': 'Жовтоолександрівка',
  'UA1204021030': 'Зоря',
  'UA1204023002': 'Балкове',
  'UA1204023017': 'Червона Поляна',
  'UA1204023019': 'Чумаки',
  'UA1204023018': 'Чигринівка',
  'UA1204023006': 'Демурино-Варварівка',
  'UA1204023007': 'Долинське',
  'UA1204023004': 'Галина Лозуватка',
  'UA1204023005': 'Грушуватка',
  'UA1204023008': 'Кам\'яне',
  'UA1204023009': 'Красноіванівка',
  'UA1204023010': 'Нерудсталь',
  'UA1204023011': 'Новоіванівка',
  'UA1204023013': 'Саївка',
  'UA1204023001': 'Саксагань',
  'UA1204023012': 'Савро',
  'UA1204023014': 'Семенівка',
  'UA1204023015': 'Тернувате',
  'UA1204023016': 'Цівки',
  'UA1204023003': 'Вільне',
  'UA1204003002': 'Адалимівка',
  'UA1204003004': 'Дубове',
  'UA1204003003': 'Гранове',
  'UA1204003005': 'Калинівка',
  'UA1204003006': 'Малоолександрівка',
  'UA1204003007': 'Петрівка',
  'UA1204003008': 'Полівське',
  'UA1204003009': 'Саксагань',
  'UA1204003010': 'Широке',
  'UA1204003011': 'Соколівка',
  'UA1204003001': 'Верхівцеве',
  'UA1204005005': 'Андріївка',
  'UA1204005004': 'Авксенівка',
  'UA1204005007': 'Бородаївка',
  'UA1204005008': 'Бородаївські Хутори',
  'UA1204005006': 'Боровківка',
  'UA1204005009': 'Братське',
  'UA1204005045': 'Чепине',
  'UA1204005046': 'Чкаловка',
  'UA1204005015': 'Діденкове',
  'UA1204005016': 'Дніпровокам\'янка',
  'UA1204005002': 'Дніпровське',
  'UA1204005017': 'Домоткань',
  'UA1204005014': 'Ганнівка',
  'UA1204005022': 'Івашкове',
  'UA1204005023': 'Калужине',
  'UA1204005024': 'Клин',
  'UA1204005025': 'Корнило-Наталівка',
  'UA1204005026': 'Кривоносове',
  'UA1204005027': 'Матюченкове',
  'UA1204005030': 'Мости',
  'UA1204005028': 'Миколаївка',
  'UA1204005029': 'Мишурин Ріг',
  'UA1204005031': 'Новогригорівка',
  'UA1204005003': 'Новомиколаївка',
  'UA1204005032': 'Новоселівка',
  'UA1204005033': 'Павлівка',
  'UA1204005034': 'Павло-Григорівка',
  'UA1204005035': 'Перше Травня',
  'UA1204005036': 'Підлужжя',
  'UA1204005037': 'Попівка',
  'UA1204005038': 'Правобережне',
  'UA1204005039': 'Пушкарівка',
  'UA1204005040': 'Самоткань',
  'UA1204005041': 'Солов\'ївка',
  'UA1204005042': 'Суслівка',
  'UA1204005043': 'Тарасівка',
  'UA1204005044': 'Томаківка',
  'UA1204005010': 'Василівка',
  'UA1204005001': 'Верхньодніпровськ',
  'UA1204005011': 'Вільні Хутори',
  'UA1204005012': 'Водяне',
  'UA1204005013': 'Воєводівка',
  'UA1204005047': 'Якимівка',
  'UA1204005048': 'Ярок',
  'UA1204005018': 'Заполички',
  'UA1204005019': 'Заріччя',
  'UA1204005020': 'Зелене',
  'UA1204005021': 'Зуботрясівка',
  'UA1204009002': 'Дідове',
  'UA1204009003': 'Дмитрівка',
  'UA1204009004': 'Доброгірське',
  'UA1204009005': 'Кринички',
  'UA1204009006': 'Кушнарівка',
  'UA1204009007': 'Мар\'янівка',
  'UA1204009008': 'Мотронівка',
  'UA1204009009': 'Новоганнівка',
  'UA1204009010': 'Посуньки',
  'UA1204009011': 'Соколове',
  'UA1204009001': 'Вільногірськ',
  'UA1204007002': 'Байківка',
  'UA1204007010': 'Червоний Яр',
  'UA1204007003': 'Іванівка',
  'UA1204007004': 'Комісарівка',
  'UA1204007005': 'Кулябкине',
  'UA1204007007': 'Лозуватка',
  'UA1204007006': 'Ликошине',
  'UA1204007008': 'Новоукраїнка',
  'UA1204007009': 'Терно-Лозуватка',
  'UA1204007001': 'Вишневе',
  'UA1204013002': 'Березине',
  'UA1204013003': 'Благодатне',
  'UA1204013004': 'Бузулуки',
  'UA1204013022': 'Черкаське',
  'UA1204013020': 'Червона Балка',
  'UA1204013021': 'Червоне',
  'UA1204013023': 'Гранітне',
  'UA1204013007': 'Гуляйполе',
  'UA1204013008': 'Козаче',
  'UA1204013009': 'Лозуватка',
  'UA1204013010': 'Лозуватське',
  'UA1204013011': 'Лугове',
  'UA1204013012': 'Малософіївка',
  'UA1204013013': 'Михайлівка',
  'UA1204013014': 'Первомайське',
  'UA1204013015': 'Преображенка',
  'UA1204013016': 'Смоленка',
  'UA1204013017': 'Тарасівка',
  'UA1204013018': 'Удачне',
  'UA1204013019': 'Українське',
  'UA1204013006': 'Вітрівка',
  'UA1204013005': 'Високе',
  'UA1204013001': 'Затишне',
  'UA1204011003': 'Мар\'янівка',
  'UA1204011004': 'Суха Балка',
  'UA1204011002': 'Волочаївка',
  'UA1204011001': 'Жовті Води',
  'UA1206001001': 'Апостолове',
  'UA1206001016': 'Червона Колона',
  'UA1206001004': 'Кам\'янка',
  'UA1206001005': 'Катеринівка',
  'UA1206001006': 'Михайлівка',
  'UA1206001007': 'Михайло-Заводське',
  'UA1206001008': 'Нова Січ',
  'UA1206001009': 'Новоіванівка',
  'UA1206001010': 'Новомар\'янівка',
  'UA1206001011': 'Новосеменівка',
  'UA1206001012': 'Перше Травня',
  'UA1206001013': 'Сергіївка',
  'UA1206001017': 'Шевченко',
  'UA1206001018': 'Широчани',
  'UA1206001014': 'Слов\'янка',
  'UA1206001015': 'Тарасо-Григорівка',
  'UA1206001020': 'Українка',
  'UA1206001002': 'Володимирівка',
  'UA1206001003': 'Запорізьке',
  'UA1206001019': 'Жовте',
  'UA1206011002': 'Андріївка',
  'UA1206011031': 'Червоне Поле',
  'UA1206011032': 'Червоний Яр',
  'UA1206011001': 'Девладове',
  'UA1206011010': 'Довгівка',
  'UA1206011007': 'Ганно-Миколаївка',
  'UA1206011008': 'Гончарове',
  'UA1206011009': 'Грушки',
  'UA1206011014': 'Кодак',
  'UA1206011013': 'Ковалеве',
  'UA1206011015': 'Кринички',
  'UA1206011016': 'Криничувате',
  'UA1206011017': 'Любе',
  'UA1206011018': 'Макорти',
  'UA1206011019': 'Мар\'є-Дмитрівка',
  'UA1206011020': 'Мар\'є-Костянтинівка',
  'UA1206011021': 'Мар\'ївка',
  'UA1206011022': 'Мотина Балка',
  'UA1206011023': 'Нова Зоря',
  'UA1206011024': 'Новомихайлівка',
  'UA1206011025': 'Олександрівка',
  'UA1206011026': 'Ордо-Василівка',
  'UA1206011027': 'Перше Травня',
  'UA1206011034': 'Потоцьке',
  'UA1206011028': 'Райполе',
  'UA1206011029': 'Сергіївка',
  'UA1206011030': 'Спокойствіє',
  'UA1206011003': 'Вербове',
  'UA1206011004': 'Веселе Поле',
  'UA1206011005': 'Водяне',
  'UA1206011006': 'Володимирівка',
  'UA1206011033': 'Южне',
  'UA1206011011': 'Зав\'ялівка',
  'UA1206011012': 'Зелений Гай',
  'UA1206005016': 'Чабанове',
  'UA1206005017': 'Червоне',
  'UA1206005001': 'Глеюватка',
  'UA1206005004': 'Гомельське',
  'UA1206005008': 'Калинівка',
  'UA1206005009': 'Кам\'яне Поле',
  'UA1206005010': 'Красна Балка',
  'UA1206005011': 'Лісове',
  'UA1206005012': 'Недайвода',
  'UA1206005013': 'Новоіванівка',
  'UA1206005014': 'Новопокровка',
  'UA1206005019': 'Рудничне',
  'UA1206005018': 'Шевченківське',
  'UA1206005015': 'Тернуватка',
  'UA1206005002': 'Веселий Кут',
  'UA1206005003': 'Високе Поле',
  'UA1206005005': 'Запорожець',
  'UA1206005006': 'Зелене Поле',
  'UA1206005007': 'Зоря',
  'UA1206007001': 'Гречані Поди',
  'UA1206007003': 'Калинівка',
  'UA1206007005': 'Красний Під',
  'UA1206007004': 'Кравці',
  'UA1206007006': 'Кряжове',
  'UA1206007007': 'Миролюбівка',
  'UA1206007008': 'Нове Життя',
  'UA1206007010': 'Олександрівка',
  'UA1206007009': 'Озерне',
  'UA1206007011': 'Подове',
  'UA1206007012': 'Пологи',
  'UA1206007014': 'Степове',
  'UA1206007013': 'Свистунове',
  'UA1206007015': 'Трудолюбівка',
  'UA1206007002': 'Водяне',
  'UA1206009003': 'Червоний Тік',
  'UA1206009007': 'Червоний Запорожець',
  'UA1206009004': 'Гранітне',
  'UA1206009001': 'Грушівка',
  'UA1206009005': 'Тік',
  'UA1206009006': 'Токівське',
  'UA1206009002': 'Усть-Кам\'янка',
  'UA1206015002': 'Андріївка',
  'UA1206015022': 'Червоне',
  'UA1206015006': 'Дем\'янівка',
  'UA1206015005': 'Городуватка',
  'UA1206015001': 'Карпівка',
  'UA1206015009': 'Казанківка',
  'UA1206015010': 'Малинівка',
  'UA1206015013': 'Могилівка',
  'UA1206015011': 'Миколаївка',
  'UA1206015012': 'Мирне',
  'UA1206015014': 'Новомалинівка',
  'UA1206015015': 'Олександрія',
  'UA1206015016': 'Плугатар',
  'UA1206015017': 'Полтавка',
  'UA1206015018': 'Радевичеве',
  'UA1206015019': 'Розівка',
  'UA1206015023': 'Широка Дача',
  'UA1206015024': 'Широка Долина',
  'UA1206015021': 'Цвіткове',
  'UA1206015020': 'Тихий Став',
  'UA1206015003': 'Веселий Став',
  'UA1206015004': 'Вишневе',
  'UA1206015025': 'Яблунівка',
  'UA1206015026': 'Явдотівка',
  'UA1206015007': 'Зелений Гай',
  'UA1206015008': 'Зелений Став',
  'UA1206017004': 'Авангард',
  'UA1206017005': 'Гірницьке',
  'UA1206017006': 'Коломійцеве',
  'UA1206017001': 'Кривий Ріг',
  'UA1206017002': 'Новоіванівка',
  'UA1206017003': 'Тернуватий Кут',
  'UA1206019003': 'Анастасівка',
  'UA1206019004': 'Базарове',
  'UA1206019032': 'Червона Поляна',
  'UA1206019030': 'Чкаловка',
  'UA1206019009': 'Данилівка',
  'UA1206019010': 'Дружба',
  'UA1206019006': 'Гейківка',
  'UA1206019007': 'Грузька Григорівка',
  'UA1206019008': 'Грузьке',
  'UA1206019015': 'Інгулець',
  'UA1206019013': 'Іванівка',
  'UA1206019014': 'Іванівка',
  'UA1206019002': 'Христофорівка',
  'UA1206019016': 'Кривбас',
  'UA1206019017': 'Кудашівка',
  'UA1206019001': 'Лозуватка',
  'UA1206019018': 'Мар\'янівка',
  'UA1206019031': 'Мусіївка',
  'UA1206019019': 'Надія',
  'UA1206019022': 'Новоганнівка',
  'UA1206019023': 'Новолозуватка',
  'UA1206019020': 'Новий Кременчук',
  'UA1206019021': 'Новий Мир',
  'UA1206019024': 'Павлівка',
  'UA1206019025': 'Радіонівка',
  'UA1206019026': 'Раєво-Олександрівка',
  'UA1206019027': 'Ранній Ранок',
  'UA1206019028': 'Софіївка',
  'UA1206019029': 'Тернівка',
  'UA1206019005': 'Вільне',
  'UA1206019011': 'Зелений Гай',
  'UA1206019012': 'Зелений Луг',
  'UA1206023010': 'Червоний Ранок',
  'UA1206023003': 'Інгулець',
  'UA1206023004': 'Курганка',
  'UA1206023005': 'Латівка',
  'UA1206023006': 'Макарівка',
  'UA1206023007': 'Нове',
  'UA1206023001': 'Новолатівка',
  'UA1206023008': 'Новоселівка',
  'UA1206023011': 'Шведове',
  'UA1206023009': 'Стародобровільське',
  'UA1206023002': 'Зелена Балка',
  'UA1206025003': 'Андрусівка',
  'UA1206025004': 'Братсько-Семенівка',
  'UA1206025005': 'Бурлацьке',
  'UA1206025033': 'Червоні Поди',
  'UA1206025010': 'Дніпровка',
  'UA1206025014': 'Кам\'янське',
  'UA1206025015': 'Коломійцеве',
  'UA1206025016': 'Красівське',
  'UA1206025017': 'Красне',
  'UA1206025036': 'Лісопитомник',
  'UA1206025018': 'Львів',
  'UA1206025019': 'Маяк',
  'UA1206025020': 'Надеждівка',
  'UA1206025021': 'Нова Зоря',
  'UA1206025037': 'Нові Садки',
  'UA1206025023': 'Новогригорівка',
  'UA1206025025': 'Новомайське',
  'UA1206025026': 'Новомар\'янівське',
  'UA1206025001': 'Новопілля',
  'UA1206025027': 'Новоселівка',
  'UA1206025024': 'Новожитомир',
  'UA1206025022': 'Новий Шлях',
  'UA1206025038': 'Пичугине',
  'UA1206025002': 'Радушне',
  'UA1206025028': 'Романівка',
  'UA1206025029': 'Садове',
  'UA1206025034': 'Шевченкове',
  'UA1206025035': 'Широке',
  'UA1206025030': 'Степове',
  'UA1206025031': 'Суворовка',
  'UA1206025032': 'Трудове',
  'UA1206025006': 'Веселе',
  'UA1206025007': 'Вільний Посад',
  'UA1206025008': 'Вільний Табір',
  'UA1206025009': 'Водяне',
  'UA1206025011': 'Златопіль',
  'UA1206025012': 'Златоустівка',
  'UA1206025013': 'Золота Поляна',
  'UA1206021006': 'Новоукраїнське',
  'UA1206021001': 'Нива Трудова',
  'UA1206021007': 'Садове',
  'UA1206021008': 'Солдатське',
  'UA1206021002': 'Веселі Чумаки',
  'UA1206021003': 'Вільне',
  'UA1206021004': 'Єлизаветпілля',
  'UA1206021005': 'Зоряне',
  'UA1206029002': 'Благодатне',
  'UA1206029006': 'Дачне',
  'UA1206029004': 'Ганнівка',
  'UA1206029005': 'Григорівка',
  'UA1206029008': 'Кошове',
  'UA1206029009': 'Надія',
  'UA1206029010': 'Новокурське',
  'UA1206029011': 'Новоукраїнка',
  'UA1206029012': 'Одрадне',
  'UA1206029013': 'Оленівка',
  'UA1206029014': 'Подидар',
  'UA1206029016': 'Шестірня',
  'UA1206029001': 'Широке',
  'UA1206029003': 'Весела Дача',
  'UA1206029007': 'Запоріжжя',
  'UA1206027002': 'Авдотівка',
  'UA1206027003': 'Братське',
  'UA1206027009': 'Ізлучисте',
  'UA1206027010': 'Кам\'янка',
  'UA1206027011': 'Катеринівка',
  'UA1206027012': 'Катерино-Наталівка',
  'UA1206027013': 'Любимівка',
  'UA1206027029': 'Лошкарівка',
  'UA1206027015': 'Михайлівка',
  'UA1206027014': 'Миколаївка',
  'UA1206027016': 'Назарівка',
  'UA1206027017': 'Непереможне',
  'UA1206027020': 'Новоюлівка',
  'UA1206027019': 'Новохортиця',
  'UA1206027018': 'Новопетрівка',
  'UA1206027021': 'Олександро-Білівка',
  'UA1206027022': 'Олексіївка',
  'UA1206027023': 'Петрівка',
  'UA1206027024': 'Петрове',
  'UA1206027028': 'Широке',
  'UA1206027001': 'Софіївка',
  'UA1206027025': 'Степове',
  'UA1206027026': 'Тарасівка',
  'UA1206027027': 'Трудолюбівка',
  'UA1206027005': 'Вільне Життя',
  'UA1206027006': 'Володимирівка',
  'UA1206027004': 'Вишневе',
  'UA1206027007': 'Запорізьке',
  'UA1206027008': 'Зелене',
  'UA1206003002': 'Базавлучок',
  'UA1206003003': 'Дачне',
  'UA1206003005': 'Калашники',
  'UA1206003006': 'Мар\'ївка',
  'UA1206003007': 'Нові Ковна',
  'UA1206003010': 'Новоолексіївка',
  'UA1206003011': 'Новоподільське',
  'UA1206003008': 'Нововасилівка',
  'UA1206003009': 'Нововітебське',
  'UA1206003012': 'Одрубок',
  'UA1206003013': 'Павлівка',
  'UA1206003014': 'Петропавлівка',
  'UA1206003015': 'Садове',
  'UA1206003016': 'Тернуватка',
  'UA1206003017': 'Українка',
  'UA1206003001': 'Вакулове',
  'UA1206003018': 'Явдохівка',
  'UA1206003004': 'Жовте',
  'UA1206013003': 'Мала Костромка',
  'UA1206013004': 'Мар\'янське',
  'UA1206013002': 'Велика Костромка',
  'UA1206013001': 'Зеленодольськ',
  'UA1208015002': 'Борисівка',
  'UA1208015001': 'Червоногригорівка',
  'UA1208015003': 'Дмитрівка',
  'UA1208015007': 'Кам\'янське',
  'UA1208015004': 'Мусіївка',
  'UA1208015006': 'Придніпровське',
  'UA1208015005': 'Привільне',
  'UA1208001004': 'Добра Надія',
  'UA1208001005': 'Іллінка',
  'UA1208001008': 'Максимівка',
  'UA1208001001': 'Марганець',
  'UA1208001002': 'Мар\'ївка',
  'UA1208001006': 'Новокам\'янка',
  'UA1208001007': 'Новокиївка',
  'UA1208001003': 'Вільне',
  'UA1208003006': 'Долинське',
  'UA1208003005': 'Глухе',
  'UA1208003014': 'Маяк',
  'UA1208003001': 'Мирове',
  'UA1208003008': 'Настасівка',
  'UA1208003010': 'Новопавлівка',
  'UA1208003009': 'Новий Мир',
  'UA1208003015': 'Прогрес',
  'UA1208003011': 'Стрюківка',
  'UA1208003012': 'Топила',
  'UA1208003002': 'Весела Федорівка',
  'UA1208003004': 'Вищетарасівка',
  'UA1208003003': 'Виводове',
  'UA1208003007': 'Жмерине',
  'UA1208003013': 'Зоря',
  'UA1208005001': 'Нікополь',
  'UA1208007002': 'Бекетівка',
  'UA1208007043': 'Чкалове',
  'UA1208007042': 'Чистопіль',
  'UA1208007009': 'Довгівка',
  'UA1208007010': 'Дружба',
  'UA1208007007': 'Головкове',
  'UA1208007008': 'Голубівка',
  'UA1208007015': 'Іванівка',
  'UA1208007041': 'Христофорівка',
  'UA1208007017': 'Крутий Берег',
  'UA1208007016': 'Криничувате',
  'UA1208007018': 'Лебединське',
  'UA1208007019': 'Лошкарівка',
  'UA1208007020': 'Лукіївка',
  'UA1208007021': 'Максимівка',
  'UA1208007023': 'Мар\'ївка',
  'UA1208007022': 'Маринопіль',
  'UA1208007025': 'Менделєєвка',
  'UA1208007024': 'Межуївка',
  'UA1208007026': 'Нова Балта',
  'UA1208007027': 'Новоіванівка',
  'UA1208007028': 'Новоселівка',
  'UA1208007031': 'Охотниче',
  'UA1208007029': 'Олександрівка',
  'UA1208007030': 'Олександропіль',
  'UA1208007033': 'Пахар',
  'UA1208007032': 'Павлопілля',
  'UA1208007001': 'Першотравневе',
  'UA1208007035': 'Підгірне',
  'UA1208007034': 'Південне',
  'UA1208007036': 'Приют',
  'UA1208007044': 'Шевченкове',
  'UA1208007045': 'Шишкине',
  'UA1208007038': 'Східне',
  'UA1208007037': 'Сорочине',
  'UA1208007039': 'Таврійське',
  'UA1208007040': 'Томаківське',
  'UA1208007003': 'Веселе',
  'UA1208007006': 'Водяне',
  'UA1208007004': 'Високе',
  'UA1208007005': 'Високопіль',
  'UA1208007011': 'Західне',
  'UA1208007013': 'Зелене',
  'UA1208007014': 'Змагання',
  'UA1208007012': 'Звізда',
  'UA1208009003': 'Чортомлик',
  'UA1208009002': 'Гірницьке',
  'UA1208009004': 'Миронівка',
  'UA1208009001': 'Покров',
  'UA1208009006': 'Шолохове',
  'UA1208009005': 'Улянівка',
  'UA1208011002': 'Капулівка',
  'UA1208011003': 'Катеринівка',
  'UA1208011012': 'Хмельницьке',
  'UA1208011004': 'Красне',
  'UA1208011005': 'Набережне',
  'UA1208011006': 'Новософіївка',
  'UA1208011007': 'Олексіївка',
  'UA1208011001': 'Покровське',
  'UA1208011008': 'Приміське',
  'UA1208011009': 'Путилівка',
  'UA1208011013': 'Шахтар',
  'UA1208011010': 'Старозаводське',
  'UA1208011011': 'Степове',
  'UA1208013002': 'Баркове',
  'UA1208013033': 'Чайки',
  'UA1208013034': 'Червоний Яр',
  'UA1208013035': 'Чумаки',
  'UA1208013005': 'Ганнівка',
  'UA1208013006': 'Гарбузівка',
  'UA1208013007': 'Грушеве',
  'UA1208013012': 'Катьощине',
  'UA1208013015': 'Краснопіль',
  'UA1208013016': 'Крутеньке',
  'UA1208013013': 'Кисличувата',
  'UA1208013014': 'Китайгородка',
  'UA1208013018': 'Михайлівка',
  'UA1208013017': 'Миролюбівка',
  'UA1208013019': 'Новокатьощине',
  'UA1208013020': 'Новокраснопіль',
  'UA1208013022': 'Новомихайлівка',
  'UA1208013021': 'Новомиколаївка',
  'UA1208013023': 'Новоукраїнка',
  'UA1208013024': 'Олександрівка',
  'UA1208013025': 'Петрівка',
  'UA1208013026': 'Преображенка',
  'UA1208013027': 'Садове',
  'UA1208013028': 'Семенівка',
  'UA1208013029': 'Сергіївка',
  'UA1208013030': 'Степанівка',
  'UA1208013031': 'Тарасівка',
  'UA1208013001': 'Томаківка',
  'UA1208013032': 'Урожайне',
  'UA1208013003': 'Веселий Яр',
  'UA1208013004': 'Високе',
  'UA1208013008': 'Запорізька Балка',
  'UA1208013009': 'Запорізьке',
  'UA1208013010': 'Зелений Гай',
  'UA1208013011': 'Зелений Клин',
  'UA1210013001': 'Черкаське',
  'UA1210013002': 'Гвардійське',
  'UA1210015001': 'Чернеччина',
  'UA1210015003': 'Дмухайлівка',
  'UA1210015002': 'Гупалівка',
  'UA1210015005': 'Краснопілля',
  'UA1210015006': 'Кременівка',
  'UA1210015008': 'Мусієнкове',
  'UA1210015007': 'Минівка',
  'UA1210015004': 'Заплавка',
  'UA1210001002': 'Андріївка',
  'UA1210001008': 'Герасимівка',
  'UA1210001009': 'Гнатівка',
  'UA1210001001': 'Губиниха',
  'UA1210001012': 'Івано-Михайлівка',
  'UA1210001024': 'Хащеве',
  'UA1210001013': 'Королівка',
  'UA1210001014': 'Мар\'янівка',
  'UA1210001015': 'Миколаївка',
  'UA1210001025': 'Мирне',
  'UA1210001016': 'Надеждівка',
  'UA1210001017': 'Нове',
  'UA1210001018': 'Новоскотувате',
  'UA1210001019': 'Новостепанівка',
  'UA1210001020': 'Попасне',
  'UA1210001021': 'Привільне',
  'UA1210001022': 'Скотувате',
  'UA1210001023': 'Тарасове',
  'UA1210001003': 'Варварівка',
  'UA1210001004': 'Василівка',
  'UA1210001005': 'Веселе',
  'UA1210001006': 'Вільне',
  'UA1210001007': 'Всесвятське',
  'UA1210001010': 'Євецько-Миколаївка',
  'UA1210001011': 'Затишне',
  'UA1210003002': 'Бузівка',
  'UA1210003004': 'Гавришівка',
  'UA1210003007': 'Ковпаківка',
  'UA1210003001': 'Личкове',
  'UA1210003008': 'Приорільське',
  'UA1210003003': 'Великокозирщина',
  'UA1210003006': 'Йосипівка',
  'UA1210003005': 'Зоряне',
  'UA1210005006': 'Деконка',
  'UA1210005007': 'Дубравка',
  'UA1210005008': 'Дудківка',
  'UA1210005005': 'Грабки',
  'UA1210005013': 'Калинівка',
  'UA1210005012': 'Казначеївка',
  'UA1210005014': 'Кільчень',
  'UA1210005015': 'Котовка',
  'UA1210005016': 'Крамарка',
  'UA1210005001': 'Магдалинівка',
  'UA1210005017': 'Малоандріївка',
  'UA1210005018': 'Мар\'ївка',
  'UA1210005019': 'Новоіванівка',
  'UA1210005020': 'Новопетрівка',
  'UA1210005024': 'Очеретувате',
  'UA1210005021': 'Олександрівка',
  'UA1210005022': 'Оленівка',
  'UA1210005023': 'Олянівка',
  'UA1210005025': 'Першотравенка',
  'UA1210005027': 'Почино-Софіївка',
  'UA1210005026': 'Поливанівка',
  'UA1210005036': 'Шевченківка',
  'UA1210005035': 'Шевське',
  'UA1210005028': 'Січкарівка',
  'UA1210005029': 'Степанівка',
  'UA1210005030': 'Тарасівка',
  'UA1210005031': 'Тарасівка',
  'UA1210005032': 'Тарасівка',
  'UA1210005033': 'Топчине',
  'UA1210005034': 'Трудолюбівка',
  'UA1210005002': 'Веселий Гай',
  'UA1210005004': 'Водяне',
  'UA1210005003': 'Виноградівка',
  'UA1210005009': 'Євдокіївка',
  'UA1210005011': 'Запоріжжя',
  'UA1210005010': 'Жданівка',
  'UA1210007001': 'Новомосковськ',
  'UA1210009002': 'Багате',
  'UA1210009004': 'Ганнівка',
  'UA1210009005': 'Голубівка',
  'UA1210009006': 'Керносівка',
  'UA1210009020': 'Кільчень',
  'UA1210009007': 'Козирщина',
  'UA1210009008': 'Левенцівка',
  'UA1210009009': 'Малокозирщина',
  'UA1210009010': 'Михайлівка',
  'UA1210009021': 'Миролюбівка',
  'UA1210009011': 'Новошандрівка',
  'UA1210009012': 'Олександрія',
  'UA1210009013': 'Орілька',
  'UA1210009014': 'Панасівка',
  'UA1210009001': 'Перещепине',
  'UA1210009015': 'Рівне',
  'UA1210009018': 'Шандрівка',
  'UA1210009016': 'Свічанівка',
  'UA1210009017': 'Троїцьке',
  'UA1210009003': 'Воскресенівка',
  'UA1210009019': 'Вишневе',
  'UA1210011002': 'Меліоративне',
  'UA1210011004': 'Новоселівка',
  'UA1210011005': 'Новотроїцьке',
  'UA1210011006': 'Орлівщина',
  'UA1210011007': 'Підпільне',
  'UA1210011001': 'Піщанка',
  'UA1210011008': 'Соколове',
  'UA1210011009': 'Ягідне',
  'UA1210011003': 'Знаменівка',
  'UA1212001001': 'Богданівка',
  'UA1212001002': 'Богуслав',
  'UA1212001004': 'Кохівка',
  'UA1212001005': 'Мар\'ївка',
  'UA1212001006': 'Мерцалівка',
  'UA1212001007': 'Нова Дача',
  'UA1212001008': 'Нова Русь',
  'UA1212001009': 'Самарське',
  'UA1212001010': 'Шахтарське',
  'UA1212001003': 'Зелене',
  'UA1212005002': 'Булахівка',
  'UA1212005009': 'Червона Долина',
  'UA1212005010': 'Червона Нива',
  'UA1212005003': 'Дачне',
  'UA1212005004': 'Домаха',
  'UA1212005005': 'Карабинівка',
  'UA1212005006': 'Лиманське',
  'UA1212005001': 'Межиріч',
  'UA1212005011': 'Мінеральні Води',
  'UA1212005007': 'Новоолександрівське',
  'UA1212005012': 'Новоселівське',
  'UA1212005008': 'Оженківка',
  'UA1212007001': 'Павлоград',
  'UA1212009001': 'Тернівка',
  'UA1212009002': 'Зелена Долина',
  'UA1212011004': 'Левадки',
  'UA1212011005': 'Малоолександрівка',
  'UA1212011006': 'Мар\'ївка',
  'UA1212011007': 'Новоіларіонівське',
  'UA1212011010': 'Пристень',
  'UA1212011009': 'Привовчанське',
  'UA1212011008': 'Писарівка',
  'UA1212011001': 'Троїцьке',
  'UA1212011002': 'Вербове',
  'UA1212011003': 'Зелений Гай',
  'UA1212003005': 'Кочережки',
  'UA1212003006': 'Морозівське',
  'UA1212003007': 'Нові Вербки',
  'UA1212003008': 'Новомиколаївське',
  'UA1212003009': 'Олександрівка',
  'UA1212003010': 'Оленівка',
  'UA1212003011': 'Підлісне',
  'UA1212003012': 'Поперечне',
  'UA1212003014': 'Сергіївка',
  'UA1212003015': 'Солонці',
  'UA1212003016': 'Степ',
  'UA1212003013': 'Свідівок',
  'UA1212003001': 'Вербки',
  'UA1212003002': 'Веселе',
  'UA1212003003': 'В\'язівок',
  'UA1212003004': 'Жолобок',
  'UA1212013002': 'Білозерське',
  'UA1212013003': 'Бразолове',
  'UA1212013041': 'Чаплинка',
  'UA1212013042': 'Чернявщина',
  'UA1212013043': 'Чорноглазівка',
  'UA1212013013': 'Долина',
  'UA1212013014': 'Дубове',
  'UA1212013040': 'Федорівське',
  'UA1212013012': 'Голубівське',
  'UA1212013018': 'Івано-Межиріцьке',
  'UA1212013019': 'Катеринівка',
  'UA1212013020': 'Кіндратівка',
  'UA1212013028': 'Новочорноглазівське',
  'UA1212013023': 'Новогригорівка',
  'UA1212013024': 'Новоіванівське',
  'UA1212013025': 'Новомосковське',
  'UA1212013026': 'Новостроївка',
  'UA1212013027': 'Новотимофіївське',
  'UA1212013022': 'Новов\'язівське',
  'UA1212013021': 'Нижнянка',
  'UA1212013029': 'Олексіївка',
  'UA1212013030': 'Орлівське',
  'UA1212013031': 'Первомайське',
  'UA1212013035': 'П\'ятихатки',
  'UA1212013032': 'Преображенка',
  'UA1212013033': 'Призове',
  'UA1212013034': 'Пшеничне',
  'UA1212013044': 'Широка Балка',
  'UA1212013036': 'Сокільське',
  'UA1212013037': 'Терни',
  'UA1212013038': 'Українське',
  'UA1212013039': 'Улянівка',
  'UA1212013005': 'Варламівка',
  'UA1212013004': 'Варварівка',
  'UA1212013006': 'Василівка',
  'UA1212013007': 'Вербське',
  'UA1212013008': 'Вербуватівка',
  'UA1212013009': 'Весела Гірка',
  'UA1212013011': 'В\'язівське-Водяне',
  'UA1212013010': 'Водяне',
  'UA1212013046': 'Яблунівка',
  'UA1212013001': 'Юр\'ївка',
  'UA1212013045': 'Юр\'ївське',
  'UA1212013016': 'Зарічне',
  'UA1212013017': 'Затишне',
  'UA1212013015': 'Жемчужне',
  'UA1212013047': 'Жемчужне',
  'UA1214001002': 'Богдано-Вербки',
  'UA1214001001': 'Богинівка',
  'UA1214001004': 'Добринька',
  'UA1214001017': 'Хороше',
  'UA1214001006': 'Коханівка',
  'UA1214001008': 'Новодмитрівка',
  'UA1214001007': 'Нововербське',
  'UA1214001010': 'Олександрівка',
  'UA1214001011': 'Олександропіль',
  'UA1214001012': 'Осадче',
  'UA1214001009': 'Озерне',
  'UA1214001018': 'Шевченка',
  'UA1214001013': 'Сонцеве',
  'UA1214001014': 'Старий Колодязь',
  'UA1214001015': 'Товсте',
  'UA1214001016': 'Успенівка',
  'UA1214001003': 'Водяне',
  'UA1214001005': 'Зелений Гай',
  'UA1214007003': 'Артемівка',
  'UA1214007004': 'Бровки',
  'UA1214007002': 'Чаплине',
  'UA1214007006': 'Дачне',
  'UA1214007007': 'Добровілля',
  'UA1214007008': 'Довге',
  'UA1214007001': 'Дубовики',
  'UA1214007005': 'Гришаї',
  'UA1214007012': 'Касаєве',
  'UA1214007026': 'Хуторо-Чаплине',
  'UA1214007025': 'Хвилі',
  'UA1214007013': 'Копані',
  'UA1214007014': 'Краснощокове',
  'UA1214007015': 'Крутеньке',
  'UA1214007016': 'Лиса Балка',
  'UA1214007017': 'Медичне',
  'UA1214007018': 'Миколаївка',
  'UA1214007019': 'Новоандріївка',
  'UA1214007020': 'Очеретувате',
  'UA1214007021': 'Петрикове',
  'UA1214007022': 'Рівне',
  'UA1214007027': 'Шевченкове',
  'UA1214007023': 'Свиридове',
  'UA1214007024': 'Таранове',
  'UA1214007010': 'Зелена Роща',
  'UA1214007011': 'Зелений Гай',
  'UA1214007009': 'Журавлинка',
  'UA1214011003': 'Дерезувате',
  'UA1214011004': 'Дороге',
  'UA1214011001': 'Іларіонове',
  'UA1214011006': 'Іванівка',
  'UA1214011016': 'Хорошеве',
  'UA1214011007': 'Лозуватка',
  'UA1214011008': 'Лугове',
  'UA1214011010': 'Мар\'їнка',
  'UA1214011009': 'Мар\'ївка',
  'UA1214011014': 'Первомайське',
  'UA1214011011': 'Ракове',
  'UA1214011002': 'Сад',
  'UA1214011017': 'Шахтарське',
  'UA1214011013': 'Широкосмоленка',
  'UA1214011012': 'Старолозуватка',
  'UA1214011015': 'Степове',
  'UA1214011005': 'Знаменівське',
  'UA1214013003': 'Гаврилівка',
  'UA1214013001': 'Маломихайлівка',
  'UA1214013004': 'Підгаврилівка',
  'UA1214013002': 'Просяна',
  'UA1214013005': 'Яблунівка',
  'UA1214015003': 'Біляківка',
  'UA1214015002': 'Демурине',
  'UA1214015013': 'Іванівка',
  'UA1214015014': 'Колона-Межова',
  'UA1214015015': 'Красногорівка',
  'UA1214015016': 'Мар\'ївка',
  'UA1214015001': 'Межова',
  'UA1214015017': 'Новогригорівка',
  'UA1214015018': 'Новолозуватівка',
  'UA1214015019': 'Новоолександрівка',
  'UA1214015020': 'Новопідгородне',
  'UA1214015021': 'Новотроїцьке',
  'UA1214015022': 'Олександрівка',
  'UA1214015023': 'Попутне',
  'UA1214015024': 'Преображенка',
  'UA1214015025': 'Райполе',
  'UA1214015026': 'Славне',
  'UA1214015027': 'Степове',
  'UA1214015028': 'Сухарева Балка',
  'UA1214015029': 'Українка',
  'UA1214015004': 'Василівка',
  'UA1214015005': 'Веселе',
  'UA1214015006': 'Веселе',
  'UA1214015007': 'Водолазьке',
  'UA1214015009': 'Володимирівка',
  'UA1214015008': 'Вознесенське',
  'UA1214015010': 'Всесвятське',
  'UA1214015030': 'Юр\'ївка',
  'UA1214015012': 'Запорізьке',
  'UA1214015011': 'Жукове',
  'UA1214017002': 'Бажани',
  'UA1214017016': 'Чумаки',
  'UA1214017004': 'Дмитрівка',
  'UA1214017006': 'Кардаші',
  'UA1214017007': 'Катеринівка',
  'UA1214017008': 'Кунінова',
  'UA1214017009': 'Маломиколаївка',
  'UA1214017010': 'Мар\'їна Роща',
  'UA1214017001': 'Миколаївка',
  'UA1214017011': 'Новопричепилівка',
  'UA1214017012': 'Олефірівка',
  'UA1214017013': 'Петрівка',
  'UA1214017014': 'Русакове',
  'UA1214017015': 'Сидоренко',
  'UA1214017017': 'Васильківське',
  'UA1214017003': 'Відродження',
  'UA1214017005': 'Запоріжжя',
  'UA1214019002': 'Антонівське',
  'UA1214019003': 'Богданівка',
  'UA1214019010': 'Чаус',
  'UA1214019011': 'Чугуєве',
  'UA1214019004': 'Дачне',
  'UA1214019008': 'Федорівське',
  'UA1214019009': 'Філія',
  'UA1214019005': 'Миколаївка',
  'UA1214019001': 'Новопавлівка',
  'UA1214019006': 'Солоне',
  'UA1214019007': 'Тарасівка',
  'UA1214021001': 'Першотравенськ',
  'UA1214023004': 'Лозове',
  'UA1214023005': 'Лугове',
  'UA1214023001': 'Петропавлівка',
  'UA1214023007': 'Росішки',
  'UA1214023006': 'Роздори',
  'UA1214023008': 'Самарське',
  'UA1214023003': 'Васюківка',
  'UA1214023002': 'Залізничне',
  'UA1214025002': 'Андріївка',
  'UA1214025003': 'Богодарівка',
  'UA1214025004': 'Братське',
  'UA1214025050': 'Чорненкове',
  'UA1214025014': 'Данилівка',
  'UA1214025015': 'Діброва',
  'UA1214025016': 'Добропасове',
  'UA1214025017': 'Дрозди',
  'UA1214025011': 'Гай',
  'UA1214025012': 'Гапоно-Мечетне',
  'UA1214025013': 'Герасимівка',
  'UA1214025021': 'Катеринівка',
  'UA1214025049': 'Христофорівка',
  'UA1214025024': 'Коломійці',
  'UA1214025025': 'Кривобокове',
  'UA1214025023': 'Кирпичне',
  'UA1214025022': 'Киричкове',
  'UA1214025026': 'Левадне',
  'UA1214025028': 'Маяк',
  'UA1214025027': 'Малинівка',
  'UA1214025029': 'Мечетне',
  'UA1214025030': 'Нечаївка',
  'UA1214025031': 'Новоолександрівка',
  'UA1214025032': 'Новоскелювате',
  'UA1214025033': 'Олександрівка',
  'UA1214025034': 'Олексіївка',
  'UA1214025035': 'Орли',
  'UA1214025036': 'Остапівське',
  'UA1214025037': 'Отрішки',
  'UA1214025038': 'Першотравневе',
  'UA1214025039': 'Петриків',
  'UA1214025041': 'Піщане',
  'UA1214025001': 'Покровське',
  'UA1214025042': 'Привілля',
  'UA1214025040': 'Писанці',
  'UA1214025043': 'Радісне',
  'UA1214025044': 'Романки',
  'UA1214025045': 'Скотувате',
  'UA1214025046': 'Солоне',
  'UA1214025047': 'Старокасянівське',
  'UA1214025048': 'Тихе',
  'UA1214025005': 'Вербове',
  'UA1214025007': 'Відрадне',
  'UA1214025008': 'Вільне',
  'UA1214025010': 'Водяне',
  'UA1214025009': 'Вовче',
  'UA1214025006': 'Вишневе',
  'UA1214025018': 'Єгорівка',
  'UA1214025019': 'Зарічне',
  'UA1214025020': 'Зелена Долина',
  'UA1214027002': 'Андріївка',
  'UA1214027003': 'Березнуватка',
  'UA1214027004': 'Богуславка',
  'UA1214027015': 'Дніпровське',
  'UA1214027016': 'Дубове',
  'UA1214027013': 'Георгіївка',
  'UA1214027014': 'Грушувато-Криничне',
  'UA1214027019': 'Іванівка',
  'UA1214027020': 'Калинівське',
  'UA1214027022': 'Кодацьке',
  'UA1214027023': 'Котлярівське',
  'UA1214027021': 'Київське',
  'UA1214027024': 'Луб\'янка',
  'UA1214027026': 'Мар\'ївка',
  'UA1214027025': 'Мажари',
  'UA1214027029': 'Морозівське',
  'UA1214027028': 'Михайлівка',
  'UA1214027027': 'Миролюбівка',
  'UA1214027030': 'Ненаситець',
  'UA1214027036': 'Носачі',
  'UA1214027035': 'Новочернігівське',
  'UA1214027032': 'Новогніде',
  'UA1214027033': 'Новоолександрівка',
  'UA1214027034': 'Новоолексіївка',
  'UA1214027031': 'Новий Посьолок',
  'UA1214027038': 'Панасівка',
  'UA1214027039': 'Партизани',
  'UA1214027037': 'Павлівка',
  'UA1214027001': 'Раївка',
  'UA1214027040': 'Рудево-Миколаївка',
  'UA1214027041': 'Садове',
  'UA1214027046': 'Шевченківське',
  'UA1214027042': 'Суха Калина',
  'UA1214027043': 'Терса',
  'UA1214027044': 'Токове',
  'UA1214027045': 'Циганівка',
  'UA1214027005': 'Василівка',
  'UA1214027006': 'Василівка-на-Дніпрі',
  'UA1214027007': 'Великомихайлівка',
  'UA1214027008': 'Вербки-Осокорівка',
  'UA1214027009': 'Вербове',
  'UA1214027010': 'Веселе',
  'UA1214027011': 'Вільне',
  'UA1214027012': 'Воронове',
  'UA1214027047': 'Ясне',
  'UA1214027018': 'Заяче',
  'UA1214027017': 'Запорожець',
  'UA1214029003': 'Гострий Камінь',
  'UA1214029004': 'Катражка',
  'UA1214029006': 'Новопавлоградське',
  'UA1214029005': 'Нововознесенка',
  'UA1214029007': 'Парне',
  'UA1214029008': 'Роздолля',
  'UA1214029001': 'Роздори',
  'UA1214029009': 'Старовишневецьке',
  'UA1214029002': 'Водяне',
  'UA1214029010': 'Вишневецьке',
  'UA1214033002': 'Андріївка',
  'UA1214033003': 'Бегма',
  'UA1214033004': 'Бурханівка',
  'UA1214033009': 'Дубо-Осокорівка',
  'UA1214033007': 'Гірки',
  'UA1214033008': 'Грякувате',
  'UA1214033012': 'Мар\'ївське',
  'UA1214033013': 'Надеждине',
  'UA1214033014': 'Новоолександрівське',
  'UA1214033015': 'Новоолександропіль',
  'UA1214033016': 'Обоянівське',
  'UA1214033017': 'Олександропіль',
  'UA1214033018': 'Осокорівка',
  'UA1214033019': 'Першозванівка',
  'UA1214033020': 'Польове',
  'UA1214033021': 'Попове',
  'UA1214033001': 'Славгород',
  'UA1214033022': 'Третяківка',
  'UA1214033023': 'Троїцьке',
  'UA1214033024': 'Тургенєвка',
  'UA1214033005': 'Варварівка',
  'UA1214033006': 'Володимирівське',
  'UA1214033010': 'Запорізьке',
  'UA1214033011': 'Зелене',
  'UA1214035002': 'Андронівка',
  'UA1214035005': 'Крутоярівка',
  'UA1214035006': 'Мала Покровка',
  'UA1214035007': 'Малієве',
  'UA1214035008': 'Маліївське',
  'UA1214035009': 'Миронове',
  'UA1214035010': 'Наталівка',
  'UA1214035011': 'Полтавське',
  'UA1214035001': 'Слов\'янка',
  'UA1214035003': 'Зелене',
  'UA1214035004': 'Зоряне',
  'UA1214031001': 'Синельникове',
  'UA1214037005': 'Мар\'янка',
  'UA1214037007': 'Новохорошевське',
  'UA1214037006': 'Новоселівка',
  'UA1214037008': 'Троїцьке',
  'UA1214037001': 'Українське',
  'UA1214037002': 'Василівка',
  'UA1214037003': 'Вереміївка',
  'UA1214037004': 'Зелений Гай',
  'UA1214003003': 'Аврамівка',
  'UA1214003004': 'Бабакове',
  'UA1214003005': 'Богданівка',
  'UA1214003006': 'Бондареве',
  'UA1214003050': 'Червона Долина',
  'UA1214003015': 'Дебальцеве',
  'UA1214003016': 'Дібрівка',
  'UA1214003017': 'Довге',
  'UA1214003014': 'Григорівка',
  'UA1214003020': 'Іванівка',
  'UA1214003021': 'Іванівське',
  'UA1214003022': 'Каплистівка',
  'UA1214003023': 'Катеринівка',
  'UA1214003024': 'Кобзар',
  'UA1214003025': 'Колоно-Миколаївка',
  'UA1214003026': 'Красне',
  'UA1214003055': 'Крутоярка',
  'UA1214003027': 'Криворізьке',
  'UA1214003028': 'Луб\'янці',
  'UA1214003029': 'Лугове',
  'UA1214003030': 'Манвелівка',
  'UA1214003031': 'Морозівське',
  'UA1214003034': 'Новогригорівка',
  'UA1214003035': 'Новоіванівка',
  'UA1214003036': 'Новотерсянське',
  'UA1214003032': 'Нововасильківка',
  'UA1214003033': 'Нововоскресенівка',
  'UA1214003037': 'Охотниче',
  'UA1214003038': 'Павлівка',
  'UA1214003041': 'Перепеляче',
  'UA1214003040': 'Перевальське',
  'UA1214003039': 'Первомайське',
  'UA1214003042': 'Петриківка',
  'UA1214003056': 'Правда',
  'UA1214003043': 'Преображенське',
  'UA1214003044': 'Пришиб',
  'UA1214003002': 'Письменне',
  'UA1214003045': 'Рубанівське',
  'UA1214003046': 'Самарське',
  'UA1214003051': 'Шевченківське',
  'UA1214003052': 'Шевченко',
  'UA1214003053': 'Шев\'якине',
  'UA1214003054': 'Широке',
  'UA1214003047': 'Солонці',
  'UA1214003048': 'Тихе',
  'UA1214003049': 'Улянівка',
  'UA1214003001': 'Васильківка',
  'UA1214003007': 'Великоолександрівка',
  'UA1214003008': 'Вербівське',
  'UA1214003009': 'Веселий Кут',
  'UA1214003012': 'Воронізьке',
  'UA1214003013': 'Воскресенівка',
  'UA1214003010': 'Вовчанське',
  'UA1214003011': 'Возвратне',
  'UA1214003018': 'Зелений Гай',
  'UA1214003019': 'Зоря',
  'UA1214005002': 'Березове',
  'UA1214005005': 'Калинівське',
  'UA1214005015': 'Хороше',
  'UA1214005006': 'Лісне',
  'UA1214005007': 'Маліївка',
  'UA1214005008': 'Новогеоргіївка',
  'UA1214005009': 'Новомиколаївка',
  'UA1214005010': 'Новоселівка',
  'UA1214005011': 'Орестопіль',
  'UA1214005012': 'Соснівка',
  'UA1214005013': 'Степове',
  'UA1214005014': 'Тернове',
  'UA1214005001': 'Великомихайлівка',
  'UA1214005003': 'Вороне',
  'UA1214005016': 'Январське',
  'UA1214005004': 'Запорізьке',
  'UA1214009002': 'Калинівка',
  'UA1214009003': 'Кам\'януватка',
  'UA1214009015': 'Хорошеве',
  'UA1214009005': 'Козачий Гай',
  'UA1214009006': 'Красне',
  'UA1214009004': 'Кислянка',
  'UA1214009007': 'Майське',
  'UA1214009008': 'Максимівка',
  'UA1214009009': 'Надеждівка',
  'UA1214009010': 'Нове',
  'UA1214009011': 'Новомиколаївка',
  'UA1214009012': 'Очеретувате',
  'UA1214009013': 'Романівка',
  'UA1214009014': 'Тернове',
  'UA1214009001': 'Зайцеве',
  'UA1402001003': 'Андріївка',
  'UA1402001001': 'Бахмут',
  'UA1402001004': 'Берхівка',
  'UA1402001009': 'Іванград',
  'UA1402001010': 'Іванівське',
  'UA1402001018': 'Хромове',
  'UA1402001012': 'Кліщіївка',
  'UA1402001011': 'Клинове',
  'UA1402001002': 'Красна Гора',
  'UA1402001013': 'Мідна Руда',
  'UA1402001014': 'Нова Кам\'янка',
  'UA1402001017': 'Опитне',
  'UA1402001015': 'Покровське',
  'UA1402001005': 'Вершина',
  'UA1402001006': 'Весела Долина',
  'UA1402001007': 'Відродження',
  'UA1402001019': 'Ягідне',
  'UA1402001008': 'Зайцеве',
  'UA1402001016': 'Зеленопілля',
  'UA1402013002': 'Богданівка',
  'UA1402013001': 'Часів Яр',
  'UA1402013003': 'Григорівка',
  'UA1402013004': 'Калинівка',
  'UA1402007003': 'Дронівка',
  'UA1402007002': 'Григорівка',
  'UA1402007004': 'Платонівка',
  'UA1402007005': 'Різниківка',
  'UA1402007007': 'Серебрянка',
  'UA1402007001': 'Сіверськ',
  'UA1402007006': 'Свято-Покровське',
  'UA1402009002': 'Бахмутське',
  'UA1402009003': 'Берестове',
  'UA1402009004': 'Білогорівка',
  'UA1402009005': 'Благодатне',
  'UA1402009006': 'Бондарне',
  'UA1402009012': 'Діброва',
  'UA1402009013': 'Дубово-Василівка',
  'UA1402009031': 'Федорівка Друга',
  'UA1402009030': 'Федорівка',
  'UA1402009011': 'Голубівка',
  'UA1402009032': 'Хромівка',
  'UA1402009015': 'Краснополівка',
  'UA1402009016': 'Липівка',
  'UA1402009017': 'Липове',
  'UA1402009019': 'Міньківка',
  'UA1402009018': 'Миколаївка',
  'UA1402009035': 'Нагірне',
  'UA1402009020': 'Никифорівка',
  'UA1402009021': 'Оріхово-Василівка',
  'UA1402009023': 'Парасковіївка',
  'UA1402009022': 'Пазено',
  'UA1402009036': 'Підгородне',
  'UA1402009025': 'Привілля',
  'UA1402009024': 'Пилипчатине',
  'UA1402009026': 'Роздолівка',
  'UA1402009027': 'Сакко і Ванцетті',
  'UA1402009001': 'Соледар',
  'UA1402009037': 'Спірне',
  'UA1402009028': 'Стряпівка',
  'UA1402009029': 'Трипілля',
  'UA1402009008': 'Васюківка',
  'UA1402009007': 'Василівка',
  'UA1402009009': 'Веселе',
  'UA1402009010': 'Володимирівка',
  'UA1402009034': 'Виїмка',
  'UA1402009033': 'Яковлівка',
  'UA1402009014': 'Залізнянське',
  'UA1402005006': 'Дача',
  'UA1402005017': 'Доломітне',
  'UA1402005016': 'Гладосове',
  'UA1402005007': 'Кодема',
  'UA1402005008': 'Криничне',
  'UA1402005009': 'Лозове',
  'UA1402005003': 'Луганське',
  'UA1402005011': 'Миколаївка Друга',
  'UA1402005010': 'Миколаївка',
  'UA1402005012': 'Миронівка',
  'UA1402005004': 'Миронівський',
  'UA1402005018': 'Новолуганське',
  'UA1402005013': 'Одрадівка',
  'UA1402005019': 'Роти',
  'UA1402005014': 'Розсадки',
  'UA1402005015': 'Семигір\'я',
  'UA1402005001': 'Світлодарськ',
  'UA1402005020': 'Травневе',
  'UA1402005005': 'Воздвиженка',
  'UA1402005002': 'Зайцеве',
  'UA1402011013': 'Дачне',
  'UA1402011015': 'Дружба',
  'UA1402011014': 'Диліївка',
  'UA1402011016': 'Кримське',
  'UA1402011003': 'Курдюмівка',
  'UA1402011010': 'Леонідівка',
  'UA1402011004': 'Неліпівка',
  'UA1402011005': 'Нью-Йорк',
  'UA1402011017': 'Озарянівка',
  'UA1402011006': 'Петрівка',
  'UA1402011007': 'Південне',
  'UA1402011008': 'Північне',
  'UA1402011009': 'Щербинівка',
  'UA1402011019': 'Шуми',
  'UA1402011018': 'Суха Балка',
  'UA1402011001': 'Торецьк',
  'UA1402011012': 'Валентинівка',
  'UA1402011011': 'Юр\'ївка',
  'UA1402011002': 'Залізне',
  'UA1402003003': 'Івано-Дар\'ївка',
  'UA1402003004': 'Кузьминівка',
  'UA1402003005': 'Новоселівка',
  'UA1402003006': 'Переїзне',
  'UA1402003002': 'Верхньокам\'янське',
  'UA1402003001': 'Званівка',
  'UA1408001001': 'Амвросіївка',
  'UA1408001004': 'Артемівка',
  'UA1408001005': 'Білоярівка',
  'UA1408001006': 'Благодатне',
  'UA1408001036': 'Дзеркальне',
  'UA1408001011': 'Григорівка',
  'UA1408001013': 'Калинове',
  'UA1408001039': 'Калинове',
  'UA1408001014': 'Карпово-Надеждинка',
  'UA1408001040': 'Катеринівка',
  'UA1408001058': 'Харківське',
  'UA1408001042': 'Кленівка',
  'UA1408001016': 'Комишуваха',
  'UA1408001002': 'Копані',
  'UA1408001017': 'Кошарне',
  'UA1408001018': 'Красний Луч',
  'UA1408001043': 'Кринички',
  'UA1408001041': 'Квашине',
  'UA1408001015': 'Киселівка',
  'UA1408001044': 'Лисиче',
  'UA1408001019': 'Мала Шишівка',
  'UA1408001020': 'Манич',
  'UA1408001045': 'Мережки',
  'UA1408001021': 'Мокроєланчик',
  'UA1408001003': 'Новоамвросіївське',
  'UA1408001022': 'Новоєланчик',
  'UA1408001023': 'Новоіванівка',
  'UA1408001024': 'Новоклинівка',
  'UA1408001025': 'Новоукраїнське',
  'UA1408001046': 'Нижньокринське',
  'UA1408001047': 'Обрізне',
  'UA1408001026': 'Олексіївське',
  'UA1408001027': 'Ольгинське',
  'UA1408001048': 'Овочеве',
  'UA1408001028': 'Павлівське',
  'UA1408001049': 'Петренки',
  'UA1408001029': 'Петропавлівка',
  'UA1408001050': 'Побєда',
  'UA1408001051': 'Родники',
  'UA1408001052': 'Рубашкине',
  'UA1408001030': 'Садове',
  'UA1408001032': 'Семенівське',
  'UA1408001054': 'Сергієве-Кринка',
  'UA1408001033': 'Сіятель',
  'UA1408001055': 'Степне',
  'UA1408001053': 'Свободне',
  'UA1408001031': 'Свистуни',
  'UA1408001056': 'Трепельне',
  'UA1408001057': 'Улянівське',
  'UA1408001034': 'Успенка',
  'UA1408001007': 'Василівка',
  'UA1408001008': 'Велике Мішкове',
  'UA1408001009': 'Верхньоєланчик',
  'UA1408001010': 'Вільхівчик',
  'UA1408001035': 'Виселки',
  'UA1408001012': 'Єлизавето-Миколаївка',
  'UA1408001038': 'Жукова Балка',
  'UA1408001037': 'Житенко',
  'UA1408003007': 'Бирюки',
  'UA1408003001': 'Донецьк',
  'UA1408003003': 'Горбачево-Михайлівка',
  'UA1408003009': 'Гришки',
  'UA1408003010': 'Кисличе',
  'UA1408003004': 'Ларине',
  'UA1408003002': 'Моспине',
  'UA1408003011': 'Михайлівка',
  'UA1408003012': 'Новодвірське',
  'UA1408003005': 'Олександрівка',
  'UA1408003013': 'Павлоградське',
  'UA1408003006': 'Старомихайлівка',
  'UA1408003014': 'Темрюк',
  'UA1408003008': 'Вербова Балка',
  'UA1408005003': 'Агрономічне',
  'UA1408005016': 'Бондаревське',
  'UA1408005015': 'Червоносільське',
  'UA1408005014': 'Федорівка',
  'UA1408005019': 'Грабське',
  'UA1408005005': 'Григорівка',
  'UA1408005001': 'Іловайськ',
  'UA1408005020': 'Кобзарі',
  'UA1408005002': 'Кутейникове',
  'UA1408005021': 'Металіст',
  'UA1408005007': 'Многопілля',
  'UA1408005008': 'Покровка',
  'UA1408005009': 'Полтавське',
  'UA1408005022': 'Придорожнє',
  'UA1408005010': 'Русько-Орлівка',
  'UA1408005011': 'Садове',
  'UA1408005012': 'Степано-Кринка',
  'UA1408005013': 'Третяки',
  'UA1408005004': 'Вербівка',
  'UA1408005017': 'Верхньоосикове',
  'UA1408005018': 'Виноградне',
  'UA1408005006': 'Зелене',
  'UA1408009003': 'Благодатне',
  'UA1408009011': 'Дубівка',
  'UA1408009004': 'Гірне',
  'UA1408009001': 'Харцизьк',
  'UA1408009006': 'Миколаївка',
  'UA1408009013': 'Новомиколаївка',
  'UA1408009014': 'Новопелагіївка',
  'UA1408009015': 'Півче',
  'UA1408009007': 'Покровка',
  'UA1408009009': 'Шахтне',
  'UA1408009010': 'Широке',
  'UA1408009008': 'Троїцько-Харцизьк',
  'UA1408009016': 'Цупки',
  'UA1408009017': 'Ведмеже',
  'UA1408009018': 'Водобуд',
  'UA1408009012': 'Золотарівка',
  'UA1408009002': 'Зугрес',
  'UA1408009005': 'Зуївка',
  'UA1408007026': 'Алмазне',
  'UA1408007006': 'Грузько-Ломівка',
  'UA1408007005': 'Грузько-Зорянське',
  'UA1408007007': 'Гусельське',
  'UA1408007033': 'Холмисте',
  'UA1408007017': 'Холодне',
  'UA1408007009': 'Колосникове',
  'UA1408007020': 'Красна Зоря',
  'UA1408007010': 'Кринична',
  'UA1408007028': 'Леб\'яже',
  'UA1408007012': 'Лісне',
  'UA1408007021': 'Липове',
  'UA1408007011': 'Липське',
  'UA1408007013': 'Маяк',
  'UA1408007001': 'Макіївка',
  'UA1408007014': 'Межове',
  'UA1408007022': 'Молочарка',
  'UA1408007029': 'Монахове',
  'UA1408007031': 'Новомар\'ївка',
  'UA1408007032': 'Новомосковське',
  'UA1408007023': 'Новоселівка',
  'UA1408007030': 'Новий Світ',
  'UA1408007015': 'Нижня Кринка',
  'UA1408007024': 'Оріхове',
  'UA1408007016': 'П\'ятипілля',
  'UA1408007025': 'Шевченко',
  'UA1408007027': 'Василівка',
  'UA1408007002': 'Велике Оріхове',
  'UA1408007019': 'Верхня Кринка',
  'UA1408007004': 'Вугляр',
  'UA1408007003': 'Високе',
  'UA1408007018': 'Ясинівка',
  'UA1408007008': 'Землянки',
  'UA1408011005': 'Каштанове',
  'UA1408011006': 'Крута Балка',
  'UA1408011007': 'Мінеральне',
  'UA1408011003': 'Спартак',
  'UA1408011002': 'Веселе',
  'UA1408011004': 'Яковлівка',
  'UA1408011001': 'Ясинувата',
  'UA1406015006': 'Балочне',
  'UA1406015001': 'Чистякове',
  'UA1406015004': 'Грабове',
  'UA1406015002': 'Пелагіївка',
  'UA1406015005': 'Рівне',
  'UA1406015003': 'Розсипне',
  'UA1406005001': 'Дебальцеве',
  'UA1406005002': 'Дебальцівське',
  'UA1406005003': 'Калинівка',
  'UA1406005004': 'Логвинове',
  'UA1406005006': 'Новогригорівка',
  'UA1406005005': 'Нижнє Лозове',
  'UA1406005007': 'Санжарівка',
  'UA1406003009': 'Федорівка',
  'UA1406003002': 'Гольмівський',
  'UA1406003001': 'Горлівка',
  'UA1406003004': 'Михайлівка',
  'UA1406003006': 'Озерянівка',
  'UA1406003003': 'Пантелеймонівка',
  'UA1406003007': 'П\'ятихатки',
  'UA1406003005': 'Рясне',
  'UA1406003010': 'Широка Балка',
  'UA1406003008': 'Ставки',
  'UA1406013001': 'Хрестівка',
  'UA1406013004': 'Комишатка',
  'UA1406013005': 'Красний Луч',
  'UA1406013006': 'Круглик',
  'UA1406013018': 'Кумшацьке',
  'UA1406013007': 'Малоорлівка',
  'UA1406013008': 'Михайлівка',
  'UA1406013009': 'Нікішине',
  'UA1406013010': 'Новоорлівка',
  'UA1406013011': 'Орлово-Іванівка',
  'UA1406013012': 'Петропавлівка',
  'UA1406013019': 'Польове',
  'UA1406013020': 'Рідкодуб',
  'UA1406013013': 'Розсипне',
  'UA1406013017': 'Шевченко',
  'UA1406013021': 'Славне',
  'UA1406013022': 'Степне',
  'UA1406013014': 'Стіжкове',
  'UA1406013015': 'Стрюкове',
  'UA1406013016': 'Тимофіївка',
  'UA1406013002': 'Веселе',
  'UA1406013003': 'Відродження',
  'UA1406017022': 'Чумаки',
  'UA1406017012': 'Дорофієнко',
  'UA1406017013': 'Дубове',
  'UA1406017011': 'Гірне',
  'UA1406017002': 'Контарне',
  'UA1406017018': 'Кищенко',
  'UA1406017019': 'Лобанівка',
  'UA1406017020': 'Молодецьке',
  'UA1406017003': 'Московське',
  'UA1406017021': 'Садове',
  'UA1406017004': 'Сердите',
  'UA1406017001': 'Шахтарськ',
  'UA1406017008': 'Шапошникове',
  'UA1406017005': 'Стіжківське',
  'UA1406017007': 'Тернове',
  'UA1406017006': 'Велика Шишівка',
  'UA1406017009': 'Вікторія',
  'UA1406017010': 'Вінницьке',
  'UA1406017017': 'Зачатівка',
  'UA1406017016': 'Захарченко',
  'UA1406017014': 'Зарічне',
  'UA1406017015': 'Зарощенське',
  'UA1406011002': 'Андріївка',
  'UA1406011027': 'Балка',
  'UA1406011003': 'Бражине',
  'UA1406011025': 'Червона Зоря',
  'UA1406011026': 'Чугуно-Крепинка',
  'UA1406011013': 'Дібрівка',
  'UA1406011014': 'Дмитрівка',
  'UA1406011004': 'Гірницьке',
  'UA1406011028': 'Кожевня',
  'UA1406011016': 'Латишеве',
  'UA1406011006': 'Лиманчук',
  'UA1406011017': 'Мануйлівка',
  'UA1406011018': 'Маринівка',
  'UA1406011029': 'Молчалине',
  'UA1406011007': 'Никифорове',
  'UA1406011019': 'Передерієве',
  'UA1406011008': 'Первомайське',
  'UA1406011009': 'Первомайський',
  'UA1406011020': 'Петрівське',
  'UA1406011010': 'Побєда',
  'UA1406011021': 'Розсипне',
  'UA1406011022': 'Саурівка',
  'UA1406011011': 'Сєверне',
  'UA1406011001': 'Сніжне',
  'UA1406011023': 'Степанівка',
  'UA1406011030': 'Сухівське',
  'UA1406011024': 'Тарани',
  'UA1406011012': 'Верхній Кут',
  'UA1406011005': 'Залісне',
  'UA1406011015': 'Зрубне',
  'UA1406001008': 'Булавине',
  'UA1406001002': 'Булавинське',
  'UA1406001010': 'Данилове',
  'UA1406001009': 'Грозне',
  'UA1406001011': 'Іллінка',
  'UA1406001013': 'Каютине',
  'UA1406001012': 'Кам\'янка',
  'UA1406001003': 'Олександрівське',
  'UA1406001004': 'Оленівка',
  'UA1406001005': 'Ольховатка',
  'UA1406001006': 'Прибережне',
  'UA1406001014': 'Рідкодуб',
  'UA1406001015': 'Савелівка',
  'UA1406001016': 'Ступакове',
  'UA1406001007': 'Весела Долина',
  'UA1406001001': 'Вуглегірськ',
  'UA1406007006': 'Авіловка',
  'UA1406007002': 'Бунге',
  'UA1406007003': 'Дружне',
  'UA1406007004': 'Корсунь',
  'UA1406007008': 'Кринички',
  'UA1406007009': 'Новоселівка',
  'UA1406007010': 'Путепровід',
  'UA1406007011': 'Шапошникове',
  'UA1406007014': 'Щебенка',
  'UA1406007012': 'Шевченко',
  'UA1406007005': 'Софіївка',
  'UA1406007013': 'Старопетрівське',
  'UA1406007007': 'Верхня Кринка',
  'UA1406007001': 'Єнакієве',
  'UA1406009005': 'Молодий Шахтар',
  'UA1406009003': 'Розівка',
  'UA1406009004': 'Шевченко',
  'UA1406009002': 'Вільхівка',
  'UA1406009001': 'Жданівка',
  'UA1410001002': 'Біла Кам\'янка',
  'UA1410001003': 'Білокриничне',
  'UA1410001004': 'Богданівка',
  'UA1410001001': 'Бойківське',
  'UA1410001039': 'Черевківське',
  'UA1410001041': 'Чумак',
  'UA1410001040': 'Чирилянське',
  'UA1410001009': 'Дерсове',
  'UA1410001007': 'Греково-Олександрівка',
  'UA1410001008': 'Григорівка',
  'UA1410001014': 'Іванівка',
  'UA1410001015': 'Каплани',
  'UA1410001016': 'Конькове',
  'UA1410001017': 'Котляревське',
  'UA1410001018': 'Кузнецово-Михайлівка',
  'UA1410001019': 'Лавринове',
  'UA1410001020': 'Лукове',
  'UA1410001021': 'Майорове',
  'UA1410001024': 'Мічуріне',
  'UA1410001023': 'Михайлівка',
  'UA1410001022': 'Миколаївка',
  'UA1410001025': 'Нова Мар\'ївка',
  'UA1410001026': 'Новоласпа',
  'UA1410001027': 'Новоолександрівка',
  'UA1410001028': 'Олександрівське',
  'UA1410001029': 'Первомайське',
  'UA1410001030': 'Первомайське',
  'UA1410001031': 'Петрівське',
  'UA1410001032': 'Розівка',
  'UA1410001033': 'Садки',
  'UA1410001034': 'Самсонове',
  'UA1410001042': 'Шевченко',
  'UA1410001036': 'Староласпа',
  'UA1410001035': 'Свободне',
  'UA1410001037': 'Тавричеське',
  'UA1410001038': 'Тернівка',
  'UA1410001005': 'Вершинівка',
  'UA1410001006': 'Воля',
  'UA1410001010': 'Запорожець',
  'UA1410001011': 'Зелений Гай',
  'UA1410001012': 'Зернове',
  'UA1410001013': 'Зорі',
  'UA1410003003': 'Андріївка',
  'UA1410003009': 'Червоне',
  'UA1410003001': 'Докучаєвськ',
  'UA1410003004': 'Доля',
  'UA1410003005': 'Кремінець',
  'UA1410003007': 'Любівка',
  'UA1410003006': 'Луганське',
  'UA1410003010': 'Малинове',
  'UA1410003011': 'Молодіжне',
  'UA1410003012': 'Нова Оленівка',
  'UA1410003013': 'Новомиколаївка',
  'UA1410003002': 'Оленівка',
  'UA1410003008': 'Сигнальне',
  'UA1410003014': 'Ясне',
  'UA1410005002': 'Андріївка',
  'UA1410005003': 'Берегове',
  'UA1410005004': 'Берестове',
  'UA1410005033': 'Бурне',
  'UA1410005009': 'Глинка',
  'UA1410005001': 'Кальміуське',
  'UA1410005011': 'Кам\'яне',
  'UA1410005012': 'Кам\'янка',
  'UA1410005036': 'Колоски',
  'UA1410005013': 'Краснопілля',
  'UA1410005014': 'Культура',
  'UA1410005015': 'Кумачове',
  'UA1410005035': 'Кипуча Криниця',
  'UA1410005017': 'Любівка',
  'UA1410005016': 'Лужки',
  'UA1410005019': 'Новокатеринівка',
  'UA1410005020': 'Новомихайлівка',
  'UA1410005018': 'Новозар\'ївка',
  'UA1410005021': 'Петрівське',
  'UA1410005022': 'Підгірне',
  'UA1410005023': 'Побєда',
  'UA1410005024': 'Прохорівське',
  'UA1410005025': 'Ребрикове',
  'UA1410005037': 'Родникове',
  'UA1410005026': 'Роздольне',
  'UA1410005029': 'Шевченко',
  'UA1410005030': 'Шевченко',
  'UA1410005032': 'Шмідта',
  'UA1410005031': 'Широке',
  'UA1410005028': 'Сонцеве',
  'UA1410005038': 'Строїтель',
  'UA1410005027': 'Світлий Луч',
  'UA1410005005': 'Василівка',
  'UA1410005006': 'Верхокам\'янка',
  'UA1410005007': 'Веселе',
  'UA1410005008': 'Вишневе',
  'UA1410005010': 'Зелене',
  'UA1410005034': 'Зернове',
  'UA1410007003': 'Азов',
  'UA1410007005': 'Бессарабка',
  'UA1410007004': 'Безіменне',
  'UA1410007011': 'Гусельщикове',
  'UA1410007012': 'Качкарське',
  'UA1410007035': 'Холодне',
  'UA1410007036': 'Хомутове',
  'UA1410007037': 'Хрещатицьке',
  'UA1410007013': 'Клинкине',
  'UA1410007014': 'Ковське',
  'UA1410007015': 'Козацьке',
  'UA1410007016': 'Козлівка',
  'UA1410007018': 'Куликове',
  'UA1410007017': 'Кузнеці',
  'UA1410007019': 'Маркине',
  'UA1410007020': 'Митьково-Качкарі',
  'UA1410007021': 'Набережне',
  'UA1410007001': 'Новоазовськ',
  'UA1410007040': 'Обрив',
  'UA1410007022': 'Олександрівське',
  'UA1410007023': 'Патріотичне',
  'UA1410007024': 'Первомайське',
  'UA1410007025': 'Порохня',
  'UA1410007026': 'Приморське',
  'UA1410007027': 'Роза',
  'UA1410007030': 'Саханка',
  'UA1410007028': 'Самійлове',
  'UA1410007029': 'Самсонове',
  'UA1410007039': 'Щербак',
  'UA1410007038': 'Шевченко',
  'UA1410007002': 'Сєдове',
  'UA1410007031': 'Сєдово-Василівка',
  'UA1410007032': 'Соснівське',
  'UA1410007034': 'Українське',
  'UA1410007033': 'Ужівка',
  'UA1410007006': 'Ванюшкине',
  'UA1410007007': 'Веденське',
  'UA1410007008': 'Верхньошироківське',
  'UA1410007009': 'Веселе',
  'UA1410007010': 'Вітава',
  'UA1410009003': 'Андріївка',
  'UA1410009019': 'Чумаки',
  'UA1410009005': 'Горбатенко',
  'UA1410009006': 'Кам\'янка',
  'UA1410009021': 'Манжиків Кут',
  'UA1410009007': 'Мар\'янівка',
  'UA1410009022': 'Менчугове',
  'UA1410009008': 'Новобешеве',
  'UA1410009009': 'Новоселівка',
  'UA1410009002': 'Новий Світ',
  'UA1410009010': 'Обільне',
  'UA1410009011': 'Олександрівка',
  'UA1410009012': 'Осикове',
  'UA1410009013': 'Петрівське',
  'UA1410009014': 'Піщане',
  'UA1410009015': 'Придорожнє',
  'UA1410009016': 'Сарабаш',
  'UA1410009001': 'Старобешеве',
  'UA1410009018': 'Стила',
  'UA1410009017': 'Світле',
  'UA1410009020': 'Верезамське',
  'UA1410009004': 'Вознесенка',
  'UA1412001001': 'Андріївка',
  'UA1412001003': 'Новоандріївка',
  'UA1412001004': 'Роганське',
  'UA1412001005': 'Сергіївка',
  'UA1412001002': 'Варварівка',
  'UA1412023001': 'Черкаське',
  'UA1412023002': 'Іванівка',
  'UA1412023004': 'Маячка',
  'UA1412023003': 'Майдан',
  'UA1412023005': 'Новомиколаївка',
  'UA1412023006': 'Новоселівка',
  'UA1412023007': 'Олександрівка',
  'UA1412023008': 'Прелесне',
  'UA1412023009': 'Привілля',
  'UA1412023011': 'Шнурки',
  'UA1412023010': 'Троїцьке',
  'UA1412003001': 'Дружківка',
  'UA1412003006': 'Дружківське',
  'UA1412003007': 'Кіндратівка',
  'UA1412003008': 'Красний Кут',
  'UA1412003009': 'Куртівка',
  'UA1412003010': 'Миколайпілля',
  'UA1412003002': 'Новогригорівка',
  'UA1412003003': 'Новомиколаївка',
  'UA1412003011': 'Новопавлівка',
  'UA1412003004': 'Олексієво-Дружківка',
  'UA1412003012': 'Осикове',
  'UA1412003013': 'Павлівка',
  'UA1412003014': 'Петрівка',
  'UA1412003019': 'Приют',
  'UA1412003005': 'Райське',
  'UA1412003015': 'Райське',
  'UA1412003016': 'Софіївка',
  'UA1412003020': 'Старорайське',
  'UA1412003017': 'Торецьке',
  'UA1412003018': 'Торське',
  'UA1412005021': 'Бересток',
  'UA1412005002': 'Березівка',
  'UA1412005022': 'Довга Балка',
  'UA1412005004': 'Гнатівка',
  'UA1412005001': 'Іллінівка',
  'UA1412005006': 'Калинове',
  'UA1412005007': 'Катеринівка',
  'UA1412005024': 'Клебан-Бик',
  'UA1412005008': 'Нова Полтавка',
  'UA1412005009': 'Новооленівка',
  'UA1412005010': 'Олександро-Калинове',
  'UA1412005011': 'Олександропіль',
  'UA1412005012': 'Плещіївка',
  'UA1412005013': 'Полтавка',
  'UA1412005014': 'Попів Яр',
  'UA1412005015': 'Романівка',
  'UA1412005025': 'Розкішне',
  'UA1412005016': 'Русин Яр',
  'UA1412005017': 'Стара Миколаївка',
  'UA1412005018': 'Степанівка',
  'UA1412005019': 'Тарасівка',
  'UA1412005003': 'Водяне Друге',
  'UA1412005020': 'Яблунівка',
  'UA1412005005': 'Зелене Поле',
  'UA1412005023': 'Зоря',
  'UA1412007020': 'Безім\'яне',
  'UA1412007002': 'Біла Гора',
  'UA1412007003': 'Білокузьминівка',
  'UA1412007024': 'Червоне',
  'UA1412007005': 'Диліївка',
  'UA1412007019': 'Федорівка',
  'UA1412007006': 'Іванопілля',
  'UA1412007007': 'Іжевка',
  'UA1412007008': 'Клинове',
  'UA1412007001': 'Костянтинівка',
  'UA1412007009': 'Майське',
  'UA1412007010': 'Маркове',
  'UA1412007021': 'Молочарка',
  'UA1412007011': 'Миколаївка',
  'UA1412007012': 'Неліпівка',
  'UA1412007022': 'Новодмитрівка',
  'UA1412007013': 'Новомаркове',
  'UA1412007014': 'Олександро-Шультине',
  'UA1412007015': 'Подільське',
  'UA1412007016': 'Попасне',
  'UA1412007017': 'Предтечине',
  'UA1412007023': 'Стінки',
  'UA1412007018': 'Ступочки',
  'UA1412007004': 'Віролюбівка',
  'UA1412009014': 'Ашуркове',
  'UA1412009002': 'Біленьке',
  'UA1412009011': 'Дмитрівка',
  'UA1412009003': 'Комишуваха',
  'UA1412009001': 'Краматорськ',
  'UA1412009004': 'Красноторка',
  'UA1412009005': 'Малотаранівка',
  'UA1412009006': 'Олександрівка',
  'UA1412009012': 'Привілля',
  'UA1412009013': 'Семенівка',
  'UA1412009008': 'Шабельківка',
  'UA1412009007': 'Софіївка',
  'UA1412009015': 'Василівська Пустош',
  'UA1412009009': 'Ясна Поляна',
  'UA1412009010': 'Ясногірка',
  'UA1412011007': 'Брусівка',
  'UA1412011009': 'Дерилове',
  'UA1412011010': 'Діброва',
  'UA1412011002': 'Дробишеве',
  'UA1412011013': 'Іванівка',
  'UA1412011014': 'Каленики',
  'UA1412011015': 'Карпівка',
  'UA1412011016': 'Катеринівка',
  'UA1412011017': 'Колодязі',
  'UA1412011018': 'Коровій Яр',
  'UA1412011020': 'Кримки',
  'UA1412011019': 'Крива Лука',
  'UA1412011022': 'Лозове',
  'UA1412011001': 'Лиман',
  'UA1412011021': 'Липове',
  'UA1412011037': 'Мирне',
  'UA1412011038': 'Нове',
  'UA1412011023': 'Новомихайлівка',
  'UA1412011024': 'Новосадове',
  'UA1412011004': 'Новоселівка',
  'UA1412011026': 'Олександрівка',
  'UA1412011025': 'Озерне',
  'UA1412011027': 'Рідкодуб',
  'UA1412011028': 'Рубці',
  'UA1412011029': 'Середнє',
  'UA1412011033': 'Шандриголове',
  'UA1412011034': 'Щурове',
  'UA1412011039': 'Соснове',
  'UA1412011030': 'Старий Караван',
  'UA1412011040': 'Ставки',
  'UA1412011031': 'Терни',
  'UA1412011032': 'Торське',
  'UA1412011008': 'Вовчий Яр',
  'UA1412011005': 'Ямпіль',
  'UA1412011035': 'Ямполівка',
  'UA1412011006': 'Ярова',
  'UA1412011036': 'Яцьківка',
  'UA1412011011': 'Закітне',
  'UA1412011003': 'Зарічне',
  'UA1412011012': 'Зелена Долина',
  'UA1412013002': 'Донецьке',
  'UA1412013005': 'Карпівка',
  'UA1412013006': 'Малинівка',
  'UA1412013001': 'Миколаївка',
  'UA1412013007': 'Никонорівка',
  'UA1412013008': 'Оріхуватка',
  'UA1412013009': 'Першомар\'ївка',
  'UA1412013010': 'Пискунівка',
  'UA1412013003': 'Райгородок',
  'UA1412013011': 'Рай-Олександрівка',
  'UA1412013012': 'Селезнівка',
  'UA1412013013': 'Стародубівка',
  'UA1412013014': 'Тихонівка',
  'UA1412013004': 'Васютинське',
  'UA1412013015': 'Юрківка',
  'UA1412015016': 'Федорівка',
  'UA1412015003': 'Іверське',
  'UA1412015004': 'Катеринівка',
  'UA1412015005': 'Криниці',
  'UA1412015007': 'Куроїдівка',
  'UA1412015006': 'Курицине',
  'UA1412015001': 'Новодонецьке',
  'UA1412015019': 'Новоявленка',
  'UA1412015008': 'Новоіверське',
  'UA1412015009': 'Новопавлівка',
  'UA1412015010': 'Новопетрівка',
  'UA1412015011': 'Новосамарське',
  'UA1412015012': 'Новосергіївка',
  'UA1412015020': 'Самарське',
  'UA1412015013': 'Самійлівка',
  'UA1412015017': 'Шевченко',
  'UA1412015018': 'Шостаківка',
  'UA1412015014': 'Спасько-Михайлівка',
  'UA1412015015': 'Степанівка',
  'UA1412015002': 'Весела Гора',
  'UA1412017002': 'Беззаботівка',
  'UA1412017003': 'Бузинівка',
  'UA1412017008': 'Дмитро-Дар\'ївка',
  'UA1412017009': 'Дмитроколине',
  'UA1412017006': 'Голубівка',
  'UA1412017007': 'Громова Балка',
  'UA1412017016': 'Карпівка',
  'UA1412017017': 'Левадне',
  'UA1412017018': 'Львівка',
  'UA1412017019': 'Мар\'ївка',
  'UA1412017022': 'Михайлівка',
  'UA1412017020': 'Микільське',
  'UA1412017021': 'Мирна Долина',
  'UA1412017023': 'Надеждівка',
  'UA1412017024': 'Некременне',
  'UA1412017026': 'Новоандріївка',
  'UA1412017027': 'Новобахметьєве',
  'UA1412017029': 'Новоолександрівка',
  'UA1412017030': 'Новополтавка',
  'UA1412017031': 'Новопригоже',
  'UA1412017032': 'Новостепанівка',
  'UA1412017028': 'Новознаменівка',
  'UA1412017025': 'Новий Кавказ',
  'UA1412017033': 'Очеретине',
  'UA1412017001': 'Олександрівка',
  'UA1412017034': 'Пасічне',
  'UA1412017035': 'Петрівка Друга',
  'UA1412017036': 'Петрівка Перша',
  'UA1412017037': 'Роздолля',
  'UA1412017041': 'Шаврове',
  'UA1412017039': 'Софіїно-Лиман',
  'UA1412017038': 'Софіївка',
  'UA1412017040': 'Староварварівка',
  'UA1412017004': 'Варварівка',
  'UA1412017005': 'Високопілля',
  'UA1412017042': 'Яковлівка',
  'UA1412017010': 'Єлизаветівка',
  'UA1412017011': 'Запаро-Мар\'ївка',
  'UA1412017012': 'Зелене',
  'UA1412017013': 'Зелений Брід',
  'UA1412017014': 'Знаменівка',
  'UA1412017015': 'Золоті Пруди',
  'UA1412021002': 'Андріївка',
  'UA1412021003': 'Билбасівка',
  'UA1412021005': 'Мирне',
  'UA1412021001': 'Слов\'янськ',
  'UA1412021004': 'Торець',
  'UA1412019002': 'Адамівка',
  'UA1412019003': 'Богородичне',
  'UA1412019005': 'Долина',
  'UA1412019004': 'Глибока Макатиха',
  'UA1412019013': 'Хрестище',
  'UA1412019006': 'Краснопілля',
  'UA1412019008': 'Маяки',
  'UA1412019007': 'Мазанівка',
  'UA1412019009': 'Микільське',
  'UA1412019010': 'Пришиб',
  'UA1412019001': 'Святогірськ',
  'UA1412019011': 'Сидорове',
  'UA1412019012': 'Тетянівка',
  'UA1414001016': 'Асланове',
  'UA1414001003': 'Гранітне',
  'UA1414001001': 'Кальчик',
  'UA1414001005': 'Касянівка',
  'UA1414001006': 'Катеринівка',
  'UA1414001007': 'Келерівка',
  'UA1414001015': 'Херсонес',
  'UA1414001009': 'Ключове',
  'UA1414001010': 'Кременівка',
  'UA1414001008': 'Кирилівка',
  'UA1414001011': 'Македонівка',
  'UA1414001012': 'Малоянисоль',
  'UA1414001013': 'Приовражне',
  'UA1414001014': 'Труженка',
  'UA1414001002': 'Водяне',
  'UA1414001004': 'Зоря',
  'UA1414003003': 'Азовське',
  'UA1414003004': 'Бабах-Тарама',
  'UA1414003005': 'Білосарайська Коса',
  'UA1414003006': 'Бурякова Балка',
  'UA1414003008': 'Дем\'янівка',
  'UA1414003007': 'Глибоке',
  'UA1414003010': 'Комишувате',
  'UA1414003001': 'Мангуш',
  'UA1414003011': 'Мелекіне',
  'UA1414003012': 'Огороднє',
  'UA1414003013': 'Портівське',
  'UA1414003014': 'Стародубівка',
  'UA1414003015': 'Українка',
  'UA1414003016': 'Урзуф',
  'UA1414003002': 'Ялта',
  'UA1414003017': 'Юр\'ївка',
  'UA1414003009': 'Захарівка',
  'UA1414005003': 'Агробаза',
  'UA1414005004': 'Бердянське',
  'UA1414005008': 'Червоне',
  'UA1414005001': 'Маріуполь',
  'UA1414005005': 'Покровське',
  'UA1414005006': 'Приазовське',
  'UA1414005007': 'Приміське',
  'UA1414005011': 'Рибацьке',
  'UA1414005009': 'Шевченко',
  'UA1414005010': 'Широка Балка',
  'UA1414005002': 'Старий Крим',
  'UA1414007002': 'Бойове',
  'UA1414007025': 'Федорівка',
  'UA1414007005': 'Кальчинівка',
  'UA1414007006': 'Криничне',
  'UA1414007007': 'Ксенівка',
  'UA1414007027': 'Лісне',
  'UA1414007008': 'Лугове',
  'UA1414007009': 'Малинівка',
  'UA1414007010': 'Назарівка',
  'UA1414007001': 'Нікольське',
  'UA1414007011': 'Новогригорівка',
  'UA1414007014': 'Новоянисоль',
  'UA1414007012': 'Новокраснівка',
  'UA1414007013': 'Новороманівка',
  'UA1414007015': 'Паннівка',
  'UA1414007017': 'Перемога',
  'UA1414007016': 'Первомайське',
  'UA1414007018': 'Республіка',
  'UA1414007019': 'Садове',
  'UA1414007020': 'Сергіївка',
  'UA1414007026': 'Шевченко',
  'UA1414007021': 'Суженка',
  'UA1414007022': 'Темрюк',
  'UA1414007023': 'Тополине',
  'UA1414007024': 'Українка',
  'UA1414007003': 'Веселе',
  'UA1414007004': 'Зелений Яр',
  'UA1414009003': 'Бердянське',
  'UA1414009014': 'Чермалик',
  'UA1414009015': 'Черненко',
  'UA1414009013': 'Федорівка',
  'UA1414009005': 'Гнутове',
  'UA1414009017': 'Калинівка',
  'UA1414009007': 'Лебединське',
  'UA1414009018': 'Ломакине',
  'UA1414009008': 'Орловське',
  'UA1414009009': 'Павлопіль',
  'UA1414009011': 'Пікузи',
  'UA1414009010': 'Пищевик',
  'UA1414009001': 'Сартана',
  'UA1414009016': 'Широкине',
  'UA1414009012': 'Сопине',
  'UA1414009002': 'Талаківка',
  'UA1414009004': 'Водяне',
  'UA1414009006': 'Заїченко',
  'UA1416001001': 'Авдіївка',
  'UA1416001002': 'Опитне',
  'UA1416003001': 'Білозерське',
  'UA1416003002': 'Благодать',
  'UA1416003007': 'Бокове',
  'UA1416003005': 'Нововодяне',
  'UA1416003006': 'Первомайське',
  'UA1416003003': 'Веселе Поле',
  'UA1416003004': 'Весна',
  'UA1416007002': 'Білицьке',
  'UA1416007001': 'Добропілля',
  'UA1416007007': 'Ганнівка',
  'UA1416007008': 'Копані',
  'UA1416007013': 'Красноярське',
  'UA1416007009': 'Кутузовка',
  'UA1416007011': 'Новоукраїнка',
  'UA1416007010': 'Нововікторівка',
  'UA1416007014': 'Новий Донбас',
  'UA1416007012': 'Рубіжне',
  'UA1416007016': 'Шевченко',
  'UA1416007004': 'Святогорівка',
  'UA1416007015': 'Світле',
  'UA1416007005': 'Вікторівка',
  'UA1416007006': 'Вірівка',
  'UA1416007003': 'Водянське',
  'UA1416005003': 'Балаган',
  'UA1416005004': 'Баранівка',
  'UA1416005030': 'Федорівка',
  'UA1416005001': 'Гродівка',
  'UA1416005011': 'Іванівка',
  'UA1416005012': 'Красний Яр',
  'UA1416005013': 'Крутий Яр',
  'UA1416005032': 'Лозуватське',
  'UA1416005014': 'Лисичне',
  'UA1416005015': 'Малинівка',
  'UA1416005021': 'Московське',
  'UA1416005020': 'Михайлівка',
  'UA1416005016': 'Миколаївка',
  'UA1416005017': 'Миколаївка',
  'UA1416005018': 'Мирне',
  'UA1416005019': 'Миролюбівка',
  'UA1416005002': 'Новоекономічне',
  'UA1416005022': 'Новоолександрівка',
  'UA1416005023': 'Новоторецьке',
  'UA1416005024': 'Прогрес',
  'UA1416005025': 'Промінь',
  'UA1416005026': 'Разіне',
  'UA1416005028': 'Сергіївка',
  'UA1416005031': 'Шевченко Перше',
  'UA1416005027': 'Свиридонівка',
  'UA1416005029': 'Тимофіївка',
  'UA1416005005': 'Веселе',
  'UA1416005006': 'Вовче',
  'UA1416005007': 'Воздвиженка',
  'UA1416005009': 'Єлизаветівка',
  'UA1416005008': 'Євгенівка',
  'UA1416005010': 'Журавка',
  'UA1416009004': 'Добропілля',
  'UA1416009003': 'Гуліве',
  'UA1416009008': 'Кам\'янка',
  'UA1416009009': 'Красноподілля',
  'UA1416009001': 'Криворіжжя',
  'UA1416009010': 'Лиман',
  'UA1416009011': 'Матяшеве',
  'UA1416009012': 'Мирне',
  'UA1416009013': 'Надія',
  'UA1416009018': 'Новофедорівка',
  'UA1416009014': 'Новогришине',
  'UA1416009015': 'Новокриворіжжя',
  'UA1416009016': 'Новомар\'ївка',
  'UA1416009017': 'Новоолександрівка',
  'UA1416009019': 'Петрівське',
  'UA1416009020': 'Ракша',
  'UA1416009021': 'Шилівка',
  'UA1416009002': 'Василівка',
  'UA1416009022': 'Юр\'ївка',
  'UA1416009005': 'Завидо-Борзенка',
  'UA1416009006': 'Завидо-Кудашеве',
  'UA1416009007': 'Зелене',
  'UA1416011006': 'Берестки',
  'UA1416011013': 'Дачне',
  'UA1416011012': 'Дальнє',
  'UA1416011010': 'Ганнівка',
  'UA1416011011': 'Гігант',
  'UA1416011002': 'Гірник',
  'UA1416011003': 'Гостре',
  'UA1416011004': 'Іллінка',
  'UA1416011016': 'Ізмайлівка',
  'UA1416011017': 'Костянтинопольське',
  'UA1416011018': 'Кремінна Балка',
  'UA1416011005': 'Курахівка',
  'UA1416011001': 'Курахове',
  'UA1416011019': 'Новодмитрівка',
  'UA1416011020': 'Новоселидівка',
  'UA1416011027': 'Острівське',
  'UA1416011021': 'Сонцівка',
  'UA1416011028': 'Старі Терни',
  'UA1416011022': 'Степанівка',
  'UA1416011023': 'Сухі Яли',
  'UA1416011024': 'Трудове',
  'UA1416011025': 'Успенівка',
  'UA1416011007': 'Веселий Гай',
  'UA1416011008': 'Вовченка',
  'UA1416011009': 'Вознесенка',
  'UA1416011026': 'Янтарне',
  'UA1416011014': 'Зеленівка',
  'UA1416011015': 'Зоря',
  'UA1416013003': 'Антонівка',
  'UA1416013004': 'Георгіївка',
  'UA1416013009': 'Іллінка',
  'UA1416013010': 'Катеринівка',
  'UA1416013011': 'Костянтинівка',
  'UA1416013002': 'Красногорівка',
  'UA1416013012': 'Максимільянівка',
  'UA1416013001': 'Мар\'їнка',
  'UA1416013013': 'Новомихайлівка',
  'UA1416013014': 'Олександропіль',
  'UA1416013015': 'Парасковіївка',
  'UA1416013016': 'Побєда',
  'UA1416013017': 'Романівка',
  'UA1416013018': 'Славне',
  'UA1416013005': 'Єлизаветівка',
  'UA1416013006': 'Желанне Друге',
  'UA1416013007': 'Желанне Перше',
  'UA1416013008': 'Зоряне',
  'UA1416015002': 'Красний Лиман',
  'UA1416015001': 'Мирноград',
  'UA1416015003': 'Рівне',
  'UA1416015005': 'Сухецьке',
  'UA1416015004': 'Світле',
  'UA1416017004': 'Долинівка',
  'UA1416017003': 'Галицинівка',
  'UA1416017006': 'Калинове',
  'UA1416017007': 'Карлівка',
  'UA1416017002': 'Комишівка',
  'UA1416017008': 'Лісівка',
  'UA1416017009': 'Маринівка',
  'UA1416017010': 'Мемрик',
  'UA1416017012': 'Михайлівка',
  'UA1416017011': 'Миколаївка',
  'UA1416017001': 'Новогродівка',
  'UA1416017013': 'Новожеланне',
  'UA1416017014': 'Орлівка',
  'UA1416017015': 'Птиче',
  'UA1416017005': 'Завітне',
  'UA1416019005': 'Архангельське',
  'UA1416019006': 'Бердичі',
  'UA1416019032': 'Бетманове',
  'UA1416019034': 'Кам\'янка',
  'UA1416019004': 'Керамік',
  'UA1416019010': 'Красногорівка',
  'UA1416019035': 'Ласточкине',
  'UA1416019036': 'Лозове',
  'UA1416019011': 'Межове',
  'UA1416019012': 'Нетайлове',
  'UA1416019037': 'Невельське',
  'UA1416019013': 'Новобахмутівка',
  'UA1416019014': 'Новобахмутівка',
  'UA1416019015': 'Новокалинове',
  'UA1416019016': 'Новопокровське',
  'UA1416019018': 'Новоселівка Друга',
  'UA1416019019': 'Новоселівка Перша',
  'UA1416019017': 'Новоселівка',
  'UA1416019001': 'Очеретине',
  'UA1416019020': 'Олександропіль',
  'UA1416019021': 'Орлівка',
  'UA1416019022': 'Пантелеймонівка',
  'UA1416019023': 'Первомайське',
  'UA1416019038': 'Піски',
  'UA1416019024': 'Семенівка',
  'UA1416019039': 'Сєверне',
  'UA1416019025': 'Скучне',
  'UA1416019026': 'Сокіл',
  'UA1416019027': 'Соловйове',
  'UA1416019040': 'Степове',
  'UA1416019028': 'Тоненьке',
  'UA1416019029': 'Троїцьке',
  'UA1416019030': 'Уманське',
  'UA1416019007': 'Василівка',
  'UA1416019002': 'Верхньоторецьке',
  'UA1416019008': 'Веселе',
  'UA1416019009': 'Водяне',
  'UA1416019033': 'Восход',
  'UA1416019031': 'Яснобродівка',
  'UA1416019003': 'Желанне',
  'UA1416021004': 'Богданівка',
  'UA1416021041': 'Чунишине',
  'UA1416021009': 'Даченське',
  'UA1416021006': 'Гнатівка',
  'UA1416021007': 'Горіхове',
  'UA1416021008': 'Гришине',
  'UA1416021036': 'Котлярівка',
  'UA1416021035': 'Котлине',
  'UA1416021014': 'Лисівка',
  'UA1416021037': 'Надеждинка',
  'UA1416021016': 'Новоандріївка',
  'UA1416021018': 'Новоєлизаветівка',
  'UA1416021019': 'Новоолександрівка',
  'UA1416021020': 'Новооленівка',
  'UA1416021021': 'Новопавлівка',
  'UA1416021038': 'Новопустинка',
  'UA1416021022': 'Новотроїцьке',
  'UA1416021023': 'Новоукраїнка',
  'UA1416021017': 'Нововасилівка',
  'UA1416021015': 'Новий Труд',
  'UA1416021039': 'Перше Травня',
  'UA1416021024': 'Піщане',
  'UA1416021001': 'Покровськ',
  'UA1416021025': 'Преображенка',
  'UA1416021040': 'Пушкіне',
  'UA1416021026': 'Ріг',
  'UA1416021002': 'Родинське',
  'UA1416021003': 'Шевченко',
  'UA1416021027': 'Солоне',
  'UA1416021028': 'Срібне',
  'UA1416021029': 'Сухий Яр',
  'UA1416021031': 'Троянда',
  'UA1416021030': 'Троїцьке',
  'UA1416021032': 'Українка',
  'UA1416021033': 'Успенівка',
  'UA1416021005': 'Вовкове',
  'UA1416021034': 'Ясенове',
  'UA1416021011': 'Запоріжжя',
  'UA1416021013': 'Зелене',
  'UA1416021010': 'Жовте',
  'UA1416021012': 'Звірове',
  'UA1416023005': 'Григорівка',
  'UA1416023006': 'Новоолексіївка',
  'UA1416023007': 'Петрівка',
  'UA1416023008': 'Пустинка',
  'UA1416023001': 'Селидове',
  'UA1416023004': 'Цукурине',
  'UA1416023002': 'Українськ',
  'UA1416023003': 'Вишневе',
  'UA1416023009': 'Юр\'ївка',
  'UA1416027002': 'Бойківка',
  'UA1416027021': 'Дорожнє',
  'UA1416027006': 'Грузьке',
  'UA1416027008': 'Іванівка',
  'UA1416027009': 'Коптєве',
  'UA1416027010': 'Кучерів Яр',
  'UA1416027011': 'Лідине',
  'UA1416027022': 'Маяк',
  'UA1416027012': 'Мар\'ївка',
  'UA1416027014': 'Нове Шахове',
  'UA1416027015': 'Новоторецьке',
  'UA1416027016': 'Новотроїцьке',
  'UA1416027013': 'Никанорівка',
  'UA1416027017': 'Панківка',
  'UA1416027018': 'Петрівка',
  'UA1416027001': 'Шахове',
  'UA1416027019': 'Суворове',
  'UA1416027020': 'Торецьке',
  'UA1416027003': 'Веселе',
  'UA1416027004': 'Вільне',
  'UA1416027005': 'Володимирівка',
  'UA1416027007': 'Золотий Колодязь',
  'UA1416025002': 'Калинівка',
  'UA1416025003': 'Молодецьке',
  'UA1416025004': 'Муравка',
  'UA1416025005': 'Новомиколаївка',
  'UA1416025006': 'Новосергіївка',
  'UA1416025007': 'Сергіївка',
  'UA1416025001': 'Удачне',
  'UA1404015002': 'Анадоль',
  'UA1404015006': 'Діанівка',
  'UA1404015005': 'Голубицьке',
  'UA1404015011': 'Калинове',
  'UA1404015001': 'Хлібодарівка',
  'UA1404015012': 'Кропивницьке',
  'UA1404015013': 'Лазарівка',
  'UA1404015014': 'Лідине',
  'UA1404015015': 'Малинівка',
  'UA1404015016': 'Новомиколаївка',
  'UA1404015017': 'Новоолексіївка',
  'UA1404015018': 'Передове',
  'UA1404015019': 'Петрівка',
  'UA1404015020': 'Полкове',
  'UA1404015021': 'Привільне',
  'UA1404015022': 'Рівнопіль',
  'UA1404015026': 'Шевченко',
  'UA1404015023': 'Сонячне',
  'UA1404015024': 'Степне',
  'UA1404015025': 'Стрітенка',
  'UA1404015003': 'Веселе',
  'UA1404015004': 'Вільне',
  'UA1404015008': 'Зачатівка',
  'UA1404015027': 'Зачатівка',
  'UA1404015007': 'Затишне',
  'UA1404015009': 'Златоустівка',
  'UA1404015010': 'Знаменівка',
  'UA1404007020': 'Бурлацьке',
  'UA1404007006': 'Дніпроенергія',
  'UA1404007018': 'Федорівка',
  'UA1404007005': 'Грушівське',
  'UA1404007009': 'Іскра',
  'UA1404007001': 'Комар',
  'UA1404007022': 'Комишуваха',
  'UA1404007010': 'Мирне',
  'UA1404007012': 'Новохатське',
  'UA1404007011': 'Новоочеретувате',
  'UA1404007013': 'Олександроград',
  'UA1404007023': 'Перебудова',
  'UA1404007014': 'Піддубне',
  'UA1404007015': 'Привільне',
  'UA1404007024': 'Шевченко',
  'UA1404007016': 'Скудне',
  'UA1404007017': 'Толстой',
  'UA1404007002': 'Веселе',
  'UA1404007003': 'Вільне Поле',
  'UA1404007004': 'Воскресенка',
  'UA1404007019': 'Ялта',
  'UA1404007007': 'Запоріжжя',
  'UA1404007021': 'Зелений Гай',
  'UA1404007008': 'Зірка',
  'UA1404009002': 'Андріївка',
  'UA1404009012': 'Бахчовик',
  'UA1404009013': 'Дружне',
  'UA1404009003': 'Гранітне',
  'UA1404009005': 'Кам\'янка',
  'UA1404009014': 'Маловодне',
  'UA1404009001': 'Мирне',
  'UA1404009006': 'Новогригорівка',
  'UA1404009008': 'Новоселівка Друга',
  'UA1404009007': 'Новоселівка',
  'UA1404009015': 'Обільне',
  'UA1404009009': 'Старогнатівка',
  'UA1404009010': 'Старомар\'ївка',
  'UA1404009011': 'Степанівка',
  'UA1404009004': 'Запорізьке',
  'UA1404011002': 'Благодатне',
  'UA1404011006': 'Богданівка',
  'UA1404011004': 'Графське',
  'UA1404011008': 'Лісне',
  'UA1404011009': 'Миколаївка',
  'UA1404011010': 'Новогнатівка',
  'UA1404011005': 'Новотроїцьке',
  'UA1404011001': 'Ольгинка',
  'UA1404011011': 'Пільне',
  'UA1404011007': 'Вікторівка',
  'UA1404011003': 'Володимирівка',
  'UA1404013003': 'Георгіївка',
  'UA1404013012': 'Керменчик',
  'UA1404013013': 'Ключове',
  'UA1404013006': 'Красна Поляна',
  'UA1404013007': 'Малий Керменчик',
  'UA1404013014': 'Новодонецьке',
  'UA1404013008': 'Новомайорське',
  'UA1404013009': 'Новопетриківка',
  'UA1404013010': 'Орлинське',
  'UA1404013001': 'Старомлинівка',
  'UA1404013002': 'Володине',
  'UA1404013011': 'Ялинське',
  'UA1404013004': 'Євгенівка',
  'UA1404013005': 'Завітне Бажання',
  'UA1404001002': 'Андріївка',
  'UA1404001003': 'Багатир',
  'UA1404001023': 'Благодатне',
  'UA1404001007': 'Костянтинопіль',
  'UA1404001008': 'Макарівка',
  'UA1404001009': 'Нескучне',
  'UA1404001011': 'Новопіль',
  'UA1404001012': 'Новосілка',
  'UA1404001013': 'Новоукраїнка',
  'UA1404001010': 'Новий Комар',
  'UA1404001025': 'Одрадне',
  'UA1404001014': 'Олексіївка',
  'UA1404001015': 'Петропавлівка',
  'UA1404001016': 'Рівнопіль',
  'UA1404001026': 'Роздольне',
  'UA1404001027': 'Розлив',
  'UA1404001021': 'Шахтарське',
  'UA1404001022': 'Шевченко',
  'UA1404001017': 'Слов\'янка',
  'UA1404001018': 'Старомайорське',
  'UA1404001019': 'Сторожеве',
  'UA1404001020': 'Улакли',
  'UA1404001028': 'Урожайне',
  'UA1404001001': 'Велика Новосілка',
  'UA1404001004': 'Времівка',
  'UA1404001029': 'Ясна Поляна',
  'UA1404001005': 'Зелене Поле',
  'UA1404001006': 'Зелений Кут',
  'UA1404001024': 'Золота Нива',
  'UA1404003003': 'Благовіщенка',
  'UA1404003004': 'Ближнє',
  'UA1404003005': 'Бугас',
  'UA1404003008': 'Дмитрівка',
  'UA1404003002': 'Донське',
  'UA1404003010': 'Іванівка',
  'UA1404003011': 'Кирилівка',
  'UA1404003012': 'Малогнатівка',
  'UA1404003013': 'Новоандріївка',
  'UA1404003014': 'Новоапостолівка',
  'UA1404003015': 'Новогригорівка',
  'UA1404003016': 'Новопавлівка',
  'UA1404003017': 'Новотатарівка',
  'UA1404003018': 'Прохорівка',
  'UA1404003019': 'Рибинське',
  'UA1404003020': 'Свободне',
  'UA1404003021': 'Тарасівка',
  'UA1404003022': 'Трудівське',
  'UA1404003023': 'Трудове',
  'UA1404003006': 'Валер\'янівка',
  'UA1404003007': 'Василівка',
  'UA1404003001': 'Волноваха',
  'UA1404003009': 'Зелений Гай',
  'UA1404005002': 'Березове',
  'UA1404005003': 'Богоявленка',
  'UA1404005006': 'Максимівка',
  'UA1404005007': 'Микільське',
  'UA1404005008': 'Новоукраїнка',
  'UA1404005009': 'Павлівка',
  'UA1404005010': 'Петрівське',
  'UA1404005011': 'Пречистівка',
  'UA1404005015': 'Шевченко',
  'UA1404005012': 'Солодке',
  'UA1404005013': 'Степне',
  'UA1404005014': 'Тарамчук',
  'UA1404005004': 'Водяне',
  'UA1404005001': 'Вугледар',
  'UA1404005005': 'Єгорівка',
  'UA2604001001': 'Більшівці',
  'UA2604001002': 'Дитятин',
  'UA2604001015': 'Хохонів',
  'UA2604001005': 'Кінашів',
  'UA2604001006': 'Кукільники',
  'UA2604001007': 'Курів',
  'UA2604001008': 'Набережне',
  'UA2604001009': 'Нараївка',
  'UA2604001010': 'Нові Скоморохи',
  'UA2604001011': 'Підшумлянці',
  'UA2604001012': 'Поділля',
  'UA2604001013': 'Слобідка Більшівцівська',
  'UA2604001014': 'Старі Скоморохи',
  'UA2604001016': 'Яблунів',
  'UA2604001004': 'Загір\'я-Кукільницьке',
  'UA2604001003': 'Жалибори',
  'UA2604003001': 'Богородчани',
  'UA2604003007': 'Діброва',
  'UA2604003002': 'Глибівка',
  'UA2604003003': 'Глибоке',
  'UA2604003004': 'Горохолин Ліс',
  'UA2604003005': 'Горохолина',
  'UA2604003006': 'Грабовець',
  'UA2604003010': 'Іваниківка',
  'UA2604003017': 'Хмелівка',
  'UA2604003011': 'Копачівка',
  'UA2604003012': 'Ластівці',
  'UA2604003013': 'Підгір\'я',
  'UA2604003014': 'Похівка',
  'UA2604003015': 'Саджава',
  'UA2604003016': 'Старуня',
  'UA2604003009': 'Забережжя',
  'UA2604003008': 'Жураки',
  'UA2604005002': 'Букачівська Слобода',
  'UA2604005001': 'Букачівці',
  'UA2604005012': 'Чагрів',
  'UA2604005013': 'Чернів',
  'UA2604005008': 'Колоколин',
  'UA2604005007': 'Козарі',
  'UA2604005009': 'Луковець-Вишнівський',
  'UA2604005010': 'Луковець-Журівський',
  'UA2604005011': 'Посвірж',
  'UA2604005004': 'Вишнів',
  'UA2604005003': 'Витань',
  'UA2604005005': 'Журавеньки',
  'UA2604005006': 'Зруб',
  'UA2604007002': 'Бовшів',
  'UA2604007001': 'Бурштин',
  'UA2604007004': 'Дем\'янів',
  'UA2604007005': 'Діброва',
  'UA2604007008': 'Коростовичі',
  'UA2604007009': 'Куничі',
  'UA2604007010': 'Куропатники',
  'UA2604007011': 'Насташине',
  'UA2604007012': 'Новий Мартинів',
  'UA2604007013': 'Озеряни',
  'UA2604007014': 'Різдвяни',
  'UA2604007015': 'Сарники',
  'UA2604007016': 'Слобода',
  'UA2604007017': 'Старий Мартинів',
  'UA2604007018': 'Тенетники',
  'UA2604007003': 'Вигівка',
  'UA2604007019': 'Юнашків',
  'UA2604007006': 'Задністрянське',
  'UA2604007007': 'Заливки',
  'UA2604013002': 'Бишів',
  'UA2604013005': 'Делієве',
  'UA2604013001': 'Дубівці',
  'UA2604013006': 'Кінчаки',
  'UA2604013007': 'Кремидів',
  'UA2604013008': 'Лани',
  'UA2604013009': 'Маріямпіль',
  'UA2604013010': 'Медуха',
  'UA2604013011': 'Межигірці',
  'UA2604013012': 'Озерце',
  'UA2604013013': 'Садки',
  'UA2604013014': 'Тумир',
  'UA2604013015': 'Тустань',
  'UA2604013003': 'Водники',
  'UA2604013004': 'Ворониця',
  'UA2604011001': 'Дзвиняч',
  'UA2604011002': 'Космач',
  'UA2604011003': 'Луквиця',
  'UA2604011004': 'Міжгір\'я',
  'UA2604011005': 'Росільна',
  'UA2604009002': 'Блюдники',
  'UA2604009003': 'Бринь',
  'UA2604009007': 'Демешківці',
  'UA2604009008': 'Дорогів',
  'UA2604009001': 'Галич',
  'UA2604009006': 'Ганнівці',
  'UA2604009011': 'Колодіїв',
  'UA2604009012': 'Комарів',
  'UA2604009010': 'Козина',
  'UA2604009013': 'Крилос',
  'UA2604009014': 'Курипів',
  'UA2604009015': 'Мединя',
  'UA2604009016': 'Німшин',
  'UA2604009017': 'Острів',
  'UA2604009018': 'Перлівці',
  'UA2604009019': 'Поплавники',
  'UA2604009020': 'Придністров\'я',
  'UA2604009021': 'Пукасівці',
  'UA2604009022': 'Сапогів',
  'UA2604009026': 'Шевченкове',
  'UA2604009023': 'Сокіл',
  'UA2604009024': 'Суботів',
  'UA2604009025': 'Темирівці',
  'UA2604009005': 'Вікторів',
  'UA2604009004': 'Височанка',
  'UA2604009009': 'Залуква',
  'UA2604019002': 'Березівка',
  'UA2604019003': 'Братківці',
  'UA2604019018': 'Черніїв',
  'UA2604019019': 'Чукалівка',
  'UA2604019005': 'Добровляни',
  'UA2604019006': 'Драгомирчани',
  'UA2604019001': 'Івано-Франківськ',
  'UA2604019007': 'Камінне',
  'UA2604019017': 'Хриплин',
  'UA2604019008': 'Колодіївка',
  'UA2604019009': 'Крихівці',
  'UA2604019010': 'Микитинці',
  'UA2604019011': 'Підлужжя',
  'UA2604019012': 'Підпечери',
  'UA2604019013': 'Радча',
  'UA2604019014': 'Тисменичани',
  'UA2604019015': 'Угорники',
  'UA2604019016': 'Узин',
  'UA2604019004': 'Вовчинець',
  'UA2604021001': 'Лисець',
  'UA2604021002': 'Посіч',
  'UA2604021003': 'Старий Лисець',
  'UA2604021004': 'Стебник',
  'UA2604023003': 'Гарасимів',
  'UA2604023002': 'Гавриляк',
  'UA2604023004': 'Гончарів',
  'UA2604023008': 'Хотимир',
  'UA2604023001': 'Обертин',
  'UA2604023007': 'Олещин',
  'UA2604023009': 'Яківка',
  'UA2604023005': 'Жабокруки',
  'UA2604023006': 'Жуків',
  'UA2604025002': 'Будзин',
  'UA2604025003': 'Делева',
  'UA2604025004': 'Долина',
  'UA2604025006': 'Ісаків',
  'UA2604025007': 'Луг',
  'UA2604025008': 'Мостище',
  'UA2604025009': 'Одаїв',
  'UA2604025001': 'Олеша',
  'UA2604025010': 'Озеряни',
  'UA2604025011': 'Петрів',
  'UA2604025012': 'Підвербці',
  'UA2604025014': 'Соколівка',
  'UA2604025013': 'Сокирчин',
  'UA2604025015': 'Суходіл',
  'UA2604025005': 'Живачів',
  'UA2604027002': 'Бабухів',
  'UA2604027003': 'Беньківці',
  'UA2604027004': 'Березівка',
  'UA2604027005': 'Бойки',
  'UA2604027062': 'Черче',
  'UA2604027063': 'Чесники',
  'UA2604027016': 'Данильче',
  'UA2604027017': 'Дегова',
  'UA2604027019': 'Добринів',
  'UA2604027020': 'Долиняни',
  'UA2604027018': 'Дички',
  'UA2604027061': 'Фрага',
  'UA2604027013': 'Гоноратівка',
  'UA2604027014': 'Городиська',
  'UA2604027015': 'Григорів',
  'UA2604027067': 'Григорівська Слобода',
  'UA2604027068': 'Кам\'янка',
  'UA2604027029': 'Кліщівна',
  'UA2604027030': 'Княгиничі',
  'UA2604027031': 'Конюшки',
  'UA2604027032': 'Корчунок',
  'UA2604027033': 'Кривня',
  'UA2604027034': 'Кутці',
  'UA2604027069': 'Лісова',
  'UA2604027039': 'Любша',
  'UA2604027036': 'Лопушня',
  'UA2604027038': 'Лучинці',
  'UA2604027037': 'Луковище',
  'UA2604027035': 'Липівка',
  'UA2604027040': 'Малий Заланів',
  'UA2604027041': 'Малинівка',
  'UA2604027042': 'Мельна',
  'UA2604027070': 'Межигаї',
  'UA2604027043': 'Нижня Липиця',
  'UA2604027044': 'Обельниця',
  'UA2604027045': 'Перенівка',
  'UA2604027046': 'Підбір\'я',
  'UA2604027048': 'Підгороддя',
  'UA2604027049': 'Підкамінь',
  'UA2604027050': 'Підмихайлівці',
  'UA2604027047': 'Підвиння',
  'UA2604027051': 'Погребівка',
  'UA2604027052': 'Помонята',
  'UA2604027053': 'Потік',
  'UA2604027054': 'Приозерне',
  'UA2604027055': 'Пуків',
  'UA2604027056': 'Путятинці',
  'UA2604027071': 'Пилипівці',
  'UA2604027001': 'Рогатин',
  'UA2604027057': 'Руда',
  'UA2604027072': 'Стефанівка',
  'UA2604027059': 'Стратин',
  'UA2604027058': 'Світанок',
  'UA2604027060': 'Уїзд',
  'UA2604027006': 'Васючин',
  'UA2604027007': 'Вербилівці',
  'UA2604027008': 'Верхня Липиця',
  'UA2604027010': 'Вільхова',
  'UA2604027011': 'Воронів',
  'UA2604027012': 'Воскресинці',
  'UA2604027066': 'Вигода',
  'UA2604027009': 'Виспа',
  'UA2604027065': 'Яглуш',
  'UA2604027064': 'Явче',
  'UA2604027028': 'Йосипівка',
  'UA2604027023': 'Загір\'я',
  'UA2604027024': 'Заланів',
  'UA2604027026': 'Залужжя',
  'UA2604027025': 'Залип\'я',
  'UA2604027027': 'Зеленів',
  'UA2604027021': 'Жовчів',
  'UA2604027022': 'Журів',
  'UA2604029002': 'Бабче',
  'UA2604029003': 'Богрівка',
  'UA2604029014': 'Бойки',
  'UA2604029004': 'Гута',
  'UA2604029006': 'Кричка',
  'UA2604029005': 'Кривець',
  'UA2604029007': 'Манява',
  'UA2604029008': 'Маркова',
  'UA2604029009': 'Монастирчани',
  'UA2604029010': 'Пороги',
  'UA2604029011': 'Раковець',
  'UA2604029001': 'Солотвин',
  'UA2604029012': 'Стара Гута',
  'UA2604029013': 'Яблунька',
  'UA2604031002': 'Гринівка',
  'UA2604031003': 'Лесівка',
  'UA2604031004': 'Нивочин',
  'UA2604031005': 'Скобичівка',
  'UA2604031001': 'Старі Богородчани',
  'UA2604035002': 'Антонівка',
  'UA2604035003': 'Бортники',
  'UA2604035004': 'Братишів',
  'UA2604035005': 'Буківна',
  'UA2604035006': 'Бушкалик',
  'UA2604035014': 'Діброва',
  'UA2604035009': 'Гончарівка',
  'UA2604035010': 'Гостів',
  'UA2604035011': 'Грабичанка',
  'UA2604035013': 'Грушка',
  'UA2604035012': 'Гринівці',
  'UA2604035017': 'Колінці',
  'UA2604035018': 'Королівка',
  'UA2604035019': 'Купелів',
  'UA2604035020': 'Кутище',
  'UA2604035022': 'Локітка',
  'UA2604035021': 'Лиса Гора',
  'UA2604035023': 'Мельники',
  'UA2604035024': 'Надорожна',
  'UA2604035026': 'Новосілка',
  'UA2604035025': 'Нижнів',
  'UA2604035027': 'Олешів',
  'UA2604035028': 'Остриня',
  'UA2604035029': 'Палагичі',
  'UA2604035030': 'Петрилів',
  'UA2604035031': 'Попелів',
  'UA2604035032': 'Прибилів',
  'UA2604035033': 'Пужники',
  'UA2604035034': 'Смерклів',
  'UA2604035035': 'Тарасівка',
  'UA2604035001': 'Тлумач',
  'UA2604035007': 'Вікняни',
  'UA2604035008': 'Вільне',
  'UA2604035015': 'Загір\'я',
  'UA2604035016': 'Золота Липа',
  'UA2604033019': 'Чорнолізці',
  'UA2604033018': 'Хом\'яківка',
  'UA2604033003': 'Клубівці',
  'UA2604033004': 'Красилівка',
  'UA2604033005': 'Липівка',
  'UA2604033006': 'Марківці',
  'UA2604033007': 'Милування',
  'UA2604033008': 'Нова Липівка',
  'UA2604033009': 'Нові Кривотули',
  'UA2604033010': 'Одаї',
  'UA2604033011': 'Погоня',
  'UA2604033012': 'Пшеничники',
  'UA2604033013': 'Рошнів',
  'UA2604033014': 'Слобідка',
  'UA2604033015': 'Старі Кривотули',
  'UA2604033016': 'Студинець',
  'UA2604033017': 'Терновиця',
  'UA2604033001': 'Тисмениця',
  'UA2604033002': 'Вільшаниця',
  'UA2604037002': 'Клузів',
  'UA2604037001': 'Угринів',
  'UA2604039002': 'Майдан',
  'UA2604039003': 'Нова Гута',
  'UA2604039004': 'Павлівка',
  'UA2604039005': 'Рибне',
  'UA2604039006': 'Сілець',
  'UA2604039007': 'Тязів',
  'UA2604039008': 'Ценжів',
  'UA2604039001': 'Ямниця',
  'UA2604015003': 'Довге',
  'UA2604015002': 'Ганнусівка',
  'UA2604015004': 'Побережжя',
  'UA2604015005': 'Стриганці',
  'UA2604015001': 'Єзупіль',
  'UA2604017002': 'Підлісся',
  'UA2604017001': 'Загвіздя',
  'UA2606001001': 'Болехів',
  'UA2606001002': 'Бубнище',
  'UA2606001003': 'Буковець',
  'UA2606001004': 'Гузіїв',
  'UA2606001006': 'Козаківка',
  'UA2606001007': 'Міжріччя',
  'UA2606001008': 'Поляниця',
  'UA2606001009': 'Сукіль',
  'UA2606001010': 'Танява',
  'UA2606001011': 'Тисів',
  'UA2606001005': 'Заріччя',
  'UA2606003002': 'Брошнів',
  'UA2606003001': 'Брошнів-Осада',
  'UA2606003003': 'Кадобна',
  'UA2606003004': 'Креховичі',
  'UA2606003005': 'Раків',
  'UA2606013002': 'Белеїв',
  'UA2606013007': 'Діброва',
  'UA2606013001': 'Долина',
  'UA2606013004': 'Гериня',
  'UA2606013005': 'Гошів',
  'UA2606013006': 'Грабів',
  'UA2606013008': 'Княжолука',
  'UA2606013009': 'Крива',
  'UA2606013010': 'Лоп\'янка',
  'UA2606013011': 'Мала Тур\'я',
  'UA2606013012': 'Надіїв',
  'UA2606013013': 'Новичка',
  'UA2606013014': 'Оболоння',
  'UA2606013015': 'Підбережжя',
  'UA2606013016': 'Рахиня',
  'UA2606013017': 'Слобода-Долинська',
  'UA2606013018': 'Солуків',
  'UA2606013020': 'Тяпче',
  'UA2606013019': 'Тростянець',
  'UA2606013003': 'Велика Тур\'я',
  'UA2606013022': 'Якубів',
  'UA2606013021': 'Яворів',
  'UA2606015001': 'Дуба',
  'UA2606015003': 'Дубшари',
  'UA2606015004': 'Іванівка',
  'UA2606015005': 'Князівське',
  'UA2606015006': 'Лецівка',
  'UA2606015007': 'Підлісся',
  'UA2606015008': 'Ріпне',
  'UA2606015009': 'Рошняте',
  'UA2606015010': 'Цінева',
  'UA2606015002': 'Вільхівка',
  'UA2606015011': 'Ясеновець',
  'UA2606017002': 'Бабин-Зарічний',
  'UA2606017003': 'Боднарів',
  'UA2606017006': 'Довге-Калуське',
  'UA2606017005': 'Голинь',
  'UA2606017001': 'Калуш',
  'UA2606017007': 'Копанки',
  'UA2606017008': 'Кропивник',
  'UA2606017010': 'Мостище',
  'UA2606017009': 'Мислів',
  'UA2606017011': 'Пійло',
  'UA2606017012': 'Ріп\'янка',
  'UA2606017013': 'Середній Бабин',
  'UA2606017014': 'Сівка-Калуська',
  'UA2606017015': 'Студінка',
  'UA2606017016': 'Тужилів',
  'UA2606017004': 'Вістова',
  'UA2606017017': 'Яворівка',
  'UA2606019002': 'Бережниця',
  'UA2606019003': 'Берлоги',
  'UA2606019005': 'Добровляни',
  'UA2606019004': 'Грабівка',
  'UA2606019001': 'Новиця',
  'UA2606019008': 'Підмихайля',
  'UA2606019009': 'Середній Угринів',
  'UA2606019010': 'Старий Угринів',
  'UA2606019006': 'Завій',
  'UA2606019007': 'Зелений Яр',
  'UA2606021012': 'Бабське',
  'UA2606021016': 'Черепина',
  'UA2606021002': 'Гриньків',
  'UA2606021004': 'Красне',
  'UA2606021013': 'Кузьминець',
  'UA2606021005': 'Лази',
  'UA2606021006': 'Ловаги',
  'UA2606021007': 'Небилів',
  'UA2606021008': 'Осмолода',
  'UA2606021001': 'Перегінське',
  'UA2606021014': 'Погар',
  'UA2606021010': 'Слобода-Небилівська',
  'UA2606021009': 'Сливки',
  'UA2606021015': 'Турівка',
  'UA2606021011': 'Ясень',
  'UA2606021003': 'Закерничне',
  'UA2606023004': 'Камінь',
  'UA2606023005': 'Нижній Струтинь',
  'UA2606023006': 'Петранка',
  'UA2606023007': 'Рівня',
  'UA2606023001': 'Рожнятів',
  'UA2606023009': 'Слобода-Рівнянська',
  'UA2606023008': 'Сваричів',
  'UA2606023010': 'Топільське',
  'UA2606023002': 'Вербівка',
  'UA2606023003': 'Верхній Струтинь',
  'UA2606025003': 'Луги',
  'UA2606025002': 'Липовиця',
  'UA2606025004': 'Підсухи',
  'UA2606025005': 'Погорілець',
  'UA2606025001': 'Спас',
  'UA2606025006': 'Суходіл',
  'UA2606005002': 'Болохів',
  'UA2606005005': 'Довгий Войнилів',
  'UA2606005004': 'Гуменів',
  'UA2606005008': 'Іванкова',
  'UA2606005009': 'Кулинка',
  'UA2606005010': 'Негівці',
  'UA2606005011': 'Станькова',
  'UA2606005012': 'Степанівка',
  'UA2606005001': 'Верхня',
  'UA2606005003': 'Вилки',
  'UA2606005006': 'Завадка',
  'UA2606005007': 'Збора',
  'UA2606011002': 'Діброва',
  'UA2606011004': 'Довпотів',
  'UA2606011003': 'Довжка',
  'UA2606011005': 'Дубовиця',
  'UA2606011006': 'Кудлатівка',
  'UA2606011007': 'Лука',
  'UA2606011008': 'Мошківці',
  'UA2606011009': 'Павликівка',
  'UA2606011011': 'Перекоси',
  'UA2606011010': 'Перевозець',
  'UA2606011012': 'Середня',
  'UA2606011013': 'Сівка-Войнилівська',
  'UA2606011014': 'Слобідка',
  'UA2606011015': 'Томашівці',
  'UA2606011016': 'Цвітова',
  'UA2606011001': 'Войнилів',
  'UA2606007002': 'Ангелівка',
  'UA2606007004': 'Ілемня',
  'UA2606007005': 'Кропивник',
  'UA2606007006': 'Лолин',
  'UA2606007007': 'Максимівка',
  'UA2606007008': 'Мислівка',
  'UA2606007010': 'Новоселиця',
  'UA2606007011': 'Новошин',
  'UA2606007009': 'Новий Мізунь',
  'UA2606007012': 'Пациків',
  'UA2606007013': 'Підліски',
  'UA2606007014': 'Пшеничники',
  'UA2606007015': 'Сенечів',
  'UA2606007017': 'Шевченкове',
  'UA2606007016': 'Старий Мізунь',
  'UA2606007001': 'Вигода',
  'UA2606007003': 'Вишків',
  'UA2606009002': 'Кальна',
  'UA2606009004': 'Лужки',
  'UA2606009003': 'Липа',
  'UA2606009005': 'Розточки',
  'UA2606009006': 'Слобода-Болехівська',
  'UA2606009007': 'Станківці',
  'UA2606009008': 'Церковна',
  'UA2606009009': 'Вигодівка',
  'UA2606009001': 'Витвиця',
  'UA2608025001': 'Чернелиця',
  'UA2608025003': 'Далешове',
  'UA2608025004': 'Дубки',
  'UA2608025010': 'Хмелева',
  'UA2608025005': 'Колінки',
  'UA2608025006': 'Копачинці',
  'UA2608025007': 'Корнів',
  'UA2608025008': 'Кунисівці',
  'UA2608025009': 'Репужинці',
  'UA2608025002': 'Вільхівці',
  'UA2608003002': 'Білка',
  'UA2608003037': 'Чернятин',
  'UA2608003038': 'Чортовець',
  'UA2608003007': 'Глушків',
  'UA2608003001': 'Городенка',
  'UA2608003008': 'Городниця',
  'UA2608003036': 'Хвалибога',
  'UA2608003009': 'Котиківка',
  'UA2608003010': 'Лука',
  'UA2608003012': 'Монастирок',
  'UA2608003011': 'Михальче',
  'UA2608003013': 'Незвисько',
  'UA2608003014': 'Новоселівка',
  'UA2608003015': 'Олієво-Корнів',
  'UA2608003016': 'Олієво-Королівка',
  'UA2608003017': 'Острівець',
  'UA2608003018': 'Передівання',
  'UA2608003019': 'Поточище',
  'UA2608003021': 'Пробабин',
  'UA2608003020': 'Прикмище',
  'UA2608003022': 'Раковець',
  'UA2608003023': 'Рашків',
  'UA2608003024': 'Рогиня',
  'UA2608003025': 'Росохач',
  'UA2608003026': 'Семаківці',
  'UA2608003027': 'Семенівка',
  'UA2608003028': 'Серафинці',
  'UA2608003029': 'Слобідка',
  'UA2608003030': 'Сороки',
  'UA2608003031': 'Стрільче',
  'UA2608003033': 'Топорівці',
  'UA2608003034': 'Торговиця',
  'UA2608003032': 'Тишківці',
  'UA2608003035': 'Уніж',
  'UA2608003003': 'Вербівці',
  'UA2608003005': 'Вікно',
  'UA2608003006': 'Воронів',
  'UA2608003004': 'Виноград',
  'UA2608003040': 'Якубівка',
  'UA2608003039': 'Ясенів-Пільний',
  'UA2608001002': 'Берем\'яни',
  'UA2608001008': 'Чехова',
  'UA2608001001': 'Гвіздець',
  'UA2608001007': 'Хом\'яківка',
  'UA2608001003': 'Кулачківці',
  'UA2608001004': 'Малий Гвіздець',
  'UA2608001005': 'Остапківці',
  'UA2608001006': 'Старий Гвіздець',
  'UA2608007003': 'Грушів',
  'UA2608007004': 'Іванівці',
  'UA2608007001': 'Коломия',
  'UA2608007005': 'Корнич',
  'UA2608007006': 'Королівка',
  'UA2608007007': 'Кубаївка',
  'UA2608007008': 'Раківчик',
  'UA2608007009': 'Саджавка',
  'UA2608007011': 'Шепарівці',
  'UA2608007010': 'Товмачик',
  'UA2608007002': 'Воскресинці',
  'UA2608009002': 'Богородичин',
  'UA2608009008': 'Черемхів',
  'UA2608009004': 'Казанів',
  'UA2608009001': 'Коршів',
  'UA2608009005': 'Ліски',
  'UA2608009006': 'Лісна Слобідка',
  'UA2608009007': 'Михалків',
  'UA2608009003': 'Жукотин',
  'UA2608011004': 'Дебеславці',
  'UA2608011002': 'Ганнів',
  'UA2608011003': 'Гуцулівка',
  'UA2608011007': 'Корости',
  'UA2608011010': 'Кропивище',
  'UA2608011009': 'Кринички',
  'UA2608011008': 'Кривоброди',
  'UA2608011017': 'Липники',
  'UA2608011001': 'Матеївці',
  'UA2608011011': 'Перерив',
  'UA2608011012': 'Пилипи',
  'UA2608011013': 'Семаківці',
  'UA2608011014': 'Трач',
  'UA2608011015': 'Тростянка',
  'UA2608011016': 'Цуцулин',
  'UA2608011005': 'Залуччя',
  'UA2608011006': 'Замулинці',
  'UA2608013004': 'Ковалівка',
  'UA2608013005': 'Мишин',
  'UA2608013001': 'Нижній Вербіж',
  'UA2608013006': 'Спас',
  'UA2608013002': 'Великий Ключів',
  'UA2608013003': 'Верхній Вербіж',
  'UA2608015002': 'Баб\'янка',
  'UA2608015003': 'Боднарів',
  'UA2608015006': 'Глибока',
  'UA2608015007': 'Голосків',
  'UA2608015008': 'Грабич',
  'UA2608015019': 'Хоросна',
  'UA2608015010': 'Лісний Хлібичин',
  'UA2608015011': 'Молодилів',
  'UA2608015012': 'Нижня Велесниця',
  'UA2608015001': 'Отинія',
  'UA2608015013': 'Сідлище',
  'UA2608015014': 'Скопівка',
  'UA2608015015': 'Станіславівка',
  'UA2608015016': 'Струпків',
  'UA2608015017': 'Торговиця',
  'UA2608015018': 'Угорники',
  'UA2608015005': 'Ворона',
  'UA2608015004': 'Виноград',
  'UA2608015009': 'Закрівці',
  'UA2608017002': 'Кіданч',
  'UA2608017003': 'Княждвір',
  'UA2608017004': 'Малий Ключів',
  'UA2608017005': 'Марківка',
  'UA2608017006': 'Молодятин',
  'UA2608017001': 'Печеніжин',
  'UA2608017007': 'Рунгури',
  'UA2608017008': 'Слобода',
  'UA2608017009': 'Сопів',
  'UA2608021006': 'Фатовець',
  'UA2608021003': 'Годи-Добровідка',
  'UA2608021004': 'Мала Кам\'янка',
  'UA2608021001': 'П\'ядики',
  'UA2608021009': 'Студлів',
  'UA2608021007': 'Ценява',
  'UA2608021005': 'Турка',
  'UA2608021002': 'Велика Кам\'янка',
  'UA2608021008': 'Ясінки',
  'UA2608019002': 'Джурків',
  'UA2608019004': 'Кобилець',
  'UA2608019005': 'Назірна',
  'UA2608019001': 'Підгайчики',
  'UA2608019006': 'Пищаче',
  'UA2608019003': 'Загайпіль',
  'UA2608023002': 'Белелуя',
  'UA2608023003': 'Будилів',
  'UA2608023008': 'Долішнє Залуччя',
  'UA2608023009': 'Драгасимів',
  'UA2608023007': 'Джурів',
  'UA2608023006': 'Горішнє Залуччя',
  'UA2608023027': 'Хутір-Будилів',
  'UA2608023013': 'Княже',
  'UA2608023014': 'Красноставці',
  'UA2608023015': 'Новоселиця',
  'UA2608023016': 'Орелець',
  'UA2608023017': 'Підвисоке',
  'UA2608023018': 'Попельники',
  'UA2608023019': 'Потічок',
  'UA2608023020': 'Прутівка',
  'UA2608023021': 'Русів',
  'UA2608023001': 'Снятин',
  'UA2608023022': 'Стецева',
  'UA2608023023': 'Стецівка',
  'UA2608023025': 'Тучапи',
  'UA2608023024': 'Тулова',
  'UA2608023026': 'Устя',
  'UA2608023005': 'Вовчківці',
  'UA2608023004': 'Видинів',
  'UA2608023011': 'Задубрівці',
  'UA2608023012': 'Запруття',
  'UA2608023010': 'Завалля',
  'UA2608005002': 'Балинці',
  'UA2608005003': 'Борщів',
  'UA2608005004': 'Борщівська Турка',
  'UA2608005005': 'Бучачки',
  'UA2608005007': 'Ганьківці',
  'UA2608005009': 'Іллінці',
  'UA2608005010': 'Келихів',
  'UA2608005019': 'Хлібичин',
  'UA2608005011': 'Любківці',
  'UA2608005012': 'Олешків',
  'UA2608005013': 'Рожневі Поля',
  'UA2608005014': 'Рудники',
  'UA2608005020': 'Шевченкове',
  'UA2608005017': 'Трофанівка',
  'UA2608005015': 'Троїця',
  'UA2608005016': 'Тростянець',
  'UA2608005018': 'Тулуків',
  'UA2608005006': 'Вишнівка',
  'UA2608005001': 'Заболотів',
  'UA2608005008': 'Зібранівка',
  'UA2610001002': 'Бабин',
  'UA2610001012': 'Черганівка',
  'UA2610001004': 'Город',
  'UA2610001001': 'Косів',
  'UA2610001005': 'Микитинці',
  'UA2610001006': 'Пістинь',
  'UA2610001007': 'Річка',
  'UA2610001013': 'Шепіт',
  'UA2610001014': 'Шешори',
  'UA2610001008': 'Смодна',
  'UA2610001009': 'Снідавка',
  'UA2610001010': 'Соколівка',
  'UA2610001011': 'Старий Косів',
  'UA2610001003': 'Вербовець',
  'UA2610001015': 'Яворів',
  'UA2610003002': 'Брустури',
  'UA2610003001': 'Космач',
  'UA2610003003': 'Прокурава',
  'UA2610005001': 'Кути',
  'UA2610005003': 'Малий Рожин',
  'UA2610005004': 'Розтоки',
  'UA2610005005': 'Слобідка',
  'UA2610005006': 'Старі Кути',
  'UA2610005007': 'Тюдів',
  'UA2610005002': 'Великий Рожин',
  'UA2610007004': 'Хімчин',
  'UA2610007002': 'Кобаки',
  'UA2610007001': 'Рожнів',
  'UA2610007003': 'Рибне',
  'UA2610009002': 'Акрешори',
  'UA2610009003': 'Баня-Березів',
  'UA2610009005': 'Люча',
  'UA2610009006': 'Лючки',
  'UA2610009007': 'Нижній Березів',
  'UA2610009008': 'Середній Березів',
  'UA2610009009': 'Стопчатів',
  'UA2610009010': 'Текуча',
  'UA2610009011': 'Уторопи',
  'UA2610009004': 'Вижній Березів',
  'UA2610009001': 'Яблунів',
  'UA2612003002': 'Білі Ослави',
  'UA2612003005': 'Чорні Ослави',
  'UA2612003004': 'Чорний Потік',
  'UA2612003001': 'Делятин',
  'UA2612003003': 'Заріччя',
  'UA2612005004': 'Добротів',
  'UA2612005003': 'Глинки',
  'UA2612005001': 'Ланчин',
  'UA2612005005': 'Середній Майдан',
  'UA2612005002': 'Вишнівці',
  'UA2612007003': 'Гвізд',
  'UA2612007004': 'Красна',
  'UA2612007006': 'Лісна Тарновиця',
  'UA2612007005': 'Лісна Велесниця',
  'UA2612007007': 'Лоєва',
  'UA2612007009': 'Млини',
  'UA2612007010': 'Молодків',
  'UA2612007008': 'Мирне',
  'UA2612007001': 'Надвірна',
  'UA2612007011': 'Назавизів',
  'UA2612007012': 'Парище',
  'UA2612007013': 'Стримба',
  'UA2612007002': 'Верхній Майдан',
  'UA2612009003': 'Білозорина',
  'UA2612009004': 'Букове',
  'UA2612009002': 'Битків',
  'UA2612009011': 'Черник',
  'UA2612009006': 'Максимець',
  'UA2612009007': 'Мозолівка',
  'UA2612009001': 'Пасічна',
  'UA2612009008': 'Пнів',
  'UA2612009009': 'Постоята',
  'UA2612009010': 'Соколовиця',
  'UA2612009005': 'Зелена',
  'UA2612011004': 'Фитьків',
  'UA2612011003': 'Гаврилівка',
  'UA2612011001': 'Перерісль',
  'UA2612011005': 'Цуцилів',
  'UA2612011002': 'Волосів',
  'UA2612013002': 'Бистриця',
  'UA2612013004': 'Климпуші',
  'UA2612013001': 'Поляниця',
  'UA2612013007': 'Причіл',
  'UA2612013003': 'Вороненко',
  'UA2612013005': 'Яблуниця',
  'UA2612013006': 'Згари',
  'UA2612001002': 'Татарів',
  'UA2612001001': 'Ворохта',
  'UA2612015002': 'Микуличин',
  'UA2612015001': 'Яремче',
  'UA2602001002': 'Барвінків',
  'UA2602001003': 'Біла Річка',
  'UA2602001001': 'Білоберізка',
  'UA2602001015': 'Черемошна',
  'UA2602001007': 'Довгополе',
  'UA2602001004': 'Голошина',
  'UA2602001005': 'Грамотне',
  'UA2602001006': 'Гринява',
  'UA2602001014': 'Хороцеве',
  'UA2602001008': 'Кохан',
  'UA2602001009': 'Полянки',
  'UA2602001010': 'Пробійнівка',
  'UA2602001011': 'Сеньківське',
  'UA2602001012': 'Стебні',
  'UA2602001017': 'Стовпні',
  'UA2602001013': 'Устеріки',
  'UA2602001016': 'Яблуниця',
  'UA2602003002': 'Бережниця',
  'UA2602003003': 'Буковець',
  'UA2602003019': 'Черетів',
  'UA2602003020': 'Чорна Річка',
  'UA2602003009': 'Голови',
  'UA2602003011': 'Ільці',
  'UA2602003013': 'Красноїлля',
  'UA2602003012': 'Красник',
  'UA2602003014': 'Кривопілля',
  'UA2602003015': 'Криворівня',
  'UA2602003016': 'Перехресне',
  'UA2602003017': 'Рівня',
  'UA2602003018': 'Стаїще',
  'UA2602003004': 'Великий Ходак',
  'UA2602003005': 'Верхній Ясенів',
  'UA2602003001': 'Верховина',
  'UA2602003007': 'Віпче',
  'UA2602003008': 'Волова',
  'UA2602003006': 'Вигода',
  'UA2602003010': 'Замагора',
  'UA2602005003': 'Буркут',
  'UA2602005002': 'Бистрець',
  'UA2602005004': 'Дземброня',
  'UA2602005005': 'Топільче',
  'UA2602005006': 'Явірник',
  'UA2602005001': 'Зелене',
  'UA6302001004': 'Бабаки',
  'UA6302001005': 'Бабенки',
  'UA6302001006': 'Байрак',
  'UA6302001068': 'Балабанівка',
  'UA6302001001': 'Богодухів',
  'UA6302001007': 'Братениця',
  'UA6302001016': 'Дегтярі',
  'UA6302001015': 'Дев\'ятибратове',
  'UA6302001017': 'Дмитрівка',
  'UA6302001060': 'Філатове',
  'UA6302001012': 'Гарбузи',
  'UA6302001070': 'Гавриші',
  'UA6302001013': 'Горбанівка',
  'UA6302001071': 'Горького',
  'UA6302001014': 'Губарівка',
  'UA6302001002': 'Гути',
  'UA6302001020': 'Іванівка',
  'UA6302001021': 'Івано-Шийчине',
  'UA6302001022': 'Кадниця',
  'UA6302001061': 'Хрущова Микитівка',
  'UA6302001024': 'Кленове',
  'UA6302001025': 'Корбині Івани',
  'UA6302001026': 'Коротке',
  'UA6302001029': 'Кручик',
  'UA6302001028': 'Крупчине',
  'UA6302001027': 'Крисине',
  'UA6302001030': 'Куп\'єваха',
  'UA6302001023': 'Кияни',
  'UA6302001031': 'Леськівка',
  'UA6302001032': 'Лозова',
  'UA6302001073': 'Максимівка',
  'UA6302001074': 'Мала Іванівка',
  'UA6302001033': 'Мар\'їне',
  'UA6302001034': 'Мар\'їне',
  'UA6302001035': 'Матвіївка',
  'UA6302001036': 'Мерло',
  'UA6302001038': 'Москаленки',
  'UA6302001039': 'Мусійки',
  'UA6302001037': 'Миролюбівка',
  'UA6302001040': 'Новоселівка',
  'UA6302001041': 'Новоселівка',
  'UA6302001042': 'Новософіївка',
  'UA6302001043': 'Новоукраїнка',
  'UA6302001044': 'Олександрівка',
  'UA6302001047': 'Паляничники',
  'UA6302001045': 'Павлівка',
  'UA6302001046': 'Павлове',
  'UA6302001049': 'Першотравневе',
  'UA6302001075': 'Першотравневе',
  'UA6302001048': 'Первухинка',
  'UA6302001050': 'Петропавлівка',
  'UA6302001051': 'Пісочин',
  'UA6302001052': 'Полкова Микитівка',
  'UA6302001053': 'Ріпки',
  'UA6302001054': 'Сазоно-Баланівка',
  'UA6302001055': 'Семенів Яр',
  'UA6302001003': 'Шарівка',
  'UA6302001066': 'Щербаки',
  'UA6302001064': 'Шкарлати',
  'UA6302001065': 'Шуби',
  'UA6302001062': 'Шигимагине',
  'UA6302001063': 'Шийчине',
  'UA6302001056': 'Сінне',
  'UA6302001057': 'Скосогорівка',
  'UA6302001076': 'Степне',
  'UA6302001058': 'Сухини',
  'UA6302001077': 'Таверівка',
  'UA6302001059': 'Тимофіївка',
  'UA6302001078': 'Улянівка',
  'UA6302001008': 'Вертіївка',
  'UA6302001069': 'Вікторівка',
  'UA6302001009': 'Вінницькі Івани',
  'UA6302001010': 'Воскобійники',
  'UA6302001011': 'Воскресенівка',
  'UA6302001067': 'Яблучне',
  'UA6302001018': 'Заброди',
  'UA6302001019': 'Зарябинка',
  'UA6302001072': 'Зарічне',
  'UA6302007002': 'Андрусівка',
  'UA6302007003': 'Білоусове',
  'UA6302007004': 'Бондарівка',
  'UA6302007005': 'Бровкове',
  'UA6302007012': 'Дмитрівка',
  'UA6302007007': 'Ганнівка',
  'UA6302007008': 'Гладківка',
  'UA6302007009': 'Григорівка',
  'UA6302007010': 'Гришкове',
  'UA6302007011': 'Гуртовівка',
  'UA6302007013': 'Каленикове',
  'UA6302007001': 'Коломак',
  'UA6302007015': 'Крамарівка',
  'UA6302007014': 'Кисівка',
  'UA6302007016': 'Латишівка',
  'UA6302007017': 'Логвинівка',
  'UA6302007019': 'Мірошниківка',
  'UA6302007018': 'Миколаївка',
  'UA6302007020': 'Нагальне',
  'UA6302007021': 'Новоіванівське',
  'UA6302007022': 'Панасівка',
  'UA6302007023': 'Пащенівка',
  'UA6302007024': 'Петропавлівка',
  'UA6302007025': 'Підлісне',
  'UA6302007026': 'Покровка',
  'UA6302007027': 'Прядківка',
  'UA6302007028': 'Різуненкове',
  'UA6302007032': 'Шелестове',
  'UA6302007033': 'Шляхове',
  'UA6302007029': 'Сургаївка',
  'UA6302007030': 'Трудолюбівка',
  'UA6302007031': 'Цепочкине',
  'UA6302007006': 'Вдовичине',
  'UA6302007034': 'Явтухівка',
  'UA6302009003': 'Березівка',
  'UA6302009004': 'Бідило',
  'UA6302009005': 'Благодатне',
  'UA6302009051': 'Бузова',
  'UA6302009006': 'Бузове',
  'UA6302009049': 'Чернещина',
  'UA6302009054': 'Дублянка',
  'UA6302009008': 'Гаркавець',
  'UA6302009009': 'Городнє',
  'UA6302009010': 'Гринів Яр',
  'UA6302009015': 'Качалівка',
  'UA6302009055': 'Кам\'яно-Хутірське',
  'UA6302009012': 'Каплунівка',
  'UA6302009013': 'Капранське',
  'UA6302009014': 'Карайкозівка',
  'UA6302009047': 'Ходунаївка',
  'UA6302009048': 'Хутірське',
  'UA6302009017': 'Княжа Долина',
  'UA6302009021': 'Коломацький Шлях',
  'UA6302009022': 'Колонтаїв',
  'UA6302009023': 'Комарівка',
  'UA6302009002': 'Костянтинівка',
  'UA6302009024': 'Котелевка',
  'UA6302009019': 'Ковальчуківка',
  'UA6302009018': 'Ковалівка',
  'UA6302009056': 'Ковалівське',
  'UA6302009020': 'Козіївка',
  'UA6302009001': 'Краснокутськ',
  'UA6302009025': 'Кусторівка',
  'UA6302009016': 'Китченківка',
  'UA6302009057': 'Лісне',
  'UA6302009026': 'Любівка',
  'UA6302009058': 'Лучки',
  'UA6302009029': 'Мойка',
  'UA6302009030': 'Мурафа',
  'UA6302009028': 'Михайлівка',
  'UA6302009059': 'Михайлівське',
  'UA6302009027': 'Мирне',
  'UA6302009031': 'Настеньківка',
  'UA6302009032': 'Одрада',
  'UA6302009033': 'Олексіївка',
  'UA6302009060': 'Оленівське',
  'UA6302009034': 'Олійники',
  'UA6302009035': 'Основинці',
  'UA6302009037': 'Пархомівка',
  'UA6302009036': 'Павлюківка',
  'UA6302009061': 'Павлівка',
  'UA6302009038': 'Петрівське',
  'UA6302009063': 'Прогрес',
  'UA6302009039': 'Прокопенкове',
  'UA6302009062': 'Пильнянка',
  'UA6302009040': 'Рандава',
  'UA6302009041': 'Рябоконеве',
  'UA6302009042': 'Сергіївка',
  'UA6302009050': 'Шевченкове',
  'UA6302009044': 'Слобідка',
  'UA6302009045': 'Сонцедарівка',
  'UA6302009064': 'Сорокове',
  'UA6302009046': 'Степанівка',
  'UA6302009065': 'Степове',
  'UA6302009066': 'Степове',
  'UA6302009043': 'Ситники',
  'UA6302009007': 'В\'язова',
  'UA6302009052': 'Водяне',
  'UA6302009053': 'Володимирівка',
  'UA6302009011': 'Зубівка',
  'UA6302003004': 'Баранове',
  'UA6302003005': 'Благодатне',
  'UA6302003006': 'Бугаївка',
  'UA6302003008': 'Бурякове',
  'UA6302003007': 'Бурівка',
  'UA6302003009': 'Буцьківка',
  'UA6302003091': 'Черемушна',
  'UA6302003023': 'Данильчин Кут',
  'UA6302003024': 'Добропілля',
  'UA6302003026': 'Дорофіївка',
  'UA6302003025': 'Довжик',
  'UA6302003101': 'Газове',
  'UA6302003019': 'Гонтів Яр',
  'UA6302003020': 'Гребінники',
  'UA6302003021': 'Гринців Ріг',
  'UA6302003022': 'Гузівка',
  'UA6302003018': 'Гвоздьове',
  'UA6302003033': 'Кантакузівка',
  'UA6302003034': 'Катричівка',
  'UA6302003089': 'Халимонівка',
  'UA6302003090': 'Хворостове',
  'UA6302003035': 'Кобзарівка',
  'UA6302003038': 'Колодківка',
  'UA6302003039': 'Корнієнкове',
  'UA6302003040': 'Корсунівка',
  'UA6302003041': 'Корсунове',
  'UA6302003042': 'Косенкове',
  'UA6302003043': 'Костів',
  'UA6302003036': 'Коверівка',
  'UA6302003002': 'Ков\'яги',
  'UA6302003037': 'Козаченківка',
  'UA6302003044': 'Круглик',
  'UA6302003045': 'Крута Балка',
  'UA6302003046': 'Кузьмівка',
  'UA6302003047': 'Ландишове',
  'UA6302003048': 'Лисконоги',
  'UA6302003049': 'Литвинівка',
  'UA6302003050': 'Майдан',
  'UA6302003051': 'Мала Кадигробівка',
  'UA6302003052': 'Манили',
  'UA6302003053': 'Мельникове',
  'UA6302003058': 'Мічурінське',
  'UA6302003057': 'Мірошники',
  'UA6302003056': 'Мізяки',
  'UA6302003059': 'Мозолівка',
  'UA6302003055': 'Михайлівка',
  'UA6302003054': 'Минківка',
  'UA6302003060': 'Нестеренки',
  'UA6302003062': 'Новоселівка',
  'UA6302003061': 'Новий Мерчик',
  'UA6302003065': 'Очеретове',
  'UA6302003063': 'Огульці',
  'UA6302003064': 'Олександрівка',
  'UA6302003066': 'Пасічне',
  'UA6302003067': 'Перекіп',
  'UA6302003068': 'Перепелицівка',
  'UA6302003069': 'Петренкове',
  'UA6302003070': 'Піски',
  'UA6302003102': 'Привокзальне',
  'UA6302003071': 'Рідкодуб',
  'UA6302003072': 'Різдвяне',
  'UA6302003073': 'Рогівка',
  'UA6302003074': 'Розсохівка',
  'UA6302003075': 'Рудий Байрак',
  'UA6302003077': 'Семківка',
  'UA6302003078': 'Серпневе',
  'UA6302003092': 'Шарівка',
  'UA6302003097': 'Щербинівка',
  'UA6302003094': 'Шелудькове',
  'UA6302003093': 'Шевченкове',
  'UA6302003096': 'Шлях',
  'UA6302003095': 'Шийки',
  'UA6302003080': 'Скельки',
  'UA6302003081': 'Сніжків',
  'UA6302003082': 'Соснівка',
  'UA6302003083': 'Старі Валки',
  'UA6302003003': 'Старий Мерчик',
  'UA6302003084': 'Суха Балка',
  'UA6302003076': 'Свинарі',
  'UA6302003079': 'Сидоренкове',
  'UA6302003085': 'Тетющине',
  'UA6302003086': 'Трохимівка',
  'UA6302003087': 'Тугаївка',
  'UA6302003088': 'Тупицівка',
  'UA6302003001': 'Валки',
  'UA6302003010': 'Велика Губщина',
  'UA6302003011': 'Велика Кадигробівка',
  'UA6302003014': 'Війтенки',
  'UA6302003015': 'Вільхівське',
  'UA6302003017': 'Водяна Балка',
  'UA6302003016': 'Водопій',
  'UA6302003013': 'Вишневе',
  'UA6302003012': 'Високопілля',
  'UA6302003098': 'Яблунівка',
  'UA6302003100': 'Яхременки',
  'UA6302003099': 'Ясеневе',
  'UA6302003029': 'Зайцівка',
  'UA6302003030': 'Заміське',
  'UA6302003028': 'Завгороднє',
  'UA6302003027': 'Журавлі',
  'UA6302003031': 'Золочівське',
  'UA6302003032': 'Золочівське',
  'UA6302005002': 'Андріївка',
  'UA6302005003': 'Баранівка',
  'UA6302005004': 'Басове',
  'UA6302005005': 'Березівка',
  'UA6302005006': 'Борохи',
  'UA6302005007': 'Бугаї Другі',
  'UA6302005060': 'Чепелі',
  'UA6302005061': 'Червона Зоря',
  'UA6302005062': 'Чорноглазівка',
  'UA6302005017': 'Довжик',
  'UA6302005018': 'Дуванка',
  'UA6302005057': 'Феськи',
  'UA6302005012': 'Головашівка',
  'UA6302005013': 'Гресі',
  'UA6302005016': 'Гур\'їв Козачок',
  'UA6302005015': 'Гур\'їв',
  'UA6302005014': 'Гуринівка',
  'UA6302005022': 'Івашки',
  'UA6302005065': 'Калинове',
  'UA6302005023': 'Карасівка',
  'UA6302005025': 'Костянтинівка',
  'UA6302005024': 'Ковалі',
  'UA6302005026': 'Леміщине',
  'UA6302005028': 'Лютівка',
  'UA6302005027': 'Литвинове',
  'UA6302005034': 'Маяк',
  'UA6302005030': 'Макарове',
  'UA6302005029': 'Макариха',
  'UA6302005031': 'Мала Рогозянка',
  'UA6302005066': 'Малі Феськи',
  'UA6302005032': 'Малижине',
  'UA6302005033': 'Мартинівка',
  'UA6302005035': 'Мерло',
  'UA6302005037': 'Морозова Долина',
  'UA6302005067': 'Муравське',
  'UA6302005036': 'Миронівка',
  'UA6302005038': 'Одноробівка',
  'UA6302005068': 'Одноробівка',
  'UA6302005039': 'Окіп',
  'UA6302005040': 'Олександрівка',
  'UA6302005041': 'Орішанка',
  'UA6302005069': 'Перемога',
  'UA6302005070': 'Перовське',
  'UA6302005071': 'Першотравневе',
  'UA6302005042': 'Петрівка',
  'UA6302005044': 'Постольне',
  'UA6302005043': 'Писарівка',
  'UA6302005047': 'Рясне',
  'UA6302005045': 'Рідний Край',
  'UA6302005046': 'Розсохувате',
  'UA6302005063': 'Широкий Яр',
  'UA6302005050': 'Скорики',
  'UA6302005049': 'Сковородинівка',
  'UA6302005051': 'Сніги',
  'UA6302005072': 'Сніги',
  'UA6302005052': 'Соснівка',
  'UA6302005053': 'Сотницький Козачок',
  'UA6302005054': 'Стогнії',
  'UA6302005048': 'Світличне',
  'UA6302005058': 'Цапівка',
  'UA6302005059': 'Цилюрики',
  'UA6302005055': 'Турове',
  'UA6302005073': 'Тимофіївка',
  'UA6302005056': 'Уди',
  'UA6302005008': 'Велика Рогозянка',
  'UA6302005064': 'Відродженівське',
  'UA6302005010': 'Вікнине',
  'UA6302005011': 'Вільшанське',
  'UA6302005009': 'Високе',
  'UA6302005019': 'Завадське',
  'UA6302005020': 'Зіньківське',
  'UA6302005001': 'Золочів',
  'UA6302005021': 'Зрубанка',
  'UA6314015002': 'Базаліївка',
  'UA6314015001': 'Чкаловське',
  'UA6314015019': 'Дослідне',
  'UA6314015003': 'Гаврилівка',
  'UA6314015004': 'Гракове',
  'UA6314015005': 'Іванівка',
  'UA6314015006': 'Коробочкине',
  'UA6314015007': 'Леб\'яже',
  'UA6314015010': 'Михайлівка',
  'UA6314015008': 'Миколаївка',
  'UA6314015009': 'Миколаївка',
  'UA6314015011': 'Нова Гнилиця',
  'UA6314015012': 'Осиковий Гай',
  'UA6314015013': 'Пушкарне',
  'UA6314015014': 'Ртищівка',
  'UA6314015015': 'Степове',
  'UA6314015016': 'Студенок',
  'UA6314015017': 'Таганка',
  'UA6314015018': 'Юрченкове',
  'UA6314015020': 'Залізничне',
  'UA6314017001': 'Чугуїв',
  'UA6314017006': 'Кам\'яна Яруга',
  'UA6314017007': 'Клугино-Башкирівка',
  'UA6314017002': 'Кочеток',
  'UA6314017008': 'Піщане',
  'UA6314017009': 'Тетлега',
  'UA6314017003': 'Василів Хутір',
  'UA6314017004': 'Велика Бабка',
  'UA6314017005': 'Зарожне',
  'UA6314005001': 'Малинівка',
  'UA6314005002': 'Мосьпанове',
  'UA6314005003': 'Стара Гнилиця',
  'UA6314007003': 'Есхар',
  'UA6314007001': 'Новопокровка',
  'UA6314007006': 'Поди',
  'UA6314007010': 'Роздольне',
  'UA6314007008': 'Стара Покровка',
  'UA6314007007': 'Світанок',
  'UA6314007009': 'Тернова',
  'UA6314007002': 'Введенка',
  'UA6314007004': 'Зауддя',
  'UA6314007005': 'Зелений Колодязь',
  'UA6314009002': 'Артемівка',
  'UA6314009003': 'Борщова',
  'UA6314009004': 'Ганнівка',
  'UA6314009005': 'Гарашківка',
  'UA6314009006': 'Гуслівка',
  'UA6314009007': 'Кицівка',
  'UA6314009008': 'Мартове',
  'UA6314009009': 'Новий Бурлук',
  'UA6314009001': 'Печеніги',
  'UA6314009010': 'Першотравневе',
  'UA6314009012': 'П\'ятницьке',
  'UA6314009011': 'Приморське',
  'UA6314011015': 'Благодатне',
  'UA6314011013': 'Черкаський Бишкин',
  'UA6314011004': 'Дачне',
  'UA6314011016': 'Донець',
  'UA6314011002': 'Геївка',
  'UA6314011003': 'Геніївка',
  'UA6314011006': 'Курортне',
  'UA6314011017': 'Лісне',
  'UA6314011007': 'Лиман',
  'UA6314011008': 'Мохнач',
  'UA6314011009': 'Нижній Бишкин',
  'UA6314011014': 'Шелудьківка',
  'UA6314011010': 'Скрипаї',
  'UA6314011001': 'Слобожанське',
  'UA6314011011': 'Суха Гомільша',
  'UA6314011012': 'Українське',
  'UA6314011005': 'Занки',
  'UA6314013002': 'Березники',
  'UA6314013005': 'Дідівка',
  'UA6314013017': 'Федорівка',
  'UA6314013004': 'Гонтарівка',
  'UA6314013018': 'Хотімля',
  'UA6314013007': 'Кирилівка',
  'UA6314013008': 'Металівка',
  'UA6314013009': 'Молодова',
  'UA6314013010': 'Москалівка',
  'UA6314013011': 'Паськівка',
  'UA6314013012': 'Перківка',
  'UA6314013013': 'Погоріле',
  'UA6314013014': 'Радькове',
  'UA6314013015': 'Середівка',
  'UA6314013019': 'Шестакове',
  'UA6314013020': 'Широке',
  'UA6314013001': 'Старий Салтів',
  'UA6314013016': 'Томахівка',
  'UA6314013003': 'Вишневе',
  'UA6314013006': 'Зарічне',
  'UA6314001004': 'Байрак',
  'UA6314001005': 'Бакшеївка',
  'UA6314001006': 'Бережне',
  'UA6314001002': 'Білий Колодязь',
  'UA6314001007': 'Благодатне',
  'UA6314001008': 'Бочкове',
  'UA6314001011': 'Бударки',
  'UA6314001009': 'Бугаївка',
  'UA6314001010': 'Бугруватка',
  'UA6314001012': 'Бузове',
  'UA6314001069': 'Чайківка',
  'UA6314001070': 'Черняків',
  'UA6314001026': 'Дегтярне',
  'UA6314001022': 'Ганнопілля',
  'UA6314001023': 'Гарбузівка',
  'UA6314001024': 'Гатище',
  'UA6314001025': 'Графське',
  'UA6314001032': 'Іванівка',
  'UA6314001033': 'Ізбицьке',
  'UA6314001034': 'Караїчне',
  'UA6314001067': 'Хрипуни',
  'UA6314001066': 'Хижнякове',
  'UA6314001035': 'Котівка',
  'UA6314001036': 'Красний Яр',
  'UA6314001037': 'Кругле',
  'UA6314001041': 'Лошакове',
  'UA6314001040': 'Лосівка',
  'UA6314001039': 'Лозова',
  'UA6314001042': 'Лукашове',
  'UA6314001038': 'Лиман',
  'UA6314001043': 'Мала Вовча',
  'UA6314001044': 'Миколаївка',
  'UA6314001045': 'Нестерне',
  'UA6314001046': 'Новоолександрівка',
  'UA6314001047': 'Огірцеве',
  'UA6314001048': 'Охрімівка',
  'UA6314001049': 'Петропавлівка',
  'UA6314001050': 'Пільна',
  'UA6314001051': 'Піщане',
  'UA6314001052': 'Плетенівка',
  'UA6314001053': 'Покаляне',
  'UA6314001054': 'Приліпка',
  'UA6314001056': 'Різникове',
  'UA6314001057': 'Рубіжне',
  'UA6314001055': 'Рибалкине',
  'UA6314001058': 'Сердобине',
  'UA6314001071': 'Шабельне',
  'UA6314001074': 'Шестерівка',
  'UA6314001073': 'Шевченкове Перше',
  'UA6314001072': 'Шевченкове',
  'UA6314001061': 'Сосновий Бір',
  'UA6314001062': 'Стариця',
  'UA6314001059': 'Симинівка',
  'UA6314001060': 'Синельникове',
  'UA6314001068': 'Цегельне',
  'UA6314001063': 'Тихе',
  'UA6314001064': 'Українка',
  'UA6314001065': 'Українське',
  'UA6314001013': 'Варварівка',
  'UA6314001014': 'Варварівка',
  'UA6314001015': 'Василівка',
  'UA6314001017': 'Верхня Писарівка',
  'UA6314001016': 'Верхній Салтів',
  'UA6314001003': 'Вільча',
  'UA6314001018': 'Вірівка',
  'UA6314001020': 'Волохівка',
  'UA6314001021': 'Волохівське',
  'UA6314001001': 'Вовчанськ',
  'UA6314001019': 'Вовчанські Хутори',
  'UA6314001075': 'Юрченкове',
  'UA6314001028': 'Захарівка',
  'UA6314001027': 'Замулівка',
  'UA6314001030': 'Землянки',
  'UA6314001029': 'Земляний Яр',
  'UA6314001031': 'Зибине',
  'UA6314003003': 'Аксютівка',
  'UA6314003004': 'Артюхівка',
  'UA6314003005': 'Безпалівка',
  'UA6314003056': 'Безпалівка',
  'UA6314003006': 'Бірки',
  'UA6314003007': 'Бірочок Другий',
  'UA6314003008': 'Борова',
  'UA6314003057': 'Бутівка',
  'UA6314003055': 'Чемужівка',
  'UA6314003060': 'Черемушне',
  'UA6314003021': 'Дудківка',
  'UA6314003020': 'Джгун',
  'UA6314003054': 'Федорівка',
  'UA6314003015': 'Гайдари',
  'UA6314003016': 'Глибока Долина',
  'UA6314003017': 'Гришківка',
  'UA6314003019': 'Гусина Поляна',
  'UA6314003018': 'Гужвинське',
  'UA6314003027': 'Іськів Яр',
  'UA6314003028': 'Камплиця',
  'UA6314003031': 'Клименівка',
  'UA6314003033': 'Колісники',
  'UA6314003034': 'Коропове',
  'UA6314003035': 'Костянтівка',
  'UA6314003032': 'Козачка',
  'UA6314003037': 'Красна Поляна',
  'UA6314003036': 'Кравцове',
  'UA6314003038': 'Кукулівка',
  'UA6314003029': 'Кирюхи',
  'UA6314003030': 'Кисле',
  'UA6314003059': 'Лазуківка',
  'UA6314003039': 'Левківка',
  'UA6314003040': 'Миргороди',
  'UA6314003041': 'Омельченки',
  'UA6314003042': 'Островерхівка',
  'UA6314003043': 'Пасіки',
  'UA6314003044': 'Першотравневе',
  'UA6314003045': 'Петрищеве',
  'UA6314003046': 'Погоріле',
  'UA6314003047': 'Реп\'яхівка',
  'UA6314003048': 'Роздольне',
  'UA6314003050': 'Соколове',
  'UA6314003049': 'Сидори',
  'UA6314003051': 'Таранівка',
  'UA6314003053': 'Тросне',
  'UA6314003052': 'Тимченки',
  'UA6314003009': 'Велика Гомільша',
  'UA6314003010': 'Веселе',
  'UA6314003014': 'Водяхівка',
  'UA6314003013': 'Водяне',
  'UA6314003011': 'Вилівка',
  'UA6314003058': 'Вирішальний',
  'UA6314003012': 'Височинівка',
  'UA6314003023': 'Задонецьке',
  'UA6314003024': 'Залізничні Бірки',
  'UA6314003025': 'Западня',
  'UA6314003022': 'Жаданівка',
  'UA6314003002': 'Зідьки',
  'UA6314003001': 'Зміїв',
  'UA6314003026': 'Звідки',
  'UA6304001002': 'Асіївка',
  'UA6304001003': 'Байрак',
  'UA6304001001': 'Балаклія',
  'UA6304001004': 'Берестянки',
  'UA6304001005': 'Борщівка',
  'UA6304001006': 'Бригадирівка',
  'UA6304001032': 'Чепіль',
  'UA6304001033': 'Червоний Шлях',
  'UA6304001014': 'Гусарівка',
  'UA6304001016': 'Калинівка',
  'UA6304001017': 'Крейдянка',
  'UA6304001018': 'Криничне',
  'UA6304001019': 'Лозовенька',
  'UA6304001020': 'Мілова',
  'UA6304001021': 'Нова Гусарівка',
  'UA6304001022': 'Нова Серпухівка',
  'UA6304001023': 'Новомиколаївка',
  'UA6304001024': 'Первомайське',
  'UA6304001025': 'Петрівське',
  'UA6304001026': 'Попівка',
  'UA6304001027': 'Протопопівка',
  'UA6304001035': 'Щурівка',
  'UA6304001034': 'Шевелівка',
  'UA6304001037': 'Слобожанське',
  'UA6304001028': 'Соколівка',
  'UA6304001029': 'Степок',
  'UA6304001030': 'Таранушине',
  'UA6304001031': 'Успенське',
  'UA6304001007': 'Вербівка',
  'UA6304001008': 'Вільне',
  'UA6304001009': 'Вітрівка',
  'UA6304001012': 'Волобуївка',
  'UA6304001013': 'Волохів Яр',
  'UA6304001011': 'Волвенкове',
  'UA6304001010': 'Вовчий Яр',
  'UA6304001036': 'Яковенкове',
  'UA6304001015': 'Завгороднє',
  'UA6304003003': 'Африканівка',
  'UA6304003002': 'Архангелівка',
  'UA6304003004': 'Барабашівка',
  'UA6304003001': 'Барвінкове',
  'UA6304003005': 'Благодатне',
  'UA6304003006': 'Богданове',
  'UA6304003007': 'Богодарове',
  'UA6304003054': 'Червона Балка',
  'UA6304003056': 'Червона Поляна',
  'UA6304003055': 'Червона Зоря',
  'UA6304003057': 'Червоне',
  'UA6304003058': 'Червоний Лиман',
  'UA6304003018': 'Данилівка',
  'UA6304003019': 'Дібрівне',
  'UA6304003020': 'Дмитрівка',
  'UA6304003052': 'Федорівка',
  'UA6304003053': 'Федорівка',
  'UA6304003014': 'Гаврилівка',
  'UA6304003016': 'Грушуваха',
  'UA6304003015': 'Григорівка',
  'UA6304003017': 'Гусарівка',
  'UA6304003021': 'Іванівка',
  'UA6304003022': 'Іванівка',
  'UA6304003059': 'Іванівка',
  'UA6304003024': 'Котівка',
  'UA6304003023': 'Ковалівка',
  'UA6304003025': 'Курулька',
  'UA6304003029': 'Маяк',
  'UA6304003027': 'Малолітки',
  'UA6304003026': 'Малинівка',
  'UA6304003028': 'Мар\'ївка',
  'UA6304003030': 'Мечебилове',
  'UA6304003031': 'Миколаївка',
  'UA6304003032': 'Надеждівка',
  'UA6304003033': 'Нікополь',
  'UA6304003034': 'Нова Дмитрівка',
  'UA6304003035': 'Нова Миколаївка',
  'UA6304003036': 'Новобогданове',
  'UA6304003037': 'Новопавлівка',
  'UA6304003038': 'Олександрівка',
  'UA6304003039': 'Олександрівка',
  'UA6304003040': 'Пашкове',
  'UA6304003041': 'Петрівка',
  'UA6304003043': 'Подолівка',
  'UA6304003042': 'Погонівка',
  'UA6304003044': 'Пригоже',
  'UA6304003045': 'Рідне',
  'UA6304003046': 'Семиланне',
  'UA6304003048': 'Стара Семенівка',
  'UA6304003047': 'Ставкова Балка',
  'UA6304003050': 'Степок',
  'UA6304003049': 'Степове',
  'UA6304003051': 'Українка',
  'UA6304003008': 'Василівка Друга',
  'UA6304003009': 'Василівка Перша',
  'UA6304003010': 'Велика Андріївка',
  'UA6304003011': 'Велика Комишуваха',
  'UA6304003012': 'Веселе',
  'UA6304003013': 'Високе',
  'UA6304003060': 'Язикове',
  'UA6304005002': 'Андріївка',
  'UA6304005003': 'Бахтин',
  'UA6304005004': 'Богуславка',
  'UA6304005005': 'Бойні',
  'UA6304005006': 'Борівська Андріївка',
  'UA6304005001': 'Борова',
  'UA6304005035': 'Чернещина',
  'UA6304005034': 'Червоний Став',
  'UA6304005013': 'Дружелюбівка',
  'UA6304005009': 'Гаврилівка',
  'UA6304005010': 'Глущенкове',
  'UA6304005011': 'Голубівка',
  'UA6304005012': 'Гороховатка',
  'UA6304005016': 'Ізюмське',
  'UA6304005017': 'Калинове',
  'UA6304005018': 'Копанки',
  'UA6304005019': 'Лозова',
  'UA6304005020': 'Маліївка',
  'UA6304005021': 'Мирне',
  'UA6304005024': 'Нова Кругляківка',
  'UA6304005026': 'Новоплатонівка',
  'UA6304005027': 'Новосергіївка',
  'UA6304005025': 'Новий Мир',
  'UA6304005023': 'Нижче Солоне',
  'UA6304005022': 'Нижня Журавка',
  'UA6304005028': 'Ольгівка',
  'UA6304005029': 'Парнувате',
  'UA6304005038': 'Першотравневе',
  'UA6304005031': 'Підлиман',
  'UA6304005030': 'Підвисоке',
  'UA6304005032': 'Піски-Радьківські',
  'UA6304005036': 'Шийківка',
  'UA6304005033': 'Степове',
  'UA6304005008': 'Вище Солоне',
  'UA6304005007': 'Вишневе',
  'UA6304005037': 'Ясинувате',
  'UA6304005014': 'Загризове',
  'UA6304005015': 'Зелений Гай',
  'UA6304007002': 'Андріївка',
  'UA6304007010': 'Червона Гірка',
  'UA6304007004': 'Дальня Шебелинка',
  'UA6304007001': 'Донець',
  'UA6304007003': 'Глазунівка',
  'UA6304007005': 'Копанка',
  'UA6304007006': 'Новотроїцьке',
  'UA6304007014': 'П\'ятигірське',
  'UA6304007013': 'Покровське',
  'UA6304007008': 'Прогрес',
  'UA6304007007': 'Пришиб',
  'UA6304007009': 'Серафимівка',
  'UA6304007011': 'Шебелинка',
  'UA6304007012': 'Явірське',
  'UA6304009002': 'Бабенкове',
  'UA6304009003': 'Бригадирівка',
  'UA6304009016': 'Федорівка',
  'UA6304009004': 'Глинське',
  'UA6304009007': 'Іскра',
  'UA6304009006': 'Іванівка',
  'UA6304009001': 'Ізюм',
  'UA6304009008': 'Кам\'янка',
  'UA6304009009': 'Крамарівка',
  'UA6304009010': 'Левківка',
  'UA6304009011': 'Пимонівка',
  'UA6304009012': 'Рудневе',
  'UA6304009014': 'Суха Кам\'янка',
  'UA6304009013': 'Синичено',
  'UA6304009015': 'Тихоцьке',
  'UA6304009005': 'Забавне',
  'UA6304011002': 'Боголюбівка',
  'UA6304011003': 'Бугаївка',
  'UA6304011017': 'Чорнобаївка',
  'UA6304011016': 'Чистоводівка',
  'UA6304011004': 'Іванчуківка',
  'UA6304011005': 'Козютівка',
  'UA6304011001': 'Куньє',
  'UA6304011006': 'Липчанівка',
  'UA6304011007': 'Лисогірка',
  'UA6304011008': 'Новопавлівка',
  'UA6304011009': 'Олександрівка',
  'UA6304011010': 'Підвисоке',
  'UA6304011011': 'Поляна',
  'UA6304011012': 'Попасне',
  'UA6304011013': 'Рідний Край',
  'UA6304011014': 'Розсохувате',
  'UA6304011018': 'Щасливе',
  'UA6304011015': 'Сухий Яр',
  'UA6304013002': 'Андріївка',
  'UA6304013003': 'Бражківка',
  'UA6304013004': 'Букине',
  'UA6304013006': 'Діброва',
  'UA6304013007': 'Дмитрівка',
  'UA6304013009': 'Донецьке',
  'UA6304013008': 'Довгеньке',
  'UA6304013011': 'Капитолівка',
  'UA6304013012': 'Карнаухівка',
  'UA6304013013': 'Комарівка',
  'UA6304013014': 'Копанки',
  'UA6304013015': 'Мала Комишуваха',
  'UA6304013016': 'Миколаївка',
  'UA6304013001': 'Оскіл',
  'UA6304013017': 'Пасіка',
  'UA6304013018': 'Петропілля',
  'UA6304013019': 'Придонецьке',
  'UA6304013020': 'Семенівка',
  'UA6304013026': 'Шпаківка',
  'UA6304013021': 'Сніжківка',
  'UA6304013022': 'Співаківка',
  'UA6304013023': 'Студенок',
  'UA6304013024': 'Сулигівка',
  'UA6304013025': 'Топольське',
  'UA6304013005': 'Вірнопілля',
  'UA6304013027': 'Яремівка',
  'UA6304013010': 'Заводи',
  'UA6304015002': 'Бородоярське',
  'UA6304015005': 'Довгалівка',
  'UA6304015015': 'Крючки',
  'UA6304015008': 'Морозівка',
  'UA6304015007': 'Мирна Долина',
  'UA6304015009': 'Норцівка',
  'UA6304015016': 'Новоселівка',
  'UA6304015017': 'Нурове',
  'UA6304015010': 'Пазіївка',
  'UA6304015011': 'Першотравневе',
  'UA6304015001': 'Савинці',
  'UA6304015012': 'Слабунівка',
  'UA6304015018': 'Теплянка',
  'UA6304015013': 'Українка',
  'UA6304015014': 'Веселе',
  'UA6304015004': 'Вільхуватка',
  'UA6304015003': 'Вишнева',
  'UA6304015006': 'Залиман',
  'UA6312001001': 'Безлюдівка',
  'UA6312001009': 'Хмарівка',
  'UA6312001003': 'Хорошеве',
  'UA6312001005': 'Котляри',
  'UA6312001004': 'Кирсанове',
  'UA6312001006': 'Лизогубівка',
  'UA6312001007': 'Мовчани',
  'UA6312001011': 'Подольох',
  'UA6312001010': 'Шубіне',
  'UA6312001008': 'Темнівка',
  'UA6312001002': 'Васищеве',
  'UA6312007005': 'Алісівка',
  'UA6312007006': 'Безруки',
  'UA6312007007': 'Білаші',
  'UA6312007008': 'Болибоки',
  'UA6312007009': 'Бугаївка',
  'UA6312007014': 'Дементіївка',
  'UA6312007001': 'Дергачі',
  'UA6312007015': 'Дубівка',
  'UA6312007012': 'Гоптівка',
  'UA6312007013': 'Гранів',
  'UA6312007018': 'Кочубеївка',
  'UA6312007002': 'Козача Лопань',
  'UA6312007019': 'Кудіївка',
  'UA6312007020': 'Лещенки',
  'UA6312007021': 'Лобанівка',
  'UA6312007022': 'Малі Проходи',
  'UA6312007023': 'Маслії',
  'UA6312007024': 'Мищенки',
  'UA6312007025': 'Нова Козача',
  'UA6312007037': 'Нове',
  'UA6312007003': 'Прудянка',
  'UA6312007038': 'Питомник',
  'UA6312007026': 'Руська Лозова',
  'UA6312007027': 'Семенівка',
  'UA6312007032': 'Шаповалівка',
  'UA6312007033': 'Шевченка',
  'UA6312007035': 'Шопине',
  'UA6312007034': 'Шовкопляси',
  'UA6312007004': 'Слатине',
  'UA6312007028': 'Солоний Яр',
  'UA6312007030': 'Токарівка Друга',
  'UA6312007029': 'Токарівка',
  'UA6312007031': 'Цупівка',
  'UA6312007010': 'Великі Проходи',
  'UA6312007036': 'Ветеринарне',
  'UA6312007011': 'Висока Яруга',
  'UA6312007016': 'Ємці',
  'UA6312007017': 'Замірці',
  'UA6312027001': 'Харків',
  'UA6312011008': 'Байрак',
  'UA6312011009': 'Барчани',
  'UA6312011003': 'Горіхове',
  'UA6312011004': 'Гурине',
  'UA6312011010': 'Караван',
  'UA6312011011': 'Коваленки',
  'UA6312011001': 'Люботин',
  'UA6312011002': 'Манченки',
  'UA6312011005': 'Мищенки',
  'UA6312011006': 'Нестеренки',
  'UA6312011012': 'Санжари',
  'UA6312011007': 'Смородське',
  'UA6312011013': 'Спартаси',
  'UA6312011014': 'Травневе',
  'UA6312011015': 'Ударне',
  'UA6312009003': 'Борщова',
  'UA6312009002': 'Борисівка',
  'UA6312009016': 'Глибоке',
  'UA6312009006': 'Красне',
  'UA6312009007': 'Лук\'янці',
  'UA6312009001': 'Липці',
  'UA6312009017': 'Мале Веселе',
  'UA6312009008': 'Мороховець',
  'UA6312009009': 'Нескучне',
  'UA6312009010': 'Олійникове',
  'UA6312009011': 'Перемога',
  'UA6312009012': 'Пильна',
  'UA6312009013': 'Слобожанське',
  'UA6312009014': 'Стрілеча',
  'UA6312009015': 'Тернова',
  'UA6312009004': 'Веселе',
  'UA6312009005': 'Зелене',
  'UA6312013005': 'Чайківка',
  'UA6312013006': 'Черкаська Лозова',
  'UA6312013003': 'Караван',
  'UA6312013007': 'Лісне',
  'UA6312013004': 'Лужок',
  'UA6312013001': 'Мала Данилівка',
  'UA6312013002': 'Зайченки',
  'UA6312015004': 'Кринички',
  'UA6312015005': 'Лелюки',
  'UA6312015001': 'Мерефа',
  'UA6312015006': 'Нижня Озеряна',
  'UA6312015007': 'Олександрівка',
  'UA6312015009': 'Селекційне',
  'UA6312015002': 'Утківка',
  'UA6312015003': 'Верхня Озеряна',
  'UA6312015008': 'Яковлівка',
  'UA6312017003': 'Бахметівка',
  'UA6312017002': 'Бірки',
  'UA6312017004': 'Бражники',
  'UA6312017005': 'Брідок',
  'UA6312017011': 'Дерегівка',
  'UA6312017035': 'Федорівка',
  'UA6312017010': 'Гуляй Поле',
  'UA6312017009': 'Головнівка',
  'UA6312017013': 'Ключеводське',
  'UA6312017014': 'Княжне',
  'UA6312017015': 'Круглянка',
  'UA6312017016': 'Кут',
  'UA6312017018': 'Лихове',
  'UA6312017017': 'Липкуватівка',
  'UA6312017019': 'Мануйлове',
  'UA6312017020': 'Мокра Рокитна',
  'UA6312017021': 'Моськівка',
  'UA6312017023': 'Нова Мерефа',
  'UA6312017001': 'Нова Водолага',
  'UA6312017024': 'Новопросянське',
  'UA6312017025': 'Новоселівка',
  'UA6312017022': 'Низівка',
  'UA6312017026': 'Одринка',
  'UA6312017027': 'Ордівка',
  'UA6312017028': 'Павлівка',
  'UA6312017029': 'Пластунівка',
  'UA6312017030': 'Просяне',
  'UA6312017031': 'Рокитне',
  'UA6312017036': 'Щебетуни',
  'UA6312017032': 'Сосонівка',
  'UA6312017033': 'Стара Водолага',
  'UA6312017034': 'Стулепівка',
  'UA6312017006': 'Василівське',
  'UA6312017007': 'Ватутіне',
  'UA6312017008': 'Вільхуватка',
  'UA6312017012': 'Знам\'янка',
  'UA6312021002': 'Березівка',
  'UA6312021003': 'Коротич',
  'UA6312021004': 'Надточії',
  'UA6312021007': 'Новий Коротич',
  'UA6312021005': 'Олешки',
  'UA6312021001': 'Пісочин',
  'UA6312021008': 'Рай-Оленівка',
  'UA6312021006': 'Стара Московка',
  'UA6312019004': 'Бідряги',
  'UA6312019002': 'Буди',
  'UA6312019003': 'Бистре',
  'UA6312019005': 'Першотравневе',
  'UA6312019001': 'Південне',
  'UA6312023002': 'Борове',
  'UA6312023007': 'Докучаєвське',
  'UA6312023006': 'Хроли',
  'UA6312023003': 'Лелюки',
  'UA6312023004': 'Логачівка',
  'UA6312023005': 'Пономаренки',
  'UA6312023001': 'Рогань',
  'UA6312025015': 'Березівське',
  'UA6312025004': 'Безруків',
  'UA6312025007': 'Дворічний Кут',
  'UA6312025016': 'Григорівка',
  'UA6312025006': 'Гуківка',
  'UA6312025008': 'Куряжанка',
  'UA6312025017': 'Курортне',
  'UA6312025003': 'Пересічне',
  'UA6312025018': 'Південне',
  'UA6312025009': 'Подвірки',
  'UA6312025010': 'Польова',
  'UA6312025011': 'Протопопівка',
  'UA6312025012': 'Сіряки',
  'UA6312025001': 'Солоницівка',
  'UA6312025013': 'Тернова',
  'UA6312025005': 'В\'язове',
  'UA6312025002': 'Вільшани',
  'UA6312025014': 'Ярошівка',
  'UA6312029002': 'Бутенкове',
  'UA6312029008': 'Черкаські Тишки',
  'UA6312029009': 'Черняки',
  'UA6312029003': 'Михайлівка',
  'UA6312029004': 'Олександрівка',
  'UA6312029005': 'Петрівка',
  'UA6312029006': 'Руські Тишки',
  'UA6312029001': 'Циркуни',
  'UA6312029007': 'Українське',
  'UA6312005003': 'Байрак',
  'UA6312005004': 'Бісквітне',
  'UA6312005005': 'Благодатне',
  'UA6312005006': 'Бобрівка',
  'UA6312005007': 'Бражники',
  'UA6312005015': 'Елітне',
  'UA6312005011': 'Коропи',
  'UA6312005002': 'Кулиничі',
  'UA6312005017': 'Кутузівка',
  'UA6312005012': 'Мала Рогань',
  'UA6312005018': 'Момотове',
  'UA6312005019': 'Перемога',
  'UA6312005020': 'Прелесне',
  'UA6312005021': 'Слобідське',
  'UA6312005013': 'Сороківка',
  'UA6312005014': 'Степанки',
  'UA6312005008': 'Верхня Роганка',
  'UA6312005001': 'Вільхівка',
  'UA6312005009': 'Заїки',
  'UA6312005010': 'Затишшя',
  'UA6312005016': 'Зернове',
  'UA6312003002': 'Бабаї',
  'UA6312003006': 'Нова Березівка',
  'UA6312003003': 'Покотилівка',
  'UA6312003004': 'Ржавець',
  'UA6312003001': 'Високий',
  'UA6312003005': 'Затишне',
  'UA6306003003': 'Андріївка',
  'UA6306003004': 'Антонівка',
  'UA6306003005': 'Бесарабівка',
  'UA6306003010': 'Дальнє',
  'UA6306003009': 'Гутирівка',
  'UA6306003015': 'Калюжине',
  'UA6306003014': 'Калинівка',
  'UA6306003016': 'Карабущине',
  'UA6306003001': 'Кегичівка',
  'UA6306003019': 'Кофанівка',
  'UA6306003020': 'Коханівка',
  'UA6306003018': 'Козачі Майдани',
  'UA6306003017': 'Козацьке',
  'UA6306003040': 'Красне',
  'UA6306003021': 'Краснянське',
  'UA6306003022': 'Крутоярівка',
  'UA6306003023': 'Лозова',
  'UA6306003024': 'Мажарка',
  'UA6306003025': 'Медведівка',
  'UA6306003026': 'Нова Парафіївка',
  'UA6306003027': 'Новоіванівка',
  'UA6306003028': 'Олександрівка',
  'UA6306003029': 'Олександрівське',
  'UA6306003031': 'Парасковія',
  'UA6306003030': 'Павлівка',
  'UA6306003032': 'Писарівка',
  'UA6306003034': 'Рояківка',
  'UA6306003033': 'Розсохувата',
  'UA6306003035': 'Серго',
  'UA6306003037': 'Шевченкове',
  'UA6306003038': 'Шляхове',
  'UA6306003002': 'Слобожанське',
  'UA6306003036': 'Софіївка',
  'UA6306003039': 'Вільне',
  'UA6306003007': 'Власівка',
  'UA6306003008': 'Вовківка',
  'UA6306003006': 'Високе',
  'UA6306003011': 'Зелена Діброва',
  'UA6306003012': 'Землянки',
  'UA6306003013': 'Золотухівка',
  'UA6306005002': 'Берестовенька',
  'UA6306005022': 'Червоне',
  'UA6306005023': 'Дослідне',
  'UA6306005005': 'Гірчаківка',
  'UA6306005006': 'Гранове',
  'UA6306005008': 'Іванівське',
  'UA6306005009': 'Калинівка',
  'UA6306005021': 'Хрестище',
  'UA6306005011': 'Кобцівка',
  'UA6306005001': 'Красноград',
  'UA6306005024': 'Куми',
  'UA6306005010': 'Кирилівка',
  'UA6306005013': 'Мокрянка',
  'UA6306005012': 'Миколо-Комишувата',
  'UA6306005014': 'Новоселівка',
  'UA6306005015': 'Оленівка',
  'UA6306005016': 'Першотравневе',
  'UA6306005017': 'Піщанка',
  'UA6306005025': 'Покровське',
  'UA6306005026': 'Степове',
  'UA6306005018': 'Світле',
  'UA6306005019': 'Тишенківка',
  'UA6306005020': 'Українка',
  'UA6306005004': 'Вишневе',
  'UA6306005003': 'Високе',
  'UA6306005007': 'Зоряне',
  'UA6306007028': 'Балки',
  'UA6306007002': 'Березівка',
  'UA6306007009': 'Добренька',
  'UA6306007029': 'Дружба',
  'UA6306007008': 'Гадяч',
  'UA6306007010': 'Кам\'янка',
  'UA6306007012': 'Кобзівка Друга',
  'UA6306007011': 'Кобзівка',
  'UA6306007013': 'Копанки',
  'UA6306007015': 'Лукашівка',
  'UA6306007014': 'Лип\'янка',
  'UA6306007017': 'Маховик',
  'UA6306007016': 'Мартинівка',
  'UA6306007001': 'Наталине',
  'UA6306007018': 'Новопавлівка',
  'UA6306007019': 'Одрадівка',
  'UA6306007020': 'Олександрівка',
  'UA6306007021': 'Петрівка',
  'UA6306007022': 'Попівка',
  'UA6306007023': 'Роздолля',
  'UA6306007030': 'Садове',
  'UA6306007026': 'Шкаврове',
  'UA6306007024': 'Соснівка',
  'UA6306007025': 'Улянівка',
  'UA6306007003': 'Варварівка',
  'UA6306007005': 'Вільховий Ріг',
  'UA6306007004': 'Вільне',
  'UA6306007007': 'Володимирівка',
  'UA6306007006': 'Вознесенське',
  'UA6306007027': 'Ясна Поляна',
  'UA6306009002': 'Андріївка',
  'UA6306009003': 'Аполлонівка',
  'UA6306009004': 'Багата Чернещина',
  'UA6306009005': 'Берестове',
  'UA6306009006': 'Бондарівка',
  'UA6306009059': 'Червона Долина',
  'UA6306009060': 'Червоний Степ',
  'UA6306009061': 'Чорнолозка',
  'UA6306009012': 'Дар-Надежда',
  'UA6306009013': 'Дубові Гряди',
  'UA6306009009': 'Гаркушине',
  'UA6306009010': 'Германівка',
  'UA6306009011': 'Гришівка',
  'UA6306009017': 'Івано-Слиньківка',
  'UA6306009018': 'Калинівка',
  'UA6306009019': 'Касянівка',
  'UA6306009020': 'Катеринівка',
  'UA6306009058': 'Халтуріна',
  'UA6306009022': 'Костянтинівка',
  'UA6306009021': 'Козирів',
  'UA6306009023': 'Красна Гірка',
  'UA6306009024': 'Красноярка',
  'UA6306009025': 'Кузьминівка',
  'UA6306009026': 'Лебедівка',
  'UA6306009027': 'Лесівка',
  'UA6306009028': 'Лигівка',
  'UA6306009029': 'Максимівка',
  'UA6306009030': 'Малі Бучки',
  'UA6306009031': 'Мар\'ївка',
  'UA6306009032': 'Миколаївка',
  'UA6306009033': 'Миколаївка',
  'UA6306009035': 'Надеждине',
  'UA6306009034': 'Нагірне',
  'UA6306009036': 'Нова Балка',
  'UA6306009037': 'Нова Чернещина',
  'UA6306009038': 'Новобогданівка',
  'UA6306009040': 'Новодмитрівка',
  'UA6306009041': 'Новомихайлівка',
  'UA6306009042': 'Новоолександрівка',
  'UA6306009039': 'Нововолодимирівка',
  'UA6306009043': 'Огіївка',
  'UA6306009044': 'Олександрівка',
  'UA6306009045': 'Олексіївка',
  'UA6306009046': 'Олійники',
  'UA6306009047': 'Орільське',
  'UA6306009048': 'Петрівка',
  'UA6306009049': 'Піскувате',
  'UA6306009001': 'Сахновщина',
  'UA6306009062': 'Шевченкове',
  'UA6306009063': 'Шевченкове',
  'UA6306009050': 'Скиртяне',
  'UA6306009051': 'Староволодимирівка',
  'UA6306009052': 'Степанівка',
  'UA6306009054': 'Судиха',
  'UA6306009053': 'Сугарівське',
  'UA6306009056': 'Тарасівка',
  'UA6306009055': 'Тавежня',
  'UA6306009057': 'Тернуватка',
  'UA6306009007': 'Великі Бучки',
  'UA6306009008': 'Володимирівка',
  'UA6306009064': 'Яковлівка',
  'UA6306009014': 'Загаркушине',
  'UA6306009015': 'Зелене',
  'UA6306009016': 'Зелений Клин',
  'UA6306011002': 'Білоусівка',
  'UA6306011021': 'Червона Поляна',
  'UA6306011005': 'Дегтярка',
  'UA6306011006': 'Дячківка',
  'UA6306011004': 'Гаврилівка',
  'UA6306011008': 'Караван',
  'UA6306011009': 'Клинове',
  'UA6306011011': 'Ляшівка',
  'UA6306011010': 'Литовки',
  'UA6306011013': 'Москальцівка',
  'UA6306011014': 'Муравлинка',
  'UA6306011012': 'Миколаївка',
  'UA6306011015': 'Охоче',
  'UA6306011022': 'Палатки',
  'UA6306011016': 'Парасковія',
  'UA6306011017': 'Печіївка',
  'UA6306011018': 'Раківка',
  'UA6306011019': 'Слобожанське',
  'UA6306011020': 'Станичне',
  'UA6306011001': 'Старовірівка',
  'UA6306011003': 'Винники',
  'UA6306011007': 'Завадівка',
  'UA6306001002': 'Абазівка',
  'UA6306001003': 'Бердянка',
  'UA6306001038': 'Чернещина',
  'UA6306001005': 'Дудівка',
  'UA6306001012': 'Кочетівка',
  'UA6306001011': 'Котівка',
  'UA6306001013': 'Леб\'яже',
  'UA6306001014': 'Лиманівка',
  'UA6306001015': 'Малий Орчик',
  'UA6306001016': 'Миколаївка',
  'UA6306001017': 'Нагірне',
  'UA6306001018': 'Нове Мажарове',
  'UA6306001019': 'Нове Пекельне',
  'UA6306001020': 'Новоселівка',
  'UA6306001021': 'Олександрівка',
  'UA6306001022': 'Олянівка',
  'UA6306001023': 'Орчик',
  'UA6306001024': 'Педашка Перша',
  'UA6306001025': 'Перемога',
  'UA6306001026': 'Першотравневе',
  'UA6306001027': 'Петрівка',
  'UA6306001028': 'Письмаківка',
  'UA6306001029': 'Романівка',
  'UA6306001030': 'Рунівщина',
  'UA6306001031': 'Семенівка',
  'UA6306001032': 'Скалонівка',
  'UA6306001033': 'Сомівка',
  'UA6306001034': 'Старе Мажарове',
  'UA6306001035': 'Старе Пекельне',
  'UA6306001036': 'Травневе',
  'UA6306001037': 'Устимівка',
  'UA6306001004': 'Вишневе',
  'UA6306001006': 'Забарине',
  'UA6306001001': 'Зачепилівка',
  'UA6306001007': 'Займанка',
  'UA6306001008': 'Залінійне',
  'UA6306001009': 'Зарічне',
  'UA6306001010': 'Зіньківщина',
  'UA6308005002': 'Берестове',
  'UA6308005003': 'Богданівське',
  'UA6308005004': 'Бологівка',
  'UA6308005012': 'Добролюбівка',
  'UA6308005013': 'Довгеньке',
  'UA6308005053': 'Дворічанське',
  'UA6308005001': 'Дворічна',
  'UA6308005054': 'Дворічне',
  'UA6308005052': 'Фиголівка',
  'UA6308005009': 'Гороб\'ївка',
  'UA6308005010': 'Гракове',
  'UA6308005011': 'Гряниківка',
  'UA6308005015': 'Іванівка',
  'UA6308005016': 'Кам\'янка',
  'UA6308005017': 'Касянівка',
  'UA6308005018': 'Колодязне',
  'UA6308005019': 'Красне Перше',
  'UA6308005020': 'Кутьківка',
  'UA6308005023': 'Лозова Друга',
  'UA6308005024': 'Лозова Перша',
  'UA6308005021': 'Лиман Другий',
  'UA6308005022': 'Лиман Перший',
  'UA6308005025': 'Мальцівка',
  'UA6308005026': 'Масютівка',
  'UA6308005027': 'Мечнікове',
  'UA6308005028': 'Миколаївка',
  'UA6308005029': 'Митрофанівка',
  'UA6308005030': 'Нежданівка',
  'UA6308005032': 'Новоєгорівка',
  'UA6308005033': 'Новомлинськ',
  'UA6308005034': 'Новоужвинівка',
  'UA6308005031': 'Нововасилівка',
  'UA6308005035': 'Обухівка',
  'UA6308005036': 'Одрадне',
  'UA6308005037': 'Павлівка',
  'UA6308005038': 'Першотравневе',
  'UA6308005039': 'Петрівка',
  'UA6308005040': 'Петрівське',
  'UA6308005041': 'Петро-Іванівка',
  'UA6308005043': 'Піщанка',
  'UA6308005042': 'Піски',
  'UA6308005044': 'Плескачівка',
  'UA6308005045': 'Путникове',
  'UA6308005046': 'Рідкодуб',
  'UA6308005047': 'Строївка',
  'UA6308005048': 'Тавільжанка',
  'UA6308005049': 'Терни',
  'UA6308005050': 'Токарівка',
  'UA6308005051': 'Тополі',
  'UA6308005055': 'Тополі',
  'UA6308005005': 'Васильцівка',
  'UA6308005006': 'Великий Виселок',
  'UA6308005007': 'Вільшана',
  'UA6308005008': 'Водяне',
  'UA6308005014': 'Западне',
  'UA6308007002': 'Благодатівка',
  'UA6308007011': 'Дорошівка',
  'UA6308007008': 'Голубівка',
  'UA6308007009': 'Грушівка',
  'UA6308007010': 'Гусинка',
  'UA6308007013': 'Калинове',
  'UA6308007001': 'Кіндрашівка',
  'UA6308007014': 'Ковалівка',
  'UA6308007015': 'Курочкине',
  'UA6308007016': 'Мала Шапківка',
  'UA6308007019': 'Моначинівка',
  'UA6308007020': 'Московка',
  'UA6308007017': 'Миколаївка Друга',
  'UA6308007018': 'Миколаївка Перша',
  'UA6308007021': 'Нечволодівка',
  'UA6308007022': 'Паламарівка',
  'UA6308007024': 'Просянка',
  'UA6308007023': 'Прилютове',
  'UA6308007025': 'Радьківка',
  'UA6308007026': 'Садове',
  'UA6308007027': 'Самборівка',
  'UA6308007028': 'Селище',
  'UA6308007029': 'Смородьківка',
  'UA6308007030': 'Соболівка',
  'UA6308007032': 'Цибівка',
  'UA6308007031': 'Тищенківка',
  'UA6308007003': 'Василівка',
  'UA6308007004': 'Василівка',
  'UA6308007005': 'Велика Шапківка',
  'UA6308007007': 'Вовчий Яр',
  'UA6308007006': 'Вишнівка',
  'UA6308007012': 'Єгорівка',
  'UA6308009004': 'Болдирівка',
  'UA6308009002': 'Ківшарівка',
  'UA6308009001': 'Куп\'янськ',
  'UA6308009003': 'Куп\'янськ-Вузловий',
  'UA6308009005': 'Осадьківка',
  'UA6308009006': 'Осиново',
  'UA6308009007': 'Пойдунівка',
  'UA6308009009': 'Прокопівка',
  'UA6308009008': 'Пристін',
  'UA6308009010': 'Сеньок',
  'UA6308009011': 'Стінка',
  'UA6308009012': 'Тамарганівка',
  'UA6308011002': 'Біле',
  'UA6308011004': 'Глушківка',
  'UA6308011012': 'Хомине',
  'UA6308011005': 'Колісниківка',
  'UA6308011006': 'Кругляківка',
  'UA6308011001': 'Курилівка',
  'UA6308011007': 'Лісна Стінка',
  'UA6308011008': 'Новоосинове',
  'UA6308011013': 'Піщане',
  'UA6308011009': 'Подоли',
  'UA6308011010': 'Сенькове',
  'UA6308011011': 'Синиха',
  'UA6308011003': 'Воронцівка',
  'UA6308013002': 'Берестове',
  'UA6308013004': 'Іванівка',
  'UA6308013006': 'Котлярівка',
  'UA6308013007': 'Крохмальне',
  'UA6308013008': 'Кучерівка',
  'UA6308013005': 'Кислівка',
  'UA6308013009': 'Миколаївка',
  'UA6308013010': 'Нова Тарасівка',
  'UA6308013011': 'Орлянка',
  'UA6308013001': 'Петропавлівка',
  'UA6308013012': 'Піщане',
  'UA6308013014': 'Степова Новоселівка',
  'UA6308013013': 'Синьківка',
  'UA6308013015': 'Табаївка',
  'UA6308013016': 'Ягідне',
  'UA6308013003': 'Затишне',
  'UA6308015002': 'Аркадівка',
  'UA6308015003': 'Баранове',
  'UA6308015005': 'Березівка',
  'UA6308015004': 'Безмятежне',
  'UA6308015006': 'Богодарівка',
  'UA6308015060': 'Борівське',
  'UA6308015015': 'Дуванка',
  'UA6308015056': 'Федорівка',
  'UA6308015012': 'Гетьманівка',
  'UA6308015013': 'Горожанівка',
  'UA6308015014': 'Гроза',
  'UA6308015018': 'Іванівка',
  'UA6308015019': 'Іванівка',
  'UA6308015057': 'Худоярове',
  'UA6308015020': 'Колісниківка',
  'UA6308015021': 'Кравцівка',
  'UA6308015022': 'Лелюківка',
  'UA6308015023': 'Максимівка',
  'UA6308015024': 'Малі Кринки',
  'UA6308015029': 'Мостове',
  'UA6308015027': 'Михайлівка',
  'UA6308015028': 'Михайлівка',
  'UA6308015025': 'Микільське',
  'UA6308015026': 'Миропілля',
  'UA6308015032': 'Новомиколаївка',
  'UA6308015033': 'Новостепанівка',
  'UA6308015031': 'Новий Лиман',
  'UA6308015030': 'Нижній Бурлук',
  'UA6308015035': 'Одрадне',
  'UA6308015034': 'Огурцівка',
  'UA6308015036': 'Олександрівка',
  'UA6308015037': 'Олексіївка',
  'UA6308015038': 'Первомайське',
  'UA6308015039': 'Петрівка',
  'UA6308015040': 'Петропілля',
  'UA6308015041': 'Полтава',
  'UA6308015042': 'Раївка',
  'UA6308015043': 'Роздольне',
  'UA6308015045': 'Самарське',
  'UA6308015044': 'Сазонівка',
  'UA6308015046': 'Семенівка',
  'UA6308015001': 'Шевченкове',
  'UA6308015058': 'Шевченкове',
  'UA6308015059': 'Шишківка',
  'UA6308015047': 'Смолівка',
  'UA6308015048': 'Сподобівка',
  'UA6308015050': 'Станіславка',
  'UA6308015052': 'Старовірівка',
  'UA6308015051': 'Старий Чизвик',
  'UA6308015049': 'Ставище',
  'UA6308015053': 'Сумське',
  'UA6308015054': 'Тетянівка',
  'UA6308015055': 'Троїцьке',
  'UA6308015007': 'Василенкове',
  'UA6308015008': 'Великі Хутори',
  'UA6308015009': 'Верхньозорянське',
  'UA6308015010': 'Володимирівка',
  'UA6308015011': 'Волоська Балаклія',
  'UA6308015016': 'Журавка',
  'UA6308015017': 'Зорянське',
  'UA6308001002': 'Амбарне',
  'UA6308001003': 'Андріївка',
  'UA6308001004': 'Аркушине',
  'UA6308001005': 'Балка',
  'UA6308001006': 'Буряківка',
  'UA6308001050': 'Червона Хвиля',
  'UA6308001037': 'Дорошенкове',
  'UA6308001009': 'Гнилиця Перша',
  'UA6308001008': 'Гнилиця',
  'UA6308001010': 'Голубівка',
  'UA6308001011': 'Горяне',
  'UA6308001012': 'Григорівка',
  'UA6308001039': 'Канівцеве',
  'UA6308001016': 'Катеринівка',
  'UA6308001031': 'Хатнє',
  'UA6308001017': 'Красне',
  'UA6308001040': 'Красноярське',
  'UA6308001041': 'Куп\'єваха',
  'UA6308001018': 'Лебедівка',
  'UA6308001019': 'Лозове',
  'UA6308001042': 'Литвинівка',
  'UA6308001043': 'Малахове',
  'UA6308001020': 'Малий Бурлук',
  'UA6308001021': 'Манцівка',
  'UA6308001022': 'Михайлівка',
  'UA6308001044': 'Микільське',
  'UA6308001023': 'Нестерівка',
  'UA6308001024': 'Нова Олександрівка',
  'UA6308001045': 'Нова Олександрівка',
  'UA6308001025': 'Новоселівка',
  'UA6308001046': 'Підсереднє',
  'UA6308001026': 'Плоске',
  'UA6308001027': 'Полковниче',
  'UA6308001028': 'Рогозянка',
  'UA6308001047': 'Садовод',
  'UA6308001029': 'Середній Бурлук',
  'UA6308001032': 'Шевченкове',
  'UA6308001033': 'Шевченкове',
  'UA6308001034': 'Шипувате',
  'UA6308001051': 'Шипувате',
  'UA6308001048': 'Сірий Яр',
  'UA6308001049': 'Соніно',
  'UA6308001030': 'Стецьківка',
  'UA6308001001': 'Великий Бурлук',
  'UA6308001007': 'Веселе',
  'UA6308001036': 'Яєчне',
  'UA6308001035': 'Юр\'ївка',
  'UA6308001014': 'Заміст',
  'UA6308001015': 'Зелений Гай',
  'UA6308001038': 'Зелений Гай',
  'UA6308001013': 'Жуків Яр',
  'UA6308003003': 'Анискине',
  'UA6308003004': 'Артільне',
  'UA6308003005': 'Березники',
  'UA6308003024': 'Чорне',
  'UA6308003025': 'Чугунівка',
  'UA6308003009': 'Довгеньке',
  'UA6308003010': 'Довжанка',
  'UA6308003030': 'Федорівка',
  'UA6308003007': 'Гогине',
  'UA6308003008': 'Грачівка',
  'UA6308003012': 'Івашкине',
  'UA6308003013': 'Комісарове',
  'UA6308003014': 'Крейдянка',
  'UA6308003015': 'Купине',
  'UA6308003028': 'Курганне',
  'UA6308003016': 'Лобанівка',
  'UA6308003017': 'Мілове',
  'UA6308003029': 'Миколаївка',
  'UA6308003018': 'Нефедівка',
  'UA6308003019': 'Озерне',
  'UA6308003020': 'Потихонове',
  'UA6308003002': 'Приколотне',
  'UA6308003021': 'Рублене',
  'UA6308003026': 'Шев\'яківка',
  'UA6308003027': 'Широке',
  'UA6308003022': 'Слизневе',
  'UA6308003023': 'Устинівка',
  'UA6308003001': 'Вільхуватка',
  'UA6308003006': 'Водяне',
  'UA6308003011': 'Зарубинка',
  'UA6310001001': 'Біляївка',
  'UA6310001002': 'Булацелівка',
  'UA6310001023': 'Червоне',
  'UA6310001005': 'Дмитрівка',
  'UA6310001009': 'Кіптівка',
  'UA6310001010': 'Красне',
  'UA6310001025': 'Краснопавлівське',
  'UA6310001011': 'Крюкове',
  'UA6310001012': 'Мар\'ївка',
  'UA6310001013': 'Миронівка',
  'UA6310001014': 'Нова Семенівка',
  'UA6310001015': 'Нове',
  'UA6310001016': 'Новоєгорівка',
  'UA6310001017': 'Паризьке',
  'UA6310001018': 'Петрівка',
  'UA6310001019': 'Побєда',
  'UA6310001020': 'Роздолля',
  'UA6310001024': 'Шульське',
  'UA6310001021': 'Суданка',
  'UA6310001022': 'Тимченки',
  'UA6310001003': 'Верхня Орілька',
  'UA6310001004': 'Веселе',
  'UA6310001006': 'Задорожнє',
  'UA6310001007': 'Закутнівка',
  'UA6310001008': 'Зеленівка',
  'UA6310003002': 'Алісівка',
  'UA6310003003': 'Андріївка',
  'UA6310003005': 'Башилівка',
  'UA6310003004': 'Батюшки',
  'UA6310003007': 'Берестове',
  'UA6310003008': 'Берестове',
  'UA6310003006': 'Безпальцеве',
  'UA6310003001': 'Близнюки',
  'UA6310003009': 'Богданівка',
  'UA6310003010': 'Бубнове Перше',
  'UA6310003011': 'Бурбулатове',
  'UA6310003092': 'Червоне',
  'UA6310003028': 'Далеке',
  'UA6310003029': 'Дмитрівка',
  'UA6310003030': 'Дмитрівка',
  'UA6310003031': 'Добровілля',
  'UA6310003032': 'Дубове',
  'UA6310003033': 'Дунине',
  'UA6310003034': 'Енергетиків',
  'UA6310003090': 'Федорівка',
  'UA6310003025': 'Ганнівка',
  'UA6310003026': 'Ганно-Рудаєве',
  'UA6310003027': 'Григорівка',
  'UA6310003036': 'Катеринівка',
  'UA6310003091': 'Холодне',
  'UA6310003038': 'Кленове',
  'UA6310003039': 'Криштопівка',
  'UA6310003037': 'Квітневе',
  'UA6310003040': 'Ладне',
  'UA6310003041': 'Лугове',
  'UA6310003042': 'Лукашівка',
  'UA6310003043': 'Мар\'ївка',
  'UA6310003050': 'Морокине',
  'UA6310003044': 'Микільське',
  'UA6310003045': 'Миколаївка Друга',
  'UA6310003046': 'Миколаївка Перша',
  'UA6310003047': 'Милівка',
  'UA6310003048': 'Мирне',
  'UA6310003049': 'Миролюбівка',
  'UA6310003051': 'Надеждине',
  'UA6310003052': 'Настасівка',
  'UA6310003053': 'Новоіванівка',
  'UA6310003054': 'Новомар\'ївка',
  'UA6310003055': 'Новомиколаївка',
  'UA6310003056': 'Новонадеждине',
  'UA6310003057': 'Новоолександрівка',
  'UA6310003058': 'Новопавлівка',
  'UA6310003059': 'Новопокровка',
  'UA6310003060': 'Новоселівка',
  'UA6310003061': 'Новоселівка',
  'UA6310003062': 'Новотроїцьке',
  'UA6310003063': 'Новоукраїнка',
  'UA6310003064': 'Новоуплатне',
  'UA6310003065': 'Одинецьке',
  'UA6310003066': 'Олександрівка',
  'UA6310003067': 'Олексіївка',
  'UA6310003068': 'Остерське',
  'UA6310003069': 'Острівщина',
  'UA6310003070': 'Павлівка',
  'UA6310003071': 'Плахтіївка',
  'UA6310003072': 'Преображенівка',
  'UA6310003073': 'Привілля',
  'UA6310003077': 'Рясне',
  'UA6310003075': 'Роздолівка',
  'UA6310003076': 'Рудаєве',
  'UA6310003074': 'Рижове',
  'UA6310003078': 'Садове',
  'UA6310003079': 'Самійлівка',
  'UA6310003080': 'Самійлівка',
  'UA6310003081': 'Семенівка',
  'UA6310003082': 'Серафимівка',
  'UA6310003083': 'Сергієва Балка',
  'UA6310003093': 'Шевченкове Друге',
  'UA6310003094': 'Шевченкове Перше',
  'UA6310003095': 'Широке',
  'UA6310003084': 'Слобожанське',
  'UA6310003086': 'Софіївка Перша',
  'UA6310003085': 'Софіївка',
  'UA6310003087': 'Степове',
  'UA6310003088': 'Тимофіївка',
  'UA6310003089': 'Уплатне',
  'UA6310003012': 'Валер\'яновка',
  'UA6310003013': 'Варварівка',
  'UA6310003014': 'Васюкове',
  'UA6310003016': 'Верхня Самара',
  'UA6310003015': 'Верхньоводяне',
  'UA6310003017': 'Верхове',
  'UA6310003018': 'Веселе',
  'UA6310003020': 'Вільне Друге',
  'UA6310003021': 'Вільне Перше',
  'UA6310003023': 'Водяне',
  'UA6310003024': 'Водяне',
  'UA6310003022': 'Водолазьке',
  'UA6310003019': 'Вишневе',
  'UA6310003097': 'Яковівка',
  'UA6310003096': 'Якимівка',
  'UA6310003035': 'Зубове',
  'UA6310005005': 'Артільне',
  'UA6310005006': 'Бакшарівка',
  'UA6310005007': 'Барабашівка',
  'UA6310005008': 'Благодатне',
  'UA6310005009': 'Богомолівка',
  'UA6310005010': 'Браїлівка',
  'UA6310005011': 'Братолюбівка',
  'UA6310005012': 'Бритай',
  'UA6310005013': 'Бунакове',
  'UA6310005091': 'Чернігівське',
  'UA6310005081': 'Червоний Кут',
  'UA6310005025': 'Домаха',
  'UA6310005024': 'Довгове',
  'UA6310005023': 'Дивізійне',
  'UA6310005076': 'Федорівка',
  'UA6310005077': 'Федорівка',
  'UA6310005018': 'Герсеванівка',
  'UA6310005019': 'Герсеванівське',
  'UA6310005020': 'Городнє',
  'UA6310005021': 'Горохівка',
  'UA6310005022': 'Григоросове',
  'UA6310005031': 'Іванівка',
  'UA6310005032': 'Катеринівка',
  'UA6310005079': 'Хлібне',
  'UA6310005078': 'Хижняківка',
  'UA6310005086': 'Кінне',
  'UA6310005033': 'Копані',
  'UA6310005002': 'Краснопавлівка',
  'UA6310005087': 'Лагідне',
  'UA6310005036': 'Лісівське',
  'UA6310005001': 'Лозова',
  'UA6310005034': 'Лиман',
  'UA6310005035': 'Литовщина',
  'UA6310005037': 'Мальцівське',
  'UA6310005038': 'Мар\'ївка',
  'UA6310005045': 'Михайлівка',
  'UA6310005039': 'Миколаївка',
  'UA6310005040': 'Миколаївка',
  'UA6310005041': 'Миколаївка',
  'UA6310005042': 'Мирне',
  'UA6310005043': 'Миролюбівка',
  'UA6310005088': 'Миролюбівка',
  'UA6310005044': 'Миронівка',
  'UA6310005047': 'Надеждівка',
  'UA6310005046': 'Надеждине',
  'UA6310005048': 'Нестеліївка',
  'UA6310005049': 'Нова Іванівка',
  'UA6310005050': 'Нова Мечебилівка',
  'UA6310005090': 'Нове',
  'UA6310005051': 'Новоселівка',
  'UA6310005089': 'Нижня Краснопавлівка',
  'UA6310005052': 'Оддихне',
  'UA6310005053': 'Олександрівка',
  'UA6310005054': 'Олексіївка',
  'UA6310005003': 'Орілька',
  'UA6310005004': 'Панютине',
  'UA6310005055': 'Павлівка Друга',
  'UA6310005056': 'Перемога',
  'UA6310005057': 'Петропілля',
  'UA6310005058': 'Плисове',
  'UA6310005059': 'Поди',
  'UA6310005060': 'Полтавське',
  'UA6310005061': 'Привілля',
  'UA6310005062': 'Різдвянка',
  'UA6310005063': 'Роздори',
  'UA6310005064': 'Рубіжне',
  'UA6310005065': 'Садове',
  'UA6310005068': 'Сергіївка',
  'UA6310005082': 'Шатівка',
  'UA6310005083': 'Шугаївка',
  'UA6310005069': 'Смирнівка',
  'UA6310005070': 'Степанівка',
  'UA6310005071': 'Степове',
  'UA6310005072': 'Страсне',
  'UA6310005067': 'Святушине',
  'UA6310005066': 'Світловщина',
  'UA6310005080': 'Царедарівка',
  'UA6310005073': 'Тихопілля',
  'UA6310005074': 'Українське',
  'UA6310005075': 'Українське',
  'UA6310005014': 'Веселе',
  'UA6310005015': 'Веселе',
  'UA6310005016': 'Вільне',
  'UA6310005017': 'Водолага',
  'UA6310005084': 'Яблучне',
  'UA6310005085': 'Яковлівка',
  'UA6310005026': 'Єлизаветівка',
  'UA6310005029': 'Захарівське',
  'UA6310005027': 'Запарівка',
  'UA6310005028': 'Запорізьке',
  'UA6310005030': 'Зелений Гай',
  'UA6310007002': 'Берека',
  'UA6310007003': 'Берестки',
  'UA6310007006': 'Кам\'янка',
  'UA6310007007': 'Картамиш',
  'UA6310007009': 'Красиве',
  'UA6310007010': 'Крутоярка',
  'UA6310007008': 'Киселі',
  'UA6310007011': 'Лозівське',
  'UA6310007012': 'Максимівка',
  'UA6310007014': 'Михайлівка',
  'UA6310007013': 'Миколаївка',
  'UA6310007015': 'Новоберецьке',
  'UA6310007016': 'Одрадове',
  'UA6310007001': 'Олексіївка',
  'UA6310007017': 'Перерізнівка',
  'UA6310007018': 'Рокитне',
  'UA6310007019': 'Семенівка',
  'UA6310007022': 'Шевченкове',
  'UA6310007023': 'Слобідське',
  'UA6310007020': 'Степове',
  'UA6310007021': 'Сумці',
  'UA6310007024': 'Трійчате',
  'UA6310007025': 'Троїцьке',
  'UA6310007004': 'Верхній Бишкин',
  'UA6310007005': 'Єфремівка',
  'UA6310009003': 'Грушине',
  'UA6310009004': 'Калинівка',
  'UA6310009005': 'Караченців',
  'UA6310009006': 'Кашпурівка',
  'UA6310009007': 'Маслівка',
  'UA6310009001': 'Первомайський',
  'UA6310009008': 'Радомислівка',
  'UA6310009009': 'Ржавчик',
  'UA6310009002': 'Високе',
  'UA6502001001': 'Берислав',
  'UA6502001003': 'Новоберислав',
  'UA6502001004': 'Новосілка',
  'UA6502001005': 'Першотравневе',
  'UA6502001006': 'Раківка',
  'UA6502001010': 'Шляхове',
  'UA6502001007': 'Тараса Шевченка',
  'UA6502001008': 'Томарине',
  'UA6502001009': 'Урожайне',
  'UA6502001002': 'Зміївка',
  'UA6502003001': 'Борозенське',
  'UA6502003010': 'Чарівне',
  'UA6502003002': 'Дмитренка',
  'UA6502003003': 'Костомарове',
  'UA6502003004': 'Красносільське',
  'UA6502003005': 'Кучерське',
  'UA6502003006': 'Нова Кубань',
  'UA6502003007': 'П\'ятихатки',
  'UA6502003008': 'Садок',
  'UA6502003009': 'Стариця',
  'UA6502009002': 'Андріївка',
  'UA6502009003': 'Благодатівка',
  'UA6502009004': 'Бобровий Кут',
  'UA6502009001': 'Калинівське',
  'UA6502009007': 'Краснолюбецьк',
  'UA6502009008': 'Лозове',
  'UA6502009009': 'Мала Сейдеминуха',
  'UA6502009010': 'Новогреднєве',
  'UA6502009011': 'Новополтавка',
  'UA6502009012': 'Сухий Ставок',
  'UA6502009005': 'Заповіт',
  'UA6502009006': 'Зелений Гай',
  'UA6502011003': 'Кам\'янка',
  'UA6502011001': 'Кочубеївка',
  'UA6502011004': 'Краснівка',
  'UA6502011005': 'Микільське',
  'UA6502011006': 'Наталине',
  'UA6502011007': 'Нова Шестірня',
  'UA6502011008': 'Новобратське',
  'UA6502011009': 'Орлове',
  'UA6502011010': 'Пригір\'я',
  'UA6502011011': 'Рівнопілля',
  'UA6502011012': 'Розівка',
  'UA6502011013': 'Світлівка',
  'UA6502011014': 'Свободне',
  'UA6502011002': 'Заградівка',
  'UA6502013010': 'Червоний Яр',
  'UA6502013002': 'Дудчани',
  'UA6502013003': 'Качкарівка',
  'UA6502013001': 'Милове',
  'UA6502013004': 'Нова Кам\'янка',
  'UA6502013005': 'Новогригорівка',
  'UA6502013006': 'Новокаїри',
  'UA6502013007': 'Республіканець',
  'UA6502013008': 'Саблуківка',
  'UA6502013009': 'Суханове',
  'UA6502017002': 'Біляївка',
  'UA6502017009': 'Червоне',
  'UA6502017003': 'Гаврилівка',
  'UA6502017005': 'Майське',
  'UA6502017006': 'Михайлівка',
  'UA6502017001': 'Новоолександрівка',
  'UA6502017007': 'Петропавлівка',
  'UA6502017008': 'Українка',
  'UA6502017004': 'Золота Балка',
  'UA6502019009': 'Червоний Маяк',
  'UA6502019003': 'Кошара',
  'UA6502019002': 'Костирка',
  'UA6502019004': 'Крупиця',
  'UA6502019005': 'Максима Горького',
  'UA6502019008': 'Монастирське',
  'UA6502019001': 'Новорайськ',
  'UA6502019006': 'Степне',
  'UA6502019007': 'Заможне',
  'UA6502015008': 'Хрещенівка',
  'UA6502015010': 'Ленінське',
  'UA6502015002': 'Любимівка',
  'UA6502015003': 'Миролюбівка',
  'UA6502015001': 'Нововоронцовка',
  'UA6502015004': 'Нововоскресенське',
  'UA6502015005': 'Осокорівка',
  'UA6502015006': 'Петрівка',
  'UA6502015009': 'Шевченківка',
  'UA6502015007': 'Трудолюбівка',
  'UA6502021002': 'Бургунка',
  'UA6502021005': 'Львівські Отруби',
  'UA6502021006': 'Львове',
  'UA6502021011': 'Матросівка',
  'UA6502021007': 'Миколаївка',
  'UA6502021008': 'Одрадокам\'янка',
  'UA6502021009': 'Ольгівка',
  'UA6502021010': 'Таврійське',
  'UA6502021001': 'Тягинка',
  'UA6502021004': 'Вірівка',
  'UA6502021003': 'Високе',
  'UA6502005004': 'Безводне',
  'UA6502005002': 'Біла Криниця',
  'UA6502005005': 'Білогірка',
  'UA6502005006': 'Білоусове',
  'UA6502005007': 'Білоусове',
  'UA6502005008': 'Брускинське',
  'UA6502005009': 'Буцівське',
  'UA6502005031': 'Червона Людмилівка',
  'UA6502005032': 'Чкалове',
  'UA6502005012': 'Давидів Брід',
  'UA6502005013': 'Довгове',
  'UA6502005015': 'Іщенка',
  'UA6502005016': 'Кам\'яне',
  'UA6502005003': 'Кар\'єрне',
  'UA6502005017': 'Костромка',
  'UA6502005018': 'Криничанка',
  'UA6502005019': 'Мала Олександрівка',
  'UA6502005021': 'Нова Калуга Друга',
  'UA6502005020': 'Нова Калуга',
  'UA6502005023': 'Новодмитрівка',
  'UA6502005024': 'Новопавлівка',
  'UA6502005022': 'Нововасилівка',
  'UA6502005025': 'Первомайське',
  'UA6502005034': 'Щасливе',
  'UA6502005033': 'Шостакове',
  'UA6502005026': 'Старосілля',
  'UA6502005027': 'Степове',
  'UA6502005029': 'Токареве',
  'UA6502005030': 'Трифонівка',
  'UA6502005028': 'Твердомедове',
  'UA6502005001': 'Велика Олександрівка',
  'UA6502005010': 'Веселе',
  'UA6502005011': 'Вишневе',
  'UA6502005014': 'Запоріжжя',
  'UA6502007002': 'Архангельське',
  'UA6502007003': 'Благодатне',
  'UA6502007004': 'Блакитне',
  'UA6502007022': 'Черешневе',
  'UA6502007006': 'Добрянка',
  'UA6502007021': 'Федорівка',
  'UA6502007008': 'Іванівка',
  'UA6502007009': 'Князівка',
  'UA6502007010': 'Костирка',
  'UA6502007011': 'Мала Шестірня',
  'UA6502007012': 'Мар\'їне',
  'UA6502007013': 'Миколаївка',
  'UA6502007015': 'Новогригорівське',
  'UA6502007016': 'Новомиколаївка',
  'UA6502007017': 'Новопетрівка',
  'UA6502007014': 'Нововознесенське',
  'UA6502007018': 'Ольгине',
  'UA6502007019': 'Потьомкине',
  'UA6502007020': 'Тополине',
  'UA6502007005': 'Вереміївка',
  'UA6502007001': 'Високопілля',
  'UA6502007007': 'Зарічне',
  'UA6504001005': 'Атамань',
  'UA6504001004': 'Азовське',
  'UA6504001006': 'Бойове',
  'UA6504001056': 'Чернігівка',
  'UA6504001055': 'Червоне',
  'UA6504001057': 'Чонгар',
  'UA6504001016': 'Догмарівка',
  'UA6504001017': 'Драгоманове',
  'UA6504001013': 'Гайове',
  'UA6504001001': 'Генічеськ',
  'UA6504001014': 'Генічеська Гірка',
  'UA6504001015': 'Гордієнківці',
  'UA6504001054': 'Херсонське',
  'UA6504001063': 'Комунарське',
  'UA6504001020': 'Люблинка',
  'UA6504001021': 'Макшіївка',
  'UA6504001022': 'Малинівка',
  'UA6504001024': 'Москаленка',
  'UA6504001025': 'Муравейник',
  'UA6504001023': 'Миколаївка',
  'UA6504001064': 'Ногайське',
  'UA6504001026': 'Нова Праця',
  'UA6504001032': 'Новодмитрівка',
  'UA6504001031': 'Новогригорівка',
  'UA6504001033': 'Новоєфремівка',
  'UA6504001034': 'Новоіванівка',
  'UA6504001002': 'Новоолексіївка',
  'UA6504001027': 'Новий Азов',
  'UA6504001028': 'Новий Мир',
  'UA6504001030': 'Новий Труд',
  'UA6504001036': 'Олексіївка',
  'UA6504001035': 'Озеряни',
  'UA6504001037': 'Павлівка',
  'UA6504001045': 'Пчілка',
  'UA6504001038': 'Перекоп',
  'UA6504001039': 'Петрівка',
  'UA6504001040': 'Плавське',
  'UA6504001041': 'Попівка',
  'UA6504001044': 'Пробудження',
  'UA6504001042': 'Придорожнє',
  'UA6504001043': 'Приморське',
  'UA6504001066': 'Приозерне',
  'UA6504001065': 'Привільне',
  'UA6504001046': 'Рівне',
  'UA6504001047': 'Роздолля',
  'UA6504001003': 'Рикове',
  'UA6504001048': 'Салькове',
  'UA6504001049': 'Семихатка',
  'UA6504001050': 'Сергіївка',
  'UA6504001058': 'Щасливцеве',
  'UA6504001051': 'Сокологірне',
  'UA6504001052': 'Стокопані',
  'UA6504001053': 'Стрілкове',
  'UA6504001067': 'Сиваш',
  'UA6504001068': 'Таврійське',
  'UA6504001007': 'Велетнівка',
  'UA6504001008': 'Веснянка',
  'UA6504001010': 'Вікторівка',
  'UA6504001011': 'Вільне',
  'UA6504001012': 'Володимирівка',
  'UA6504001009': 'Виноградний Клин',
  'UA6504001060': 'Ярошик',
  'UA6504001061': 'Ясна Поляна',
  'UA6504001062': 'Залізничне',
  'UA6504001018': 'Запорожець',
  'UA6504001019': 'Зелений Гай',
  'UA6504003002': 'Агаймани',
  'UA6504003003': 'Балашове',
  'UA6504003004': 'Благодатне',
  'UA6504003006': 'Дмитрівка',
  'UA6504003007': 'Дружбівка',
  'UA6504003028': 'Федорівка',
  'UA6504003001': 'Іванівка',
  'UA6504003010': 'Квіткове',
  'UA6504003011': 'Любимівка',
  'UA6504003012': 'Мартівка',
  'UA6504003013': 'Михайлівка',
  'UA6504003015': 'Новодмитрівка Друга',
  'UA6504003016': 'Новодмитрівка Перша',
  'UA6504003017': 'Новомиколаївка',
  'UA6504003018': 'Новосеменівка',
  'UA6504003014': 'Нововасилівка',
  'UA6504003027': 'Новознаменка',
  'UA6504003019': 'Першотравневе',
  'UA6504003025': 'Щасливе',
  'UA6504003024': 'Шотівка',
  'UA6504003023': 'Широка Балка',
  'UA6504003021': 'Трохимівка',
  'UA6504003020': 'Тимофіївка',
  'UA6504003022': 'Українське',
  'UA6504003026': 'Веселівка',
  'UA6504003005': 'Воскресенка',
  'UA6504003008': 'Захарівка',
  'UA6504003009': 'Зелений Гай',
  'UA6504007003': 'Благовіщенка',
  'UA6504007042': 'Чкалове',
  'UA6504007043': 'Чумацький Шлях',
  'UA6504007013': 'Дружелюбівка',
  'UA6504007011': 'Двійне',
  'UA6504007012': 'Дивне',
  'UA6504007041': 'Федорівка',
  'UA6504007009': 'Горностаївка',
  'UA6504007010': 'Громівка',
  'UA6504007019': 'Качкарівка',
  'UA6504007017': 'Калинівка',
  'UA6504007018': 'Катеринівка',
  'UA6504007020': 'Ковильне',
  'UA6504007021': 'Кривий Ріг',
  'UA6504007022': 'Лиходідівка',
  'UA6504007023': 'Маячка',
  'UA6504007024': 'Метрополь',
  'UA6504007026': 'Новомихайлівка',
  'UA6504007025': 'Новомиколаївка',
  'UA6504007027': 'Новопокровка',
  'UA6504007028': 'Новорепівка',
  'UA6504007001': 'Новотроїцьке',
  'UA6504007029': 'Новоукраїнка',
  'UA6504007031': 'Одрадівка',
  'UA6504007032': 'Олександрівка',
  'UA6504007030': 'Овер\'янівка',
  'UA6504007033': 'Перемога',
  'UA6504007034': 'Подове',
  'UA6504007035': 'Попелак',
  'UA6504007036': 'Садове',
  'UA6504007038': 'Сергіївка',
  'UA6504007040': 'Софіївка',
  'UA6504007037': 'Свиридонівка',
  'UA6504007039': 'Сивашівка',
  'UA6504007002': 'Сиваське',
  'UA6504007004': 'Василівка',
  'UA6504007005': 'Водославка',
  'UA6504007007': 'Воскресенка',
  'UA6504007008': 'Воскресенське',
  'UA6504007006': 'Вознесенка',
  'UA6504007044': 'Ясна Поляна',
  'UA6504007015': 'Захарівка',
  'UA6504007014': 'Заозерне',
  'UA6504007016': 'Зелене',
  'UA6504005002': 'Анатолівка',
  'UA6504005003': 'Богданівка',
  'UA6504005004': 'Братське',
  'UA6504005023': 'Чеховка',
  'UA6504005024': 'Дальнє',
  'UA6504005009': 'Дем\'янівка',
  'UA6504005010': 'Догмарівка',
  'UA6504005011': 'Донцове',
  'UA6504005015': 'Косаківка',
  'UA6504005017': 'Новоолександрівка',
  'UA6504005018': 'Новопетрівка',
  'UA6504005019': 'Новорогачинське',
  'UA6504005001': 'Нижні Сірогози',
  'UA6504005016': 'Нижні Торгаї',
  'UA6504005020': 'Партизани',
  'UA6504005021': 'Першопокровка',
  'UA6504005025': 'Сірогози',
  'UA6504005022': 'Степне',
  'UA6504005005': 'Верби',
  'UA6504005006': 'Верхні Сірогози',
  'UA6504005007': 'Верхні Торгаї',
  'UA6504005008': 'Вільне',
  'UA6504005012': 'Заповітне',
  'UA6504005013': 'Зернове',
  'UA6504005014': 'Змагання',
  'UA6506001001': 'Асканія-Нова',
  'UA6506001004': 'Іллінка',
  'UA6506001003': 'Хлібодарівка',
  'UA6506001005': 'Комиш',
  'UA6506001006': 'Маркеєв',
  'UA6506001007': 'Молочне',
  'UA6506001008': 'Новий Етап',
  'UA6506001009': 'Питомник',
  'UA6506001002': 'Веселе Друге',
  'UA6506029002': 'Андріївка',
  'UA6506029003': 'Балтазарівка',
  'UA6506029004': 'Білоцерківка',
  'UA6506029005': 'Благодатне',
  'UA6506029001': 'Чаплинка',
  'UA6506029016': 'Червона Поляна',
  'UA6506029017': 'Червоний Яр',
  'UA6506029018': 'Чорна Долина',
  'UA6506029007': 'Кучерявоволодимирівка',
  'UA6506029006': 'Кудряве',
  'UA6506029008': 'Магдалинівка',
  'UA6506029009': 'Морозівка',
  'UA6506029010': 'Нове',
  'UA6506029011': 'Новий Гай',
  'UA6506029012': 'Першокостянтинівка',
  'UA6506029013': 'Преображенка',
  'UA6506029014': 'Рачівка',
  'UA6506029015': 'Скадовка',
  'UA6506007019': 'Червоноблагодатне',
  'UA6506007001': 'Горностаївка',
  'UA6506007007': 'Каїри',
  'UA6506007009': 'Кочубеївка',
  'UA6506007008': 'Козачі Лагері',
  'UA6506007010': 'Красне',
  'UA6506007011': 'Лопатки',
  'UA6506007012': 'Маринське',
  'UA6506007013': 'Нова Благовіщенка',
  'UA6506007014': 'Нові Олешки',
  'UA6506007015': 'Новоєлизаветівка',
  'UA6506007016': 'Ольгине',
  'UA6506007017': 'Славне',
  'UA6506007018': 'Софіївка',
  'UA6506007002': 'Василівка',
  'UA6506007003': 'Велика Благовіщенка',
  'UA6506007004': 'Вільне',
  'UA6506007020': 'Ясна Поляна',
  'UA6506007005': 'Заводівка',
  'UA6506007006': 'Зелений Под',
  'UA6506011006': 'Чорноморівка',
  'UA6506011001': 'Каховка',
  'UA6506011003': 'Коробки',
  'UA6506011004': 'Малокаховка',
  'UA6506011005': 'Роздольне',
  'UA6506011002': 'Вільна Україна',
  'UA6506027002': 'Долинське',
  'UA6506027001': 'Хрестівка',
  'UA6506027003': 'Маячинка',
  'UA6506027004': 'Надеждівка',
  'UA6506027005': 'Наталівка',
  'UA6506027006': 'Новонаталівка',
  'UA6506027007': 'Рогачинка',
  'UA6506027009': 'Шевченка',
  'UA6506027008': 'Світле',
  'UA6506013002': 'Антонівка',
  'UA6506013003': 'Братолюбівка',
  'UA6506013009': 'Червона Поляна',
  'UA6506013004': 'Дубівка',
  'UA6506013001': 'Костянтинівка',
  'UA6506013007': 'Миколаївка',
  'UA6506013008': 'Старолук\'янівка',
  'UA6506013005': 'Запорізьке',
  'UA6506013006': 'Зірка',
  'UA6506015003': 'Комишанка',
  'UA6506015001': 'Любимівка',
  'UA6506015004': 'Лук\'янівка',
  'UA6506015005': 'Софіївка',
  'UA6506015002': 'Василівка',
  'UA6506015006': 'Завітне',
  'UA6506017002': 'Дніпряни',
  'UA6506017004': 'Корсунка',
  'UA6506017003': 'Козацьке',
  'UA6506017005': 'Маслівка',
  'UA6506017001': 'Нова Каховка',
  'UA6506017006': 'Нові Лагері',
  'UA6506017007': 'Обривка',
  'UA6506017008': 'Піщане',
  'UA6506017010': 'Райське',
  'UA6506017011': 'Тополівка',
  'UA6506017009': 'Веселе',
  'UA6506019001': 'Григорівка',
  'UA6506019002': 'Іванівка',
  'UA6506019003': 'Нововолодимирівка',
  'UA6506019004': 'Павлівка',
  'UA6506019005': 'Строганівка',
  'UA6506021003': 'Демидівка',
  'UA6506021005': 'Миколаївка',
  'UA6506021001': 'Рубанівка',
  'UA6506021002': 'Веселе',
  'UA6506021004': 'Запоріжжя',
  'UA6506025006': 'Червоне Поділля',
  'UA6506025007': 'Чорнянка',
  'UA6506025002': 'Кам\'янка',
  'UA6506025003': 'Новокам\'янка',
  'UA6506025008': 'Плодове',
  'UA6506025004': 'Сергіївка',
  'UA6506025001': 'Таврійськ',
  'UA6506025005': 'Цукури',
  'UA6506023002': 'Дудчине',
  'UA6506023004': 'Любимо-Мар\'ївка',
  'UA6506023005': 'Любимо-Павлівка',
  'UA6506023006': 'Мар\'янівка',
  'UA6506023007': 'Ольгівка',
  'UA6506023011': 'Остапенка',
  'UA6506023008': 'Скворцівка',
  'UA6506023009': 'Солідарне',
  'UA6506023001': 'Тавричанка',
  'UA6506023010': 'Волинське',
  'UA6506023003': 'Заозерне',
  'UA6506003002': 'Дмитрівка',
  'UA6506003003': 'Катеринівка',
  'UA6506003004': 'Князе-Григорівка',
  'UA6506003005': 'Костянтинівка',
  'UA6506003006': 'Мала Лепетиха',
  'UA6506003008': 'Середнє',
  'UA6506003007': 'Сергіївка',
  'UA6506003001': 'Велика Лепетиха',
  'UA6506005002': 'Бабине',
  'UA6506005003': 'Бережанка',
  'UA6506005021': 'Чистопілля',
  'UA6506005006': 'Георгіївка',
  'UA6506005009': 'Кожум\'яки',
  'UA6506005010': 'Лисиче',
  'UA6506005011': 'Михайлівка',
  'UA6506005013': 'Новознам\'янка',
  'UA6506005012': 'Нижній Рогачик',
  'UA6506005014': 'Олексіївка',
  'UA6506005015': 'Павлівка',
  'UA6506005016': 'Первомаївка',
  'UA6506005017': 'Самійлівка',
  'UA6506005018': 'Таврійське',
  'UA6506005019': 'Трудовик',
  'UA6506005020': 'Ушкалка',
  'UA6506005001': 'Верхній Рогачик',
  'UA6506005005': 'Володимирівка',
  'UA6506005004': 'Вишневе',
  'UA6506005007': 'Зелене',
  'UA6506005008': 'Зоря',
  'UA6506009002': 'Архангельська Слобода',
  'UA6506009003': 'Богданівка',
  'UA6506009004': 'Діброва',
  'UA6506009005': 'Дмитрівка',
  'UA6506009016': 'Федорівка',
  'UA6506009006': 'Калинівка',
  'UA6506009007': 'Костогризове',
  'UA6506009008': 'Наталівка',
  'UA6506009009': 'Петропавлівка',
  'UA6506009010': 'Подівка',
  'UA6506009011': 'Просторе',
  'UA6506009012': 'Семенівка',
  'UA6506009014': 'Слиненка',
  'UA6506009015': 'Сокирки',
  'UA6506009013': 'Зелена Рубанівка',
  'UA6506009001': 'Зелений Під',
  'UA6510001013': 'Берегове',
  'UA6510001001': 'Білозерка',
  'UA6510001024': 'Черешеньки',
  'UA6510001016': 'Дніпровське',
  'UA6510001017': 'Дослідне',
  'UA6510001015': 'Гончарне',
  'UA6510001002': 'Грозове',
  'UA6510001005': 'Кізомис',
  'UA6510001020': 'Молодецьке',
  'UA6510001018': 'Мирне',
  'UA6510001019': 'Миролюбівка',
  'UA6510001006': 'Надеждівка',
  'UA6510001007': 'Нова Зоря',
  'UA6510001008': 'Новодмитрівка',
  'UA6510001009': 'Паришеве',
  'UA6510001021': 'Первомайське',
  'UA6510001010': 'Правдине',
  'UA6510001023': 'Ромашкове',
  'UA6510001022': 'Розлив',
  'UA6510001011': 'Таврійське',
  'UA6510001012': 'Томина Балка',
  'UA6510001014': 'Велетенське',
  'UA6510001025': 'Янтарне',
  'UA6510001003': 'Знам\'янка',
  'UA6510001004': 'Зорівка',
  'UA6510017002': 'Барвінок',
  'UA6510017003': 'Благодатне',
  'UA6510017001': 'Чорнобаївка',
  'UA6510017006': 'Клапая',
  'UA6510017010': 'Копані',
  'UA6510017007': 'Крутий Яр',
  'UA6510017005': 'Киселівка',
  'UA6510017008': 'Посад-Покровське',
  'UA6510017009': 'Солдатське',
  'UA6510017004': 'Зелений Гай',
  'UA6510007014': 'Чайкине',
  'UA6510007001': 'Дар\'ївка',
  'UA6510007013': 'Федорівка',
  'UA6510007005': 'Інгулець',
  'UA6510007006': 'Інгулівка',
  'UA6510007004': 'Іванівка',
  'UA6510007007': 'Лиманець',
  'UA6510007008': 'Микільське',
  'UA6510007009': 'Новотягинка',
  'UA6510007010': 'Понятівка',
  'UA6510007011': 'Токарівка',
  'UA6510007012': 'Ульяновка',
  'UA6510007002': 'Вітрове',
  'UA6510007015': 'Ясна Поляна',
  'UA6510007003': 'Зарічне',
  'UA6510015002': 'Антонівка',
  'UA6510015010': 'Благовіщенське',
  'UA6510015006': 'Богданівка',
  'UA6510015012': 'Інженерне',
  'UA6510015001': 'Херсон',
  'UA6510015004': 'Комишани',
  'UA6510015013': 'Молодіжне',
  'UA6510015005': 'Наддніпрянське',
  'UA6510015007': 'Петрівка',
  'UA6510015014': 'Придніпровське',
  'UA6510015015': 'Приозерне',
  'UA6510015008': 'Садове',
  'UA6510015016': 'Сонячне',
  'UA6510015009': 'Степанівка',
  'UA6510015003': 'Зеленівка',
  'UA6510015011': 'Зимівник',
  'UA6510009004': 'Мірошниківка',
  'UA6510009001': 'Музиківка',
  'UA6510009005': 'Східне',
  'UA6510009002': 'Висунці',
  'UA6510009003': 'Загорянівка',
  'UA6510011011': 'Челбурда',
  'UA6510011003': 'Костогризове',
  'UA6510011002': 'Козачі Лагері',
  'UA6510011004': 'Кринки',
  'UA6510011001': 'Олешки',
  'UA6510011012': 'Підлісне',
  'UA6510011005': 'Підстепне',
  'UA6510011006': 'Піщанівка',
  'UA6510011007': 'Поди',
  'UA6510011013': 'Пойма',
  'UA6510011008': 'Раденськ',
  'UA6510011009': 'Саги',
  'UA6510011010': 'Солонці',
  'UA6510013002': 'Олександрівка',
  'UA6510013004': 'Широка Балка',
  'UA6510013003': 'Софіївка',
  'UA6510013001': 'Станіслав',
  'UA6510003003': 'Абрикосівка',
  'UA6510003002': 'Добросілля',
  'UA6510003001': 'Великі Копані',
  'UA6510005002': 'Брилівка',
  'UA6510005003': 'Клини',
  'UA6510005004': 'Мирне',
  'UA6510005005': 'Привітне',
  'UA6510005006': 'Рідне',
  'UA6510005007': 'Тарасівка',
  'UA6510005001': 'Виноградове',
  'UA6510019003': 'Дружне',
  'UA6510019004': 'Марченка',
  'UA6510019002': 'Нова Маячка',
  'UA6510019005': 'Подо-Калинівка',
  'UA6510019006': 'Привільне',
  'UA6510019008': 'Щасливе',
  'UA6510019007': 'Стара Маячка',
  'UA6510019001': 'Ювілейне',
  'UA6508001001': 'Бехтери',
  'UA6508001002': 'Берегове',
  'UA6508001015': 'Чорноморське',
  'UA6508001014': 'Чорноморські Криниці',
  'UA6508001005': 'Круглоозерка',
  'UA6508001006': 'Лиманівка',
  'UA6508001008': 'Новочорномор\'я',
  'UA6508001007': 'Новофедорівка',
  'UA6508001009': 'Облої',
  'UA6508001010': 'Олексіївка',
  'UA6508001011': 'Приморське',
  'UA6508001012': 'Суворовка',
  'UA6508001013': 'Тендрівське',
  'UA6508001003': 'Залізний Порт',
  'UA6508001004': 'Збур\'ївка',
  'UA6508017002': 'Братське',
  'UA6508017001': 'Чулаківка',
  'UA6508017004': 'Геройське',
  'UA6508017006': 'Іванівка',
  'UA6508017008': 'Очаківське',
  'UA6508017007': 'Олександрівка',
  'UA6508017009': 'Пам\'ятне',
  'UA6508017010': 'Рибальче',
  'UA6508017013': 'Садове',
  'UA6508017012': 'Вільна Дружина',
  'UA6508017003': 'Вільна Україна',
  'UA6508017011': 'Виноградне',
  'UA6508017005': 'Забарине',
  'UA6508005002': 'Добропілля',
  'UA6508005001': 'Долматівка',
  'UA6508005004': 'Київка',
  'UA6508005007': 'Новоселівка',
  'UA6508005005': 'Нововолодимирівка',
  'UA6508005006': 'Сліпушинське',
  'UA6508005008': 'Світанок',
  'UA6508005003': 'Зеленотропинське',
  'UA6508003002': 'Білогрудове',
  'UA6508003003': 'Буркути',
  'UA6508003006': 'Гладківка',
  'UA6508003001': 'Гола Пристань',
  'UA6508003007': 'Кардашинка',
  'UA6508003008': 'Кохани',
  'UA6508003009': 'Мала Кардашинка',
  'UA6508003010': 'Малі Копані',
  'UA6508003011': 'Нова Збур\'ївка',
  'UA6508003012': 'Рідна Україна',
  'UA6508003013': 'Стара Збур\'ївка',
  'UA6508003014': 'Таврійське',
  'UA6508003004': 'Велика Кардашинка',
  'UA6508003005': 'Великий Клин',
  'UA6508007002': 'Бабенківка Друга',
  'UA6508007003': 'Бабенківка Перша',
  'UA6508007005': 'Гаврилівка Друга',
  'UA6508007001': 'Каланчак',
  'UA6508007014': 'Хорли',
  'UA6508007006': 'Максима Горького',
  'UA6508007007': 'Новокиївка',
  'UA6508007008': 'Новоолександрівка',
  'UA6508007009': 'Новопавлівка',
  'UA6508007010': 'Олександрівка',
  'UA6508007011': 'Олексіївка',
  'UA6508007013': 'Приморське',
  'UA6508007012': 'Привілля',
  'UA6508007015': 'Роздольне',
  'UA6508007004': 'Вербове',
  'UA6508009003': 'Калинівка',
  'UA6508009001': 'Лазурне',
  'UA6508009004': 'Лиманське',
  'UA6508009005': 'Новоросійське',
  'UA6508009006': 'Новософіївка',
  'UA6508009007': 'Петрівка',
  'UA6508009002': 'Володимирівка',
  'UA6508011002': 'Каїрка',
  'UA6508011003': 'Макарівка',
  'UA6508011001': 'Мирне',
  'UA6508011004': 'Пам\'ятник',
  'UA6508011005': 'Польове',
  'UA6508011006': 'Преображенка',
  'UA6508011007': 'Ставки',
  'UA6508013002': 'Біленьке',
  'UA6508013003': 'Карабулат',
  'UA6508013004': 'Михайлівка',
  'UA6508013001': 'Новомиколаївка',
  'UA6508013005': 'Новосілка',
  'UA6508013006': 'Промінь',
  'UA6508013007': 'Труд',
  'UA6508015002': 'Андріївка',
  'UA6508015020': 'Антонівка',
  'UA6508015021': 'Благодатне',
  'UA6508015005': 'Дарівка',
  'UA6508015004': 'Гостроподолянське',
  'UA6508015023': 'Грушівка',
  'UA6508015017': 'Хатки',
  'UA6508015006': 'Красне',
  'UA6508015007': 'Мала Андронівка',
  'UA6508015008': 'Малоолександрівка',
  'UA6508015009': 'Миколаївка',
  'UA6508015010': 'Новоукраїнка',
  'UA6508015011': 'Олександрівка',
  'UA6508015025': 'Озерне',
  'UA6508015012': 'Петрівка',
  'UA6508015026': 'Петропавлівка',
  'UA6508015013': 'Приморське',
  'UA6508015014': 'Радісне',
  'UA6508015018': 'Шевченко',
  'UA6508015019': 'Широке',
  'UA6508015001': 'Скадовськ',
  'UA6508015027': 'Степне',
  'UA6508015015': 'Тарасівка',
  'UA6508015028': 'Таврія',
  'UA6508015016': 'Улянівка',
  'UA6508015003': 'Велика Андронівка',
  'UA6508015022': 'Вишневе',
  'UA6508015024': 'Зелене',
  'UA6802029002': 'Андріївка',
  'UA6802029003': 'Антонівка',
  'UA6802029004': 'Бережанка',
  'UA6802029005': 'Біла',
  'UA6802029041': 'Чагарівка',
  'UA6802029001': 'Чемерівці',
  'UA6802029042': 'Черче',
  'UA6802029043': 'Чорна',
  'UA6802029012': 'Демківці',
  'UA6802029013': 'Діброва',
  'UA6802029014': 'Драганівка',
  'UA6802029011': 'Гайове',
  'UA6802029040': 'Хропотова',
  'UA6802029022': 'Кочубіїв',
  'UA6802029021': 'Кормильча',
  'UA6802029023': 'Красноставці',
  'UA6802029024': 'Криків',
  'UA6802029025': 'Кугаївці',
  'UA6802029026': 'Летава',
  'UA6802029027': 'Мала Зелена',
  'UA6802029028': 'Михайлівка',
  'UA6802029029': 'Нова Гута',
  'UA6802029030': 'Нове Життя',
  'UA6802029031': 'Почапинці',
  'UA6802029032': 'Ружа',
  'UA6802029033': 'Садове',
  'UA6802029044': 'Шидлівці',
  'UA6802029035': 'Слобідка-Скипчанська',
  'UA6802029036': 'Слобідка-Смотрицька',
  'UA6802029037': 'Сокиринці',
  'UA6802029038': 'Степанівка',
  'UA6802029034': 'Свіршківці',
  'UA6802029039': 'Теремківці',
  'UA6802029006': 'Велика Зелена',
  'UA6802029010': 'Вікторівка',
  'UA6802029007': 'Вигода',
  'UA6802029008': 'Вишневе',
  'UA6802029009': 'Вишнівчик',
  'UA6802029046': 'Ямпільчик',
  'UA6802029045': 'Юрківці',
  'UA6802029017': 'Залісся',
  'UA6802029018': 'Залуччя',
  'UA6802029019': 'Зарічанка',
  'UA6802029016': 'Завадівка',
  'UA6802029020': 'Збриж',
  'UA6802029015': 'Жердя',
  'UA6802005002': 'Антонівка',
  'UA6802005048': 'Чаньків',
  'UA6802005049': 'Чимбарівка',
  'UA6802005014': 'Дем\'янківці',
  'UA6802005015': 'Держанівка',
  'UA6802005016': 'Дубинка',
  'UA6802005001': 'Дунаївці',
  'UA6802005008': 'Ганнівка',
  'UA6802005009': 'Гірчична',
  'UA6802005010': 'Голозубинці',
  'UA6802005011': 'Городиська',
  'UA6802005012': 'Гута-Блищанівська',
  'UA6802005013': 'Гута-Яцьковецька',
  'UA6802005021': 'Іванківці',
  'UA6802005022': 'Катеринівка',
  'UA6802005023': 'Кривчик',
  'UA6802005024': 'Ксаверівка',
  'UA6802005026': 'Ліпини',
  'UA6802005025': 'Лисець',
  'UA6802005027': 'Мала Кужелівка',
  'UA6802005028': 'Мала Побіянка',
  'UA6802005029': 'Малий Жванчик',
  'UA6802005031': 'Млаки',
  'UA6802005032': 'Мушкутинці',
  'UA6802005030': 'Миньківці',
  'UA6802005033': 'Нестерівці',
  'UA6802005034': 'Панасівка',
  'UA6802005035': 'Пільний Мукарів',
  'UA6802005036': 'Притулівка',
  'UA6802005038': 'Рачинці',
  'UA6802005037': 'Рахнівка',
  'UA6802005039': 'Руда-Гірчичнянська',
  'UA6802005042': 'Січинці',
  'UA6802005043': 'Слобідка-Гірчичнянська',
  'UA6802005044': 'Сокілець',
  'UA6802005045': 'Соснівка',
  'UA6802005046': 'Степок',
  'UA6802005041': 'Синяківці',
  'UA6802005040': 'Сивороги',
  'UA6802005047': 'Трибухівка',
  'UA6802005003': 'Велика Кужелева',
  'UA6802005004': 'Велика Побійна',
  'UA6802005005': 'Великий Жванчик',
  'UA6802005007': 'Воробіївка',
  'UA6802005006': 'Вихрівка',
  'UA6802005050': 'Ярова Слобідка',
  'UA6802005051': 'Яцьківці',
  'UA6802005017': 'Заголосна',
  'UA6802005018': 'Залісці',
  'UA6802005019': 'Заставля',
  'UA6802005020': 'Зеленче',
  'UA6802001002': 'Бурти',
  'UA6802001003': 'Долинівка',
  'UA6802001001': 'Гуків',
  'UA6802001005': 'Мала Бережанка',
  'UA6802001006': 'Мар\'янівка',
  'UA6802001008': 'П\'ятничани',
  'UA6802001007': 'Пукляки',
  'UA6802001004': 'Жабинці',
  'UA6802003002': 'Абрикосівка',
  'UA6802003006': 'Дубинка',
  'UA6802003007': 'Думанів',
  'UA6802003005': 'Голосків',
  'UA6802003001': 'Гуменці',
  'UA6802003009': 'Карачківці',
  'UA6802003011': 'Колубаївці',
  'UA6802003012': 'Корчівка',
  'UA6802003010': 'Киселівка',
  'UA6802003013': 'Лисогірка',
  'UA6802003014': 'Малозалісся',
  'UA6802003015': 'Нігин',
  'UA6802003016': 'Привороття Друге',
  'UA6802003017': 'Пудлівці',
  'UA6802003023': 'Сахкамінь',
  'UA6802003018': 'Слобідка-Гуменецька',
  'UA6802003019': 'Супрунківці',
  'UA6802003020': 'Тернавка',
  'UA6802003022': 'Цикова',
  'UA6802003021': 'Улянівка',
  'UA6802003003': 'Великозалісся',
  'UA6802003004': 'Вербка',
  'UA6802003008': 'Залісся Друге',
  'UA6802011013': 'Червона Чагарівка',
  'UA6802011003': 'Довжок',
  'UA6802011001': 'Кам\'янець-Подільський',
  'UA6802011012': 'Ходорівці',
  'UA6802011005': 'Княгинин',
  'UA6802011006': 'Колибаївка',
  'UA6802011007': 'Лісківці',
  'UA6802011008': 'Нагоряни',
  'UA6802011009': 'Острівчани',
  'UA6802011010': 'Рихта',
  'UA6802011011': 'Смотрич',
  'UA6802011002': 'Вільховець',
  'UA6802011004': 'Зіньківці',
  'UA6802013002': 'Броварі',
  'UA6802013005': 'Демшин',
  'UA6802013006': 'Дерев\'яне',
  'UA6802013004': 'Гелетина',
  'UA6802013007': 'Калачківці',
  'UA6802013008': 'Колодіївка',
  'UA6802013001': 'Китайгород',
  'UA6802013009': 'Ленівка',
  'UA6802013010': 'Патринці',
  'UA6802013011': 'Привітне',
  'UA6802013012': 'Рогізна',
  'UA6802013013': 'Субіч',
  'UA6802013003': 'Вихватнівці',
  'UA6802015002': 'Блищанівка',
  'UA6802015007': 'Чечельник',
  'UA6802015001': 'Маків',
  'UA6802015003': 'Михайлівка',
  'UA6802015008': 'Шатава',
  'UA6802015004': 'Слобідка-Балинська',
  'UA6802015006': 'Слобідка-Рахнівська',
  'UA6802015005': 'Слобідка-Залісецька',
  'UA6802017001': 'Дунаївці',
  'UA6802017003': 'Гамарня',
  'UA6802017004': 'Гута-Морозівська',
  'UA6802017018': 'Харитонівка',
  'UA6802017005': 'Лошківці',
  'UA6802017007': 'Маліївці',
  'UA6802017006': 'Малий Карабчіїв',
  'UA6802017008': 'Міцівці',
  'UA6802017009': 'Морозів',
  'UA6802017010': 'Підлісний Мукарів',
  'UA6802017011': 'Слобідка-Малієвецька',
  'UA6802017012': 'Сприсівка',
  'UA6802017013': 'Ставище',
  'UA6802017014': 'Тернова',
  'UA6802017016': 'Томашівка',
  'UA6802017015': 'Тинна',
  'UA6802017017': 'Удріївці',
  'UA6802017002': 'Варварівка',
  'UA6802019002': 'Антонівка',
  'UA6802019003': 'Балабанівка',
  'UA6802019004': 'Березівка',
  'UA6802019005': 'Борсуки',
  'UA6802019006': 'Браїлівка',
  'UA6802019007': 'Бучая',
  'UA6802019016': 'Джуржівка',
  'UA6802019052': 'Філянівка',
  'UA6802019013': 'Глібів',
  'UA6802019011': 'Глибівка',
  'UA6802019012': 'Глибочок',
  'UA6802019014': 'Губарів',
  'UA6802019015': 'Гута-Глібівська',
  'UA6802019022': 'Іванівка',
  'UA6802019023': 'Іванківці',
  'UA6802019024': 'Івашківці',
  'UA6802019025': 'Капустяни',
  'UA6802019026': 'Каскада',
  'UA6802019054': 'Хребтіїв',
  'UA6802019053': 'Хворосна',
  'UA6802019027': 'Косиківці',
  'UA6802019028': 'Кружківці',
  'UA6802019030': 'Куча',
  'UA6802019029': 'Куражин',
  'UA6802019031': 'Любомирівка',
  'UA6802019033': 'Мала Щурка',
  'UA6802019032': 'Мала Стружка',
  'UA6802019034': 'Маціорськ',
  'UA6802019035': 'Миржіївка',
  'UA6802019036': 'Нова Гута',
  'UA6802019001': 'Нова Ушиця',
  'UA6802019037': 'Новий Глібів',
  'UA6802019038': 'Отроків',
  'UA6802019039': 'Песець',
  'UA6802019042': 'Пилипківці',
  'UA6802019041': 'Пилипи-Хребтіївські',
  'UA6802019040': 'Пижівка',
  'UA6802019043': 'Рудківці',
  'UA6802019044': 'Садове',
  'UA6802019058': 'Щербівці',
  'UA6802019056': 'Шебутинці',
  'UA6802019057': 'Шелестяни',
  'UA6802019045': 'Слобідка',
  'UA6802019046': 'Слобода',
  'UA6802019047': 'Соколівка',
  'UA6802019049': 'Стара Гута',
  'UA6802019048': 'Ставчани',
  'UA6802019050': 'Струга',
  'UA6802019055': 'Цівківці',
  'UA6802019051': 'Тимків',
  'UA6802019008': 'Вахнівці',
  'UA6802019010': 'Вільховець',
  'UA6802019009': 'Виселок',
  'UA6802019018': 'Заборознівці',
  'UA6802019019': 'Загоряни',
  'UA6802019059': 'Загродське',
  'UA6802019020': 'Заміхів',
  'UA6802019021': 'Зелені Курилівці',
  'UA6802019017': 'Жабинці',
  'UA6802021002': 'Адамівка',
  'UA6802021016': 'Чорнокозинці',
  'UA6802021003': 'Добровілля',
  'UA6802021005': 'Кадиївці',
  'UA6802021006': 'Кізя',
  'UA6802021007': 'Ніверка',
  'UA6802021008': 'Нововолодимирівка',
  'UA6802021001': 'Оринин',
  'UA6802021009': 'Параївка',
  'UA6802021010': 'Підпилип\'я',
  'UA6802021011': 'Подоляни',
  'UA6802021012': 'Привороття',
  'UA6802021013': 'Ріпинці',
  'UA6802021017': 'Шустівці',
  'UA6802021014': 'Суржа',
  'UA6802021015': 'Теклівка',
  'UA6802021004': 'Залісся Перше',
  'UA6802023002': 'Баговиця',
  'UA6802023003': 'Боришківці',
  'UA6802023019': 'Фурманівка',
  'UA6802023007': 'Калиня',
  'UA6802023008': 'Кам\'янка',
  'UA6802023009': 'Княжпіль',
  'UA6802023010': 'Кульчіївці',
  'UA6802023011': 'Мала Слобідка',
  'UA6802023012': 'Мукша Китайгородська',
  'UA6802023013': 'Оленівка',
  'UA6802023014': 'Панівці',
  'UA6802023022': 'Шутнівці',
  'UA6802023001': 'Слобідка-Кульчієвецька',
  'UA6802023015': 'Станіславівка',
  'UA6802023016': 'Суржинці',
  'UA6802023017': 'Тарасівка',
  'UA6802023020': 'Цвіклівці Другі',
  'UA6802023021': 'Цибулівка',
  'UA6802023018': 'Устя',
  'UA6802023004': 'Велика Слобідка',
  'UA6802023005': 'Врублівці',
  'UA6802023023': 'Яруга',
  'UA6802023006': 'Зубрівка',
  'UA6802025002': 'Балин',
  'UA6802025003': 'Балинівка',
  'UA6802025004': 'Криничани',
  'UA6802025005': 'Лисогірка',
  'UA6802025006': 'Михівка',
  'UA6802025007': 'Ріпинці',
  'UA6802025008': 'Рудка',
  'UA6802025001': 'Смотрич',
  'UA6802025009': 'Стара Гута',
  'UA6802027013': 'Чабанівка',
  'UA6802027002': 'Гораївка',
  'UA6802027003': 'Грушка',
  'UA6802027004': 'Гута-Чугорська',
  'UA6802027005': 'Каштанівка',
  'UA6802027006': 'Крушанівка',
  'UA6802027009': 'Лучки',
  'UA6802027007': 'Липи',
  'UA6802027008': 'Лисківці',
  'UA6802027010': 'Нефедівці',
  'UA6802027011': 'Подільське',
  'UA6802027012': 'Рункошів',
  'UA6802027001': 'Стара Ушиця',
  'UA6802009002': 'Боднарівка',
  'UA6802009007': 'Дубівка',
  'UA6802009005': 'Голенищеве',
  'UA6802009006': 'Гусятин',
  'UA6802009008': 'Івахнівці',
  'UA6802009011': 'Кутківці',
  'UA6802009010': 'Кузьминчик',
  'UA6802009012': 'Лисогірка',
  'UA6802009013': 'Романівка',
  'UA6802009014': 'Рудка',
  'UA6802009004': 'Вільхівці',
  'UA6802009003': 'Вівся',
  'UA6802009009': 'Йосипівка',
  'UA6802009001': 'Закупне',
  'UA6802007002': 'Бабшин',
  'UA6802007003': 'Брага',
  'UA6802007023': 'Червона Діброва',
  'UA6802007006': 'Гаврилівці',
  'UA6802007007': 'Гринчук',
  'UA6802007010': 'Ісаківці',
  'UA6802007011': 'Каветчина',
  'UA6802007012': 'Кізя-Кудринецька',
  'UA6802007013': 'Кудринці',
  'UA6802007014': 'Ластівці',
  'UA6802007015': 'Малинівці',
  'UA6802007016': 'Межигір',
  'UA6802007017': 'Мілівці',
  'UA6802007018': 'Руда',
  'UA6802007019': 'Слобідка-Малиновецька',
  'UA6802007020': 'Слобідка-Рихтівська',
  'UA6802007021': 'Сокіл',
  'UA6802007022': 'Цвіклівці Перші',
  'UA6802007004': 'Вільне',
  'UA6802007005': 'Вітківці',
  'UA6802007008': 'Завалля',
  'UA6802007009': 'Збруч',
  'UA6802007001': 'Жванець',
  'UA6804001001': 'Антоніни',
  'UA6804001008': 'Долинівці',
  'UA6804001032': 'Федорівка',
  'UA6804001007': 'Гриценки',
  'UA6804001011': 'Ключівка',
  'UA6804001012': 'Корчівка',
  'UA6804001013': 'Красівка',
  'UA6804001014': 'Кременчуки',
  'UA6804001015': 'Криворудка',
  'UA6804001016': 'Кучманівка',
  'UA6804001017': 'Ледянка',
  'UA6804001018': 'Лісова Волиця',
  'UA6804001019': 'Мала Салиха',
  'UA6804001020': 'Маленки',
  'UA6804001021': 'Малі Пузирки',
  'UA6804001022': 'Малі Юначки',
  'UA6804001023': 'Медці',
  'UA6804001024': 'Нова Криворудка',
  'UA6804001025': 'Нові Терешки',
  'UA6804001026': 'Решнівка',
  'UA6804001027': 'Рублянка',
  'UA6804001028': 'Севрюки',
  'UA6804001029': 'Терешки',
  'UA6804001030': 'Тріски',
  'UA6804001031': 'Трусилівка',
  'UA6804001002': 'Васьківчики',
  'UA6804001003': 'Велика Медведівка',
  'UA6804001004': 'Велика Салиха',
  'UA6804001005': 'Великі Орлинці',
  'UA6804001006': 'Великі Юначки',
  'UA6804001034': 'Якимівці',
  'UA6804001033': 'Юзіно',
  'UA6804001009': 'Закриниччя',
  'UA6804001010': 'Зелена',
  'UA6804049002': 'Антонівка',
  'UA6804049003': 'Бережанка',
  'UA6804049001': 'Чорний Острів',
  'UA6804049006': 'Грузевиця',
  'UA6804049008': 'Катеринівка',
  'UA6804049009': 'Крачки',
  'UA6804049010': 'Лапківці',
  'UA6804049011': 'Ляпинці',
  'UA6804049012': 'Малі Орлинці',
  'UA6804049013': 'Манилівка',
  'UA6804049015': 'Мар\'янівка',
  'UA6804049014': 'Мартинівка',
  'UA6804049016': 'Миколаїв',
  'UA6804049017': 'Осташки',
  'UA6804049018': 'Педоси',
  'UA6804049019': 'Польові Гринівці',
  'UA6804049020': 'Рідкодуби',
  'UA6804049021': 'Ставчинці',
  'UA6804049004': 'Везденьки',
  'UA6804049005': 'Вовча Гора',
  'UA6804049007': 'Захарівці',
  'UA6804015003': 'Адамівка',
  'UA6804015005': 'Бомкове',
  'UA6804015004': 'Божиківці',
  'UA6804015006': 'Буцневе',
  'UA6804015032': 'Черешенька',
  'UA6804015001': 'Деражня',
  'UA6804015008': 'Галузинці',
  'UA6804015009': 'Гатна',
  'UA6804015010': 'Гута',
  'UA6804015013': 'Іванківці',
  'UA6804015014': 'Кальна',
  'UA6804015015': 'Копачівка',
  'UA6804015016': 'Коржівці',
  'UA6804015017': 'Красносілка',
  'UA6804015018': 'Літки',
  'UA6804015002': 'Лозове',
  'UA6804015020': 'Маниківці',
  'UA6804015021': 'Марківка',
  'UA6804015019': 'Мазники',
  'UA6804015023': 'Новосілка',
  'UA6804015022': 'Нижнє',
  'UA6804015024': 'Осикове',
  'UA6804015026': 'Підлісне',
  'UA6804015025': 'Пилипи',
  'UA6804015027': 'Розсохи',
  'UA6804015033': 'Шарки',
  'UA6804015034': 'Шелехове',
  'UA6804015036': 'Шпичинці',
  'UA6804015035': 'Шиїнці',
  'UA6804015028': 'Сіноводи',
  'UA6804015029': 'Слобідка',
  'UA6804015030': 'Слобідка-Шелехівська',
  'UA6804015031': 'Теперівка',
  'UA6804015007': 'Волоське',
  'UA6804015037': 'Яблунівка',
  'UA6804015039': 'Яськівці',
  'UA6804015038': 'Явтухи',
  'UA6804015011': 'Загінці',
  'UA6804015012': 'Зяньківці',
  'UA6804013002': 'Балакири',
  'UA6804013003': 'Басівка',
  'UA6804013004': 'Бедриківці',
  'UA6804013005': 'Борщівка',
  'UA6804013046': 'Чорниводи',
  'UA6804013011': 'Дахнівка',
  'UA6804013001': 'Городок',
  'UA6804013010': 'Грицьків',
  'UA6804013014': 'Іванівка',
  'UA6804013015': 'Калитинці',
  'UA6804013045': 'Хмелівка',
  'UA6804013016': 'Ковалівка',
  'UA6804013018': 'Кремінянські Хутори',
  'UA6804013017': 'Кремінна',
  'UA6804013020': 'Купин',
  'UA6804013019': 'Кузьмин',
  'UA6804013022': 'Лісогірка',
  'UA6804013021': 'Лісоводи',
  'UA6804013048': 'Лісоводи',
  'UA6804013023': 'Мала Левада',
  'UA6804013024': 'Мала Яромирка',
  'UA6804013025': 'Матвійківці',
  'UA6804013026': 'Мудриголови',
  'UA6804013027': 'Немиринці',
  'UA6804013028': 'Нова Пісочна',
  'UA6804013029': 'Нове Поріччя',
  'UA6804013031': 'Новосілка',
  'UA6804013030': 'Новий Світ',
  'UA6804013032': 'Остапківці',
  'UA6804013033': 'Папірня',
  'UA6804013034': 'Підлісний Олексинець',
  'UA6804013035': 'Пільний Олексинець',
  'UA6804013036': 'Радковиця',
  'UA6804013047': 'Шишківці',
  'UA6804013038': 'Скіпче',
  'UA6804013039': 'Слобідка-Кузьминська',
  'UA6804013040': 'Слобідка-Олексинецька',
  'UA6804013041': 'Стара Пісочна',
  'UA6804013042': 'Старе Поріччя',
  'UA6804013037': 'Сирватинці',
  'UA6804013043': 'Тростянець',
  'UA6804013044': 'Турчинці',
  'UA6804013006': 'Варівці',
  'UA6804013007': 'Велика Левада',
  'UA6804013008': 'Велика Яромирка',
  'UA6804013009': 'Великий Карабчіїв',
  'UA6804013013': 'Завадинці',
  'UA6804013012': 'Жищинці',
  'UA6804011011': 'Чабани',
  'UA6804011003': 'Данюки',
  'UA6804011004': 'Доброгорща',
  'UA6804011002': 'Гелетинці',
  'UA6804011001': 'Гвардійське',
  'UA6804011010': 'Хоминці',
  'UA6804011006': 'Нафтулівка',
  'UA6804011007': 'Немичинці',
  'UA6804011008': 'Олешківці',
  'UA6804011009': 'Райківці',
  'UA6804011005': 'Жучківці',
  'UA6804047002': 'Бахматівці',
  'UA6804047003': 'Березове',
  'UA6804047004': 'Богданівці',
  'UA6804047025': 'Богданівці',
  'UA6804047022': 'Черепівка',
  'UA6804047023': 'Черепова',
  'UA6804047008': 'Давидківці',
  'UA6804047009': 'Іванківці',
  'UA6804047010': 'Івашківці',
  'UA6804047001': 'Хмельницький',
  'UA6804047011': 'Климківці',
  'UA6804047012': 'Колибань',
  'UA6804047013': 'Копистин',
  'UA6804047014': 'Мала Колибань',
  'UA6804047015': 'Малашівці',
  'UA6804047016': 'Масівці',
  'UA6804047017': 'Мацьківці',
  'UA6804047018': 'Олешин',
  'UA6804047019': 'Пархомівці',
  'UA6804047021': 'Прибузьке',
  'UA6804047020': 'Пирогівці',
  'UA6804047024': 'Шаровечка',
  'UA6804047005': 'Велика Калинівка',
  'UA6804047006': 'Водички',
  'UA6804047007': 'Волиця',
  'UA6804021002': 'Баглайки',
  'UA6804021003': 'Берегелі',
  'UA6804021029': 'Чепелівка',
  'UA6804021030': 'Чернелівка',
  'UA6804021009': 'Дружне',
  'UA6804021010': 'Дубина',
  'UA6804021011': 'Дубище',
  'UA6804021008': 'Грицики',
  'UA6804021028': 'Хотьківці',
  'UA6804021001': 'Красилів',
  'UA6804021016': 'Кульчинки',
  'UA6804021015': 'Кульчини',
  'UA6804021017': 'Лагодинці',
  'UA6804021018': 'Манівці',
  'UA6804021020': 'Мончинці',
  'UA6804021021': 'Моньки',
  'UA6804021019': 'Митинці',
  'UA6804021022': 'Новодубище',
  'UA6804021023': 'Пашутинці',
  'UA6804021024': 'Печеське',
  'UA6804021025': 'Пилипи',
  'UA6804021026': 'Слобідка-Красилівська',
  'UA6804021027': 'Сорокодуби',
  'UA6804021004': 'Вереміївка',
  'UA6804021005': 'Веселівка',
  'UA6804021006': 'Волиця',
  'UA6804021007': 'Воскодавинці',
  'UA6804021031': 'Яворівці',
  'UA6804021012': 'Западинці',
  'UA6804021013': 'Заруддя',
  'UA6804021014': 'Заставки',
  'UA6804023003': 'Анютине',
  'UA6804023002': 'Антонівка',
  'UA6804023004': 'Білецьке',
  'UA6804023005': 'Бохни',
  'UA6804023006': 'Буцні',
  'UA6804023043': 'Чапля',
  'UA6804023009': 'Голенищеве',
  'UA6804023010': 'Горбасів',
  'UA6804023011': 'Гречинці',
  'UA6804023012': 'Грушківці',
  'UA6804023013': 'Іванинці',
  'UA6804023015': 'Копитинці',
  'UA6804023014': 'Козачки',
  'UA6804023016': 'Кудинка',
  'UA6804023001': 'Летичів',
  'UA6804023017': 'Лісо-Березівка',
  'UA6804023018': 'Лозни',
  'UA6804023019': 'Майдан',
  'UA6804023021': 'Майдан-Голенищівський',
  'UA6804023022': 'Майдан-Сахнівський',
  'UA6804023020': 'Майдан-Вербецький',
  'UA6804023023': 'Малаківщина',
  'UA6804023024': 'Марківці',
  'UA6804023026': 'Москалівка',
  'UA6804023025': 'Михунки',
  'UA6804023027': 'Нова Гута',
  'UA6804023028': 'Новокостянтинів',
  'UA6804023029': 'Новомиколаївка',
  'UA6804023030': 'Подільське',
  'UA6804023031': 'Попівці',
  'UA6804023032': 'Прилужне',
  'UA6804023033': 'Ревуха',
  'UA6804023036': 'Російська Буда',
  'UA6804023034': 'Рожни',
  'UA6804023035': 'Розсохувата',
  'UA6804023037': 'Рудня',
  'UA6804023038': 'Сахни',
  'UA6804023040': 'Снітівка',
  'UA6804023041': 'Суслівці',
  'UA6804023039': 'Свічна',
  'UA6804023042': 'Терлівка',
  'UA6804023007': 'Варенка',
  'UA6804023008': 'Вербка',
  'UA6804023045': 'Ялинівка',
  'UA6804023044': 'Юрченки',
  'UA6804025002': 'Аркадіївці',
  'UA6804025003': 'Гнатівці',
  'UA6804025013': 'Ходаківці',
  'UA6804025004': 'Климашівка',
  'UA6804025001': 'Лісові Гринівці',
  'UA6804025005': 'Моломолинці',
  'UA6804025006': 'Пашківці',
  'UA6804025007': 'Печеськи',
  'UA6804025008': 'Редвинці',
  'UA6804025014': 'Шпичинці',
  'UA6804025009': 'Скаржинці',
  'UA6804025010': 'Стуфчинці',
  'UA6804025011': 'Терешівці',
  'UA6804025012': 'Тиранівка',
  'UA6804027004': 'Голосків',
  'UA6804027003': 'Головчинці',
  'UA6804027006': 'Лисогірка',
  'UA6804027001': 'Меджибіж',
  'UA6804027007': 'Митківці',
  'UA6804027008': 'Русанівці',
  'UA6804027011': 'Шрубків',
  'UA6804027009': 'Ставниця',
  'UA6804027010': 'Требухівці',
  'UA6804027002': 'Волосівці',
  'UA6804027012': 'Ярославка',
  'UA6804027005': 'Западинці',
  'UA6804029002': 'Берегелі',
  'UA6804029005': 'Деркачі',
  'UA6804029006': 'Ілляшівка',
  'UA6804029007': 'Кантівка',
  'UA6804029008': 'Круглики',
  'UA6804029009': 'Морозівка',
  'UA6804029001': 'Миролюбне',
  'UA6804029010': 'Немиринці',
  'UA6804029011': 'Новоселиця',
  'UA6804029012': 'Підгірне',
  'UA6804029013': 'Самчинці',
  'UA6804029014': 'Семиреньки',
  'UA6804029015': 'Сковородки',
  'UA6804029003': 'Верхняки',
  'UA6804029004': 'Веселе',
  'UA6804031002': 'Баглаї',
  'UA6804031003': 'Березина',
  'UA6804031004': 'Бубнівка',
  'UA6804031014': 'Чернява',
  'UA6804031006': 'Дзеленці',
  'UA6804031005': 'Глядки',
  'UA6804031007': 'Копачівка Друга',
  'UA6804031008': 'Кущівка',
  'UA6804031009': 'Личівка',
  'UA6804031001': 'Наркевичі',
  'UA6804031010': 'Новоленськ',
  'UA6804031011': 'Пахутинці',
  'UA6804031012': 'Сергіївка',
  'UA6804031015': 'Шмирки',
  'UA6804031013': 'Трительники',
  'UA6804031016': 'Юхимівці',
  'UA6804033002': 'Андрійківці',
  'UA6804033003': 'Баламутівка',
  'UA6804033007': 'Іванівка',
  'UA6804033008': 'Іванківці',
  'UA6804033009': 'Карпівці',
  'UA6804033010': 'Королівка',
  'UA6804033011': 'Кудринці',
  'UA6804033012': 'Лехнівка',
  'UA6804033013': 'Лугове',
  'UA6804033014': 'Лука',
  'UA6804033015': 'Малиничі',
  'UA6804033019': 'Монастирок',
  'UA6804033017': 'Михайлівка',
  'UA6804033018': 'Михалківці',
  'UA6804033016': 'Микитинці',
  'UA6804033020': 'Нижчі Вовківці',
  'UA6804033021': 'Перегінка',
  'UA6804033001': 'Розсоша',
  'UA6804033023': 'Ружичанка',
  'UA6804033022': 'Рижулинці',
  'UA6804033026': 'Шумівці',
  'UA6804033024': 'Скаржинці',
  'UA6804033025': 'Слобідка',
  'UA6804033004': 'Видошня',
  'UA6804033005': 'Виноградівка',
  'UA6804033006': 'Вищі Вовківці',
  'UA6804035002': 'Андріївка',
  'UA6804035003': 'Бубнівка',
  'UA6804035009': 'Іванківці',
  'UA6804035010': 'Калинівка',
  'UA6804035011': 'Кам\'янка',
  'UA6804035025': 'Хоптинці',
  'UA6804035012': 'Клинове',
  'UA6804035013': 'Кринцілів',
  'UA6804035014': 'Куманів',
  'UA6804035015': 'Курівка',
  'UA6804035017': 'Ліпибоки',
  'UA6804035016': 'Липівка',
  'UA6804035018': 'Мартинківці',
  'UA6804035019': 'Олександрівка',
  'UA6804035020': 'Підлісний Веселець',
  'UA6804035021': 'Покровка',
  'UA6804035001': 'Сатанів',
  'UA6804035022': 'Сатанівка',
  'UA6804035023': 'Спасівка',
  'UA6804035024': 'Тарасівка',
  'UA6804035004': 'Веселець',
  'UA6804035005': 'Вигнанка',
  'UA6804035026': 'Юринці',
  'UA6804035006': 'Жаглівка',
  'UA6804035007': 'Журавлинці',
  'UA6804035008': 'Зверхівці',
  'UA6804051002': 'Каламаринка',
  'UA6804051003': 'Кузьмин',
  'UA6804051006': 'Мотрунки',
  'UA6804051005': 'Мовчани',
  'UA6804051004': 'Михайлівці',
  'UA6804051007': 'Радісне',
  'UA6804051001': 'Щиборівка',
  'UA6804051008': 'Сушки',
  'UA6804037002': 'Андріївка',
  'UA6804037003': 'Глушківці',
  'UA6804037004': 'Глушковецьке',
  'UA6804037005': 'Корначівка',
  'UA6804037006': 'Майдан-Морозівський',
  'UA6804037007': 'Проскурівка',
  'UA6804037008': 'Слобідка-Глушковецька',
  'UA6804037001': 'Солобківці',
  'UA6804037009': 'Стріхівці',
  'UA6804037010': 'Тарасівка',
  'UA6804039002': 'Андронівка',
  'UA6804039003': 'Баглаї',
  'UA6804039004': 'Березне',
  'UA6804039005': 'Бовкуни',
  'UA6804039006': 'Бутівці',
  'UA6804039066': 'Червона Семенівка',
  'UA6804039019': 'Демківці',
  'UA6804039020': 'Драчі',
  'UA6804039021': 'Дубина',
  'UA6804039014': 'Гнатки',
  'UA6804039017': 'Громівка',
  'UA6804039015': 'Грибенинка',
  'UA6804039016': 'Григорівка',
  'UA6804039018': 'Губча',
  'UA6804039027': 'Іршики',
  'UA6804039028': 'Капустин',
  'UA6804039029': 'Караїмівка',
  'UA6804039065': 'Хутори',
  'UA6804039064': 'Хижники',
  'UA6804039031': 'Костянець',
  'UA6804039032': 'Красносілка',
  'UA6804039034': 'Круча',
  'UA6804039033': 'Криниця',
  'UA6804039035': 'Кучівка',
  'UA6804039030': 'Киселі',
  'UA6804039037': 'Ланок',
  'UA6804039036': 'Лажева',
  'UA6804039038': 'Лисинці',
  'UA6804039041': 'Малі Мацевичі',
  'UA6804039042': 'Мальки',
  'UA6804039039': 'Малий Чернятин',
  'UA6804039040': 'Малишівка',
  'UA6804039043': 'Нападівка',
  'UA6804039044': 'Немирівка',
  'UA6804039045': 'Огіївці',
  'UA6804039046': 'Оріхівка',
  'UA6804039047': 'Партинці',
  'UA6804039048': 'Пашківці',
  'UA6804039049': 'Пеньки',
  'UA6804039050': 'Першотравневе',
  'UA6804039053': 'Половинники',
  'UA6804039054': 'Попівці',
  'UA6804039055': 'Прохорівка',
  'UA6804039052': 'Пихтії',
  'UA6804039051': 'Писарівка',
  'UA6804039056': 'Радківці',
  'UA6804039057': 'Раштівка',
  'UA6804039058': 'Решнівка',
  'UA6804039059': 'Росолівці',
  'UA6804039061': 'Сахнівці',
  'UA6804039060': 'Самчики',
  'UA6804039001': 'Старокостянтинів',
  'UA6804039062': 'Степок',
  'UA6804039063': 'Стецьки',
  'UA6804039008': 'Великі Мацевичі',
  'UA6804039007': 'Великий Чернятин',
  'UA6804039009': 'Вербівочка',
  'UA6804039010': 'Вербородинці',
  'UA6804039011': 'Веснянка',
  'UA6804039012': 'Волиця-Керекешина',
  'UA6804039013': 'Воронківці',
  'UA6804039067': 'Яремичі',
  'UA6804039022': 'Ємці',
  'UA6804039024': 'Загірне',
  'UA6804039025': 'Залісся',
  'UA6804039026': 'Зеленці',
  'UA6804039023': 'Жабче',
  'UA6804041016': 'Чорна',
  'UA6804041003': 'Губин',
  'UA6804041005': 'Калинівка',
  'UA6804041006': 'Коржівка',
  'UA6804041007': 'Ладиги',
  'UA6804041008': 'Левківка',
  'UA6804041009': 'Лісове',
  'UA6804041012': 'Махаринці',
  'UA6804041010': 'Малий Вишнопіль',
  'UA6804041011': 'Мартинівка',
  'UA6804041013': 'Райки',
  'UA6804041015': 'Сербинівка',
  'UA6804041014': 'Северини',
  'UA6804041017': 'Шевченка',
  'UA6804041001': 'Старий Остропіль',
  'UA6804041002': 'Вишнопіль',
  'UA6804041004': 'Йосипівка',
  'UA6804043002': 'Адампіль',
  'UA6804043003': 'Бабине',
  'UA6804043004': 'Буглаї',
  'UA6804043043': 'Чехи',
  'UA6804043008': 'Дашківці',
  'UA6804043009': 'Дубова',
  'UA6804043006': 'Гончариха',
  'UA6804043007': 'Гречана',
  'UA6804043014': 'Ілятка',
  'UA6804043012': 'Іванківці',
  'UA6804043013': 'Івки',
  'UA6804043016': 'Карпівці',
  'UA6804043040': 'Харківці',
  'UA6804043041': 'Хутір Дашківський',
  'UA6804043017': 'Красносілка',
  'UA6804043018': 'Липки',
  'UA6804043019': 'Лисанівці',
  'UA6804043022': 'Мшанець',
  'UA6804043020': 'Миколаївка',
  'UA6804043021': 'Мисюрівка',
  'UA6804043023': 'Нова Синявка',
  'UA6804043025': 'Олександрівка',
  'UA6804043026': 'Олексіївка',
  'UA6804043024': 'Ожарівка',
  'UA6804043027': 'Паньківці',
  'UA6804043028': 'Паплинці',
  'UA6804043029': 'Пасічна',
  'UA6804043030': 'Перекора',
  'UA6804043034': 'Подоляни',
  'UA6804043031': 'Пилява',
  'UA6804043032': 'Пилявка',
  'UA6804043033': 'Пишки',
  'UA6804043035': 'Рідкодуб',
  'UA6804043044': 'Щербані',
  'UA6804043036': 'Сьомаки',
  'UA6804043001': 'Стара Синява',
  'UA6804043037': 'Теліжинці',
  'UA6804043038': 'Травневе',
  'UA6804043042': 'Цимбалівка',
  'UA6804043039': 'Уласово-Русанівка',
  'UA6804043005': 'Вишневе',
  'UA6804043045': 'Яблунівка',
  'UA6804043015': 'Йосипівка',
  'UA6804043010': 'Залісся',
  'UA6804043011': 'Заставці',
  'UA6804045003': 'Антонівка',
  'UA6804045002': 'Базалія',
  'UA6804045004': 'Бережинці',
  'UA6804045005': 'Борщівка',
  'UA6804045051': 'Червона Дубина',
  'UA6804045052': 'Червоне',
  'UA6804045053': 'Червоний Случ',
  'UA6804045054': 'Човгузів',
  'UA6804045015': 'Дмитрівка',
  'UA6804045013': 'Гаївка',
  'UA6804045014': 'Гальчинці',
  'UA6804045012': 'Гаврилівка',
  'UA6804045018': 'Ільківці',
  'UA6804045019': 'Карабіївка',
  'UA6804045020': 'Караїна',
  'UA6804045021': 'Колісець',
  'UA6804045022': 'Колки',
  'UA6804045023': 'Коров\'є',
  'UA6804045024': 'Котюржинці',
  'UA6804045025': 'Кривовілька',
  'UA6804045027': 'Кунча',
  'UA6804045026': 'Кузьминці',
  'UA6804045029': 'Лідихівка',
  'UA6804045030': 'Лютарівка',
  'UA6804045028': 'Лисогірка',
  'UA6804045031': 'Майдан-Петрівський',
  'UA6804045033': 'Малі Жеребки',
  'UA6804045032': 'Малий Лазучин',
  'UA6804045034': 'Мар\'янівка',
  'UA6804045035': 'Медисівка',
  'UA6804045037': 'Михнівка',
  'UA6804045036': 'Михиринці',
  'UA6804045038': 'Немиринці',
  'UA6804045039': 'Новоіванівка',
  'UA6804045040': 'Новоставці',
  'UA6804045041': 'Олійники',
  'UA6804045042': 'Ординці',
  'UA6804045043': 'Підліски',
  'UA6804045044': 'Поляхова',
  'UA6804045045': 'Рідка',
  'UA6804045046': 'Романів',
  'UA6804045055': 'Шибена',
  'UA6804045048': 'Строки',
  'UA6804045047': 'Святець',
  'UA6804045001': 'Теофіполь',
  'UA6804045049': 'Троянівка',
  'UA6804045050': 'Турівка',
  'UA6804045006': 'Василівка',
  'UA6804045007': 'Великий Лазучин',
  'UA6804045009': 'Волиця',
  'UA6804045010': 'Волиця-Польова',
  'UA6804045011': 'Воронівці',
  'UA6804045008': 'Вовківці',
  'UA6804045016': 'Єлизаветпіль',
  'UA6804045017': 'Заруддя',
  'UA6804003002': 'Бокиївка',
  'UA6804003003': 'Бронівка',
  'UA6804003005': 'Гайдайки',
  'UA6804003008': 'Качуринці',
  'UA6804003010': 'Криштопівка',
  'UA6804003009': 'Кривачинці',
  'UA6804003011': 'Купіль',
  'UA6804003012': 'Куровечка',
  'UA6804003013': 'Медведівка',
  'UA6804003014': 'Мочулинці',
  'UA6804003015': 'Павликівці',
  'UA6804003016': 'Петриківці',
  'UA6804003018': 'Порохня',
  'UA6804003017': 'Писарівка',
  'UA6804003019': 'Сарнів',
  'UA6804003001': 'Війтівці',
  'UA6804003004': 'Видава',
  'UA6804003006': 'Завалійки',
  'UA6804003007': 'Зелена',
  'UA6804005002': 'Балки',
  'UA6804005003': 'Бистриця',
  'UA6804005010': 'Дашківці',
  'UA6804005011': 'Дружба',
  'UA6804005029': 'Фащіївка',
  'UA6804005007': 'Гоголі',
  'UA6804005006': 'Говори',
  'UA6804005008': 'Гринева',
  'UA6804005009': 'Гута',
  'UA6804005014': 'Калюсик',
  'UA6804005015': 'Карачіївці',
  'UA6804005016': 'Карижин',
  'UA6804005017': 'Ломачинці',
  'UA6804005018': 'Майдан-Карачієвецький',
  'UA6804005019': 'Майдан-Олександрівський',
  'UA6804005020': 'Нетечинці',
  'UA6804005022': 'Охрімівці',
  'UA6804005021': 'Осламів',
  'UA6804005023': 'Петрашівка',
  'UA6804005026': 'Подолянське',
  'UA6804005024': 'Пилипи-Олександрівські',
  'UA6804005025': 'Пирогівка',
  'UA6804005027': 'Славута',
  'UA6804005028': 'Слобідка-Охрімовецька',
  'UA6804005004': 'Великий Олександрів',
  'UA6804005001': 'Віньківці',
  'UA6804005005': 'Виселок',
  'UA6804005030': 'Яснозір\'я',
  'UA6804005012': 'Женишківці',
  'UA6804005013': 'Зоряне',
  'UA6804009002': 'Авратин',
  'UA6804009003': 'Бальківці',
  'UA6804009004': 'Богданівка',
  'UA6804009005': 'Бутівці',
  'UA6804009049': 'Червоний Кут',
  'UA6804009050': 'Червоний Острів',
  'UA6804009051': 'Чухелі',
  'UA6804009047': 'Федірки',
  'UA6804009010': 'Гарнишівка',
  'UA6804009011': 'Гонорівка',
  'UA6804009012': 'Гречана',
  'UA6804009015': 'Іванівка',
  'UA6804009016': 'Іванівці',
  'UA6804009017': 'Канівка',
  'UA6804009048': 'Холодець',
  'UA6804009018': 'Клинини',
  'UA6804009019': 'Копачівка',
  'UA6804009020': 'Користова',
  'UA6804009021': 'Курилівка',
  'UA6804009022': 'Кушнирівка',
  'UA6804009023': 'Кушнирівська Слобідка',
  'UA6804009024': 'Левківці',
  'UA6804009025': 'Лісове',
  'UA6804009027': 'Лонки',
  'UA6804009026': 'Лозова',
  'UA6804009028': 'Маначин',
  'UA6804009029': 'Мирівка',
  'UA6804009030': 'Мислова',
  'UA6804009031': 'Нова Гребля',
  'UA6804009032': 'Ожигівці',
  'UA6804009033': 'Пальчинці',
  'UA6804009034': 'Петрівка',
  'UA6804009035': 'Петрівщина',
  'UA6804009036': 'Поляни',
  'UA6804009037': 'Попівці',
  'UA6804009038': 'Постолівка',
  'UA6804009039': 'Раціборівка',
  'UA6804009041': 'Рябіївка',
  'UA6804009040': 'Ріпна',
  'UA6804009042': 'Сербинів',
  'UA6804009052': 'Щаснівка',
  'UA6804009043': 'Случ',
  'UA6804009044': 'Соболівка',
  'UA6804009045': 'Соломна',
  'UA6804009046': 'Тарноруда',
  'UA6804009006': 'Великі Жеребки',
  'UA6804009008': 'Вільшани',
  'UA6804009009': 'Вочківці',
  'UA6804009001': 'Волочиськ',
  'UA6804009007': 'Вигода',
  'UA6804009053': 'Яхнівці',
  'UA6804009013': 'Зайчики',
  'UA6804009014': 'Збручівка',
  'UA6804007021': 'Чернелівці',
  'UA6804007003': 'Городище',
  'UA6804007004': 'Гришки',
  'UA6804007007': 'Кайтанівка',
  'UA6804007008': 'Клопотівці',
  'UA6804007009': 'Коричинці',
  'UA6804007010': 'Майдан-Чернелевецький',
  'UA6804007011': 'Макарове',
  'UA6804007013': 'Новостроївка',
  'UA6804007012': 'Новий Майдан',
  'UA6804007014': 'Подолянське',
  'UA6804007015': 'Радівці',
  'UA6804007016': 'Садове',
  'UA6804007017': 'Стара Гута',
  'UA6804007018': 'Старий Майдан',
  'UA6804007019': 'Степівка',
  'UA6804007020': 'Стреків',
  'UA6804007002': 'Вівсяники',
  'UA6804007001': 'Вовковинці',
  'UA6804007005': 'Згар',
  'UA6804007006': 'Згарок',
  'UA6804053002': 'Антонівці',
  'UA6804053003': 'Баранівка',
  'UA6804053004': 'Боднарівка',
  'UA6804053005': 'Борбухи',
  'UA6804053006': 'Буйволівці',
  'UA6804053013': 'Голохвасти',
  'UA6804053015': 'Кадиївка',
  'UA6804053016': 'Коритна',
  'UA6804053017': 'Косогірка',
  'UA6804053018': 'Круті Броди',
  'UA6804053019': 'Лебедівка',
  'UA6804053020': 'Лисівка',
  'UA6804053021': 'Магнишівка',
  'UA6804053022': 'Москалівка',
  'UA6804053023': 'Нове Село',
  'UA6804053024': 'Пасічна',
  'UA6804053025': 'Правдівка',
  'UA6804053026': 'Савинці',
  'UA6804053032': 'Шарівка',
  'UA6804053033': 'Шевченка',
  'UA6804053027': 'Слобідка-Кадиївська',
  'UA6804053028': 'Соколівка',
  'UA6804053029': 'Соснівка',
  'UA6804053030': 'Сутківці',
  'UA6804053031': 'Томашівка',
  'UA6804053007': 'Васильківці',
  'UA6804053008': 'Вербка',
  'UA6804053009': 'Вербка-Мурована',
  'UA6804053010': 'Верхівці',
  'UA6804053012': 'Волудринці',
  'UA6804053011': 'Вихилівка',
  'UA6804053001': 'Ярмолинці',
  'UA6804053034': 'Ясенівка',
  'UA6804053014': 'Жилинці',
  'UA6804017008': 'Дворик',
  'UA6804017006': 'Глібки',
  'UA6804017007': 'Говори',
  'UA6804017009': 'Калинівка',
  'UA6804017011': 'Кошелівка',
  'UA6804017010': 'Котюржинці',
  'UA6804017012': 'Мала Клітна',
  'UA6804017013': 'Малі Зозулинці',
  'UA6804017014': 'Марківці',
  'UA6804017015': 'Новосілка',
  'UA6804017017': 'Підлісся',
  'UA6804017016': 'Писарівка',
  'UA6804017019': 'Слобідка-Чернелівська',
  'UA6804017018': 'Світле',
  'UA6804017020': 'Трудове',
  'UA6804017002': 'Велика Клітна',
  'UA6804017003': 'Великі Зозулинці',
  'UA6804017004': 'Вербівка',
  'UA6804017005': 'Волиця Друга',
  'UA6804017021': 'Юхт',
  'UA6804017001': 'Заслучне',
  'UA6804019002': 'Адамівка',
  'UA6804019006': 'Черкасівка',
  'UA6804019003': 'Грим\'ячка',
  'UA6804019004': 'Покутинці',
  'UA6804019005': 'Станіславівка',
  'UA6804019001': 'Зіньків',
  'UA6806001001': 'Берездів',
  'UA6806001002': 'Бесідки',
  'UA6806001006': 'Дяків',
  'UA6806001005': 'Гориця',
  'UA6806001025': 'Хвощівка',
  'UA6806001008': 'Красносілка',
  'UA6806001009': 'Красностав',
  'UA6806001010': 'Кутки',
  'UA6806001011': 'Малий Правутин',
  'UA6806001012': 'Манятин',
  'UA6806001015': 'Модестівка',
  'UA6806001016': 'Мухарів',
  'UA6806001014': 'Михайлівка',
  'UA6806001013': 'Мирутин',
  'UA6806001017': 'Печиводи',
  'UA6806001018': 'Піддубці',
  'UA6806001019': 'Плоска',
  'UA6806001020': 'Селичів',
  'UA6806001026': 'Шагова',
  'UA6806001022': 'Сьомаки',
  'UA6806001021': 'Ставичани',
  'UA6806001023': 'Тростянець',
  'UA6806001024': 'Улянівка',
  'UA6806001003': 'Великий Правутин',
  'UA6806001004': 'Веселинівка',
  'UA6806001027': 'Яблунівка',
  'UA6806001007': 'Зубівщина',
  'UA6806003002': 'Баймаки',
  'UA6806003001': 'Білогір\'я',
  'UA6806003056': 'Червоне',
  'UA6806003013': 'Данилівка',
  'UA6806003014': 'Денисівка',
  'UA6806003015': 'Держаки',
  'UA6806003016': 'Дзвінки',
  'UA6806003011': 'Гулівці',
  'UA6806003012': 'Гурщина',
  'UA6806003025': 'Іванівка',
  'UA6806003027': 'Калинівка',
  'UA6806003028': 'Карасиха',
  'UA6806003029': 'Карпилівка',
  'UA6806003030': 'Кащенці',
  'UA6806003055': 'Хорошів',
  'UA6806003034': 'Корниця',
  'UA6806003033': 'Коритне',
  'UA6806003032': 'Козин',
  'UA6806003035': 'Кур\'янки',
  'UA6806003031': 'Квітневе',
  'UA6806003036': 'Ліски',
  'UA6806003037': 'Мала Боровиця',
  'UA6806003038': 'Малі Калетинці',
  'UA6806003039': 'Міжгір\'я',
  'UA6806003040': 'Мокроволя',
  'UA6806003041': 'Надишень',
  'UA6806003042': 'Новосеменів',
  'UA6806003043': 'Окіп',
  'UA6806003044': 'Переросле',
  'UA6806003045': 'Семенів',
  'UA6806003057': 'Шельвів',
  'UA6806003059': 'Шуньки',
  'UA6806003058': 'Шимківці',
  'UA6806003048': 'Соснівка',
  'UA6806003049': 'Соснівочка',
  'UA6806003050': 'Ставищани',
  'UA6806003051': 'Степанівка',
  'UA6806003052': 'Сушівці',
  'UA6806003047': 'Синютки',
  'UA6806003046': 'Сивки',
  'UA6806003053': 'Тростянка',
  'UA6806003054': 'Українка',
  'UA6806003003': 'Варивідки',
  'UA6806003004': 'Велика Боровиця',
  'UA6806003005': 'Великі Калетинці',
  'UA6806003006': 'Весняне',
  'UA6806003007': 'Вікентове',
  'UA6806003008': 'Вікнини',
  'UA6806003009': 'Вільшаниця',
  'UA6806003010': 'Водички',
  'UA6806003026': 'Йосипівці',
  'UA6806003061': 'Юрівка',
  'UA6806003060': 'Ювківці',
  'UA6806003019': 'Загірці',
  'UA6806003020': 'Загребля',
  'UA6806003021': 'Закіт',
  'UA6806003022': 'Залужжя',
  'UA6806003023': 'Заріччя',
  'UA6806003017': 'Жемелинці',
  'UA6806003018': 'Жижниківці',
  'UA6806003024': 'Зіньки',
  'UA6806005005': 'Досін',
  'UA6806005004': 'Довжки',
  'UA6806005001': 'Ганнопіль',
  'UA6806005003': 'Глинники',
  'UA6806005014': 'Хоняків',
  'UA6806005015': 'Хоросток',
  'UA6806005007': 'Клепачі',
  'UA6806005006': 'Киликиїв',
  'UA6806005008': 'Малий Скнит',
  'UA6806005009': 'Нараївка',
  'UA6806005010': 'Понора',
  'UA6806005011': 'Прикордонна Улашанівка',
  'UA6806005012': 'Рівки',
  'UA6806005016': 'Шатерники',
  'UA6806005013': 'Соснівка',
  'UA6806005002': 'Великий Скнит',
  'UA6806007001': 'Гриців',
  'UA6806007003': 'Корпилівка',
  'UA6806007004': 'Крачанівка',
  'UA6806007005': 'Курганівка',
  'UA6806007006': 'Лотівка',
  'UA6806007007': 'Мала Шкарівка',
  'UA6806007009': 'Москвитянівка',
  'UA6806007008': 'Микулин',
  'UA6806007010': 'Онишківці',
  'UA6806007011': 'Орлинці',
  'UA6806007012': 'Рожична',
  'UA6806007014': 'Сасанівка',
  'UA6806007013': 'Саверці',
  'UA6806007015': 'Устянівка',
  'UA6806007002': 'Велика Шкарівка',
  'UA6806009006': 'Більчин',
  'UA6806009007': 'Більчинка',
  'UA6806009002': 'Білеве',
  'UA6806009004': 'Білогородка',
  'UA6806009005': 'Білотин',
  'UA6806009003': 'Білижинці',
  'UA6806009010': 'Дібровка',
  'UA6806009009': 'Двірець',
  'UA6806009016': 'Іванівка',
  'UA6806009001': 'Ізяслав',
  'UA6806009017': 'Калетинці',
  'UA6806009018': 'Кіндратки',
  'UA6806009019': 'Клубівка',
  'UA6806009020': 'Комини',
  'UA6806009021': 'Криволука',
  'UA6806009023': 'Лютарка',
  'UA6806009022': 'Лопушне',
  'UA6806009027': 'Мокрець',
  'UA6806009025': 'Михля',
  'UA6806009026': 'Михнів',
  'UA6806009024': 'Мислятин',
  'UA6806009028': 'Нечаївка',
  'UA6806009029': 'Новостав',
  'UA6806009031': 'Підлісці',
  'UA6806009030': 'Півнева Гора',
  'UA6806009032': 'Покощівка',
  'UA6806009033': 'Поліське',
  'UA6806009034': 'Припутенка',
  'UA6806009035': 'Припутні',
  'UA6806009036': 'Путринці',
  'UA6806009037': 'Радошівка',
  'UA6806009038': 'Ревуха',
  'UA6806009039': 'Ріпки',
  'UA6806009040': 'Рокитне',
  'UA6806009046': 'Щурівчики',
  'UA6806009045': 'Щурівці',
  'UA6806009041': 'Сошне',
  'UA6806009043': 'Топірчики',
  'UA6806009044': 'Топори',
  'UA6806009042': 'Тишевичі',
  'UA6806009008': 'Васьківці',
  'UA6806009011': 'Забрід',
  'UA6806009013': 'Закружці',
  'UA6806009014': 'Заріччя',
  'UA6806009012': 'Завадинці',
  'UA6806009015': 'Зубарі',
  'UA6806011003': 'Дідова Гора',
  'UA6806011002': 'Головлі',
  'UA6806011011': 'Хоровиця',
  'UA6806011004': 'Колом\'є',
  'UA6806011005': 'Комарівка',
  'UA6806011001': 'Крупець',
  'UA6806011006': 'Лисиче',
  'UA6806011007': 'Нижні Головлі',
  'UA6806011008': 'Полянь',
  'UA6806011009': 'Потереба',
  'UA6806011010': 'Стригани',
  'UA6806013002': 'Білопіль',
  'UA6806013003': 'Брикуля',
  'UA6806013017': 'Чотирбоки',
  'UA6806013007': 'Коса Рішнівка',
  'UA6806013008': 'Коськів',
  'UA6806013009': 'Лавринівці',
  'UA6806013001': 'Ленківці',
  'UA6806013010': 'Марківці',
  'UA6806013011': 'Мокіївці',
  'UA6806013012': 'Пиляї',
  'UA6806013013': 'Сошки',
  'UA6806013014': 'Старі Бейзими',
  'UA6806013015': 'Степ',
  'UA6806013016': 'Сульжин',
  'UA6806013004': 'Велика Рішнівка',
  'UA6806013005': 'Вербівці',
  'UA6806013006': 'Вишневе',
  'UA6806015002': 'Бронники',
  'UA6806015018': 'Червоний Цвіт',
  'UA6806015004': 'Дубіївка',
  'UA6806015003': 'Городнявка',
  'UA6806015014': 'Хмелівка',
  'UA6806015015': 'Хутір',
  'UA6806015006': 'Конотоп',
  'UA6806015007': 'Корчик',
  'UA6806015008': 'Круглик',
  'UA6806015009': 'Майдан-Лабунь',
  'UA6806015001': 'Михайлючка',
  'UA6806015011': 'Романів',
  'UA6806015010': 'Рилівка',
  'UA6806015012': 'Сенігів',
  'UA6806015013': 'Судимонт',
  'UA6806015017': 'Цмівка',
  'UA6806015016': 'Цвіт',
  'UA6806015005': 'Заморочення',
  'UA6806017001': 'Нетішин',
  'UA6806017002': 'Новий Кривин',
  'UA6806017003': 'Старий Кривин',
  'UA6806019002': 'Антонівка',
  'UA6806019003': 'Борисів',
  'UA6806019006': 'Дертка',
  'UA6806019007': 'Добрин',
  'UA6806019008': 'Долоччя',
  'UA6806019005': 'Гаврилівка',
  'UA6806019010': 'Кам\'янка',
  'UA6806019021': 'Хотень Другий',
  'UA6806019022': 'Хотень Перший',
  'UA6806019011': 'Кунів',
  'UA6806019012': 'Лісна',
  'UA6806019013': 'Мала Радогощ',
  'UA6806019015': 'М\'якоти',
  'UA6806019014': 'Михайлівка',
  'UA6806019016': 'Нова Гутиська',
  'UA6806019017': 'Новосілка',
  'UA6806019001': 'Плужне',
  'UA6806019023': 'Шекеринці',
  'UA6806019019': 'Стара Гутиська',
  'UA6806019020': 'Сторониче',
  'UA6806019018': 'Сивір',
  'UA6806019004': 'Велика Радогощ',
  'UA6806019009': 'Закриничне',
  'UA6806021002': 'Адамів',
  'UA6806021003': 'Білецьке',
  'UA6806021004': 'Блидні',
  'UA6806021005': 'Бражинці',
  'UA6806021034': 'Черніївка',
  'UA6806021033': 'Червоне',
  'UA6806021012': 'Дубовий Гай',
  'UA6806021031': 'Фадіївка',
  'UA6806021010': 'Ганнусине',
  'UA6806021011': 'Голубча',
  'UA6806021032': 'Храбузна',
  'UA6806021013': 'Кіпчинці',
  'UA6806021017': 'Коханівка',
  'UA6806021014': 'Колосівка',
  'UA6806021015': 'Котелянка',
  'UA6806021016': 'Котюржинці',
  'UA6806021018': 'Кустівці',
  'UA6806021019': 'Любомирка',
  'UA6806021020': 'Малі Каленичі',
  'UA6806021021': 'Москалівка',
  'UA6806021022': 'Новолабунь',
  'UA6806021023': 'Новоселиця',
  'UA6806021024': 'Онацьківці',
  'UA6806021001': 'Полонне',
  'UA6806021025': 'Прислуч',
  'UA6806021026': 'Радісне',
  'UA6806021027': 'Роговичі',
  'UA6806021028': 'Сягрів',
  'UA6806021030': 'Троєщина',
  'UA6806021029': 'Титьків',
  'UA6806021006': 'Варварівка',
  'UA6806021007': 'Велика Березна',
  'UA6806021008': 'Великі Каленичі',
  'UA6806021009': 'Воробіївка',
  'UA6806021035': 'Юровщина',
  'UA6806023002': 'Буртин',
  'UA6806023007': 'Лісне',
  'UA6806023005': 'Лодзянівка',
  'UA6806023004': 'Липівка',
  'UA6806023006': 'Новаки',
  'UA6806023001': 'Понінка',
  'UA6806023003': 'Залісся',
  'UA6806025002': 'Бейзими',
  'UA6806025021': 'Чепці',
  'UA6806025022': 'Чижівка',
  'UA6806025006': 'Даньківці',
  'UA6806025005': 'Григорівка',
  'UA6806025007': 'Калинівка',
  'UA6806025020': 'Христівка',
  'UA6806025008': 'Кропивна',
  'UA6806025009': 'Ліщани',
  'UA6806025010': 'Мала Медведівка',
  'UA6806025011': 'Мирне',
  'UA6806025012': 'Нове Село',
  'UA6806025013': 'Пильки',
  'UA6806025001': 'Сахнівці',
  'UA6806025023': 'Шевченка',
  'UA6806025015': 'Сморшки',
  'UA6806025016': 'Сохужинці',
  'UA6806025014': 'Свириди',
  'UA6806025017': 'Тарасівка',
  'UA6806025018': 'Теліжинці',
  'UA6806025019': 'Тернавка',
  'UA6806025003': 'Великі Пузирки',
  'UA6806025004': 'Влашанівка',
  'UA6806033003': 'Плесна',
  'UA6806033004': 'Пліщин',
  'UA6806033001': 'Шепетівка',
  'UA6806033002': 'Жилинці',
  'UA6806027003': 'Голики',
  'UA6806027001': 'Славута',
  'UA6806027002': 'Варварівка',
  'UA6806029002': 'Березне',
  'UA6806029003': 'Білокриниччя',
  'UA6806029007': 'Городище',
  'UA6806029021': 'Хролин',
  'UA6806029009': 'Климентовичі',
  'UA6806029010': 'Красносілка',
  'UA6806029011': 'Купине',
  'UA6806029012': 'Лозичне',
  'UA6806029013': 'Мальованка',
  'UA6806029014': 'Новичі',
  'UA6806029015': 'Пашуки',
  'UA6806029016': 'Поляна',
  'UA6806029017': 'Рудня-Новенька',
  'UA6806029018': 'Савичі',
  'UA6806029019': 'Серединці',
  'UA6806029001': 'Судилків',
  'UA6806029020': 'Траулин',
  'UA6806029004': 'Велика Медведівка',
  'UA6806029006': 'Вовківчики',
  'UA6806029005': 'Вовківці',
  'UA6806029008': 'Жолудки',
  'UA6806031002': 'Бачманівка',
  'UA6806031007': 'Дятилівка',
  'UA6806031005': 'Губельці',
  'UA6806031006': 'Гута',
  'UA6806031009': 'Іванівка',
  'UA6806031010': 'Кам\'янка',
  'UA6806031019': 'Хоровець',
  'UA6806031011': 'Марачівка',
  'UA6806031012': 'Миньківці',
  'UA6806031013': 'Ногачівка',
  'UA6806031014': 'Пашуки',
  'UA6806031015': 'Перемишель',
  'UA6806031016': 'Пузирки',
  'UA6806031017': 'Романіни',
  'UA6806031021': 'Шевченко',
  'UA6806031018': 'Ташки',
  'UA6806031020': 'Цвітоха',
  'UA6806031001': 'Улашанівка',
  'UA6806031003': 'Вачів',
  'UA6806031004': 'Волиця',
  'UA6806031008': 'Жуків',
  'UA6806035004': 'Дідківці',
  'UA6806035005': 'Довгалівка',
  'UA6806035006': 'Лепесівка',
  'UA6806035008': 'Москалівка',
  'UA6806035007': 'Миклаші',
  'UA6806035009': 'Норилів',
  'UA6806035010': 'Паньківці',
  'UA6806035011': 'Погорільці',
  'UA6806035012': 'Ставок',
  'UA6806035013': 'Тихомель',
  'UA6806035003': 'В\'язовець',
  'UA6806035002': 'Воробіївка',
  'UA6806035001': 'Ямпіль',
  'UA3502001001': 'Благовіщенське',
  'UA3502001002': 'Богданове',
  'UA3502001006': 'Данилова Балка',
  'UA3502001007': 'Дельфінове',
  'UA3502001005': 'Грушка',
  'UA3502001010': 'Кам\'яна Криниця',
  'UA3502001011': 'Кам\'яний Брід',
  'UA3502001025': 'Христофорове',
  'UA3502001012': 'Кошаро-Олександрівка',
  'UA3502001013': 'Лозувата',
  'UA3502001014': 'Луполове',
  'UA3502001015': 'Маньківське',
  'UA3502001016': 'Мечиславка',
  'UA3502001017': 'Новоселиця',
  'UA3502001018': 'Петрівка',
  'UA3502001019': 'Розношенське',
  'UA3502001020': 'Сабатинівка',
  'UA3502001021': 'Сергіївка',
  'UA3502001026': 'Шамраєве',
  'UA3502001027': 'Шевченка',
  'UA3502001024': 'Станіславове',
  'UA3502001023': 'Синьки',
  'UA3502001022': 'Синицівка',
  'UA3502001003': 'Великі Трояни',
  'UA3502001004': 'Вільхове',
  'UA3502001009': 'Йосипівка',
  'UA3502001008': 'Змійове',
  'UA3502005002': 'Бандурове',
  'UA3502005003': 'Берестяги',
  'UA3502005004': 'Бугове',
  'UA3502005017': 'Червоне',
  'UA3502005006': 'Долинівка',
  'UA3502005001': 'Гайворон',
  'UA3502005007': 'Казавчин',
  'UA3502005016': 'Хащувате',
  'UA3502005008': 'Мощене',
  'UA3502005009': 'Переямпіль',
  'UA3502005010': 'Покровське',
  'UA3502005011': 'Прогрес',
  'UA3502005012': 'Садове',
  'UA3502005013': 'Солгутове',
  'UA3502005014': 'Соломія',
  'UA3502005015': 'Тополі',
  'UA3502005005': 'Вікнина',
  'UA3502007001': 'Голованівськ',
  'UA3502007031': 'Голованівськ',
  'UA3502007003': 'Грузьке',
  'UA3502007007': 'Клинове',
  'UA3502007009': 'Костянтинівка',
  'UA3502007008': 'Ковалівка',
  'UA3502007010': 'Красногірка',
  'UA3502007011': 'Краснопілля',
  'UA3502007012': 'Манжурка',
  'UA3502007014': 'Мар\'янівка',
  'UA3502007013': 'Маринопіль',
  'UA3502007015': 'Матвіївка',
  'UA3502007016': 'Межирічка',
  'UA3502007017': 'Молдовка',
  'UA3502007018': 'Надеждівка',
  'UA3502007019': 'Наливайка',
  'UA3502007020': 'Новоголованівськ',
  'UA3502007021': 'Новосілка',
  'UA3502007022': 'Олександрівка',
  'UA3502007023': 'Олексіївка',
  'UA3502007024': 'Роздол',
  'UA3502007025': 'Розкішне',
  'UA3502007029': 'Шепилове',
  'UA3502007026': 'Свірневе',
  'UA3502007027': 'Троянка',
  'UA3502007028': 'Цвіткове',
  'UA3502007002': 'Вербове',
  'UA3502007030': 'Ясне',
  'UA3502007004': 'Ємилівка',
  'UA3502007032': 'Ємилівка',
  'UA3502007006': 'Зелена Балка',
  'UA3502007005': 'Журавлинка',
  'UA3502011005': 'Іванівка',
  'UA3502011007': 'Кальниболота',
  'UA3502011006': 'Калинівка',
  'UA3502011008': 'Кіндратівка',
  'UA3502011001': 'Надлак',
  'UA3502011009': 'Низове',
  'UA3502011010': 'Покровка',
  'UA3502011011': 'Приют',
  'UA3502011013': 'Шляхове',
  'UA3502011012': 'Тимофіївка',
  'UA3502011002': 'Вільшанка',
  'UA3502011003': 'Вукитичеве',
  'UA3502011004': 'Жеванівка',
  'UA3502013021': 'Червінка',
  'UA3502013004': 'Диковичеве',
  'UA3502013002': 'Ганнівка',
  'UA3502013003': 'Голубенка',
  'UA3502013006': 'Кагарлик',
  'UA3502013007': 'Кам\'янече',
  'UA3502013008': 'Комишеве',
  'UA3502013009': 'Кринички',
  'UA3502013010': 'Левківка',
  'UA3502013011': 'Лозуватка',
  'UA3502013012': 'Мар\'янівка',
  'UA3502013001': 'Новоархангельськ',
  'UA3502013013': 'Новопетрівка',
  'UA3502013014': 'Сабове',
  'UA3502013017': 'Скалева',
  'UA3502013018': 'Скалівські Хутори',
  'UA3502013019': 'Солдатське',
  'UA3502013015': 'Свердликове',
  'UA3502013016': 'Синюха',
  'UA3502013020': 'Торговиця',
  'UA3502013005': 'Журівка',
  'UA3502015002': 'Давидівка',
  'UA3502015003': 'Крутеньке',
  'UA3502015004': 'Лебединка',
  'UA3502015005': 'Лещівка',
  'UA3502015010': 'Лісне',
  'UA3502015001': 'Перегонівка',
  'UA3502015006': 'Полонисте',
  'UA3502015007': 'Семидуби',
  'UA3502015008': 'Табанове',
  'UA3502015009': 'Тернове',
  'UA3502017002': 'Борщова',
  'UA3502017006': 'Копенкувате',
  'UA3502017007': 'Мартинівка',
  'UA3502017008': 'Небелівка',
  'UA3502017009': 'Нерубайка',
  'UA3502017010': 'Орлове',
  'UA3502017001': 'Підвисоке',
  'UA3502017011': 'Покотилове',
  'UA3502017012': 'Розсохуватець',
  'UA3502017016': 'Шевченка',
  'UA3502017013': 'Станіславівка',
  'UA3502017014': 'Тарасівка',
  'UA3502017015': 'Тернівка',
  'UA3502017003': 'Вербівка',
  'UA3502017004': 'Володимирівка',
  'UA3502017017': 'Ятрань',
  'UA3502017005': 'Зрубанці',
  'UA3502019002': 'Грузянка',
  'UA3502019003': 'Капітанка',
  'UA3502019006': 'Люшнювате',
  'UA3502019004': 'Липняги',
  'UA3502019005': 'Липовеньке',
  'UA3502019007': 'Одая',
  'UA3502019001': 'Побузьке',
  'UA3502019008': 'Пушкове',
  'UA3502019009': 'Сухий Ташлик',
  'UA3502003002': 'Березова Балка',
  'UA3502003003': 'Бузникувате',
  'UA3502003023': 'Чистопілля',
  'UA3502003007': 'Добре',
  'UA3502003008': 'Добрянка',
  'UA3502003009': 'Дорожинка',
  'UA3502003013': 'Калмазове',
  'UA3502003014': 'Коритно-Забузьке',
  'UA3502003015': 'Куца Балка',
  'UA3502003017': 'Мала Мазниця',
  'UA3502003016': 'Мала Вільшанка',
  'UA3502003018': 'Осички',
  'UA3502003019': 'Плоско-Забузьке',
  'UA3502003021': 'Станкувате',
  'UA3502003022': 'Степанівка',
  'UA3502003020': 'Синюха',
  'UA3502003001': 'Вільшанка',
  'UA3502003004': 'Вівсяники',
  'UA3502003005': 'Владиславка',
  'UA3502003006': 'Вовча Балка',
  'UA3502003012': 'Йосипівка',
  'UA3502003011': 'Залізничне',
  'UA3502003010': 'Завітне',
  'UA3502009003': 'Березівка',
  'UA3502009011': 'Чемерпіль',
  'UA3502009012': 'Червоні Маяки',
  'UA3502009006': 'Кленове',
  'UA3502009007': 'Могильне',
  'UA3502009002': 'Салькове',
  'UA3502009009': 'Ташлик',
  'UA3502009008': 'Таужне',
  'UA3502009010': 'Тракт',
  'UA3502009004': 'Вільховецьке',
  'UA3502009001': 'Завалля',
  'UA3502009005': 'Жакчик',
  'UA3504001001': 'Аджамка',
  'UA3504001011': 'Червоний Яр',
  'UA3504001003': 'Григорівка',
  'UA3504001005': 'Медерове',
  'UA3504001006': 'Молодецьке',
  'UA3504001008': 'Новоолександрівка',
  'UA3504001007': 'Нововолодимирівка',
  'UA3504001009': 'Павло-Миколаївка',
  'UA3504001010': 'Привілля',
  'UA3504001002': 'Веселівка',
  'UA3504001004': 'Зелений Гай',
  'UA3504003001': 'Бобринець',
  'UA3504003002': 'Богданівка',
  'UA3504003008': 'Червона Долина',
  'UA3504003004': 'Дібрівка',
  'UA3504003005': 'Коржеве',
  'UA3504003006': 'Обланка',
  'UA3504003007': 'Садове',
  'UA3504003009': 'Шляхове',
  'UA3504003003': 'Великодрюкове',
  'UA3504009001': 'Дмитрівка',
  'UA3504009005': 'Долина',
  'UA3504009004': 'Гостинне',
  'UA3504009007': 'Іванківці',
  'UA3504009008': 'Калинівка',
  'UA3504009009': 'Макариха',
  'UA3504009010': 'Новопокровка',
  'UA3504009011': 'Плоске',
  'UA3504009013': 'Цибулеве',
  'UA3504009002': 'Веселий Кут',
  'UA3504009014': 'Юхимове',
  'UA3504009006': 'Заломи',
  'UA3504011003': 'Антонівка',
  'UA3504011004': 'Березівка',
  'UA3504011005': 'Богданівка',
  'UA3504011006': 'Братський Посад',
  'UA3504011030': 'Червоне Озеро',
  'UA3504011001': 'Долинська',
  'UA3504011028': 'Фалькове',
  'UA3504011029': 'Федоро-Шулічине',
  'UA3504011008': 'Гординівка',
  'UA3504011010': 'Катеринівка',
  'UA3504011011': 'Лаврівка',
  'UA3504011012': 'Маловодяне',
  'UA3504011013': 'Марфівка',
  'UA3504011002': 'Молодіжне',
  'UA3504011014': 'Мирне',
  'UA3504011017': 'Новоданилівка',
  'UA3504011015': 'Новогригорівка Друга',
  'UA3504011016': 'Новогригорівка Перша',
  'UA3504011018': 'Новомихайлівка',
  'UA3504011019': 'Новоолександрівка',
  'UA3504011020': 'Новосавицьке',
  'UA3504011021': 'Новошевченкове',
  'UA3504011023': 'Очеретяне',
  'UA3504011022': 'Олександрівка',
  'UA3504011032': 'Першотравневе',
  'UA3504011024': 'Писанка',
  'UA3504011031': 'Широка Балка',
  'UA3504011025': 'Славне',
  'UA3504011026': 'Степове',
  'UA3504011027': 'Суходільське',
  'UA3504011007': 'Вишневе',
  'UA3504011009': 'Згода',
  'UA3504007002': 'Благодатне',
  'UA3504007003': 'Бокове',
  'UA3504007004': 'Братолюбівка',
  'UA3504007016': 'Червоне',
  'UA3504007009': 'Дубровине',
  'UA3504007008': 'Ганнівка',
  'UA3504007001': 'Гурівка',
  'UA3504007011': 'Іванівка',
  'UA3504007012': 'Нагірне',
  'UA3504007013': 'Никифорівка',
  'UA3504007014': 'Пишне',
  'UA3504007015': 'Ситаєве',
  'UA3504007005': 'Варварівка',
  'UA3504007006': 'Василівка',
  'UA3504007007': 'Веселі Боковеньки',
  'UA3504007010': 'Зелений Гай',
  'UA3504015002': 'Аврамівка',
  'UA3504015004': 'Грузьке',
  'UA3504015001': 'Катеринівка',
  'UA3504015005': 'Лісне',
  'UA3504015006': 'Мальовниче',
  'UA3504015008': 'Могутнє',
  'UA3504015007': 'Миронівка',
  'UA3504015009': 'Обознівка',
  'UA3504015011': 'Олександрівка',
  'UA3504015012': 'Олексіївка',
  'UA3504015013': 'Осикувате',
  'UA3504015010': 'Овсяниківка',
  'UA3504015003': 'Володимирівка',
  'UA3504017002': 'Апрелівка',
  'UA3504017003': 'Апрелівське',
  'UA3504017005': 'Береславка',
  'UA3504017004': 'Березівка',
  'UA3504017006': 'Благодатне',
  'UA3504017007': 'Бобринка',
  'UA3504017008': 'Борисівка',
  'UA3504017009': 'Бредихине',
  'UA3504017010': 'Буховецьке',
  'UA3504017066': 'Чарівне',
  'UA3504017067': 'Червонопілля',
  'UA3504017068': 'Чигиринське',
  'UA3504017022': 'Дончине',
  'UA3504017064': 'Федіївка',
  'UA3504017065': 'Федорівка',
  'UA3504017019': 'Ганнопіль',
  'UA3504017020': 'Горіхівка',
  'UA3504017021': 'Грузьке',
  'UA3504017027': 'Іванівка',
  'UA3504017001': 'Кетрисанівка',
  'UA3504017029': 'Кохане',
  'UA3504017028': 'Костомарівка',
  'UA3504017031': 'Крутоярка',
  'UA3504017030': 'Кривоносове',
  'UA3504017032': 'Майське',
  'UA3504017033': 'Маковіївка',
  'UA3504017035': 'Мар\'янівка',
  'UA3504017034': 'Маріуполь',
  'UA3504017037': 'Мюдівка',
  'UA3504017036': 'Миколо-Бабанка',
  'UA3504017071': 'Мирне',
  'UA3504017039': 'Новогомельське',
  'UA3504017040': 'Новоградівка',
  'UA3504017041': 'Новокиївка',
  'UA3504017042': 'Новомиколаївка',
  'UA3504017043': 'Новосамара',
  'UA3504017038': 'Никонорівка',
  'UA3504017044': 'Олексіївка',
  'UA3504017045': 'Оленівське',
  'UA3504017046': 'Осикувате',
  'UA3504017047': 'Павлогірківка',
  'UA3504017048': 'Пенькове',
  'UA3504017049': 'Піщане',
  'UA3504017051': 'Поляна',
  'UA3504017050': 'Полум\'яне',
  'UA3504017052': 'Проскурівка',
  'UA3504017054': 'Рощахівка',
  'UA3504017072': 'Роздолля',
  'UA3504017053': 'Розтичеве',
  'UA3504017055': 'Солонцюватка',
  'UA3504017056': 'Сорочанове',
  'UA3504017057': 'Степанівка',
  'UA3504017058': 'Степівка',
  'UA3504017059': 'Сугокліївка',
  'UA3504017060': 'Тарасівка',
  'UA3504017061': 'Трудолюбівка',
  'UA3504017062': 'Ударне',
  'UA3504017063': 'Улянівка',
  'UA3504017011': 'Варламівка',
  'UA3504017012': 'Василівка',
  'UA3504017013': 'Вереміївка',
  'UA3504017014': 'Верхньоінгульське',
  'UA3504017015': 'Веселівка',
  'UA3504017018': 'Водяно-Михайлівка',
  'UA3504017016': 'Височанове',
  'UA3504017017': 'Витязівка',
  'UA3504017070': 'Яблучко',
  'UA3504017069': 'Юр\'ївка',
  'UA3504017073': 'Южне',
  'UA3504017024': 'Західне',
  'UA3504017023': 'Завадівка',
  'UA3504017025': 'Златопілля',
  'UA3504017026': 'Зоряне',
  'UA3504019002': 'Антонівка',
  'UA3504019003': 'Березнегувате',
  'UA3504019004': 'Богодарівка',
  'UA3504019005': 'Братерське',
  'UA3504019006': 'Бузова',
  'UA3504019050': 'Червона Слобода',
  'UA3504019051': 'Червоновершка',
  'UA3504019019': 'Долинівка',
  'UA3504019020': 'Дружба',
  'UA3504019013': 'Гарманівка',
  'UA3504019014': 'Голубієвичі',
  'UA3504019015': 'Гордіївка',
  'UA3504019016': 'Грізне',
  'UA3504019017': 'Громадське',
  'UA3504019018': 'Губівка',
  'UA3504019024': 'Інженерівка',
  'UA3504019001': 'Компаніївка',
  'UA3504019025': 'Коневе',
  'UA3504019026': 'Коротяк',
  'UA3504019027': 'Кременчувате',
  'UA3504019028': 'Криничувате',
  'UA3504019029': 'Лозуватка',
  'UA3504019030': 'Лужок',
  'UA3504019031': 'Малоконеве',
  'UA3504019032': 'Мар\'ївка',
  'UA3504019033': 'Морквина',
  'UA3504019034': 'Наглядівка',
  'UA3504019035': 'Нечаївка',
  'UA3504019036': 'Обертасове',
  'UA3504019037': 'Павлівка',
  'UA3504019038': 'Першотравенка',
  'UA3504019039': 'Петрівка',
  'UA3504019040': 'Покровка',
  'UA3504019041': 'Полтавка',
  'UA3504019043': 'Ромашки',
  'UA3504019042': 'Роздолля',
  'UA3504019044': 'Сасівка',
  'UA3504019045': 'Семенівка',
  'UA3504019046': 'Софіївка',
  'UA3504019047': 'Тернова Балка',
  'UA3504019048': 'Травневе',
  'UA3504019049': 'Трудолюбівка',
  'UA3504019007': 'Вербове',
  'UA3504019010': 'Водяне',
  'UA3504019011': 'Володимирівка',
  'UA3504019012': 'Волошки',
  'UA3504019008': 'Виноградівка',
  'UA3504019009': 'Вишнівка',
  'UA3504019022': 'Зелене',
  'UA3504019021': 'Живанівка',
  'UA3504019023': 'Золотницьке',
  'UA3504021001': 'Кропивницький',
  'UA3504021002': 'Нове',
  'UA3504023002': 'Бережинка',
  'UA3504023004': 'Білопіль',
  'UA3504023003': 'Білозерне',
  'UA3504023010': 'Дубівка',
  'UA3504023011': 'Інгуло-Кам\'янка',
  'UA3504023013': 'Корбомиколаївка',
  'UA3504023012': 'Козирівка',
  'UA3504023014': 'Куцівка',
  'UA3504023015': 'Митрофанівка',
  'UA3504023001': 'Новгородка',
  'UA3504023016': 'Новоандріївка',
  'UA3504023017': 'Новомиколаївка',
  'UA3504023018': 'Ольгівка',
  'UA3504023019': 'Перше Травня',
  'UA3504023020': 'Петрокорбівка',
  'UA3504023021': 'Просторе',
  'UA3504023023': 'Ручайки',
  'UA3504023022': 'Рибчине',
  'UA3504023024': 'Сотницьке',
  'UA3504023025': 'Спасове',
  'UA3504023026': 'Спільне',
  'UA3504023027': 'Тарасівка',
  'UA3504023005': 'Велика Чечеліївка',
  'UA3504023006': 'Верблюжка',
  'UA3504023007': 'Вершино-Кам\'янка',
  'UA3504023008': 'Веселе',
  'UA3504023009': 'Воронцівка',
  'UA3504025004': 'Антонівка',
  'UA3504025005': 'Бандурове',
  'UA3504025006': 'Біляївка',
  'UA3504025007': 'Бірки',
  'UA3504025008': 'Бовтишка',
  'UA3504025009': 'Букварка',
  'UA3504025010': 'Бурякове',
  'UA3504025013': 'Гайове',
  'UA3504025014': 'Голикове',
  'UA3504025015': 'Григорівка',
  'UA3504025016': 'Гутницька',
  'UA3504025017': 'Івангород',
  'UA3504025018': 'Іванівка',
  'UA3504025050': 'Хайнівка',
  'UA3504025020': 'Красносілка',
  'UA3504025021': 'Красносілля',
  'UA3504025022': 'Кримки',
  'UA3504025019': 'Китайгород',
  'UA3504025003': 'Лісове',
  'UA3504025024': 'Любомирка',
  'UA3504025023': 'Липівка',
  'UA3504025025': 'Мар\'янівка',
  'UA3504025028': 'Могилів Курінь',
  'UA3504025027': 'Михайлівка',
  'UA3504025026': 'Миколаївка',
  'UA3504025029': 'Настине',
  'UA3504025030': 'Несваткове',
  'UA3504025032': 'Нова Осота',
  'UA3504025031': 'Нижчі Верещаки',
  'UA3504025001': 'Олександрівка',
  'UA3504025033': 'Омельгород',
  'UA3504025034': 'Підлісне',
  'UA3504025035': 'Плішки',
  'UA3504025036': 'Польове',
  'UA3504025037': 'Поселянівка',
  'UA3504025038': 'Родниківка',
  'UA3504025039': 'Роздолля',
  'UA3504025040': 'Розумівка',
  'UA3504025041': 'Ружичеве',
  'UA3504025043': 'Северинівка',
  'UA3504025044': 'Соснівка',
  'UA3504025046': 'Стара Осота',
  'UA3504025045': 'Ставидла',
  'UA3504025047': 'Стримівка',
  'UA3504025042': 'Світова Зірка',
  'UA3504025048': 'Тарасівка',
  'UA3504025049': 'Триліси',
  'UA3504025051': 'Цвітне',
  'UA3504025011': 'Веселе',
  'UA3504025012': 'Вищі Верещаки',
  'UA3504025052': 'Ясинове',
  'UA3504025053': 'Ясинуватка',
  'UA3504025002': 'Єлизаветградка',
  'UA3504027002': 'Бережинка',
  'UA3504027005': 'Демешкове',
  'UA3504027019': 'Федорівка',
  'UA3504027004': 'Гаївка',
  'UA3504027007': 'Калинівка',
  'UA3504027008': 'Клинці',
  'UA3504027009': 'Любо-Надеждівка',
  'UA3504027010': 'Макове',
  'UA3504027011': 'Миколаївські Сади',
  'UA3504027012': 'Неопалимівка',
  'UA3504027013': 'Новогригорівка',
  'UA3504027014': 'Паращине Поле',
  'UA3504027001': 'Первозванівка',
  'UA3504027015': 'Покровське',
  'UA3504027016': 'Попівка',
  'UA3504027017': 'Сонячне',
  'UA3504027018': 'Степове',
  'UA3504027003': 'Верхівці',
  'UA3504027006': 'Зоря',
  'UA3504029002': 'Безводня',
  'UA3504029020': 'Черняхівка',
  'UA3504029006': 'Дар\'ївка',
  'UA3504029007': 'Димине',
  'UA3504029005': 'Ганнинське',
  'UA3504029008': 'Іванівка',
  'UA3504029009': 'Івано-Благодатне',
  'UA3504029010': 'Карлівка',
  'UA3504029011': 'Корлюгівка',
  'UA3504029012': 'Липове',
  'UA3504029013': 'Миколаївка',
  'UA3504029014': 'Назарівка',
  'UA3504029015': 'Нова Павлівка',
  'UA3504029016': 'Новопетрівка',
  'UA3504029017': 'Оленівка',
  'UA3504029018': 'Олено-Косогорівка',
  'UA3504029021': 'Шевченкове',
  'UA3504029022': 'Шостаківка',
  'UA3504029001': 'Соколівське',
  'UA3504029019': 'Українка',
  'UA3504029004': 'Вільне',
  'UA3504029003': 'Вишняківка',
  'UA3504031002': 'Барвінівка',
  'UA3504031003': 'Богданівка',
  'UA3504031007': 'Долино-Кам\'янка',
  'UA3504031006': 'Глибока Балка',
  'UA3504031009': 'Казарня',
  'UA3504031012': 'Коханівка',
  'UA3504031010': 'Копані',
  'UA3504031011': 'Костянтинівка',
  'UA3504031013': 'Кучерівка',
  'UA3504031014': 'Мілова Балка',
  'UA3504031015': 'Мошорине',
  'UA3504031017': 'Новополяна',
  'UA3504031018': 'Новороманівка',
  'UA3504031019': 'Новотрепівка',
  'UA3504031016': 'Нововодяне',
  'UA3504031020': 'Саблине',
  'UA3504031021': 'Спасо-Мажарівка',
  'UA3504031001': 'Суботці',
  'UA3504031022': 'Топило',
  'UA3504031023': 'Трепівка',
  'UA3504031004': 'Васине',
  'UA3504031005': 'Володимирівка',
  'UA3504031008': 'Зелений Гай',
  'UA3504033002': 'Березівка',
  'UA3504033003': 'Березуватка',
  'UA3504033004': 'Брусівка',
  'UA3504033007': 'Докучаєве',
  'UA3504033005': 'Ганно-Леонтовичеве',
  'UA3504033006': 'Ганно-Требинівка',
  'UA3504033009': 'Інгульське',
  'UA3504033011': 'Костянтинівка',
  'UA3504033010': 'Козлівське',
  'UA3504033012': 'Криничне',
  'UA3504033013': 'Криничуватка',
  'UA3504033014': 'Лебедине',
  'UA3504033015': 'Любовичка',
  'UA3504033016': 'Мала Ганнівка',
  'UA3504033017': 'Мальчевське',
  'UA3504033018': 'Мар\'янівка',
  'UA3504033020': 'Медове',
  'UA3504033019': 'Медвежа Балка',
  'UA3504033021': 'Нариманівка',
  'UA3504033025': 'Новочигиринка',
  'UA3504033022': 'Новоігорівка',
  'UA3504033023': 'Новокиївка',
  'UA3504033024': 'Новоустинівка',
  'UA3504033026': 'Олександрівка',
  'UA3504033027': 'Орлова Балка',
  'UA3504033028': 'Переможне',
  'UA3504033029': 'Садки',
  'UA3504033030': 'Седнівка',
  'UA3504033031': 'Селіванове',
  'UA3504033032': 'Сонцеве',
  'UA3504033033': 'Степанівка',
  'UA3504033034': 'Степове',
  'UA3504033036': 'Третє',
  'UA3504033035': 'Тирлова Балка',
  'UA3504033001': 'Устинівка',
  'UA3504033008': 'Завтурове',
  'UA3504005002': 'Андросове',
  'UA3504005011': 'Червоний Кут',
  'UA3504005004': 'Кандаурове',
  'UA3504005005': 'Лозуватка',
  'UA3504005006': 'Оситняжка',
  'UA3504005007': 'Петрове',
  'UA3504005008': 'Підгайці',
  'UA3504005009': 'Рожнятівка',
  'UA3504005010': 'Созонівка',
  'UA3504005001': 'Велика Северинка',
  'UA3504005003': 'Високі Байраки',
  'UA3504013004': 'Новоолександрівка',
  'UA3504013005': 'Петрове',
  'UA3504013006': 'Сокільники',
  'UA3504013003': 'Водяне',
  'UA3504013002': 'Знам\'янка Друга',
  'UA3504013001': 'Знам\'янка',
  'UA3506005002': 'Анатолівка',
  'UA3506005003': 'Богодарівка',
  'UA3506005004': 'Братолюбівка',
  'UA3506005011': 'Добротимофіївка',
  'UA3506005001': 'Добровеличківка',
  'UA3506005012': 'Дружелюбівка',
  'UA3506005010': 'Гнатівка',
  'UA3506005013': 'Карбівка',
  'UA3506005014': 'Липняжка',
  'UA3506005015': 'Мар\'ївка',
  'UA3506005016': 'Маркове',
  'UA3506005017': 'Михайлівка',
  'UA3506005019': 'Новодобрянка',
  'UA3506005020': 'Новолутківка',
  'UA3506005021': 'Новомиколаївка',
  'UA3506005022': 'Новоодеса',
  'UA3506005023': 'Новостанкувата',
  'UA3506005018': 'Нововікторівка',
  'UA3506005024': 'Олександрівка',
  'UA3506005025': 'Олександро-Акацатове',
  'UA3506005026': 'Олександро-Завадське',
  'UA3506005030': 'Шевченка',
  'UA3506005027': 'Скопіївка',
  'UA3506005028': 'Тернове',
  'UA3506005029': 'Трояни',
  'UA3506005005': 'Варваро-Олександрівка',
  'UA3506005006': 'Василівка',
  'UA3506005007': 'Водяне',
  'UA3506005008': 'Володимирівка',
  'UA3506005009': 'Вороб\'ївка',
  'UA3506005031': 'Юр\'ївка',
  'UA3506001001': 'Ганнівка',
  'UA3506001005': 'Громуха',
  'UA3506001004': 'Григорівка',
  'UA3506001006': 'Караказелівка',
  'UA3506001007': 'Кропивницьке',
  'UA3506001008': 'Куликова Балка',
  'UA3506001009': 'Острівка',
  'UA3506001010': 'Приют',
  'UA3506001013': 'Шутеньке',
  'UA3506001012': 'Шишкине',
  'UA3506001011': 'Трудолюбівка',
  'UA3506001002': 'Вільні Луки',
  'UA3506001003': 'В\'юнки',
  'UA3506001014': 'Юр\'ївка',
  'UA3506003001': 'Глодоси',
  'UA3506003004': 'Козакова Балка',
  'UA3506003005': 'Ліски',
  'UA3506003006': 'Мар\'янівка',
  'UA3506003007': 'Михайлівка',
  'UA3506003008': 'Новомиколаївка',
  'UA3506003009': 'Новоподимка',
  'UA3506003010': 'Петрівка',
  'UA3506003011': 'Подимка',
  'UA3506003002': 'Вербівка',
  'UA3506003003': 'Войнівка',
  'UA3506009014': 'Червона Поляна',
  'UA3506009003': 'Димине',
  'UA3506009004': 'Краснопілка',
  'UA3506009005': 'Лозуватка',
  'UA3506009006': 'Лутківка',
  'UA3506009001': 'Мала Виска',
  'UA3506009007': 'Мануйлівка',
  'UA3506009008': 'Миролюбівка',
  'UA3506009009': 'Новомиколаївка',
  'UA3506009010': 'Олександрівка',
  'UA3506009011': 'Паліївка',
  'UA3506009012': 'Первомайське',
  'UA3506009013': 'Тарасівка',
  'UA3506009002': 'Веселе',
  'UA3506009015': 'Вишневе',
  'UA3506009016': 'Заповідне',
  'UA3506011002': 'Арсенівка',
  'UA3506011006': 'Ковалівка',
  'UA3506011007': 'Мар\'янівка',
  'UA3506011008': 'Матусівка',
  'UA3506011009': 'Новокрасне',
  'UA3506011010': 'Новомихайлівка',
  'UA3506011011': 'Новооникієве',
  'UA3506011012': 'Олексіївка',
  'UA3506011013': 'Оникієве',
  'UA3506011015': 'Пасічне',
  'UA3506011014': 'Павлівка',
  'UA3506011001': 'Велика Виска',
  'UA3506011003': 'Веселівка',
  'UA3506011004': 'Вись',
  'UA3506011005': 'Заріччя',
  'UA3506013003': 'Андріївка',
  'UA3506013004': 'Берегове',
  'UA3506013006': 'Бровкове',
  'UA3506013007': 'Бурти',
  'UA3506013005': 'Бирзулове',
  'UA3506013010': 'Дібрівка',
  'UA3506013009': 'Ганнівка',
  'UA3506013013': 'Іванівка',
  'UA3506013015': 'Кам\'янка',
  'UA3506013016': 'Кам\'януватка',
  'UA3506013017': 'Каніж',
  'UA3506013002': 'Капітанівка',
  'UA3506013018': 'Коробчине',
  'UA3506013019': 'Костянтинівка',
  'UA3506013020': 'Котівка',
  'UA3506013021': 'Лев-Балка',
  'UA3506013023': 'Лікареве',
  'UA3506013022': 'Листопадове',
  'UA3506013026': 'Мар\'янопіль',
  'UA3506013024': 'Мар\'ївка',
  'UA3506013025': 'Мартоноша',
  'UA3506013029': 'Моргунівка',
  'UA3506013030': 'Мостове',
  'UA3506013027': 'Миколаївка',
  'UA3506013028': 'Миролюбівка',
  'UA3506013001': 'Новомиргород',
  'UA3506013031': 'Оситна',
  'UA3506013032': 'Оситняжка',
  'UA3506013033': 'Панчеве',
  'UA3506013034': 'Пенькине',
  'UA3506013035': 'Петроострів',
  'UA3506013037': 'Прищепівка',
  'UA3506013038': 'Пурпурівка',
  'UA3506013036': 'Писарівка',
  'UA3506013039': 'Рівне',
  'UA3506013040': 'Розлива',
  'UA3506013041': 'Рубаний Міст',
  'UA3506013047': 'Щербатівка',
  'UA3506013046': 'Шпакове',
  'UA3506013042': 'Сухолітівка',
  'UA3506013044': 'Троянове',
  'UA3506013045': 'Турія',
  'UA3506013043': 'Тишківка',
  'UA3506013008': 'Василівка',
  'UA3506013014': 'Йосипівка',
  'UA3506013011': 'Защита',
  'UA3506013012': 'Зелене',
  'UA3506015002': 'Арепівка',
  'UA3506015004': 'Далеке',
  'UA3506015014': 'Фурманівка',
  'UA3506015008': 'Кам\'яний Міст',
  'UA3506015009': 'Мар\'янопіль',
  'UA3506015010': 'Новоолександрівка',
  'UA3506015001': 'Новоукраїнка',
  'UA3506015015': 'Щасливка',
  'UA3506015012': 'Схід',
  'UA3506015011': 'Сотницька Балка',
  'UA3506015013': 'Улянівка',
  'UA3506015003': 'Воронівка',
  'UA3506015016': 'Яблунівка',
  'UA3506015005': 'Єгорівка',
  'UA3506015006': 'Захарівка',
  'UA3506015007': 'Звірівка',
  'UA3506017019': 'Червона Поляна',
  'UA3506017004': 'Глиняне',
  'UA3506017006': 'Коколове',
  'UA3506017007': 'Крикунка',
  'UA3506017005': 'Кирилівка',
  'UA3506017008': 'Любомирка',
  'UA3506017011': 'Міжколодяжне',
  'UA3506017009': 'Микільське',
  'UA3506017010': 'Миколаївка',
  'UA3506017012': 'Нова Ковалівка',
  'UA3506017013': 'Новоглиняне',
  'UA3506017014': 'Новопетрівка',
  'UA3506017015': 'Олексіївка',
  'UA3506017017': 'Перчунове',
  'UA3506017016': 'Перемога',
  'UA3506017001': 'Піщаний Брід',
  'UA3506017018': 'Сміливе',
  'UA3506017002': 'Веснянка',
  'UA3506017003': 'Вікняне',
  'UA3506017020': 'Якимівка',
  'UA3506019004': 'Червоний Розділ',
  'UA3506019002': 'Новопавлівка',
  'UA3506019001': 'Помічна',
  'UA3506019003': 'Помічна',
  'UA3506021027': 'Червоний Ташлик',
  'UA3506021008': 'Іванівка',
  'UA3506021009': 'Іванівці',
  'UA3506021012': 'Комишувате',
  'UA3506021028': 'Костянтинівка',
  'UA3506021011': 'Ковалівка',
  'UA3506021010': 'Квітка',
  'UA3506021013': 'Леонтовичеве',
  'UA3506021014': 'Лозуватка',
  'UA3506021015': 'Мала Помічна',
  'UA3506021016': 'Мала Тимошівка',
  'UA3506021017': 'Нерубаївка',
  'UA3506021019': 'Новоєгорівка',
  'UA3506021029': 'Новоселівка',
  'UA3506021018': 'Нововодяне',
  'UA3506021020': 'Піддубне',
  'UA3506021021': 'Піщанське',
  'UA3506021022': 'Радісне',
  'UA3506021001': 'Рівне',
  'UA3506021023': 'Семенасте',
  'UA3506021025': 'Софіївка',
  'UA3506021024': 'Солдатське',
  'UA3506021026': 'Степове',
  'UA3506021002': 'Варварівка',
  'UA3506021003': 'Веселий Кут',
  'UA3506021006': 'Вільне',
  'UA3506021007': 'Водяне',
  'UA3506021005': 'Вишневе',
  'UA3506021004': 'Висоцьке',
  'UA3506023002': 'Андріївка',
  'UA3506023003': 'Березівка',
  'UA3506023006': 'Дорофіївка',
  'UA3506023005': 'Гаївка',
  'UA3506023008': 'Калаколове',
  'UA3506023021': 'Хмельове',
  'UA3506023009': 'Копанки',
  'UA3506023010': 'Межове',
  'UA3506023011': 'Миропіль',
  'UA3506023013': 'Новогригорівка',
  'UA3506023014': 'Новоолександрівка',
  'UA3506023015': 'Новопавлівка',
  'UA3506023016': 'Новопетрівка',
  'UA3506023017': 'Новостанівка',
  'UA3506023012': 'Нововознесенка',
  'UA3506023019': 'П\'ятихатки',
  'UA3506023018': 'Полохівка',
  'UA3506023001': 'Смоліне',
  'UA3506023020': 'Успенівка',
  'UA3506023004': 'Виноградне',
  'UA3506023022': 'Якимівка',
  'UA3506023007': 'Запашка',
  'UA3506025002': 'Андріївка',
  'UA3506025003': 'Богданівка',
  'UA3506025011': 'Федорівка',
  'UA3506025005': 'Гаївка',
  'UA3506025006': 'Новомихайлівка',
  'UA3506025007': 'Новотишківка',
  'UA3506025008': 'Осикове',
  'UA3506025009': 'Показове',
  'UA3506025010': 'Попівка',
  'UA3506025001': 'Тишківка',
  'UA3506025004': 'Вірне',
  'UA3506007002': 'Новаківка',
  'UA3506007003': 'Первомайськ',
  'UA3506007004': 'Плетений Ташлик',
  'UA3506007005': 'Розсохуватка',
  'UA3506007006': 'Шевченкове',
  'UA3506007001': 'Злинка',
  'UA3508003011': 'Гайове',
  'UA3508003003': 'Григорівка',
  'UA3508003012': 'Квітневе',
  'UA3508003004': 'Лозуватка',
  'UA3508003001': 'Нова Прага',
  'UA3508003005': 'Олександро-Пащенкове',
  'UA3508003006': 'Пантазіївка',
  'UA3508003009': 'Шарівка',
  'UA3508003010': 'Шевченкове',
  'UA3508003007': 'Світлопіль',
  'UA3508003008': 'Троянка',
  'UA3508003002': 'Веселка',
  'UA3508005004': 'Гайок',
  'UA3508005005': 'Головківка',
  'UA3508005006': 'Головківське',
  'UA3508005008': 'Іванівка',
  'UA3508005009': 'Ізмайлівка',
  'UA3508005010': 'Королівка',
  'UA3508005011': 'Марто-Іванівка',
  'UA3508005001': 'Олександрія',
  'UA3508005002': 'Олександрійське',
  'UA3508005012': 'Олександро-Степанівка',
  'UA3508005013': 'Піщаний Брід',
  'UA3508005014': 'Пустельникове',
  'UA3508005003': 'Видне',
  'UA3508005007': 'Звенигородка',
  'UA3508007003': 'Байдакове',
  'UA3508007004': 'Браїлівка',
  'UA3508007007': 'Дача',
  'UA3508007008': 'Деріївка',
  'UA3508007026': 'Федорівка',
  'UA3508007010': 'Калачівка',
  'UA3508007011': 'Камбурліївка',
  'UA3508007012': 'Костянтинівка',
  'UA3508007013': 'Куцеволівка',
  'UA3508007015': 'Любівка',
  'UA3508007014': 'Лозуватка',
  'UA3508007016': 'Мар\'ївка',
  'UA3508007017': 'Млинок',
  'UA3508007018': 'Морозівка',
  'UA3508007019': 'Омельник',
  'UA3508007001': 'Онуфріївка',
  'UA3508007020': 'Павловолуйськ',
  'UA3508007002': 'Павлиш',
  'UA3508007021': 'Петрівка',
  'UA3508007023': 'П\'ятомиколаївка',
  'UA3508007022': 'Попівка',
  'UA3508007027': 'Шевченка',
  'UA3508007024': 'Солдатське',
  'UA3508007025': 'Успенка',
  'UA3508007005': 'Василівка',
  'UA3508007006': 'Вишнівці',
  'UA3508007028': 'Ясинуватка',
  'UA3508007009': 'Зибкове',
  'UA3508009002': 'Бандурівка',
  'UA3508009003': 'Диківка',
  'UA3508009004': 'Морозівка',
  'UA3508009005': 'Оліївка',
  'UA3508009001': 'Пантаївка',
  'UA3508009006': 'Ясинуватка',
  'UA3508011002': 'Балахівка',
  'UA3508011004': 'Баштине',
  'UA3508011005': 'Богданівка',
  'UA3508011006': 'Братське',
  'UA3508011039': 'Чечеліївка',
  'UA3508011037': 'Червонокостянтинівка',
  'UA3508011038': 'Червоносілля',
  'UA3508011036': 'Федорівка',
  'UA3508011009': 'Ганнівка',
  'UA3508011013': 'Іскрівка',
  'UA3508011012': 'Іванівка',
  'UA3508011015': 'Козацьке',
  'UA3508011016': 'Краснопілля',
  'UA3508011017': 'Лани',
  'UA3508011018': 'Лелеківка',
  'UA3508011019': 'Луганка',
  'UA3508011020': 'Мала Ганнівка',
  'UA3508011022': 'Маловодяне',
  'UA3508011021': 'Малинівка',
  'UA3508011023': 'Мар\'янівка',
  'UA3508011027': 'Новофедорівка',
  'UA3508011025': 'Новомануйлівка',
  'UA3508011026': 'Новопетрівка',
  'UA3508011024': 'Новий Стародуб',
  'UA3508011028': 'Олександрівка',
  'UA3508011029': 'Олександродар',
  'UA3508011030': 'Олександро-Мар\'ївка',
  'UA3508011031': 'Олімпіадівка',
  'UA3508011001': 'Петрове',
  'UA3508011032': 'Покровка',
  'UA3508011033': 'Рядове',
  'UA3508011034': 'Сабадашеве',
  'UA3508011035': 'Солдатське',
  'UA3508011007': 'Водяне',
  'UA3508011008': 'Володимирівка',
  'UA3508011014': 'Йосипівка',
  'UA3508011010': 'Зелене',
  'UA3508011011': 'Зелений Гай',
  'UA3508013002': 'Братське',
  'UA3508013027': 'Червона Кам\'янка',
  'UA3508013028': 'Червоний Поділ',
  'UA3508013004': 'Дівоче Поле',
  'UA3508013005': 'Добронадіївка',
  'UA3508013006': 'Долинське',
  'UA3508013003': 'Гулевичі',
  'UA3508013011': 'Катеринівка',
  'UA3508013012': 'Куколівка',
  'UA3508013013': 'Медове',
  'UA3508013014': 'Михайлівка',
  'UA3508013015': 'Новозолотарівка',
  'UA3508013016': 'Олександрівка',
  'UA3508013017': 'Пахарівка',
  'UA3508013018': 'Першотравневе',
  'UA3508013019': 'Петрозагір\'я',
  'UA3508013001': 'Попельнасте',
  'UA3508013029': 'Щасливе',
  'UA3508013020': 'Солов\'ївка',
  'UA3508013021': 'Сонине',
  'UA3508013022': 'Тарасівка',
  'UA3508013023': 'Тарасово-Шевченкове',
  'UA3508013024': 'Травневе',
  'UA3508013025': 'Трудівка',
  'UA3508013026': 'Улянівка',
  'UA3508013030': 'Ялинівка',
  'UA3508013007': 'Зелена Балка',
  'UA3508013008': 'Зелене',
  'UA3508013009': 'Зелений Барвінок',
  'UA3508013010': 'Зелений Гай',
  'UA3508015002': 'Андріївка',
  'UA3508015003': 'Березівка',
  'UA3508015004': 'Бутівське',
  'UA3508015008': 'Діброви',
  'UA3508015007': 'Глибоке',
  'UA3508015011': 'Косівка',
  'UA3508015012': 'Костянтинівка',
  'UA3508015013': 'Лікарівка',
  'UA3508015014': 'Мала Березівка',
  'UA3508015015': 'Мар\'ївка',
  'UA3508015016': 'Недогарки',
  'UA3508015017': 'Новоолексіївка',
  'UA3508015025': 'Новоселівка',
  'UA3508015018': 'Новоулянівка',
  'UA3508015019': 'Протопопівка',
  'UA3508015001': 'Приютівка',
  'UA3508015020': 'Рожеве',
  'UA3508015021': 'Степанівка',
  'UA3508015022': 'Сургани',
  'UA3508015024': 'Веселе',
  'UA3508015005': 'Вільний Посад',
  'UA3508015006': 'Войнівка',
  'UA3508015023': 'Ягідне',
  'UA3508015009': 'Занфірівка',
  'UA3508015010': 'Запоріжжя',
  'UA3508017005': 'Мала Скельова',
  'UA3508017006': 'Миронівка',
  'UA3508017008': 'Олексіївка',
  'UA3508017007': 'Озера',
  'UA3508017009': 'Павлівка',
  'UA3508017001': 'Світловодськ',
  'UA3508017010': 'Талова Балка',
  'UA3508017003': 'Велика Скельова',
  'UA3508017004': 'Вільне',
  'UA3508017002': 'Власівка',
  'UA3508001002': 'Арсенівка',
  'UA3508001003': 'Аудиторівка',
  'UA3508001004': 'Бабичівка',
  'UA3508001005': 'Бугруватка',
  'UA3508001024': 'Федірки',
  'UA3508001007': 'Ганнівка',
  'UA3508001008': 'Глинськ',
  'UA3508001009': 'Григорівка',
  'UA3508001012': 'Іванівка',
  'UA3508001013': 'Калантаїв',
  'UA3508001014': 'Кобзарівка',
  'UA3508001015': 'Микільське',
  'UA3508001016': 'Нагірне',
  'UA3508001017': 'Оврагове',
  'UA3508001018': 'Перехрестівка',
  'UA3508001019': 'Подорожнє',
  'UA3508001020': 'Семигір\'я',
  'UA3508001021': 'Серебряне',
  'UA3508001022': 'Сніжкова Балка',
  'UA3508001023': 'Сніжкове',
  'UA3508001001': 'Велика Андрусівка',
  'UA3508001006': 'Вищепанівка',
  'UA3508001025': 'Яремівка',
  'UA3508001010': 'Захарівка',
  'UA3508001011': 'Золотарівка',
  'UA8000000000': 'Київ',
  'UA3202001001': 'Біла Церква',
  'UA3202001009': 'Дрозди',
  'UA3202001005': 'Гайок',
  'UA3202001007': 'Глушки',
  'UA3202001006': 'Глибочка',
  'UA3202001008': 'Городище',
  'UA3202001016': 'Храпачі',
  'UA3202001010': 'Мазепинці',
  'UA3202001012': 'Піщана',
  'UA3202001011': 'Пилипча',
  'UA3202001017': 'Шкарівка',
  'UA3202001014': 'Скребиші',
  'UA3202001013': 'Сидори',
  'UA3202001002': 'Терезине',
  'UA3202001015': 'Томилівка',
  'UA3202001003': 'Вільна Тарасівка',
  'UA3202001004': 'Володимирівка',
  'UA3202025002': 'Андріївка',
  'UA3202025003': 'Безугляки',
  'UA3202025010': 'Чмирівка',
  'UA3202025001': 'Фурси',
  'UA3202025005': 'Мала Михайлівка',
  'UA3202025006': 'Мала Сквирка',
  'UA3202025007': 'Матюші',
  'UA3202025008': 'Пищики',
  'UA3202025009': 'Трушки',
  'UA3202025004': 'Великополовецьке',
  'UA3202025011': 'Яблунівка',
  'UA3202005002': 'Дослідницьке',
  'UA3202005001': 'Гребінки',
  'UA3202005005': 'Ксаверівка Друга',
  'UA3202005004': 'Ксаверівка',
  'UA3202005006': 'Лосятин',
  'UA3202005007': 'Петрівка',
  'UA3202005008': 'Пінчуки',
  'UA3202005009': 'Саливонки',
  'UA3202005010': 'Соколівка',
  'UA3202005011': 'Степанівка',
  'UA3202005012': 'Тростинська Новоселиця',
  'UA3202005003': 'Вільшанська Новоселиця',
  'UA3202007009': 'Червоне',
  'UA3202007001': 'Ковалівка',
  'UA3202007003': 'Кищинці',
  'UA3202007004': 'Мар\'янівка',
  'UA3202007005': 'Паляничинці',
  'UA3202007006': 'Пологи',
  'UA3202007007': 'Пшеничне',
  'UA3202007008': 'Устимівка',
  'UA3202007002': 'Вінницькі Стави',
  'UA3202009002': 'Бакали',
  'UA3202009003': 'Бикова Гребля',
  'UA3202009018': 'Черкас',
  'UA3202009019': 'Черкас',
  'UA3202009020': 'Чупира',
  'UA3202009016': 'Фастівка',
  'UA3202009017': 'Фесюри',
  'UA3202009004': 'Гайок',
  'UA3202009005': 'Клочки',
  'UA3202009007': 'Коржівка',
  'UA3202009006': 'Коженики',
  'UA3202009001': 'Мала Вільшанка',
  'UA3202009008': 'Межове',
  'UA3202009009': 'Одноріг',
  'UA3202009010': 'Озерна',
  'UA3202009011': 'Поправка',
  'UA3202009012': 'Потіївка',
  'UA3202009021': 'Щербаки',
  'UA3202009013': 'Сорокотяги',
  'UA3202009014': 'Тадіївка',
  'UA3202009015': 'Тарасівка',
  'UA3202011002': 'Бране Поле',
  'UA3202011004': 'Дібрівка',
  'UA3202011005': 'Дмитренки',
  'UA3202011003': 'Гута',
  'UA3202011007': 'Коряківка',
  'UA3202011008': 'Красногородка',
  'UA3202011001': 'Медвин',
  'UA3202011009': 'Митаївка',
  'UA3202011010': 'Побережка',
  'UA3202011012': 'Щербашинці',
  'UA3202011011': 'Софійка',
  'UA3202011006': 'Закутинці',
  'UA3202013002': 'Бакумівка',
  'UA3202013004': 'Бушеве',
  'UA3202013003': 'Бирюки',
  'UA3202013007': 'Калинівка',
  'UA3202013008': 'Колесникове',
  'UA3202013010': 'Любка',
  'UA3202013009': 'Луб\'янка',
  'UA3202013011': 'Маківка',
  'UA3202013012': 'Насташка',
  'UA3202013013': 'Нова Маківка',
  'UA3202013014': 'Ольшаниця',
  'UA3202013015': 'Острів',
  'UA3202013016': 'Першотравневе',
  'UA3202013017': 'Пугачівка',
  'UA3202013001': 'Рокитне',
  'UA3202013018': 'Ромашки',
  'UA3202013019': 'Савинці',
  'UA3202013023': 'Шарки',
  'UA3202013020': 'Синява',
  'UA3202013021': 'Телешівка',
  'UA3202013022': 'Троїцьке',
  'UA3202013006': 'Запруддя',
  'UA3202013005': 'Житні Гори',
  'UA3202015002': 'Антонів',
  'UA3202015003': 'Безпечна',
  'UA3202015004': 'Буки',
  'UA3202015041': 'Чубинці',
  'UA3202015008': 'Домантівка',
  'UA3202015009': 'Дулицьке',
  'UA3202015010': 'Дунайка',
  'UA3202015007': 'Горобіївка',
  'UA3202015012': 'Каленна',
  'UA3202015013': 'Кам\'яна Гребля',
  'UA3202015015': 'Кононівка',
  'UA3202015017': 'Краснянка',
  'UA3202015016': 'Красноліси',
  'UA3202015018': 'Кривошиїнці',
  'UA3202015014': 'Квітневе',
  'UA3202015019': 'Лаврики',
  'UA3202015021': 'Малі Лисівці',
  'UA3202015020': 'Малі Єрчики',
  'UA3202015023': 'Мовчанівка',
  'UA3202015022': 'Миньківці',
  'UA3202015024': 'Нова Пустоварівка',
  'UA3202015025': 'Новий Шлях',
  'UA3202015026': 'Оріховець',
  'UA3202015027': 'Пустоварівка',
  'UA3202015029': 'Рогізна',
  'UA3202015030': 'Руда',
  'UA3202015028': 'Рибчинці',
  'UA3202015032': 'Самгородок',
  'UA3202015031': 'Саврань',
  'UA3202015033': 'Селезенівка',
  'UA3202015042': 'Шаліївка',
  'UA3202015043': 'Шамраївка',
  'UA3202015044': 'Шапіївка',
  'UA3202015001': 'Сквира',
  'UA3202015034': 'Таборів',
  'UA3202015035': 'Тарасівка',
  'UA3202015036': 'Терешки',
  'UA3202015038': 'Тхорівка',
  'UA3202015037': 'Токарівка',
  'UA3202015040': 'Цапіївка',
  'UA3202015039': 'Улянівка',
  'UA3202015005': 'Великі Єрчики',
  'UA3202015006': 'Владиславка',
  'UA3202015045': 'Ями',
  'UA3202015011': 'Золотуха',
  'UA3202017002': 'Антонівка',
  'UA3202017003': 'Бесідка',
  'UA3202017004': 'Богатирка',
  'UA3202017005': 'Брилівка',
  'UA3202017028': 'Червоне',
  'UA3202017010': 'Гейсиха',
  'UA3202017011': 'Гостра Могила',
  'UA3202017012': 'Григорівська Слобода',
  'UA3202017014': 'Іванівка',
  'UA3202017015': 'Красилівка',
  'UA3202017016': 'Кривець',
  'UA3202017017': 'Любча',
  'UA3202017018': 'Полковниче',
  'UA3202017019': 'Попружна',
  'UA3202017020': 'Розкішна',
  'UA3202017021': 'Розумниця',
  'UA3202017022': 'Сніжки',
  'UA3202017023': 'Станіславчик',
  'UA3202017001': 'Ставище',
  'UA3202017024': 'Стрижавка',
  'UA3202017025': 'Сухий Яр',
  'UA3202017027': 'Торчиця',
  'UA3202017026': 'Торчицький Степок',
  'UA3202017006': 'Василиха',
  'UA3202017007': 'Веселе',
  'UA3202017008': 'Винарівка',
  'UA3202017009': 'Вишківське',
  'UA3202017030': 'Ясенівка',
  'UA3202017029': 'Юрківка',
  'UA3202017013': 'Журавлиха',
  'UA3202019002': 'Антонівка',
  'UA3202019003': 'Бовкун',
  'UA3202019004': 'Буда',
  'UA3202019034': 'Чернин',
  'UA3202019033': 'Червоні Яри',
  'UA3202019009': 'Дубівка',
  'UA3202019010': 'Калинове',
  'UA3202019013': 'Ківшовата',
  'UA3202019014': 'Косяківка',
  'UA3202019015': 'Красюки',
  'UA3202019017': 'Круті Горби',
  'UA3202019016': 'Крива',
  'UA3202019011': 'Кирдани',
  'UA3202019012': 'Кислівка',
  'UA3202019018': 'Лісовичі',
  'UA3202019019': 'Лука',
  'UA3202019020': 'Лук\'янівка',
  'UA3202019021': 'Маковецьке',
  'UA3202019022': 'Мала Березянка',
  'UA3202019023': 'Мала Вовнянка',
  'UA3202019024': 'Малоберезанське',
  'UA3202019025': 'Плоске',
  'UA3202019026': 'Потоки',
  'UA3202019027': 'Ріжки',
  'UA3202019028': 'Салиха',
  'UA3202019029': 'Северинівка',
  'UA3202019030': 'Станишівка',
  'UA3202019031': 'Степок',
  'UA3202019001': 'Тараща',
  'UA3202019032': 'Улашівка',
  'UA3202019005': 'Велика Березянка',
  'UA3202019006': 'Велика Вовнянка',
  'UA3202019007': 'Веселий Кут',
  'UA3202019008': 'Володимирівка',
  'UA3202019035': 'Юшків Ріг',
  'UA3202021002': 'Бурківці',
  'UA3202021032': 'Черепин',
  'UA3202021033': 'Черепинка',
  'UA3202021008': 'Денихівка',
  'UA3202021010': 'Дібрівка',
  'UA3202021011': 'Дубина',
  'UA3202021009': 'Дзвеняче',
  'UA3202021004': 'Галайки',
  'UA3202021005': 'Голодьки',
  'UA3202021006': 'Горошків',
  'UA3202021007': 'Григорівка',
  'UA3202021012': 'Кашперівка',
  'UA3202021031': 'Хмелівка',
  'UA3202021013': 'Клюки',
  'UA3202021014': 'Кошів',
  'UA3202021016': 'Молочне',
  'UA3202021015': 'Михайлівка',
  'UA3202021017': 'Ненадиха',
  'UA3202021018': 'Одайпіль',
  'UA3202021019': 'Перше Травня',
  'UA3202021021': 'П\'ятигори',
  'UA3202021020': 'Погреби',
  'UA3202021022': 'Ріденьке',
  'UA3202021023': 'Росішки',
  'UA3202021024': 'Скибинці',
  'UA3202021025': 'Софіпіль',
  'UA3202021026': 'Стадниця',
  'UA3202021027': 'Степове',
  'UA3202021028': 'Тайниця',
  'UA3202021029': 'Тарасівка',
  'UA3202021030': 'Теліжинці',
  'UA3202021001': 'Тетіїв',
  'UA3202021003': 'Високе',
  'UA3202023002': 'Блощинці',
  'UA3202023020': 'Чепиліївка',
  'UA3202023006': 'Іванівка',
  'UA3202023008': 'Красне',
  'UA3202023009': 'Людвинівка',
  'UA3202023010': 'Макіївка',
  'UA3202023011': 'Мала Антонівка',
  'UA3202023012': 'Михайлівка',
  'UA3202023013': 'Олійникова Слобода',
  'UA3202023014': 'Острійки',
  'UA3202023015': 'Павлівка',
  'UA3202023016': 'Розаліївка',
  'UA3202023017': 'Степок',
  'UA3202023018': 'Сухоліси',
  'UA3202023019': 'Тарасівка',
  'UA3202023001': 'Узин',
  'UA3202023003': 'Василів',
  'UA3202023004': 'Вербова',
  'UA3202023007': 'Йосипівка',
  'UA3202023005': 'Затиша',
  'UA3202003002': 'Березна',
  'UA3202003003': 'Біліївка',
  'UA3202003032': 'Чепіжинці',
  'UA3202003008': 'Дружба',
  'UA3202003005': 'Гайворон',
  'UA3202003006': 'Городище-Косівське',
  'UA3202003007': 'Городище-Пустоварівське',
  'UA3202003011': 'Капустинці',
  'UA3202003012': 'Кленове',
  'UA3202003013': 'Коржиха',
  'UA3202003014': 'Косівка',
  'UA3202003016': 'Лобачів',
  'UA3202003017': 'Логвин',
  'UA3202003015': 'Лихачиха',
  'UA3202003018': 'Мармуліївка',
  'UA3202003019': 'Матвіїха',
  'UA3202003020': 'Михайлівка',
  'UA3202003021': 'Надросівка',
  'UA3202003022': 'Нове Життя',
  'UA3202003023': 'Ожегівка',
  'UA3202003024': 'Пархомівка',
  'UA3202003025': 'Петрашівка',
  'UA3202003027': 'Рачки',
  'UA3202003026': 'Ратуш',
  'UA3202003028': 'Рогізна',
  'UA3202003029': 'Рубченки',
  'UA3202003030': 'Руде Село',
  'UA3202003033': 'Шевченкове',
  'UA3202003031': 'Тарган',
  'UA3202003034': 'Веснянка',
  'UA3202003001': 'Володарка',
  'UA3202003004': 'Володимирівка',
  'UA3202003009': 'Завадівка',
  'UA3202003010': 'Зрайки',
  'UA3204001002': 'Андріївка',
  'UA3204001003': 'Артемівка',
  'UA3204001001': 'Бориспіль',
  'UA3204001005': 'Глибоке',
  'UA3204001006': 'Горобіївка',
  'UA3204001007': 'Городище',
  'UA3204001008': 'Григорівка',
  'UA3204001009': 'Іванків',
  'UA3204001011': 'Кучаків',
  'UA3204001010': 'Кириївщина',
  'UA3204001012': 'Лебедин',
  'UA3204001013': 'Любарці',
  'UA3204001014': 'Мала Стариця',
  'UA3204001015': 'Перегуди',
  'UA3204001016': 'Рогозів',
  'UA3204001017': 'Сеньківка',
  'UA3204001018': 'Сулимівка',
  'UA3204001019': 'Тарасівка',
  'UA3204001004': 'Велика Стариця',
  'UA3204007001': 'Дівички',
  'UA3204007003': 'Гречаники',
  'UA3204007005': 'Кавказ',
  'UA3204007006': 'Ковалин',
  'UA3204007007': 'Стовп\'яги',
  'UA3204007002': 'Веселе',
  'UA3204007004': 'Єрківці',
  'UA3204005001': 'Гора',
  'UA3204005003': 'Мартусівка',
  'UA3204005004': 'Ревне',
  'UA3204005002': 'Затишне',
  'UA3204011013': 'Чирське',
  'UA3204011007': 'Дем\'янці',
  'UA3204011008': 'Довга Гребля',
  'UA3204011004': 'Гайшин',
  'UA3204011005': 'Гланишів',
  'UA3204011006': 'Гребля',
  'UA3204011012': 'Харківці',
  'UA3204011010': 'Мар\'янівка',
  'UA3204011009': 'Мазінки',
  'UA3204011001': 'Переяслав',
  'UA3204011011': 'Плескачі',
  'UA3204011002': 'Велика Каратуль',
  'UA3204011003': 'Вовчків',
  'UA3204013002': 'Безуглівка',
  'UA3204013008': 'Чубинське',
  'UA3204013004': 'Дударків',
  'UA3204013006': 'Мала Олександрівка',
  'UA3204013007': 'Проліски',
  'UA3204013001': 'Щасливе',
  'UA3204013003': 'Велика Олександрівка',
  'UA3204013005': 'Займище',
  'UA3204015003': 'Козлів',
  'UA3204015004': 'Леляки',
  'UA3204015005': 'Переяславське',
  'UA3204015006': 'Пристроми',
  'UA3204015007': 'Семенівка',
  'UA3204015008': 'Сомкова Долина',
  'UA3204015009': 'Соснівка',
  'UA3204015010': 'Соснова',
  'UA3204015011': 'Строкова',
  'UA3204015001': 'Студеники',
  'UA3204015002': 'Заострів',
  'UA3204017015': 'Чопилки',
  'UA3204017005': 'Дениси',
  'UA3204017004': 'Горбані',
  'UA3204017006': 'Мала Каратуль',
  'UA3204017007': 'Натягайлівка',
  'UA3204017008': 'Перше Травня',
  'UA3204017009': 'Пологи-Вергуни',
  'UA3204017010': 'Положаї',
  'UA3204017011': 'Помоклі',
  'UA3204017016': 'Шевченкове',
  'UA3204017012': 'Тарасівка',
  'UA3204017001': 'Ташань',
  'UA3204017013': 'Травневе',
  'UA3204017014': 'Улянівка',
  'UA3204017003': 'Воскресенське',
  'UA3204017002': 'Виповзки',
  'UA3204019007': 'Хоцьки',
  'UA3204019003': 'Лецьки',
  'UA3204019004': 'Пологи-Чобітки',
  'UA3204019005': 'Пологи-Яненки',
  'UA3204019006': 'Світанок',
  'UA3204019001': 'Циблі',
  'UA3204019002': 'Вінинці',
  'UA3204003003': 'Головурів',
  'UA3204003005': 'Кийлів',
  'UA3204003006': 'Малі Єрківці',
  'UA3204003007': 'Мирне',
  'UA3204003008': 'Проців',
  'UA3204003009': 'Сошників',
  'UA3204003010': 'Старе',
  'UA3204003002': 'Васильки',
  'UA3204003001': 'Вороньків',
  'UA3204003004': 'Жереб\'ятин',
  'UA3204021002': 'Богданівка',
  'UA3204021003': 'Божки',
  'UA3204021038': 'Черкасівка',
  'UA3204021039': 'Черняхівка',
  'UA3204021042': 'Черняхівка',
  'UA3204021037': 'Червоне Заріччя',
  'UA3204021036': 'Червоне',
  'UA3204021010': 'Добраничівка',
  'UA3204021008': 'Двірківщина',
  'UA3204021009': 'Дзюбівка',
  'UA3204021034': 'Фарбоване',
  'UA3204021035': 'Федорівка',
  'UA3204021005': 'Гензерівка',
  'UA3204021006': 'Годунівка',
  'UA3204021041': 'Гречанівка',
  'UA3204021007': 'Григорівка',
  'UA3204021013': 'Кайнари',
  'UA3204021014': 'Капустинці',
  'UA3204021015': 'Коптевичівка',
  'UA3204021016': 'Кулябівка',
  'UA3204021017': 'Лебедівка',
  'UA3204021018': 'Лемешівка',
  'UA3204021019': 'Лозовий Яр',
  'UA3204021020': 'Лукомщина',
  'UA3204021021': 'Ничипорівка',
  'UA3204021022': 'Озерне',
  'UA3204021023': 'Панфили',
  'UA3204021024': 'Плужники',
  'UA3204021025': 'Райківщина',
  'UA3204021027': 'Рокитне',
  'UA3204021026': 'Розумівка',
  'UA3204021040': 'Шевченкове',
  'UA3204021028': 'Соколівщина',
  'UA3204021029': 'Сотниківка',
  'UA3204021030': 'Сулимівка',
  'UA3204021031': 'Супоївка',
  'UA3204021032': 'Трубівщина',
  'UA3204021033': 'Тужилів',
  'UA3204021004': 'Воронівщина',
  'UA3204021001': 'Яготин',
  'UA3204021012': 'Засупоївка',
  'UA3204021011': 'Жоравка',
  'UA3204009001': 'Гнідин',
  'UA3204009003': 'Петропавлівське',
  'UA3204009002': 'Вишеньки',
  'UA3206001002': 'Бакумівка',
  'UA3206001001': 'Баришівка',
  'UA3206001004': 'Борщів',
  'UA3206001003': 'Бзів',
  'UA3206001009': 'Дернівка',
  'UA3206001008': 'Гостролуччя',
  'UA3206001026': 'Хлопків',
  'UA3206001011': 'Корніївка',
  'UA3206001010': 'Коржі',
  'UA3206001012': 'Лукаші',
  'UA3206001013': 'Лук\'янівка',
  'UA3206001014': 'Мала Тарасівка',
  'UA3206001015': 'Масківці',
  'UA3206001016': 'Морозівка',
  'UA3206001017': 'Паришків',
  'UA3206001018': 'Пасічна',
  'UA3206001019': 'Перемога',
  'UA3206001020': 'Поділля',
  'UA3206001021': 'Рудницьке',
  'UA3206001023': 'Селичівка',
  'UA3206001024': 'Селище',
  'UA3206001022': 'Сезенків',
  'UA3206001028': 'Шовкове',
  'UA3206001027': 'Швачиха',
  'UA3206001025': 'Устинкова Гребля',
  'UA3206001005': 'Веселинівка',
  'UA3206001006': 'Власівка',
  'UA3206001007': 'Волошинівка',
  'UA3206003001': 'Березань',
  'UA3206003003': 'Дубове',
  'UA3206003002': 'Григорівка',
  'UA3206003008': 'Хмельовик',
  'UA3206003004': 'Лехнівка',
  'UA3206003005': 'Недра',
  'UA3206003006': 'Пилипче',
  'UA3206003007': 'Садове',
  'UA3206003009': 'Яблуневе',
  'UA3206003010': 'Ярешки',
  'UA3206005001': 'Бровари',
  'UA3206005002': 'Княжичі',
  'UA3206005003': 'Переможець',
  'UA3206005004': 'Требухів',
  'UA3206013001': 'Калинівка',
  'UA3206013003': 'Красилівка',
  'UA3206013002': 'Квітневе',
  'UA3206013004': 'Перемога',
  'UA3206013005': 'Рожівка',
  'UA3206013006': 'Скибин',
  'UA3206015002': 'Бервиця',
  'UA3206015001': 'Калита',
  'UA3206015004': 'Мокрець',
  'UA3206015005': 'Опанасів',
  'UA3206015006': 'Семиполки',
  'UA3206015003': 'Заворичі',
  'UA3206007002': 'Бобрик',
  'UA3206007003': 'Богданівка',
  'UA3206007005': 'Гайове',
  'UA3206007006': 'Гоголів',
  'UA3206007007': 'Гребельки',
  'UA3206007012': 'Кулажинці',
  'UA3206007013': 'Михайлівка',
  'UA3206007014': 'Перше Травня',
  'UA3206007015': 'Першотравневе',
  'UA3206007016': 'Підлісся',
  'UA3206007017': 'Плоске',
  'UA3206007018': 'Покровське',
  'UA3206007019': 'Рудня',
  'UA3206007020': 'Русанів',
  'UA3206007023': 'Шевченкове',
  'UA3206007021': 'Світильня',
  'UA3206007022': 'Тарасівка',
  'UA3206007001': 'Велика Димерка',
  'UA3206007004': 'Вільне',
  'UA3206007010': 'Захарівка',
  'UA3206007009': 'Залісся',
  'UA3206007008': 'Жердова',
  'UA3206007011': 'Зоря',
  'UA3206009002': 'Літки',
  'UA3206009003': 'Літочки',
  'UA3206009004': 'Погреби',
  'UA3206009005': 'Пухівка',
  'UA3206009006': 'Рожни',
  'UA3206009007': 'Соболівка',
  'UA3206009001': 'Зазим\'я',
  'UA3206011002': 'Аркадіївка',
  'UA3206011003': 'Безуглівка',
  'UA3206011039': 'Черевки',
  'UA3206011010': 'Горбачівка',
  'UA3206011011': 'Гречана Гребля',
  'UA3206011014': 'Іллінське',
  'UA3206011015': 'Красне',
  'UA3206011016': 'Левченкове',
  'UA3206011018': 'Любомирівка',
  'UA3206011017': 'Лизогубова Слобода',
  'UA3206011019': 'Майське',
  'UA3206011020': 'Мала Березанка',
  'UA3206011021': 'Мала Супоївка',
  'UA3206011022': 'Малий Крупіль',
  'UA3206011023': 'Нова Олександрівка',
  'UA3206011024': 'Нова Оржиця',
  'UA3206011025': 'Олександринівка',
  'UA3206011026': 'Олексіївка',
  'UA3206011027': 'Пайки',
  'UA3206011028': 'Пасківщина',
  'UA3206011029': 'Полковниче',
  'UA3206011031': 'Середівка',
  'UA3206011041': 'Щасливе',
  'UA3206011040': 'Шевченкове',
  'UA3206011032': 'Софіївка',
  'UA3206011033': 'Стара Оржиця',
  'UA3206011034': 'Старе',
  'UA3206011030': 'Свобода',
  'UA3206011035': 'Терлещина',
  'UA3206011036': 'Турівка',
  'UA3206011037': 'Урсалівка',
  'UA3206011038': 'Усівка',
  'UA3206011004': 'Великий Крупіль',
  'UA3206011006': 'Вільне',
  'UA3206011008': 'Войтове',
  'UA3206011009': 'Володимирське',
  'UA3206011007': 'Вознесенське',
  'UA3206011005': 'Вишневе',
  'UA3206011013': 'Зелене',
  'UA3206011001': 'Згурівка',
  'UA3206011012': 'Жуківка',
  'UA3208001001': 'Білогородка',
  'UA3208001002': 'Бобриця',
  'UA3208001003': 'Гнатівка',
  'UA3208001004': 'Гореничі',
  'UA3208001005': 'Лука',
  'UA3208001006': 'Музичі',
  'UA3208001007': 'Неграші',
  'UA3208001010': 'Шевченкове',
  'UA3208001009': 'Стоянка',
  'UA3208001008': 'Святопетрівське',
  'UA3208003002': 'Берестянка',
  'UA3208003003': 'Бондарня',
  'UA3208003001': 'Бородянка',
  'UA3208003010': 'Дмитрівка',
  'UA3208003011': 'Дружня',
  'UA3208003008': 'Гай',
  'UA3208003009': 'Галинка',
  'UA3208003013': 'Качали',
  'UA3208003015': 'Коблиця',
  'UA3208003014': 'Коблицький Ліс',
  'UA3208003016': 'Красний Ріг',
  'UA3208003017': 'Майданівка',
  'UA3208003019': 'Михайленків',
  'UA3208003018': 'Мирча',
  'UA3208003020': 'Небрат',
  'UA3208003021': 'Нова Буда',
  'UA3208003022': 'Нова Гребля',
  'UA3208003023': 'Нове Залісся',
  'UA3208003024': 'Новий Корогод',
  'UA3208003025': 'Озерщина',
  'UA3208003027': 'Поташня',
  'UA3208003026': 'Пилиповичі',
  'UA3208003031': 'Шибене',
  'UA3208003028': 'Стара Буда',
  'UA3208003029': 'Тальське',
  'UA3208003030': 'Торф\'яне',
  'UA3208003004': 'Вабля',
  'UA3208003005': 'Великий Ліс',
  'UA3208003007': 'Волиця',
  'UA3208003006': 'Вишняки',
  'UA3208003032': 'Язвинка',
  'UA3208003012': 'Загальці',
  'UA3208005003': 'Чайки',
  'UA3208005001': 'Петропавлівська Борщагівк',
  'UA3208005002': 'Софіївська Борщагівка',
  'UA3208007002': 'Бабинці',
  'UA3208007004': 'Блиставиця',
  'UA3208007001': 'Буча',
  'UA3208007005': 'Буда-Бабинецька',
  'UA3208007014': 'Червоне',
  'UA3208007007': 'Гаврилівка',
  'UA3208007009': 'Луб\'янка',
  'UA3208007010': 'Мироцьке',
  'UA3208007011': 'Раківка',
  'UA3208007012': 'Синяк',
  'UA3208007013': 'Тарасівщина',
  'UA3208007006': 'Вороньківка',
  'UA3208007003': 'Ворзель',
  'UA3208007008': 'Здвижівка',
  'UA3208013003': 'Буча',
  'UA3208013002': 'Бузова',
  'UA3208013001': 'Дмитрівка',
  'UA3208013004': 'Горбовичі',
  'UA3208013005': 'Гурівщина',
  'UA3208013006': 'Капітанівка',
  'UA3208013013': 'Хмільна',
  'UA3208013008': 'Лісне',
  'UA3208013009': 'Любимівка',
  'UA3208013007': 'Личанка',
  'UA3208013011': 'Мрія',
  'UA3208013010': 'Мила',
  'UA3208013012': 'Петрушки',
  'UA3208013014': 'Шпитьки',
  'UA3208011002': 'Горенка',
  'UA3208011001': 'Гостомель',
  'UA3208011003': 'Мощун',
  'UA3208011004': 'Озера',
  'UA3208015002': 'Діброва',
  'UA3208015001': 'Ірпінь',
  'UA3208015004': 'Козинці',
  'UA3208015005': 'Михайлівка-Рубежівка',
  'UA3208015003': 'Забуччя',
  'UA3208017001': 'Коцюбинське',
  'UA3208019003': 'Андріївка',
  'UA3208019004': 'Березівка',
  'UA3208019005': 'Борівка',
  'UA3208019046': 'Червона Гірка',
  'UA3208019047': 'Червона Слобода',
  'UA3208019043': 'Фасівочка',
  'UA3208019044': 'Фасова',
  'UA3208019045': 'Ферма',
  'UA3208019010': 'Гавронщина',
  'UA3208019014': 'Калинівка',
  'UA3208019002': 'Кодра',
  'UA3208019015': 'Колонщина',
  'UA3208019016': 'Комарівка',
  'UA3208019018': 'Копіївка',
  'UA3208019017': 'Копилів',
  'UA3208019019': 'Королівка',
  'UA3208019023': 'Людвинівка',
  'UA3208019022': 'Лозовик',
  'UA3208019020': 'Липівка',
  'UA3208019021': 'Лисиця',
  'UA3208019001': 'Макарів',
  'UA3208019024': 'Макарівська Буда',
  'UA3208019025': 'Маковище',
  'UA3208019026': 'Малий Карашин',
  'UA3208019027': 'Мар\'янівка',
  'UA3208019028': 'Мар\'янівка',
  'UA3208019030': 'Мотижин',
  'UA3208019029': 'Миколаївка',
  'UA3208019031': 'Наливайківка',
  'UA3208019032': 'Небелиця',
  'UA3208019033': 'Ніжиловичі',
  'UA3208019034': 'Новомирівка',
  'UA3208019035': 'Пашківка',
  'UA3208019036': 'Плахтянка',
  'UA3208019037': 'Почепин',
  'UA3208019038': 'Рожів',
  'UA3208019039': 'Садки-Строївка',
  'UA3208019040': 'Северинівка',
  'UA3208019048': 'Шнурів Ліс',
  'UA3208019042': 'Соболівка',
  'UA3208019041': 'Ситняки',
  'UA3208019006': 'Великий Карашин',
  'UA3208019008': 'Вітрівка',
  'UA3208019009': 'Волосінь',
  'UA3208019007': 'Вишеград',
  'UA3208019049': 'Юрів',
  'UA3208019011': 'Забуяння',
  'UA3208019012': 'Завалівка',
  'UA3208019013': 'Зурівка',
  'UA3208021002': 'Клавдієво-Тарасове',
  'UA3208021003': 'Микуличі',
  'UA3208021001': 'Немішаєве',
  'UA3208021004': 'Пороскотень',
  'UA3208023002': 'Мигалки',
  'UA3208023001': 'Пісківка',
  'UA3208023003': 'Раска',
  'UA3208009002': 'Крюківщина',
  'UA3208009001': 'Вишневе',
  'UA3200000002': 'Чорнобиль',
  'UA3200000001': 'Прип\'ять',
  'UA3214003001': 'Боярка',
  'UA3214003002': 'Дзвінкове',
  'UA3214003005': 'Іванків',
  'UA3214003006': 'Княжичі',
  'UA3214003007': 'Малютянка',
  'UA3214003008': 'Нове',
  'UA3214003009': 'Новосілки',
  'UA3214003010': 'Перевіз',
  'UA3214003011': 'Тарасівка',
  'UA3214003004': 'Забір\'я',
  'UA3214003003': 'Жорнівка',
  'UA3214001001': 'Бишів',
  'UA3214001013': 'Чорногородка',
  'UA3214001012': 'Ферма',
  'UA3214001003': 'Горобіївка',
  'UA3214001004': 'Грузьке',
  'UA3214001005': 'Козичанка',
  'UA3214001006': 'Леонівка',
  'UA3214001008': 'Лубське',
  'UA3214001007': 'Лишня',
  'UA3214001009': 'Мостище',
  'UA3214001010': 'Нові Опачичі',
  'UA3214001011': 'Осикове',
  'UA3214001002': 'Весела Слобідка',
  'UA3214001014': 'Яблунівка',
  'UA3214001015': 'Ясногородка',
  'UA3214017001': 'Чабани',
  'UA3214017002': 'Новосілки',
  'UA3214015002': 'Борова',
  'UA3214015003': 'Бортники',
  'UA3214015001': 'Фастів',
  'UA3214015018': 'Фастівець',
  'UA3214015009': 'Гвардійське',
  'UA3214015010': 'Клехівка',
  'UA3214015011': 'Мала Офірна',
  'UA3214015012': 'Мала Снітинка',
  'UA3214015013': 'Млинок',
  'UA3214015014': 'Мотовилівка',
  'UA3214015015': 'Мотовилівська Слобідка',
  'UA3214015016': 'Оленівка',
  'UA3214015017': 'Тарасенки',
  'UA3214015004': 'Велика Мотовилівка',
  'UA3214015005': 'Велика Офірна',
  'UA3214015006': 'Велика Снітинка',
  'UA3214015007': 'Веприк',
  'UA3214015008': 'Вишняки',
  'UA3214005001': 'Гатне',
  'UA3214005002': 'Віта-Поштова',
  'UA3214005003': 'Юрівка',
  'UA3214007002': 'Березенщина',
  'UA3214007003': 'Борисів',
  'UA3214007004': 'Дерев\'янки',
  'UA3214007001': 'Глеваха',
  'UA3214007007': 'Кобці',
  'UA3214007009': 'Крячки',
  'UA3214007008': 'Крушинка',
  'UA3214007010': 'Мала Бугаївка',
  'UA3214007011': 'Мархалівка',
  'UA3214007012': 'Путрівка',
  'UA3214007005': 'Зайців',
  'UA3214007006': 'Залізне',
  'UA3214007013': 'Зелений Бір',
  'UA3214009002': 'Багрин',
  'UA3214009003': 'Бобриця',
  'UA3214009006': 'Данилівка',
  'UA3214009007': 'Діброва',
  'UA3214009001': 'Калинівка',
  'UA3214009015': 'Хлепча',
  'UA3214009008': 'Кожухівка',
  'UA3214009009': 'Липовий Скиток',
  'UA3214009010': 'Мала Солтанівка',
  'UA3214009011': 'Плесецьке',
  'UA3214009012': 'Порадівка',
  'UA3214009013': 'Руликів',
  'UA3214009014': 'Скрипки',
  'UA3214009004': 'Варовичі',
  'UA3214009005': 'Велика Солтанівка',
  'UA3214011003': 'Дмитрівка',
  'UA3214011005': 'Королівка',
  'UA3214011001': 'Кожанка',
  'UA3214011006': 'Малополовецьке',
  'UA3214011008': 'Півні',
  'UA3214011007': 'Пилипівка',
  'UA3214011009': 'Скригалівка',
  'UA3214011010': 'Софіївка',
  'UA3214011011': 'Ставки',
  'UA3214011015': 'Степове',
  'UA3214011012': 'Тарасівка',
  'UA3214011013': 'Триліси',
  'UA3214011002': 'Волиця',
  'UA3214011014': 'Яхни',
  'UA3214011004': 'Єлизаветівка',
  'UA3214013007': 'Деминівка',
  'UA3214013008': 'Дідівщина',
  'UA3214013009': 'Дорогинка',
  'UA3214013015': 'Федорівка',
  'UA3214013011': 'Кончаки',
  'UA3214013010': 'Конопельки',
  'UA3214013012': 'Кощіївка',
  'UA3214013013': 'Пришивальня',
  'UA3214013014': 'Соснівка',
  'UA3214013001': 'Томашівка',
  'UA3214013002': 'Василівка',
  'UA3214013003': 'Великі Гуляки',
  'UA3214013005': 'Вільне',
  'UA3214013006': 'Вільшанська Нива',
  'UA3214013004': 'Вишня',
  'UA3214013017': 'Ярошівка',
  'UA3214013016': 'Юрівка',
  'UA3212001002': 'Біївці',
  'UA3212001001': 'Богуслав',
  'UA3212001003': 'Бородані',
  'UA3212001024': 'Чайки',
  'UA3212001005': 'Дешки',
  'UA3212001006': 'Дибинці',
  'UA3212001008': 'Ісайки',
  'UA3212001007': 'Івки',
  'UA3212001009': 'Калинівка',
  'UA3212001010': 'Карандинці',
  'UA3212001023': 'Хохітва',
  'UA3212001011': 'Киданівка',
  'UA3212001012': 'Лютарі',
  'UA3212001014': 'Москаленки',
  'UA3212001013': 'Мисайлівка',
  'UA3212001015': 'Половецьке',
  'UA3212001016': 'Поташня',
  'UA3212001017': 'Розкопанці',
  'UA3212001018': 'Саварка',
  'UA3212001019': 'Семигори',
  'UA3212001025': 'Шупики',
  'UA3212001020': 'Синиця',
  'UA3212001021': 'Тептіївка',
  'UA3212001022': 'Туники',
  'UA3212001004': 'Вільховець',
  'UA3212001026': 'Яцюки',
  'UA3212017002': 'Гвоздів',
  'UA3212017003': 'Іванковичі',
  'UA3212017001': 'Ходосівка',
  'UA3212017008': 'Хотів',
  'UA3212017004': 'Кременище',
  'UA3212017005': 'Круглик',
  'UA3212017006': 'Лісники',
  'UA3212017007': 'Рославичі',
  'UA3212005002': 'Антонівка',
  'UA3212005003': 'Бендюгівка',
  'UA3212005004': 'Бурти',
  'UA3212005034': 'Черняхів',
  'UA3212005010': 'Демівщина',
  'UA3212005007': 'Горохівське',
  'UA3212005008': 'Горохове',
  'UA3212005009': 'Горохуватка',
  'UA3212005015': 'Іванівка',
  'UA3212005016': 'Кадомка',
  'UA3212005001': 'Кагарлик',
  'UA3212005017': 'Калинівка',
  'UA3212005033': 'Халча',
  'UA3212005018': 'Леонівка',
  'UA3212005020': 'Ліщинка',
  'UA3212005019': 'Липовець',
  'UA3212005021': 'Мирівка',
  'UA3212005022': 'Новосілки',
  'UA3212005024': 'Очеретяне',
  'UA3212005023': 'Оріхове',
  'UA3212005025': 'Переселення',
  'UA3212005026': 'Расавка',
  'UA3212005027': 'Расавка',
  'UA3212005035': 'Шпендівка',
  'UA3212005036': 'Шубівка',
  'UA3212005028': 'Слобода',
  'UA3212005029': 'Стави',
  'UA3212005030': 'Сущани',
  'UA3212005031': 'Тарасівка',
  'UA3212005032': 'Тернівка',
  'UA3212005006': 'Воронівка',
  'UA3212005005': 'Виселкове',
  'UA3212005011': 'Зелений Яр',
  'UA3212005012': 'Землянка',
  'UA3212005013': 'Зікрачі',
  'UA3212005014': 'Зорівка',
  'UA3212007002': 'Березове',
  'UA3212007004': 'Капустяна',
  'UA3212007005': 'Конюша',
  'UA3212007001': 'Козин',
  'UA3212007006': 'Креничі',
  'UA3212007007': 'Малі Дмитровичі',
  'UA3212007008': 'Нові Безрадичі',
  'UA3212007009': 'Паращина',
  'UA3212007010': 'Підгірці',
  'UA3212007011': 'Романків',
  'UA3212007012': 'Старі Безрадичі',
  'UA3212007013': 'Тарасівка',
  'UA3212007003': 'Великі Дмитровичі',
  'UA3212009002': 'Андріївка',
  'UA3212009036': 'Фролівка',
  'UA3212009006': 'Горобіївка',
  'UA3212009007': 'Гулі',
  'UA3212009010': 'Іванівка',
  'UA3212009011': 'Карапиші',
  'UA3212009014': 'Коритище',
  'UA3212009013': 'Козин',
  'UA3212009015': 'Кулешів',
  'UA3212009016': 'Кутелів',
  'UA3212009012': 'Кип\'ячка',
  'UA3212009017': 'Македони',
  'UA3212009018': 'Малі Прицьки',
  'UA3212009019': 'Маслівка',
  'UA3212009020': 'Матвіївка',
  'UA3212009022': 'Михайлівка',
  'UA3212009021': 'Микитяни',
  'UA3212009001': 'Миронівка',
  'UA3212009023': 'Нова Миронівка',
  'UA3212009024': 'Нова Олександрівка',
  'UA3212009025': 'Олександрівка',
  'UA3212009026': 'Олексіївка',
  'UA3212009030': 'П\'ятихатка',
  'UA3212009027': 'Польове',
  'UA3212009028': 'Потік',
  'UA3212009029': 'Пустовіти',
  'UA3212009031': 'Росава',
  'UA3212009032': 'Салів',
  'UA3212009038': 'Шандра',
  'UA3212009033': 'Світле',
  'UA3212009034': 'Тарасівка',
  'UA3212009037': 'Центральне',
  'UA3212009035': 'Тулинці',
  'UA3212009003': 'Вахутинці',
  'UA3212009004': 'Вікторівка',
  'UA3212009005': 'Владиславка',
  'UA3212009040': 'Яхни',
  'UA3212009008': 'Ємчиха',
  'UA3212009039': 'Юхни',
  'UA3212009009': 'Зеленьки',
  'UA3212011002': 'Безіменне',
  'UA3212011007': 'Деремезна',
  'UA3212011006': 'Дерев\'яна',
  'UA3212011008': 'Долина',
  'UA3212011003': 'Германівка',
  'UA3212011004': 'Григорівка',
  'UA3212011005': 'Гусачівка',
  'UA3212011011': 'Копачів',
  'UA3212011010': 'Козіївка',
  'UA3212011012': 'Красна Слобідка',
  'UA3212011013': 'Красне Перше',
  'UA3212011014': 'Кулі',
  'UA3212011015': 'Ленди',
  'UA3212011016': 'Макарівка',
  'UA3212011017': 'Мала Вільшанка',
  'UA3212011018': 'Матяшівка',
  'UA3212011019': 'Нещерів',
  'UA3212011001': 'Обухів',
  'UA3212011020': 'Перегонівка',
  'UA3212011021': 'Перше Травня',
  'UA3212011022': 'Семенівка',
  'UA3212011025': 'Шевченкове',
  'UA3212011023': 'Степок',
  'UA3212011024': 'Таценки',
  'UA3212011009': 'Застугна',
  'UA3212013002': 'Балико-Щучинка',
  'UA3212013009': 'Дібрівка',
  'UA3212013010': 'Дударі',
  'UA3212013007': 'Гребені',
  'UA3212013008': 'Грушів',
  'UA3212013022': 'Ходорів',
  'UA3212013011': 'Кузьминці',
  'UA3212013012': 'Липовий Ріг',
  'UA3212013013': 'Малий Букрин',
  'UA3212013014': 'Онацьки',
  'UA3212013015': 'Панікарча',
  'UA3212013017': 'Пії',
  'UA3212013016': 'Півці',
  'UA3212013018': 'Ромашки',
  'UA3212013001': 'Ржищів',
  'UA3212013019': 'Стайки',
  'UA3212013020': 'Стрітівка',
  'UA3212013021': 'Уляники',
  'UA3212013003': 'Ведмедівка',
  'UA3212013005': 'Великі Пріцьки',
  'UA3212013004': 'Великий Букрин',
  'UA3212013006': 'Виселка',
  'UA3212013024': 'Яблунівка',
  'UA3212013023': 'Юшки',
  'UA3212015007': 'Халеп\'я',
  'UA3212015005': 'Плюти',
  'UA3212015008': 'Щербанівка',
  'UA3212015006': 'Трипілля',
  'UA3212015001': 'Українка',
  'UA3212015002': 'Верем\'я',
  'UA3212015003': 'Витачів',
  'UA3212015004': 'Жуківці',
  'UA3212003002': 'Барахти',
  'UA3212003003': 'Безп\'ятне',
  'UA3212003018': 'Червоне Поле',
  'UA3212003016': 'Червоне',
  'UA3212003017': 'Червоне',
  'UA3212003010': 'Кодаки',
  'UA3212003011': 'Кулібаба',
  'UA3212003012': 'Луб\'янка',
  'UA3212003013': 'Митниця',
  'UA3212003014': 'Погреби',
  'UA3212003019': 'Шевченківка',
  'UA3212003015': 'Тростинка',
  'UA3212003001': 'Васильків',
  'UA3212003004': 'Велика Бугаївка',
  'UA3212003005': 'Велика Вільшанка',
  'UA3212003020': 'Яцьки',
  'UA3212003006': 'Заріччя',
  'UA3212003007': 'Застугна',
  'UA3212003008': 'Здорівка',
  'UA3212003009': 'Зозулі',
  'UA3210003002': 'Абрамівка',
  'UA3210003003': 'Андріївка',
  'UA3210003004': 'Богдани',
  'UA3210003009': 'Демидів',
  'UA3210003010': 'Дмитрівка',
  'UA3210003011': 'Дудки',
  'UA3210003001': 'Димер',
  'UA3210003033': 'Федорівка',
  'UA3210003007': 'Глібівка',
  'UA3210003008': 'Гута-Катюжанська',
  'UA3210003012': 'Каменка',
  'UA3210003013': 'Катюжанка',
  'UA3210003014': 'Козаровичі',
  'UA3210003015': 'Круги',
  'UA3210003017': 'Лісовичі',
  'UA3210003018': 'Любидва',
  'UA3210003019': 'Любимівка',
  'UA3210003016': 'Литвинівка',
  'UA3210003020': 'Миколаївка',
  'UA3210003021': 'Овдієва Нива',
  'UA3210003022': 'Пилява',
  'UA3210003026': 'Рови',
  'UA3210003027': 'Розтісне',
  'UA3210003028': 'Рудня-Димерська',
  'UA3210003025': 'Рихта',
  'UA3210003023': 'Рикунь',
  'UA3210003024': 'Ритні',
  'UA3210003029': 'Савенки',
  'UA3210003031': 'Сухолуччя',
  'UA3210003030': 'Сичівка',
  'UA3210003032': 'Толокунь',
  'UA3210003005': 'Вахівка',
  'UA3210003006': 'Володимирівка',
  'UA3210003034': 'Ясногородка',
  'UA3210005002': 'Білий Берег',
  'UA3210005003': 'Блідча',
  'UA3210005004': 'Болотня',
  'UA3210005005': 'Буда-Полідарівська',
  'UA3210005077': 'Чкаловка',
  'UA3210005013': 'Доманівка',
  'UA3210005011': 'Димарка',
  'UA3210005012': 'Дитятки',
  'UA3210005073': 'Федорівка',
  'UA3210005074': 'Феневичі',
  'UA3210005075': 'Фрузинівка',
  'UA3210005009': 'Горностайпіль',
  'UA3210005010': 'Губин',
  'UA3210005001': 'Іванків',
  'UA3210005022': 'Калинове',
  'UA3210005023': 'Карпилівка',
  'UA3210005076': 'Хочева',
  'UA3210005025': 'Коленці',
  'UA3210005026': 'Коленцівське',
  'UA3210005024': 'Ковалівка',
  'UA3210005027': 'Красилівка',
  'UA3210005028': 'Кропивня',
  'UA3210005029': 'Кухарі',
  'UA3210005030': 'Лапутьки',
  'UA3210005031': 'Леонівка',
  'UA3210005032': 'Людвинівка',
  'UA3210005033': 'Макарівка',
  'UA3210005034': 'Мала Макарівка',
  'UA3210005035': 'Медвин',
  'UA3210005036': 'Мокра Корма',
  'UA3210005037': 'Мусійки',
  'UA3210005038': 'Нові Макалевичі',
  'UA3210005039': 'Нові Соколи',
  'UA3210005040': 'Обуховичі',
  'UA3210005042': 'Олізарівка',
  'UA3210005041': 'Олива',
  'UA3210005043': 'Оране',
  'UA3210005044': 'Осовець',
  'UA3210005046': 'Підгайне',
  'UA3210005047': 'Піски',
  'UA3210005048': 'Полідарівка',
  'UA3210005049': 'Поталіївка',
  'UA3210005050': 'Потоки',
  'UA3210005051': 'Прибірськ',
  'UA3210005045': 'Пироговичі',
  'UA3210005052': 'Рахвалівка',
  'UA3210005054': 'Рокитна Слобода',
  'UA3210005053': 'Розважів',
  'UA3210005055': 'Рудня-Левківська',
  'UA3210005058': 'Рудня-Шпилівська',
  'UA3210005056': 'Рудня-Сидорівська',
  'UA3210005057': 'Рудня-Тальська',
  'UA3210005059': 'Русаки',
  'UA3210005078': 'Шевченкове',
  'UA3210005079': 'Шевченкове',
  'UA3210005080': 'Шпилі',
  'UA3210005061': 'Слобода-Кухарська',
  'UA3210005062': 'Соснівка',
  'UA3210005064': 'Станішівка',
  'UA3210005066': 'Старі Соколи',
  'UA3210005067': 'Старовичі',
  'UA3210005065': 'Старий Міст',
  'UA3210005063': 'Ставрівка',
  'UA3210005068': 'Степанівка',
  'UA3210005069': 'Страхолісся',
  'UA3210005070': 'Сукачі',
  'UA3210005060': 'Сидоровичі',
  'UA3210005071': 'Термахівка',
  'UA3210005072': 'Тетерівське',
  'UA3210005006': 'Варівськ',
  'UA3210005007': 'Верхолісся',
  'UA3210005008': 'Воропаївка',
  'UA3210005081': 'Яхнівка',
  'UA3210005019': 'Захарівка',
  'UA3210005017': 'Запрудка',
  'UA3210005018': 'Заруддя',
  'UA3210005014': 'Жерева',
  'UA3210005015': 'Жеревпілля',
  'UA3210005016': 'Жміївка',
  'UA3210005021': 'Зорин',
  'UA3210005020': 'Зимовище',
  'UA3210007002': 'Гута-Межигірська',
  'UA3210007003': 'Лютіж',
  'UA3210007001': 'Нові Петрівці',
  'UA3210007004': 'Старі Петрівці',
  'UA3210009002': 'Боденьки',
  'UA3210009006': 'Лебедівка',
  'UA3210009008': 'Новосілки',
  'UA3210009007': 'Нижча Дубечня',
  'UA3210009001': 'Пірнове',
  'UA3210009009': 'Ровжі',
  'UA3210009010': 'Сувид',
  'UA3210009004': 'Воропаїв',
  'UA3210009003': 'Вища Дубечня',
  'UA3210009005': 'Жукин',
  'UA3210011002': 'Буда Вовчківська',
  'UA3210011003': 'Буда-Радинська',
  'UA3210011029': 'Черемошна',
  'UA3210011007': 'Дубова',
  'UA3210011008': 'Дубова',
  'UA3210011028': 'Федорівка',
  'UA3210011006': 'Городещина',
  'UA3210011012': 'Калинівка',
  'UA3210011001': 'Красятичі',
  'UA3210011013': 'Левковичі',
  'UA3210011014': 'Лісове',
  'UA3210011015': 'Луговики',
  'UA3210011016': 'Максимовичі',
  'UA3210011017': 'Мар\'янівка',
  'UA3210011018': 'Міхлівщина',
  'UA3210011019': 'Млачівка',
  'UA3210011020': 'Нівецьке',
  'UA3210011021': 'Омелянівка',
  'UA3210011023': 'Радинка',
  'UA3210011022': 'Рагівка',
  'UA3210011024': 'Романівка',
  'UA3210011030': 'Шкнева',
  'UA3210011025': 'Стара Марківка',
  'UA3210011026': 'Стещина',
  'UA3210011027': 'Стовпне',
  'UA3210011004': 'Вересня',
  'UA3210011005': 'Вовчків',
  'UA3210011009': 'Залишани',
  'UA3210011010': 'Зелена Поляна',
  'UA3210011011': 'Зірка',
  'UA3210013001': 'Славутич',
  'UA3210001003': 'Хотянівка',
  'UA3210001002': 'Осещина',
  'UA3210001001': 'Вишгород',
  'UA4402001017': 'Адріанопіль',
  'UA4402001001': 'Алчевськ',
  'UA4402001005': 'Байрачки',
  'UA4402001006': 'Біле',
  'UA4402001029': 'Боржиківка',
  'UA4402001007': 'Бугаївка',
  'UA4402001028': 'Чорногорівка',
  'UA4402001014': 'Чорнухине',
  'UA4402001030': 'Депрерадівка',
  'UA4402001012': 'Фащівка',
  'UA4402001019': 'Городнє',
  'UA4402001008': 'Городище',
  'UA4402001020': 'Кам\'янка',
  'UA4402001031': 'Карпати',
  'UA4402001009': 'Комісарівка',
  'UA4402001032': 'Круглик',
  'UA4402001003': 'Кипуче',
  'UA4402001021': 'Малоіванівка',
  'UA4402001022': 'Малокостянтинівка',
  'UA4402001033': 'Міус',
  'UA4402001010': 'Михайлівка',
  'UA4402001023': 'Новоселівка',
  'UA4402001004': 'Перевальськ',
  'UA4402001024': 'Петрівка',
  'UA4402001011': 'Селезнівка',
  'UA4402001034': 'Селезнівське',
  'UA4402001035': 'Софіївка',
  'UA4402001025': 'Тімірязєве',
  'UA4402001026': 'Троїцьке',
  'UA4402001013': 'Центральний',
  'UA4402001027': 'Уткине',
  'UA4402001018': 'Вергулівка',
  'UA4402001016': 'Ящикове',
  'UA4402001015': 'Юр\'ївка',
  'UA4402001002': 'Зоринськ',
  'UA4402005002': 'Алмазна',
  'UA4402005016': 'Бердянка',
  'UA4402005017': 'Березівське',
  'UA4402005018': 'Богданівка',
  'UA4402005003': 'Брянка',
  'UA4402005028': 'Червоний Лиман',
  'UA4402005021': 'Дачне',
  'UA4402005010': 'Донецький',
  'UA4402005008': 'Ганнівка',
  'UA4402005009': 'Глибокий',
  'UA4402005004': 'Голубівка',
  'UA4402005029': 'Голубівське',
  'UA4402005005': 'Ірміно',
  'UA4402005001': 'Кадіївка',
  'UA4402005011': 'Калинове',
  'UA4402005023': 'Калинове-Борщувате',
  'UA4402005012': 'Криничанське',
  'UA4402005030': 'Криничне',
  'UA4402005013': 'Ломуватка',
  'UA4402005031': 'Молодіжне',
  'UA4402005024': 'Надарівка',
  'UA4402005032': 'Новий',
  'UA4402005025': 'Оленівка',
  'UA4402005006': 'Первомайськ',
  'UA4402005026': 'Польове',
  'UA4402005014': 'Сентянівка',
  'UA4402005033': 'Старе',
  'UA4402005027': 'Степанівка',
  'UA4402005034': 'Тавричанське',
  'UA4402005007': 'Вергулівка',
  'UA4402005019': 'Веселогорівка',
  'UA4402005020': 'Весняне',
  'UA4402005015': 'Южна Ломуватка',
  'UA4402005022': 'Зарічне',
  'UA4402003007': 'Довге',
  'UA4402003006': 'Говоруха',
  'UA4402003002': 'Іванівське',
  'UA4402003020': 'Хороше',
  'UA4402003010': 'Красний Лиман',
  'UA4402003021': 'Криворіжжя',
  'UA4402003003': 'Лозівський',
  'UA4402003011': 'Мамушеве',
  'UA4402003013': 'Новодачне',
  'UA4402003012': 'Новогригорівка',
  'UA4402003014': 'Пахалівка',
  'UA4402003015': 'Петровеньки',
  'UA4402003016': 'Пришиб',
  'UA4402003004': 'Родакове',
  'UA4402003005': 'Слов\'яносербськ',
  'UA4402003017': 'Сміле',
  'UA4402003018': 'Степове',
  'UA4402003019': 'Суходіл',
  'UA4402003022': 'Яснодольськ',
  'UA4402003008': 'Замостя',
  'UA4402003009': 'Знам\'янка',
  'UA4402003001': 'Зимогір\'я',
  'UA4404001014': 'Ананьївка',
  'UA4404001015': 'Антракоп',
  'UA4404001016': 'Астахове',
  'UA4404001017': 'Березівка',
  'UA4404001018': 'Бобриківка',
  'UA4404001044': 'Братське',
  'UA4404001043': 'Черемшине',
  'UA4404001021': 'Дар\'їно-Єрмаківка',
  'UA4404001020': 'Дар\'ївка',
  'UA4404001001': 'Довжанськ',
  'UA4404001046': 'Довжанське',
  'UA4404001006': 'Дубове',
  'UA4404001053': 'Федорівка',
  'UA4404001047': 'Іващенко',
  'UA4404001048': 'Калинівка',
  'UA4404001024': 'Калинник',
  'UA4404001025': 'Карпове-Кріпенське',
  'UA4404001054': 'Хмельницький',
  'UA4404001007': 'Кленовий',
  'UA4404001026': 'Кондрюче',
  'UA4404001027': 'Коробкине',
  'UA4404001008': 'Криничне',
  'UA4404001009': 'Кундрюче',
  'UA4404001028': 'Куряче',
  'UA4404001049': 'Киселеве',
  'UA4404001029': 'Любиме',
  'UA4404001033': 'Маяк',
  'UA4404001030': 'Маловедмеже',
  'UA4404001031': 'Мар\'ївка',
  'UA4404001032': 'Матвіївка',
  'UA4404001034': 'Медвежанка',
  'UA4404001035': 'Миколаївка',
  'UA4404001036': 'Нагірне',
  'UA4404001010': 'Нагольно-Тарасівка',
  'UA4404001037': 'Новоборовиці',
  'UA4404001011': 'Новодар\'ївка',
  'UA4404001038': 'Олександрівка',
  'UA4404001039': 'Панченкове',
  'UA4404001012': 'Павлівка',
  'UA4404001050': 'Покровка',
  'UA4404001051': 'Прохладне',
  'UA4404001040': 'Провалля',
  'UA4404001041': 'Ритикове',
  'UA4404001013': 'Шахтарське',
  'UA4404001052': 'Устинівка',
  'UA4404001042': 'Уткине',
  'UA4404001045': 'Валянівськ',
  'UA4404001003': 'Вальянівське',
  'UA4404001004': 'Ведмеже',
  'UA4404001005': 'Великокам\'янка',
  'UA4404001019': 'Верхньотузлове',
  'UA4404001002': 'Вознесенівка',
  'UA4404001022': 'Зеленопілля',
  'UA4404001023': 'Зимівники',
  'UA4404003009': 'Батир',
  'UA4404003010': 'Берегове',
  'UA4404003011': 'Біленьке',
  'UA4404003012': 'Білоскелювате',
  'UA4404003020': 'Давидо-Микільське',
  'UA4404003021': 'Дружне',
  'UA4404003019': 'Габун',
  'UA4404003023': 'Іллівка',
  'UA4404003022': 'Іванівка',
  'UA4404003003': 'Ізварине',
  'UA4404003038': 'Хорошилове',
  'UA4404003039': 'Хрящівка',
  'UA4404003024': 'Королівка',
  'UA4404003004': 'Краснодарський',
  'UA4404003025': 'Кружилівка',
  'UA4404003026': 'Липове',
  'UA4404003027': 'Макарів Яр',
  'UA4404003041': 'Малокалинове',
  'UA4404003028': 'Малий Суходіл',
  'UA4404003029': 'Микишівка',
  'UA4404003032': 'Новокиївка',
  'UA4404003031': 'Нижня Гарасимівка',
  'UA4404003042': 'Нижня Шевирівка',
  'UA4404003030': 'Нижньодеревечка',
  'UA4404003033': 'Огульчанськ',
  'UA4404003034': 'Пантеліївка',
  'UA4404003035': 'Підгірне',
  'UA4404003036': 'Попівка',
  'UA4404003043': 'Поріччя',
  'UA4404003037': 'Радісне',
  'UA4404003005': 'Сєверний',
  'UA4404003006': 'Сєверо-Гундорівський',
  'UA4404003001': 'Сорокине',
  'UA4404003002': 'Суходільськ',
  'UA4404003044': 'Світличне',
  'UA4404003007': 'Тепле',
  'UA4404003008': 'Урало-Кавказ',
  'UA4404003013': 'Великий Суходіл',
  'UA4404003015': 'Верхньодеревечка',
  'UA4404003014': 'Верхньогарасимівка',
  'UA4404003016': 'Верхньошевирівка',
  'UA4404003017': 'Власівка',
  'UA4404003018': 'Водоток',
  'UA4404003040': 'Західний',
  'UA4406001004': 'Бурчак-Михайлівка',
  'UA4406001030': 'Фабричне',
  'UA4406001007': 'Гайове',
  'UA4406001003': 'Катеринівка',
  'UA4406001024': 'Христове',
  'UA4406001010': 'Крута Гора',
  'UA4406001012': 'Лобачеве',
  'UA4406001001': 'Луганськ',
  'UA4406001011': 'Лиман',
  'UA4406001028': 'Металіст',
  'UA4406001013': 'Миколаївка',
  'UA4406001014': 'Новоселівка',
  'UA4406001015': 'Обозне',
  'UA4406001002': 'Олександрівськ',
  'UA4406001016': 'Паньківка',
  'UA4406001017': 'Привітне',
  'UA4406001018': 'Раївка',
  'UA4406001019': 'Розкішне',
  'UA4406001020': 'Сабівка',
  'UA4406001026': 'Шишкове',
  'UA4406001022': 'Стукалова Балка',
  'UA4406001023': 'Суходіл',
  'UA4406001021': 'Світле',
  'UA4406001029': 'Тепличне',
  'UA4406001025': 'Цвітні Піски',
  'UA4406001005': 'Весела Гора',
  'UA4406001006': 'Весела Тарасівка',
  'UA4406001009': 'Земляне',
  'UA4406001008': 'Жовте',
  'UA4406001027': 'Зразкове',
  'UA4406003002': 'Білоріченський',
  'UA4406003008': 'Бокове',
  'UA4406003007': 'Челюскінець',
  'UA4406003004': 'Георгіївка',
  'UA4406003012': 'Глафірівка',
  'UA4406003014': 'Іллірія',
  'UA4406003015': 'Кам\'янка',
  'UA4406003032': 'Кам\'яний Пласт',
  'UA4406003033': 'Ключове',
  'UA4406003034': 'Комишуваха',
  'UA4406003016': 'Круглик',
  'UA4406003035': 'Лісне',
  'UA4406003001': 'Лутугине',
  'UA4406003017': 'Македонівка',
  'UA4406003018': 'Мала Мартинівка',
  'UA4406003019': 'Мала Юр\'ївка',
  'UA4406003005': 'Марія',
  'UA4406003020': 'Мар\'ївка',
  'UA4406003036': 'Мирне',
  'UA4406003021': 'Новобулахівка',
  'UA4406003022': 'Новофедорівка',
  'UA4406003037': 'Новопавлівка',
  'UA4406003023': 'Оріхівка',
  'UA4406003024': 'Паліївка',
  'UA4406003025': 'Переможне',
  'UA4406003026': 'Першозванівка',
  'UA4406003027': 'Петро-Миколаївка',
  'UA4406003028': 'П\'ятигорівка',
  'UA4406003030': 'Шовкова Протока',
  'UA4406003038': 'Шимшинівка',
  'UA4406003029': 'Ушаківка',
  'UA4406003006': 'Успенка',
  'UA4406003009': 'Велика Мартинівка',
  'UA4406003010': 'Верхня Оріхівка',
  'UA4406003011': 'Волнухине',
  'UA4406003003': 'Врубівський',
  'UA4406003039': 'Ясне',
  'UA4406003013': 'Західне',
  'UA4406003031': 'Збірне',
  'UA4406005010': 'Андріївка',
  'UA4406005002': 'Буран',
  'UA4406005016': 'Дубівка',
  'UA4406005004': 'Гірне',
  'UA4406005014': 'Глибоке',
  'UA4406005015': 'Горіхова Балка',
  'UA4406005017': 'Катеринівка',
  'UA4406005028': 'Хрящувате',
  'UA4406005018': 'Комісарівка',
  'UA4406005019': 'Красне',
  'UA4406005020': 'Красний Яр',
  'UA4406005021': 'Лисе',
  'UA4406005001': 'Молодогвардійськ',
  'UA4406005005': 'Мирне',
  'UA4406005022': 'Новоганнівка',
  'UA4406005006': 'Новоолександрівка',
  'UA4406005027': 'Новосімейкіне',
  'UA4406005007': 'Новосвітлівка',
  'UA4406005023': 'Придорожне',
  'UA4406005024': 'Самсонівка',
  'UA4406005029': 'Широке',
  'UA4406005008': 'Сімейкине',
  'UA4406005009': 'Талове',
  'UA4406005025': 'Тернове',
  'UA4406005011': 'Валіївка',
  'UA4406005003': 'Великий Лог',
  'UA4406005026': 'Верхня Краснянка',
  'UA4406005012': 'Видно-Софіївка',
  'UA4406005013': 'Вишневий Діл',
  'UA4408001001': 'Антрацит',
  'UA4408001002': 'Боково-Платове',
  'UA4408001020': 'Червона Поляна',
  'UA4408001011': 'Дякове',
  'UA4408001004': 'Дубівський',
  'UA4408001014': 'Іллінка',
  'UA4408001006': 'Кам\'яне',
  'UA4408001026': 'Христофорівка',
  'UA4408001021': 'Ковпакове',
  'UA4408001007': 'Кріпенський',
  'UA4408001015': 'Леонове',
  'UA4408001016': 'Леськине',
  'UA4408001022': 'Лісне',
  'UA4408001017': 'Лобівські Копальні',
  'UA4408001023': 'Мельникове',
  'UA4408001008': 'Нижній Нагольчик',
  'UA4408001018': 'Оріхове',
  'UA4408001019': 'Рафайлівка',
  'UA4408001024': 'Садовий',
  'UA4408001009': 'Щотове',
  'UA4408001025': 'Степове',
  'UA4408001003': 'Верхній Нагольчик',
  'UA4408001010': 'Ясенівський',
  'UA4408001005': 'Єсаулівка',
  'UA4408001013': 'Зеленодільське',
  'UA4408001012': 'Зелений Курган',
  'UA4408005017': 'Баштевич',
  'UA4408005002': 'Боково-Хрустальне',
  'UA4408005028': 'Буткевич',
  'UA4408005030': 'Давидівка',
  'UA4408005013': 'Фащівка',
  'UA4408005014': 'Федорівка',
  'UA4408005019': 'Ганнівка',
  'UA4408005005': 'Грушове',
  'UA4408005031': 'Індустрія',
  'UA4408005007': 'Іванівка',
  'UA4408005015': 'Хрустальне',
  'UA4408005001': 'Хрустальний',
  'UA4408005043': 'Хрустальний',
  'UA4408005008': 'Княгинівка',
  'UA4408005034': 'Комендантське',
  'UA4408005024': 'Корінне',
  'UA4408005032': 'Ковильне',
  'UA4408005033': 'Козаківка',
  'UA4408005035': 'Краснолуцький',
  'UA4408005009': 'Красний Кут',
  'UA4408005036': 'Курган',
  'UA4408005025': 'Лісне',
  'UA4408005010': 'Маломиколаївка',
  'UA4408005003': 'Міусинськ',
  'UA4408005026': 'Микитівка',
  'UA4408005027': 'Новоєлизаветівка',
  'UA4408005037': 'Орлівське',
  'UA4408005004': 'Петрово-Красносілля',
  'UA4408005011': 'Садово-Хрустальненський',
  'UA4408005016': 'Штерівка',
  'UA4408005044': 'Широкий',
  'UA4408005012': 'Софіївський',
  'UA4408005038': 'Сонячне',
  'UA4408005039': 'Степове',
  'UA4408005040': 'Тамара',
  'UA4408005041': 'Трубний',
  'UA4408005042': 'Урожайне',
  'UA4408005029': 'Вергулівське',
  'UA4408005018': 'Воскресенівка',
  'UA4408005020': 'Єлизаветівка',
  'UA4408005022': 'Західне',
  'UA4408005006': 'Запоріжжя',
  'UA4408005023': 'Зелений Гай',
  'UA4408005021': 'Жереб\'яче',
  'UA4408003007': 'Благівка',
  'UA4408003008': 'Бобрикове',
  'UA4408003002': 'Гірник',
  'UA4408003011': 'Грибуваха',
  'UA4408003003': 'Картушине',
  'UA4408003014': 'Картушине',
  'UA4408003015': 'Клуникове',
  'UA4408003023': 'Кошари',
  'UA4408003004': 'Любимівка',
  'UA4408003016': 'Лози',
  'UA4408003017': 'Мечетка',
  'UA4408003005': 'Михайлівка',
  'UA4408003018': 'Новодар\'ївка',
  'UA4408003019': 'Новокраснівка',
  'UA4408003024': 'Новоукраїнка',
  'UA4408003020': 'Платонівка',
  'UA4408003021': 'Ребрикове',
  'UA4408003001': 'Ровеньки',
  'UA4408003006': 'Тацине',
  'UA4408003022': 'Улянівка',
  'UA4408003009': 'Вербівка',
  'UA4408003010': 'Вишневе',
  'UA4408003012': 'Єгорівка',
  'UA4408003013': 'Залізничне',
  'UA4416003002': 'Айдар-Миколаївка',
  'UA4416003003': 'Бахмутівка',
  'UA4416003004': 'Безгинове',
  'UA4416003030': 'Чистопілля',
  'UA4416003007': 'Деменкове',
  'UA4416003008': 'Денежникове',
  'UA4416003009': 'Дмитрівка',
  'UA4416003010': 'Дубове',
  'UA4416003006': 'Гречишкине',
  'UA4416003011': 'Капітанове',
  'UA4416003013': 'Колядівка',
  'UA4416003012': 'Ковпаки',
  'UA4416003014': 'Маловенделівка',
  'UA4416003017': 'Муратове',
  'UA4416003016': 'Михайлюки',
  'UA4416003015': 'Михайлівка',
  'UA4416003001': 'Новоайдар',
  'UA4416003018': 'Новоохтирка',
  'UA4416003019': 'Окнине',
  'UA4416003020': 'Олексіївка',
  'UA4416003021': 'Переможне',
  'UA4416003022': 'Петренкове',
  'UA4416003032': 'Побєда',
  'UA4416003023': 'Попасне',
  'UA4416003024': 'Путилине',
  'UA4416003025': 'Райгородка',
  'UA4416003031': 'Штормове',
  'UA4416003026': 'Співаківка',
  'UA4416003027': 'Степний Яр',
  'UA4416003028': 'Трудове',
  'UA4416003029': 'Царівка',
  'UA4416003005': 'Вовкодаєве',
  'UA4416001002': 'Артема',
  'UA4416001006': 'Крепи',
  'UA4416001007': 'Михайлівка',
  'UA4416001008': 'Нижній Мінченок',
  'UA4416001001': 'Нижньотепле',
  'UA4416001009': 'Піщане',
  'UA4416001010': 'Середньотепле',
  'UA4416001011': 'Сотенне',
  'UA4416001012': 'Тепле',
  'UA4416001003': 'Велика Чернігівка',
  'UA4416001004': 'Верхній Мінченок',
  'UA4416001005': 'Верхньобогданівка',
  'UA4416009004': 'Геївка',
  'UA4416009005': 'Кряківка',
  'UA4416009006': 'Лобачеве',
  'UA4416009007': 'Лопаскине',
  'UA4416009008': 'Оріхове-Донецьке',
  'UA4416009009': 'Передільське',
  'UA4416009002': 'Петропавлівка',
  'UA4416009001': 'Щастя',
  'UA4416009010': 'Старий Айдар',
  'UA4416009011': 'Трьохізбенка',
  'UA4416009003': 'Войтове',
  'UA4416007002': 'Благовіщенка',
  'UA4416007010': 'Чугинка',
  'UA4416007005': 'Деркульське',
  'UA4416007004': 'Гарасимівка',
  'UA4416007011': 'Козачий',
  'UA4416007007': 'Красна Талівка',
  'UA4416007008': 'Красний Деркул',
  'UA4416007009': 'Олександрівка',
  'UA4416007012': 'Розквіт',
  'UA4416007001': 'Широкий',
  'UA4416007013': 'Степове',
  'UA4416007014': 'Талове',
  'UA4416007003': 'Вільне',
  'UA4416007006': 'Золотарівка',
  'UA4416005002': 'Болотене',
  'UA4416005005': 'Колесниківка',
  'UA4416005006': 'Комишне',
  'UA4416005007': 'Макарове',
  'UA4416005008': 'Малинове',
  'UA4416005009': 'Нижня Вільхова',
  'UA4416005010': 'Плотина',
  'UA4416005011': 'Пшеничне',
  'UA4416005001': 'Станиця Луганська',
  'UA4416005012': 'Сизе',
  'UA4416005003': 'Валуйське',
  'UA4416005004': 'Верхня Вільхова',
  'UA4416005014': 'Вільхове',
  'UA4416005013': 'Юганівка',
  'UA4412001001': 'Гірське',
  'UA4412001007': 'Катеринівка',
  'UA4412001008': 'Кримське',
  'UA4412001004': 'Новотошківське',
  'UA4412001003': 'Нижнє',
  'UA4412001009': 'Оріхове',
  'UA4412001010': 'Причепилівка',
  'UA4412001011': 'Сокільники',
  'UA4412001005': 'Тошківка',
  'UA4412001006': 'Жолобок',
  'UA4412001002': 'Золоте',
  'UA4412003007': 'Червонопопівка',
  'UA4412003008': 'Діброва',
  'UA4412003002': 'Голикове',
  'UA4412003001': 'Кремінна',
  'UA4412003010': 'Кузьмине',
  'UA4412003003': 'Новокраснянка',
  'UA4412003004': 'Піщане',
  'UA4412003005': 'Пшеничне',
  'UA4412003011': 'Стара Краснянка',
  'UA4412003006': 'Суровцівка',
  'UA4412003009': 'Житлівка',
  'UA4412005008': 'Біла Гора',
  'UA4412005004': 'Білогорівка',
  'UA4412005015': 'Лоскутівка',
  'UA4412005001': 'Лисичанськ',
  'UA4412005014': 'Лисичанський',
  'UA4412005006': 'Малорязанцеве',
  'UA4412005007': 'Мирна Долина',
  'UA4412005002': 'Новодружеськ',
  'UA4412005016': 'Підлісне',
  'UA4412005003': 'Привілля',
  'UA4412005011': 'Рай-Олександрівка',
  'UA4412005013': 'Шипилівка',
  'UA4412005017': 'Тополівка',
  'UA4412005012': 'Устинівка',
  'UA4412005009': 'Верхньокам\'янка',
  'UA4412005005': 'Вовчоярівка',
  'UA4412005010': 'Золотарівка',
  'UA4412007012': 'Дружба',
  'UA4412007011': 'Глинокар\'єр',
  'UA4412007003': 'Комишуваха',
  'UA4412007013': 'Миколаївка',
  'UA4412007007': 'Новоіванівка',
  'UA4412007008': 'Новоолександрівка',
  'UA4412007006': 'Новозванівка',
  'UA4412007014': 'Ниркове',
  'UA4412007009': 'Олександропілля',
  'UA4412007001': 'Попасна',
  'UA4412007010': 'Троїцьке',
  'UA4412007005': 'Вікторівка',
  'UA4412007002': 'Врубівка',
  'UA4412007004': 'Вискрива',
  'UA4412009002': 'Булгаківка',
  'UA4412009004': 'Голубівка',
  'UA4412009006': 'Климівка',
  'UA4412009007': 'Крутеньке',
  'UA4412009008': 'Кудряшівка',
  'UA4412009009': 'Михайлівка',
  'UA4412009010': 'Півневе',
  'UA4412009012': 'Прогрес',
  'UA4412009011': 'Пристине',
  'UA4412009001': 'Рубіжне',
  'UA4412009014': 'Шевченко',
  'UA4412009013': 'Скаргівка',
  'UA4412009003': 'Варварівка',
  'UA4412009005': 'Затишне',
  'UA4412011006': 'Боброве',
  'UA4412011002': 'Борівське',
  'UA4412011007': 'Боровеньки',
  'UA4412011017': 'Чабанівка',
  'UA4412011009': 'Гаврилівка',
  'UA4412011018': 'Лісна Дача',
  'UA4412011004': 'Метьолкіне',
  'UA4412011012': 'Нова Астрахань',
  'UA4412011011': 'Нижній Суходіл',
  'UA4412011013': 'Олександрівка',
  'UA4412011014': 'Осколонівка',
  'UA4412011019': 'Павлоград',
  'UA4412011015': 'Пурдівка',
  'UA4412011001': 'Сєвєродонецьк',
  'UA4412011016': 'Смолянинове',
  'UA4412011020': 'Синецький',
  'UA4412011005': 'Сиротине',
  'UA4412011008': 'Воєводівка',
  'UA4412011003': 'Воронове',
  'UA4412011010': 'Єпіфанівка',
  'UA4414003002': 'Березівка',
  'UA4414003001': 'Білолуцьк',
  'UA4414003005': 'Костянтинівка',
  'UA4414003004': 'Козлове',
  'UA4414003006': 'Кубань',
  'UA4414003007': 'Литвинове',
  'UA4414003008': 'Можняківка',
  'UA4414003009': 'Новобіла',
  'UA4414003010': 'Павленкове',
  'UA4414003011': 'Пелагіївка',
  'UA4414003017': 'Шапран',
  'UA4414003014': 'Соснівка',
  'UA4414003012': 'Світле',
  'UA4414003013': 'Синельникове',
  'UA4414003015': 'Танюшівка',
  'UA4414003016': 'Трембачеве',
  'UA4414003003': 'Залісне',
  'UA4414003018': 'Зелений Гай',
  'UA4414001002': 'Бараниківка',
  'UA4414001001': 'Біловодськ',
  'UA4414001003': 'Брусівка',
  'UA4414001009': 'Данилівка',
  'UA4414001005': 'Гармашівка',
  'UA4414001006': 'Гончарове',
  'UA4414001008': 'Городнє',
  'UA4414001007': 'Городище',
  'UA4414001012': 'Кононівка',
  'UA4414001013': 'Копані',
  'UA4414001014': 'Крейдяне',
  'UA4414001015': 'Лимарівка',
  'UA4414001016': 'Литвинівка',
  'UA4414001018': 'Новодеркул',
  'UA4414001019': 'Новолимарівка',
  'UA4414001020': 'Новоолександрівка',
  'UA4414001021': 'Новоспасівка',
  'UA4414001022': 'Ноздрівка',
  'UA4414001017': 'Нижньобараниківка',
  'UA4414001023': 'Парневе',
  'UA4414001024': 'Первомайськ',
  'UA4414001025': 'Плугатар',
  'UA4414001026': 'Привільне',
  'UA4414001027': 'Роздолля',
  'UA4414001028': 'Семикозівка',
  'UA4414001033': 'Шуліківка',
  'UA4414001029': 'Степове',
  'UA4414001030': 'Тернове',
  'UA4414001031': 'Третяківка',
  'UA4414001032': 'Узлісся',
  'UA4414001004': 'Вітрогон',
  'UA4414001010': 'Євсуг',
  'UA4414001011': 'Зелеківка',
  'UA4414013002': 'Антонівка',
  'UA4414013003': 'Березове',
  'UA4414013004': 'Бондареве',
  'UA4414013005': 'Бутове',
  'UA4414013001': 'Чмирівка',
  'UA4414013010': 'Караяшник',
  'UA4414013011': 'Лозове',
  'UA4414013012': 'Новоомелькове',
  'UA4414013013': 'Оріхове',
  'UA4414013014': 'Петрівське',
  'UA4414013015': 'Піщане',
  'UA4414013016': 'Роздольне',
  'UA4414013017': 'Садки',
  'UA4414013018': 'Сенькове',
  'UA4414013021': 'Шпотине',
  'UA4414013022': 'Степове',
  'UA4414013019': 'Тарабани',
  'UA4414013020': 'Тецьке',
  'UA4414013006': 'Веселе',
  'UA4414013007': 'Вишневе',
  'UA4414013009': 'Західне',
  'UA4414013008': 'Запорізьке',
  'UA4414005002': 'Бондарівка',
  'UA4414005003': 'Бондарне',
  'UA4414005010': 'Деркулове',
  'UA4414005034': 'Фартуківка',
  'UA4414005007': 'Гераськівка',
  'UA4414005008': 'Гераськівське',
  'UA4414005009': 'Городище',
  'UA4414005011': 'Кабичівка',
  'UA4414005012': 'Караван-Солодкий',
  'UA4414005013': 'Каськівка',
  'UA4414005014': 'Красне Поле',
  'UA4414005015': 'Крейдяне',
  'UA4414005017': 'Крупчанське',
  'UA4414005016': 'Кризьке',
  'UA4414005018': 'Курячівка',
  'UA4414005021': 'Лісна Поляна',
  'UA4414005022': 'Лобасове',
  'UA4414005019': 'Лимарівка',
  'UA4414005020': 'Липове',
  'UA4414005001': 'Марківка',
  'UA4414005023': 'Марківське',
  'UA4414005024': 'Нова Україна',
  'UA4414005025': 'Первомайське',
  'UA4414005026': 'Просяне',
  'UA4414005027': 'Розсохувате',
  'UA4414005028': 'Рудівка',
  'UA4414005031': 'Скородна',
  'UA4414005029': 'Сичанське',
  'UA4414005030': 'Сичівка',
  'UA4414005032': 'Тернівка',
  'UA4414005033': 'Тишківка',
  'UA4414005004': 'Веселе',
  'UA4414005005': 'Виноградне',
  'UA4414005006': 'Височинівка',
  'UA4414007002': 'Березове',
  'UA4414007003': 'Благодатне',
  'UA4414007004': 'Бондарівка',
  'UA4414007007': 'Діброва',
  'UA4414007012': 'Калмиківка',
  'UA4414007025': 'Хоминське',
  'UA4414007014': 'Криничне',
  'UA4414007013': 'Кирносове',
  'UA4414007001': 'Мілове',
  'UA4414007016': 'Морозівка',
  'UA4414007017': 'Мусіївка',
  'UA4414007015': 'Микільське',
  'UA4414007018': 'Новомикільське',
  'UA4414007019': 'Новострільцівка',
  'UA4414007020': 'Олексіївка',
  'UA4414007021': 'Півнівка',
  'UA4414007022': 'Рання Зоря',
  'UA4414007026': 'Шелестівка',
  'UA4414007027': 'Шелестівка',
  'UA4414007023': 'Стрільцівка',
  'UA4414007024': 'Травневе',
  'UA4414007005': 'Великоцьк',
  'UA4414007006': 'Водянолипове',
  'UA4414007028': 'Ярське',
  'UA4414007029': 'Яснопромінське',
  'UA4414007009': 'Зарічне',
  'UA4414007008': 'Журавське',
  'UA4414007010': 'Зориківка',
  'UA4414007011': 'Зоринівка',
  'UA4414009002': 'Булавинівка',
  'UA4414009004': 'Донцівка',
  'UA4414009003': 'Ганусівка',
  'UA4414009007': 'Ікове',
  'UA4414009008': 'Кам\'янка',
  'UA4414009021': 'Хворостяне',
  'UA4414009009': 'Лисогорівка',
  'UA4414009010': 'Макартетине',
  'UA4414009001': 'Новопсков',
  'UA4414009011': 'Новорозсош',
  'UA4414009012': 'Осинове',
  'UA4414009013': 'Пантюхине',
  'UA4414009015': 'Піски',
  'UA4414009014': 'Писарівка',
  'UA4414009017': 'Рогове',
  'UA4414009016': 'Риб\'янцеве',
  'UA4414009018': 'Солоне',
  'UA4414009019': 'Степне',
  'UA4414009020': 'Тев\'яшеве',
  'UA4414009005': 'Заайдарівка',
  'UA4414009006': 'Закотне',
  'UA4414015002': 'Байдівка',
  'UA4414015011': 'Федчине',
  'UA4414015004': 'Кам\'янка',
  'UA4414015012': 'Хворостянівка',
  'UA4414015005': 'Лозовівка',
  'UA4414015006': 'Лозуватка',
  'UA4414015007': 'Малохатка',
  'UA4414015008': 'Новоастраханське',
  'UA4414015009': 'Омелькове',
  'UA4414015010': 'Орлівка',
  'UA4414015001': 'Шульгинка',
  'UA4414015003': 'Валуйки',
  'UA4414011002': 'Балакирівка',
  'UA4414011003': 'Бутківка',
  'UA4414011007': 'Дубовівка',
  'UA4414011006': 'Джемільне',
  'UA4414011005': 'Ганнівка',
  'UA4414011009': 'Калмиківка',
  'UA4414011010': 'Кринички',
  'UA4414011011': 'Курячівка',
  'UA4414011012': 'Левадне',
  'UA4414011013': 'Лиман',
  'UA4414011014': 'Маринівка',
  'UA4414011016': 'Новоборове',
  'UA4414011017': 'Новодонбаське',
  'UA4414011018': 'Новоселівка',
  'UA4414011015': 'Нижньопокровка',
  'UA4414011019': 'Підгорівка',
  'UA4414011020': 'Половинкине',
  'UA4414011021': 'Проїждже',
  'UA4414011022': 'Проказине',
  'UA4414011001': 'Старобільськ',
  'UA4414011024': 'Суханівка',
  'UA4414011023': 'Світле',
  'UA4414011025': 'Титарівка',
  'UA4414011004': 'Верхня Покровка',
  'UA4414011008': 'Єгорівка',
  'UA4410001001': 'Білокуракине',
  'UA4410001002': 'Бунчуківка',
  'UA4410001036': 'Чабанове',
  'UA4410001004': 'Дем\'янівка',
  'UA4410001003': 'Грицаївка',
  'UA4410001007': 'Калинівське',
  'UA4410001008': 'Картамишеве',
  'UA4410001033': 'Хоменкове Друге',
  'UA4410001034': 'Хоменкове Перше',
  'UA4410001009': 'Климівка',
  'UA4410001010': 'Коноплянівка',
  'UA4410001011': 'Куплювате',
  'UA4410001012': 'Курячівка',
  'UA4410001015': 'Ляшківка',
  'UA4410001014': 'Луб\'янка',
  'UA4410001013': 'Лизине',
  'UA4410001016': 'Маньківка',
  'UA4410001017': 'Нещеретове',
  'UA4410001018': 'Новоандріївка',
  'UA4410001019': 'Олександропіль',
  'UA4410001020': 'Олексіївка',
  'UA4410001021': 'Осикове',
  'UA4410001023': 'Паньківка',
  'UA4410001022': 'Павлівка',
  'UA4410001024': 'Плахо-Петрівка',
  'UA4410001025': 'Попівка',
  'UA4410001027': 'Просторе',
  'UA4410001026': 'Приходьківка',
  'UA4410001028': 'Романівка',
  'UA4410001029': 'Рудове',
  'UA4410001037': 'Шапарівка',
  'UA4410001038': 'Шовкунівка',
  'UA4410001031': 'Стативчине',
  'UA4410001030': 'Світленьке',
  'UA4410001035': 'Цілуйкове',
  'UA4410001032': 'Тимошине',
  'UA4410001006': 'Заїківка',
  'UA4410001005': 'Заводянка',
  'UA4410003002': 'Андріївка',
  'UA4410003004': 'Джерельне',
  'UA4410003005': 'Кармазинівка',
  'UA4410003001': 'Коломийчиха',
  'UA4410003006': 'Ковалівка',
  'UA4410003007': 'Кривошиївка',
  'UA4410003008': 'Куземівка',
  'UA4410003009': 'М\'ясожарівка',
  'UA4410003010': 'Надія',
  'UA4410003011': 'Нежурине',
  'UA4410003012': 'Новоєгорівка',
  'UA4410003013': 'Новоєгорівка',
  'UA4410003021': 'Новоселівське',
  'UA4410003014': 'Паталахівка',
  'UA4410003015': 'Підкуйчанськ',
  'UA4410003016': 'Попівка',
  'UA4410003017': 'Райгородка',
  'UA4410003018': 'Сергіївка',
  'UA4410003019': 'Стельмахівка',
  'UA4410003020': 'Сторожівка',
  'UA4410003003': 'Володимирівка',
  'UA4410005002': 'Бараниківка',
  'UA4410005003': 'Греківка',
  'UA4410005001': 'Красноріченське',
  'UA4410005005': 'Макіївка',
  'UA4410005006': 'Невське',
  'UA4410005008': 'Новолюбівка',
  'UA4410005009': 'Новоолександрівка',
  'UA4410005007': 'Нововодяне',
  'UA4410005010': 'Площанка',
  'UA4410005004': 'Залиман',
  'UA4410007002': 'Березівка',
  'UA4410007022': 'Чапліївка',
  'UA4410007007': 'Дуванка',
  'UA4410007005': 'Гладкове',
  'UA4410007006': 'Головкове',
  'UA4410007008': 'Калинова Балка',
  'UA4410007009': 'Кочине-Розпасіївка',
  'UA4410007010': 'Лозне',
  'UA4410007001': 'Лозно-Олександрівка',
  'UA4410007011': 'Лугове',
  'UA4410007012': 'Маслакове',
  'UA4410007013': 'Миколаївка',
  'UA4410007025': 'Мирне',
  'UA4410007015': 'Нянчине',
  'UA4410007014': 'Новопокровка',
  'UA4410007016': 'Олексапіль',
  'UA4410007017': 'Петрівка',
  'UA4410007018': 'Попасне',
  'UA4410007019': 'Привілля',
  'UA4410007024': 'Шахове',
  'UA4410007023': 'Шарівка',
  'UA4410007020': 'Солідарне',
  'UA4410007021': 'Трудродительське',
  'UA4410007004': 'Вільшани',
  'UA4410007003': 'Вівчарове',
  'UA4410009004': 'Куликівка',
  'UA4410009005': 'Лебедівка',
  'UA4410009006': 'Наугольне',
  'UA4410009008': 'Новочервоне',
  'UA4410009007': 'Новониканорівка',
  'UA4410009001': 'Нижня Дуванка',
  'UA4410009009': 'Оборотнівка',
  'UA4410009010': 'Олександрівка',
  'UA4410009012': 'Полтава',
  'UA4410009013': 'Преображенне',
  'UA4410009011': 'Пилипівка',
  'UA4410009014': 'Софіївка',
  'UA4410009015': 'Тарасівка',
  'UA4410009016': 'Твердохлібове',
  'UA4410009002': 'Верхня Дуванка',
  'UA4410009003': 'Вестатівка',
  'UA4410009017': 'Яблунівка',
  'UA4410011002': 'Андріївка',
  'UA4410011003': 'Барикине',
  'UA4410011021': 'Чепигівка',
  'UA4410011005': 'Дачне',
  'UA4410011004': 'Гончарівка',
  'UA4410011007': 'Іванівка',
  'UA4410011020': 'Хомівка',
  'UA4410011008': 'Коржове',
  'UA4410011009': 'Кругле',
  'UA4410011023': 'Лагідне',
  'UA4410011010': 'Маньківка',
  'UA4410011011': 'Мілуватка',
  'UA4410011012': 'Містки',
  'UA4410011013': 'Новомикільське',
  'UA4410011014': 'Новопреображенне',
  'UA4410011015': 'Павлівка',
  'UA4410011016': 'Промінь',
  'UA4410011017': 'Рудівка',
  'UA4410011024': 'Сосновий',
  'UA4410011001': 'Сватове',
  'UA4410011018': 'Свистунівка',
  'UA4410011019': 'Травневе',
  'UA4410011022': 'Західний',
  'UA4410011006': 'Зміївка',
  'UA4410013002': 'Аношкине',
  'UA4410013003': 'Арапівка',
  'UA4410013004': 'Бабичеве',
  'UA4410013005': 'Багачка',
  'UA4410013006': 'Бараничеве',
  'UA4410013007': 'Богородицьке',
  'UA4410013011': 'Давидівка',
  'UA4410013012': 'Демино-Олександрівка',
  'UA4410013013': 'Джерельне',
  'UA4410013043': 'Федосіївка',
  'UA4410013010': 'Глотівка',
  'UA4410013017': 'Іллінка',
  'UA4410013018': 'Караїчне',
  'UA4410013019': 'Кашкарне',
  'UA4410013020': 'Клинуватка',
  'UA4410013022': 'Коченове',
  'UA4410013023': 'Кошелівка',
  'UA4410013021': 'Козарик',
  'UA4410013024': 'Красногригорівка',
  'UA4410013025': 'Лантратівка',
  'UA4410013026': 'Максимівка',
  'UA4410013027': 'Малоолександрівка',
  'UA4410013028': 'Маслівка',
  'UA4410013029': 'Михайлівка',
  'UA4410013031': 'Новоолександрівка',
  'UA4410013030': 'Новознам\'янка',
  'UA4410013032': 'Озеро',
  'UA4410013033': 'Покровське',
  'UA4410013034': 'Полтавське',
  'UA4410013035': 'Роднички',
  'UA4410013036': 'Розпасіївка',
  'UA4410013037': 'Розсипне',
  'UA4410013045': 'Шатківка',
  'UA4410013039': 'Солонці',
  'UA4410013040': 'Судьбинка',
  'UA4410013038': 'Сиротине',
  'UA4410013042': 'Тополі',
  'UA4410013001': 'Троїцьке',
  'UA4410013044': 'Царівка',
  'UA4410013041': 'Тимонове',
  'UA4410013009': 'Воєводське',
  'UA4410013008': 'Високе',
  'UA4410013046': 'Ями',
  'UA4410013015': 'Загір\'я',
  'UA4410013016': 'Зайцеве',
  'UA4410013014': 'Жовтневе',
  'UA4612001001': 'Белз',
  'UA4612001007': 'Діброва',
  'UA4612001008': 'Домашів',
  'UA4612001006': 'Глухів',
  'UA4612001012': 'Карів',
  'UA4612001023': 'Хлівчани',
  'UA4612001013': 'Корчів',
  'UA4612001015': 'Муроване',
  'UA4612001014': 'Михайлівка',
  'UA4612001016': 'Низи',
  'UA4612001017': 'Острівок',
  'UA4612001018': 'Перемисловичі',
  'UA4612001019': 'Піддубне',
  'UA4612001020': 'Себечів',
  'UA4612001021': 'Стаївка',
  'UA4612001022': 'Тяглів',
  'UA4612001024': 'Цеблів',
  'UA4612001002': 'Угнів',
  'UA4612001003': 'Ванів',
  'UA4612001004': 'Вербове',
  'UA4612001005': 'Воронів',
  'UA4612001010': 'Заболоття',
  'UA4612001011': 'Заставне',
  'UA4612001009': 'Жужеляни',
  'UA4612013004': 'Бендюга',
  'UA4612013005': 'Бережне',
  'UA4612013006': 'Борятин',
  'UA4612013001': 'Червоноград',
  'UA4612013009': 'Добрячин',
  'UA4612013003': 'Гірник',
  'UA4612013008': 'Городище',
  'UA4612013010': 'Межиріччя',
  'UA4612013011': 'Острів',
  'UA4612013012': 'Поздимир',
  'UA4612013013': 'Рудка',
  'UA4612013014': 'Сілець',
  'UA4612013002': 'Соснівка',
  'UA4612013007': 'Волсвин',
  'UA4612005001': 'Добротвір',
  'UA4612005003': 'Долини',
  'UA4612005004': 'Долини',
  'UA4612005002': 'Гряда',
  'UA4612005006': 'Кошаковські',
  'UA4612005005': 'Козаки',
  'UA4612005008': 'Маїки',
  'UA4612005009': 'Матяші',
  'UA4612005007': 'Мазярня-Каранська',
  'UA4612005010': 'Незнанів',
  'UA4612005011': 'Перекалки',
  'UA4612005012': 'Полонична',
  'UA4612005013': 'Рогалі',
  'UA4612005014': 'Рокети',
  'UA4612005015': 'Сілець',
  'UA4612005016': 'Старий Добротвір',
  'UA4612005017': 'Стриганка',
  'UA4612005018': 'Тартак',
  'UA4612005019': 'Тичок',
  'UA4612005020': 'Тишиця',
  'UA4612007002': 'Адамівка',
  'UA4612007003': 'Барилів',
  'UA4612007004': 'Батиїв',
  'UA4612007005': 'Бебехи',
  'UA4612007006': 'Березівка',
  'UA4612007008': 'Грицеволя',
  'UA4612007027': 'Хмільно',
  'UA4612007011': 'Корчівка',
  'UA4612007012': 'Куликів',
  'UA4612007013': 'Кустин',
  'UA4612007001': 'Лопатин',
  'UA4612007014': 'Миколаїв',
  'UA4612007016': 'Новоставці',
  'UA4612007015': 'Нивиці',
  'UA4612007017': 'Підмонастирок',
  'UA4612007018': 'Пустельники',
  'UA4612007019': 'Романівка',
  'UA4612007020': 'Руденко',
  'UA4612007028': 'Щуровичі',
  'UA4612007021': 'Сморжів',
  'UA4612007022': 'Старий Майдан',
  'UA4612007024': 'Стремільче',
  'UA4612007023': 'Стирківці',
  'UA4612007025': 'Трійця',
  'UA4612007026': 'Увин',
  'UA4612007007': 'Волиця-Барилова',
  'UA4612007010': 'Загатка',
  'UA4612007009': 'Завидче',
  'UA4612009002': 'Андріївка',
  'UA4612009003': 'Бабичі',
  'UA4612009004': 'Бишів',
  'UA4612009009': 'Дмитрів',
  'UA4612009010': 'Дубини',
  'UA4612009007': 'Гоголів',
  'UA4612009008': 'Гута-Скляна',
  'UA4612009014': 'Корчин',
  'UA4612009015': 'Криве',
  'UA4612009016': 'Кути',
  'UA4612009017': 'Монастирок-Оглядівський',
  'UA4612009018': 'Мукані',
  'UA4612009019': 'Немилів',
  'UA4612009020': 'Нестаничі',
  'UA4612009021': 'Новий Витків',
  'UA4612009022': 'Обортів',
  'UA4612009023': 'Оглядів',
  'UA4612009024': 'Опліцько',
  'UA4612009025': 'Ордів',
  'UA4612009026': 'Павлів',
  'UA4612009028': 'Полове',
  'UA4612009027': 'Пиратин',
  'UA4612009001': 'Радехів',
  'UA4612009029': 'Радванці',
  'UA4612009030': 'Раковище',
  'UA4612009031': 'Розжалів',
  'UA4612009032': 'Сабанівка',
  'UA4612009033': 'Середпільці',
  'UA4612009041': 'Шайноги',
  'UA4612009035': 'Станин',
  'UA4612009036': 'Стоянів',
  'UA4612009037': 'Сушно',
  'UA4612009034': 'Синьків',
  'UA4612009038': 'Тетевчиці',
  'UA4612009039': 'Тоболів',
  'UA4612009040': 'Торки',
  'UA4612009005': 'Волиця',
  'UA4612009006': 'Вузлове',
  'UA4612009042': 'Яструбичі',
  'UA4612009013': 'Йосипівка',
  'UA4612009011': 'Забава',
  'UA4612009012': 'Збоївська',
  'UA4612011003': 'Боб\'ятин',
  'UA4612011004': 'Бодячів',
  'UA4612011006': 'Бояничі',
  'UA4612011005': 'Борок',
  'UA4612011056': 'Фусів',
  'UA4612011011': 'Ганівка',
  'UA4612011012': 'Гатківка',
  'UA4612011013': 'Горбків',
  'UA4612011014': 'Гута',
  'UA4612011019': 'Ільковичі',
  'UA4612011057': 'Хоробрів',
  'UA4612011020': 'Княже',
  'UA4612011021': 'Комарів',
  'UA4612011022': 'Конотопи',
  'UA4612011023': 'Копитів',
  'UA4612011025': 'Лещатів',
  'UA4612011024': 'Лешків',
  'UA4612011026': 'Лубнівка',
  'UA4612011027': 'Лучиці',
  'UA4612011028': 'Матів',
  'UA4612011030': 'Нісмичі',
  'UA4612011029': 'Ниновичі',
  'UA4612011031': 'Опільсько',
  'UA4612011033': 'Переспа',
  'UA4612011034': 'Перетоки',
  'UA4612011032': 'Перв\'ятичі',
  'UA4612011035': 'Пісочне',
  'UA4612011036': 'Поториця',
  'UA4612011037': 'Равщина',
  'UA4612011040': 'Роятин',
  'UA4612011038': 'Романівка',
  'UA4612011039': 'Ромош',
  'UA4612011041': 'Русин',
  'UA4612011042': 'Савчин',
  'UA4612011058': 'Шарпанці',
  'UA4612011060': 'Шпиколоси',
  'UA4612011059': 'Шихтарі',
  'UA4612011044': 'Скоморохи',
  'UA4612011045': 'Смиків',
  'UA4612011001': 'Сокаль',
  'UA4612011046': 'Спасів',
  'UA4612011047': 'Старгород',
  'UA4612011048': 'Стенятин',
  'UA4612011049': 'Суховоля',
  'UA4612011043': 'Свитазів',
  'UA4612011050': 'Тартаків',
  'UA4612011051': 'Теляж',
  'UA4612011052': 'Трудолюбівка',
  'UA4612011053': 'Тудорковичі',
  'UA4612011054': 'Угринів',
  'UA4612011055': 'Ульвівок',
  'UA4612011007': 'Варяж',
  'UA4612011008': 'Велике',
  'UA4612011009': 'Войславичі',
  'UA4612011010': 'Волиця',
  'UA4612011015': 'Забужжя',
  'UA4612011017': 'Залижня',
  'UA4612011016': 'Завишень',
  'UA4612011002': 'Жвирка',
  'UA4612011018': 'Зубків',
  'UA4612003003': 'Боянець',
  'UA4612003002': 'Борове',
  'UA4612003004': 'Бутини',
  'UA4612003007': 'Двірці',
  'UA4612003009': 'Куличків',
  'UA4612003010': 'Купичволя',
  'UA4612003011': 'Лісове',
  'UA4612003012': 'Піддовге',
  'UA4612003013': 'Підріка',
  'UA4612003014': 'Пристань',
  'UA4612003015': 'Реклинець',
  'UA4612003017': 'Шишаки',
  'UA4612003016': 'Стремінь',
  'UA4612003001': 'Великі Мости',
  'UA4612003005': 'Верини',
  'UA4612003006': 'Волиця',
  'UA4612003008': 'Заріка',
  'UA4602001001': 'Борислав',
  'UA4602001003': 'Мокряни',
  'UA4602001004': 'Підмонастирок',
  'UA4602001005': 'Попелі',
  'UA4602001006': 'Уріж',
  'UA4602001002': 'Винники',
  'UA4602001007': 'Ясениця-Сільна',
  'UA4602003005': 'Бійничі',
  'UA4602003006': 'Болехівці',
  'UA4602003007': 'Брониця',
  'UA4602003003': 'Биків',
  'UA4602003004': 'Бистриця',
  'UA4602003011': 'Дережичі',
  'UA4602003012': 'Добрівляни',
  'UA4602003013': 'Долішній Лужок',
  'UA4602003001': 'Дрогобич',
  'UA4602003010': 'Глинне',
  'UA4602003034': 'Хатки',
  'UA4602003015': 'Котоване',
  'UA4602003016': 'Лішня',
  'UA4602003017': 'Медвежа',
  'UA4602003019': 'Монастир-Дережицький',
  'UA4602003020': 'Монастир-Лішнянський',
  'UA4602003018': 'Михайлевичі',
  'UA4602003021': 'Нагуєвичі',
  'UA4602003023': 'Нове Село',
  'UA4602003024': 'Новошичі',
  'UA4602003022': 'Нижні Гаї',
  'UA4602003025': 'Ортиничі',
  'UA4602003026': 'Почаєвичі',
  'UA4602003027': 'Раневичі',
  'UA4602003028': 'Рихтичі',
  'UA4602003029': 'Селець',
  'UA4602003030': 'Снятинка',
  'UA4602003031': 'Старе Село',
  'UA4602003002': 'Стебник',
  'UA4602003032': 'Ступниця',
  'UA4602003033': 'Унятичі',
  'UA4602003008': 'Верхні Гаї',
  'UA4602003009': 'Воля Якубова',
  'UA4602003014': 'Залужани',
  'UA4602005007': 'Далява',
  'UA4602005008': 'Довге',
  'UA4602005005': 'Городківка',
  'UA4602005006': 'Грушів',
  'UA4602005010': 'Коросниця',
  'UA4602005011': 'Летня',
  'UA4602005012': 'Літиня',
  'UA4602005001': 'Меденичі',
  'UA4602005013': 'Опори',
  'UA4602005015': 'Ріпчиці',
  'UA4602005014': 'Рівне',
  'UA4602005016': 'Ролів',
  'UA4602005017': 'Солонське',
  'UA4602005018': 'Тинів',
  'UA4602005002': 'Верхній Дорожів',
  'UA4602005003': 'Волоща',
  'UA4602005004': 'Вороблевичі',
  'UA4602005009': 'Зади',
  'UA4602007003': 'Бистриця-Гірська',
  'UA4602007006': 'Довге',
  'UA4602007004': 'Головське',
  'UA4602007005': 'Гута',
  'UA4602007010': 'Коритище',
  'UA4602007011': 'Кринтята',
  'UA4602007012': 'Ластівка',
  'UA4602007013': 'Майдан',
  'UA4602007014': 'Новий Кропивник',
  'UA4602007015': 'Опака',
  'UA4602007016': 'Перепростиня',
  'UA4602007002': 'Підбуж',
  'UA4602007017': 'Підсухе',
  'UA4602007018': 'Рибник',
  'UA4602007001': 'Східниця',
  'UA4602007020': 'Смільна',
  'UA4602007021': 'Старий Кропивник',
  'UA4602007022': 'Сторона',
  'UA4602007019': 'Свидник',
  'UA4602007008': 'Залокоть',
  'UA4602007007': 'Жданівка',
  'UA4602007009': 'Зубриця',
  'UA4602009002': 'Бистрий',
  'UA4602009003': 'Доброгостів',
  'UA4602009005': 'Модричі',
  'UA4602009006': 'Орів',
  'UA4602009007': 'Станиля',
  'UA4602009001': 'Трускавець',
  'UA4602009008': 'Уличне',
  'UA4602009004': 'Зимівки',
  'UA4606001003': 'Баківці',
  'UA4606001004': 'Бертишів',
  'UA4606001001': 'Бібрка',
  'UA4606001005': 'Благодатівка',
  'UA4606001011': 'Глібовичі',
  'UA4606001012': 'Гончарів',
  'UA4606001013': 'Грабник',
  'UA4606001045': 'Ходорківці',
  'UA4606001017': 'Кнісело',
  'UA4606001018': 'Кологори',
  'UA4606001019': 'Копань',
  'UA4606001016': 'Квітневе',
  'UA4606001020': 'Лани',
  'UA4606001021': 'Лінія',
  'UA4606001023': 'Любешка',
  'UA4606001022': 'Лопушна',
  'UA4606001024': 'Малі Ланки',
  'UA4606001026': 'Мостище',
  'UA4606001025': 'Мивсева',
  'UA4606001002': 'Нові Стрілища',
  'UA4606001027': 'Орішківці',
  'UA4606001032': 'П\'ятничани',
  'UA4606001029': 'Підгородище',
  'UA4606001031': 'Під\'ярків',
  'UA4606001030': 'Підмонастир',
  'UA4606001028': 'Підвисоке',
  'UA4606001033': 'Репехів',
  'UA4606001034': 'Романів',
  'UA4606001036': 'Селиська',
  'UA4606001037': 'Сенів',
  'UA4606001038': 'Серники',
  'UA4606001046': 'Шпильчина',
  'UA4606001039': 'Соколівка',
  'UA4606001040': 'Старі Стрілища',
  'UA4606001041': 'Стоки',
  'UA4606001042': 'Стрілки',
  'UA4606001043': 'Суходіл',
  'UA4606001035': 'Свірж',
  'UA4606001044': 'Трибоківці',
  'UA4606001006': 'Великі Глібовичі',
  'UA4606001008': 'Вілявче',
  'UA4606001007': 'Вільховець',
  'UA4606001010': 'Волощина',
  'UA4606001009': 'Волове',
  'UA4606001014': 'Задубина',
  'UA4606001015': 'Закривець',
  'UA4606009002': 'Бережани',
  'UA4606009003': 'Будьків',
  'UA4606009021': 'Черепин',
  'UA4606009022': 'Чишки',
  'UA4606009001': 'Давидів',
  'UA4606009012': 'Дмитровичі',
  'UA4606009007': 'Гаї',
  'UA4606009008': 'Гончари',
  'UA4606009009': 'Горішній',
  'UA4606009010': 'Городиславичі',
  'UA4606009011': 'Гринів',
  'UA4606009014': 'Коцурів',
  'UA4606009015': 'Кротошин',
  'UA4606009016': 'Миколаїв',
  'UA4606009017': 'Пасіки-Зубрицькі',
  'UA4606009018': 'Підсоснів',
  'UA4606009023': 'Шоломинь',
  'UA4606009019': 'Соснівка',
  'UA4606009020': 'Старе Село',
  'UA4606009005': 'Відники',
  'UA4606009006': 'Волиця',
  'UA4606009004': 'Виннички',
  'UA4606009013': 'Звенигород',
  'UA4606011003': 'Бабії',
  'UA4606011006': 'Бір-Кунинський',
  'UA4606011005': 'Бірки',
  'UA4606011007': 'Боброїди',
  'UA4606011008': 'Бучми',
  'UA4606011004': 'Бишків',
  'UA4606011001': 'Добросин',
  'UA4606011013': 'Думичі',
  'UA4606011011': 'Городжів',
  'UA4606011012': 'Гринчуки',
  'UA4606011019': 'Качмарі',
  'UA4606011018': 'Кам\'яна Гора',
  'UA4606011035': 'Хитрейки',
  'UA4606011020': 'Кіпті',
  'UA4606011021': 'Кулиничі',
  'UA4606011022': 'Кунин',
  'UA4606011023': 'Лавриків',
  'UA4606011024': 'Лущики',
  'UA4606011002': 'Магерів',
  'UA4606011025': 'Мавдрики',
  'UA4606011027': 'Монастирок',
  'UA4606011026': 'Миляво',
  'UA4606011028': 'Окопи',
  'UA4606011029': 'Панчищини',
  'UA4606011032': 'Піддеревенка',
  'UA4606011033': 'Підлісся',
  'UA4606011034': 'Погарисько',
  'UA4606011030': 'Пили',
  'UA4606011031': 'Пирятин',
  'UA4606011036': 'Цитуля',
  'UA4606011009': 'Велике Передмістя',
  'UA4606011010': 'Віхті',
  'UA4606011015': 'Замок',
  'UA4606011016': 'Зарище',
  'UA4606011017': 'Зубейки',
  'UA4606005001': 'Глиняни',
  'UA4606005006': 'Косичі',
  'UA4606005007': 'Кривичі',
  'UA4606005008': 'Куровичі',
  'UA4606005009': 'Мазів',
  'UA4606005011': 'Печенія',
  'UA4606005010': 'Перегноїв',
  'UA4606005012': 'Підгайчики',
  'UA4606005013': 'Погорільці',
  'UA4606005014': 'Розворяни',
  'UA4606005018': 'Шопки',
  'UA4606005015': 'Словіта',
  'UA4606005016': 'Солова',
  'UA4606005017': 'Туркотин',
  'UA4606005002': 'Великий Полюхів',
  'UA4606005003': 'Вижняни',
  'UA4606005019': 'Якторів',
  'UA4606005005': 'Заставне',
  'UA4606005004': 'Женів',
  'UA4606007002': 'Артищів',
  'UA4606007003': 'Бар',
  'UA4606007004': 'Бартатів',
  'UA4606007005': 'Братковичі',
  'UA4606007038': 'Черлянське Передмістя',
  'UA4606007037': 'Черляни',
  'UA4606007012': 'Добряни',
  'UA4606007013': 'Долиняни',
  'UA4606007014': 'Дроздовичі',
  'UA4606007015': 'Дубаневичі',
  'UA4606007009': 'Галичани',
  'UA4606007010': 'Годвишня',
  'UA4606007001': 'Городок',
  'UA4606007011': 'Градівка',
  'UA4606007020': 'Керниця',
  'UA4606007021': 'Лісновичі',
  'UA4606007022': 'Любовичі',
  'UA4606007023': 'Мавковичі',
  'UA4606007026': 'Молошки',
  'UA4606007027': 'Мшана',
  'UA4606007024': 'Мильчиці',
  'UA4606007025': 'Милятин',
  'UA4606007028': 'Підмогилка',
  'UA4606007029': 'Побережне',
  'UA4606007030': 'Повітно',
  'UA4606007031': 'Путятичі',
  'UA4606007032': 'Речичани',
  'UA4606007033': 'Родатичі',
  'UA4606007039': 'Шоломиничі',
  'UA4606007034': 'Стоділки',
  'UA4606007035': 'Тучапи',
  'UA4606007036': 'Угри',
  'UA4606007006': 'Велика Калинка',
  'UA4606007008': 'Воля-Бартатівська',
  'UA4606007007': 'Вовчухи',
  'UA4606007017': 'Залужжя',
  'UA4606007016': 'Заверешиця',
  'UA4606007018': 'Зелений Гай',
  'UA4606007019': 'Зушиці',
  'UA4606019002': 'Батятичі',
  'UA4606019008': 'Дальнич',
  'UA4606019009': 'Деревляни',
  'UA4606019010': 'Дернів',
  'UA4606019006': 'Гайок',
  'UA4606019007': 'Грушка',
  'UA4606019001': 'Кам\'янка-Бузька',
  'UA4606019014': 'Красічин',
  'UA4606019015': 'Липники',
  'UA4606019016': 'Мазярка',
  'UA4606019017': 'Обидів',
  'UA4606019018': 'Прибужани',
  'UA4606019019': 'Рожанка',
  'UA4606019020': 'Руда',
  'UA4606019021': 'Руда-Сілецька',
  'UA4606019022': 'Сапіжанка',
  'UA4606019023': 'Сокіл',
  'UA4606019024': 'Спас',
  'UA4606019025': 'Стрептів',
  'UA4606019026': 'Тадані',
  'UA4606019027': 'Товмач',
  'UA4606019003': 'Верени',
  'UA4606019005': 'Воля-Жовтанецька',
  'UA4606019004': 'Високофедорівка',
  'UA4606019028': 'Ягідня',
  'UA4606019029': 'Ямне',
  'UA4606019012': 'Забужжя',
  'UA4606019011': 'Желдець',
  'UA4606019013': 'Зубів Міст',
  'UA4606021002': 'Андріянів',
  'UA4606021003': 'Березець',
  'UA4606021004': 'Бучали',
  'UA4606021005': 'Грабине',
  'UA4606021006': 'Грімне',
  'UA4606021008': 'Катериничі',
  'UA4606021009': 'Кліцько',
  'UA4606021001': 'Комарно',
  'UA4606021011': 'Лівчиці',
  'UA4606021010': 'Литовка',
  'UA4606021012': 'Монастирець',
  'UA4606021013': 'Мости',
  'UA4606021014': 'Нове Село',
  'UA4606021015': 'Паланики',
  'UA4606021016': 'Переможне',
  'UA4606021017': 'Підзвіринець',
  'UA4606021018': 'Поляна',
  'UA4606021019': 'Татаринів',
  'UA4606021020': 'Тершаків',
  'UA4606021021': 'Тулиголове',
  'UA4606021022': 'Якимчиці',
  'UA4606021007': 'Заболоття',
  'UA4606023002': 'Артасів',
  'UA4606023005': 'Гребінці',
  'UA4606023008': 'Кошелів',
  'UA4606023007': 'Костеїв',
  'UA4606023001': 'Куликів',
  'UA4606023009': 'Малий Дорошів',
  'UA4606023010': 'Мервичі',
  'UA4606023011': 'Могиляни',
  'UA4606023012': 'Надичі',
  'UA4606023013': 'Нове Село',
  'UA4606023014': 'Перемивки',
  'UA4606023015': 'Смереків',
  'UA4606023016': 'Стронятин',
  'UA4606023017': 'Сулимів',
  'UA4606023003': 'Великий Дорошів',
  'UA4606023004': 'Віднів',
  'UA4606023006': 'Звертів',
  'UA4606025004': 'Брюховичі',
  'UA4606025003': 'Дубляни',
  'UA4606025008': 'Гряда',
  'UA4606025001': 'Львів',
  'UA4606025013': 'Лисиничі',
  'UA4606025014': 'Малехів',
  'UA4606025015': 'Малі Грибовичі',
  'UA4606025016': 'Малі Підліски',
  'UA4606025017': 'Підбірці',
  'UA4606025018': 'Підрясне',
  'UA4606025019': 'Рясне-Руське',
  'UA4606025005': 'Рудне',
  'UA4606025020': 'Ситихів',
  'UA4606025006': 'Великі Грибовичі',
  'UA4606025007': 'Воля-Гомулецька',
  'UA4606025002': 'Винники',
  'UA4606025010': 'Зарудці',
  'UA4606025011': 'Зашків',
  'UA4606025009': 'Завадів',
  'UA4606025012': 'Збиранка',
  'UA4606027002': 'Гамаліївка',
  'UA4606027003': 'Кам\'янопіль',
  'UA4606027001': 'Муроване',
  'UA4606027004': 'Сороки-Львівські',
  'UA4606027005': 'Ямпіль',
  'UA4606029003': 'Банюнин',
  'UA4606029004': 'Борщовичі',
  'UA4606029007': 'Дідилів',
  'UA4606029017': 'Хренів',
  'UA4606029008': 'Кукезів',
  'UA4606029009': 'Малі Нагірці',
  'UA4606029010': 'Неслухів',
  'UA4606029011': 'Нова Лодина',
  'UA4606029001': 'Новий Яричів',
  'UA4606029012': 'Пикуловичі',
  'UA4606029013': 'Руданці',
  'UA4606029014': 'Соколів',
  'UA4606029015': 'Старий Яричів',
  'UA4606029018': 'Цеперів',
  'UA4606029016': 'Убині',
  'UA4606029005': 'Великі Підліски',
  'UA4606029006': 'Великосілки',
  'UA4606029002': 'Запитів',
  'UA4606031002': 'Дібрівки',
  'UA4606031003': 'Конопниця',
  'UA4606031001': 'Оброшине',
  'UA4606031004': 'Підгайці',
  'UA4606031005': 'Пришляки',
  'UA4606031006': 'Ставчани',
  'UA4606033002': 'Бачів',
  'UA4606033003': 'Біле',
  'UA4606033004': 'Білка',
  'UA4606033005': 'Болотня',
  'UA4606033006': 'Боршів',
  'UA4606033008': 'Брюховичі',
  'UA4606033007': 'Брикун',
  'UA4606033058': 'Чемеринці',
  'UA4606033059': 'Чуперносів',
  'UA4606033014': 'Добряничі',
  'UA4606033015': 'Дунаїв',
  'UA4606033016': 'Дусанів',
  'UA4606033012': 'Ганачівка',
  'UA4606033013': 'Гуральня',
  'UA4606033019': 'Іванівка',
  'UA4606033020': 'Камінна',
  'UA4606033057': 'Хомина',
  'UA4606033022': 'Кореличі',
  'UA4606033023': 'Коросно',
  'UA4606033024': 'Костенів',
  'UA4606033027': 'Кучерівка',
  'UA4606033026': 'Курний',
  'UA4606033025': 'Кузубатиця',
  'UA4606033021': 'Кимир',
  'UA4606033029': 'Ладанці',
  'UA4606033028': 'Лагодів',
  'UA4606033031': 'Лоні',
  'UA4606033032': 'Лонівка',
  'UA4606033030': 'Липівці',
  'UA4606033033': 'Малий Полюхів',
  'UA4606033034': 'Мерещів',
  'UA4606033035': 'Неділиська',
  'UA4606033036': 'Новосілки',
  'UA4606033037': 'Осталовичі',
  'UA4606033001': 'Перемишляни',
  'UA4606033038': 'Підсмереки',
  'UA4606033039': 'Плеників',
  'UA4606033040': 'Плетеничі',
  'UA4606033041': 'Плоска',
  'UA4606033042': 'Пнятин',
  'UA4606033044': 'Подусільна',
  'UA4606033043': 'Подусів',
  'UA4606033045': 'Прибинь',
  'UA4606033046': 'Рівна',
  'UA4606033047': 'Розсохи',
  'UA4606033048': 'Рубче',
  'UA4606033050': 'Смереківка',
  'UA4606033051': 'Станимир',
  'UA4606033049': 'Сивороги',
  'UA4606033052': 'Тернівка',
  'UA4606033053': 'Тучне',
  'UA4606033054': 'Унів',
  'UA4606033056': 'Ушковичі',
  'UA4606033055': 'Утіховичі',
  'UA4606033011': 'Вовків',
  'UA4606033009': 'Виписки',
  'UA4606033010': 'Вишнівчик',
  'UA4606033017': 'Заставки-Яблунів',
  'UA4606033018': 'Затемне',
  'UA4606035011': 'Чорнушовичі',
  'UA4606035010': 'Чижиків',
  'UA4606035003': 'Глуховичі',
  'UA4606035005': 'Миклашів',
  'UA4606035006': 'Нижня Білка',
  'UA4606035001': 'Підберізці',
  'UA4606035007': 'Підгірне',
  'UA4606035008': 'Сухоріччя',
  'UA4606035009': 'Тарасівка',
  'UA4606035002': 'Верхня Білка',
  'UA4606035004': 'Журавники',
  'UA4606037002': 'Береги',
  'UA4606037004': 'Диб\'янки',
  'UA4606037005': 'Малинівка',
  'UA4606037007': 'Містки',
  'UA4606037006': 'Милошевичі',
  'UA4606037008': 'Наварія',
  'UA4606037009': 'Полянка',
  'UA4606037001': 'Пустомити',
  'UA4606037010': 'Семенівка',
  'UA4606037003': 'Віняви',
  'UA4606039002': 'Березина',
  'UA4606039003': 'Борове',
  'UA4606039004': 'Буди',
  'UA4606039045': 'Чорнії',
  'UA4606039014': 'Дев\'ятир',
  'UA4606039015': 'Дубрівка',
  'UA4606039016': 'Думи',
  'UA4606039008': 'Гійче',
  'UA4606039009': 'Гіркани',
  'UA4606039010': 'Гірки',
  'UA4606039011': 'Голокам\'янка',
  'UA4606039012': 'Горяни',
  'UA4606039013': 'Гута Обединська',
  'UA4606039021': 'Капелюх',
  'UA4606039022': 'Клебани',
  'UA4606039023': 'Ковалі',
  'UA4606039024': 'Криве',
  'UA4606039026': 'Лосини',
  'UA4606039027': 'Луг',
  'UA4606039029': 'Луцики',
  'UA4606039028': 'Лужки',
  'UA4606039025': 'Липник',
  'UA4606039031': 'Малі Долини',
  'UA4606039030': 'Малий',
  'UA4606039032': 'Мощана',
  'UA4606039034': 'Нова Кам\'янка',
  'UA4606039033': 'Ниви',
  'UA4606039035': 'Оліярники',
  'UA4606039036': 'Пільце',
  'UA4606039037': 'Помлинів',
  'UA4606039038': 'Потелич',
  'UA4606039040': 'Рата',
  'UA4606039001': 'Рава-Руська',
  'UA4606039039': 'Равське',
  'UA4606039041': 'Річки',
  'UA4606039046': 'Шабельня',
  'UA4606039043': 'Сорочі Лози',
  'UA4606039044': 'Старе Село',
  'UA4606039042': 'Синьковичі',
  'UA4606039005': 'Великі Долини',
  'UA4606039006': 'Вільшанка',
  'UA4606039007': 'Волиця',
  'UA4606039020': 'Йоничі',
  'UA4606039017': 'Забір\'я',
  'UA4606039018': 'Загір\'я',
  'UA4606039019': 'Зелена Гута',
  'UA4606045015': 'Черкаси',
  'UA4606045004': 'Дмитре',
  'UA4606045005': 'Дуб\'янка',
  'UA4606045002': 'Горбачі',
  'UA4606045003': 'Гуменець',
  'UA4606045006': 'Лани',
  'UA4606045007': 'Льопи',
  'UA4606045008': 'Никонковичі',
  'UA4606045009': 'Одиноке',
  'UA4606045010': 'Піски',
  'UA4606045011': 'Попеляни',
  'UA4606045012': 'Сердиця',
  'UA4606045001': 'Щирець',
  'UA4606045016': 'Шуфраганка',
  'UA4606045013': 'Соколівка',
  'UA4606045014': 'Сороки',
  'UA4606045017': 'Яструбків',
  'UA4606041002': 'Басівка',
  'UA4606041003': 'Годовиця',
  'UA4606041001': 'Сокільники',
  'UA4606043004': 'Деревач',
  'UA4606043003': 'Грабник',
  'UA4606043021': 'Хоросно',
  'UA4606043008': 'Ков\'ярі',
  'UA4606043009': 'Кугаїв',
  'UA4606043010': 'Липники',
  'UA4606043011': 'Малечковичі',
  'UA4606043012': 'Милятичі',
  'UA4606043013': 'Нагоряни',
  'UA4606043014': 'Новосілка',
  'UA4606043015': 'Підсадки',
  'UA4606043016': 'Підтемне',
  'UA4606043017': 'Поршна',
  'UA4606043018': 'Раковець',
  'UA4606043019': 'Селисько',
  'UA4606043001': 'Солонка',
  'UA4606043020': 'Товщів',
  'UA4606043002': 'Вовків',
  'UA4606043006': 'Загір\'я',
  'UA4606043005': 'Жирівка',
  'UA4606043007': 'Зубра',
  'UA4606003002': 'Бірче',
  'UA4606003016': 'Чуловичі',
  'UA4606003015': 'Хишевичі',
  'UA4606003006': 'Коропуж',
  'UA4606003007': 'Косівець',
  'UA4606003009': 'Мальованка',
  'UA4606003008': 'Малий Любінь',
  'UA4606003010': 'Піски',
  'UA4606003012': 'Поріччя Задвірне',
  'UA4606003011': 'Поріччя',
  'UA4606003013': 'Поріччя-Грунтове',
  'UA4606003014': 'Романівка',
  'UA4606003001': 'Великий Любінь',
  'UA4606003004': 'Залужани',
  'UA4606003005': 'Зашковичі',
  'UA4606003003': 'Завидовичі',
  'UA4606013002': 'Бесіди',
  'UA4606013003': 'Блищиводи',
  'UA4606013004': 'Борові',
  'UA4606013048': 'Чистопілля',
  'UA4606013012': 'Деревня',
  'UA4606013013': 'Дернівка',
  'UA4606013014': 'Діброва',
  'UA4606013047': 'Фійна',
  'UA4606013009': 'Галасі',
  'UA4606013010': 'Глинськ',
  'UA4606013011': 'Гори',
  'UA4606013020': 'Казумин',
  'UA4606013022': 'Копанка',
  'UA4606013021': 'Козулька',
  'UA4606013023': 'Крехів',
  'UA4606013024': 'Кропи',
  'UA4606013025': 'Крута Долина',
  'UA4606013026': 'Кулява',
  'UA4606013028': 'Любеля',
  'UA4606013027': 'Липники',
  'UA4606013029': 'Майдан',
  'UA4606013030': 'Малі Передримихи',
  'UA4606013031': 'Мацошин',
  'UA4606013032': 'Мокротин',
  'UA4606013033': 'Нагірці',
  'UA4606013034': 'Нова Скварява',
  'UA4606013035': 'Оплітна',
  'UA4606013036': 'Папірня',
  'UA4606013037': 'Поляни',
  'UA4606013038': 'Руда',
  'UA4606013039': 'Руда-Крехівська',
  'UA4606013040': 'Сарнівка',
  'UA4606013049': 'Школярі',
  'UA4606013041': 'Сопошин',
  'UA4606013042': 'Сороки',
  'UA4606013043': 'Соснина',
  'UA4606013044': 'Стара Скварява',
  'UA4606013045': 'Тернів',
  'UA4606013046': 'Туринка',
  'UA4606013005': 'Великі Передримихи',
  'UA4606013008': 'В\'язова',
  'UA4606013006': 'Відродження',
  'UA4606013007': 'Воля-Висоцька',
  'UA4606013015': 'Забрід',
  'UA4606013017': 'Залози',
  'UA4606013018': 'Замочок',
  'UA4606013016': 'Завади',
  'UA4606013001': 'Жовква',
  'UA4606013019': 'Зіболки',
  'UA4606015013': 'Честині',
  'UA4606015006': 'Горпин',
  'UA4606015007': 'Грабовець',
  'UA4606015008': 'Колоденці',
  'UA4606015009': 'Новий Став',
  'UA4606015010': 'Печихвости',
  'UA4606015011': 'Ременів',
  'UA4606015012': 'Ставники',
  'UA4606015002': 'Велике Колодно',
  'UA4606015005': 'Вихопні',
  'UA4606015003': 'Вирів',
  'UA4606015004': 'Вислобоки',
  'UA4606015014': 'Якимів',
  'UA4606015001': 'Жовтанці',
  'UA4606017005': 'Холодновідка',
  'UA4606017002': 'Лапаївка',
  'UA4606017003': 'Скнилів',
  'UA4606017004': 'Суховоля',
  'UA4606017001': 'Зимна Вода',
  'UA4608001002': 'Баранівці',
  'UA4608001003': 'Берестяни',
  'UA4608001001': 'Бісковичі',
  'UA4608001005': 'Букова',
  'UA4608001004': 'Биличі',
  'UA4608001015': 'Колонія',
  'UA4608001016': 'Копань',
  'UA4608001017': 'Красниця',
  'UA4608001018': 'Лановичі',
  'UA4608001019': 'Лютовиська',
  'UA4608001020': 'Максимовичі',
  'UA4608001021': 'Мала Вербівка',
  'UA4608001022': 'Малі Баранівці',
  'UA4608001023': 'Міжгайці',
  'UA4608001024': 'Надиби',
  'UA4608001025': 'П\'яновичі',
  'UA4608001026': 'Ракова',
  'UA4608001027': 'Рогізно',
  'UA4608001028': 'Рудня',
  'UA4608001029': 'Садковичі',
  'UA4608001030': 'Сусідовичі',
  'UA4608001031': 'Тарава',
  'UA4608001006': 'Вербівка',
  'UA4608001007': 'Верхівці',
  'UA4608001009': 'Владипіль',
  'UA4608001012': 'Воютичі',
  'UA4608001011': 'Воля-Баранецька',
  'UA4608001010': 'Волиця',
  'UA4608001008': 'Викоти',
  'UA4608001032': 'Язи',
  'UA4608001013': 'Заріччя',
  'UA4608001014': 'Заріччя',
  'UA4608003002': 'Багнувате',
  'UA4608003003': 'Беньова',
  'UA4608003004': 'Бітля',
  'UA4608003005': 'Боберка',
  'UA4608003001': 'Бориня',
  'UA4608003006': 'Буковинка',
  'UA4608003015': 'Івашківці',
  'UA4608003016': 'Карпатське',
  'UA4608003017': 'Комарники',
  'UA4608003018': 'Кривка',
  'UA4608003019': 'Либохора',
  'UA4608003020': 'Межигір\'я',
  'UA4608003025': 'Нижня Яблунька',
  'UA4608003023': 'Нижнє Гусне',
  'UA4608003022': 'Нижнє Висоцьке',
  'UA4608003021': 'Нижнє',
  'UA4608003024': 'Нижній Турів',
  'UA4608003027': 'Ропавське',
  'UA4608003026': 'Риків',
  'UA4608003030': 'Шандровець',
  'UA4608003031': 'Штуковець',
  'UA4608003029': 'Сянки',
  'UA4608003028': 'Сигловате',
  'UA4608003011': 'Верхня Яблунька',
  'UA4608003009': 'Верхнє Гусне',
  'UA4608003008': 'Верхнє Висоцьке',
  'UA4608003007': 'Верхнє',
  'UA4608003010': 'Верхній Турів',
  'UA4608003032': 'Яблунів',
  'UA4608003033': 'Яворів',
  'UA4608003012': 'Закичера',
  'UA4608003013': 'Заріччя',
  'UA4608003014': 'Зворець',
  'UA4608005004': 'Болозів',
  'UA4608005005': 'Боневичі',
  'UA4608005006': 'Боршевичі',
  'UA4608005003': 'Библо',
  'UA4608005037': 'Чижки',
  'UA4608005013': 'Дешичі',
  'UA4608005001': 'Добромиль',
  'UA4608005014': 'Дроздовичі',
  'UA4608005009': 'Городисько',
  'UA4608005010': 'Грабівниця',
  'UA4608005011': 'Грушатичі',
  'UA4608005012': 'Губичі',
  'UA4608005016': 'Княжпіль',
  'UA4608005017': 'Комаровичі',
  'UA4608005018': 'Конів',
  'UA4608005019': 'Кропивник',
  'UA4608005020': 'Мігово',
  'UA4608005021': 'Міженець',
  'UA4608005023': 'Нове Місто',
  'UA4608005002': 'Нижанковичі',
  'UA4608005022': 'Нижня Вовча',
  'UA4608005024': 'Пацьковичі',
  'UA4608005025': 'Передільниця',
  'UA4608005029': 'П\'ятниця',
  'UA4608005026': 'Підмостичі',
  'UA4608005027': 'Поляна',
  'UA4608005028': 'Посада-Новоміська',
  'UA4608005030': 'Рожеве',
  'UA4608005031': 'Саночани',
  'UA4608005032': 'Солянуватка',
  'UA4608005033': 'Стороневичі',
  'UA4608005034': 'Тернава',
  'UA4608005035': 'Товарна',
  'UA4608005036': 'Трушевичі',
  'UA4608005007': 'Велике',
  'UA4608005008': 'Вілюничі',
  'UA4608005015': 'Зоротовичі',
  'UA4608021002': 'Березів',
  'UA4608021003': 'Буньковичі',
  'UA4608021024': 'Чаплі',
  'UA4608021005': 'Глибока',
  'UA4608021006': 'Городовичі',
  'UA4608021007': 'Гуманець',
  'UA4608021010': 'Іванів',
  'UA4608021011': 'Катина',
  'UA4608021001': 'Хирів',
  'UA4608021012': 'Лібухова',
  'UA4608021013': 'Лопушниця',
  'UA4608021014': 'Муроване',
  'UA4608021015': 'Павлівка',
  'UA4608021016': 'Поляна',
  'UA4608021017': 'Райнова',
  'UA4608021025': 'Шумина',
  'UA4608021018': 'Скелівка',
  'UA4608021020': 'Слохині',
  'UA4608021019': 'Сливниця',
  'UA4608021021': 'Старява',
  'UA4608021022': 'Тарнавка',
  'UA4608021023': 'Терло',
  'UA4608021004': 'Велика Сушиця',
  'UA4608021008': 'Заріччя',
  'UA4608021009': 'Засадки',
  'UA4608007003': 'Бабина',
  'UA4608007004': 'Береги',
  'UA4608007005': 'Бірчиці',
  'UA4608007002': 'Дубляни',
  'UA4608007009': 'Гординя',
  'UA4608007012': 'Калинів',
  'UA4608007013': 'Климівщина',
  'UA4608007015': 'Корналовичі',
  'UA4608007016': 'Корничі',
  'UA4608007014': 'Ковиничі',
  'UA4608007017': 'Кружики',
  'UA4608007018': 'Лука',
  'UA4608007019': 'Майнич',
  'UA4608007020': 'Мала Білина',
  'UA4608007022': 'Мала Хвороща',
  'UA4608007021': 'Мала Озимина',
  'UA4608007023': 'Містковичі',
  'UA4608007024': 'Нові Бірчиці',
  'UA4608007001': 'Новий Калинів',
  'UA4608007025': 'Пиняни',
  'UA4608007006': 'Велика Білина',
  'UA4608007008': 'Велика Хвороща',
  'UA4608007007': 'Велика Озимина',
  'UA4608007010': 'Залужани',
  'UA4608007011': 'Зарайське',
  'UA4608009002': 'Бережниця',
  'UA4608009003': 'Блажів',
  'UA4608009022': 'Черхава',
  'UA4608009023': 'Чуква',
  'UA4608009007': 'Глибоч',
  'UA4608009008': 'Городище',
  'UA4608009021': 'Хатки',
  'UA4608009011': 'Кульчиці',
  'UA4608009012': 'Лопушно',
  'UA4608009013': 'Лукавиця',
  'UA4608009014': 'Мала Спринька',
  'UA4608009015': 'Млин',
  'UA4608009016': 'Монастирець',
  'UA4608009017': 'Нагірне',
  'UA4608009001': 'Ралівка',
  'UA4608009018': 'Сіде',
  'UA4608009019': 'Сприня',
  'UA4608009020': 'Трояни',
  'UA4608009004': 'Вільшаник',
  'UA4608009005': 'Воля-Блажівська',
  'UA4608009006': 'Волянка',
  'UA4608009009': 'Задністря',
  'UA4608009010': 'Звір',
  'UA4608011031': 'Чайковичі',
  'UA4608011032': 'Чернихів',
  'UA4608011006': 'Долобів',
  'UA4608011010': 'Канафости',
  'UA4608011030': 'Хлопчиці',
  'UA4608011011': 'Колбаєвичі',
  'UA4608011012': 'Конюшки-Королівські',
  'UA4608011013': 'Конюшки-Тулиголівські',
  'UA4608011014': 'Круковець',
  'UA4608011015': 'Купновичі',
  'UA4608011016': 'Луки',
  'UA4608011017': 'Малинів',
  'UA4608011018': 'Михайлевичі',
  'UA4608011022': 'Новосілки-Гостинні',
  'UA4608011021': 'Новий Острів',
  'UA4608011020': 'Никловичі',
  'UA4608011019': 'Нижнє',
  'UA4608011023': 'Орховичі',
  'UA4608011024': 'Острів',
  'UA4608011025': 'Підгайчики',
  'UA4608011027': 'Подільці',
  'UA4608011026': 'Погірці',
  'UA4608011028': 'Роздільне',
  'UA4608011001': 'Рудки',
  'UA4608011033': 'Шептичі',
  'UA4608011029': 'Сусолів',
  'UA4608011002': 'Ваньковичі',
  'UA4608011004': 'Вістовичі',
  'UA4608011005': 'Вощанці',
  'UA4608011003': 'Вишня',
  'UA4608011034': 'Яремків',
  'UA4608011009': 'Задністряни',
  'UA4608011007': 'Загір\'я',
  'UA4608011008': 'Загір\'я',
  'UA4608013002': 'Білоки',
  'UA4608013004': 'Дубрівка',
  'UA4608013001': 'Самбір',
  'UA4608013005': 'Стрілковичі',
  'UA4608013003': 'Ваньовичі',
  'UA4608015003': 'Бачина',
  'UA4608015004': 'Біличі',
  'UA4608015010': 'Кобло',
  'UA4608015011': 'Лаврів',
  'UA4608015012': 'Морозовичі',
  'UA4608015013': 'Потік',
  'UA4608015014': 'Росохи',
  'UA4608015016': 'Соснівка',
  'UA4608015015': 'Созань',
  'UA4608015017': 'Спас',
  'UA4608015018': 'Стара Ропа',
  'UA4608015002': 'Стара Сіль',
  'UA4608015001': 'Старий Самбір',
  'UA4608015019': 'Страшевичі',
  'UA4608015020': 'Стрільбичі',
  'UA4608015021': 'Сушиця',
  'UA4608015023': 'Тершів',
  'UA4608015026': 'Торчиновичі',
  'UA4608015025': 'Торгановичі',
  'UA4608015022': 'Тварі',
  'UA4608015024': 'Тиха',
  'UA4608015005': 'Велика Лінина',
  'UA4608015006': 'Великосілля',
  'UA4608015008': 'Воля',
  'UA4608015007': 'Волошиново',
  'UA4608015009': 'Завадка',
  'UA4608017002': 'Бабино',
  'UA4608017003': 'Бусовисько',
  'UA4608017011': 'Дністрик',
  'UA4608017007': 'Галівка',
  'UA4608017009': 'Головецько',
  'UA4608017010': 'Грозьово',
  'UA4608017008': 'Гвоздець',
  'UA4608017012': 'Лопушанка-Хомина',
  'UA4608017013': 'Мшанець',
  'UA4608017014': 'Недільна',
  'UA4608017015': 'Плоске',
  'UA4608017016': 'Ріп\'яна',
  'UA4608017017': 'Смеречка',
  'UA4608017001': 'Стрілки',
  'UA4608017019': 'Топільниця',
  'UA4608017020': 'Тур\'є',
  'UA4608017018': 'Тисовиця',
  'UA4608017004': 'Велика Волосянка',
  'UA4608017005': 'Верхній Лужок',
  'UA4608017006': 'Виців',
  'UA4608017021': 'Ясениця-Замкова',
  'UA4608019002': 'Бережок',
  'UA4608019004': 'Дністрик-Дубовий',
  'UA4608019008': 'Ільник',
  'UA4608019009': 'Ісаї',
  'UA4608019021': 'Хащів',
  'UA4608019010': 'Кіндратів',
  'UA4608019011': 'Ліктів',
  'UA4608019012': 'Лімна',
  'UA4608019013': 'Лопушанка',
  'UA4608019014': 'Лосинець',
  'UA4608019015': 'Мала Волосянка',
  'UA4608019016': 'Мельничне',
  'UA4608019017': 'Присліп',
  'UA4608019018': 'Радич',
  'UA4608019019': 'Розлуч',
  'UA4608019022': 'Шум\'яч',
  'UA4608019020': 'Стоділка',
  'UA4608019001': 'Турка',
  'UA4608019003': 'Вовче',
  'UA4608019025': 'Ясенка-Стецьова',
  'UA4608019024': 'Ясениця',
  'UA4608019023': 'Явора',
  'UA4608019007': 'Закіпці',
  'UA4608019006': 'Завадівка',
  'UA4608019005': 'Жукотин',
  'UA4610001004': 'Дунаєць',
  'UA4610001003': 'Ганнівці',
  'UA4610001001': 'Гніздичів',
  'UA4610001006': 'Корнелівка',
  'UA4610001007': 'Королівка',
  'UA4610001008': 'Лівчиці',
  'UA4610001009': 'Махлинець',
  'UA4610001010': 'Нове Село',
  'UA4610001011': 'Облазниця',
  'UA4610001012': 'Покрівці',
  'UA4610001013': 'Руда',
  'UA4610001002': 'Воля-Облазницька',
  'UA4610001005': 'Жирівське',
  'UA4610003006': 'Довголука',
  'UA4610003001': 'Дуліби',
  'UA4610003004': 'Гірне',
  'UA4610003005': 'Грабовець',
  'UA4610003012': 'Хромогорб',
  'UA4610003007': 'Колодниця',
  'UA4610003008': 'Конюхів',
  'UA4610003009': 'Любинці',
  'UA4610003010': 'Монастирець',
  'UA4610003011': 'Нижня Стинава',
  'UA4610003002': 'Верхня Стинава',
  'UA4610003003': 'Воля-Довголуцька',
  'UA4610027002': 'Березина',
  'UA4610027004': 'Бородчиці',
  'UA4610027005': 'Бортники',
  'UA4610027006': 'Борусів',
  'UA4610027003': 'Бориничі',
  'UA4610027008': 'Бринці-Церковні',
  'UA4610027007': 'Бринці-Загірні',
  'UA4610027009': 'Буковина',
  'UA4610027040': 'Черемхів',
  'UA4610027042': 'Чорний Острів',
  'UA4610027041': 'Чижичі',
  'UA4610027019': 'Демидів',
  'UA4610027018': 'Дев\'ятники',
  'UA4610027020': 'Добрівляни',
  'UA4610027021': 'Дроховичі',
  'UA4610027022': 'Дуліби',
  'UA4610027013': 'Голдовичі',
  'UA4610027014': 'Голешів',
  'UA4610027015': 'Городище',
  'UA4610027016': 'Городищенське',
  'UA4610027017': 'Грусятичі',
  'UA4610027026': 'Калинівка',
  'UA4610027027': 'Кам\'яне',
  'UA4610027001': 'Ходорів',
  'UA4610027028': 'Лапшин',
  'UA4610027029': 'Ліщини',
  'UA4610027030': 'Лучани',
  'UA4610027031': 'Молодинче',
  'UA4610027032': 'Молотів',
  'UA4610027033': 'Новосільці',
  'UA4610027034': 'Отиневичі',
  'UA4610027035': 'Піддністряни',
  'UA4610027036': 'Підліски',
  'UA4610027037': 'Рудківці',
  'UA4610027038': 'Садки',
  'UA4610027039': 'Сугрів',
  'UA4610027010': 'Вербиця',
  'UA4610027012': 'Вовчатичі',
  'UA4610027011': 'Вибранівка',
  'UA4610027044': 'Ятвяги',
  'UA4610027043': 'Юшківці',
  'UA4610027024': 'Загірочко',
  'UA4610027025': 'Заліски',
  'UA4610027023': 'Жирова',
  'UA4610009004': 'Долинівка',
  'UA4610009003': 'Довжки',
  'UA4610009008': 'Климець',
  'UA4610009001': 'Козьова',
  'UA4610009009': 'Красне',
  'UA4610009010': 'Криве',
  'UA4610009011': 'Матків',
  'UA4610009013': 'Мохнате',
  'UA4610009012': 'Мита',
  'UA4610009014': 'Нагірне',
  'UA4610009015': 'Орява',
  'UA4610009016': 'Орявчик',
  'UA4610009017': 'Плав\'я',
  'UA4610009018': 'Погар',
  'UA4610009020': 'Росохач',
  'UA4610009019': 'Риків',
  'UA4610009021': 'Сможе',
  'UA4610009022': 'Сухий Потік',
  'UA4610009024': 'Тухолька',
  'UA4610009023': 'Тисовець',
  'UA4610009002': 'Верхнячка',
  'UA4610009007': 'Задільське',
  'UA4610009006': 'Завадка',
  'UA4610009005': 'Жупани',
  'UA4610013002': 'Баня Лисовицька',
  'UA4610013007': 'Долішнє',
  'UA4610013006': 'Довге',
  'UA4610013014': 'Фалиш',
  'UA4610013005': 'Горішнє',
  'UA4610013009': 'Лисовичі',
  'UA4610013001': 'Моршин',
  'UA4610013010': 'Нижня Лукавиця',
  'UA4610013011': 'Пила',
  'UA4610013012': 'Смоляний',
  'UA4610013013': 'Станків',
  'UA4610013003': 'Верхня Лукавиця',
  'UA4610013004': 'Воля-Задеревацька',
  'UA4610013008': 'Задеревач',
  'UA4610011002': 'Більче',
  'UA4610011003': 'Болоня',
  'UA4610011008': 'Дроговиж',
  'UA4610011006': 'Гірське',
  'UA4610011007': 'Гонятичі',
  'UA4610011009': 'Кагуїв',
  'UA4610011010': 'Колодруби',
  'UA4610011011': 'Криниця',
  'UA4610011012': 'Липиці',
  'UA4610011013': 'Листв\'яний',
  'UA4610011014': 'Мала Горожанна',
  'UA4610011001': 'Миколаїв',
  'UA4610011015': 'Новосілки-Опарські',
  'UA4610011016': 'Павуки',
  'UA4610011017': 'Підлісся',
  'UA4610011018': 'Повергів',
  'UA4610011019': 'Раделичі',
  'UA4610011021': 'Рудники',
  'UA4610011020': 'Ричагів',
  'UA4610011022': 'Сайків',
  'UA4610011023': 'Трудове',
  'UA4610011024': 'Устя',
  'UA4610011004': 'Велика Горожанна',
  'UA4610011005': 'Вербіж',
  'UA4610015003': 'Берездівці',
  'UA4610015004': 'Березина',
  'UA4610015007': 'Долішнє',
  'UA4610015005': 'Горішнє',
  'UA4610015006': 'Гранки-Кути',
  'UA4610015001': 'Новий Розділ',
  'UA4610015008': 'Підгірці',
  'UA4610015002': 'Розділ',
  'UA4610015009': 'Станківці',
  'UA4610015010': 'Тужанівці',
  'UA4610017009': 'Черниця',
  'UA4610017003': 'Держів',
  'UA4610017005': 'Крупське',
  'UA4610017004': 'Київець',
  'UA4610017006': 'Надітичі',
  'UA4610017007': 'Острів',
  'UA4610017008': 'Пісочна',
  'UA4610017001': 'Розвадів',
  'UA4610017002': 'Верин',
  'UA4610019004': 'Дубина',
  'UA4610019003': 'Гребенів',
  'UA4610019005': 'Кам\'янка',
  'UA4610019007': 'Корчин',
  'UA4610019006': 'Коростів',
  'UA4610019008': 'Крушельниця',
  'UA4610019009': 'Межиброди',
  'UA4610019010': 'Нижнє Синьовидне',
  'UA4610019011': 'Підгородці',
  'UA4610019012': 'Побук',
  'UA4610019001': 'Сколе',
  'UA4610019013': 'Сопіт',
  'UA4610019015': 'Труханів',
  'UA4610019014': 'Тишівниця',
  'UA4610019016': 'Урич',
  'UA4610019002': 'Верхнє Синьовидне',
  'UA4610019017': 'Ямельниця',
  'UA4610021004': 'Головецько',
  'UA4610021005': 'Грабовець',
  'UA4610021006': 'Кальне',
  'UA4610021014': 'Хащованя',
  'UA4610021015': 'Хітар',
  'UA4610021007': 'Лавочне',
  'UA4610021008': 'Либохора',
  'UA4610021009': 'Нижня Рожанка',
  'UA4610021010': 'Опорець',
  'UA4610021011': 'Пшонець',
  'UA4610021001': 'Славське',
  'UA4610021012': 'Тернавка',
  'UA4610021013': 'Тухля',
  'UA4610021002': 'Верхня Рожанка',
  'UA4610021003': 'Волосянка',
  'UA4610021016': 'Ялинкувате',
  'UA4610023003': 'Бережниця',
  'UA4610023004': 'Братківці',
  'UA4610023002': 'Дашава',
  'UA4610023010': 'Діброва',
  'UA4610023012': 'Добряни',
  'UA4610023011': 'Добрівляни',
  'UA4610023008': 'Гайдучина',
  'UA4610023009': 'Голобутів',
  'UA4610023019': 'Кавське',
  'UA4610023045': 'Ходовичі',
  'UA4610023020': 'Комарів',
  'UA4610023021': 'Кути',
  'UA4610023023': 'Ланівка',
  'UA4610023022': 'Лани-Соколівські',
  'UA4610023025': 'Лотатники',
  'UA4610023026': 'Луг',
  'UA4610023024': 'Лисятичі',
  'UA4610023027': 'Малі Дідушичі',
  'UA4610023028': 'Миртюки',
  'UA4610023029': 'Нежухів',
  'UA4610023030': 'Олексичі',
  'UA4610023035': 'П\'ятничани',
  'UA4610023031': 'Підгірці',
  'UA4610023032': 'Піщани',
  'UA4610023033': 'Подорожнє',
  'UA4610023034': 'Пукеничі',
  'UA4610023036': 'Райлів',
  'UA4610023037': 'Розгірче',
  'UA4610023038': 'Семигинів',
  'UA4610023046': 'Щасливе',
  'UA4610023040': 'Слобідка',
  'UA4610023042': 'Стрілків',
  'UA4610023041': 'Стриганці',
  'UA4610023001': 'Стрий',
  'UA4610023039': 'Сихів',
  'UA4610023043': 'Угерсько',
  'UA4610023044': 'Угільня',
  'UA4610023005': 'Великі Дідушичі',
  'UA4610023006': 'Верчани',
  'UA4610023007': 'Вівня',
  'UA4610023047': 'Ярушичі',
  'UA4610023018': 'Йосиповичі',
  'UA4610023015': 'Загірне',
  'UA4610023016': 'Заплатин',
  'UA4610023017': 'Зарічне',
  'UA4610023014': 'Завадів',
  'UA4610023013': 'Жулин',
  'UA4610025002': 'Бродки',
  'UA4610025005': 'Демня',
  'UA4610025006': 'Добряни',
  'UA4610025007': 'Дуброва',
  'UA4610025004': 'Глухівець',
  'UA4610025009': 'Ілів',
  'UA4610025010': 'Красів',
  'UA4610025011': 'Луб\'яна',
  'UA4610025017': 'Липівка',
  'UA4610025012': 'Мала Воля',
  'UA4610025013': 'Поляна',
  'UA4610025014': 'Стільсько',
  'UA4610025015': 'Суха Долина',
  'UA4610025016': 'Тернопілля',
  'UA4610025001': 'Тростянець',
  'UA4610025003': 'Велика Воля',
  'UA4610025008': 'Заклад',
  'UA4610007002': 'Антонівка',
  'UA4610007003': 'Буянів',
  'UA4610007027': 'Чертіж',
  'UA4610007005': 'Демівка',
  'UA4610007006': 'Дубравка',
  'UA4610007009': 'Корчівка',
  'UA4610007010': 'Которини',
  'UA4610007011': 'Крехів',
  'UA4610007013': 'Любша',
  'UA4610007014': 'Лютинка',
  'UA4610007012': 'Лисків',
  'UA4610007016': 'Маринка',
  'UA4610007015': 'Мазурівка',
  'UA4610007017': 'Мельнич',
  'UA4610007018': 'Монастирець',
  'UA4610007019': 'Новошини',
  'UA4610007020': 'Подорожнє',
  'UA4610007021': 'Протеси',
  'UA4610007022': 'Романівка',
  'UA4610007024': 'Старе Село',
  'UA4610007025': 'Сулятичі',
  'UA4610007023': 'Сидорівка',
  'UA4610007026': 'Тернавка',
  'UA4610007004': 'Володимирці',
  'UA4610007007': 'Заграбівка',
  'UA4610007008': 'Зарічне',
  'UA4610007001': 'Журавно',
  'UA4610005002': 'Бережниця',
  'UA4610005005': 'Дем\'янка-Лісна',
  'UA4610005006': 'Дем\'янка-Наддністрянська',
  'UA4610005011': 'Іванівці',
  'UA4610005012': 'Межиріччя',
  'UA4610005013': 'Млиниська',
  'UA4610005014': 'Пчани',
  'UA4610005015': 'Рогізно',
  'UA4610005016': 'Смогів',
  'UA4610005017': 'Тейсарів',
  'UA4610005018': 'Туради',
  'UA4610005003': 'Вільхівці',
  'UA4610005004': 'Волиця-Гніздичівська',
  'UA4610005008': 'Заболотівці',
  'UA4610005009': 'Загурщина',
  'UA4610005010': 'Заріччя',
  'UA4610005007': 'Журавків',
  'UA4610005001': 'Жидачів',
  'UA4614001002': 'Бірки',
  'UA4614001003': 'Буда',
  'UA4614001009': 'Діброва',
  'UA4614001010': 'Домажир',
  'UA4614001011': 'Дубровиця',
  'UA4614001001': 'Івано-Франкове',
  'UA4614001016': 'Карачинів',
  'UA4614001017': 'Кожичі',
  'UA4614001018': 'Лелехівка',
  'UA4614001019': 'Лісопотік',
  'UA4614001020': 'Лозино',
  'UA4614001021': 'Мальчиці',
  'UA4614001022': 'Озерське',
  'UA4614001023': 'Паланки',
  'UA4614001024': 'Поріччя',
  'UA4614001025': 'Рокитне',
  'UA4614001026': 'Середній Горб',
  'UA4614001027': 'Солуки',
  'UA4614001028': 'Ставки',
  'UA4614001029': 'Страдч',
  'UA4614001030': 'Турича',
  'UA4614001004': 'Великі Гори',
  'UA4614001005': 'Великополе',
  'UA4614001006': 'Верещиця',
  'UA4614001007': 'Верхутка',
  'UA4614001008': 'Вороців',
  'UA4614001031': 'Ямельня',
  'UA4614001032': 'Ясниська',
  'UA4614001013': 'Задебрі',
  'UA4614001014': 'Затока',
  'UA4614001015': 'Зелів',
  'UA4614001012': 'Жорниська',
  'UA4614003002': 'Арламівська Воля',
  'UA4614003003': 'Берегове',
  'UA4614003004': 'Буховичі',
  'UA4614003060': 'Черневе',
  'UA4614003062': 'Чишки',
  'UA4614003061': 'Чижевичі',
  'UA4614003010': 'Добощівка',
  'UA4614003011': 'Дубинки',
  'UA4614003008': 'Годині',
  'UA4614003009': 'Гостинцеве',
  'UA4614003018': 'Качмарі',
  'UA4614003057': 'Хатки',
  'UA4614003058': 'Хлиплі',
  'UA4614003059': 'Хоросниця',
  'UA4614003019': 'Княгиничі',
  'UA4614003020': 'Колодка',
  'UA4614003022': 'Корчунок',
  'UA4614003021': 'Королин',
  'UA4614003023': 'Костильники',
  'UA4614003026': 'Кропильники',
  'UA4614003027': 'Крукеничі',
  'UA4614003025': 'Крисовичі',
  'UA4614003024': 'Крив\'яки',
  'UA4614003028': 'Липники',
  'UA4614003030': 'Максимці',
  'UA4614003031': 'Малнів',
  'UA4614003032': 'Малнівська Воля',
  'UA4614003033': 'Мартини',
  'UA4614003029': 'Мазури',
  'UA4614003034': 'Мелешки',
  'UA4614003001': 'Мостиська',
  'UA4614003035': 'Мистичі',
  'UA4614003036': 'Нагірне',
  'UA4614003037': 'Ніговичі',
  'UA4614003038': 'Острожець',
  'UA4614003039': 'Петики',
  'UA4614003041': 'Підгать',
  'UA4614003042': 'Підліски',
  'UA4614003043': 'Пісок',
  'UA4614003044': 'Пнікут',
  'UA4614003040': 'Пихи',
  'UA4614003045': 'Раденичі',
  'UA4614003046': 'Рожаки',
  'UA4614003047': 'Санники',
  'UA4614003048': 'Слабаш',
  'UA4614003049': 'Соколя',
  'UA4614003050': 'Солтиси',
  'UA4614003051': 'Старява',
  'UA4614003052': 'Стоянці',
  'UA4614003053': 'Стрілецьке',
  'UA4614003054': 'Судковичі',
  'UA4614003056': 'Топільниця',
  'UA4614003055': 'Твіржа',
  'UA4614003006': 'Воля-Садківська',
  'UA4614003007': 'Вуйковичі',
  'UA4614003005': 'Вишенька',
  'UA4614003063': 'Ятвяги',
  'UA4614003016': 'Загорби',
  'UA4614003017': 'Заріччя',
  'UA4614003012': 'Завада',
  'UA4614003013': 'Завадів',
  'UA4614003014': 'Заверхи',
  'UA4614003015': 'Зав\'язанці',
  'UA4614005003': 'Бердихів',
  'UA4614005022': 'Чолгині',
  'UA4614005006': 'Добростани',
  'UA4614005008': 'Качмарі',
  'UA4614005007': 'Кам\'янобрід',
  'UA4614005009': 'Кертинів',
  'UA4614005010': 'Когути',
  'UA4614005011': 'Ліс',
  'UA4614005012': 'Молошковичі',
  'UA4614005013': 'Мужиловичі',
  'UA4614005001': 'Новояворівськ',
  'UA4614005014': 'Підлуби',
  'UA4614005015': 'Прилбичі',
  'UA4614005016': 'Рулево',
  'UA4614005002': 'Шкло',
  'UA4614005017': 'Солиги',
  'UA4614005018': 'Стадники',
  'UA4614005019': 'Старичі',
  'UA4614005020': 'Стені',
  'UA4614005021': 'Терновиця',
  'UA4614005004': 'Воля-Добростанська',
  'UA4614005005': 'Воля-Старицька',
  'UA4614009002': 'Баличі',
  'UA4614009004': 'Боєвичі',
  'UA4614009005': 'Боляновичі',
  'UA4614009006': 'Боратичі',
  'UA4614009007': 'Буців',
  'UA4614009003': 'Биків',
  'UA4614009010': 'Ганьковичі',
  'UA4614009011': 'Гориславичі',
  'UA4614009012': 'Гусаків',
  'UA4614009014': 'Іорданівка',
  'UA4614009028': 'Хатки',
  'UA4614009029': 'Хідновичі',
  'UA4614009015': 'Конюшки',
  'UA4614009016': 'Липки',
  'UA4614009017': 'Малі Новосілки',
  'UA4614009020': 'Мочеради',
  'UA4614009019': 'Мостиська Другі',
  'UA4614009018': 'Мишлятичі',
  'UA4614009021': 'Плешевичі',
  'UA4614009022': 'Поповичі',
  'UA4614009023': 'Радохинці',
  'UA4614009001': 'Шегині',
  'UA4614009024': 'Тамановичі',
  'UA4614009026': 'Толуковичі',
  'UA4614009027': 'Тщенець',
  'UA4614009030': 'Циків',
  'UA4614009025': 'Тишковичі',
  'UA4614009008': 'Великі Новосілки',
  'UA4614009009': 'Волиця',
  'UA4614009013': 'Золотковичі',
  'UA4614007002': 'Берці',
  'UA4614007003': 'Бортятин',
  'UA4614007009': 'Дмитровичі',
  'UA4614007010': 'Довгомостиська',
  'UA4614007008': 'Дидятичі',
  'UA4614007013': 'Княжий Міст',
  'UA4614007014': 'Кульматичі',
  'UA4614007015': 'Макунів',
  'UA4614007016': 'Мала Діброва',
  'UA4614007017': 'Малі Мокряни',
  'UA4614007018': 'Новосільці',
  'UA4614007019': 'Шишоровичі',
  'UA4614007001': 'Судова Вишня',
  'UA4614007004': 'Велика Діброва',
  'UA4614007005': 'Великі Мокряни',
  'UA4614007007': 'Волостків',
  'UA4614007006': 'Вовчищовичі',
  'UA4614007011': 'Загороди',
  'UA4614007012': 'Заріччя',
  'UA4614011005': 'Бориси',
  'UA4614011004': 'Божа Воля',
  'UA4614011006': 'Брожки',
  'UA4614011007': 'Бунів',
  'UA4614011077': 'Черчик',
  'UA4614011076': 'Чернилява',
  'UA4614011078': 'Чорнокунці',
  'UA4614011022': 'Дацьки',
  'UA4614011023': 'Дебрі',
  'UA4614011024': 'Дернаки',
  'UA4614011025': 'Дрогомишль',
  'UA4614011018': 'Глинець',
  'UA4614011019': 'Глиниці',
  'UA4614011020': 'Гораєць',
  'UA4614011021': 'Грушів',
  'UA4614011029': 'Іваники',
  'UA4614011030': 'Калинівка',
  'UA4614011031': 'Калитяки',
  'UA4614011032': 'Карпи',
  'UA4614011073': 'Хляни',
  'UA4614011036': 'Коханівка',
  'UA4614011034': 'Колониці',
  'UA4614011035': 'Коти',
  'UA4614011033': 'Ковалі',
  'UA4614011002': 'Краковець',
  'UA4614011039': 'Лісок',
  'UA4614011042': 'Любині',
  'UA4614011040': 'Луг',
  'UA4614011041': 'Лужки',
  'UA4614011038': 'Липовець',
  'UA4614011037': 'Липина',
  'UA4614011043': 'Мельники',
  'UA4614011044': 'Мор\'янці',
  'UA4614011045': 'Нагачів',
  'UA4614011046': 'Наконечне Друге',
  'UA4614011047': 'Наконечне Перше',
  'UA4614011003': 'Немирів',
  'UA4614011050': 'Новосілки',
  'UA4614011048': 'Новий Яр',
  'UA4614011049': 'Новини',
  'UA4614011051': 'Оселя',
  'UA4614011052': 'Пазиняки',
  'UA4614011053': 'Передвір\'я',
  'UA4614011054': 'Пісоцький',
  'UA4614011055': 'Поруби',
  'UA4614011056': 'Поруденко',
  'UA4614011057': 'Принада',
  'UA4614011058': 'Рішин',
  'UA4614011059': 'Рогізно',
  'UA4614011060': 'Роснівка',
  'UA4614011061': 'Руда',
  'UA4614011062': 'Руда-Краковецька',
  'UA4614011063': 'Салаші',
  'UA4614011064': 'Сарни',
  'UA4614011066': 'Семирівка',
  'UA4614011068': 'Середкевичі',
  'UA4614011067': 'Середина',
  'UA4614011079': 'Шаварі',
  'UA4614011081': 'Щеплоти',
  'UA4614011082': 'Щиглі',
  'UA4614011080': 'Шутова',
  'UA4614011069': 'Слободяки',
  'UA4614011070': 'Смолин',
  'UA4614011071': 'Сопіт',
  'UA4614011072': 'Старий Яр',
  'UA4614011065': 'Свидниця',
  'UA4614011074': 'Цетуля',
  'UA4614011075': 'Ціпівки',
  'UA4614011008': 'Вахули',
  'UA4614011009': 'Великі Макари',
  'UA4614011010': 'Вербляни',
  'UA4614011013': 'Війтівщина',
  'UA4614011012': 'Віжомля',
  'UA4614011016': 'Воля Любинська',
  'UA4614011015': 'Воля',
  'UA4614011017': 'Вороблячин',
  'UA4614011014': 'Вовча Гора',
  'UA4614011011': 'Висіч',
  'UA4614011001': 'Яворів',
  'UA4614011027': 'Залужжя',
  'UA4614011028': 'Зарубани',
  'UA4614011026': 'Завадів',
  'UA4604001002': 'Антося',
  'UA4604001003': 'Берлин',
  'UA4604001004': 'Білявці',
  'UA4604001006': 'Боратин',
  'UA4604001007': 'Бордуляки',
  'UA4604001005': 'Бовдури',
  'UA4604001001': 'Броди',
  'UA4604001008': 'Бучина',
  'UA4604001018': 'Дітківці',
  'UA4604001010': 'Гаї',
  'UA4604001011': 'Гаї-Дітковецькі',
  'UA4604001012': 'Гаї-Смоленські',
  'UA4604001013': 'Гаї-Суходільські',
  'UA4604001014': 'Глушин',
  'UA4604001015': 'Голосковичі',
  'UA4604001016': 'Горбалі',
  'UA4604001017': 'Грималівка',
  'UA4604001020': 'Кіз\'я',
  'UA4604001021': 'Клекотів',
  'UA4604001023': 'Комарівка',
  'UA4604001024': 'Конюшків',
  'UA4604001025': 'Королівка',
  'UA4604001026': 'Корсів',
  'UA4604001027': 'Косарщина',
  'UA4604001022': 'Ковпин Ставок',
  'UA4604001028': 'Кути',
  'UA4604001029': 'Лагодів',
  'UA4604001030': 'Лешнів',
  'UA4604001032': 'Лісове',
  'UA4604001031': 'Липина',
  'UA4604001034': 'Мідне',
  'UA4604001035': 'Монастирок',
  'UA4604001033': 'Митниця',
  'UA4604001036': 'Орани',
  'UA4604001037': 'Панькова',
  'UA4604001038': 'Переліски',
  'UA4604001039': 'Підгір\'я',
  'UA4604001040': 'Піски',
  'UA4604001042': 'Пониковиця',
  'UA4604001041': 'Пониква',
  'UA4604001043': 'Салашка',
  'UA4604001050': 'Шнирів',
  'UA4604001045': 'Смільне',
  'UA4604001046': 'Станіславчик',
  'UA4604001048': 'Суходоли',
  'UA4604001049': 'Сухота',
  'UA4604001047': 'Суховоля',
  'UA4604001044': 'Сидинівка',
  'UA4604001009': 'Видра',
  'UA4604001051': 'Язлівчик',
  'UA4604001019': 'Збруї',
  'UA4604003005': 'Бачка',
  'UA4604003004': 'Баймаки',
  'UA4604003003': 'Бажани',
  'UA4604003006': 'Боложинів',
  'UA4604003007': 'Брахівка',
  'UA4604003008': 'Будиголош',
  'UA4604003001': 'Буськ',
  'UA4604003064': 'Чаниж',
  'UA4604003065': 'Чішки',
  'UA4604003066': 'Чучмани',
  'UA4604003019': 'Думниця',
  'UA4604003020': 'Дунів',
  'UA4604003012': 'Гаївське',
  'UA4604003013': 'Горбачі',
  'UA4604003015': 'Грабова',
  'UA4604003014': 'Грабина',
  'UA4604003016': 'Гумниська',
  'UA4604003017': 'Гута',
  'UA4604003018': 'Гутисько-Тур\'янське',
  'UA4604003062': 'Хватів',
  'UA4604003029': 'Кізлів',
  'UA4604003030': 'Кудирявці',
  'UA4604003031': 'Купче',
  'UA4604003032': 'Кути',
  'UA4604003033': 'Лабач',
  'UA4604003034': 'Ланерівка',
  'UA4604003035': 'Леньків',
  'UA4604003039': 'Лісок',
  'UA4604003036': 'Лісове',
  'UA4604003037': 'Лісове',
  'UA4604003038': 'Лісове',
  'UA4604003040': 'Маращанка',
  'UA4604003044': 'Новосілки',
  'UA4604003042': 'Новий Милятин',
  'UA4604003043': 'Новий Ріпнів',
  'UA4604003041': 'Ниви',
  'UA4604003002': 'Олесько',
  'UA4604003045': 'Ожидів',
  'UA4604003046': 'Павлики',
  'UA4604003047': 'Переволочна',
  'UA4604003048': 'Підставки',
  'UA4604003049': 'Побужани',
  'UA4604003050': 'Ракобовти',
  'UA4604003052': 'Ріпнів',
  'UA4604003053': 'Рокитне',
  'UA4604003051': 'Рижани',
  'UA4604003056': 'Соколя',
  'UA4604003055': 'Соколівка',
  'UA4604003057': 'Старий Милятин',
  'UA4604003058': 'Стовпин',
  'UA4604003054': 'Сидори',
  'UA4604003059': 'Теребежі',
  'UA4604003060': 'Топорів',
  'UA4604003063': 'Циків',
  'UA4604003061': 'Тур\'я',
  'UA4604003009': 'Вербляни',
  'UA4604003011': 'Волуйки',
  'UA4604003010': 'Волиця-Деревлянська',
  'UA4604003067': 'Яблунівка',
  'UA4604003068': 'Янгелівка',
  'UA4604003028': 'Йосипівка',
  'UA4604003022': 'Заболотне',
  'UA4604003023': 'Заболото',
  'UA4604003024': 'Забрід',
  'UA4604003026': 'Закомар\'я',
  'UA4604003027': 'Застав\'є',
  'UA4604003025': 'Заводське',
  'UA4604003021': 'Журатин',
  'UA4604009009': 'Андріївка',
  'UA4604009002': 'Балучин',
  'UA4604009003': 'Безброди',
  'UA4604009004': 'Богданівка',
  'UA4604009005': 'Бортків',
  'UA4604009001': 'Красне',
  'UA4604009007': 'Куткір',
  'UA4604009008': 'Мала Вільшанка',
  'UA4604009010': 'Острів',
  'UA4604009011': 'Острівчик-Пильний',
  'UA4604009012': 'Петричі',
  'UA4604009013': 'Полоничі',
  'UA4604009014': 'Полтва',
  'UA4604009015': 'Русилів',
  'UA4604009016': 'Скнилів',
  'UA4604009017': 'Сторонибаби',
  'UA4604009018': 'Утішків',
  'UA4604009006': 'Задвір\'я',
  'UA4604011002': 'Батьків',
  'UA4604011028': 'Чепелі',
  'UA4604011029': 'Черниця',
  'UA4604011006': 'Дудин',
  'UA4604011004': 'Голубиця',
  'UA4604011005': 'Горбанівка',
  'UA4604011010': 'Кутище',
  'UA4604011011': 'Літовище',
  'UA4604011013': 'Лукаші',
  'UA4604011012': 'Лукавець',
  'UA4604011014': 'Малинище',
  'UA4604011015': 'Маркопіль',
  'UA4604011016': 'Межигори',
  'UA4604011017': 'Микити',
  'UA4604011018': 'Накваша',
  'UA4604011019': 'Нем\'яч',
  'UA4604011020': 'Орихівчик',
  'UA4604011021': 'Паликорови',
  'UA4604011022': 'Паньківці',
  'UA4604011023': 'Пеняки',
  'UA4604011001': 'Підкамінь',
  'UA4604011024': 'Попівці',
  'UA4604011031': 'Шпаки',
  'UA4604011030': 'Шишківці',
  'UA4604011026': 'Стрихалюки',
  'UA4604011025': 'Стиборівка',
  'UA4604011027': 'Тетильківці',
  'UA4604011003': 'Вербівчик',
  'UA4604011032': 'Яблунівка',
  'UA4604011033': 'Яснище',
  'UA4604011008': 'Залісся',
  'UA4604011007': 'Жарків',
  'UA4604011009': 'Звижень',
  'UA4604013002': 'Бібщани',
  'UA4604013003': 'Богутин',
  'UA4604013020': 'Чижів',
  'UA4604013007': 'Коропчик',
  'UA4604013006': 'Коропець',
  'UA4604013008': 'Красносільці',
  'UA4604013009': 'Кропивна',
  'UA4604013010': 'Кульби',
  'UA4604013011': 'Махнівці',
  'UA4604013012': 'Надільне',
  'UA4604013013': 'Нестюки',
  'UA4604013014': 'Підгір\'я',
  'UA4604013015': 'Поляни',
  'UA4604013001': 'Поморяни',
  'UA4604013016': 'Ремезівці',
  'UA4604013017': 'Роздоріжне',
  'UA4604013021': 'Шпиколоси',
  'UA4604013018': 'Сновичі',
  'UA4604013019': 'Торгів',
  'UA4604013005': 'Загора',
  'UA4604013004': 'Жуків',
  'UA4604005006': 'Дуб\'є',
  'UA4604005007': 'Дубина',
  'UA4604005010': 'Лучківці',
  'UA4604005009': 'Лугове',
  'UA4604005011': 'Малі Переліски',
  'UA4604005012': 'Мамчурі',
  'UA4604005013': 'Новичина',
  'UA4604005014': 'Підгірці',
  'UA4604005015': 'Ражнів',
  'UA4604005016': 'Руда-Брідська',
  'UA4604005017': 'Теребежі',
  'UA4604005018': 'Тріщуки',
  'UA4604005002': 'Великі Переліски',
  'UA4604005003': 'Велин',
  'UA4604005005': 'Вовковатиця',
  'UA4604005004': 'Висоцько',
  'UA4604005019': 'Ясенів',
  'UA4604005001': 'Заболотці',
  'UA4604005008': 'Загірці',
  'UA4604007002': 'Білий Камінь',
  'UA4604007003': 'Бір',
  'UA4604007004': 'Бонишин',
  'UA4604007005': 'Бужок',
  'UA4604007066': 'Черемошня',
  'UA4604007065': 'Червоне',
  'UA4604007016': 'Дерев\'янки',
  'UA4604007009': 'Гавареччина',
  'UA4604007010': 'Гологірки',
  'UA4604007011': 'Гологори',
  'UA4604007012': 'Гончарівка',
  'UA4604007013': 'Городилів',
  'UA4604007014': 'Грабово',
  'UA4604007015': 'Гутище',
  'UA4604007024': 'Кам\'янисте',
  'UA4604007063': 'Хмелева',
  'UA4604007064': 'Хомець',
  'UA4604007062': 'Хильчиці',
  'UA4604007026': 'Княже',
  'UA4604007027': 'Кобилеччина',
  'UA4604007029': 'Колтів',
  'UA4604007030': 'Копані',
  'UA4604007028': 'Козаки',
  'UA4604007031': 'Кругів',
  'UA4604007025': 'Кийків',
  'UA4604007032': 'Лісові',
  'UA4604007033': 'Луг',
  'UA4604007034': 'Лука',
  'UA4604007035': 'Майдан-Гологірський',
  'UA4604007037': 'Монастирок',
  'UA4604007036': 'Митулин',
  'UA4604007038': 'Новоселище',
  'UA4604007039': 'Новосілки',
  'UA4604007040': 'Обертасів',
  'UA4604007041': 'Опаки',
  'UA4604007042': 'Осовиця',
  'UA4604007043': 'Папірня',
  'UA4604007044': 'Підгородне',
  'UA4604007045': 'Підлипці',
  'UA4604007046': 'Підлисся',
  'UA4604007047': 'Пісок',
  'UA4604007048': 'Плугів',
  'UA4604007049': 'Побіч',
  'UA4604007050': 'Почапи',
  'UA4604007051': 'Розваж',
  'UA4604007052': 'Руда',
  'UA4604007053': 'Руда-Колтівська',
  'UA4604007054': 'Сасів',
  'UA4604007055': 'Скварява',
  'UA4604007056': 'Стадня',
  'UA4604007057': 'Стінка',
  'UA4604007058': 'Струтин',
  'UA4604007059': 'Тростянець',
  'UA4604007060': 'Трудовач',
  'UA4604007061': 'Ушня',
  'UA4604007006': 'Велика Вільшаниця',
  'UA4604007007': 'Верхобуж',
  'UA4604007008': 'Вороняки',
  'UA4604007067': 'Ясенівці',
  'UA4604007017': 'Єлиховичі',
  'UA4604007019': 'Залісся',
  'UA4604007020': 'Зарваниця',
  'UA4604007021': 'Зашків',
  'UA4604007018': 'Жуличі',
  'UA4604007001': 'Золочів',
  'UA4604007023': 'Золочівка',
  'UA4604007022': 'Зозулі',
  'UA4802001026': 'Андріївка',
  'UA4802001001': 'Баштанка',
  'UA4802001022': 'Червоний Став',
  'UA4802001003': 'Добре',
  'UA4802001002': 'Горожене',
  'UA4802001021': 'Христофорівка',
  'UA4802001008': 'Костянтинівка',
  'UA4802001007': 'Київське',
  'UA4802001009': 'Новогеоргіївка',
  'UA4802001010': 'Новогорожене',
  'UA4802001011': 'Новоєгорівка',
  'UA4802001012': 'Новоіванівка',
  'UA4802001013': 'Новопавлівка',
  'UA4802001014': 'Новосергіївка',
  'UA4802001015': 'Одрадне',
  'UA4802001016': 'Піски',
  'UA4802001017': 'Плющівка',
  'UA4802001023': 'Шевченко',
  'UA4802001024': 'Шляхове',
  'UA4802001018': 'Старосолдатське',
  'UA4802001019': 'Тарасівка',
  'UA4802001020': 'Трудове',
  'UA4802001025': 'Явкине',
  'UA4802001004': 'Зелений Гай',
  'UA4802001005': 'Зелений Клин',
  'UA4802001006': 'Зелений Яр',
  'UA4802003001': 'Березнегувате',
  'UA4802003040': 'Березнегувате',
  'UA4802003002': 'Біла Криниця',
  'UA4802003038': 'Червонопілля',
  'UA4802003036': 'Червоний Став',
  'UA4802003037': 'Червоний Яр',
  'UA4802003041': 'Добре',
  'UA4802003035': 'Федорівка',
  'UA4802003009': 'Калачеве',
  'UA4802003011': 'Калуга',
  'UA4802003010': 'Калинівка',
  'UA4802003008': 'Кавказ',
  'UA4802003012': 'Лепетиха',
  'UA4802003013': 'Любомирівка',
  'UA4802003014': 'Маліївка',
  'UA4802003015': 'Маломихайлівське',
  'UA4802003016': 'Мурахівка',
  'UA4802003018': 'Новогригорівка',
  'UA4802003020': 'Новоочаків',
  'UA4802003019': 'Новоолександрівка',
  'UA4802003021': 'Новоросійське',
  'UA4802003023': 'Новосергіївка',
  'UA4802003022': 'Новосевастополь',
  'UA4802003024': 'Новоукраїнка',
  'UA4802003017': 'Нововолодимирівка',
  'UA4802003025': 'Одрадне',
  'UA4802003026': 'Озерівка',
  'UA4802003027': 'Петропавлівка',
  'UA4802003028': 'Пришиб',
  'UA4802003029': 'Романівка',
  'UA4802003030': 'Семенівка',
  'UA4802003031': 'Сергіївка',
  'UA4802003032': 'Соколівка',
  'UA4802003033': 'Тернівка',
  'UA4802003034': 'Тетянівка',
  'UA4802003003': 'Василівка',
  'UA4802003004': 'Велике Артакове',
  'UA4802003005': 'Веселе',
  'UA4802003006': 'Веселий Кут',
  'UA4802003007': 'Висунськ',
  'UA4802003039': 'Яковлівка',
  'UA4802009002': 'Баратівка',
  'UA4802009017': 'Галаганівка',
  'UA4802009001': 'Горохівське',
  'UA4802009005': 'Гречанівка',
  'UA4802009006': 'Гуляйгородок',
  'UA4802009007': 'Лиманці',
  'UA4802009008': 'Михайлівка',
  'UA4802009010': 'Новософіївка',
  'UA4802009011': 'Новотимофіївка',
  'UA4802009009': 'Новий Шлях',
  'UA4802009012': 'Олександрівка',
  'UA4802009013': 'Петропавлівське',
  'UA4802009014': 'Промінь',
  'UA4802009015': 'Романово-Булгакове',
  'UA4802009018': 'Садове',
  'UA4802009016': 'Суворе',
  'UA4802009019': 'Світла Дача',
  'UA4802009003': 'Великопілля',
  'UA4802009004': 'Виноградне',
  'UA4802011003': 'Добра Криниця',
  'UA4802011004': 'Доброкам\'янка',
  'UA4802011001': 'Інгулка',
  'UA4802011005': 'Костичі',
  'UA4802011006': 'Лобріївка',
  'UA4802011014': 'Лоцкине',
  'UA4802011008': 'Мар\'янівка',
  'UA4802011007': 'Мар\'ївка',
  'UA4802011009': 'Нове Життя',
  'UA4802011010': 'Новомар\'ївка',
  'UA4802011011': 'Новоолександрівка',
  'UA4802011015': 'Перемога',
  'UA4802011012': 'Родники',
  'UA4802011013': 'Степанівка',
  'UA4802011002': 'Виноградівка',
  'UA4802013002': 'Андріївка',
  'UA4802013003': 'Білівка',
  'UA4802013004': 'Бурячки',
  'UA4802013047': 'Червона Новоселівка',
  'UA4802013009': 'Дмитрівка',
  'UA4802013010': 'Дмитро-Білівка',
  'UA4802013050': 'Добровільське',
  'UA4802013049': 'Гранітне',
  'UA4802013011': 'Каширівка',
  'UA4802013001': 'Казанка',
  'UA4802013051': 'Казанка',
  'UA4802013052': 'Копані',
  'UA4802013012': 'Козлівка',
  'UA4802013013': 'Кротівка',
  'UA4802013014': 'Лагодівка',
  'UA4802013015': 'Лазарівка',
  'UA4802013017': 'Любомирівка',
  'UA4802013016': 'Лозове',
  'UA4802013018': 'Малофедорівка',
  'UA4802013019': 'Мар\'янівка',
  'UA4802013022': 'Михайлівка',
  'UA4802013020': 'Миколаївка',
  'UA4802013021': 'Миколо-Гулак',
  'UA4802013023': 'Неудачне',
  'UA4802013024': 'Нова Висунь',
  'UA4802013025': 'Нове',
  'UA4802013029': 'Новоданилівка',
  'UA4802013053': 'Новоданилівка',
  'UA4802013034': 'Новофедорівка',
  'UA4802013028': 'Новогригорівка',
  'UA4802013030': 'Новолазарівка',
  'UA4802013031': 'Новоолександрівка',
  'UA4802013032': 'Новоскелюватка',
  'UA4802013033': 'Новоукраїнка',
  'UA4802013026': 'Нововасилівка',
  'UA4802013027': 'Нововолодимирівка',
  'UA4802013035': 'Павлівка',
  'UA4802013036': 'Петрово-Висунське',
  'UA4802013038': 'Покровка',
  'UA4802013037': 'Пищевиця',
  'UA4802013039': 'Романівка',
  'UA4802013040': 'Сергіївка',
  'UA4802013041': 'Слобідка',
  'UA4802013042': 'Тарасівка',
  'UA4802013043': 'Тернове',
  'UA4802013045': 'Троянка',
  'UA4802013044': 'Троїцько-Сафонове',
  'UA4802013046': 'Утішне',
  'UA4802013054': 'Утішне',
  'UA4802013006': 'Великофедорівка',
  'UA4802013005': 'Великоолександрівка',
  'UA4802013007': 'Вербове',
  'UA4802013008': 'Весела Балка',
  'UA4802013048': 'Висунь',
  'UA4802015002': 'Анастасівка',
  'UA4802015004': 'Добра Воля',
  'UA4802015003': 'Григорівка',
  'UA4802015006': 'Новоантонівка',
  'UA4802015007': 'Новодмитрівка',
  'UA4802015008': 'Новомихайлівка',
  'UA4802015009': 'Новосілля',
  'UA4802015001': 'Новий Буг',
  'UA4802015010': 'Петрівка',
  'UA4802015011': 'Розанівка',
  'UA4802015012': 'Станційне',
  'UA4802015005': 'Загальна Користь',
  'UA4802017002': 'Архангельське',
  'UA4802017007': 'Кашперо-Миколаївка',
  'UA4802017006': 'Катеринівка',
  'UA4802017010': 'Любарка',
  'UA4802017009': 'Лук\'янівка',
  'UA4802017008': 'Лисянка',
  'UA4802017011': 'Михайлівка',
  'UA4802017012': 'Новобірзулівка',
  'UA4802017016': 'Новофонтанка',
  'UA4802017013': 'Новоолександрівка',
  'UA4802017014': 'Новопетрівка',
  'UA4802017015': 'Новоукраїнка',
  'UA4802017001': 'Привільне',
  'UA4802017019': 'Старогорожене',
  'UA4802017017': 'Світлицьке',
  'UA4802017018': 'Свобода',
  'UA4802017003': 'Вільна Поляна',
  'UA4802017004': 'Єрмолівка',
  'UA4802017005': 'Зелений Гай',
  'UA4802023005': 'Червона Долина',
  'UA4802023002': 'Любине',
  'UA4802023003': 'Новопетрівка',
  'UA4802023004': 'Покровське',
  'UA4802023006': 'Поляна',
  'UA4802023001': 'Широке',
  'UA4802023007': 'Степове',
  'UA4802019002': 'Афанасіївка',
  'UA4802019003': 'Безіменне',
  'UA4802019004': 'Бурханівка',
  'UA4802019006': 'Галаганівка',
  'UA4802019009': 'Івано-Кепине',
  'UA4802019010': 'Калинівка',
  'UA4802019011': 'Кобзарці',
  'UA4802019012': 'Любимівка',
  'UA4802019014': 'Новокондакове',
  'UA4802019015': 'Новопавлівське',
  'UA4802019013': 'Нововасилівка',
  'UA4802019016': 'Павлівка',
  'UA4802019017': 'Павло-Мар\'янівка',
  'UA4802019018': 'Першотравневе',
  'UA4802019001': 'Снігурівка',
  'UA4802019019': 'Тамарине',
  'UA4802019020': 'Трудолюбівка',
  'UA4802019005': 'Василівка',
  'UA4802019022': 'Васильки',
  'UA4802019023': 'Ясна Поляна',
  'UA4802019008': 'Єлизаветівка',
  'UA4802019007': 'Євгенівка',
  'UA4802019021': 'Юр\'ївка',
  'UA4802021002': 'Антонівка',
  'UA4802021003': 'Баратівка',
  'UA4802021004': 'Березнегуватське',
  'UA4802021010': 'Ганнівка',
  'UA4802021011': 'Кам\'яне',
  'UA4802021012': 'Майорівка',
  'UA4802021013': 'Майське',
  'UA4802021014': 'Мала Ганнівка',
  'UA4802021015': 'Миролюбівка',
  'UA4802021016': 'Новогригорівка',
  'UA4802021017': 'Новомиколаївка',
  'UA4802021018': 'Новопетрівка',
  'UA4802021019': 'Новорозанівка',
  'UA4802021020': 'Павлівка',
  'UA4802021021': 'Пелагіївка',
  'UA4802021022': 'Привільнянське',
  'UA4802021024': 'Щасливе',
  'UA4802021001': 'Софіївка',
  'UA4802021023': 'Улянівка',
  'UA4802021005': 'Веселий Поділ',
  'UA4802021006': 'Веселий Поділ',
  'UA4802021007': 'Вівсянівка',
  'UA4802021008': 'Володимирівка',
  'UA4802021009': 'Володимиро-Павлівка',
  'UA4802005002': 'Білоцерківка',
  'UA4802005003': 'Богомази',
  'UA4802005020': 'Чернігівка',
  'UA4802005004': 'Діброва',
  'UA4802005006': 'Лоцкине',
  'UA4802005007': 'Максимівка',
  'UA4802005009': 'Новоданилівка',
  'UA4802005014': 'Новоюр\'ївка',
  'UA4802005013': 'Новохристофорівка',
  'UA4802005010': 'Новокостянтинівка',
  'UA4802005011': 'Новополтавка',
  'UA4802005012': 'Новоселівка',
  'UA4802005008': 'Нововасилівка',
  'UA4802005015': 'Пархомівка',
  'UA4802005016': 'Показне',
  'UA4802005022': 'Полтавка',
  'UA4802005021': 'Шевченкове',
  'UA4802005018': 'Степове',
  'UA4802005017': 'Симонівка',
  'UA4802005019': 'Трояни',
  'UA4802005001': 'Вільне Запоріжжя',
  'UA4802005005': 'Єфремівка',
  'UA4802007017': 'Лісове',
  'UA4802007006': 'Матроно-Василівка',
  'UA4802007007': 'Новоблакитне',
  'UA4802007009': 'Новочудневе',
  'UA4802007008': 'Новосілля',
  'UA4802007010': 'Олександрівка',
  'UA4802007011': 'Панасівка',
  'UA4802007012': 'Привілля',
  'UA4802007013': 'Ропове',
  'UA4802007016': 'Шевченкове',
  'UA4802007014': 'Скобелеве',
  'UA4802007015': 'Суходілля',
  'UA4802007002': 'Веселий Кут',
  'UA4802007004': 'Воля',
  'UA4802007003': 'Волна',
  'UA4802007001': 'Володимирівка',
  'UA4802007005': 'Зоря',
  'UA4806001002': 'Андрієво-Зорине',
  'UA4806001003': 'Андріївка',
  'UA4806001001': 'Березанка',
  'UA4806001004': 'Богданівка',
  'UA4806001029': 'Червоний Поділ',
  'UA4806001008': 'Дмитрівка',
  'UA4806001033': 'Елеваторне',
  'UA4806001034': 'Елеваторне',
  'UA4806001007': 'Ганнівка',
  'UA4806001011': 'Калабатине',
  'UA4806001012': 'Калинівка',
  'UA4806001013': 'Комісарівка',
  'UA4806001014': 'Красне',
  'UA4806001015': 'Краснопілля',
  'UA4806001035': 'Крутоярка',
  'UA4806001018': 'Люблине',
  'UA4806001017': 'Лиманське',
  'UA4806001016': 'Лимани',
  'UA4806001019': 'Малахове',
  'UA4806001020': 'Марківка',
  'UA4806001021': 'Матіясове',
  'UA4806001022': 'Михайлівка',
  'UA4806001023': 'Новоселівка',
  'UA4806001024': 'Олександрівка',
  'UA4806001025': 'Попільне',
  'UA4806001026': 'Прогресівка',
  'UA4806001031': 'Щасливе',
  'UA4806001030': 'Шмідтівка',
  'UA4806001027': 'Суходіл',
  'UA4806001028': 'Ташине',
  'UA4806001005': 'Василівка',
  'UA4806001006': 'Вікторівка',
  'UA4806001032': 'Яблуня',
  'UA4806001009': 'Єлизаветівка',
  'UA4806001010': 'Журівка',
  'UA4806035002': 'Баланове',
  'UA4806035003': 'Березань',
  'UA4806035004': 'Благодатне',
  'UA4806035001': 'Чорноморка',
  'UA4806035007': 'Кам\'янка',
  'UA4806035008': 'Лиманне',
  'UA4806035009': 'Нове',
  'UA4806035010': 'Осетрівка',
  'UA4806035011': 'Рівне',
  'UA4806035013': 'Шевченко',
  'UA4806035012': 'Сонячне',
  'UA4806035005': 'Володимирівка',
  'UA4806035006': 'Їжицьке',
  'UA4806007001': 'Галицинове',
  'UA4806007003': 'Лупареве',
  'UA4806007002': 'Лимани',
  'UA4806007004': 'Прибузьке',
  'UA4806007006': 'Степова Долина',
  'UA4806007005': 'Українка',
  'UA4806009002': 'Анатолівка',
  'UA4806009003': 'Бессарабка',
  'UA4806009012': 'Федорівка',
  'UA4806009005': 'Глибоке',
  'UA4806009001': 'Коблеве',
  'UA4806009006': 'Лугове',
  'UA4806009007': 'Морське',
  'UA4806009008': 'Новофедорівка',
  'UA4806009009': 'Рибаківка',
  'UA4806009010': 'Тузли',
  'UA4806009011': 'Українка',
  'UA4806009004': 'Виноградне',
  'UA4806011002': 'Баловне',
  'UA4806011003': 'Гур\'ївка',
  'UA4806011005': 'Кандибине',
  'UA4806011001': 'Костянтинівка',
  'UA4806011006': 'Новоінгулка',
  'UA4806011007': 'Новоматвіївське',
  'UA4806011008': 'Новопетрівське',
  'UA4806011009': 'Піски',
  'UA4806011010': 'Себине',
  'UA4806011011': 'Сільвестрівське',
  'UA4806011004': 'Зайве',
  'UA4806013011': 'Червоне Парутине',
  'UA4806013002': 'Дмитрівка',
  'UA4806013003': 'Дніпровське',
  'UA4806013004': 'Іванівка',
  'UA4806013005': 'Каталине',
  'UA4806013001': 'Куцуруб',
  'UA4806013006': 'Матросівка',
  'UA4806013007': 'Острівка',
  'UA4806013008': 'Парутине',
  'UA4806013009': 'Прибузьке',
  'UA4806013010': 'Солончаки',
  'UA4806013012': 'Яселка',
  'UA4806017002': 'Добра Надія',
  'UA4806017005': 'Капустине',
  'UA4806017006': 'Каравелове',
  'UA4806017001': 'Мішково-Погорілове',
  'UA4806017007': 'Святомиколаївка',
  'UA4806017003': 'Ясна Поляна',
  'UA4806017004': 'Зайчівське',
  'UA4806015001': 'Миколаїв',
  'UA4806019009': 'Благодарівка',
  'UA4806019013': 'Чемерлієве',
  'UA4806019002': 'Данилівка',
  'UA4806019003': 'Іванівка',
  'UA4806019004': 'Лук\'янівка',
  'UA4806019010': 'Маловарварівка',
  'UA4806019005': 'Мефодіївка',
  'UA4806019011': 'Мирне',
  'UA4806019001': 'Нечаяне',
  'UA4806019006': 'Новоподілля',
  'UA4806019007': 'П\'ятихатки',
  'UA4806019008': 'Сеньчине',
  'UA4806019012': 'Тронка',
  'UA4806021016': 'Дільниче',
  'UA4806021003': 'Димівське',
  'UA4806021002': 'Гребеники',
  'UA4806021005': 'Кам\'янка',
  'UA4806021006': 'Криворіжжя',
  'UA4806021007': 'Михайлівка',
  'UA4806021001': 'Нова Одеса',
  'UA4806021008': 'Новомиколаївка',
  'UA4806021009': 'Новоолександрівське',
  'UA4806021010': 'Новопавлівка',
  'UA4806021011': 'Новосафронівка',
  'UA4806021012': 'Озерне',
  'UA4806021013': 'Підлісне',
  'UA4806021014': 'Троїцьке',
  'UA4806021015': 'Ясна Поляна',
  'UA4806021004': 'Зарічне',
  'UA4806025001': 'Очаків',
  'UA4806025003': 'Покровка',
  'UA4806025004': 'Покровське',
  'UA4806025002': 'Василівка',
  'UA4806023002': 'Андріївка',
  'UA4806023004': 'Корчине',
  'UA4806023003': 'Ковалівка',
  'UA4806023005': 'Новоандріївка',
  'UA4806023006': 'Новоюр\'ївка',
  'UA4806023001': 'Ольшанське',
  'UA4806023007': 'Сапетня',
  'UA4806023011': 'Шостакове',
  'UA4806023008': 'Суха Балка',
  'UA4806023009': 'Тернувате',
  'UA4806023010': 'Трихати',
  'UA4806023012': 'Ясна Поляна',
  'UA4806023013': 'Ясна Зоря',
  'UA4806027002': 'Білозірка',
  'UA4806027003': 'Благодатне',
  'UA4806027005': 'Костянтинівка',
  'UA4806027011': 'Квітневе',
  'UA4806027004': 'Киселівка',
  'UA4806027006': 'Максимівка',
  'UA4806027007': 'Новомиколаївка',
  'UA4806027008': 'Новоселівка',
  'UA4806027009': 'Партизанське',
  'UA4806027001': 'Первомайське',
  'UA4806027010': 'Засілля',
  'UA4806029003': 'Козирка',
  'UA4806029004': 'Михайлівка',
  'UA4806029005': 'Новобогданівка',
  'UA4806029001': 'Радісний Сад',
  'UA4806029006': 'Стара Богданівка',
  'UA4806029002': 'Зарічне',
  'UA4806037002': 'Богородицьке',
  'UA4806037006': 'Котляреве',
  'UA4806037018': 'Любомирівка',
  'UA4806037017': 'Луч',
  'UA4806037007': 'Миколаївське',
  'UA4806037008': 'Мирне',
  'UA4806037009': 'Новогригорівка',
  'UA4806037010': 'Новокиївка',
  'UA4806037011': 'Новоруське',
  'UA4806037012': 'Оленівка',
  'UA4806037019': 'Полігон',
  'UA4806037001': 'Шевченкове',
  'UA4806037013': 'Шевченкове',
  'UA4806037014': 'Шмідтове',
  'UA4806037020': 'Тернові Поди',
  'UA4806037021': 'Центральне',
  'UA4806037003': 'Вавилове',
  'UA4806037015': 'Водник',
  'UA4806037004': 'Зелений Гай',
  'UA4806037016': 'Знам\'янка',
  'UA4806037005': 'Зоря',
  'UA4806031015': 'Червоне Поле',
  'UA4806031003': 'Гамове',
  'UA4806031005': 'Іванівка',
  'UA4806031006': 'Катеринівка',
  'UA4806031007': 'Кринички',
  'UA4806031008': 'Кубряки',
  'UA4806031009': 'Михайлівка',
  'UA4806031010': 'Новогригорівка',
  'UA4806031011': 'Новокатеринівка',
  'UA4806031012': 'Новоселівка',
  'UA4806031013': 'Піщаний Брід',
  'UA4806031016': 'Широколанівка',
  'UA4806031001': 'Степове',
  'UA4806031014': 'Трихатське',
  'UA4806031002': 'Весняна Квітка',
  'UA4806031004': 'Зелений Гай',
  'UA4806033002': 'Антонівка',
  'UA4806033003': 'Бузьке',
  'UA4806033015': 'Червоноволодимирівка',
  'UA4806033006': 'Карлівка',
  'UA4806033007': 'Київське',
  'UA4806033008': 'Новошмідтівка',
  'UA4806033009': 'Олександрівка',
  'UA4806033010': 'Остапівка',
  'UA4806033011': 'Островське',
  'UA4806033012': 'Степове',
  'UA4806033001': 'Сухий Єланець',
  'UA4806033013': 'Суворовка',
  'UA4806033014': 'Улянівка',
  'UA4806033004': 'Веселе',
  'UA4806033005': 'Воронцівка',
  'UA4806003002': 'Безводне',
  'UA4806003017': 'Чумаки',
  'UA4806003004': 'Кам\'яна Балка',
  'UA4806003005': 'Карликівка',
  'UA4806003007': 'Крива Балка',
  'UA4806003006': 'Кир\'яківка',
  'UA4806003016': 'Надбузьке',
  'UA4806003008': 'Новомихайлівка',
  'UA4806003009': 'Петрівка',
  'UA4806003010': 'Петрово-Солониха',
  'UA4806003011': 'Подимове',
  'UA4806003012': 'Половинки',
  'UA4806003014': 'Шурине',
  'UA4806003013': 'Сливине',
  'UA4806003001': 'Весняне',
  'UA4806003003': 'Зелене',
  'UA4806003015': 'Зелений Яр',
  'UA4806005007': 'Червоне',
  'UA4806005008': 'Горохівка',
  'UA4806005009': 'Грейгове',
  'UA4806005003': 'Калинівка',
  'UA4806005004': 'Михайло-Ларине',
  'UA4806005005': 'Пересадівка',
  'UA4806005006': 'Степове',
  'UA4806005002': 'Водокачка',
  'UA4806005001': 'Воскресенське',
  'UA4808001002': 'Агрономія',
  'UA4808001001': 'Арбузинка',
  'UA4808001012': 'Кавуни',
  'UA4808001005': 'Колос Добра',
  'UA4808001013': 'Костянтинівка',
  'UA4808001006': 'Мар\'янівка',
  'UA4808001008': 'Новокрасне',
  'UA4808001009': 'Новоселівка',
  'UA4808001007': 'Новий Ставок',
  'UA4808001010': 'Полянка',
  'UA4808001011': 'Шкуратове',
  'UA4808001004': 'Воля',
  'UA4808001003': 'Вишневе',
  'UA4808003001': 'Благодатне',
  'UA4808003002': 'Булацелове',
  'UA4808003006': 'Любоіванівка',
  'UA4808003007': 'Новогригорівка',
  'UA4808003008': 'Новомихайлівка',
  'UA4808003009': 'Остапівка',
  'UA4808003010': 'Рябоконеве',
  'UA4808003011': 'Садове',
  'UA4808003012': 'Семенівка',
  'UA4808003004': 'Воєводське',
  'UA4808003003': 'Виноградний Яр',
  'UA4808003005': 'Зелена Поляна',
  'UA4808007001': 'Кам\'яний Міст',
  'UA4808007004': 'Кам\'яний Міст',
  'UA4808007005': 'Катеринка',
  'UA4808007006': 'Коломіївка',
  'UA4808007007': 'Кримка',
  'UA4808007008': 'Кумарі',
  'UA4808007009': 'Новоандріївка',
  'UA4808007010': 'Петрівка',
  'UA4808007011': 'Полтавка',
  'UA4808007015': 'Садибне',
  'UA4808007012': 'Старі Кошари',
  'UA4808007013': 'Степківка',
  'UA4808007014': 'Степове',
  'UA4808007002': 'Зелені Кошари',
  'UA4808007003': 'Зоряне',
  'UA4808009002': 'Багачівка',
  'UA4808009003': 'Берізки',
  'UA4808009004': 'Бурилове',
  'UA4808009006': 'Гойдаї',
  'UA4808009007': 'Голоскове',
  'UA4808009024': 'Іванівка',
  'UA4808009025': 'Климівка',
  'UA4808009008': 'Красненьке',
  'UA4808009009': 'Криве Озеро Друге',
  'UA4808009001': 'Криве Озеро',
  'UA4808009026': 'Купріянівка',
  'UA4808009010': 'Курячі Лози',
  'UA4808009011': 'Луканівка',
  'UA4808009013': 'Мала Багачівка',
  'UA4808009014': 'Мала Мечетня',
  'UA4808009012': 'Мазурове',
  'UA4808009016': 'Михалкове',
  'UA4808009015': 'Миколаївка',
  'UA4808009018': 'Очеретня',
  'UA4808009017': 'Ониськове',
  'UA4808009019': 'Секретарка',
  'UA4808009027': 'Семенівка',
  'UA4808009020': 'Сорочинка',
  'UA4808009021': 'Тернувате',
  'UA4808009022': 'Токарівка',
  'UA4808009023': 'Тридуби',
  'UA4808009005': 'Велика Мечетня',
  'UA4808011012': 'Бандурка',
  'UA4808011002': 'Богословка',
  'UA4808011003': 'Гаївське',
  'UA4808011004': 'Генівка',
  'UA4808011013': 'Іванівка',
  'UA4808011005': 'Куріпчине',
  'UA4808011007': 'Львів',
  'UA4808011006': 'Лиса Гора',
  'UA4808011001': 'Мигія',
  'UA4808011008': 'Новопавлівка',
  'UA4808011009': 'Романова Балка',
  'UA4808011011': 'Софіївка',
  'UA4808011010': 'Соколівка',
  'UA4808013007': 'Чаусове Друге',
  'UA4808013004': 'Грушівка',
  'UA4808013005': 'Кам\'яна Балка',
  'UA4808013006': 'Кінецьпіль',
  'UA4808013001': 'Первомайськ',
  'UA4808013002': 'Підгородна',
  'UA4808013003': 'Вербова Балка',
  'UA4808015002': 'Бандурка',
  'UA4808015003': 'Болеславчик',
  'UA4808015004': 'Брід',
  'UA4808015014': 'Чаусове',
  'UA4808015005': 'Довга Пристань',
  'UA4808015007': 'Лозуватка',
  'UA4808015008': 'Лукашівка',
  'UA4808015009': 'Мічуріне',
  'UA4808015017': 'Новоолександрівка',
  'UA4808015010': 'Підгір\'я',
  'UA4808015015': 'Шевченко',
  'UA4808015012': 'Станіславчик',
  'UA4808015011': 'Світоч',
  'UA4808015001': 'Синюхин Брід',
  'UA4808015013': 'Тарасівка',
  'UA4808015016': 'Єрмолаївка',
  'UA4808015006': 'Зелена Левада',
  'UA4808005002': 'Адамівка',
  'UA4808005003': 'Біляєве',
  'UA4808005004': 'Бобрик',
  'UA4808005005': 'Богемка',
  'UA4808005006': 'Болгарка',
  'UA4808005010': 'Доброжанівка',
  'UA4808005034': 'Федорівка',
  'UA4808005035': 'Филимонівка',
  'UA4808005009': 'Гражданівка',
  'UA4808005013': 'Іванівка',
  'UA4808005015': 'Капітанка',
  'UA4808005016': 'Ковалівка',
  'UA4808005017': 'Краснівка',
  'UA4808005018': 'Краснопіль',
  'UA4808005019': 'Кумарі',
  'UA4808005020': 'Макієве',
  'UA4808005021': 'Мала Врадіївка',
  'UA4808005022': 'Мар\'янівка',
  'UA4808005023': 'Михайлівка',
  'UA4808005025': 'Новогригорівка',
  'UA4808005027': 'Новомихайлівське',
  'UA4808005026': 'Новомиколаївське',
  'UA4808005028': 'Новоолексіївка',
  'UA4808005029': 'Новопавлівка',
  'UA4808005024': 'Нововасилівка',
  'UA4808005030': 'Острогірське',
  'UA4808005031': 'Покровське',
  'UA4808005036': 'Шевченко',
  'UA4808005032': 'Сирове',
  'UA4808005033': 'Тарасівка',
  'UA4808005007': 'Великовеселе',
  'UA4808005008': 'Веселий Лан',
  'UA4808005001': 'Врадіївка',
  'UA4808005014': 'Йосипівка',
  'UA4808005037': 'Юр\'ївка',
  'UA4808005011': 'Захарівка',
  'UA4808005012': 'Зимницьке',
  'UA4804001003': 'Антонопіль',
  'UA4804001002': 'Антонове',
  'UA4804001001': 'Братське',
  'UA4804001009': 'Іванівка',
  'UA4804001010': 'Кам\'януватка',
  'UA4804001011': 'Красноярка',
  'UA4804001012': 'Крива Пустош',
  'UA4804001013': 'Кудрявське',
  'UA4804001014': 'Макарове',
  'UA4804001018': 'Михайлівка',
  'UA4804001015': 'Микільське',
  'UA4804001016': 'Миколаївка',
  'UA4804001017': 'Мирне',
  'UA4804001019': 'Надеждівка',
  'UA4804001020': 'Новокостянтинівка',
  'UA4804001021': 'Новоолександрівка',
  'UA4804001022': 'Новоолексіївка',
  'UA4804001023': 'Новопетрівка',
  'UA4804001024': 'Озеринівка',
  'UA4804001025': 'Павлодарівка',
  'UA4804001026': 'Петропавлівка',
  'UA4804001027': 'Сергіївка',
  'UA4804001028': 'Сергіївське',
  'UA4804001033': 'Шевченко',
  'UA4804001029': 'Соколівка',
  'UA4804001030': 'Трудолюбівка',
  'UA4804001031': 'Українець',
  'UA4804001032': 'Улянівка',
  'UA4804001004': 'Веселий Роздол',
  'UA4804001006': 'Вікторівка',
  'UA4804001005': 'Висока Гора',
  'UA4804001034': 'Ясна Поляна',
  'UA4804001007': 'Єгорівка',
  'UA4804001008': 'Зелений Яр',
  'UA4804003001': 'Бузьке',
  'UA4804003003': 'Григорівське',
  'UA4804003004': 'Малосолоне',
  'UA4804003005': 'Рацинська Дача',
  'UA4804003006': 'Солдатське',
  'UA4804003007': 'Степове',
  'UA4804003008': 'Таборівка',
  'UA4804003002': 'Василівка',
  'UA4804003009': 'Вознесенське',
  'UA4804009002': 'Антонівка',
  'UA4804009003': 'Богданівське',
  'UA4804009028': 'Чорталка',
  'UA4804009001': 'Доманівка',
  'UA4804009008': 'Довжанка',
  'UA4804009009': 'Довженки',
  'UA4804009014': 'Казаринське',
  'UA4804009015': 'Копані',
  'UA4804009016': 'Копи',
  'UA4804009017': 'Коштове',
  'UA4804009018': 'Кузнецове',
  'UA4804009019': 'Маринівка',
  'UA4804009020': 'Новолікарське',
  'UA4804009021': 'Новоселівка',
  'UA4804009022': 'Олександрівка',
  'UA4804009023': 'Олександродар',
  'UA4804009024': 'Петропавлівка',
  'UA4804009031': 'Щасливка',
  'UA4804009029': 'Шевченко',
  'UA4804009030': 'Широкі Криниці',
  'UA4804009025': 'Сила',
  'UA4804009026': 'Трудолюбівка',
  'UA4804009027': 'Царедарівка',
  'UA4804009004': 'Вікторівка',
  'UA4804009007': 'Воля',
  'UA4804009005': 'Володимирівка',
  'UA4804009006': 'Володимирівка',
  'UA4804009010': 'Забари',
  'UA4804009011': 'Зброшкове',
  'UA4804009012': 'Зелений Гай',
  'UA4804009013': 'Зелений Яр',
  'UA4804011002': 'Білоусівка',
  'UA4804011001': 'Дорошівка',
  'UA4804011003': 'Новоукраїнка',
  'UA4804011007': 'Щербані',
  'UA4804011008': 'Щербанівське',
  'UA4804011006': 'Шевченко',
  'UA4804011004': 'Троїцьке',
  'UA4804011005': 'Трудове',
  'UA4804015017': 'Чернігівка',
  'UA4804015016': 'Червона Поляна',
  'UA4804015005': 'Дворянка',
  'UA4804015003': 'Горянка',
  'UA4804015004': 'Грибоносове',
  'UA4804015006': 'Іванівка',
  'UA4804015007': 'Івано-Федорівка',
  'UA4804015008': 'Ізбашівка',
  'UA4804015009': 'Козубівка',
  'UA4804015010': 'Лідіївка',
  'UA4804015001': 'Мостове',
  'UA4804015011': 'Миколаївка',
  'UA4804015012': 'Новокантакузівка',
  'UA4804015013': 'Олександрівка',
  'UA4804015014': 'Першотравнівка',
  'UA4804015018': 'Шевченко',
  'UA4804015015': 'Суха Балка',
  'UA4804015002': 'Веселе',
  'UA4804017002': 'Антонівка',
  'UA4804017006': 'Дарниця',
  'UA4804017004': 'Ганнівка',
  'UA4804017005': 'Григорівка',
  'UA4804017008': 'Кам\'яно-Костувате',
  'UA4804017009': 'Кам\'янопіль',
  'UA4804017010': 'Костувате',
  'UA4804017011': 'Лісове',
  'UA4804017026': 'Людмилівка',
  'UA4804017014': 'Мостове',
  'UA4804017013': 'Михайло-Жукове',
  'UA4804017012': 'Миролюбівка',
  'UA4804017015': 'Новофедорівка',
  'UA4804017001': 'Новомар\'ївка',
  'UA4804017016': 'Обухівка',
  'UA4804017017': 'Орлове Поле',
  'UA4804017018': 'Петрівка Друга',
  'UA4804017019': 'Петрівка Перша',
  'UA4804017021': 'П\'ятихатки',
  'UA4804017020': 'Прищепівка',
  'UA4804017022': 'Степове',
  'UA4804017024': 'Цибульки',
  'UA4804017023': 'Тимофіївка',
  'UA4804017003': 'Воронине',
  'UA4804017007': 'Єлизаветівка',
  'UA4804017025': 'Юр\'ївка',
  'UA4804019002': 'Актове',
  'UA4804019001': 'Олександрівка',
  'UA4804019008': 'Трикратне',
  'UA4804019007': 'Трикрати',
  'UA4804019003': 'Веселий Роздол',
  'UA4804019004': 'Вільний Яр',
  'UA4804019005': 'Воронівка',
  'UA4804019006': 'Зоря',
  'UA4804021002': 'Андрійчикове',
  'UA4804021003': 'Бакай',
  'UA4804021007': 'Дмитрівка',
  'UA4804021006': 'Глюгове',
  'UA4804021008': 'Кам\'яна Балка',
  'UA4804021009': 'Криворучка',
  'UA4804021010': 'Манне',
  'UA4804021011': 'Мартинівське',
  'UA4804021018': 'Мартинівське',
  'UA4804021012': 'Нове',
  'UA4804021013': 'Новобілоусівка',
  'UA4804021014': 'Новопристань',
  'UA4804021019': 'Новосілка',
  'UA4804021015': 'Очаківське',
  'UA4804021001': 'Прибужани',
  'UA4804021016': 'Рюмівське',
  'UA4804021020': 'Тімірязєвка',
  'UA4804021004': 'Вільне',
  'UA4804021005': 'Вокзал',
  'UA4804021017': 'Яструбинове',
  'UA4804023002': 'Акмечетські Ставки',
  'UA4804023003': 'Анетівка',
  'UA4804023004': 'Богданівка',
  'UA4804023005': 'Бузькі Пороги',
  'UA4804023007': 'Калинівка',
  'UA4804023008': 'Київ',
  'UA4804023009': 'Мар\'ївка',
  'UA4804023010': 'Петрівка',
  'UA4804023001': 'Прибужжя',
  'UA4804023011': 'Птиче',
  'UA4804023013': 'Щуцьке',
  'UA4804023012': 'Цвіткове',
  'UA4804023006': 'Виноградний Сад',
  'UA4804005003': 'Бондарівка',
  'UA4804005004': 'Бузоварове',
  'UA4804005043': 'Федорівка',
  'UA4804005008': 'Градівка',
  'UA4804005009': 'Григорівка',
  'UA4804005013': 'Іванівка',
  'UA4804005014': 'Калинівка',
  'UA4804005016': 'Колосівка',
  'UA4804005017': 'Кременівка',
  'UA4804005018': 'Кутузівка',
  'UA4804005015': 'Києво-Олександрівське',
  'UA4804005019': 'Луб\'янка',
  'UA4804005021': 'Михайлівка',
  'UA4804005020': 'Миколаївка',
  'UA4804005025': 'Новогригорівка',
  'UA4804005026': 'Новомиколаївка',
  'UA4804005027': 'Новопавлівка',
  'UA4804005029': 'Новосілка',
  'UA4804005028': 'Новосвітлівка',
  'UA4804005024': 'Нововоскресенка',
  'UA4804005022': 'Новий Городок',
  'UA4804005023': 'Новий Степ',
  'UA4804005030': 'Первенець',
  'UA4804005031': 'Петрівка',
  'UA4804005032': 'Поділля',
  'UA4804005033': 'Подолянка',
  'UA4804005034': 'Покровка',
  'UA4804005035': 'Поріччя',
  'UA4804005036': 'Райдолина',
  'UA4804005044': 'Штукар',
  'UA4804005038': 'Староолексіївка',
  'UA4804005037': 'Ставки',
  'UA4804005039': 'Степанівка',
  'UA4804005040': 'Суха Балка',
  'UA4804005002': 'Токарівка',
  'UA4804005041': 'Улянове',
  'UA4804005042': 'Урсолівка',
  'UA4804005005': 'Варюшине',
  'UA4804005001': 'Веселинове',
  'UA4804005007': 'Воронівка',
  'UA4804005006': 'Виноградівка',
  'UA4804005011': 'Зелене',
  'UA4804005012': 'Зоря',
  'UA4804005010': 'Звенигородка',
  'UA4804007002': 'Новогригорівка',
  'UA4804007003': 'Ракове',
  'UA4804007001': 'Вознесенськ',
  'UA4804013002': 'Богодарівка',
  'UA4804013003': 'Братолюбівка',
  'UA4804013004': 'Бугорок',
  'UA4804013014': 'Дружелюбівка',
  'UA4804013033': 'Федоро-Михайлівка',
  'UA4804013012': 'Гражданка',
  'UA4804013013': 'Григорівка',
  'UA4804013015': 'Іванівка',
  'UA4804013016': 'Калинівка',
  'UA4804013017': 'Кам\'янка',
  'UA4804013018': 'Ковалівка',
  'UA4804013019': 'Крутоярка',
  'UA4804013020': 'Малодворянка',
  'UA4804013022': 'Малоукраїнка',
  'UA4804013021': 'Маложенівка',
  'UA4804013023': 'Михайлівка',
  'UA4804013027': 'Новоявленка',
  'UA4804013026': 'Новоолександрівка',
  'UA4804013024': 'Нововасилівка',
  'UA4804013025': 'Нововолодимирівка',
  'UA4804013028': 'Ольгопіль',
  'UA4804013029': 'Півні',
  'UA4804013030': 'Приют',
  'UA4804013031': 'Семенівка',
  'UA4804013032': 'Уральське',
  'UA4804013007': 'Велідарівка',
  'UA4804013005': 'Велика Солона',
  'UA4804013006': 'Великосербулівка',
  'UA4804013008': 'Веселий Поділ',
  'UA4804013009': 'Водяне',
  'UA4804013010': 'Водяно-Лорине',
  'UA4804013011': 'Возсіятське',
  'UA4804013034': 'Ясногородка',
  'UA4804013001': 'Єланець',
  'UA4804025003': 'Бузьке',
  'UA4804025004': 'Іванівка',
  'UA4804025002': 'Костянтинівка',
  'UA4804025005': 'Панкратове',
  'UA4804025001': 'Южноукраїнськ',
  'UA5102001001': 'Андрієво-Іванівка',
  'UA5102001002': 'Богданівка',
  'UA5102001004': 'Добриднівка',
  'UA5102001005': 'Ісаєве',
  'UA5102001006': 'Каховка',
  'UA5102001007': 'Левадівка',
  'UA5102001008': 'Настасіївка',
  'UA5102001009': 'Новогригорівка',
  'UA5102001010': 'Новотроїцьке',
  'UA5102001011': 'Піщана Петрівка',
  'UA5102001012': 'Скосарівка',
  'UA5102001003': 'Веселе',
  'UA5102003001': 'Березівка',
  'UA5102003024': 'Червоне',
  'UA5102003025': 'Чорногірка',
  'UA5102003005': 'Демидове',
  'UA5102003006': 'Донська Балка',
  'UA5102003007': 'Донське',
  'UA5102003004': 'Гуляївка',
  'UA5102003009': 'Карнагорове',
  'UA5102003010': 'Косівка',
  'UA5102003011': 'Котовка',
  'UA5102003012': 'Кудрявка',
  'UA5102003013': 'Ланове',
  'UA5102003014': 'Мар\'янівка',
  'UA5102003015': 'Михайлівка',
  'UA5102003016': 'Михайло-Олександрівка',
  'UA5102003017': 'Новогригорівка',
  'UA5102003018': 'Одрадна Балка',
  'UA5102003019': 'Роздол',
  'UA5102003020': 'Сахарове',
  'UA5102003021': 'Софіївка',
  'UA5102003022': 'Степанівка',
  'UA5102003023': 'Танівка',
  'UA5102003002': 'Веселинівка',
  'UA5102003003': 'Вікторівка',
  'UA5102003027': 'Яснопілля',
  'UA5102003026': 'Юркове',
  'UA5102003008': 'Златоустове',
  'UA5102029002': 'Бранкованове',
  'UA5102029001': 'Чогодарівка',
  'UA5102029005': 'Качулове',
  'UA5102029006': 'Копійкове',
  'UA5102029007': 'Малігонове',
  'UA5102029008': 'Машенька',
  'UA5102029009': 'Самійлівка',
  'UA5102029003': 'Валентинівка',
  'UA5102029004': 'Володимирівка',
  'UA5102009002': 'Адамівка',
  'UA5102009003': 'Баланини',
  'UA5102009004': 'Баранове',
  'UA5102009005': 'Білка',
  'UA5102009006': 'Блонське',
  'UA5102009007': 'Бузинове',
  'UA5102009018': 'Черняхівське',
  'UA5102009001': 'Іванівка',
  'UA5102009010': 'Малинівка',
  'UA5102009011': 'Маслове',
  'UA5102009012': 'Нижній Куяльник',
  'UA5102009014': 'Прохорове',
  'UA5102009013': 'Причепівка',
  'UA5102009015': 'Руська Слобідка',
  'UA5102009016': 'Северинівка',
  'UA5102009019': 'Шеметове',
  'UA5102009017': 'Сухомлинове',
  'UA5102009008': 'Верхній Куяльник',
  'UA5102009009': 'Жовте',
  'UA5102011002': 'Богунове',
  'UA5102011006': 'Джугастрове',
  'UA5102011004': 'Ганно-Покровка',
  'UA5102011005': 'Гудевичеве',
  'UA5102011007': 'Калинівка',
  'UA5102011008': 'Катерино-Платонівка',
  'UA5102011001': 'Конопляне',
  'UA5102011009': 'Козлове',
  'UA5102011010': 'Крижанівка',
  'UA5102011011': 'Люботаївка',
  'UA5102011012': 'Маркевичеве',
  'UA5102011013': 'Марціянове',
  'UA5102011014': 'Михайлопіль',
  'UA5102011015': 'Новакове',
  'UA5102011016': 'Новоукраїнка',
  'UA5102011020': 'Шерове',
  'UA5102011018': 'Соколове',
  'UA5102011017': 'Силівка',
  'UA5102011019': 'Тарасівка',
  'UA5102011003': 'Вовкове',
  'UA5102013003': 'Каїри',
  'UA5102013004': 'Капітанівка',
  'UA5102013001': 'Курісове',
  'UA5102013005': 'Нове Селище',
  'UA5102013006': 'Новомиколаївка',
  'UA5102013007': 'Олександрівка',
  'UA5102013008': 'Сербка',
  'UA5102013002': 'Вишневе',
  'UA5102015002': 'Амбарів',
  'UA5102015003': 'Антонюки',
  'UA5102015022': 'Чарівне',
  'UA5102015006': 'Дружелюбівка',
  'UA5102015005': 'Гвоздівка',
  'UA5102015007': 'Каре',
  'UA5102015008': 'Комарашеве',
  'UA5102015009': 'Мала Дворянка',
  'UA5102015010': 'Мар\'янівка',
  'UA5102015011': 'Морозова',
  'UA5102015001': 'Миколаївка',
  'UA5102015012': 'Новопетрівка',
  'UA5102015013': 'Новопокровка',
  'UA5102015014': 'Олексіївка',
  'UA5102015015': 'Перемога',
  'UA5102015016': 'Переселенці',
  'UA5102015017': 'Романівка',
  'UA5102015023': 'Широке',
  'UA5102015018': 'Софіївка',
  'UA5102015019': 'Ставкове',
  'UA5102015020': 'Сухий Овраг',
  'UA5102015021': 'Ульяновка',
  'UA5102015004': 'Василівка',
  'UA5102017004': 'Іванівка',
  'UA5102017001': 'Новокальчеве',
  'UA5102017005': 'Основа',
  'UA5102017006': 'Петрівка',
  'UA5102017007': 'Ряснопіль',
  'UA5102017008': 'Садове',
  'UA5102017009': 'Семихатки',
  'UA5102017010': 'Сухине',
  'UA5102017011': 'Травневе',
  'UA5102017012': 'Улянівка',
  'UA5102017002': 'Виноградне',
  'UA5102017003': 'Зеленопілля',
  'UA5102019002': 'Армашівка',
  'UA5102019003': 'Бердинове',
  'UA5102019005': 'Костянтинів',
  'UA5102019006': 'Крижанівка',
  'UA5102019007': 'Новосвітівка',
  'UA5102019001': 'Петровірівка',
  'UA5102019008': 'Польове',
  'UA5102019009': 'Ревова',
  'UA5102019010': 'Розкішне',
  'UA5102019004': 'Жуковське',
  'UA5102021002': 'Балайчук',
  'UA5102021009': 'Чигирин',
  'UA5102021010': 'Чижове',
  'UA5102021006': 'Маринове',
  'UA5102021007': 'Новоподільське',
  'UA5102021008': 'Новоселівка',
  'UA5102021001': 'Раухівка',
  'UA5102021011': 'Шевченкове',
  'UA5102021003': 'Виноград',
  'UA5102021004': 'Заводівка',
  'UA5102021005': 'Зброжківка',
  'UA5102023002': 'Анатолівка',
  'UA5102023003': 'Антонівка',
  'UA5102023013': 'Чудське',
  'UA5102023006': 'Данилівка',
  'UA5102023007': 'Красне',
  'UA5102023008': 'Кринички',
  'UA5102023009': 'Нейкове',
  'UA5102023010': 'Онорівка',
  'UA5102023011': 'Рівне',
  'UA5102023001': 'Розквіт',
  'UA5102023014': 'Шутове',
  'UA5102023012': 'Ставкове',
  'UA5102023004': 'Веселе',
  'UA5102023005': 'Вишневе',
  'UA5102031004': 'Макарове',
  'UA5102031005': 'Мар\'янівка',
  'UA5102031006': 'Новогуляївка',
  'UA5102031007': 'Одаї',
  'UA5102031008': 'Олександро-Вовкове',
  'UA5102031009': 'Осинівка',
  'UA5102031010': 'Подільці',
  'UA5102031001': 'Ширяєве',
  'UA5102031011': 'Суха Журівка',
  'UA5102031002': 'Виноградівка',
  'UA5102031012': 'Яринославка',
  'UA5102031003': 'Йосипівка',
  'UA5102025002': 'Буци',
  'UA5102025017': 'Чорний Кут',
  'UA5102025004': 'Докторове',
  'UA5102025007': 'Лідівка',
  'UA5102025008': 'Мар\'ївка',
  'UA5102025009': 'Миколаївка',
  'UA5102025010': 'Нові Маяки',
  'UA5102025011': 'Новоандріївка',
  'UA5102025012': 'Новопавлівка',
  'UA5102025013': 'Преображенка',
  'UA5102025014': 'Січневе',
  'UA5102025015': 'Скосарівське',
  'UA5102025001': 'Старі Маяки',
  'UA5102025016': 'Тимофіївка',
  'UA5102025003': 'Вікторівка',
  'UA5102025018': 'Якимів Яр',
  'UA5102025005': 'Зірка',
  'UA5102025006': 'Золочівське',
  'UA5102027002': 'Бірюкове',
  'UA5102027005': 'Мартинівка',
  'UA5102027006': 'Нова Григорівка',
  'UA5102027007': 'Петрівка',
  'UA5102027010': 'Шабельники',
  'UA5102027011': 'Шевченка',
  'UA5102027001': 'Стрюкове',
  'UA5102027008': 'Сиротинка',
  'UA5102027009': 'Успенка',
  'UA5102027003': 'Жукове',
  'UA5102027004': 'Журівка',
  'UA5102005002': 'Петрівка',
  'UA5102005003': 'Улянівка',
  'UA5102005001': 'Великий Буялик',
  'UA5102007006': 'Малозименове',
  'UA5102007007': 'Марціянове',
  'UA5102007008': 'Новоєлизаветівка',
  'UA5102007009': 'Новопетрівка',
  'UA5102007010': 'Новостепанівка',
  'UA5102007011': 'Поліно-Осипенкове',
  'UA5102007002': 'Радісне',
  'UA5102007014': 'Софіївка',
  'UA5102007015': 'Стара Єлизаветівка',
  'UA5102007012': 'Сирітське Друге',
  'UA5102007013': 'Сирітське Перше',
  'UA5102007016': 'Цибулівка',
  'UA5102007003': 'Великозименове',
  'UA5102007004': 'Воробіївка',
  'UA5102007005': 'Воробйове',
  'UA5102007017': 'Юрашеве',
  'UA5102007001': 'Знам\'янка',
  'UA5104001001': 'Білгород-Дністровський',
  'UA5104003002': 'Балабанка',
  'UA5104003001': 'Дивізія',
  'UA5104003005': 'Кочкувате',
  'UA5104003006': 'Лиман',
  'UA5104003007': 'Маразліївка',
  'UA5104003008': 'Нове',
  'UA5104003010': 'Ройлянка',
  'UA5104003009': 'Рибальське',
  'UA5104003011': 'Царичанка',
  'UA5104003003': 'Вишневе',
  'UA5104003004': 'Жовтий Яр',
  'UA5104005001': 'Кароліно-Бугаз',
  'UA5104005002': 'Затока',
  'UA5104007002': 'Костянтинівка',
  'UA5104007001': 'Кулевча',
  'UA5104007003': 'Розівка',
  'UA5104007004': 'Сергіївка',
  'UA5104009001': 'Лиман',
  'UA5104009003': 'Новоселиця',
  'UA5104009004': 'Приморське',
  'UA5104009005': 'Трапівка',
  'UA5104009006': 'Трихатки',
  'UA5104009002': 'Зарічне',
  'UA5104011003': 'Долинівка',
  'UA5104011001': 'Маразліївка',
  'UA5104011005': 'Монаші',
  'UA5104011006': 'Олексіївка',
  'UA5104011007': 'Полянка',
  'UA5104011008': 'Романівка',
  'UA5104011009': 'Широке',
  'UA5104011002': 'Великомар\'янівка',
  'UA5104011004': 'Зелене',
  'UA5104013002': 'Андріївка',
  'UA5104013003': 'Бикоза',
  'UA5104013001': 'Молога',
  'UA5104013005': 'Нове',
  'UA5104013006': 'Розкішне',
  'UA5104013007': 'Садове',
  'UA5104013008': 'Сухолужжя',
  'UA5104013004': 'Випасне',
  'UA5104015007': 'Фараонівка',
  'UA5104015008': 'Фуратівка',
  'UA5104015002': 'Міняйлівка',
  'UA5104015003': 'Олександрівка',
  'UA5104015001': 'Петропавлівка',
  'UA5104015004': 'Пшеничне',
  'UA5104015005': 'Семисотка',
  'UA5104015006': 'Старосілля',
  'UA5104017002': 'Благодатне',
  'UA5104017004': 'Молдове',
  'UA5104017005': 'Надежда',
  'UA5104017006': 'Негрове',
  'UA5104017008': 'Пасічне',
  'UA5104017001': 'Плахтіївка',
  'UA5104017003': 'Великорибальське',
  'UA5104017007': 'Ярославка',
  'UA5104019003': 'Долинка',
  'UA5104019005': 'Кам\'янка',
  'UA5104019007': 'Михайлівка',
  'UA5104019006': 'Мирнопілля',
  'UA5104019008': 'Нова Іванівка',
  'UA5104019009': 'Нова Плахтіївка',
  'UA5104019010': 'Новоселівка',
  'UA5104019001': 'Сарата',
  'UA5104019011': 'Світлодолинське',
  'UA5104019002': 'Введенка',
  'UA5104019004': 'Зоря',
  'UA5104021008': 'Чабанське',
  'UA5104021003': 'Косівка',
  'UA5104021004': 'Курортне',
  'UA5104021005': 'Миколаївка',
  'UA5104021006': 'Попаздра',
  'UA5104021007': 'Приморське',
  'UA5104021001': 'Сергіївка',
  'UA5104021002': 'Вільне',
  'UA5104031002': 'Абрикосове',
  'UA5104031004': 'Адамівка',
  'UA5104031003': 'Авидівка',
  'UA5104031005': 'Біленьке',
  'UA5104031006': 'Благодатне',
  'UA5104031007': 'Бритівка',
  'UA5104031013': 'Черкеси',
  'UA5104031009': 'Польове',
  'UA5104031014': 'Прибережне',
  'UA5104031010': 'Привітне',
  'UA5104031011': 'Салгани',
  'UA5104031001': 'Шабо',
  'UA5104031012': 'Софіївка',
  'UA5104031008': 'Вигон',
  'UA5104023018': 'Чистоводне',
  'UA5104023005': 'Дальнічень',
  'UA5104023004': 'Гончарівка',
  'UA5104023007': 'Карналіївка',
  'UA5104023008': 'Козацьке',
  'UA5104023009': 'Красна Коса',
  'UA5104023010': 'Крутоярівка',
  'UA5104023011': 'Нова Царичанка',
  'UA5104023012': 'Петрівка',
  'UA5104023014': 'Підгірне',
  'UA5104023013': 'Південне',
  'UA5104023015': 'Руськоіванівка',
  'UA5104023016': 'Семенівка',
  'UA5104023017': 'Стара Царичанка',
  'UA5104023001': 'Старокозаче',
  'UA5104023002': 'Веселе',
  'UA5104023003': 'Володимирівка',
  'UA5104023006': 'Зеленівка',
  'UA5104025002': 'Баштанівка',
  'UA5104025003': 'Білолісся',
  'UA5104025004': 'Борисівка',
  'UA5104025006': 'Дельжилер',
  'UA5104025005': 'Глибоке',
  'UA5104025007': 'Нерушай',
  'UA5104025008': 'Нова Олексіївка',
  'UA5104025009': 'Спаське',
  'UA5104025010': 'Струмок',
  'UA5104025001': 'Татарбунари',
  'UA5104027002': 'Базар\'янка',
  'UA5104027003': 'Безім\'янка',
  'UA5104027006': 'Лебедівка',
  'UA5104027007': 'Новомихайлівка',
  'UA5104027008': 'Садове',
  'UA5104027001': 'Тузли',
  'UA5104027004': 'Весела Балка',
  'UA5104027005': 'Веселе',
  'UA5104029003': 'Комишівка Перша',
  'UA5104029004': 'Крива Балка',
  'UA5104029005': 'Лугове',
  'UA5104029006': 'Миколаївка-Новоросійська',
  'UA5104029007': 'Над\'ярне',
  'UA5104029008': 'Ройлянка',
  'UA5104029001': 'Успенівка',
  'UA5104029002': 'Забари',
  'UA5106001001': 'Арциз',
  'UA5106001004': 'Делень',
  'UA5106001003': 'Главані',
  'UA5106001006': 'Кам\'янське',
  'UA5106001012': 'Холмське',
  'UA5106001007': 'Надеждівка',
  'UA5106001008': 'Нова Іванівка',
  'UA5106001013': 'Новохолмське',
  'UA5106001009': 'Новоселівка',
  'UA5106001010': 'Плоцьк',
  'UA5106001011': 'Прямобалка',
  'UA5106001002': 'Виноградівка',
  'UA5106001005': 'Задунаївка',
  'UA5106003001': 'Болград',
  'UA5106003005': 'Оксамитне',
  'UA5106003006': 'Табаки',
  'UA5106003007': 'Тополине',
  'UA5106003003': 'Владичень',
  'UA5106003002': 'Виноградівка',
  'UA5106003004': 'Залізничне',
  'UA5106005002': 'Благодатне',
  'UA5106005003': 'Богданівка',
  'UA5106005001': 'Бородіно',
  'UA5106005004': 'Булатівка',
  'UA5106005033': 'Червоне',
  'UA5106005009': 'Ганнівка',
  'UA5106005010': 'Ганнівка',
  'UA5106005014': 'Іванчанка',
  'UA5106005015': 'Кролівка',
  'UA5106005016': 'Ламбрівка',
  'UA5106005017': 'Лісне',
  'UA5106005018': 'Матильдівка',
  'UA5106005019': 'Миколаївка',
  'UA5106005020': 'Надрічне',
  'UA5106005021': 'Нове Тарутине',
  'UA5106005022': 'Новоселівка',
  'UA5106005023': 'Новосілка',
  'UA5106005024': 'Новоукраїнка',
  'UA5106005025': 'Олексіївка',
  'UA5106005026': 'Перемога',
  'UA5106005027': 'Петрівка',
  'UA5106005028': 'Підгірне',
  'UA5106005029': 'Плачинда',
  'UA5106005030': 'Рівне',
  'UA5106005031': 'Роза',
  'UA5106005032': 'Скриванівка',
  'UA5106005005': 'Весела Долина',
  'UA5106005008': 'Володимирівка',
  'UA5106005007': 'Вознесенка Друга',
  'UA5106005006': 'Височанське',
  'UA5106005012': 'Єлизаветівка',
  'UA5106005013': 'Єлизаветівка',
  'UA5106005011': 'Євгенівка',
  'UA5106005034': 'Юр\'ївка',
  'UA5106009002': 'Дмитрівка',
  'UA5106009001': 'Городнє',
  'UA5106009003': 'Нові Трояни',
  'UA5106009004': 'Олександрівка',
  'UA5106011002': 'Коса',
  'UA5106011001': 'Криничне',
  'UA5106011003': 'Новоозерне',
  'UA5106013001': 'Кубей',
  'UA5106013003': 'Оріхівка',
  'UA5106013002': 'Виноградне',
  'UA5106015005': 'Долинівка',
  'UA5106015007': 'Нові Каплани',
  'UA5106015008': 'Новомирне',
  'UA5106015001': 'Павлівка',
  'UA5106015002': 'Василівка',
  'UA5106015004': 'Вознесенка Перша',
  'UA5106015003': 'Вишняки',
  'UA5106015006': 'Зелена Балка',
  'UA5106017002': 'Березине',
  'UA5106017006': 'Калачівка',
  'UA5106017007': 'Красне',
  'UA5106017008': 'Лужанка',
  'UA5106017009': 'Малоярославець Другий',
  'UA5106017010': 'Малоярославець Перший',
  'UA5106017011': 'Петрівськ',
  'UA5106017012': 'Підгірне',
  'UA5106017013': 'Рівне',
  'UA5106017003': 'Серпневе',
  'UA5106017014': 'Слобідка',
  'UA5106017015': 'Сухувате',
  'UA5106017001': 'Тарутине',
  'UA5106017005': 'Вільне',
  'UA5106017004': 'Виноградівка',
  'UA5106017016': 'Ярове',
  'UA5106019003': 'Мирнопілля',
  'UA5106019004': 'Роща',
  'UA5106019005': 'Садове',
  'UA5106019001': 'Теплиця',
  'UA5106019002': 'Веселий Кут',
  'UA5106007002': 'Баннівка',
  'UA5106007003': 'Голиця',
  'UA5106007004': 'Калчева',
  'UA5106007005': 'Каракурт',
  'UA5106007006': 'Новий Каракурт',
  'UA5106007001': 'Василівка',
  'UA5108003001': 'Ізмаїл',
  'UA5108005010': 'Червоний Яр',
  'UA5108005003': 'Дмитрівка',
  'UA5108005009': 'Фурманівка',
  'UA5108005001': 'Кілія',
  'UA5108005004': 'Ліски',
  'UA5108005005': 'Миколаївка',
  'UA5108005006': 'Новоселівка',
  'UA5108005007': 'Помазани',
  'UA5108005011': 'Шевченкове',
  'UA5108005008': 'Трудове',
  'UA5108005002': 'Василівка',
  'UA5108007002': 'Долинське',
  'UA5108007003': 'Котловина',
  'UA5108007004': 'Лиманське',
  'UA5108007005': 'Нагірне',
  'UA5108007006': 'Новосільське',
  'UA5108007007': 'Орлівка',
  'UA5108007008': 'Плавні',
  'UA5108007001': 'Рені',
  'UA5108009002': 'Багате',
  'UA5108009003': 'Броска',
  'UA5108009004': 'Дунайське',
  'UA5108009005': 'Каланчак',
  'UA5108009006': 'Кам\'янка',
  'UA5108009008': 'Комишівка',
  'UA5108009007': 'Кислиця',
  'UA5108009009': 'Ларжанка',
  'UA5108009010': 'Лощинівка',
  'UA5108009011': 'Матроска',
  'UA5108009012': 'Муравлівка',
  'UA5108009013': 'Нова Некрасівка',
  'UA5108009014': 'Новокаланчак',
  'UA5108009015': 'Новокам\'янка',
  'UA5108009016': 'Озерне',
  'UA5108009017': 'Першотравневе',
  'UA5108009001': 'Саф\'яни',
  'UA5108009018': 'Стара Некрасівка',
  'UA5108009019': 'Утконосівка',
  'UA5108011007': 'Дзинілор',
  'UA5108011002': 'Кирнички',
  'UA5108011003': 'Нова Покровка',
  'UA5108011004': 'Острівне',
  'UA5108011005': 'Приозерне',
  'UA5108011006': 'Старі Трояни',
  'UA5108011001': 'Суворове',
  'UA5108001006': 'Біле',
  'UA5108001002': 'Десантне',
  'UA5108001003': 'Мирне',
  'UA5108001004': 'Новомиколаївка',
  'UA5108001005': 'Приморське',
  'UA5108001001': 'Вилкове',
  'UA5110001001': 'Авангард',
  'UA5110001002': 'Хлібодарське',
  'UA5110001003': 'Нова Долина',
  'UA5110001004': 'Прилиманське',
  'UA5110001005': 'Радісне',
  'UA5110003001': 'Біляївка',
  'UA5110003002': 'Градениці',
  'UA5110003003': 'Кагарлик',
  'UA5110003004': 'Майори',
  'UA5110003005': 'Мирне',
  'UA5110003006': 'Повстанське',
  'UA5110003007': 'Широка Балка',
  'UA5110037003': 'Бурлача Балка',
  'UA5110037001': 'Чорноморськ',
  'UA5110037004': 'Малодолинське',
  'UA5110037002': 'Олександрівка',
  'UA5110039001': 'Чорноморське',
  'UA5110039002': 'Гвардійське',
  'UA5110015002': 'Болгарка',
  'UA5110015001': 'Дачне',
  'UA5110015007': 'Хоминка',
  'UA5110015005': 'Мале',
  'UA5110015006': 'Одрадове',
  'UA5110015008': 'Світлогірське',
  'UA5110015003': 'Єгорівка',
  'UA5110015004': 'Єлизаветівка',
  'UA5110013002': 'Барабой',
  'UA5110013009': 'Богатирівка',
  'UA5110013001': 'Дальник',
  'UA5110013004': 'Доброолександрівка',
  'UA5110013003': 'Грибівка',
  'UA5110013005': 'Мар\'янівка',
  'UA5110013006': 'Новоградківка',
  'UA5110013007': 'Роксолани',
  'UA5110013008': 'Санжійка',
  'UA5110017002': 'Благодатне',
  'UA5110017001': 'Доброслав',
  'UA5110017006': 'Касяни',
  'UA5110017014': 'Христо-Ботеве',
  'UA5110017007': 'Кремидівка',
  'UA5110017008': 'Нове',
  'UA5110017009': 'Ониськове',
  'UA5110017015': 'Шевченкове-Кут',
  'UA5110017011': 'Старі Шомполи',
  'UA5110017010': 'Ставки',
  'UA5110017017': 'Степове',
  'UA5110017012': 'Трояндове',
  'UA5110017013': 'Улянівка',
  'UA5110017003': 'Великі Ламзаки',
  'UA5110017004': 'Вовківське',
  'UA5110017016': 'Якова',
  'UA5110017005': 'Зоринове',
  'UA5110035001': 'Фонтанка',
  'UA5110035003': 'Крижанівка',
  'UA5110035006': 'Ліски',
  'UA5110035004': 'Нова Дофінівка',
  'UA5110035005': 'Олександрівка',
  'UA5110035007': 'Світле',
  'UA5110035002': 'Вапнярка',
  'UA5110019002': 'Букачі',
  'UA5110019005': 'Ілічанка',
  'UA5110019004': 'Іванове',
  'UA5110019006': 'Корсунці',
  'UA5110019001': 'Красносілка',
  'UA5110019007': 'Кубанка',
  'UA5110019008': 'Лізинка',
  'UA5110019009': 'Нові Шомполи',
  'UA5110019010': 'Новокубанка',
  'UA5110019011': 'Павлинка',
  'UA5110019012': 'Переможне',
  'UA5110019014': 'Шаманівка',
  'UA5110019013': 'Созонівка',
  'UA5110019003': 'Василівка',
  'UA5110021003': 'Лібенталь',
  'UA5110021001': 'Маяки',
  'UA5110021004': 'Надлиманське',
  'UA5110021005': 'Удобне',
  'UA5110021002': 'Йосипівка',
  'UA5110023002': 'Алтестове',
  'UA5110023004': 'Холодна Балка',
  'UA5110023001': 'Нерубайське',
  'UA5110023005': 'Усатове',
  'UA5110023003': 'Велика Балка',
  'UA5110027001': 'Одеса',
  'UA5110025002': 'Калаглія',
  'UA5110025003': 'Миколаївка',
  'UA5110025001': 'Овідіополь',
  'UA5110029002': 'Балка',
  'UA5110029003': 'Лиманка',
  'UA5110029004': 'Сухий Лиман',
  'UA5110029001': 'Таїрове',
  'UA5110031001': 'Теплодар',
  'UA5110033002': 'Августівка',
  'UA5110033003': 'Берегове',
  'UA5110033012': 'Черевичне',
  'UA5110033013': 'Чоботарівка',
  'UA5110033004': 'Іллінка',
  'UA5110033005': 'Ковалівка',
  'UA5110033006': 'Латівка',
  'UA5110033007': 'Маринівка',
  'UA5110033014': 'Набережне',
  'UA5110033008': 'Нова Еметівка',
  'UA5110033015': 'Нова Ковалівка',
  'UA5110033009': 'Протопопівка',
  'UA5110033010': 'Стара Еметівка',
  'UA5110033011': 'Тихе',
  'UA5110033001': 'Усатове',
  'UA5110005002': 'Петродолинське',
  'UA5110005003': 'Розселенець',
  'UA5110005001': 'Великий Дальник',
  'UA5110007002': 'Молодіжне',
  'UA5110007001': 'Великодолинське',
  'UA5110009002': 'Березань',
  'UA5110009014': 'Червона Гірка',
  'UA5110009005': 'Доброжанове',
  'UA5110009015': 'Дослідне',
  'UA5110009007': 'Кам\'янка',
  'UA5110009008': 'Курган',
  'UA5110009009': 'Михайлівка',
  'UA5110009010': 'Паліївка',
  'UA5110009011': 'Петрове',
  'UA5110009012': 'Секретарівка',
  'UA5110009013': 'Сонячне',
  'UA5110009004': 'Василівка',
  'UA5110009003': 'Важне',
  'UA5110009001': 'Вигода',
  'UA5110009006': 'Зоряне',
  'UA5110011002': 'Бутівка',
  'UA5110011004': 'Дмитрівка',
  'UA5110011006': 'Калинівка',
  'UA5110011007': 'Кінне',
  'UA5110011008': 'Кордон',
  'UA5110011009': 'Любопіль',
  'UA5110011010': 'Мар\'янівка',
  'UA5110011011': 'Міщанка',
  'UA5110011012': 'Нова Вільшанка',
  'UA5110011013': 'Першотравневе',
  'UA5110011014': 'Порт',
  'UA5110011015': 'Пшонянове',
  'UA5110011016': 'Ранжеве',
  'UA5110011019': 'Широке',
  'UA5110011017': 'Степанівка',
  'UA5110011018': 'Тилігульське',
  'UA5110011003': 'Воронівка',
  'UA5110011001': 'Визирка',
  'UA5110011005': 'Зоря Труда',
  'UA5110043002': 'Троїцьке',
  'UA5110043001': 'Яськи',
  'UA5110041003': 'Білярі',
  'UA5110041004': 'Булдинка',
  'UA5110041005': 'Григорівка',
  'UA5110041006': 'Кошари',
  'UA5110041002': 'Нові Білярі',
  'UA5110041007': 'Сичавка',
  'UA5110041001': 'Южне',
  'UA5112001002': 'Амури',
  'UA5112001001': 'Ананьїв',
  'UA5112001003': 'Ананьїв',
  'UA5112001004': 'Байтали',
  'UA5112001005': 'Благодатне',
  'UA5112001007': 'Боярка',
  'UA5112001006': 'Бондарі',
  'UA5112001011': 'Дружелюбівка',
  'UA5112001010': 'Гандрабури',
  'UA5112001013': 'Калини',
  'UA5112001015': 'Коханівка',
  'UA5112001016': 'Кохівка',
  'UA5112001014': 'Козаче',
  'UA5112001017': 'Михайлівка',
  'UA5112001019': 'Новодачне',
  'UA5112001018': 'Новогеоргіївка',
  'UA5112001020': 'Новоіванівка',
  'UA5112001021': 'Новоолександрівка',
  'UA5112001022': 'Новоселівка',
  'UA5112001023': 'Пасицели',
  'UA5112001024': 'Романівка',
  'UA5112001025': 'Селиванівка',
  'UA5112001029': 'Шелехове',
  'UA5112001028': 'Шевченкове',
  'UA5112001030': 'Шимкове',
  'UA5112001026': 'Струтинка',
  'UA5112001027': 'Точилове',
  'UA5112001008': 'Великобоярка',
  'UA5112001009': 'Вербове',
  'UA5112001012': 'Жеребкове',
  'UA5112003002': 'Акулинівка',
  'UA5112003003': 'Андріяшівка',
  'UA5112003001': 'Балта',
  'UA5112003004': 'Бендзари',
  'UA5112003005': 'Березівка',
  'UA5112003006': 'Білине',
  'UA5112003007': 'Борсуки',
  'UA5112003034': 'Чернече',
  'UA5112003010': 'Гольма',
  'UA5112003013': 'Кармалюківка',
  'UA5112003033': 'Харитинівка',
  'UA5112003015': 'Коритне',
  'UA5112003014': 'Козацьке',
  'UA5112003016': 'Крижовлин',
  'UA5112003017': 'Лісничівка',
  'UA5112003019': 'Мошняги',
  'UA5112003018': 'Мирони',
  'UA5112003020': 'Немирівське',
  'UA5112003021': 'Новополь',
  'UA5112003022': 'Обжиле',
  'UA5112003023': 'Оленівка',
  'UA5112003024': 'Пасат',
  'UA5112003025': 'Пасицели',
  'UA5112003026': 'Перейма',
  'UA5112003027': 'Перельоти',
  'UA5112003028': 'Петрівка',
  'UA5112003029': 'Плоске',
  'UA5112003030': 'Саражинка',
  'UA5112003031': 'Семено-Карпівка',
  'UA5112003032': 'Сінне',
  'UA5112003008': 'Відрада',
  'UA5112003009': 'Волова',
  'UA5112003011': 'Євтодія',
  'UA5112003012': 'Зелений Гай',
  'UA5112005001': 'Долинське',
  'UA5112005008': 'Флоринське',
  'UA5112005002': 'Гребенюки',
  'UA5112005003': 'Григорівка',
  'UA5112005004': 'Канцурове',
  'UA5112005005': 'Красне',
  'UA5112005006': 'Олександрівка',
  'UA5112005007': 'Трудолюбівка',
  'UA5112009002': 'Баштанків',
  'UA5112009003': 'Будеї',
  'UA5112009020': 'Федорівка',
  'UA5112009004': 'Грабове',
  'UA5112009006': 'Івашків',
  'UA5112009001': 'Кодима',
  'UA5112009007': 'Круті',
  'UA5112009008': 'Лабушне',
  'UA5112009009': 'Лисогірка',
  'UA5112009010': 'Олександрівка',
  'UA5112009011': 'Олексіївка',
  'UA5112009012': 'Петрівка',
  'UA5112009013': 'Пиріжна',
  'UA5112009014': 'Писарівка',
  'UA5112009015': 'Семенівка',
  'UA5112009016': 'Серби',
  'UA5112009017': 'Сергіївка',
  'UA5112009021': 'Шершенці',
  'UA5112009018': 'Смолянка',
  'UA5112009019': 'Стримба',
  'UA5112009005': 'Загнітків',
  'UA5112011002': 'Андріївка',
  'UA5112011004': 'Бочманівка',
  'UA5112011003': 'Борщі',
  'UA5112011057': 'Борщі',
  'UA5112011058': 'Чубівка',
  'UA5112011017': 'Дібрівка',
  'UA5112011018': 'Домниця',
  'UA5112011055': 'Федорівка',
  'UA5112011010': 'Гертопи',
  'UA5112011012': 'Глибочок',
  'UA5112011013': 'Гонората',
  'UA5112011014': 'Гор\'ївка',
  'UA5112011015': 'Грекове Друге',
  'UA5112011016': 'Грекове Перше',
  'UA5112011011': 'Гидерим',
  'UA5112011022': 'Качурівка',
  'UA5112011024': 'Климентове',
  'UA5112011025': 'Коси',
  'UA5112011026': 'Коси-Слобідка',
  'UA5112011001': 'Куяльник',
  'UA5112011023': 'Кирилівка',
  'UA5112011027': 'Любомирка',
  'UA5112011028': 'Мала Кіндратівка',
  'UA5112011029': 'Мала Олександрівка',
  'UA5112011030': 'Мала Петрівка',
  'UA5112011032': 'Малий Фонтан',
  'UA5112011031': 'Малий Куяльник',
  'UA5112011033': 'Мардарівка',
  'UA5112011036': 'Мурована',
  'UA5112011035': 'Миколаївка Перша',
  'UA5112011034': 'Миколаївка',
  'UA5112011037': 'Нестоїта',
  'UA5112011038': 'Нова Кульна',
  'UA5112011040': 'Новоселівка',
  'UA5112011039': 'Новий Мир',
  'UA5112011041': 'Оброчне',
  'UA5112011042': 'Олексіївка',
  'UA5112011043': 'Падрецеве',
  'UA5112011044': 'Перешори',
  'UA5112011045': 'Петрівка',
  'UA5112011046': 'Поплавка',
  'UA5112011048': 'Романівка',
  'UA5112011047': 'Розалівка',
  'UA5112011049': 'Соболівка',
  'UA5112011051': 'Станіславка',
  'UA5112011052': 'Стара Кульна',
  'UA5112011050': 'Ставки',
  'UA5112011053': 'Степанівка',
  'UA5112011054': 'Топик',
  'UA5112011005': 'Велика Кіндратівка',
  'UA5112011006': 'Велике Бурилове',
  'UA5112011007': 'Великий Фонтан',
  'UA5112011008': 'Вестерничани',
  'UA5112011009': 'Вишневе',
  'UA5112011056': 'Ясинове',
  'UA5112011019': 'Єфросинівка',
  'UA5112011020': 'Затишшя',
  'UA5112011021': 'Зелений Кут',
  'UA5112013004': 'Адамівка',
  'UA5112013002': 'Агафіївка',
  'UA5112013003': 'Агеївка',
  'UA5112013005': 'Антонівка',
  'UA5112013006': 'Арчепитівка',
  'UA5112013007': 'Бобрик Другий',
  'UA5112013008': 'Бобрик Перший',
  'UA5112013009': 'Бобрицьке',
  'UA5112013010': 'Бокове',
  'UA5112013037': 'Чайківка',
  'UA5112013038': 'Чайківське',
  'UA5112013039': 'Червоний Яр',
  'UA5112013014': 'Демидівка',
  'UA5112013015': 'Дмитрівське',
  'UA5112013017': 'Іванівка',
  'UA5112013018': 'Катеринівка Перша',
  'UA5112013020': 'Комарівка',
  'UA5112013019': 'Козачий Яр',
  'UA5112013021': 'Кричунове',
  'UA5112013001': 'Любашівка',
  'UA5112013022': 'Мала Василівка',
  'UA5112013023': 'Михайлівка',
  'UA5112013025': 'Новокарбівка',
  'UA5112013026': 'Новоолександрівка',
  'UA5112013027': 'Новоселівка',
  'UA5112013028': 'Новотроїцьке',
  'UA5112013024': 'Нововоздвиженка',
  'UA5112013029': 'Олександрівка',
  'UA5112013030': 'Петрівка',
  'UA5112013032': 'Покровка',
  'UA5112013031': 'Пилипівка',
  'UA5112013033': 'Сергіївка',
  'UA5112013040': 'Шайтанка',
  'UA5112013041': 'Шкарбинка',
  'UA5112013035': 'Степанівка',
  'UA5112013034': 'Сирівське',
  'UA5112013036': 'Троїцьке',
  'UA5112013011': 'Велика Василівка',
  'UA5112013012': 'Велике Бокове',
  'UA5112013013': 'Вишневе',
  'UA5112013043': 'Яновка',
  'UA5112013042': 'Янишівка',
  'UA5112013016': 'Заплази',
  'UA5112015002': 'Антонівка',
  'UA5112015003': 'Артирівка',
  'UA5112015004': 'Будаївці',
  'UA5112015053': 'Чорна',
  'UA5112015013': 'Дем\'янівка',
  'UA5112015014': 'Дігори',
  'UA5112015015': 'Дністровець',
  'UA5112015016': 'Довжанка',
  'UA5112015017': 'Дубове',
  'UA5112015048': 'Федорівка',
  'UA5112015049': 'Федорівка',
  'UA5112015050': 'Федосіївка',
  'UA5112015051': 'Флора',
  'UA5112015010': 'Галочі',
  'UA5112015009': 'Гавиноси',
  'UA5112015011': 'Горячівка',
  'UA5112015012': 'Гулянка',
  'UA5112015019': 'Ілля',
  'UA5112015018': 'Іванівка',
  'UA5112015020': 'Калістратівка',
  'UA5112015021': 'Левантівка',
  'UA5112015023': 'Маяки',
  'UA5112015022': 'Малаївці',
  'UA5112015024': 'Нагірне',
  'UA5112015025': 'Нестерове',
  'UA5112015026': 'Нова Волярка',
  'UA5112015027': 'Новокрасне',
  'UA5112015028': 'Новомихайлівка',
  'UA5112015029': 'Новорозівка',
  'UA5112015030': 'Новосамарка',
  'UA5112015031': 'Новосеменівка',
  'UA5112015054': 'Новий Орач',
  'UA5112015032': 'Одаї',
  'UA5112015001': 'Окни',
  'UA5112015033': 'Олександрівка',
  'UA5112015034': 'Омелянівка',
  'UA5112015055': 'Орлівка',
  'UA5112015035': 'Платонове',
  'UA5112015037': 'Розівка',
  'UA5112015036': 'Римарівка',
  'UA5112015039': 'Садове',
  'UA5112015038': 'Сагайдак',
  'UA5112015040': 'Самарка',
  'UA5112015041': 'Ставрове',
  'UA5112015042': 'Степанівка',
  'UA5112015043': 'Ткаченка',
  'UA5112015044': 'Топали',
  'UA5112015045': 'Тригради',
  'UA5112015052': 'Цеханівка',
  'UA5112015046': 'Улянівка',
  'UA5112015047': 'Унтилівка',
  'UA5112015005': 'Василівка',
  'UA5112015008': 'Волярка',
  'UA5112015007': 'Володимирівка',
  'UA5112015006': 'Вижине',
  'UA5112017002': 'Гербине',
  'UA5112017003': 'Кринички',
  'UA5112017001': 'Піщана',
  'UA5112017004': 'Пужайкове',
  'UA5112017005': 'Ракулове',
  'UA5112017006': 'Савранське',
  'UA5112017007': 'Шляхове',
  'UA5112017008': 'Шумилове',
  'UA5112019002': 'Казбеки',
  'UA5112019003': 'Липецьке',
  'UA5112019004': 'Олександрівка',
  'UA5112019001': 'Подільськ',
  'UA5112021002': 'Байбузівка',
  'UA5112021003': 'Бакша',
  'UA5112021004': 'Білоусівка',
  'UA5112021009': 'Дубки',
  'UA5112021008': 'Дубинове',
  'UA5112021006': 'Гетьманівка',
  'UA5112021007': 'Глибочок',
  'UA5112021011': 'Кам\'яне',
  'UA5112021012': 'Капустянка',
  'UA5112021014': 'Концеба',
  'UA5112021021': 'Ковбасова Поляна',
  'UA5112021013': 'Квітка',
  'UA5112021015': 'Неділкове',
  'UA5112021017': 'Острівка',
  'UA5112021016': 'Осички',
  'UA5112021018': 'Полянецьке',
  'UA5112021001': 'Саврань',
  'UA5112021019': 'Слюсареве',
  'UA5112021020': 'Струтинка',
  'UA5112021005': 'Вільшанка',
  'UA5112021010': 'Йосипівка',
  'UA5112023002': 'Кирилівка',
  'UA5112023003': 'Мала Слобідка',
  'UA5112023004': 'Правда',
  'UA5112023001': 'Слобідка',
  'UA5112023005': 'Тимкове',
  'UA5112007009': 'Чабанівка',
  'UA5112007004': 'Гвоздавка Друга',
  'UA5112007005': 'Гвоздавка Перша',
  'UA5112007006': 'Познанка Друга',
  'UA5112007007': 'Познанка Перша',
  'UA5112007010': 'Шликареве',
  'UA5112007008': 'Солтанівка',
  'UA5112007002': 'Василівка',
  'UA5112007003': 'Володимирівка',
  'UA5112007011': 'Ясенове Друге',
  'UA5112007012': 'Ясенове Перше',
  'UA5112007001': 'Зеленогірське',
  'UA5114009003': 'Кучурган',
  'UA5114009001': 'Лиманське',
  'UA5114009004': 'Нове',
  'UA5114009005': 'Новосільці',
  'UA5114009007': 'Щербанка',
  'UA5114009006': 'Степове',
  'UA5114009002': 'Виноградівка',
  'UA5114011002': 'Данилівка',
  'UA5114011003': 'Добрий Лук',
  'UA5114011004': 'Дурбайли',
  'UA5114011007': 'Іванівка',
  'UA5114011008': 'Козакове',
  'UA5114011009': 'Мартове',
  'UA5114011010': 'Мацкули',
  'UA5114011011': 'Мигаї',
  'UA5114011021': 'Мигаєве',
  'UA5114011012': 'Наливайкове',
  'UA5114011001': 'Новоборисівка',
  'UA5114011013': 'Першотравневе',
  'UA5114011014': 'Полішпакове',
  'UA5114011015': 'Поплавка',
  'UA5114011016': 'Преображенка',
  'UA5114011017': 'Путилівка',
  'UA5114011018': 'Райки',
  'UA5114011020': 'Тятри',
  'UA5114011019': 'Три Криниці',
  'UA5114011005': 'Залізничне',
  'UA5114011006': 'Заможне',
  'UA5114013002': 'Андрієво-Іванове',
  'UA5114013003': 'Антонівка',
  'UA5114013004': 'Бакалове',
  'UA5114013005': 'Балкове',
  'UA5114013006': 'Бецилове',
  'UA5114013007': 'Благодатне',
  'UA5114013008': 'Богнатове',
  'UA5114013009': 'Бринівка',
  'UA5114013010': 'Будячки',
  'UA5114013011': 'Бурдівка',
  'UA5114013012': 'Буцинівка',
  'UA5114013020': 'Калантаївка',
  'UA5114013021': 'Кам\'янка',
  'UA5114013022': 'Капаклієве',
  'UA5114013023': 'Карпівка',
  'UA5114013024': 'Карпове',
  'UA5114013025': 'Кошари',
  'UA5114013026': 'Кузьменка',
  'UA5114013027': 'Лозове',
  'UA5114013028': 'Матишівка',
  'UA5114013030': 'Міліардівка',
  'UA5114013029': 'Миколаївка',
  'UA5114013031': 'Надія',
  'UA5114013033': 'Нові Чобручі',
  'UA5114013036': 'Новодмитрівка Друга',
  'UA5114013035': 'Новодмитрівка',
  'UA5114013034': 'Новоградениця',
  'UA5114013037': 'Новоселівка',
  'UA5114013038': 'Новоукраїнка',
  'UA5114013032': 'Новий Гребеник',
  'UA5114013039': 'Олександрівка',
  'UA5114013040': 'Парканці',
  'UA5114013041': 'Перше Травня',
  'UA5114013042': 'Петро-Євдокіївка',
  'UA5114013043': 'Покровка',
  'UA5114013044': 'Понятівка',
  'UA5114013045': 'Поташенкове',
  'UA5114013001': 'Роздільна',
  'UA5114013051': 'Шеметове',
  'UA5114013050': 'Шевченкове',
  'UA5114013046': 'Слобідка',
  'UA5114013047': 'Старокостянтинівка',
  'UA5114013048': 'Старостине',
  'UA5114013049': 'Сухе',
  'UA5114013013': 'Вакулівка',
  'UA5114013014': 'Велізарове',
  'UA5114013015': 'Веселе',
  'UA5114013017': 'Володимирівка',
  'UA5114013016': 'Виноградар',
  'UA5114013018': 'Єреміївка',
  'UA5114013019': 'Желепове',
  'UA5114015002': 'Ангелінівка',
  'UA5114015003': 'Бугай',
  'UA5114015021': 'Дружба',
  'UA5114015005': 'Гаївка',
  'UA5114015006': 'Гетьманці',
  'UA5114015007': 'Івано-Миколаївка',
  'UA5114015008': 'Лучинське',
  'UA5114015009': 'Марківка',
  'UA5114015010': 'Нове',
  'UA5114015011': 'Новокостянтинівка',
  'UA5114015012': 'Новокрасне',
  'UA5114015013': 'Павлівка',
  'UA5114015014': 'Петрівка',
  'UA5114015015': 'Плавневе',
  'UA5114015016': 'Розалівка',
  'UA5114015019': 'Широке',
  'UA5114015001': 'Степанівка',
  'UA5114015017': 'Труд-Гребеник',
  'UA5114015018': 'Труд-Куток',
  'UA5114015004': 'Вапнярка',
  'UA5114015020': 'Яковлівка',
  'UA5114017003': 'Галупове',
  'UA5114017004': 'Іринівка',
  'UA5114017005': 'Малоцебрикове',
  'UA5114017006': 'Мардарівка',
  'UA5114017008': 'Новопавлівка',
  'UA5114017009': 'Новороманівка',
  'UA5114017010': 'Новоселівка',
  'UA5114017007': 'Никомаврівка',
  'UA5114017011': 'Оленівка',
  'UA5114017012': 'Ольгинове',
  'UA5114017013': 'Привільне',
  'UA5114017014': 'Саханське',
  'UA5114017015': 'Сокорове',
  'UA5114017001': 'Цебрикове',
  'UA5114017002': 'Вишневе',
  'UA5114001002': 'Багачеве',
  'UA5114001003': 'Бессарабка',
  'UA5114001012': 'Дівоцьке',
  'UA5114001028': 'Фрасине',
  'UA5114001009': 'Гірське',
  'UA5114001008': 'Гіржове',
  'UA5114001010': 'Гребеники',
  'UA5114001011': 'Грушка',
  'UA5114001014': 'Кардамичеве',
  'UA5114001015': 'Комарівка',
  'UA5114001016': 'Кучурган',
  'UA5114001017': 'Муратове',
  'UA5114001018': 'Нові Бутори',
  'UA5114001019': 'Новоолександрівка',
  'UA5114001020': 'Новопетрівка',
  'UA5114001021': 'Новоселівка',
  'UA5114001022': 'Платонівка',
  'UA5114001023': 'Полезне',
  'UA5114001024': 'Соше-Острівське',
  'UA5114001025': 'Стоянове',
  'UA5114001026': 'Трохимівка',
  'UA5114001027': 'Трудомирівка',
  'UA5114001004': 'Вакарське',
  'UA5114001005': 'Василівка',
  'UA5114001001': 'Велика Михайлівка',
  'UA5114001006': 'Великокомарівка',
  'UA5114001007': 'Водяне',
  'UA5114001013': 'Єрмішкове',
  'UA5114001029': 'Юрківка',
  'UA5114003002': 'Антоно-Ковач',
  'UA5114003003': 'Кістельниця',
  'UA5114003004': 'Малоплоске',
  'UA5114003005': 'Новоантонівка',
  'UA5114003006': 'Новосавицьке',
  'UA5114003007': 'Олег',
  'UA5114003008': 'Орел',
  'UA5114003009': 'Покровка',
  'UA5114003010': 'Привілля',
  'UA5114003011': 'Слов\'яносербка',
  'UA5114003012': 'Тростянець',
  'UA5114003001': 'Великоплоске',
  'UA5114007002': 'Балашове',
  'UA5114007003': 'Бірносове',
  'UA5114007004': 'Богданове Перше',
  'UA5114007037': 'Червона Стінка',
  'UA5114007009': 'Давидівка',
  'UA5114007010': 'Дементівка',
  'UA5114007036': 'Федосіївка',
  'UA5114007007': 'Гірківка',
  'UA5114007008': 'Глибокояр',
  'UA5114007014': 'Карабанове',
  'UA5114007015': 'Кошарка',
  'UA5114007016': 'Кримпулька',
  'UA5114007017': 'Майорське',
  'UA5114007018': 'Мала Топорівка',
  'UA5114007019': 'Мар\'янівка',
  'UA5114007020': 'Нова Шибка',
  'UA5114007022': 'Новомиколаївка',
  'UA5114007023': 'Новопавлівка',
  'UA5114007021': 'Новозаріцьке',
  'UA5114007024': 'Оленівка',
  'UA5114007025': 'Онилове',
  'UA5114007027': 'Парканівка',
  'UA5114007026': 'Павлівка',
  'UA5114007029': 'Перше Травня',
  'UA5114007028': 'Первомайське',
  'UA5114007030': 'Петрівка',
  'UA5114007031': 'Росіянівка',
  'UA5114007033': 'Самійлівка',
  'UA5114007032': 'Савчинське',
  'UA5114007034': 'Стоянове',
  'UA5114007035': 'Унтилівка',
  'UA5114007005': 'Василівка',
  'UA5114007006': 'Войничеве',
  'UA5114007011': 'Єлизаветівка',
  'UA5114007013': 'Йосипівка',
  'UA5114007001': 'Захарівка',
  'UA5114007012': 'Жигайлове',
  'UA5114005002': 'Андрусова',
  'UA5114005006': 'Дружелюбівка',
  'UA5114005005': 'Гедеримове Перше',
  'UA5114005008': 'Іванівка',
  'UA5114005009': 'Краснопіль',
  'UA5114005010': 'Малорошове',
  'UA5114005011': 'Нова Григорівка',
  'UA5114005013': 'Перехрестове Перше',
  'UA5114005012': 'Перехрестове',
  'UA5114005014': 'Петрівка',
  'UA5114005015': 'Скинешори',
  'UA5114005016': 'Торосове',
  'UA5114005003': 'Весела Балка',
  'UA5114005004': 'Володимирівка',
  'UA5114005007': 'Загір\'я',
  'UA5114005001': 'Затишшя',
  'UA5302001002': 'Бабичівка',
  'UA5302001003': 'Багни',
  'UA5302001004': 'Балабушині Верби',
  'UA5302001006': 'Бориси',
  'UA5302001005': 'Битакове Озеро',
  'UA5302001062': 'Черевані',
  'UA5302001014': 'Демченки',
  'UA5302001013': 'Демидівка',
  'UA5302001061': 'Федорівка',
  'UA5302001001': 'Глобине',
  'UA5302001011': 'Глушкове Друге',
  'UA5302001010': 'Глибоке',
  'UA5302001012': 'Гуляйполе',
  'UA5302001021': 'Іванове Селище',
  'UA5302001023': 'Коломицівка',
  'UA5302001024': 'Кордубанове',
  'UA5302001025': 'Корещина',
  'UA5302001022': 'Ковнірівщина',
  'UA5302001026': 'Куп\'євате',
  'UA5302001027': 'Ламане',
  'UA5302001028': 'Лубенщина',
  'UA5302001029': 'Лукашівка',
  'UA5302001030': 'Майданівка',
  'UA5302001035': 'Махнівка',
  'UA5302001031': 'Малинівка',
  'UA5302001033': 'Манилівське',
  'UA5302001032': 'Манжелія',
  'UA5302001034': 'Мар\'їне',
  'UA5302001036': 'Набережне',
  'UA5302001038': 'Новобудова',
  'UA5302001039': 'Новодорожнє',
  'UA5302001040': 'Новомосковське',
  'UA5302001041': 'Новоселівка',
  'UA5302001037': 'Новий Виселок',
  'UA5302001042': 'Обознівка',
  'UA5302001043': 'Опришки',
  'UA5302001044': 'Павлівка',
  'UA5302001045': 'Петрівка',
  'UA5302001047': 'Попівка',
  'UA5302001049': 'Пустовійтове',
  'UA5302001048': 'Пузикове',
  'UA5302001046': 'Пироги',
  'UA5302001050': 'Радалівка',
  'UA5302001051': 'Романівка',
  'UA5302001052': 'Руда',
  'UA5302001053': 'Семимогили',
  'UA5302001064': 'Шепелівка',
  'UA5302001063': 'Шевченки',
  'UA5302001055': 'Сіренки',
  'UA5302001056': 'Старий Хутір',
  'UA5302001057': 'Степове',
  'UA5302001054': 'Сиротенки',
  'UA5302001058': 'Троїцьке',
  'UA5302001059': 'Турбаї',
  'UA5302001060': 'Устимівка',
  'UA5302001007': 'Великі Кринки',
  'UA5302001008': 'Весела Долина',
  'UA5302001009': 'Вітки',
  'UA5302001065': 'Яроші',
  'UA5302001017': 'Заможне',
  'UA5302001018': 'Зарічне',
  'UA5302001019': 'Землянки',
  'UA5302001015': 'Жорняки',
  'UA5302001016': 'Жуки',
  'UA5302001020': 'Зубані',
  'UA5302003002': 'Базалуки',
  'UA5302003005': 'Дмитрівка',
  'UA5302003003': 'Гора',
  'UA5302003001': 'Горішні Плавні',
  'UA5302003004': 'Григоро-Бригадирівка',
  'UA5302003006': 'Карпівка',
  'UA5302003007': 'Келеберда',
  'UA5302003009': 'Кузьменки',
  'UA5302003008': 'Кияшки',
  'UA5302003010': 'Махнівка',
  'UA5302003011': 'Мотрине',
  'UA5302003012': 'Петрашівка',
  'UA5302003013': 'Салівка',
  'UA5302003014': 'Солонці',
  'UA5302003015': 'Солошине',
  'UA5302005002': 'Білоусівка',
  'UA5302005003': 'Броварки',
  'UA5302005004': 'Бугаївка',
  'UA5302005007': 'Ганнівка',
  'UA5302005008': 'Горби',
  'UA5302005001': 'Градизьк',
  'UA5302005009': 'Гриньки',
  'UA5302005010': 'Кагамлик',
  'UA5302005011': 'Канівщина',
  'UA5302005013': 'Котляревське',
  'UA5302005014': 'Крива Руда',
  'UA5302005012': 'Кирияківка',
  'UA5302005016': 'Лізки',
  'UA5302005015': 'Липове',
  'UA5302005017': 'Мозоліївка',
  'UA5302005018': 'Пелехівщина',
  'UA5302005019': 'Петрашівка',
  'UA5302005020': 'Погреби',
  'UA5302005021': 'Пронозівка',
  'UA5302005022': 'Проценки',
  'UA5302005024': 'Середпілля',
  'UA5302005028': 'Шушвалівка',
  'UA5302005026': 'Струтинівка',
  'UA5302005023': 'Святилівка',
  'UA5302005025': 'Сидори',
  'UA5302005027': 'Тимошівка',
  'UA5302005005': 'Васьківка',
  'UA5302005006': 'Вишеньки',
  'UA5302007002': 'Білецьківка',
  'UA5302007003': 'Бурти',
  'UA5302007010': 'Чечелеве',
  'UA5302007011': 'Чикалівка',
  'UA5302007001': 'Кам\'яні Потоки',
  'UA5302007004': 'Маламівка',
  'UA5302007005': 'Новоселівка',
  'UA5302007006': 'Підгірне',
  'UA5302007007': 'Ройове',
  'UA5302007008': 'Садки',
  'UA5302007009': 'Стара Білецьківка',
  'UA5302009002': 'Андрійки',
  'UA5302009003': 'Бреусівка',
  'UA5302009004': 'Бригадирівка',
  'UA5302009005': 'Булахи',
  'UA5302009006': 'Бутоярівка',
  'UA5302009058': 'Чечужине',
  'UA5302009059': 'Чорноглазівка',
  'UA5302009017': 'Дяченки',
  'UA5302009016': 'Довге',
  'UA5302009015': 'Дзюбанівка',
  'UA5302009013': 'Ганнівка',
  'UA5302009014': 'Глибока Долина',
  'UA5302009020': 'Калашники',
  'UA5302009021': 'Калинівка',
  'UA5302009022': 'Кащівка',
  'UA5302009054': 'Харченки',
  'UA5302009055': 'Хмарине',
  'UA5302009056': 'Хорішки',
  'UA5302009024': 'Костівка',
  'UA5302009001': 'Козельщина',
  'UA5302009025': 'Красносілля',
  'UA5302009023': 'Квіти',
  'UA5302009026': 'Лозки',
  'UA5302009027': 'Лутовинівка',
  'UA5302009028': 'Майорщина',
  'UA5302009029': 'Мальці',
  'UA5302009030': 'Мар\'янівка',
  'UA5302009032': 'Михайлики',
  'UA5302009031': 'Миргородщина',
  'UA5302009035': 'Нова Україна',
  'UA5302009036': 'Новоселівка',
  'UA5302009034': 'Нижня Мануйлівка',
  'UA5302009033': 'Нижня Жужманівка',
  'UA5302009037': 'Олександрівка Друга',
  'UA5302009038': 'Оленівка',
  'UA5302009039': 'Ольгівка',
  'UA5302009040': 'Омельниче',
  'UA5302009043': 'Панасівка',
  'UA5302009044': 'Пашенівка',
  'UA5302009045': 'Пашківка',
  'UA5302009042': 'Павлівка',
  'UA5302009041': 'Павлики',
  'UA5302009046': 'Підгорівка',
  'UA5302009047': 'Пригарівка',
  'UA5302009048': 'Приліпка',
  'UA5302009049': 'Рибалки',
  'UA5302009060': 'Шевченки',
  'UA5302009050': 'Солониця',
  'UA5302009051': 'Сухий Кобелячок',
  'UA5302009052': 'Сушки',
  'UA5302009057': 'Цибівка',
  'UA5302009053': 'Улинівка',
  'UA5302009008': 'Верхня Мануйлівка',
  'UA5302009007': 'Верхня Жужманівка',
  'UA5302009012': 'В\'язівка',
  'UA5302009011': 'Вільне',
  'UA5302009009': 'Винники',
  'UA5302009010': 'Висока Вакулівка',
  'UA5302009061': 'Юрівка',
  'UA5302009062': 'Юрки',
  'UA5302009063': 'Юрочки',
  'UA5302009019': 'Задовга',
  'UA5302009018': 'Загребелля',
  'UA5302011001': 'Кременчук',
  'UA5302011002': 'Мала Кохнівка',
  'UA5302011003': 'Потоки',
  'UA5302011004': 'Придніпрянське',
  'UA5302011005': 'Соснівка',
  'UA5302013002': 'Бондарі',
  'UA5302013003': 'Бутенки',
  'UA5302013008': 'Гайове',
  'UA5302013009': 'Горбані',
  'UA5302013012': 'Книшівка',
  'UA5302013001': 'Нова Галещина',
  'UA5302013013': 'Олександрівка',
  'UA5302013014': 'Остапці',
  'UA5302013015': 'Піски',
  'UA5302013016': 'Ревівка',
  'UA5302013017': 'Трудовик',
  'UA5302013004': 'Василенки',
  'UA5302013005': 'Василівка',
  'UA5302013006': 'Велика Безуглівка',
  'UA5302013007': 'Вишняки',
  'UA5302013011': 'Йосипівка',
  'UA5302013010': 'Заруддя',
  'UA5302015002': 'Бурбине',
  'UA5302015006': 'Дем\'янівка',
  'UA5302015004': 'Гаївка',
  'UA5302015005': 'Горошине',
  'UA5302015008': 'Іванівка',
  'UA5302015021': 'Худоліївка',
  'UA5302015009': 'Кукоби',
  'UA5302015010': 'Лукашівка',
  'UA5302015011': 'Матвіївка',
  'UA5302015012': 'Мирони',
  'UA5302015013': 'Наріжжя',
  'UA5302015014': 'Наталенки',
  'UA5302015015': 'Новий Калкаїв',
  'UA5302015001': 'Оболонь',
  'UA5302015016': 'Погребняки',
  'UA5302015017': 'Пузирі',
  'UA5302015018': 'Старий Калкаїв',
  'UA5302015019': 'Строкачі',
  'UA5302015020': 'Тукали',
  'UA5302015003': 'Василяки',
  'UA5302015007': 'Зікранці',
  'UA5302017004': 'Демидівка',
  'UA5302017017': 'Федоренки',
  'UA5302017003': 'Гуньки',
  'UA5302017006': 'Ковалі',
  'UA5302017007': 'Крамаренки',
  'UA5302017008': 'Литвиненки',
  'UA5302017009': 'Найденівка',
  'UA5302017001': 'Омельник',
  'UA5302017010': 'Онищенки',
  'UA5302017012': 'П\'ятихатки',
  'UA5302017011': 'Пустовіти',
  'UA5302017013': 'Радочини',
  'UA5302017014': 'Рокитне',
  'UA5302017015': 'Романки',
  'UA5302017018': 'Щербаки',
  'UA5302017019': 'Щербухи',
  'UA5302017016': 'Степівка',
  'UA5302017002': 'Варакути',
  'UA5302017020': 'Яремівка',
  'UA5302017005': 'Запсілля',
  'UA5302019004': 'Гориславці',
  'UA5302019005': 'Кіндрівка',
  'UA5302019007': 'Коржівка',
  'UA5302019006': 'Ковалівка',
  'UA5302019008': 'Кривуші',
  'UA5302019009': 'Майбородівка',
  'UA5302019010': 'Максимівка',
  'UA5302019013': 'Михайленки',
  'UA5302019011': 'Миловидівка',
  'UA5302019012': 'Мирне',
  'UA5302019014': 'Недогарки',
  'UA5302019015': 'Нова Знам\'янка',
  'UA5302019016': 'Олефірівка',
  'UA5302019017': 'Панівка',
  'UA5302019018': 'Пащенівка',
  'UA5302019001': 'Піщане',
  'UA5302019020': 'Пухальщина',
  'UA5302019019': 'Писарщина',
  'UA5302019021': 'Рокитне-Донівка',
  'UA5302019022': 'Самусіївка',
  'UA5302019002': 'Вільна Терешківка',
  'UA5302019003': 'Воскобійники',
  'UA5302019023': 'Ялинці',
  'UA5302021013': 'Черемушки',
  'UA5302021002': 'Дабинівка',
  'UA5302021004': 'Кобелячок',
  'UA5302021005': 'Колісники',
  'UA5302021006': 'Комендантівка',
  'UA5302021007': 'Криничне',
  'UA5302021008': 'Малики',
  'UA5302021009': 'Олександрія',
  'UA5302021011': 'Порубаї',
  'UA5302021001': 'Пришиб',
  'UA5302021010': 'Пилипенки',
  'UA5302021012': 'Роботівка',
  'UA5302021003': 'Єристівка',
  'UA5302023002': 'Бадьорівка',
  'UA5302023003': 'Байрак',
  'UA5302023004': 'Бакумівка',
  'UA5302023006': 'Біляки',
  'UA5302023005': 'Білогуби',
  'UA5302023007': 'Богданівка',
  'UA5302023008': 'Брусове',
  'UA5302023009': 'Бурімка',
  'UA5302023042': 'Чаплинці',
  'UA5302023043': 'Червоний Лиман',
  'UA5302023016': 'Герасимівка',
  'UA5302023017': 'Греблі',
  'UA5302023018': 'Грицаї',
  'UA5302023021': 'Калинівка',
  'UA5302023022': 'Карпиха',
  'UA5302023023': 'Крива Руда',
  'UA5302023024': 'Курганне',
  'UA5302023026': 'Малі Липняги',
  'UA5302023025': 'Малинівка',
  'UA5302023027': 'Нова Олександрівка',
  'UA5302023028': 'Нова Петрівка',
  'UA5302023029': 'Новоселиця',
  'UA5302023031': 'Очеретувате',
  'UA5302023030': 'Осокори',
  'UA5302023032': 'Паніванівка',
  'UA5302023033': 'Поділ',
  'UA5302023034': 'Рокити',
  'UA5302023001': 'Семенівка',
  'UA5302023035': 'Середине',
  'UA5302023044': 'Шепелівка',
  'UA5302023036': 'Слюзівка',
  'UA5302023037': 'Степанівка',
  'UA5302023038': 'Тарасівка',
  'UA5302023039': 'Товсте',
  'UA5302023040': 'Тройняки',
  'UA5302023041': 'Устимівка',
  'UA5302023010': 'Василівка',
  'UA5302023011': 'Великі Липняги',
  'UA5302023012': 'Вербки',
  'UA5302023013': 'Вереміївка',
  'UA5302023014': 'Веселий Поділ',
  'UA5302023015': 'Вільне',
  'UA5302023019': 'Єгорівка',
  'UA5302023020': 'Заїчинці',
  'UA5304013002': 'Білоусівка',
  'UA5304013003': 'Богданівка',
  'UA5304013004': 'Богодарівка',
  'UA5304013005': 'Бондарі',
  'UA5304013006': 'Бубни',
  'UA5304013036': 'Чаплинка',
  'UA5304013001': 'Чорнухи',
  'UA5304013008': 'Гайки',
  'UA5304013009': 'Галяве',
  'UA5304013010': 'Гільці',
  'UA5304013011': 'Голотівщина',
  'UA5304013012': 'Городище',
  'UA5304013034': 'Харсіки',
  'UA5304013035': 'Хейлівщина',
  'UA5304013014': 'Кізлівка',
  'UA5304013015': 'Ковалі',
  'UA5304013016': 'Красне',
  'UA5304013018': 'Лісова Слобідка',
  'UA5304013019': 'Луговики',
  'UA5304013017': 'Липове',
  'UA5304013020': 'Мелехи',
  'UA5304013021': 'Мокіївка',
  'UA5304013022': 'Нехристівка',
  'UA5304013023': 'Нова Діброва',
  'UA5304013024': 'Нова Петрівщина',
  'UA5304013025': 'Олександрівка',
  'UA5304013026': 'Осняг',
  'UA5304013027': 'Пацали',
  'UA5304013029': 'Піски-Удайські',
  'UA5304013028': 'Пізники',
  'UA5304013030': 'Постав-Мука',
  'UA5304013032': 'Суха Лохвиця',
  'UA5304013033': 'Сухоносівка',
  'UA5304013031': 'Синяківщина',
  'UA5304013007': 'Вороньки',
  'UA5304013037': 'Яцюкове',
  'UA5304013013': 'Загребелля',
  'UA5304001002': 'Березівка',
  'UA5304001003': 'Бесідівщина',
  'UA5304001006': 'Горби',
  'UA5304001001': 'Гребінка',
  'UA5304001008': 'Грушківка',
  'UA5304001007': 'Григорівка',
  'UA5304001009': 'Гулаківка',
  'UA5304001011': 'Корніївка',
  'UA5304001012': 'Короваї',
  'UA5304001013': 'Кулажинці',
  'UA5304001014': 'Лутайка',
  'UA5304001015': 'Майорщина',
  'UA5304001016': 'Мар\'янівка',
  'UA5304001017': 'Михайлівка',
  'UA5304001018': 'Наталівка',
  'UA5304001019': 'Новодар',
  'UA5304001020': 'Новоселівка',
  'UA5304001022': 'Олександрівка',
  'UA5304001023': 'Олексіївка',
  'UA5304001024': 'Оржиця',
  'UA5304001025': 'Осавульщина',
  'UA5304001021': 'Овсюки',
  'UA5304001026': 'Павлівщина',
  'UA5304001030': 'Почаївка',
  'UA5304001028': 'Покровщина',
  'UA5304001029': 'Польове',
  'UA5304001027': 'Писарщина',
  'UA5304001031': 'Рудка',
  'UA5304001032': 'Саївка',
  'UA5304001034': 'Сербинівка',
  'UA5304001035': 'Сімаки',
  'UA5304001036': 'Скочак',
  'UA5304001037': 'Сліпорід-Іванівка',
  'UA5304001038': 'Слободо-Петрівка',
  'UA5304001039': 'Сотницьке',
  'UA5304001040': 'Стукалівка',
  'UA5304001033': 'Світанок',
  'UA5304001041': 'Тарасівка',
  'UA5304001042': 'Тополеве',
  'UA5304001005': 'Відрадне',
  'UA5304001004': 'Високе',
  'UA5304001010': 'Загребелля',
  'UA5304011002': 'Андріївка',
  'UA5304011003': 'Барилівщина',
  'UA5304011004': 'Барилове',
  'UA5304011005': 'Березняки',
  'UA5304011007': 'Бочки',
  'UA5304011006': 'Бовбасівка',
  'UA5304011008': 'Бригадирівка',
  'UA5304011009': 'Бубереве',
  'UA5304011010': 'Бурлаки',
  'UA5304011011': 'Бутівці',
  'UA5304011087': 'Червоне',
  'UA5304011023': 'Дації',
  'UA5304011024': 'Демина Балка',
  'UA5304011025': 'Дубове',
  'UA5304011020': 'Глибока Долина',
  'UA5304011022': 'Грушине',
  'UA5304011021': 'Григорівка',
  'UA5304011019': 'Гирине',
  'UA5304011030': 'Іванці',
  'UA5304011031': 'Іващенки',
  'UA5304011086': 'Хоменки',
  'UA5304011001': 'Хорол',
  'UA5304011084': 'Хвощівка',
  'UA5304011085': 'Хильківка',
  'UA5304011032': 'Клепачі',
  'UA5304011033': 'Княжа Лука',
  'UA5304011037': 'Коломійцеве Озеро',
  'UA5304011038': 'Костюки',
  'UA5304011034': 'Ковалі',
  'UA5304011035': 'Ковтуни',
  'UA5304011036': 'Козубівка',
  'UA5304011039': 'Кривці',
  'UA5304011040': 'Кулики',
  'UA5304011041': 'Кулиничі',
  'UA5304011042': 'Куторжиха',
  'UA5304011043': 'Лагодівка',
  'UA5304011044': 'Лазьки',
  'UA5304011045': 'Левченки',
  'UA5304011046': 'Лісянщина',
  'UA5304011047': 'Лобкова Балка',
  'UA5304011048': 'Лози',
  'UA5304011049': 'Мала Попівка',
  'UA5304011050': 'Мартинівка',
  'UA5304011051': 'Мелюшки',
  'UA5304011054': 'Мусіївка',
  'UA5304011052': 'Миколаївка',
  'UA5304011053': 'Мищенки',
  'UA5304011055': 'Настасівка',
  'UA5304011056': 'Наталівка',
  'UA5304011057': 'Новачиха',
  'UA5304011059': 'Новоаврамівка',
  'UA5304011060': 'Новоіванівка',
  'UA5304011058': 'Новий Байрак',
  'UA5304011061': 'Оріхівщина',
  'UA5304011062': 'Орликівщина',
  'UA5304011063': 'Остапенки',
  'UA5304011066': 'Падусі',
  'UA5304011064': 'Павленки',
  'UA5304011065': 'Павлівка',
  'UA5304011067': 'Петракіївка',
  'UA5304011068': 'Покровська Багачка',
  'UA5304011069': 'Попівка',
  'UA5304011070': 'Пристань',
  'UA5304011071': 'Радьки',
  'UA5304011073': 'Роплянське',
  'UA5304011072': 'Рибченки',
  'UA5304011074': 'Садове',
  'UA5304011075': 'Середнє',
  'UA5304011088': 'Шарківщина',
  'UA5304011091': 'Шкилі',
  'UA5304011092': 'Штомпелівка',
  'UA5304011089': 'Широке',
  'UA5304011090': 'Шишаки',
  'UA5304011076': 'Софине',
  'UA5304011078': 'Стайки',
  'UA5304011079': 'Стара Мусіївка',
  'UA5304011080': 'Староаврамівка',
  'UA5304011077': 'Ставки',
  'UA5304011081': 'Тарасівка',
  'UA5304011082': 'Третякове',
  'UA5304011083': 'Трубайці',
  'UA5304011012': 'Ванжина Долина',
  'UA5304011013': 'Варварівка',
  'UA5304011014': 'Вербине',
  'UA5304011015': 'Вергуни',
  'UA5304011018': 'В\'язівок',
  'UA5304011016': 'Вишневе',
  'UA5304011017': 'Вишняки',
  'UA5304011093': 'Ялосовецьке',
  'UA5304011026': 'Єньки',
  'UA5304011027': 'Єрківці',
  'UA5304011028': 'Запорожчине',
  'UA5304011029': 'Зубенки',
  'UA5304003002': 'Барвінщина',
  'UA5304003003': 'Березівка',
  'UA5304003004': 'Березоточа',
  'UA5304003005': 'Броварки',
  'UA5304003060': 'Чернече',
  'UA5304003059': 'Червоні Пологи',
  'UA5304003061': 'Чуднівці',
  'UA5304003014': 'Ісківці',
  'UA5304003015': 'Калайдинці',
  'UA5304003056': 'Халепці',
  'UA5304003058': 'Хорошки',
  'UA5304003057': 'Хитці',
  'UA5304003016': 'Клепачі',
  'UA5304003017': 'Кононівка',
  'UA5304003019': 'Крем\'янка',
  'UA5304003018': 'Кревелівка',
  'UA5304003020': 'Крутий Берег',
  'UA5304003022': 'Куп\'єваха',
  'UA5304003021': 'Кузубівка',
  'UA5304003024': 'Ломаки',
  'UA5304003001': 'Лубни',
  'UA5304003025': 'Луки',
  'UA5304003026': 'Лушники',
  'UA5304003023': 'Литвяки',
  'UA5304003027': 'Малий В\'язівок',
  'UA5304003028': 'Матяшівка',
  'UA5304003029': 'Мацківці',
  'UA5304003030': 'Мацкова Лучка',
  'UA5304003031': 'Мгар',
  'UA5304003032': 'Михнівці',
  'UA5304003033': 'Назарівка',
  'UA5304003035': 'Новаки',
  'UA5304003034': 'Нижній Булатець',
  'UA5304003036': 'Окіп',
  'UA5304003037': 'Олександрівка',
  'UA5304003038': 'Оріхівка',
  'UA5304003043': 'П\'ятигірці',
  'UA5304003040': 'Піски',
  'UA5304003041': 'Покровське',
  'UA5304003042': 'Пулинці',
  'UA5304003039': 'Пишне',
  'UA5304003044': 'Ремівка',
  'UA5304003046': 'Селюків',
  'UA5304003062': 'Шершнівка',
  'UA5304003063': 'Шинківщина',
  'UA5304003047': 'Снітин',
  'UA5304003048': 'Снітине',
  'UA5304003049': 'Солониця',
  'UA5304003050': 'Стадня',
  'UA5304003051': 'Суха Солониця',
  'UA5304003045': 'Свічківка',
  'UA5304003053': 'Тернівщина',
  'UA5304003052': 'Терни',
  'UA5304003055': 'Тотчине',
  'UA5304003054': 'Тишки',
  'UA5304003012': 'В\'язівок',
  'UA5304003008': 'Вільшанка',
  'UA5304003011': 'Войниха',
  'UA5304003009': 'Вовча Долина',
  'UA5304003010': 'Вовчик',
  'UA5304003006': 'Висачки',
  'UA5304003007': 'Вищий Булатець',
  'UA5304003013': 'Засулля',
  'UA5304005002': 'Біївці',
  'UA5304005003': 'Богодарівка',
  'UA5304005031': 'Черевки',
  'UA5304005010': 'Духове',
  'UA5304005007': 'Гінці',
  'UA5304005008': 'Горобії',
  'UA5304005009': 'Губське',
  'UA5304005013': 'Іванівка',
  'UA5304005014': 'Карпилівка',
  'UA5304005029': 'Хорошки',
  'UA5304005030': 'Хоружівка',
  'UA5304005016': 'Котляревське',
  'UA5304005015': 'Козаче',
  'UA5304005017': 'Лазірки',
  'UA5304005018': 'Макарівщина',
  'UA5304005019': 'Максимівщина',
  'UA5304005020': 'Несено-Іржавець',
  'UA5304005021': 'Нове',
  'UA5304005001': 'Новооржицьке',
  'UA5304005022': 'Новоселівка',
  'UA5304005023': 'Остапівка',
  'UA5304005024': 'Приймівщина',
  'UA5304005025': 'Сліпорід',
  'UA5304005026': 'Степурі',
  'UA5304005027': 'Тарандинці',
  'UA5304005028': 'Тимки',
  'UA5304005006': 'Воронинці',
  'UA5304005004': 'Вили',
  'UA5304005005': 'Вишневе',
  'UA5304005011': 'Єнківці',
  'UA5304005012': 'Заріччя',
  'UA5304007029': 'Чайківщина',
  'UA5304007030': 'Чевельча',
  'UA5304007031': 'Чмихалове',
  'UA5304007032': 'Чутівка',
  'UA5304007004': 'Денисівка',
  'UA5304007005': 'Дмитрівка',
  'UA5304007003': 'Грабів',
  'UA5304007011': 'Колодна',
  'UA5304007012': 'Круподеринці',
  'UA5304007013': 'Лукім\'я',
  'UA5304007015': 'Маяківка',
  'UA5304007014': 'Малоселецьке',
  'UA5304007017': 'Новий Іржавець',
  'UA5304007016': 'Нижній Іржавець',
  'UA5304007018': 'Онішки',
  'UA5304007001': 'Оржиця',
  'UA5304007020': 'Плехів',
  'UA5304007021': 'Полуніївка',
  'UA5304007019': 'Пилиповичі',
  'UA5304007022': 'Райозеро',
  'UA5304007023': 'Савинці',
  'UA5304007024': 'Сазонівка',
  'UA5304007025': 'Старий Іржавець',
  'UA5304007026': 'Тарасенкове',
  'UA5304007027': 'Тарасівка',
  'UA5304007028': 'Теремецьке',
  'UA5304007002': 'Великоселецьке',
  'UA5304007033': 'Яблуневе',
  'UA5304007006': 'Загребелля',
  'UA5304007007': 'Загребля',
  'UA5304007008': 'Залужне',
  'UA5304007009': 'Заріг',
  'UA5304007010': 'Золотухи',
  'UA5304009002': 'Архемівка',
  'UA5304009003': 'Березова Рудка',
  'UA5304009004': 'Білоцерківці',
  'UA5304009044': 'Червоне',
  'UA5304009014': 'Давидівка',
  'UA5304009015': 'Дейманівка',
  'UA5304009012': 'Грабарівка',
  'UA5304009013': 'Гурбинці',
  'UA5304009019': 'Калинів Міст',
  'UA5304009020': 'Каплинці',
  'UA5304009021': 'Кейбалівка',
  'UA5304009043': 'Харківці',
  'UA5304009023': 'Крячківка',
  'UA5304009022': 'Кроти',
  'UA5304009024': 'Курінька',
  'UA5304009025': 'Леляки',
  'UA5304009026': 'Мала Круча',
  'UA5304009027': 'Малютинці',
  'UA5304009028': 'Мар\'їнське',
  'UA5304009029': 'Меченки',
  'UA5304009030': 'Могилівщина',
  'UA5304009031': 'Нетратівка',
  'UA5304009032': 'Нові Мартиновичі',
  'UA5304009011': 'Новоселівка',
  'UA5304009033': 'Олександрівка',
  'UA5304009034': 'Першотравневе',
  'UA5304009035': 'Повстин',
  'UA5304009036': 'Прихідьки',
  'UA5304009001': 'Пирятин',
  'UA5304009037': 'Рівне',
  'UA5304009038': 'Сасинівка',
  'UA5304009045': 'Шкурати',
  'UA5304009039': 'Скибинці',
  'UA5304009040': 'Смотрики',
  'UA5304009041': 'Теплівка',
  'UA5304009042': 'Усівка',
  'UA5304009007': 'Вечірки',
  'UA5304009005': 'Велика Круча',
  'UA5304009006': 'Верхоярівка',
  'UA5304009010': 'Вікторія',
  'UA5304009009': 'Вишневе',
  'UA5304009008': 'Високе',
  'UA5304009046': 'Яцини',
  'UA5304009018': 'Ївженки',
  'UA5304009016': 'Замостище',
  'UA5304009017': 'Заріччя',
  'UA5306001002': 'Андрущине',
  'UA5306001003': 'Балаклія',
  'UA5306001004': 'Баланди',
  'UA5306001001': 'Білоцерківка',
  'UA5306001005': 'Бірки',
  'UA5306001010': 'Дзюбівщина',
  'UA5306001008': 'Герусівка',
  'UA5306001009': 'Говори',
  'UA5306001011': 'Колосівка',
  'UA5306001012': 'Коноплянка',
  'UA5306001013': 'Корнієнки',
  'UA5306001015': 'Красногорівка',
  'UA5306001014': 'Кравченки',
  'UA5306001016': 'Лугове',
  'UA5306001017': 'Морозівщина',
  'UA5306001018': 'Мостовівщина',
  'UA5306001019': 'Огирівка',
  'UA5306001021': 'Поділ',
  'UA5306001022': 'Попове',
  'UA5306001020': 'Писарівщина',
  'UA5306001023': 'Рокита',
  'UA5306001029': 'Шпирни',
  'UA5306001028': 'Шипоші',
  'UA5306001025': 'Стінки',
  'UA5306001024': 'Сидорівщина',
  'UA5306001026': 'Трудолюбиве',
  'UA5306001027': 'Цикали',
  'UA5306001006': 'Вишарі',
  'UA5306001007': 'Вишняківка',
  'UA5306009002': 'Біленченківка',
  'UA5306009003': 'Бутовичеське',
  'UA5306009021': 'Червоний Кут',
  'UA5306009005': 'Донцівщина',
  'UA5306009001': 'Гадяч',
  'UA5306009004': 'Грипаки',
  'UA5306009020': 'Харківці',
  'UA5306009008': 'Кіблицьке',
  'UA5306009009': 'Круглик',
  'UA5306009007': 'Кияшківське',
  'UA5306009006': 'Київське',
  'UA5306009010': 'Малі Будища',
  'UA5306009011': 'Оріханове',
  'UA5306009012': 'Осняги',
  'UA5306009013': 'Островерхівка',
  'UA5306009014': 'Петроселівка',
  'UA5306009015': 'Писарівщина',
  'UA5306009016': 'Рудиків',
  'UA5306009017': 'Саранчова Долина',
  'UA5306009018': 'Сари',
  'UA5306009019': 'Степаненки',
  'UA5306011003': 'Дакалівка',
  'UA5306011001': 'Гоголеве',
  'UA5306011002': 'Грянчиха',
  'UA5306011004': 'Кульбашне',
  'UA5306011005': 'Мар\'янське',
  'UA5306011006': 'Матяшівка',
  'UA5306011007': 'Михайлівка',
  'UA5306011008': 'Підлуки',
  'UA5306011009': 'Псільське',
  'UA5306011010': 'Устивиця',
  'UA5306015002': 'Андріївка',
  'UA5306015003': 'Бакумівка',
  'UA5306015004': 'Безводівка',
  'UA5306015005': 'Булуки',
  'UA5306015034': 'Черевки',
  'UA5306015035': 'Черкащани',
  'UA5306015038': 'Дібрівка',
  'UA5306015009': 'Гасенки',
  'UA5306015010': 'Григорівка',
  'UA5306015014': 'Клюшниківка',
  'UA5306015001': 'Комишня',
  'UA5306015015': 'Лісове',
  'UA5306015016': 'Мала Грем\'яча',
  'UA5306015017': 'Мокрії',
  'UA5306015018': 'Новоселиця',
  'UA5306015019': 'Онацьке',
  'UA5306015020': 'Остапівка',
  'UA5306015021': 'Панченки',
  'UA5306015022': 'Петрівка',
  'UA5306015024': 'Попівка',
  'UA5306015025': 'Прокоповичі',
  'UA5306015023': 'Писарівка',
  'UA5306015026': 'Радченкове',
  'UA5306015027': 'Сажка',
  'UA5306015036': 'Штомпелі',
  'UA5306015037': 'Шульги',
  'UA5306015029': 'Скиданки',
  'UA5306015030': 'Сохацьке',
  'UA5306015031': 'Ступки',
  'UA5306015028': 'Синьощоки',
  'UA5306015032': 'Травневе',
  'UA5306015006': 'Велика Грем\'яча',
  'UA5306015007': 'Верхня Будаківка',
  'UA5306015008': 'Вовки',
  'UA5306015011': 'Залізняки',
  'UA5306015013': 'Зуївці',
  'UA5306017002': 'Берізки',
  'UA5306017003': 'Бірки',
  'UA5306017004': 'Бухалове',
  'UA5306017005': 'Глибока Долина',
  'UA5306017006': 'Гречанівка',
  'UA5306017016': 'Хитці',
  'UA5306017001': 'Красна Лука',
  'UA5306017009': 'Максимівка',
  'UA5306017010': 'Мала Побиванка',
  'UA5306017011': 'Миколаївка',
  'UA5306017012': 'Пирятинщина',
  'UA5306017013': 'Римарівка',
  'UA5306017019': 'Шевченкове',
  'UA5306017014': 'Сватки',
  'UA5306017015': 'Тригубщина',
  'UA5306017018': 'Ціпки',
  'UA5306017017': 'Цимбалове',
  'UA5306017007': 'Зелена Балка',
  'UA5306017008': 'Змажине',
  'UA5306021002': 'Глибоке',
  'UA5306021003': 'Кругле Озеро',
  'UA5306021001': 'Лютенька',
  'UA5306021004': 'Лисівка',
  'UA5306021005': 'Мала Обухівка',
  'UA5306021006': 'Млини',
  'UA5306021007': 'Новий Виселок',
  'UA5306021008': 'Перевіз',
  'UA5306021009': 'Рашівка',
  'UA5306021010': 'Солдатове',
  'UA5306021011': 'Соснівка',
  'UA5306021012': 'Юр\'ївка',
  'UA5306019002': 'Архипівка',
  'UA5306019004': 'Бербениці',
  'UA5306019003': 'Безсали',
  'UA5306019005': 'Білогорілка',
  'UA5306019043': 'Червона Балка',
  'UA5306019044': 'Червона Слобідка',
  'UA5306019045': 'Чижі',
  'UA5306019014': 'Дереківщина',
  'UA5306019015': 'Дібрівне',
  'UA5306019016': 'Долинка',
  'UA5306019011': 'Гаївщина',
  'UA5306019012': 'Гамаліївка',
  'UA5306019013': 'Гиряві Ісківці',
  'UA5306019041': 'Харківці',
  'UA5306019042': 'Христанівка',
  'UA5306019020': 'Криниця',
  'UA5306019001': 'Лохвиця',
  'UA5306019021': 'Лука',
  'UA5306019022': 'Мехедівка',
  'UA5306019023': 'Млини',
  'UA5306019024': 'Нове',
  'UA5306019025': 'Озерне',
  'UA5306019026': 'Парницьке',
  'UA5306019027': 'Пестичевське',
  'UA5306019028': 'Погарщина',
  'UA5306019029': 'Пучківщина',
  'UA5306019031': 'Романиха',
  'UA5306019033': 'Ручки',
  'UA5306019032': 'Руда',
  'UA5306019030': 'Риги',
  'UA5306019046': 'Шевченкове',
  'UA5306019047': 'Шмиглі',
  'UA5306019035': 'Слобідка',
  'UA5306019036': 'Сокириха',
  'UA5306019037': 'Старий Хутір',
  'UA5306019038': 'Степне',
  'UA5306019039': 'Степуки',
  'UA5306019034': 'Свиридівка',
  'UA5306019040': 'Токарі',
  'UA5306019006': 'Васильки',
  'UA5306019007': 'Венслави',
  'UA5306019008': 'Веселе',
  'UA5306019010': 'Воля',
  'UA5306019009': 'Вишеньки',
  'UA5306019049': 'Яхники',
  'UA5306019050': 'Яшники',
  'UA5306019048': 'Юсківці',
  'UA5306019018': 'Западинці',
  'UA5306019017': 'Жабки',
  'UA5306019019': 'Зірка',
  'UA5306023002': 'Бієве',
  'UA5306023003': 'Білики',
  'UA5306023009': 'Деркачі',
  'UA5306023010': 'Дібрівка',
  'UA5306023011': 'Довгалівка',
  'UA5306023007': 'Гаркушинці',
  'UA5306023008': 'Глибоке',
  'UA5306023034': 'Хомутець',
  'UA5306023016': 'Котлярі',
  'UA5306023017': 'Кузьменки',
  'UA5306023015': 'Кибинці',
  'UA5306023018': 'Лещенки',
  'UA5306023019': 'Любівщина',
  'UA5306023021': 'Малі Сорочинці',
  'UA5306023022': 'Мальці',
  'UA5306023020': 'Малинівка',
  'UA5306023023': 'Марченки',
  'UA5306023024': 'Милашенкове',
  'UA5306023001': 'Миргород',
  'UA5306023025': 'Носенки',
  'UA5306023026': 'Осове',
  'UA5306023027': 'Петрівці',
  'UA5306023028': 'Показове',
  'UA5306023030': 'Руда',
  'UA5306023029': 'Рибальське',
  'UA5306023035': 'Шахворостівка',
  'UA5306023036': 'Шпакове',
  'UA5306023031': 'Слобідка',
  'UA5306023032': 'Стовбине',
  'UA5306023033': 'Трудолюб',
  'UA5306023004': 'Верховина',
  'UA5306023005': 'Веселе',
  'UA5306023006': 'Вовнянка',
  'UA5306023037': 'Ярмаки',
  'UA5306023012': 'Ємці',
  'UA5306023013': 'Єрки',
  'UA5306023014': 'Зубівка',
  'UA5306025002': 'Балясне',
  'UA5306025003': 'Березова Лука',
  'UA5306025006': 'Коновалове',
  'UA5306025007': 'Лихопілля',
  'UA5306025008': 'Мелешки',
  'UA5306025001': 'Петрівка-Роменська',
  'UA5306025009': 'Ручки',
  'UA5306025010': 'Середняки',
  'UA5306025004': 'Венеславівка',
  'UA5306025005': 'Ветхалівка',
  'UA5306027003': 'Конюшеве',
  'UA5306027004': 'Новооріхівка',
  'UA5306027001': 'Ромодан',
  'UA5306027005': 'Ромодан',
  'UA5306027007': 'Шарківщина',
  'UA5306027006': 'Сотницьке',
  'UA5306027002': 'Величківка',
  'UA5306029002': 'Бешти',
  'UA5306029020': 'Часниківка',
  'UA5306029007': 'Дрюківщина',
  'UA5306029006': 'Гірки',
  'UA5306029009': 'Ісківці',
  'UA5306029019': 'Хитці',
  'UA5306029010': 'Корсунівка',
  'UA5306029011': 'Лучка',
  'UA5306029012': 'Овдіївка',
  'UA5306029013': 'Пласківщина',
  'UA5306029014': 'Потоцьківщина',
  'UA5306029015': 'Рудка',
  'UA5306029016': 'Саранчине',
  'UA5306029001': 'Сенча',
  'UA5306029021': 'Шеки',
  'UA5306029022': 'Шкадрети',
  'UA5306029017': 'Скоробагатьки',
  'UA5306029018': 'Сльозиха',
  'UA5306029005': 'Вовківське',
  'UA5306029003': 'Вирішальне',
  'UA5306029004': 'Високе',
  'UA5306029023': 'Яблунівка',
  'UA5306029008': 'Ждани',
  'UA5306031013': 'Чернече',
  'UA5306031005': 'Дачне',
  'UA5306031007': 'Качанове',
  'UA5306031006': 'Калинівщина',
  'UA5306031008': 'Крамарщина',
  'UA5306031009': 'Лободине',
  'UA5306031010': 'Новоселівка',
  'UA5306031011': 'Розбишівка',
  'UA5306031001': 'Сергіївка',
  'UA5306031012': 'Степове',
  'UA5306031003': 'Вечірчине',
  'UA5306031002': 'Веселе',
  'UA5306031004': 'Вирішальне',
  'UA5306033002': 'Бабичі',
  'UA5306033003': 'Баранівка',
  'UA5306033004': 'Білаші',
  'UA5306033005': 'Бухуни',
  'UA5306033069': 'Чернишівка',
  'UA5306033070': 'Чорнобаї',
  'UA5306033018': 'Дем\'янки',
  'UA5306033019': 'Дмитрівка',
  'UA5306033062': 'Федунка',
  'UA5306033013': 'Гнатенки',
  'UA5306033014': 'Гоголеве',
  'UA5306033015': 'Гончарі',
  'UA5306033016': 'Горішнє',
  'UA5306033017': 'Григорівщина',
  'UA5306033063': 'Харенки',
  'UA5306033066': 'Ходосиха',
  'UA5306033067': 'Христівка',
  'UA5306033064': 'Хвальки',
  'UA5306033065': 'Хвощове',
  'UA5306033025': 'Климове',
  'UA5306033028': 'Коляди',
  'UA5306033027': 'Колодяжне',
  'UA5306033026': 'Ковердина Балка',
  'UA5306033029': 'Криворучки',
  'UA5306033023': 'Кирпотівка',
  'UA5306033024': 'Киселиха',
  'UA5306033030': 'Легейди',
  'UA5306033031': 'Лещани',
  'UA5306033032': 'Луці',
  'UA5306033033': 'Мала Бузова',
  'UA5306033034': 'Малий Перевіз',
  'UA5306033035': 'Маликівщина',
  'UA5306033036': 'Маначинівка',
  'UA5306033037': 'Маслівці',
  'UA5306033039': 'Мірошники',
  'UA5306033038': 'Михайлики',
  'UA5306033040': 'Науменки',
  'UA5306033043': 'Носи',
  'UA5306033041': 'Нижні Яреськи',
  'UA5306033042': 'Низова Яковенщина',
  'UA5306033044': 'Павлівка',
  'UA5306033045': 'Пелагеївка',
  'UA5306033046': 'Переводчикове',
  'UA5306033047': 'Першотравневе',
  'UA5306033048': 'Покровське',
  'UA5306033049': 'Порскалівка',
  'UA5306033050': 'Принцеве',
  'UA5306033051': 'Пришиб',
  'UA5306033052': 'Раївка',
  'UA5306033054': 'Романки',
  'UA5306033053': 'Римиги',
  'UA5306033055': 'Сагайдак',
  'UA5306033056': 'Салимівщина',
  'UA5306033057': 'Самари',
  'UA5306033072': 'Шафранівка',
  'UA5306033071': 'Шарлаївка',
  'UA5306033073': 'Швадрони',
  'UA5306033001': 'Шишаки',
  'UA5306033058': 'Соснівка',
  'UA5306033059': 'Сулими',
  'UA5306033061': 'Товсте',
  'UA5306033068': 'Цьови',
  'UA5306033060': 'Тищенки',
  'UA5306033008': 'Величкове',
  'UA5306033006': 'Велика Бузова',
  'UA5306033007': 'Великий Перевіз',
  'UA5306033009': 'Вертелецьке',
  'UA5306033011': 'Воронянщина',
  'UA5306033012': 'Воскобійники',
  'UA5306033010': 'Вишневе',
  'UA5306033074': 'Яковенщина-Горова',
  'UA5306033075': 'Яреськи',
  'UA5306033021': 'Зелене',
  'UA5306033020': 'Жоржівка',
  'UA5306033022': 'Зозулі',
  'UA5306003002': 'Багачка Перша',
  'UA5306003003': 'Байрак',
  'UA5306003004': 'Балюки',
  'UA5306003005': 'Бехтерщина',
  'UA5306003006': 'Буряківщина',
  'UA5306003007': 'Бутова Долина',
  'UA5306003010': 'Довгалівка',
  'UA5306003009': 'Гарнокут',
  'UA5306003012': 'Іванівка',
  'UA5306003013': 'Кротівщина',
  'UA5306003014': 'Лукаші',
  'UA5306003015': 'Мала Решетилівка',
  'UA5306003016': 'Мар\'янівка',
  'UA5306003017': 'Перекопівка',
  'UA5306003019': 'Пушкареве',
  'UA5306003018': 'Пилипенки',
  'UA5306003020': 'Радивонівка',
  'UA5306003021': 'Семенівка',
  'UA5306003026': 'Шепелі',
  'UA5306003027': 'Широка Долина',
  'UA5306003028': 'Широке',
  'UA5306003022': 'Скибівщина',
  'UA5306003024': 'Стефанівщина',
  'UA5306003023': 'Степанівка',
  'UA5306003025': 'Суржки',
  'UA5306003001': 'Велика Багачка',
  'UA5306003008': 'Володимирівка',
  'UA5306003029': 'Якимове',
  'UA5306003011': 'Затон',
  'UA5306005002': 'Бакути',
  'UA5306005003': 'Бобрик',
  'UA5306005004': 'Броварки',
  'UA5306005009': 'Дучинці',
  'UA5306005011': 'Книшівка',
  'UA5306005012': 'Мартинівка',
  'UA5306005013': 'Могилатів',
  'UA5306005014': 'Морозівщина',
  'UA5306005015': 'Педоричі',
  'UA5306005016': 'Плішивець',
  'UA5306005020': 'Шадурка',
  'UA5306005017': 'Тепле',
  'UA5306005019': 'Тютюрівщина',
  'UA5306005018': 'Тимофіївка',
  'UA5306005006': 'Вельбівка',
  'UA5306005005': 'Велике',
  'UA5306005001': 'Великі Будища',
  'UA5306005007': 'Веприк',
  'UA5306005008': 'Воронівщина',
  'UA5306005010': 'Запсільське',
  'UA5306007002': 'Бессараби',
  'UA5306007021': 'Декабристів',
  'UA5306007005': 'Довгалеве',
  'UA5306007007': 'Іващенки',
  'UA5306007009': 'Коптів',
  'UA5306007008': 'Ковалівка',
  'UA5306007010': 'Купівщина',
  'UA5306007011': 'Мареничі',
  'UA5306007012': 'Олефірівка',
  'UA5306007013': 'Панасівка',
  'UA5306007014': 'Полив\'яне',
  'UA5306007015': 'Радченки',
  'UA5306007017': 'Сакалівка',
  'UA5306007016': 'Савинці',
  'UA5306007018': 'Семеренки',
  'UA5306007019': 'Солонці',
  'UA5306007020': 'Цисеве',
  'UA5306007003': 'Велика Обухівка',
  'UA5306007001': 'Великі Сорочинці',
  'UA5306007004': 'Великий Байрак',
  'UA5306007006': 'Зелений Кут',
  'UA5306013002': 'Бодаква',
  'UA5306013009': 'Червоні Луки',
  'UA5306013008': 'Хрулі',
  'UA5306013005': 'Нижня Будаківка',
  'UA5306013006': 'Піски',
  'UA5306013007': 'Пісочки',
  'UA5306013010': 'Шевченки',
  'UA5306013003': 'Вишневе',
  'UA5306013011': 'Яремівщина',
  'UA5306013004': 'Заморіївка',
  'UA5306013001': 'Заводське',
  'UA5308001002': 'Бережнівка',
  'UA5308001003': 'Білоконівка',
  'UA5308001001': 'Білики',
  'UA5308001004': 'Богданівка',
  'UA5308001005': 'Бутенки',
  'UA5308001023': 'Червоне',
  'UA5308001024': 'Червоні Квіти',
  'UA5308001025': 'Чорбівка',
  'UA5308001026': 'Чумаки',
  'UA5308001008': 'Дрижина Гребля',
  'UA5308001012': 'Колодяжне',
  'UA5308001013': 'Комарівка',
  'UA5308001014': 'Кустолові Кущі',
  'UA5308001015': 'Марківка',
  'UA5308001016': 'Мирне',
  'UA5308001017': 'Прогрес',
  'UA5308001018': 'Рубани',
  'UA5308001027': 'Шапки',
  'UA5308001020': 'Славнівка',
  'UA5308001021': 'Степове',
  'UA5308001019': 'Свічкареве',
  'UA5308001022': 'Трояни',
  'UA5308001007': 'Вітрова Балка',
  'UA5308001006': 'Вишневе',
  'UA5308001011': 'Зелене',
  'UA5308001010': 'Жуки',
  'UA5308001009': 'Жирки',
  'UA5308045027': 'Черняківка',
  'UA5308045001': 'Чутове',
  'UA5308045009': 'Дондасівка',
  'UA5308045008': 'Грякове',
  'UA5308045011': 'Кантемирівка',
  'UA5308045012': 'Кочубеївка',
  'UA5308045013': 'Левенцівка',
  'UA5308045015': 'Лозуватка',
  'UA5308045014': 'Лисича',
  'UA5308045017': 'Нове Грякове',
  'UA5308045018': 'Новофедорівка',
  'UA5308045016': 'Нижні Рівні',
  'UA5308045019': 'Охоче',
  'UA5308045020': 'Першотравневе',
  'UA5308045021': 'Розпашне',
  'UA5308045028': 'Щасливе',
  'UA5308045022': 'Смородщина',
  'UA5308045023': 'Стінка',
  'UA5308045024': 'Сторожове',
  'UA5308045025': 'Таверівка',
  'UA5308045026': 'Тойбік',
  'UA5308045002': 'Василівка',
  'UA5308045003': 'Верхні Рівні',
  'UA5308045005': 'Вільхуватка',
  'UA5308045006': 'Водяне',
  'UA5308045007': 'Войнівка',
  'UA5308045004': 'Виноминівка',
  'UA5308045029': 'Юнаки',
  'UA5308045010': 'Зеленківка',
  'UA5308007002': 'Богданівка',
  'UA5308007008': 'Довга Пустош',
  'UA5308007001': 'Драбинівка',
  'UA5308007009': 'Дудкин Гай',
  'UA5308007007': 'Галущина Гребля',
  'UA5308007010': 'Крута Балка',
  'UA5308007011': 'Кустолове',
  'UA5308007012': 'Лугове',
  'UA5308007013': 'Малі Солонці',
  'UA5308007014': 'Мушина Гребля',
  'UA5308007015': 'Райдужне',
  'UA5308007016': 'Суха Маячка',
  'UA5308007003': 'Варварівка',
  'UA5308007004': 'Веселка',
  'UA5308007005': 'Вільний Степ',
  'UA5308007006': 'Вовківка',
  'UA5308005002': 'Андренки',
  'UA5308005003': 'Андріївка',
  'UA5308005004': 'Байрак',
  'UA5308005005': 'Балясне',
  'UA5308005006': 'Борисівка',
  'UA5308005056': 'Чернечий Яр',
  'UA5308005057': 'Чернещина',
  'UA5308005016': 'Дейнеківка',
  'UA5308005018': 'Дячкове',
  'UA5308005017': 'Діброва',
  'UA5308005001': 'Диканька',
  'UA5308005054': 'Федорівка',
  'UA5308005012': 'Гавронці',
  'UA5308005013': 'Глоди',
  'UA5308005014': 'Горбатівка',
  'UA5308005015': 'Горянщина',
  'UA5308005021': 'Кам\'янка',
  'UA5308005022': 'Кардашівка',
  'UA5308005055': 'Хоменки',
  'UA5308005023': 'Климківка',
  'UA5308005024': 'Кокозівка',
  'UA5308005025': 'Кононенки',
  'UA5308005026': 'Кратова Говтва',
  'UA5308005027': 'Кучерівка',
  'UA5308005028': 'Ландарі',
  'UA5308005029': 'Лани',
  'UA5308005030': 'Мала Рудка',
  'UA5308005031': 'Марченки',
  'UA5308005033': 'Міжгір\'я',
  'UA5308005032': 'Михайлівка',
  'UA5308005034': 'Надежда',
  'UA5308005035': 'Нелюбівка',
  'UA5308005036': 'Нова Василівка',
  'UA5308005037': 'Одарюківка',
  'UA5308005038': 'Олефірщина',
  'UA5308005039': 'Онацьки',
  'UA5308005040': 'Орданівка',
  'UA5308005041': 'Петренки',
  'UA5308005042': 'Петро-Давидівка',
  'UA5308005044': 'Попівка',
  'UA5308005045': 'Проні',
  'UA5308005043': 'Писарівщина',
  'UA5308005047': 'Слиньків Яр',
  'UA5308005048': 'Сохацька Балка',
  'UA5308005049': 'Стасі',
  'UA5308005050': 'Степанівка',
  'UA5308005051': 'Судівка',
  'UA5308005046': 'Сивці',
  'UA5308005052': 'Тополівка',
  'UA5308005053': 'Трояни',
  'UA5308005007': 'Василівка',
  'UA5308005008': 'Велика Рудка',
  'UA5308005009': 'Великі Будища',
  'UA5308005010': 'Веселівка',
  'UA5308005011': 'Водяна Балка',
  'UA5308005058': 'Ярохівка',
  'UA5308005019': 'Єлизаветівка',
  'UA5308005020': 'Жадани',
  'UA5308011002': 'Бабайкове',
  'UA5308011004': 'Давидівка',
  'UA5308011013': 'Голобородьківське',
  'UA5308011014': 'Іванівка',
  'UA5308011001': 'Карлівка',
  'UA5308011005': 'Короленківка',
  'UA5308011006': 'Лип\'янка',
  'UA5308011007': 'Максимівка',
  'UA5308011015': 'Михайлівське',
  'UA5308011008': 'Павлівщина',
  'UA5308011009': 'Попівка',
  'UA5308011010': 'Розумівка',
  'UA5308011016': 'Солона Балка',
  'UA5308011017': 'Тагамлицьке',
  'UA5308011011': 'Тарасівка',
  'UA5308011003': 'Володимирівка',
  'UA5308011012': 'Ясне',
  'UA5308013002': 'Брачківка',
  'UA5308013004': 'Бродщина',
  'UA5308013003': 'Бринзи',
  'UA5308013059': 'Чкалове',
  'UA5308013017': 'Дашківка',
  'UA5308013018': 'Деменки',
  'UA5308013009': 'Гаймарівка',
  'UA5308013010': 'Гайове',
  'UA5308013011': 'Галагурівка',
  'UA5308013012': 'Галі-Горбатки',
  'UA5308013013': 'Ганжівка',
  'UA5308013014': 'Гарбузівка',
  'UA5308013015': 'Григорівка',
  'UA5308013016': 'Грицаївка',
  'UA5308013020': 'Іванівка',
  'UA5308013021': 'Канави',
  'UA5308013001': 'Кобеляки',
  'UA5308013024': 'Колісниківка',
  'UA5308013023': 'Коваленківка',
  'UA5308013025': 'Красне',
  'UA5308013026': 'Кунівка',
  'UA5308013022': 'Кишеньки',
  'UA5308013027': 'Лебедине',
  'UA5308013029': 'Лесинки',
  'UA5308013028': 'Леваневське',
  'UA5308013033': 'Ліщинівка',
  'UA5308013032': 'Лісне',
  'UA5308013031': 'Лівобережна Сокілка',
  'UA5308013034': 'Лучки',
  'UA5308013030': 'Литвини',
  'UA5308013035': 'Мартинівка',
  'UA5308013037': 'Мідянівка',
  'UA5308013038': 'Морози',
  'UA5308013036': 'Миколаївка',
  'UA5308013040': 'Орлик',
  'UA5308013039': 'Озера',
  'UA5308013042': 'Панське',
  'UA5308013041': 'Павлівка',
  'UA5308013043': 'Перегонівка',
  'UA5308013044': 'Підгора',
  'UA5308013045': 'Поводи',
  'UA5308013046': 'Правобережна Сокілка',
  'UA5308013050': 'Прощуради',
  'UA5308013049': 'Просяниківка',
  'UA5308013048': 'Проскури',
  'UA5308013047': 'Придніпрянське',
  'UA5308013051': 'Ревущине',
  'UA5308013052': 'Самарщина',
  'UA5308013060': 'Шабельники',
  'UA5308013062': 'Шенгури',
  'UA5308013061': 'Шевченки',
  'UA5308013054': 'Сінне',
  'UA5308013055': 'Соснівка',
  'UA5308013056': 'Сухе',
  'UA5308013057': 'Сухинівка',
  'UA5308013053': 'Світлогірське',
  'UA5308013058': 'Твердохліби',
  'UA5308013005': 'Василівка',
  'UA5308013006': 'Вібли',
  'UA5308013007': 'Вільховатка',
  'UA5308013008': 'Водолагівка',
  'UA5308013063': 'Яблунівка',
  'UA5308013019': 'Золотарівка',
  'UA5308015003': 'Дудникове',
  'UA5308015001': 'Коломацьке',
  'UA5308015005': 'Мале Ладижине',
  'UA5308015006': 'Олексіївка',
  'UA5308015007': 'Рубанівка',
  'UA5308015008': 'Сонячне',
  'UA5308015009': 'Старицьківка',
  'UA5308015010': 'Степанівка',
  'UA5308015011': 'Степне',
  'UA5308015002': 'Василівка',
  'UA5308015004': 'Зоря',
  'UA5308017002': 'Більськ',
  'UA5308017010': 'Чернещина',
  'UA5308017003': 'Деревки',
  'UA5308017004': 'Камінне',
  'UA5308017001': 'Котельва',
  'UA5308017005': 'Любка',
  'UA5308017008': 'Млинки',
  'UA5308017006': 'Михайлівка Перша',
  'UA5308017007': 'Михайлове',
  'UA5308017009': 'Сидоряче',
  'UA5308019011': 'Чалівка',
  'UA5308019009': 'Федорівка',
  'UA5308019010': 'Холодне Плесо',
  'UA5308019003': 'Климівка',
  'UA5308019004': 'Коржиха',
  'UA5308019005': 'Куми',
  'UA5308019001': 'Ланна',
  'UA5308019006': 'Львівське',
  'UA5308019007': 'Нижня Ланна',
  'UA5308019008': 'Редути',
  'UA5308019002': 'Верхня Ланна',
  'UA5308023002': 'Байрак',
  'UA5308023003': 'Бондури',
  'UA5308023004': 'Браїлки',
  'UA5308023026': 'Чередники',
  'UA5308023007': 'Дмитренки',
  'UA5308023006': 'Гвоздиківка',
  'UA5308023008': 'Калашники',
  'UA5308023009': 'Клименки',
  'UA5308023010': 'Кованчик',
  'UA5308023011': 'Куклинці',
  'UA5308023012': 'Левенцівка',
  'UA5308023001': 'Мачухи',
  'UA5308023014': 'Малі Козуби',
  'UA5308023013': 'Мазурівка',
  'UA5308023016': 'Михайлики',
  'UA5308023015': 'Миколаївка',
  'UA5308023018': 'Підлепичі',
  'UA5308023019': 'Плоске',
  'UA5308023020': 'Полузір\'я',
  'UA5308023017': 'Писаренки',
  'UA5308023021': 'Сердюки',
  'UA5308023027': 'Шевченки',
  'UA5308023022': 'Снопове',
  'UA5308023023': 'Судіївка',
  'UA5308023024': 'Твердохліби',
  'UA5308023025': 'Твердохліби',
  'UA5308023005': 'Васьки',
  'UA5308021002': 'Білухівка',
  'UA5308021007': 'Красне',
  'UA5308021005': 'Мар\'янівка',
  'UA5308021001': 'Мартинівка',
  'UA5308021009': 'Шевченка',
  'UA5308021008': 'Тишенківка',
  'UA5308021006': 'Вакулиха',
  'UA5308021003': 'Варварівка',
  'UA5308021004': 'Знаменка',
  'UA5308025002': 'Абрамівка',
  'UA5308025003': 'Базилівщина',
  'UA5308025004': 'Богданівка',
  'UA5308025008': 'Дмитрівка',
  'UA5308025007': 'Григорівка',
  'UA5308025009': 'Калинівка',
  'UA5308025011': 'Кошманівка',
  'UA5308025010': 'Козельщина',
  'UA5308025012': 'Латишівка',
  'UA5308025001': 'Машівка',
  'UA5308025013': 'Миронівка',
  'UA5308025014': 'Нова Павлівка',
  'UA5308025015': 'Новий Тагамлик',
  'UA5308025016': 'Огуївка',
  'UA5308025017': 'Петрівка',
  'UA5308025018': 'Сахнівщина',
  'UA5308025019': 'Селещина',
  'UA5308025020': 'Сухоносівка',
  'UA5308025021': 'Тимченківка',
  'UA5308025005': 'Вільне',
  'UA5308025006': 'Вовча Балка',
  'UA5308027002': 'Андріївка',
  'UA5308027003': 'Грабівщина',
  'UA5308027005': 'Коновалівка',
  'UA5308027006': 'Красногірка',
  'UA5308027007': 'Кустолово-Суходілка',
  'UA5308027008': 'Любимівка',
  'UA5308027009': 'Мала Нехвороща',
  'UA5308027001': 'Михайлівка',
  'UA5308027010': 'Павлівка',
  'UA5308027011': 'Первомайське',
  'UA5308027012': 'Ряське',
  'UA5308027013': 'Свистунівка',
  'UA5308027014': 'Усть-Лип\'янка',
  'UA5308027004': 'Жирківка',
  'UA5308029002': 'Андріївка',
  'UA5308029003': 'Бурти',
  'UA5308029004': 'Губарівка',
  'UA5308029005': 'Лівенське',
  'UA5308029006': 'Маячка',
  'UA5308029001': 'Нехвороща',
  'UA5308029007': 'Рекунівка',
  'UA5308029010': 'Шедієве',
  'UA5308029009': 'Соколова Балка',
  'UA5308029008': 'Світлівщина',
  'UA5308031002': 'Балівка',
  'UA5308031003': 'Бечеве',
  'UA5308031004': 'Бридуни',
  'UA5308031012': 'Давидівка',
  'UA5308031013': 'Дубина',
  'UA5308031009': 'Ганжі',
  'UA5308031010': 'Горобці',
  'UA5308031011': 'Грекопавлівка',
  'UA5308031017': 'Кальницьке',
  'UA5308031018': 'Клюсівка',
  'UA5308031019': 'Коби',
  'UA5308031020': 'Козуби',
  'UA5308031021': 'Кунцеве',
  'UA5308031022': 'Кустолове Перше',
  'UA5308031023': 'Лахни',
  'UA5308031024': 'Лелюхівка',
  'UA5308031025': 'Лисівка',
  'UA5308031026': 'Мала Перещепина',
  'UA5308031027': 'Малий Кобелячок',
  'UA5308031028': 'Маньківка',
  'UA5308031029': 'Мар\'янівка',
  'UA5308031030': 'Назаренки',
  'UA5308031001': 'Нові Санжари',
  'UA5308031031': 'Олійники',
  'UA5308031032': 'Пасічне',
  'UA5308031033': 'Пологи',
  'UA5308031034': 'Пологи-Низ',
  'UA5308031035': 'Попове',
  'UA5308031036': 'Пристанційне',
  'UA5308031037': 'Пудлівка',
  'UA5308031038': 'Руденківка',
  'UA5308031046': 'Шовкопляси',
  'UA5308031047': 'Шпортьки',
  'UA5308031039': 'Собківка',
  'UA5308031040': 'Старі Санжари',
  'UA5308031041': 'Стовбина Долина',
  'UA5308031042': 'Стрижівщина',
  'UA5308031043': 'Судівка',
  'UA5308031044': 'Сулими',
  'UA5308031045': 'Супротивна Балка',
  'UA5308031005': 'Велике Болото',
  'UA5308031007': 'Великі Солонці',
  'UA5308031006': 'Великий Кобелячок',
  'UA5308031008': 'Вісичі',
  'UA5308031014': 'Ємцева Долина',
  'UA5308031015': 'Забрідки',
  'UA5308031016': 'Зачепилівка',
  'UA5308033002': 'Березівка',
  'UA5308033004': 'Божківське',
  'UA5308033005': 'Божкове',
  'UA5308033003': 'Божки',
  'UA5308033006': 'Брунівка',
  'UA5308033007': 'Бурти',
  'UA5308033033': 'Черкасівка',
  'UA5308033032': 'Фисуни',
  'UA5308033012': 'Гаврилки',
  'UA5308033013': 'Глоби',
  'UA5308033014': 'Головки',
  'UA5308033015': 'Гонтарі',
  'UA5308033017': 'Карнаухи',
  'UA5308033019': 'Коломак',
  'UA5308033018': 'Кованьківка',
  'UA5308033020': 'Крюкове',
  'UA5308033021': 'Мар\'ївка',
  'UA5308033022': 'Надержинщина',
  'UA5308033023': 'Нестеренки',
  'UA5308033001': 'Новоселівка',
  'UA5308033024': 'Опішняни',
  'UA5308033025': 'Пасківка',
  'UA5308033026': 'Петрашівка',
  'UA5308033027': 'Рунівщина',
  'UA5308033034': 'Шили',
  'UA5308033030': 'Сягайли',
  'UA5308033028': 'Ступки',
  'UA5308033029': 'Сусідки',
  'UA5308033031': 'Терентіївка',
  'UA5308033008': 'Васильці',
  'UA5308033009': 'Вербове',
  'UA5308033010': 'Вільхівщина',
  'UA5308033011': 'Вільховий Ріг',
  'UA5308033016': 'Забаряни',
  'UA5308035002': 'Бабанське',
  'UA5308035003': 'Батьки',
  'UA5308035004': 'Безруки',
  'UA5308035005': 'Бухалівка',
  'UA5308035030': 'Човно-Федорівка',
  'UA5308035011': 'Деряги',
  'UA5308035012': 'Діброва',
  'UA5308035013': 'Драни',
  'UA5308035010': 'Глинське',
  'UA5308035016': 'Карабазівка',
  'UA5308035029': 'Хижняківка',
  'UA5308035018': 'Клименки',
  'UA5308035019': 'Кольченки',
  'UA5308035020': 'Корлюкове',
  'UA5308035017': 'Кирякове',
  'UA5308035021': 'Лавринці',
  'UA5308035022': 'Лазьки',
  'UA5308035023': 'Малі Будища',
  'UA5308035025': 'Міські Млини',
  'UA5308035024': 'Мисики',
  'UA5308035001': 'Опішня',
  'UA5308035026': 'Попівка',
  'UA5308035027': 'Пругли',
  'UA5308035028': 'Устименки',
  'UA5308035006': 'Васьки',
  'UA5308035007': 'Вільхове',
  'UA5308035008': 'Вінтенці',
  'UA5308035009': 'Волошкове',
  'UA5308035031': 'Яблучне',
  'UA5308035015': 'Заїченці',
  'UA5308035014': 'Заїки',
  'UA5308037002': 'Абазівка',
  'UA5308037003': 'Андріївка',
  'UA5308037004': 'Андрушки',
  'UA5308037005': 'Бершацьке',
  'UA5308037006': 'Біологічне',
  'UA5308037007': 'Бочанівка',
  'UA5308037008': 'Бричківка',
  'UA5308037009': 'Бугаївка',
  'UA5308037053': 'Червона Долина',
  'UA5308037054': 'Чорноглазівка',
  'UA5308037019': 'Давидівка',
  'UA5308037020': 'Долина',
  'UA5308037013': 'Глухове',
  'UA5308037014': 'Говтвянчик',
  'UA5308037015': 'Гожули',
  'UA5308037016': 'Грабинівка',
  'UA5308037017': 'Гринівка',
  'UA5308037018': 'Гутирівка',
  'UA5308037025': 'Івашки',
  'UA5308037027': 'Каплунівка',
  'UA5308037028': 'Карпусі',
  'UA5308037029': 'Келебердівка',
  'UA5308037031': 'Косточки',
  'UA5308037030': 'Ковалівка',
  'UA5308037032': 'Лаврики',
  'UA5308037033': 'Лозівка',
  'UA5308037034': 'Макарцівка',
  'UA5308037035': 'Макухівка',
  'UA5308037036': 'Мильці',
  'UA5308037037': 'Носівка',
  'UA5308037039': 'Очканівка',
  'UA5308037038': 'Олепіри',
  'UA5308037040': 'Пальчиківка',
  'UA5308037041': 'Патлаївка',
  'UA5308037042': 'Петрівка',
  'UA5308037001': 'Полтава',
  'UA5308037043': 'Рожаївка',
  'UA5308037044': 'Сем\'янівка',
  'UA5308037055': 'Шостаки',
  'UA5308037045': 'Соломахівка',
  'UA5308037046': 'Соснівка',
  'UA5308037047': 'Супрунівка',
  'UA5308037048': 'Тахтаулове',
  'UA5308037049': 'Тернівщина',
  'UA5308037050': 'Трирогове',
  'UA5308037052': 'Циганське',
  'UA5308037051': 'Уманцівка',
  'UA5308037010': 'Валок',
  'UA5308037011': 'Верхоли',
  'UA5308037012': 'Витівка',
  'UA5308037056': 'Яцинова Слобідка',
  'UA5308037026': 'Їжаківка',
  'UA5308037022': 'Залізничне',
  'UA5308037023': 'Затурине',
  'UA5308037021': 'Жуки',
  'UA5308037024': 'Зорівка',
  'UA5308039002': 'Андріївка',
  'UA5308039003': 'Бабичі',
  'UA5308039004': 'Бакай',
  'UA5308039005': 'Березняки',
  'UA5308039006': 'Білоконі',
  'UA5308039007': 'Братешки',
  'UA5308039009': 'Буняківка',
  'UA5308039008': 'Бузинівщина',
  'UA5308039016': 'Дем\'янці',
  'UA5308039015': 'Демидівка',
  'UA5308039017': 'Дмитренки',
  'UA5308039018': 'Долина',
  'UA5308039019': 'Дружба',
  'UA5308039073': 'Федіївка',
  'UA5308039010': 'Ганжі',
  'UA5308039011': 'Глибока Балка',
  'UA5308039014': 'Гольманівка',
  'UA5308039013': 'Голуби',
  'UA5308039012': 'Говтва',
  'UA5308039021': 'Каленики',
  'UA5308039022': 'Капустяни',
  'UA5308039074': 'Хоружі',
  'UA5308039075': 'Хрещате',
  'UA5308039024': 'Коліньки',
  'UA5308039025': 'Коломак',
  'UA5308039026': 'Колотії',
  'UA5308039027': 'Коржі',
  'UA5308039029': 'Крохмальці',
  'UA5308039028': 'Кривки',
  'UA5308039031': 'Кукобівка',
  'UA5308039030': 'Кузьменки',
  'UA5308039023': 'Киселівка',
  'UA5308039037': 'Лютівка',
  'UA5308039035': 'Лобачі',
  'UA5308039036': 'Лучки',
  'UA5308039032': 'Лиман Другий',
  'UA5308039033': 'Лиман Перший',
  'UA5308039034': 'Литвинівка',
  'UA5308039038': 'Малий Бакай',
  'UA5308039044': 'М\'якеньківка',
  'UA5308039042': 'Молодиківщина',
  'UA5308039043': 'Мушти',
  'UA5308039041': 'Михнівка',
  'UA5308039039': 'Миколаївка',
  'UA5308039040': 'Мирне',
  'UA5308039045': 'Нагірне',
  'UA5308039046': 'Нова Диканька',
  'UA5308039047': 'Нова Михайлівка',
  'UA5308039048': 'Нове Остапове',
  'UA5308039049': 'Олефіри',
  'UA5308039050': 'Онищенки',
  'UA5308039051': 'Остап\'є',
  'UA5308039052': 'Паненки',
  'UA5308039055': 'Пащенки',
  'UA5308039053': 'Пасічники',
  'UA5308039054': 'Паськівка',
  'UA5308039057': 'Підгір\'я',
  'UA5308039058': 'Підок',
  'UA5308039059': 'Піщане',
  'UA5308039060': 'Плавні',
  'UA5308039085': 'Покровське',
  'UA5308039061': 'Потеряйки',
  'UA5308039062': 'Потеряйки-Горові',
  'UA5308039063': 'Потічок',
  'UA5308039064': 'Прокопівка',
  'UA5308039065': 'Пустовари',
  'UA5308039056': 'Писаренки',
  'UA5308039001': 'Решетилівка',
  'UA5308039066': 'Сені',
  'UA5308039076': 'Шамраївка',
  'UA5308039077': 'Шарлаї',
  'UA5308039078': 'Шевченкове',
  'UA5308039081': 'Шкурупії',
  'UA5308039082': 'Шкурупіївка',
  'UA5308039083': 'Шрамки',
  'UA5308039079': 'Шилівка',
  'UA5308039080': 'Шишацьке',
  'UA5308039067': 'Славки',
  'UA5308039068': 'Сухорабівка',
  'UA5308039069': 'Тривайли',
  'UA5308039070': 'Тури',
  'UA5308039071': 'Тутаки',
  'UA5308039072': 'Уханівка',
  'UA5308039084': 'Яценки',
  'UA5308039020': 'Запсілля',
  'UA5308047002': 'Буланове',
  'UA5308047005': 'Гора',
  'UA5308047006': 'Горбанівка',
  'UA5308047007': 'Квіткове',
  'UA5308047008': 'Малий Тростянець',
  'UA5308047010': 'Нижні Млини',
  'UA5308047009': 'Нижні Вільшани',
  'UA5308047011': 'Пожарна Балка',
  'UA5308047012': 'Розсошенці',
  'UA5308047013': 'Сапожине',
  'UA5308047001': 'Щербані',
  'UA5308047015': 'Шмиглі',
  'UA5308047014': 'Тютюнники',
  'UA5308047003': 'Великий Тростянець',
  'UA5308047004': 'Вищі Вільшани',
  'UA5308041002': 'Березове',
  'UA5308041021': 'Флорівка',
  'UA5308041020': 'Филенкове',
  'UA5308041004': 'Іскрівка',
  'UA5308041006': 'Коханівка',
  'UA5308041005': 'Козаче',
  'UA5308041007': 'Лисівщина',
  'UA5308041008': 'Майорівка',
  'UA5308041010': 'Нова Кочубеївка',
  'UA5308041009': 'Никонорівка',
  'UA5308041011': 'Павлівка',
  'UA5308041012': 'Первозванівка',
  'UA5308041013': 'Петрівка',
  'UA5308041014': 'Підгірне',
  'UA5308041015': 'Рябківка',
  'UA5308041022': 'Шевченківка',
  'UA5308041001': 'Скороходове',
  'UA5308041016': 'Скибівка',
  'UA5308041017': 'Степанівка',
  'UA5308041018': 'Степове',
  'UA5308041019': 'Трудолюбівка',
  'UA5308041003': 'Вільниця',
  'UA5308043002': 'Безручки',
  'UA5308043003': 'Бузова Пасківка',
  'UA5308043006': 'Головач',
  'UA5308043009': 'Кашубівка',
  'UA5308043010': 'Клюшники',
  'UA5308043011': 'Копили',
  'UA5308043012': 'Курилехівка',
  'UA5308043013': 'Лукищина',
  'UA5308043014': 'Мале Микільське',
  'UA5308043015': 'Марківка',
  'UA5308043016': 'Микільське',
  'UA5308043017': 'Минівка',
  'UA5308043019': 'Портнівка',
  'UA5308043018': 'Писарівка',
  'UA5308043001': 'Терешки',
  'UA5308043020': 'Цибулі',
  'UA5308043004': 'Ватажкове',
  'UA5308043005': 'Ваці',
  'UA5308043007': 'Заворскло',
  'UA5308043008': 'Зінці',
  'UA5308003002': 'Борівське',
  'UA5308003028': 'Чоботарі',
  'UA5308003006': 'Дем\'янівка',
  'UA5308003007': 'Діброва',
  'UA5308003004': 'Гетьманка',
  'UA5308003005': 'Глобівка',
  'UA5308003011': 'Касяни',
  'UA5308003012': 'Ковалеве',
  'UA5308003013': 'Ковжижа',
  'UA5308003014': 'Козлівщина',
  'UA5308003015': 'Лабурівка',
  'UA5308003016': 'Лихачівка',
  'UA5308003017': 'Мала Рублівка',
  'UA5308003018': 'Маловидне',
  'UA5308003019': 'Мар\'їне',
  'UA5308003020': 'Матвіївка',
  'UA5308003021': 'Микілка',
  'UA5308003022': 'Милорадове',
  'UA5308003023': 'Назаренки',
  'UA5308003024': 'Підварівка',
  'UA5308003029': 'Шевченкове',
  'UA5308003025': 'Стадниця',
  'UA5308003026': 'Терещенки',
  'UA5308003027': 'Терни',
  'UA5308003001': 'Велика Рублівка',
  'UA5308003003': 'Ворони',
  'UA5308003009': 'Зайці Другі',
  'UA5308003008': 'Зайці',
  'UA5308003010': 'Зуби',
  'UA5308009002': 'Арсенівка',
  'UA5308009003': 'Артелярщина',
  'UA5308009004': 'Бірки',
  'UA5308009005': 'Бобрівник',
  'UA5308009006': 'Будки',
  'UA5308009079': 'Чорняки',
  'UA5308009017': 'Дадакалівка',
  'UA5308009018': 'Дамаска',
  'UA5308009019': 'Дейкалівка',
  'UA5308009020': 'Довбнівка',
  'UA5308009022': 'Довжок',
  'UA5308009021': 'Довжик',
  'UA5308009024': 'Дуб\'яги',
  'UA5308009025': 'Дуб\'янщина',
  'UA5308009023': 'Дубівка',
  'UA5308009074': 'Федорівка',
  'UA5308009013': 'Галійка',
  'UA5308009014': 'Горобії',
  'UA5308009015': 'Гришки',
  'UA5308009016': 'Гусаки',
  'UA5308009028': 'Іщенківка',
  'UA5308009075': 'Хмарівка',
  'UA5308009076': 'Храпачів Яр',
  'UA5308009077': 'Хрипки',
  'UA5308009030': 'Кілочки',
  'UA5308009031': 'Княжева Слобода',
  'UA5308009032': 'Косяки',
  'UA5308009033': 'Кругле',
  'UA5308009029': 'Кирило-Ганнівка',
  'UA5308009034': 'Лагоди',
  'UA5308009035': 'Левченки',
  'UA5308009036': 'Лютенські Будища',
  'UA5308009037': 'Макухи',
  'UA5308009038': 'Манилівка',
  'UA5308009039': 'Матяші',
  'UA5308009042': 'Морози',
  'UA5308009041': 'Михайлівка',
  'UA5308009040': 'Миколаївка',
  'UA5308009043': 'Новоселівка',
  'UA5308009044': 'Одрадівка',
  'UA5308009045': 'Пеленківщина',
  'UA5308009046': 'Переліски',
  'UA5308009047': 'Першотравневе',
  'UA5308009048': 'Петрівка',
  'UA5308009049': 'Петро-Ганнівка',
  'UA5308009052': 'Підозірка',
  'UA5308009053': 'Пірки',
  'UA5308009054': 'Піщанка',
  'UA5308009055': 'Покровське',
  'UA5308009056': 'Проценки',
  'UA5308009050': 'Пилипенки',
  'UA5308009051': 'Пишненки',
  'UA5308009058': 'Романівка',
  'UA5308009057': 'Романи',
  'UA5308009059': 'Руденки-Гончарі',
  'UA5308009060': 'Саранчівка',
  'UA5308009081': 'Шенгаріївка',
  'UA5308009080': 'Шевченки',
  'UA5308009083': 'Шкурпели',
  'UA5308009082': 'Шилівка',
  'UA5308009063': 'Слинківщина',
  'UA5308009064': 'Соколівщина',
  'UA5308009066': 'Стара Михайлівка',
  'UA5308009065': 'Ставкове',
  'UA5308009067': 'Стрілевщина',
  'UA5308009068': 'Ступки',
  'UA5308009069': 'Сулими',
  'UA5308009061': 'Свічкарівщина',
  'UA5308009062': 'Сиверинівка',
  'UA5308009070': 'Тарасівка',
  'UA5308009072': 'Троянівка',
  'UA5308009078': 'Цвітове',
  'UA5308009071': 'Тимченки',
  'UA5308009073': 'Удовиченки',
  'UA5308009007': 'Василе-Устимівка',
  'UA5308009008': 'Василькове',
  'UA5308009009': 'Велика Павлівка',
  'UA5308009010': 'Велика Пожарня',
  'UA5308009012': 'Власівка',
  'UA5308009011': 'Високе',
  'UA5308009084': 'Яцине-Окарі',
  'UA5308009026': 'Загрунівка',
  'UA5308009027': 'Зайці',
  'UA5308009001': 'Зіньків',
  'UA5604001002': 'Аршичин',
  'UA5604001003': 'Баболоки',
  'UA5604001001': 'Бокійма',
  'UA5604001006': 'Головчиці',
  'UA5604001014': 'Хорупань',
  'UA5604001007': 'Клин',
  'UA5604001008': 'Козирщина',
  'UA5604001009': 'Красне',
  'UA5604001010': 'Мятин',
  'UA5604001011': 'Пекалів',
  'UA5604001012': 'Рудливе',
  'UA5604001013': 'Смордва',
  'UA5604001004': 'Війниця',
  'UA5604001005': 'Вовничі',
  'UA5604003002': 'Берестечко',
  'UA5604003003': 'Більче',
  'UA5604003001': 'Боремель',
  'UA5604003005': 'Малеве',
  'UA5604003006': 'Набережне',
  'UA5604003008': 'Новий Тік',
  'UA5604003007': 'Ниви-Золочівські',
  'UA5604003009': 'Пашева',
  'UA5604003011': 'Шибин',
  'UA5604003010': 'Смиків',
  'UA5604003004': 'Золочівка',
  'UA5604009001': 'Демидівка',
  'UA5604009007': 'Дубляни',
  'UA5604009006': 'Глибока Долина',
  'UA5604009008': 'Ільпибоки',
  'UA5604009009': 'Калинівка',
  'UA5604009023': 'Хрінники',
  'UA5604009010': 'Княгинине',
  'UA5604009011': 'Копань',
  'UA5604009013': 'Лішня',
  'UA5604009014': 'Лопавше',
  'UA5604009012': 'Лисин',
  'UA5604009016': 'Охматків',
  'UA5604009015': 'Острів',
  'UA5604009017': 'Перекалі',
  'UA5604009018': 'Пляшева',
  'UA5604009019': 'Рогізне',
  'UA5604009020': 'Рудка',
  'UA5604009021': 'Солонів',
  'UA5604009022': 'Товпижин',
  'UA5604009002': 'Вербень',
  'UA5604009005': 'Вовковиї',
  'UA5604009003': 'Вичавки',
  'UA5604009004': 'Вишневе',
  'UA5604011001': 'Дубно',
  'UA5604013002': 'Березини',
  'UA5604013003': 'Білогорівка',
  'UA5604013004': 'Бригадирівка',
  'UA5604013008': 'Добривода',
  'UA5604013009': 'Дубини',
  'UA5604013005': 'Гай',
  'UA5604013006': 'Гранівка',
  'UA5604013007': 'Гусари',
  'UA5604013011': 'Іванівка',
  'UA5604013012': 'Іващуки',
  'UA5604013001': 'Козин',
  'UA5604013013': 'Курсики',
  'UA5604013014': 'Нова Пляшева',
  'UA5604013015': 'Пасіки',
  'UA5604013016': 'Підвисоке',
  'UA5604013017': 'Пустоіванне',
  'UA5604013018': 'Радихівщина',
  'UA5604013019': 'Рудня',
  'UA5604013020': 'Савчуки',
  'UA5604013021': 'Середнє',
  'UA5604013022': 'Станіслави',
  'UA5604013010': 'Зарічне',
  'UA5604015002': 'Баранне',
  'UA5604015003': 'Боратин',
  'UA5604015009': 'Довгалівка',
  'UA5604015005': 'Гайки',
  'UA5604015006': 'Гайки-Ситенські',
  'UA5604015007': 'Гнильче',
  'UA5604015008': 'Гоноратка',
  'UA5604015012': 'Карпилівка',
  'UA5604015025': 'Хотин',
  'UA5604015013': 'Коритне',
  'UA5604015014': 'Коти',
  'UA5604015001': 'Крупець',
  'UA5604015016': 'Михайлівка',
  'UA5604015015': 'Митниця',
  'UA5604015017': 'Нова Митниця',
  'UA5604015018': 'Пляшівка',
  'UA5604015019': 'Полуничне',
  'UA5604015020': 'Рідків',
  'UA5604015022': 'Срібне',
  'UA5604015021': 'Ситне',
  'UA5604015023': 'Табачуки',
  'UA5604015024': 'Теслугів',
  'UA5604015004': 'Веселе',
  'UA5604015010': 'Заміщина',
  'UA5604015011': 'Засув',
  'UA5604019002': 'Береги',
  'UA5604019003': 'Богушівка',
  'UA5604019004': 'Брищі',
  'UA5604019007': 'Добрятин',
  'UA5604019009': 'Долина',
  'UA5604019008': 'Довгошиї',
  'UA5604019006': 'Гончариха',
  'UA5604019010': 'Іванівка',
  'UA5604019011': 'Іванківці',
  'UA5604019012': 'Коблин',
  'UA5604019013': 'Кораблище',
  'UA5604019014': 'Косареве',
  'UA5604019015': 'Лукарівка',
  'UA5604019016': 'Малі Дорогостаї',
  'UA5604019017': 'Мальоване',
  'UA5604019018': 'Мантин',
  'UA5604019019': 'Маслянка',
  'UA5604019001': 'Млинів',
  'UA5604019021': 'Мошків',
  'UA5604019020': 'Московщина',
  'UA5604019024': 'Новоселівка',
  'UA5604019022': 'Новина-Добрятинська',
  'UA5604019023': 'Новини',
  'UA5604019026': 'Остріїв',
  'UA5604019025': 'Озліїв',
  'UA5604019028': 'Перемилівка',
  'UA5604019027': 'Перевередів',
  'UA5604019029': 'Підгайці',
  'UA5604019030': 'Пітушків',
  'UA5604019031': 'Посників',
  'UA5604019032': 'Привітне',
  'UA5604019033': 'Пугачівка',
  'UA5604019034': 'Радів',
  'UA5604019035': 'Річище',
  'UA5604019036': 'Стоморги',
  'UA5604019037': 'Терешів',
  'UA5604019038': 'Травневе',
  'UA5604019039': 'Тушебин',
  'UA5604019041': 'Улянівка',
  'UA5604019040': 'Ужинець',
  'UA5604019005': 'Владиславівка',
  'UA5604017002': 'Білоберіжжя',
  'UA5604017003': 'Боцянівка',
  'UA5604017005': 'Княгинин',
  'UA5604017006': 'Костянець',
  'UA5604017007': 'Липа',
  'UA5604017008': 'Листвин',
  'UA5604017010': 'Мокре',
  'UA5604017011': 'Молодіжне',
  'UA5604017001': 'Мирогоща Друга',
  'UA5604017009': 'Мирогоща Перша',
  'UA5604017012': 'Нараїв',
  'UA5604017013': 'Острів',
  'UA5604017014': 'Травневе',
  'UA5604017004': 'Заруддя',
  'UA5604021002': 'Бакорин',
  'UA5604021003': 'Борбин',
  'UA5604021008': 'Малин',
  'UA5604021009': 'Новосілки',
  'UA5604021001': 'Острожець',
  'UA5604021010': 'Певжа',
  'UA5604021012': 'П\'яннє',
  'UA5604021011': 'Підгай',
  'UA5604021013': 'Ставище',
  'UA5604021014': 'Уїздці',
  'UA5604021005': 'Заболоття',
  'UA5604021004': 'Заболотинці',
  'UA5604021006': 'Залав\'я',
  'UA5604021007': 'Зборів',
  'UA5604023005': 'Лихачівка',
  'UA5604023006': 'Нове',
  'UA5604023001': 'Підлозці',
  'UA5604023007': 'Ставрів',
  'UA5604023008': 'Топілля',
  'UA5604023009': 'Торговиця',
  'UA5604023002': 'Велике',
  'UA5604023004': 'Загатинці',
  'UA5604023003': 'Завалля',
  'UA5604025002': 'Будераж',
  'UA5604025003': 'Буди',
  'UA5604025004': 'Гнатівка',
  'UA5604025005': 'Миколаївка',
  'UA5604025006': 'Мильча',
  'UA5604025008': 'Підбрусинь',
  'UA5604025001': 'Повча',
  'UA5604025007': 'Пирятин',
  'UA5604025009': 'Яблунівка',
  'UA5604027002': 'Бортниця',
  'UA5604027016': 'Черешнівка',
  'UA5604027004': 'Дубрівка',
  'UA5604027006': 'Іваннє',
  'UA5604027007': 'Кліщиха',
  'UA5604027008': 'Кривуха',
  'UA5604027009': 'Лебедянка',
  'UA5604027010': 'Малі Сади',
  'UA5604027011': 'Молодаво Друге',
  'UA5604027012': 'Молодаво Перше',
  'UA5604027013': 'Молодаво Третє',
  'UA5604027014': 'Панталія',
  'UA5604027015': 'Придорожне',
  'UA5604027001': 'Привільне',
  'UA5604027003': 'Великі Сади',
  'UA5604027005': 'Зелене',
  'UA5604029002': 'Адамівка',
  'UA5604029003': 'Балки',
  'UA5604029005': 'Башарівка',
  'UA5604029004': 'Батьків',
  'UA5604029006': 'Безодня',
  'UA5604029007': 'Бугаївка',
  'UA5604029009': 'Дружба',
  'UA5604029008': 'Гаї-Лев\'ятинські',
  'UA5604029010': 'Казмірі',
  'UA5604029011': 'Копані',
  'UA5604029012': 'Круки',
  'UA5604029013': 'Лев\'ятин',
  'UA5604029014': 'Малі Гайки',
  'UA5604029015': 'Немирівка',
  'UA5604029016': 'Новоукраїнське',
  'UA5604029017': 'Опарипси',
  'UA5604029018': 'Перенятин',
  'UA5604029020': 'Підлипки',
  'UA5604029019': 'Підзамче',
  'UA5604029021': 'Приски',
  'UA5604029001': 'Радивилів',
  'UA5604029022': 'Сестрятин',
  'UA5604029023': 'Старики',
  'UA5604029024': 'Стоянівка',
  'UA5604031002': 'Бондарі',
  'UA5604031005': 'Довге Поле',
  'UA5604031003': 'Гірники',
  'UA5604031004': 'Грядки',
  'UA5604031010': 'Іваниничі',
  'UA5604031012': 'Кліпець',
  'UA5604031011': 'Клинці',
  'UA5604031013': 'Майдан',
  'UA5604031014': 'Нагоряни',
  'UA5604031001': 'Семидуби',
  'UA5604031015': 'Соснівка',
  'UA5604031016': 'Тростянець',
  'UA5604031017': 'Ясинівка',
  'UA5604031006': 'Залужжя',
  'UA5604031007': 'Збитин',
  'UA5604031008': 'Здовбиця',
  'UA5604031009': 'Злинець',
  'UA5604033002': 'Берег',
  'UA5604033003': 'Буща',
  'UA5604033004': 'Голуби',
  'UA5604033005': 'Комарівка',
  'UA5604033006': 'Мартинівка',
  'UA5604033007': 'Миньківці',
  'UA5604033008': 'Нова Миколаївка',
  'UA5604033009': 'Онишківці',
  'UA5604033010': 'Сапанівчик',
  'UA5604033014': 'Шепетин',
  'UA5604033001': 'Смига',
  'UA5604033011': 'Стара Миколаївка',
  'UA5604033012': 'Студянка',
  'UA5604033013': 'Тур\'я',
  'UA5604035002': 'Бірки',
  'UA5604035004': 'Дитиничі',
  'UA5604035006': 'Кам\'яниця',
  'UA5604035007': 'Клюки',
  'UA5604035008': 'Малі Загірці',
  'UA5604035009': 'Микитичі',
  'UA5604035010': 'Нова Носовиця',
  'UA5604035011': 'Переросля',
  'UA5604035012': 'Підлужжя',
  'UA5604035013': 'Плоска',
  'UA5604035014': 'Птича',
  'UA5604035015': 'Рачин',
  'UA5604035016': 'Стара Носовиця',
  'UA5604035017': 'Судобичі',
  'UA5604035001': 'Тараканів',
  'UA5604035018': 'Турковичі',
  'UA5604035003': 'Великі Загірці',
  'UA5604035005': 'Замчисько',
  'UA5604005002': 'Дядьковичі',
  'UA5604005006': 'Копани',
  'UA5604005007': 'Крилів',
  'UA5604005005': 'Квітневе',
  'UA5604005008': 'Маяки',
  'UA5604005009': 'Нагірне',
  'UA5604005011': 'Олибів',
  'UA5604005010': 'Озеряни',
  'UA5604005012': 'Сатиїв',
  'UA5604005001': 'Варковичі',
  'UA5604005004': 'Зелений Гай',
  'UA5604005003': 'Жорнів',
  'UA5604007002': 'Білогородка',
  'UA5604007003': 'Дубовиця',
  'UA5604007005': 'Кам\'яна Верба',
  'UA5604007006': 'Рідкодуби',
  'UA5604007007': 'Софіївка Друга',
  'UA5604007008': 'Софіївка Перша',
  'UA5604007009': 'Стовпець',
  'UA5604007001': 'Верба',
  'UA5604007004': 'Забірки',
  'UA5604037002': 'Боремець',
  'UA5604037009': 'Чекно',
  'UA5604037005': 'Надчиці',
  'UA5604037006': 'Новоукраїнка',
  'UA5604037007': 'Підлісці',
  'UA5604037008': 'Свищів',
  'UA5604037003': 'Велика Городниця',
  'UA5604037010': 'Яловичі',
  'UA5604037001': 'Ярославичі',
  'UA5604037004': 'Зоряне',
  'UA5606001001': 'Бабин',
  'UA5606001003': 'Дмитрівка',
  'UA5606001004': 'Дорогобуж',
  'UA5606001002': 'Горбаків',
  'UA5606001005': 'Іллін',
  'UA5606001006': 'Мнишин',
  'UA5606001007': 'Підліски',
  'UA5606001008': 'Подоляни',
  'UA5606001009': 'Рясники',
  'UA5606001011': 'Шкарів',
  'UA5606001010': 'Томахів',
  'UA5606003002': 'Антонівка',
  'UA5606003003': 'Балашівка',
  'UA5606003001': 'Березне',
  'UA5606003005': 'Білка',
  'UA5606003006': 'Богуші',
  'UA5606003004': 'Бистричі',
  'UA5606003016': 'Друхів',
  'UA5606003012': 'Голубне',
  'UA5606003013': 'Городище',
  'UA5606003014': 'Грушівка',
  'UA5606003015': 'Грушівська Гута',
  'UA5606003019': 'Кам\'янка',
  'UA5606003034': 'Хотин',
  'UA5606003020': 'Князівка',
  'UA5606003021': 'Колодязне',
  'UA5606003022': 'Кургани',
  'UA5606003023': 'Лінчин',
  'UA5606003025': 'Моквин',
  'UA5606003024': 'Михалин',
  'UA5606003026': 'Новий Моквин',
  'UA5606003028': 'Орлівка',
  'UA5606003027': 'Озірці',
  'UA5606003029': 'Підгало',
  'UA5606003030': 'Поліське',
  'UA5606003031': 'Прислуч',
  'UA5606003032': 'Сівки',
  'UA5606003033': 'Тишиця',
  'UA5606003007': 'Ведмедівка',
  'UA5606003008': 'Велика Купля',
  'UA5606003009': 'Велике Поле',
  'UA5606003010': 'Вільхівка',
  'UA5606003011': 'Вітковичі',
  'UA5606003035': 'Яблунне',
  'UA5606003036': 'Ялинівка',
  'UA5606003037': 'Яцьковичі',
  'UA5606003017': 'Замостище',
  'UA5606003018': 'Зірне',
  'UA5606005002': 'Антопіль',
  'UA5606005001': 'Біла Криниця',
  'UA5606005007': 'Дуби',
  'UA5606005003': 'Глинки',
  'UA5606005006': 'Городище',
  'UA5606005004': 'Гориньград Другий',
  'UA5606005005': 'Гориньград Перший',
  'UA5606005008': 'Котів',
  'UA5606005009': 'Кругле',
  'UA5606005010': 'Рисв\'янка',
  'UA5606005011': 'Шубків',
  'UA5606007002': 'Башине',
  'UA5606007001': 'Бугрин',
  'UA5606007005': 'Колесники',
  'UA5606007006': 'М\'ятин',
  'UA5606007007': 'Новоставці',
  'UA5606007008': 'Олексіївка',
  'UA5606007009': 'Посягва',
  'UA5606007010': 'Сергіївка',
  'UA5606007011': 'Угільці',
  'UA5606007003': 'Вільгір',
  'UA5606007012': 'Ясне',
  'UA5606007004': 'Зарічне',
  'UA5606019002': 'Бичаль',
  'UA5606019001': 'Деражне',
  'UA5606019004': 'Дюксин',
  'UA5606019003': 'Ганнівка',
  'UA5606019006': 'Перелисянка',
  'UA5606019007': 'Постійне',
  'UA5606019008': 'Соломка',
  'UA5606019009': 'Суськ',
  'UA5606019005': 'Жильжа',
  'UA5606021001': 'Дядьковичі',
  'UA5606021005': 'Дворовичі',
  'UA5606021004': 'Гуменники',
  'UA5606021007': 'Іваничі',
  'UA5606021008': 'Кривичі',
  'UA5606021009': 'Макотерти',
  'UA5606021010': 'Малий Шпаків',
  'UA5606021011': 'Милостів',
  'UA5606021012': 'Переділи',
  'UA5606021013': 'Пересопниця',
  'UA5606021014': 'Підгірці',
  'UA5606021015': 'Плоска',
  'UA5606021016': 'Шостаків',
  'UA5606021002': 'Великий Шпаків',
  'UA5606021003': 'Верхівськ',
  'UA5606021017': 'Ясининичі',
  'UA5606021006': 'Заріцьк',
  'UA5606013002': 'Базальтове',
  'UA5606013003': 'Берестовець',
  'UA5606013012': 'Чудви',
  'UA5606013001': 'Головин',
  'UA5606013007': 'Іваничі',
  'UA5606013008': 'Корчин',
  'UA5606013009': 'Перетоки',
  'UA5606013010': 'Садки',
  'UA5606013011': 'Ставок',
  'UA5606013004': 'Вигін',
  'UA5606013006': 'Злазне',
  'UA5606013005': 'Звіздівка',
  'UA5606015002': 'Білівські Хутори',
  'UA5606015003': 'Бронники',
  'UA5606015001': 'Городок',
  'UA5606015004': 'Караєвичі',
  'UA5606015005': 'Карпилівка',
  'UA5606015006': 'Метків',
  'UA5606015007': 'Михайлівка',
  'UA5606015008': 'Обарів',
  'UA5606015009': 'Понебель',
  'UA5606015010': 'Рогачів',
  'UA5606015011': 'Рубче',
  'UA5606015012': 'Ставки',
  'UA5606017002': 'Андрусіїв',
  'UA5606017003': 'Бочаниця',
  'UA5606017039': 'Чудниця',
  'UA5606017010': 'Дроздів',
  'UA5606017011': 'Дружне',
  'UA5606017012': 'Дуліби',
  'UA5606017037': 'Федорівка',
  'UA5606017038': 'Франівка',
  'UA5606017008': 'Глибочок',
  'UA5606017009': 'Горбів',
  'UA5606017001': 'Гоща',
  'UA5606017015': 'Красносілля',
  'UA5606017016': 'Кринички',
  'UA5606017017': 'Курозвани',
  'UA5606017019': 'Люцинів',
  'UA5606017018': 'Липки',
  'UA5606017020': 'Майків',
  'UA5606017022': 'Малятин',
  'UA5606017021': 'Малинівка',
  'UA5606017023': 'Матіївка',
  'UA5606017026': 'Мощони',
  'UA5606017025': 'Мичів',
  'UA5606017024': 'Микулин',
  'UA5606017027': 'Пашуки',
  'UA5606017028': 'Полівці',
  'UA5606017029': 'Пустомити',
  'UA5606017030': 'Річиця',
  'UA5606017031': 'Русивель',
  'UA5606017032': 'Садове',
  'UA5606017033': 'Симонів',
  'UA5606017034': 'Синів',
  'UA5606017035': 'Терентіїв',
  'UA5606017036': 'Тучин',
  'UA5606017006': 'Воронів',
  'UA5606017007': 'Воскодави',
  'UA5606017005': 'Вовкошів',
  'UA5606017004': 'Витків',
  'UA5606017040': 'Ючин',
  'UA5606017014': 'Жалянка',
  'UA5606017013': 'Жаврів',
  'UA5606029001': 'Клевань',
  'UA5606029004': 'Мочулки',
  'UA5606029002': 'Оржів',
  'UA5606029005': 'Руда-Красна',
  'UA5606029006': 'Углище',
  'UA5606029003': 'Жобрин',
  'UA5606031002': 'Богданівка',
  'UA5606031003': 'Бриків',
  'UA5606031034': 'Черниця',
  'UA5606031010': 'Даничів',
  'UA5606031011': 'Дерманка',
  'UA5606031032': 'Франкопіль',
  'UA5606031006': 'Ганнівка',
  'UA5606031009': 'Головниця',
  'UA5606031008': 'Голичівка',
  'UA5606031007': 'Гвіздів',
  'UA5606031015': 'Калинівка',
  'UA5606031033': 'Харалуг',
  'UA5606031017': 'Коловерти',
  'UA5606031018': 'Копитів',
  'UA5606031001': 'Корець',
  'UA5606031019': 'Користь',
  'UA5606031016': 'Козак',
  'UA5606031020': 'Крилів',
  'UA5606031021': 'Мала Клецька',
  'UA5606031022': 'Морозівка',
  'UA5606031023': 'Новий Корець',
  'UA5606031024': 'Новини',
  'UA5606031025': 'Річечина',
  'UA5606031026': 'Річки',
  'UA5606031027': 'Сапожин',
  'UA5606031028': 'Старий Корець',
  'UA5606031029': 'Сторожів',
  'UA5606031030': 'Топча',
  'UA5606031031': 'Устя',
  'UA5606031004': 'Велика Клецька',
  'UA5606031005': 'Весняне',
  'UA5606031013': 'Забара',
  'UA5606031014': 'Залізниця',
  'UA5606031012': 'Жадківка',
  'UA5606033003': 'Колоденка',
  'UA5606033001': 'Корнин',
  'UA5606033004': 'Порозове',
  'UA5606033005': 'Тайкури',
  'UA5606033002': 'Загороща',
  'UA5606035002': 'Брюшків',
  'UA5606035007': 'Гута',
  'UA5606035011': 'Комарівка',
  'UA5606035012': 'Корчів\'я',
  'UA5606035013': 'Космачів',
  'UA5606035001': 'Костопіль',
  'UA5606035014': 'Ледне',
  'UA5606035015': 'Майдан',
  'UA5606035016': 'Малий Мидськ',
  'UA5606035017': 'Малий Стидин',
  'UA5606035018': 'Мар\'янівка',
  'UA5606035019': 'Моквинські Хутори',
  'UA5606035020': 'Олександрівка',
  'UA5606035021': 'Осова',
  'UA5606035022': 'Пеньків',
  'UA5606035023': 'Підлужне',
  'UA5606035024': 'Пісків',
  'UA5606035025': 'Рокитне',
  'UA5606035026': 'Рудня',
  'UA5606035027': 'Тростянець',
  'UA5606035028': 'Трубиці',
  'UA5606035003': 'Велика Любаша',
  'UA5606035004': 'Великий Мидськ',
  'UA5606035005': 'Великий Стидин',
  'UA5606035006': 'Волиця',
  'UA5606035029': 'Яполоть',
  'UA5606035030': 'Яснобір',
  'UA5606035009': 'Збуж',
  'UA5606035008': 'Жалин',
  'UA5606035010': 'Золотолин',
  'UA5606039002': 'Борщівка',
  'UA5606039004': 'Данчиміст',
  'UA5606039003': 'Глажева',
  'UA5606039005': 'Кам\'яна Гора',
  'UA5606039006': 'Лісопіль',
  'UA5606039001': 'Мала Любаша',
  'UA5606039007': 'Маща',
  'UA5606039009': 'Моквин',
  'UA5606039008': 'Мирне',
  'UA5606039010': 'Новий Берестовець',
  'UA5606039011': 'Тихе',
  'UA5606037002': 'Бронне',
  'UA5606037003': 'Карачун',
  'UA5606037004': 'Малушка',
  'UA5606037001': 'Малинськ',
  'UA5606037005': 'Поляни',
  'UA5606037006': 'Яринівка',
  'UA5606041002': 'Білашів',
  'UA5606041003': 'Борщівка Друга',
  'UA5606041004': 'Борщівка Перша',
  'UA5606041005': 'Будераж',
  'UA5606041006': 'Буща',
  'UA5606041007': 'Дермань Друга',
  'UA5606041008': 'Дермань Перша',
  'UA5606041011': 'Клопіт',
  'UA5606041012': 'Мала Мощаниця',
  'UA5606041001': 'Мізоч',
  'UA5606041013': 'Мізочок',
  'UA5606041014': 'Мости',
  'UA5606041015': 'Нова Мощаниця',
  'UA5606041016': 'Новий Світ',
  'UA5606041017': 'Озерко',
  'UA5606041019': 'Підгайне',
  'UA5606041018': 'Півче',
  'UA5606041021': 'Спасів',
  'UA5606041022': 'Стара Мощаниця',
  'UA5606041023': 'Стеблівка',
  'UA5606041024': 'Ступно',
  'UA5606041025': 'Суйми',
  'UA5606041020': 'Святе',
  'UA5606041026': 'Цурків',
  'UA5606041009': 'Залібівка',
  'UA5606041010': 'Зелений Дуб',
  'UA5606043002': 'Боянівка',
  'UA5606043005': 'Козлин',
  'UA5606043006': 'Кустин',
  'UA5606043007': 'Нова Любомирка',
  'UA5606043008': 'Нова Українка',
  'UA5606043001': 'Олександрія',
  'UA5606043009': 'Пухова',
  'UA5606043010': 'Радиславка',
  'UA5606043011': 'Ремель',
  'UA5606043012': 'Решуцьк',
  'UA5606043014': 'Сергіївка',
  'UA5606043013': 'Свяття',
  'UA5606043015': 'Три Копці',
  'UA5606043003': 'Волошки',
  'UA5606043004': 'Забороль',
  'UA5606045002': 'Бадівка',
  'UA5606045003': 'Батьківці',
  'UA5606045004': 'Білашів',
  'UA5606045005': 'Болотківці',
  'UA5606045006': 'Бродів',
  'UA5606045007': 'Бродівське',
  'UA5606045008': 'Бубнівка',
  'UA5606045009': 'Бухарів',
  'UA5606045055': 'Черняхів',
  'UA5606045018': 'Данилівка',
  'UA5606045019': 'Дерев\'янче',
  'UA5606045020': 'Дубини',
  'UA5606045015': 'Гай',
  'UA5606045016': 'Грем\'яче',
  'UA5606045017': 'Грозів',
  'UA5606045023': 'Ілляшівка',
  'UA5606045053': 'Хорів',
  'UA5606045054': 'Хрінів',
  'UA5606045024': 'Країв',
  'UA5606045025': 'Кураж',
  'UA5606045026': 'Кургани',
  'UA5606045027': 'Кутянка',
  'UA5606045028': 'Лебеді',
  'UA5606045029': 'Лючин',
  'UA5606045030': 'Межиріч',
  'UA5606045034': 'Могиляни',
  'UA5606045035': 'Мощаниця',
  'UA5606045032': 'Михайлівка',
  'UA5606045033': 'Михалківці',
  'UA5606045031': 'Милятин',
  'UA5606045036': 'Новомалин',
  'UA5606045037': 'Новородчиці',
  'UA5606045001': 'Острог',
  'UA5606045038': 'Оженин',
  'UA5606045039': 'Плоске',
  'UA5606045041': 'Почапки',
  'UA5606045040': 'Посива',
  'UA5606045042': 'Прикордонне',
  'UA5606045043': 'Радужне',
  'UA5606045044': 'Розваж',
  'UA5606045045': 'Садки',
  'UA5606045056': 'Шлях',
  'UA5606045046': 'Сіянці',
  'UA5606045047': 'Слобідка',
  'UA5606045048': 'Стадники',
  'UA5606045049': 'Теремне',
  'UA5606045050': 'Тесів',
  'UA5606045051': 'Точевики',
  'UA5606045052': 'Українка',
  'UA5606045010': 'Вельбівно',
  'UA5606045011': 'Верхів',
  'UA5606045013': 'Вілія',
  'UA5606045014': 'Волосківці',
  'UA5606045012': 'Вишеньки',
  'UA5606045021': 'Завидів',
  'UA5606045022': 'Завизів',
  'UA5606047002': 'Квасилів',
  'UA5606047001': 'Рівне',
  'UA5606051002': 'Бармаки',
  'UA5606051008': 'Ходоси',
  'UA5606051009': 'Хотин',
  'UA5606051007': 'Малий Олексин',
  'UA5606051006': 'Малий Житин',
  'UA5606051001': 'Шпанів',
  'UA5606051004': 'Великий Олексин',
  'UA5606051003': 'Великий Житин',
  'UA5606051005': 'Зозів',
  'UA5606049002': 'Адамівка',
  'UA5606049003': 'Більчаки',
  'UA5606049006': 'Глибочок',
  'UA5606049007': 'Губків',
  'UA5606049008': 'Іванівка',
  'UA5606049012': 'Хмелівка',
  'UA5606049009': 'Маринин',
  'UA5606049010': 'Мочулянка',
  'UA5606049001': 'Соснове',
  'UA5606049011': 'Совпа',
  'UA5606049004': 'Великі Селища',
  'UA5606049005': 'Вілля',
  'UA5606009002': 'Березівка',
  'UA5606009003': 'Бокшин',
  'UA5606009004': 'Бранів',
  'UA5606009006': 'Дивень',
  'UA5606009005': 'Городище',
  'UA5606009009': 'Іванівка',
  'UA5606009010': 'Колодіївка',
  'UA5606009011': 'Мала Совпа',
  'UA5606009012': 'Невірків',
  'UA5606009013': 'Самостріли',
  'UA5606009016': 'Щекичин',
  'UA5606009015': 'Стовпин',
  'UA5606009014': 'Світанок',
  'UA5606009001': 'Великі Межирічі',
  'UA5606009008': 'Застав\'я',
  'UA5606009007': 'Жорнівка',
  'UA5606011005': 'Дібрівка',
  'UA5606011003': 'Грушвиця Друга',
  'UA5606011004': 'Грушвиця Перша',
  'UA5606011006': 'Мартинівка',
  'UA5606011001': 'Велика Омеляна',
  'UA5606011002': 'Вересневе',
  'UA5606025002': 'Богдашів',
  'UA5606025003': 'Глинськ',
  'UA5606025005': 'Ільпінь',
  'UA5606025006': 'Копиткове',
  'UA5606025007': 'Кошатів',
  'UA5606025008': 'Мар\'янівка',
  'UA5606025009': 'Новомильськ',
  'UA5606025010': 'Новосілки',
  'UA5606025011': 'Орестів',
  'UA5606025013': 'П\'ятигори',
  'UA5606025012': 'Підцурків',
  'UA5606025014': 'Степанівка',
  'UA5606025004': 'Загора',
  'UA5606025001': 'Здолбунів',
  'UA5606023003': 'Гільча Друга',
  'UA5606023004': 'Гільча Перша',
  'UA5606023008': 'Івачків',
  'UA5606023010': 'Коршів',
  'UA5606023011': 'Кунин',
  'UA5606023012': 'Лідаво',
  'UA5606023013': 'Миротин',
  'UA5606023014': 'Уїздці',
  'UA5606023015': 'Урвенна',
  'UA5606023002': 'В\'юнівщина',
  'UA5606023009': 'Йосипівка',
  'UA5606023005': 'Загребля',
  'UA5606023006': 'Залісся',
  'UA5606023007': 'Замлинок',
  'UA5606023001': 'Здовбиця',
  'UA5606027002': 'Білів',
  'UA5606027005': 'Дерев\'яне',
  'UA5606027006': 'Диків',
  'UA5606027003': 'Голишів',
  'UA5606027004': 'Грабів',
  'UA5606027009': 'Новостав',
  'UA5606027010': 'Новостав-Дальній',
  'UA5606027008': 'Новожуків',
  'UA5606027011': 'Олишва',
  'UA5606027012': 'Радухівка',
  'UA5606027013': 'Сморжів',
  'UA5606027014': 'Старожуків',
  'UA5606027015': 'Сухівці',
  'UA5606027007': 'Застав\'я',
  'UA5606027001': 'Зоря',
  'UA5608001001': 'Березове',
  'UA5608001002': 'Будки-Кам\'янські',
  'UA5608001005': 'Дубно',
  'UA5608001003': 'Глинне',
  'UA5608001004': 'Грабунь',
  'UA5608001007': 'Кам\'яне',
  'UA5608001010': 'Хміль',
  'UA5608001008': 'Обсіч',
  'UA5608001009': 'Познань',
  'UA5608001006': 'Заболоття',
  'UA5608007004': 'Берестя',
  'UA5608007002': 'Бережки',
  'UA5608007003': 'Бережниця',
  'UA5608007005': 'Білаші',
  'UA5608007001': 'Дубровиця',
  'UA5608007010': 'Грані',
  'UA5608007011': 'Грицьки',
  'UA5608007017': 'Колки',
  'UA5608007019': 'Крупове',
  'UA5608007018': 'Кривиця',
  'UA5608007020': 'Кураш',
  'UA5608007021': 'Лісове',
  'UA5608007022': 'Літвиця',
  'UA5608007023': 'Лютинськ',
  'UA5608007024': 'Мочулище',
  'UA5608007025': 'Нивецьк',
  'UA5608007027': 'Орв\'яниця',
  'UA5608007028': 'Осова',
  'UA5608007029': 'Острівці',
  'UA5608007026': 'Озерськ',
  'UA5608007030': 'Підлісне',
  'UA5608007031': 'Порубка',
  'UA5608007032': 'Працюки',
  'UA5608007033': 'Різки',
  'UA5608007035': 'Селець',
  'UA5608007039': 'Шахи',
  'UA5608007036': 'Соломіївка',
  'UA5608007034': 'Сварицевичі',
  'UA5608007037': 'Трипутня',
  'UA5608007038': 'Узлісся',
  'UA5608007008': 'Велюнь',
  'UA5608007007': 'Великі Озера',
  'UA5608007006': 'Великий Черемель',
  'UA5608007009': 'Вільне',
  'UA5608007040': 'Ясинець',
  'UA5608007012': 'Загребля',
  'UA5608007013': 'Залішани',
  'UA5608007014': 'Залужжя',
  'UA5608007015': 'Заслуччя',
  'UA5608007016': 'Зелень',
  'UA5608009002': 'Карасин',
  'UA5608009003': 'Карпилівка',
  'UA5608009001': 'Клесів',
  'UA5608009004': 'Клесів',
  'UA5608009005': 'Пугач',
  'UA5608009006': 'Рудня-Карпилівська',
  'UA5608009007': 'Страшеве',
  'UA5608011002': 'Біле',
  'UA5608011003': 'Будимля',
  'UA5608011009': 'Хочин',
  'UA5608011005': 'Лугове',
  'UA5608011001': 'Миляч',
  'UA5608011006': 'Переброди',
  'UA5608011007': 'Смородськ',
  'UA5608011008': 'Удрицьк',
  'UA5608011004': 'Жадень',
  'UA5608013002': 'Гута-Перейма',
  'UA5608013004': 'Катеринівка',
  'UA5608013001': 'Немовичі',
  'UA5608013006': 'Немовичі',
  'UA5608013005': 'Тинне',
  'UA5608013003': 'Зносичі',
  'UA5608015003': 'Біловіж',
  'UA5608015004': 'Більськ',
  'UA5608015005': 'Блажове',
  'UA5608015006': 'Борове',
  'UA5608015007': 'Буда',
  'UA5608015008': 'Будки-Сновидовицькі',
  'UA5608015009': 'Дерть',
  'UA5608015012': 'Карпилівка',
  'UA5608015014': 'Купель',
  'UA5608015013': 'Кисоричі',
  'UA5608015015': 'Лісове',
  'UA5608015016': 'Масевичі',
  'UA5608015017': 'Мушні',
  'UA5608015018': 'Нетреба',
  'UA5608015019': 'Олександрівка',
  'UA5608015020': 'Осницьк',
  'UA5608015021': 'Остки',
  'UA5608015001': 'Рокитне',
  'UA5608015022': 'Рокитне',
  'UA5608015023': 'Сновидовичі',
  'UA5608015024': 'Старики',
  'UA5608015002': 'Томашгород',
  'UA5608015025': 'Томашгород',
  'UA5608015010': 'Єльне',
  'UA5608015011': 'Залав\'я',
  'UA5608017002': 'Білятичі',
  'UA5608017003': 'Бутейки',
  'UA5608017036': 'Чемерне',
  'UA5608017011': 'Довге',
  'UA5608017012': 'Дубки',
  'UA5608017009': 'Глушиця',
  'UA5608017008': 'Глушицьке',
  'UA5608017010': 'Городець',
  'UA5608017013': 'Іванівка',
  'UA5608017014': 'Копище',
  'UA5608017015': 'Корост',
  'UA5608017016': 'Костянтинівка',
  'UA5608017017': 'Кричильськ',
  'UA5608017018': 'Любиковичі',
  'UA5608017019': 'Люхча',
  'UA5608017020': 'Мале Вербче',
  'UA5608017021': 'Мар\'янівка',
  'UA5608017022': 'Маслопуща',
  'UA5608017023': 'Обірки',
  'UA5608017024': 'Одринки',
  'UA5608017025': 'Орлівка',
  'UA5608017026': 'Поляна',
  'UA5608017027': 'Ремчиці',
  'UA5608017001': 'Сарни',
  'UA5608017029': 'Стрільськ',
  'UA5608017028': 'Сварині',
  'UA5608017030': 'Тріскині',
  'UA5608017034': 'Цепцевичі',
  'UA5608017031': 'Тутовичі',
  'UA5608017032': 'Убереж',
  'UA5608017033': 'Угли',
  'UA5608017004': 'Велике Вербче',
  'UA5608017005': 'Велихів',
  'UA5608017006': 'Вирка',
  'UA5608017007': 'Висове',
  'UA5608017035': 'Яринівка',
  'UA5608019003': 'Дроздинь',
  'UA5608019004': 'Переходичі',
  'UA5608019001': 'Старе Село',
  'UA5608019002': 'Вежиця',
  'UA5608021004': 'Двірець',
  'UA5608021003': 'Грушівка',
  'UA5608021005': 'Калинівка',
  'UA5608021006': 'Кузьмівка',
  'UA5608021007': 'Мельниця',
  'UA5608021008': 'Підгірник',
  'UA5608021001': 'Степань',
  'UA5608021009': 'Труди',
  'UA5608021002': 'Волоша',
  'UA5608021010': 'Яблунька',
  'UA5608003009': 'Чабель',
  'UA5608003010': 'Чудель',
  'UA5608003003': 'Дубняки',
  'UA5608003008': 'Федорівка',
  'UA5608003002': 'Гранітне',
  'UA5608003005': 'Кам\'яне-Случанське',
  'UA5608003006': 'Олексіївка',
  'UA5608003007': 'Селище',
  'UA5608003001': 'Вири',
  'UA5608003011': 'Ясногірка',
  'UA5608003004': 'Зарів\'я',
  'UA5608005002': 'Бродець',
  'UA5608005004': 'Городище',
  'UA5608005010': 'Хилін',
  'UA5608005006': 'Людинь',
  'UA5608005007': 'Партизанське',
  'UA5608005008': 'Рудня',
  'UA5608005009': 'Тумень',
  'UA5608005003': 'Вербівка',
  'UA5608005001': 'Висоцьк',
  'UA5608005005': 'Золоте',
  'UA5602001001': 'Антонівка',
  'UA5602001004': 'Чаква',
  'UA5602001003': 'Нетреба',
  'UA5602001002': 'Великі Цепцевичі',
  'UA5602009002': 'Дубівка',
  'UA5602009001': 'Каноничі',
  'UA5602009003': 'Кідри',
  'UA5602009004': 'Новаки',
  'UA5602009005': 'Озеро',
  'UA5602011003': 'Дідівка',
  'UA5602011004': 'Дубчиці',
  'UA5602011002': 'Гориничі',
  'UA5602011020': 'Храпин',
  'UA5602011007': 'Котира',
  'UA5602011010': 'Кухче',
  'UA5602011008': 'Кутин',
  'UA5602011009': 'Кутинок',
  'UA5602011011': 'Любинь',
  'UA5602011001': 'Локниця',
  'UA5602011012': 'Млин',
  'UA5602011014': 'Нобель',
  'UA5602011015': 'Новосілля',
  'UA5602011013': 'Ниговищі',
  'UA5602011016': 'Омит',
  'UA5602011017': 'Прикладники',
  'UA5602011018': 'Радове',
  'UA5602011019': 'Сенчиці',
  'UA5602011005': 'Задовже',
  'UA5602011006': 'Заозер\'я',
  'UA5602013002': 'Балаховичі',
  'UA5602013004': 'Іванчі',
  'UA5602013005': 'Маюничі',
  'UA5602013006': 'Острів',
  'UA5602013001': 'Полиці',
  'UA5602013007': 'Ромейки',
  'UA5602013008': 'Сошники',
  'UA5602013003': 'Веретено',
  'UA5602015008': 'Чучеве',
  'UA5602015003': 'Кошмаки',
  'UA5602015004': 'Лозки',
  'UA5602015005': 'Малий Жолудськ',
  'UA5602015006': 'Мостище',
  'UA5602015001': 'Рафалівка',
  'UA5602015007': 'Суховоля',
  'UA5602015002': 'Великий Жолудськ',
  'UA5602003002': 'Бабка',
  'UA5602003003': 'Березина',
  'UA5602003004': 'Більська Воля',
  'UA5602003006': 'Діброва',
  'UA5602003005': 'Городок',
  'UA5602003010': 'Кругле',
  'UA5602003009': 'Кримне',
  'UA5602003011': 'Мульчиці',
  'UA5602003012': 'Озерці',
  'UA5602003013': 'Рудка',
  'UA5602003018': 'Щоків',
  'UA5602003014': 'Собіщиці',
  'UA5602003015': 'Сопачів',
  'UA5602003016': 'Стара Рафалівка',
  'UA5602003017': 'Уріччя',
  'UA5602003001': 'Вараш',
  'UA5602003008': 'Заболоття',
  'UA5602003007': 'Журавлине',
  'UA5602005002': 'Берестівка',
  'UA5602005004': 'Біле',
  'UA5602005003': 'Бишляк',
  'UA5602005022': 'Чудля',
  'UA5602005007': 'Довговоля',
  'UA5602005021': 'Хиночі',
  'UA5602005011': 'Красносілля',
  'UA5602005014': 'Любахи',
  'UA5602005013': 'Луко',
  'UA5602005012': 'Липне',
  'UA5602005015': 'Малі Телковичі',
  'UA5602005016': 'Новосілки',
  'UA5602005017': 'Острівці',
  'UA5602005018': 'Половлі',
  'UA5602005019': 'Радижеве',
  'UA5602005020': 'Степангород',
  'UA5602005005': 'Великі Телковичі',
  'UA5602005001': 'Володимирець',
  'UA5602005006': 'Воронки',
  'UA5602005009': 'Зелене',
  'UA5602005010': 'Зелениця',
  'UA5602005008': 'Жовкині',
  'UA5602007002': 'Бір',
  'UA5602007003': 'Борове',
  'UA5602007004': 'Бродниця',
  'UA5602007005': 'Бутове',
  'UA5602007031': 'Чернин',
  'UA5602007009': 'Дібрівськ',
  'UA5602007008': 'Голубне',
  'UA5602007012': 'Іванчиці',
  'UA5602007013': 'Комори',
  'UA5602007014': 'Коник',
  'UA5602007015': 'Кухітська Воля',
  'UA5602007016': 'Лисичин',
  'UA5602007017': 'Млинок',
  'UA5602007018': 'Морочне',
  'UA5602007019': 'Мутвиця',
  'UA5602007020': 'Неньковичі',
  'UA5602007021': 'Новорічиця',
  'UA5602007022': 'Олександрове',
  'UA5602007023': 'Острівськ',
  'UA5602007024': 'Перекалля',
  'UA5602007025': 'Привітівка',
  'UA5602007027': 'Річки',
  'UA5602007026': 'Річиця',
  'UA5602007028': 'Серники',
  'UA5602007029': 'Соломир',
  'UA5602007030': 'Тиховиж',
  'UA5602007007': 'Вовчиці',
  'UA5602007006': 'Вичівка',
  'UA5602007001': 'Зарічне',
  'UA5602007011': 'Зелена Діброва',
  'UA5602007010': 'Ждань',
  'UA8500000000': 'Севастополь',
  'UA5902001002': 'Анютине',
  'UA5902001003': 'Бережне',
  'UA5902001001': 'Бочечки',
  'UA5902001004': 'Бондарі',
  'UA5902001006': 'Духанівка',
  'UA5902001012': 'Хижки',
  'UA5902001007': 'Козацьке',
  'UA5902001008': 'Новомутин',
  'UA5902001009': 'Прилужжя',
  'UA5902001011': 'Сахни',
  'UA5902001010': 'Савойське',
  'UA5902001013': 'Щекинське',
  'UA5902001005': 'Вовчик',
  'UA5902003002': 'Атаманське',
  'UA5902003003': 'Біжівка',
  'UA5902003004': 'Болотівка',
  'UA5902003005': 'Бондарі',
  'UA5902003006': 'Бошівка',
  'UA5902003001': 'Буринь',
  'UA5902003041': 'Чалищівка',
  'UA5902003043': 'Черепівка',
  'UA5902003042': 'Червона Слобода',
  'UA5902003044': 'Чумакове',
  'UA5902003016': 'Дяківка',
  'UA5902003015': 'Дмитрівка',
  'UA5902003014': 'Дич',
  'UA5902003011': 'Гатка',
  'UA5902003013': 'Голуби',
  'UA5902003012': 'Гвинтове',
  'UA5902003018': 'Ігорівка',
  'UA5902003019': 'Карпенкове',
  'UA5902003020': 'Клепали',
  'UA5902003021': 'Коновалове',
  'UA5902003047': 'Копилове',
  'UA5902003022': 'Коренівка',
  'UA5902003048': 'Кошарське',
  'UA5902003023': 'Кубракове',
  'UA5902003049': 'Леонтіївка',
  'UA5902003025': 'Михайлівка',
  'UA5902003024': 'Миколаївка',
  'UA5902003026': 'Нечаївка',
  'UA5902003030': 'Нотаріусівка',
  'UA5902003028': 'Нова Олександрівка',
  'UA5902003029': 'Новий Мир',
  'UA5902003027': 'Нижня Сагарівка',
  'UA5902003031': 'Олександрівка',
  'UA5902003032': 'Піски',
  'UA5902003033': 'Сапушине',
  'UA5902003045': 'Шевченкове',
  'UA5902003046': 'Шпокалка',
  'UA5902003034': 'Слобода',
  'UA5902003050': 'Сорочинське',
  'UA5902003035': 'Сорока',
  'UA5902003036': 'Степанівка',
  'UA5902003037': 'Суховерхівка',
  'UA5902003038': 'Темне',
  'UA5902003039': 'Тимофіївка',
  'UA5902003040': 'Успенка',
  'UA5902003007': 'Верхня Сагарівка',
  'UA5902003008': 'Вікторинівка',
  'UA5902003010': 'Воскресенка',
  'UA5902003009': 'Вознесенка',
  'UA5902003017': 'Жуківка',
  'UA5902005002': 'Базилівка',
  'UA5902005032': 'Білоусівка',
  'UA5902005031': 'Білозерка',
  'UA5902005003': 'Бурики',
  'UA5902005029': 'Чернеча Слобода',
  'UA5902005028': 'Червоний Яр',
  'UA5902005001': 'Дубов\'язівка',
  'UA5902005009': 'Дубинка',
  'UA5902005007': 'Гамаліївка',
  'UA5902005008': 'Грузьке',
  'UA5902005012': 'Капітанівка',
  'UA5902005013': 'Коханівка',
  'UA5902005014': 'Красне',
  'UA5902005015': 'Курилівка',
  'UA5902005016': 'Лебедєве',
  'UA5902005017': 'Молодівка',
  'UA5902005018': 'Пасьовини',
  'UA5902005019': 'Першотравневе',
  'UA5902005020': 'Полтавка',
  'UA5902005021': 'Рокитне',
  'UA5902005022': 'Романчукове',
  'UA5902005023': 'Салтикове',
  'UA5902005030': 'Шпотівка',
  'UA5902005024': 'Сім\'янівка',
  'UA5902005025': 'Сніжки',
  'UA5902005026': 'Совинка',
  'UA5902005027': 'Тернівка',
  'UA5902005033': 'Тополине',
  'UA5902005006': 'В\'язове',
  'UA5902005004': 'Вишневе',
  'UA5902005005': 'Вишневий Яр',
  'UA5902005011': 'Землянка',
  'UA5902005010': 'Жигайлівка',
  'UA5902007002': 'Калинівка',
  'UA5902007001': 'Конотоп',
  'UA5902007003': 'Лобківка',
  'UA5902007004': 'Підлипне',
  'UA5902009002': 'Алтинівка',
  'UA5902009003': 'Антонівка',
  'UA5902009004': 'Артюхове',
  'UA5902009005': 'Безкровне',
  'UA5902009007': 'Білогриве',
  'UA5902009009': 'Боцманів',
  'UA5902009008': 'Божок',
  'UA5902009010': 'Буйвалове',
  'UA5902009006': 'Бистрик',
  'UA5902009069': 'Червона Гірка',
  'UA5902009020': 'Дідівщина',
  'UA5902009021': 'Добротове',
  'UA5902009022': 'Дубовичі',
  'UA5902009015': 'Горохове',
  'UA5902009016': 'Гречкине',
  'UA5902009018': 'Грузьке',
  'UA5902009017': 'Грибаньове',
  'UA5902009019': 'Губарівщина',
  'UA5902009029': 'Калашинівка',
  'UA5902009030': 'Камінь',
  'UA5902009031': 'Кащенкове',
  'UA5902009067': 'Хоменкове',
  'UA5902009068': 'Хрещатик',
  'UA5902009032': 'Ковбасине',
  'UA5902009001': 'Кролевець',
  'UA5902009033': 'Кубахове',
  'UA5902009034': 'Лапшине',
  'UA5902009037': 'Любитове',
  'UA5902009036': 'Локня',
  'UA5902009073': 'Луч',
  'UA5902009035': 'Литвиновичі',
  'UA5902009038': 'Майорівка',
  'UA5902009039': 'Марухи',
  'UA5902009040': 'Медведеве',
  'UA5902009042': 'Морозівка',
  'UA5902009043': 'Мостище',
  'UA5902009044': 'Мутин',
  'UA5902009041': 'Миколаєнкове',
  'UA5902009074': 'Мирне',
  'UA5902009045': 'Неровнине',
  'UA5902009046': 'Ніжинське',
  'UA5902009047': 'Новоселиця',
  'UA5902009048': 'Обтове',
  'UA5902009049': 'Отрохове',
  'UA5902009050': 'Папкине',
  'UA5902009051': 'Пасіка',
  'UA5902009052': 'Перемога',
  'UA5902009054': 'Погорілівка',
  'UA5902009055': 'Покровське',
  'UA5902009056': 'Прогрес',
  'UA5902009053': 'Пиротчине',
  'UA5902009057': 'Ретик',
  'UA5902009058': 'Реутинці',
  'UA5902009059': 'Сажалки',
  'UA5902009070': 'Шлях',
  'UA5902009061': 'Соломашине',
  'UA5902009062': 'Спаське',
  'UA5902009063': 'Срібровщина',
  'UA5902009060': 'Свидня',
  'UA5902009064': 'Тарасівка',
  'UA5902009065': 'Терехове',
  'UA5902009066': 'Тулиголове',
  'UA5902009011': 'Васильківщина',
  'UA5902009012': 'Веселі Гори',
  'UA5902009013': 'Воргол',
  'UA5902009014': 'Воронцове',
  'UA5902009072': 'Ярославець',
  'UA5902009071': 'Ярове',
  'UA5902009024': 'Заболотове',
  'UA5902009025': 'Загорівка',
  'UA5902009027': 'Заріччя',
  'UA5902009028': 'Заруддя',
  'UA5902009026': 'Зазірки',
  'UA5902009023': 'Жабкине',
  'UA5902011002': 'Бояро-Лежачі',
  'UA5902011003': 'Бруски',
  'UA5902011005': 'Бунякине',
  'UA5902011004': 'Бувалине',
  'UA5902011031': 'Чаплищі',
  'UA5902011032': 'Червоне Озеро',
  'UA5902011010': 'Дорошівка',
  'UA5902011009': 'Гірки',
  'UA5902011012': 'Іванівка',
  'UA5902011013': 'Князівка',
  'UA5902011014': 'Козлівка',
  'UA5902011015': 'Кружок',
  'UA5902011016': 'Линове',
  'UA5902011019': 'Мачулища',
  'UA5902011018': 'Манухівка',
  'UA5902011017': 'Мазівка',
  'UA5902011020': 'Мінакове',
  'UA5902011001': 'Нова Слобода',
  'UA5902011021': 'Нові Гончарі',
  'UA5902011036': 'Новослобідське',
  'UA5902011022': 'Оріхівка',
  'UA5902011037': 'Партизанське',
  'UA5902011023': 'Почепці',
  'UA5902011024': 'Рівне',
  'UA5902011025': 'Сахарове',
  'UA5902011033': 'Ширяєве',
  'UA5902011029': 'Старі Гончарі',
  'UA5902011026': 'Свобода',
  'UA5902011027': 'Сиром\'ятникове',
  'UA5902011030': 'Уцькове',
  'UA5902011006': 'Волинцеве',
  'UA5902011035': 'Волинцівське',
  'UA5902011007': 'Воронівка',
  'UA5902011008': 'Вощинине',
  'UA5902011034': 'Юр\'єве',
  'UA5902011011': 'Жари',
  'UA5902013002': 'Андріївське',
  'UA5902013003': 'Броди',
  'UA5902013037': 'Чорноплатове',
  'UA5902013008': 'Дептівка',
  'UA5902013036': 'Фесівка',
  'UA5902013007': 'Гути',
  'UA5902013010': 'Калишенкове',
  'UA5902013011': 'Карабутове',
  'UA5902013012': 'Кошари',
  'UA5902013013': 'Кузьки',
  'UA5902013014': 'Лисогубівка',
  'UA5902013015': 'Малий Самбір',
  'UA5902013016': 'Мар\'янівка',
  'UA5902013017': 'Мельня',
  'UA5902013018': 'Михайло-Ганнівка',
  'UA5902013020': 'Нечаївське',
  'UA5902013019': 'Нехаївка',
  'UA5902013021': 'Нове',
  'UA5902013022': 'Новоселівка',
  'UA5902013023': 'Озаричі',
  'UA5902013024': 'Пекарі',
  'UA5902013001': 'Попівка',
  'UA5902013026': 'Присеймів\'я',
  'UA5902013025': 'Привокзальне',
  'UA5902013043': 'Питомник',
  'UA5902013027': 'Раки',
  'UA5902013028': 'Сарнавщина',
  'UA5902013029': 'Селище',
  'UA5902013038': 'Шаповалівка',
  'UA5902013044': 'Шевченківське',
  'UA5902013039': 'Шевченкове',
  'UA5902013030': 'Соснівка',
  'UA5902013031': 'Таранське',
  'UA5902013032': 'Торговиця',
  'UA5902013033': 'Тулушка',
  'UA5902013034': 'Турутине',
  'UA5902013035': 'Улянівка',
  'UA5902013004': 'Великий Самбір',
  'UA5902013006': 'Вільне',
  'UA5902013005': 'Вирівка',
  'UA5902013040': 'Юрівка',
  'UA5902013042': 'Залізничне',
  'UA5902013041': 'Заводське',
  'UA5902013009': 'Жолдаки',
  'UA5902015002': 'Білогалиця',
  'UA5902015003': 'Бобине',
  'UA5902015052': 'Чорнобривкине',
  'UA5902015009': 'Голубкове',
  'UA5902015014': 'Іллінське',
  'UA5902015013': 'Іванівське',
  'UA5902015015': 'Кагань',
  'UA5902015016': 'Кардаші',
  'UA5902015050': 'Харівка',
  'UA5902015051': 'Ховзівка',
  'UA5902015020': 'Кочерги',
  'UA5902015018': 'Корольки',
  'UA5902015019': 'Котівка',
  'UA5902015017': 'Козаче',
  'UA5902015021': 'Красне',
  'UA5902015022': 'Кубареве',
  'UA5902015023': 'Курдюмове',
  'UA5902015024': 'Латишівка',
  'UA5902015025': 'Малушине',
  'UA5902015026': 'Мишутине',
  'UA5902015027': 'Нова Шарпівка',
  'UA5902015028': 'Окіп',
  'UA5902015029': 'Пересипки',
  'UA5902015031': 'Пішкове',
  'UA5902015032': 'Плахівка',
  'UA5902015033': 'Плотникове',
  'UA5902015034': 'Пруди',
  'UA5902015001': 'Путивль',
  'UA5902015030': 'Пищикове',
  'UA5902015035': 'Рев\'якине',
  'UA5902015037': 'Роща',
  'UA5902015036': 'Ротівка',
  'UA5902015038': 'Руднєве',
  'UA5902015039': 'Сафонівка',
  'UA5902015040': 'Селезнівка',
  'UA5902015054': 'Щекине',
  'UA5902015055': 'Щербинівка',
  'UA5902015053': 'Шулешівка',
  'UA5902015041': 'Сімейкине',
  'UA5902015042': 'Скуносове',
  'UA5902015043': 'Сонцеве',
  'UA5902015044': 'Спадщина',
  'UA5902015045': 'Стара Шарпівка',
  'UA5902015046': 'Стрільники',
  'UA5902015047': 'Суворове',
  'UA5902015048': 'Товченикове',
  'UA5902015049': 'Трудове',
  'UA5902015004': 'Вегерівка',
  'UA5902015005': 'Веселе',
  'UA5902015008': 'В\'ятка',
  'UA5902015007': 'В\'язенка',
  'UA5902015006': 'Волокитине',
  'UA5902015056': 'Яцине',
  'UA5902015010': 'Зарічне',
  'UA5902015011': 'Зінове',
  'UA5902015012': 'Зозулине',
  'UA5904001001': 'Боромля',
  'UA5904001002': 'Братське',
  'UA5904001004': 'Градське',
  'UA5904001005': 'Гребениківка',
  'UA5904001007': 'Мозкове',
  'UA5904001008': 'Набережне',
  'UA5904001009': 'Новгородське',
  'UA5904001010': 'Пархомівка',
  'UA5904001011': 'Першотравневе',
  'UA5904001012': 'Шевченків Гай',
  'UA5904001003': 'Вовків',
  'UA5904001006': 'Жигайлівка',
  'UA5904015002': 'Бакирівка',
  'UA5904015003': 'Борзівщина',
  'UA5904015004': 'Бугрувате',
  'UA5904015005': 'Буймерівка',
  'UA5904015001': 'Чернеччина',
  'UA5904015011': 'Доброславівка',
  'UA5904015010': 'Гай-Мошенка',
  'UA5904015014': 'Кардашівка',
  'UA5904015031': 'Хухра',
  'UA5904015015': 'Корабельське',
  'UA5904015016': 'Кудряве',
  'UA5904015018': 'Лутище',
  'UA5904015017': 'Литовка',
  'UA5904015019': 'Манчичі',
  'UA5904015022': 'Мошенка',
  'UA5904015021': 'Михайленкове',
  'UA5904015020': 'Мирне',
  'UA5904015023': 'Перемога',
  'UA5904015025': 'Підлозіївка',
  'UA5904015026': 'Пологи',
  'UA5904015027': 'Попелівщина',
  'UA5904015024': 'Пилівка',
  'UA5904015028': 'Риботень',
  'UA5904015029': 'Сонячне',
  'UA5904015030': 'Українка',
  'UA5904015006': 'Вербове',
  'UA5904015007': 'Веселий Гай',
  'UA5904015009': 'Восьме Березня',
  'UA5904015008': 'Високе',
  'UA5904015032': 'Ясенове',
  'UA5904015013': 'Залісне',
  'UA5904015012': 'Журавне',
  'UA5904017002': 'Буро-Рубанівка',
  'UA5904017001': 'Чупахівка',
  'UA5904017007': 'Довжик',
  'UA5904017008': 'Духовниче',
  'UA5904017004': 'Горяйстівка',
  'UA5904017005': 'Грінченкове',
  'UA5904017006': 'Грунька',
  'UA5904017009': 'Комарівка',
  'UA5904017010': 'Коновалик',
  'UA5904017011': 'Лантратівка',
  'UA5904017012': 'Лисе',
  'UA5904017013': 'Нове',
  'UA5904017014': 'Новопостроєне',
  'UA5904017015': 'Оленинське',
  'UA5904017016': 'Олешня',
  'UA5904017017': 'Пасіки',
  'UA5904017018': 'П\'яткине',
  'UA5904017019': 'Розсохувате',
  'UA5904017020': 'Садки',
  'UA5904017021': 'Соборне',
  'UA5904017022': 'Софіївка',
  'UA5904017003': 'Всадки',
  'UA5904005002': 'Аврамківщина',
  'UA5904005003': 'Бандури',
  'UA5904005004': 'Бідани',
  'UA5904005006': 'Гнилиця',
  'UA5904005001': 'Грунь',
  'UA5904005008': 'Івахи',
  'UA5904005009': 'Куземин',
  'UA5904005010': 'Молодецьке',
  'UA5904005011': 'Пластюки',
  'UA5904005013': 'Рубани',
  'UA5904005012': 'Рибальське',
  'UA5904005015': 'Шабалтаєве',
  'UA5904005016': 'Шаповалівка',
  'UA5904005017': 'Шолудьки',
  'UA5904005014': 'Скелька',
  'UA5904005005': 'В\'язове',
  'UA5904005007': 'Жолоби',
  'UA5904009002': 'Бурячиха',
  'UA5904009003': 'Гусарщина',
  'UA5904009006': 'Качанівка',
  'UA5904009005': 'Карпилівка',
  'UA5904009001': 'Комиші',
  'UA5904009007': 'Кударі',
  'UA5904009008': 'Лимареве',
  'UA5904009009': 'Мала Павлівка',
  'UA5904009010': 'Миколаївка',
  'UA5904009011': 'Неплатине',
  'UA5904009012': 'Овчаренки',
  'UA5904009013': 'Озера',
  'UA5904009014': 'Перелуг',
  'UA5904009015': 'Щоми',
  'UA5904009004': 'Закаблуки',
  'UA5904007002': 'Березівка',
  'UA5904007006': 'Іванівка',
  'UA5904007007': 'Катанське',
  'UA5904007008': 'Катеринівка',
  'UA5904007001': 'Кириківка',
  'UA5904007009': 'Майське',
  'UA5904007010': 'Маракучка',
  'UA5904007011': 'Мирне',
  'UA5904007013': 'Рябина',
  'UA5904007012': 'Рідне',
  'UA5904007003': 'Василівка',
  'UA5904007004': 'Веселе',
  'UA5904007005': 'Вищевеселе',
  'UA5904007014': 'Яблучне',
  'UA5904011002': 'Будне',
  'UA5904011005': 'Климентове',
  'UA5904011006': 'Козятин',
  'UA5904011001': 'Охтирка',
  'UA5904011007': 'Піски',
  'UA5904011008': 'Поділ',
  'UA5904011009': 'Пристань',
  'UA5904011010': 'Сосонка',
  'UA5904011011': 'Стара Іванівка',
  'UA5904011003': 'Велике Озеро',
  'UA5904011004': 'Залужани',
  'UA5904013002': 'Артемо-Растівка',
  'UA5904013003': 'Білка',
  'UA5904013009': 'Боголюбове',
  'UA5904013004': 'Братське',
  'UA5904013005': 'Буймер',
  'UA5904013008': 'Дернове',
  'UA5904013007': 'Грузьке',
  'UA5904013012': 'Кам\'янецьке',
  'UA5904013013': 'Кам\'янка',
  'UA5904013036': 'Хвощова',
  'UA5904013014': 'Крамчанка',
  'UA5904013015': 'Криничне',
  'UA5904013038': 'Лісне',
  'UA5904013017': 'Люджа',
  'UA5904013016': 'Лучка',
  'UA5904013018': 'Мартинівка',
  'UA5904013020': 'Мащанка',
  'UA5904013019': 'Машкове',
  'UA5904013021': 'Микитівка',
  'UA5904013023': 'Новоселівка',
  'UA5904013024': 'Новоукраїнка',
  'UA5904013022': 'Ницаха',
  'UA5904013026': 'Олексине',
  'UA5904013025': 'Оводівка',
  'UA5904013027': 'Печини',
  'UA5904013028': 'Поляне',
  'UA5904013029': 'Рябівка',
  'UA5904013030': 'Савелове',
  'UA5904013031': 'Семереньки',
  'UA5904013032': 'Скрягівка',
  'UA5904013033': 'Солдатське',
  'UA5904013034': 'Станова',
  'UA5904013001': 'Тростянець',
  'UA5904013035': 'Тучне',
  'UA5904013037': 'Виноградне',
  'UA5904013006': 'Вишневе',
  'UA5904013010': 'Золотарівка',
  'UA5904013011': 'Зубівка',
  'UA5904003002': 'Братениця',
  'UA5904003004': 'Дмитрівка',
  'UA5904003005': 'Добрянське',
  'UA5904003006': 'Дружба',
  'UA5904003008': 'Копійки',
  'UA5904003009': 'Лугівка',
  'UA5904003010': 'Лукашівка',
  'UA5904003011': 'Олександрівка',
  'UA5904003013': 'Пономаренки',
  'UA5904003014': 'Попівка',
  'UA5904003012': 'Пожня',
  'UA5904003015': 'Розсоші',
  'UA5904003021': 'Шевченкове',
  'UA5904003023': 'Шурове',
  'UA5904003022': 'Широкий Берег',
  'UA5904003017': 'Спірне',
  'UA5904003018': 'Станичне',
  'UA5904003019': 'Стрілецька Пушкарка',
  'UA5904003016': 'Сидорова Яруга',
  'UA5904003020': 'Тарасівка',
  'UA5904003001': 'Велика Писарівка',
  'UA5904003003': 'Вільне',
  'UA5904003024': 'Ямне',
  'UA5904003007': 'Їздецьке',
  'UA5906001002': 'Анастасівка',
  'UA5906001001': 'Андріяшівка',
  'UA5906001003': 'Андріївка',
  'UA5906001004': 'Артюхівка',
  'UA5906001005': 'Братське',
  'UA5906001006': 'Бурбине',
  'UA5906001030': 'Чеберяки',
  'UA5906001031': 'Чисте',
  'UA5906001013': 'Дубина',
  'UA5906001009': 'Глинськ',
  'UA5906001010': 'Голінка',
  'UA5906001011': 'Губське',
  'UA5906001012': 'Гудими',
  'UA5906001028': 'Холодник',
  'UA5906001029': 'Хоминці',
  'UA5906001016': 'Лісківщина',
  'UA5906001017': 'Локня',
  'UA5906001018': 'Луценкове',
  'UA5906001019': 'Мельники',
  'UA5906001020': 'Нова Гребля',
  'UA5906001022': 'Новопетрівка',
  'UA5906001021': 'Новицьке',
  'UA5906001023': 'Перекопівка',
  'UA5906001024': 'Попівщина',
  'UA5906001025': 'Саханське',
  'UA5906001032': 'Шумське',
  'UA5906001027': 'Сурмачівка',
  'UA5906001026': 'Світівщина',
  'UA5906001007': 'Василівка',
  'UA5906001008': 'Волошнівка',
  'UA5906001033': 'Якимовичі',
  'UA5906001034': 'Ярошівка',
  'UA5906001014': 'Закубанка',
  'UA5906001015': 'Залатиха',
  'UA5906015002': 'Басівка',
  'UA5906015003': 'Бессарабка',
  'UA5906015022': 'Червоне',
  'UA5906015025': 'Діброва',
  'UA5906015009': 'Дзеркалька',
  'UA5906015018': 'Федотове',
  'UA5906015008': 'Гаї',
  'UA5906015019': 'Харченки',
  'UA5906015001': 'Хмелів',
  'UA5906015020': 'Хрещатик',
  'UA5906015021': 'Хустянка',
  'UA5906015013': 'Коновали',
  'UA5906015014': 'Косарівщина',
  'UA5906015015': 'Пшінчине',
  'UA5906015023': 'Шкуматове',
  'UA5906015016': 'Сміле',
  'UA5906015017': 'Сулими',
  'UA5906015004': 'Велика Бутівка',
  'UA5906015005': 'Великі Будки',
  'UA5906015006': 'Володимирівка',
  'UA5906015007': 'Вощилиха',
  'UA5906015024': 'Ярове',
  'UA5906015010': 'Заклимок',
  'UA5906015011': 'Залуцьке',
  'UA5906015012': 'Заріччя',
  'UA5906005002': 'Беседівка',
  'UA5906005003': 'Бороданове',
  'UA5906005005': 'Дігтярівка',
  'UA5906005004': 'Гай',
  'UA5906005001': 'Коровинці',
  'UA5906005008': 'Коритище',
  'UA5906005009': 'Косенки',
  'UA5906005010': 'Малі Будки',
  'UA5906005011': 'Мухувате',
  'UA5906005012': 'Овеча',
  'UA5906005013': 'Перекір',
  'UA5906005014': 'Ракова Січ',
  'UA5906005015': 'Рубанка',
  'UA5906005016': 'Соснівка',
  'UA5906005019': 'Тютюнникове',
  'UA5906005018': 'Томашівка',
  'UA5906005017': 'Тимощенкове',
  'UA5906005020': 'Юхти',
  'UA5906005006': 'Закроївщина',
  'UA5906005007': 'Зелений Гай',
  'UA5906007002': 'Аршуки',
  'UA5906007003': 'Байрак',
  'UA5906007004': 'Беєве',
  'UA5906007005': 'Берестівка',
  'UA5906007006': 'Бугаївка',
  'UA5906007007': 'Бухалове',
  'UA5906007034': 'Червона Долина',
  'UA5906007035': 'Червоногірка',
  'UA5906007036': 'Чирвине',
  'UA5906007013': 'Довга Лука',
  'UA5906007011': 'Галаївець',
  'UA5906007012': 'Грабщина',
  'UA5906007014': 'Іванівка',
  'UA5906007033': 'Хоменкове',
  'UA5906007015': 'Кімличка',
  'UA5906007016': 'Коцупіївка',
  'UA5906007017': 'Куплеваха',
  'UA5906007018': 'Легуші',
  'UA5906007021': 'Лучка',
  'UA5906007019': 'Липівка',
  'UA5906007020': 'Липівське',
  'UA5906007001': 'Липова Долина',
  'UA5906007022': 'Макіївське',
  'UA5906007023': 'Мельникове',
  'UA5906007024': 'Московське',
  'UA5906007025': 'Новосеменівка',
  'UA5906007026': 'Олещенкове',
  'UA5906007027': 'Панасівка',
  'UA5906007028': 'Побиванка',
  'UA5906007029': 'Русанівка',
  'UA5906007030': 'Семенівка',
  'UA5906007032': 'Стягайлівка',
  'UA5906007031': 'Столярове',
  'UA5906007040': 'Суха Грунь',
  'UA5906007008': 'Весела Долина',
  'UA5906007009': 'Веселе',
  'UA5906007010': 'Воропаї',
  'UA5906007037': 'Яганівка',
  'UA5906007038': 'Яловий Окіп',
  'UA5906007039': 'Яснопільщина',
  'UA5906009003': 'Баба',
  'UA5906009004': 'Бабакове',
  'UA5906009005': 'Березняки',
  'UA5906009006': 'Берізки',
  'UA5906009007': 'Біж',
  'UA5906009008': 'Бродок',
  'UA5906009050': 'Чемоданівка',
  'UA5906009051': 'Червона Слобода',
  'UA5906009017': 'Дараганове',
  'UA5906009018': 'Дігтярка',
  'UA5906009019': 'Долина',
  'UA5906009020': 'Дремове',
  'UA5906009013': 'Голубці',
  'UA5906009014': 'Горькове',
  'UA5906009015': 'Гострий Шпиль',
  'UA5906009016': 'Гринівка',
  'UA5906009023': 'Іваниця',
  'UA5906009046': 'Холодне',
  'UA5906009047': 'Холодний Яр',
  'UA5906009048': 'Хоружівка',
  'UA5906009024': 'Ківшик',
  'UA5906009025': 'Клин',
  'UA5906009026': 'Кореневе',
  'UA5906009027': 'Костянтинів',
  'UA5906009028': 'Кулішівка',
  'UA5906009029': 'Курмани',
  'UA5906009031': 'Лахнівщина',
  'UA5906009030': 'Лаврове',
  'UA5906009032': 'Луки',
  'UA5906009034': 'Мала Черепівка',
  'UA5906009035': 'Маршали',
  'UA5906009033': 'Мазне',
  'UA5906009036': 'Мелешківка',
  'UA5906009001': 'Недригайлів',
  'UA5906009037': 'Нелени',
  'UA5906009039': 'Омелькове',
  'UA5906009038': 'Озерне',
  'UA5906009040': 'Перетічки',
  'UA5906009041': 'Польове',
  'UA5906009042': 'Пушкарщина',
  'UA5906009043': 'Сакуниха',
  'UA5906009052': 'Шматове',
  'UA5906009044': 'Спартак',
  'UA5906009045': 'Терешки',
  'UA5906009002': 'Терни',
  'UA5906009049': 'Цибуленки',
  'UA5906009009': 'Вакулки',
  'UA5906009010': 'Велика Діброва',
  'UA5906009011': 'Віхове',
  'UA5906009012': 'Володимирівка',
  'UA5906009053': 'Якименки',
  'UA5906009021': 'Засулля',
  'UA5906009022': 'Зелене',
  'UA5906011002': 'Авраменкове',
  'UA5906011003': 'Бацмани',
  'UA5906011004': 'Біловод',
  'UA5906011074': 'Біловодське',
  'UA5906011005': 'Бобрик',
  'UA5906011006': 'Бойкове',
  'UA5906011007': 'Борозенка',
  'UA5906011070': 'Чижикове',
  'UA5906011019': 'Довгополівка',
  'UA5906011015': 'Галенкове',
  'UA5906011016': 'Галка',
  'UA5906011014': 'Гаврилівка',
  'UA5906011017': 'Герасимівка',
  'UA5906011018': 'Горове',
  'UA5906011027': 'Калинівка',
  'UA5906011028': 'Кашпури',
  'UA5906011029': 'Колісникове',
  'UA5906011030': 'Кононенкове',
  'UA5906011032': 'Королівщина',
  'UA5906011031': 'Коржі',
  'UA5906011033': 'Кропивинці',
  'UA5906011034': 'Кузьменкове',
  'UA5906011036': 'Левченки',
  'UA5906011035': 'Левондівка',
  'UA5906011075': 'Лучки',
  'UA5906011037': 'Лукашове',
  'UA5906011038': 'Мале',
  'UA5906011039': 'Малі Бубни',
  'UA5906011040': 'Малярівщина',
  'UA5906011041': 'Марківське',
  'UA5906011042': 'Матлахове',
  'UA5906011045': 'Мокіївка',
  'UA5906011046': 'Москалівка',
  'UA5906011047': 'Москівщина',
  'UA5906011043': 'Миколаївка',
  'UA5906011044': 'Миколаївське',
  'UA5906011048': 'Ненадіївка',
  'UA5906011049': 'Новокалинівка',
  'UA5906011051': 'Олексіївка',
  'UA5906011050': 'Овлаші',
  'UA5906011052': 'Перехрестівка',
  'UA5906011053': 'Піски',
  'UA5906011054': 'Плавинище',
  'UA5906011055': 'Плужникове',
  'UA5906011056': 'Погожа Криниця',
  'UA5906011057': 'Погреби',
  'UA5906011058': 'Попівка',
  'UA5906011059': 'Посад',
  'UA5906011060': 'Правдюки',
  'UA5906011061': 'Пустовійтівка',
  'UA5906011062': 'Ріпки',
  'UA5906011063': 'Рогинці',
  'UA5906011001': 'Ромни',
  'UA5906011065': 'Садове',
  'UA5906011066': 'Салогубівка',
  'UA5906011064': 'Савойське',
  'UA5906011067': 'Сененкове',
  'UA5906011071': 'Шилівське',
  'UA5906011068': 'Скрипалі',
  'UA5906011069': 'Степурине',
  'UA5906011008': 'Ведмеже',
  'UA5906011009': 'Велике',
  'UA5906011010': 'Великі Бубни',
  'UA5906011011': 'Веселий Степ',
  'UA5906011013': 'В\'юнне',
  'UA5906011012': 'Вовківці',
  'UA5906011072': 'Яковенкове',
  'UA5906011073': 'Ярмолинці',
  'UA5906011021': 'Загребелля',
  'UA5906011022': 'Заїзд',
  'UA5906011023': 'Заруддя',
  'UA5906011024': 'Зеленівщина',
  'UA5906011020': 'Житне',
  'UA5906011025': 'Зінове',
  'UA5906011026': 'Зюзюки',
  'UA5906013002': 'Антоненкове',
  'UA5906013010': 'Довжик',
  'UA5906013007': 'Голуби',
  'UA5906013008': 'Греки',
  'UA5906013009': 'Гришки',
  'UA5906013011': 'Капустинці',
  'UA5906013012': 'Карпці',
  'UA5906013013': 'Клюси',
  'UA5906013016': 'Колядинець',
  'UA5906013014': 'Колісники',
  'UA5906013015': 'Коломійцева Долина',
  'UA5906013017': 'Костяни',
  'UA5906013018': 'Мар\'янівка',
  'UA5906013019': 'Мельники',
  'UA5906013020': 'Нестеренки',
  'UA5906013021': 'Підставки',
  'UA5906013022': 'Подільки',
  'UA5906013023': 'Потопиха',
  'UA5906013024': 'Рудоман',
  'UA5906013025': 'Саї',
  'UA5906013026': 'Слобідка',
  'UA5906013001': 'Синівка',
  'UA5906013027': 'Тарасенки',
  'UA5906013028': 'Товсте',
  'UA5906013003': 'Велика Лука',
  'UA5906013004': 'Великий Ліс',
  'UA5906013005': 'Весела Долина',
  'UA5906013006': 'Вовкове',
  'UA5906003002': 'Білоярське',
  'UA5906003006': 'Деркачівка',
  'UA5906003019': 'Фартушине',
  'UA5906003020': 'Філонове',
  'UA5906003005': 'Городище',
  'UA5906003021': 'Хорол',
  'UA5906003009': 'Комишанка',
  'UA5906003008': 'Козельне',
  'UA5906003010': 'Кушніри',
  'UA5906003011': 'Лікарівщина',
  'UA5906003012': 'Мірки',
  'UA5906003013': 'Немудруї',
  'UA5906003014': 'П\'ятидуб',
  'UA5906003015': 'Реви',
  'UA5906003016': 'Саєве',
  'UA5906003022': 'Шаповалове',
  'UA5906003017': 'Сороколітове',
  'UA5906003018': 'Тимченки',
  'UA5906003003': 'Великі Будки',
  'UA5906003004': 'Весногірське',
  'UA5906003001': 'Вільшана',
  'UA5906003007': 'Зеленківка',
  'UA5910001001': 'Береза',
  'UA5910001004': 'Долина',
  'UA5910001021': 'Есмань',
  'UA5910001002': 'Горіле',
  'UA5910001003': 'Гута',
  'UA5910001007': 'Іващенкове',
  'UA5910001008': 'Клочківка',
  'UA5910001009': 'Кривенкове',
  'UA5910001010': 'Кушкине',
  'UA5910001011': 'Масензівка',
  'UA5910001012': 'Нарбутівка',
  'UA5910001013': 'Обложки',
  'UA5910001014': 'Одрадне',
  'UA5910001016': 'Перше Травня',
  'UA5910001015': 'Первомайське',
  'UA5910001017': 'Попівщина',
  'UA5910001019': 'Шакутівщина',
  'UA5910001020': 'Шевченкове',
  'UA5910001018': 'Слоут',
  'UA5910001006': 'Землянка',
  'UA5910001005': 'Жалківщина',
  'UA5910005002': 'Бугор',
  'UA5910005012': 'Чуйківка',
  'UA5910005005': 'Дорошенкове',
  'UA5910005006': 'Дорошівка',
  'UA5910005004': 'Довжик',
  'UA5910005001': 'Дружба',
  'UA5910005007': 'Косинське',
  'UA5910005008': 'Микитське',
  'UA5910005009': 'Об\'єднане',
  'UA5910005010': 'Палащенкове',
  'UA5910005011': 'Романькове',
  'UA5910005003': 'Василець',
  'UA5910007003': 'Бачівськ',
  'UA5910007002': 'Баранівка',
  'UA5910007004': 'Біла Береза',
  'UA5910007005': 'Бобилівка',
  'UA5910007028': 'Червона Зоря',
  'UA5910007001': 'Есмань',
  'UA5910007025': 'Фотовиж',
  'UA5910007026': 'Харківка',
  'UA5910007027': 'Ходуня',
  'UA5910007008': 'Комарівка',
  'UA5910007009': 'Кореньок',
  'UA5910007010': 'Кучерівка',
  'UA5910007011': 'Лужки',
  'UA5910007012': 'Мала Слобідка',
  'UA5910007013': 'Муравейня',
  'UA5910007014': 'Потапівка',
  'UA5910007015': 'Пустогород',
  'UA5910007018': 'Смолине',
  'UA5910007017': 'Смикарівка',
  'UA5910007019': 'Сопич',
  'UA5910007020': 'Студенок',
  'UA5910007021': 'Суходіл',
  'UA5910007016': 'Сидорівка',
  'UA5910007023': 'Тополя',
  'UA5910007022': 'Товстодубове',
  'UA5910007024': 'Уланове',
  'UA5910007007': 'Вільна Слобода',
  'UA5910007006': 'Вишеньки',
  'UA5910007029': 'Яструбщина',
  'UA5910003002': 'Баничі',
  'UA5910003003': 'Білокопитове',
  'UA5910003025': 'Будівельне',
  'UA5910003004': 'Будища',
  'UA5910003008': 'Дунаєць',
  'UA5910003001': 'Глухів',
  'UA5910003007': 'Годунівка',
  'UA5910003010': 'Іонине',
  'UA5910003011': 'Калюжне',
  'UA5910003023': 'Хотминівка',
  'UA5910003012': 'Кравченкове',
  'UA5910003013': 'Мацкове',
  'UA5910003014': 'Москаленки',
  'UA5910003015': 'Некрасове',
  'UA5910003016': 'Перемога',
  'UA5910003017': 'Полошки',
  'UA5910003018': 'Привілля',
  'UA5910003019': 'Семенівка',
  'UA5910003024': 'Щебри',
  'UA5910003020': 'Сліпород',
  'UA5910003021': 'Сутиски',
  'UA5910003022': 'Уздиця',
  'UA5910003005': 'Вікторове',
  'UA5910003006': 'Вознесенське',
  'UA5910003009': 'Заруцьке',
  'UA5910013028': 'Чернацьке',
  'UA5910013007': 'Демченкове',
  'UA5910013005': 'Гаврилова Слобода',
  'UA5910013006': 'Гутко-Ожинка',
  'UA5910013009': 'Кам\'янка',
  'UA5910013027': 'Хлібороб',
  'UA5910013010': 'Красичка',
  'UA5910013011': 'Лісова Поляна',
  'UA5910013012': 'Луг',
  'UA5910013013': 'Лукашенкове',
  'UA5910013014': 'Нова Гута',
  'UA5910013032': 'Нова Спарта',
  'UA5910013015': 'Нововолодимирівка',
  'UA5910013016': 'Перемога',
  'UA5910013018': 'Порохонь',
  'UA5910013033': 'Прогрес',
  'UA5910013017': 'Пигарівка',
  'UA5910013019': 'Ріг',
  'UA5910013021': 'Ромашкове',
  'UA5910013020': 'Рожковичі',
  'UA5910013034': 'Рудак',
  'UA5910013022': 'Рудня',
  'UA5910013001': 'Середина-Буда',
  'UA5910013029': 'Шалимівка',
  'UA5910013024': 'Сорокине',
  'UA5910013025': 'Стара Гута',
  'UA5910013023': 'Ситне',
  'UA5910013026': 'Троїцьке',
  'UA5910013002': 'Василівка',
  'UA5910013003': 'Велика Берізка',
  'UA5910013004': 'Винторівка',
  'UA5910013030': 'Ясна Поляна',
  'UA5910013031': 'Зарічне',
  'UA5910013008': 'Жихове',
  'UA5910015010': 'Черневе',
  'UA5910015003': 'Гудове',
  'UA5910015005': 'Катеринівка',
  'UA5910015009': 'Ходине',
  'UA5910015001': 'Шалигине',
  'UA5910015007': 'Соснівка',
  'UA5910015008': 'Старикове',
  'UA5910015006': 'Сваркове',
  'UA5910015002': 'Вовківка',
  'UA5910015004': 'Ємадикине',
  'UA5910017003': 'Бензики',
  'UA5910017004': 'Богданівка',
  'UA5910017005': 'Богданка',
  'UA5910017036': 'Чапліївка',
  'UA5910017037': 'Чорні Лози',
  'UA5910017012': 'Дібрівка',
  'UA5910017008': 'Гамаліївка',
  'UA5910017009': 'Глазове',
  'UA5910017010': 'Гудівщина',
  'UA5910017011': 'Гукове',
  'UA5910017014': 'Івот',
  'UA5910017015': 'Каліївка',
  'UA5910017035': 'Холодівщина',
  'UA5910017016': 'Клишки',
  'UA5910017017': 'Ковтунове',
  'UA5910017018': 'Крупець',
  'UA5910017019': 'Курдюмівка',
  'UA5910017020': 'Лісне',
  'UA5910017021': 'Лушники',
  'UA5910017022': 'Макове',
  'UA5910017023': 'Масиків',
  'UA5910017025': 'Московське',
  'UA5910017024': 'Миронівка',
  'UA5910017026': 'Ображіївка',
  'UA5910017027': 'Остроушки',
  'UA5910017029': 'Погребки',
  'UA5910017028': 'Пирогівка',
  'UA5910017038': 'Шкирманівка',
  'UA5910017001': 'Шостка',
  'UA5910017031': 'Собич',
  'UA5910017032': 'Собичеве',
  'UA5910017033': 'Солотвине',
  'UA5910017030': 'Свірж',
  'UA5910017034': 'Тиманівка',
  'UA5910017006': 'Великий Ліс',
  'UA5910017002': 'Вороніж',
  'UA5910017007': 'Вовна',
  'UA5910017013': 'Заболотне',
  'UA5910011004': 'Дем\'янівка',
  'UA5910011003': 'Гирине',
  'UA5910011007': 'Княжичі',
  'UA5910011008': 'Ломленка',
  'UA5910011009': 'Марчихина Буда',
  'UA5910011010': 'Микитівка',
  'UA5910011011': 'Орлів Яр',
  'UA5910011012': 'Орлівка',
  'UA5910011014': 'Родіонівка',
  'UA5910011015': 'Руденка',
  'UA5910011013': 'Ржане',
  'UA5910011017': 'Шевченкове',
  'UA5910011016': 'Степанівка',
  'UA5910011001': 'Свеса',
  'UA5910011002': 'Веселий Гай',
  'UA5910011005': 'Зелена Діброва',
  'UA5910011006': 'Зорине',
  'UA5910019002': 'Антонівка',
  'UA5910019003': 'Базелівщина',
  'UA5910019004': 'Білиця',
  'UA5910019029': 'Чижикове',
  'UA5910019008': 'Деражня',
  'UA5910019009': 'Діброва',
  'UA5910019028': 'Феофілівка',
  'UA5910019006': 'Говорунове',
  'UA5910019007': 'Грем\'ячка',
  'UA5910019011': 'Імшана',
  'UA5910019010': 'Івотка',
  'UA5910019012': 'Лісне',
  'UA5910019013': 'Майське',
  'UA5910019031': 'Неплюєве',
  'UA5910019014': 'Окіп',
  'UA5910019015': 'Олександрівське',
  'UA5910019017': 'Ольгине',
  'UA5910019016': 'Олине',
  'UA5910019018': 'Паліївка',
  'UA5910019019': 'Папірня',
  'UA5910019020': 'Прудище',
  'UA5910019032': 'Привокзальне',
  'UA5910019022': 'Ростов',
  'UA5910019021': 'Рождественське',
  'UA5910019030': 'Шатрище',
  'UA5910019023': 'Скобичівське',
  'UA5910019024': 'Сороковий Клин',
  'UA5910019025': 'Степне',
  'UA5910019026': 'Туранівка',
  'UA5910019027': 'Усок',
  'UA5910019005': 'Воздвиженське',
  'UA5910019001': 'Ямпіль',
  'UA5910009002': 'Боровичі',
  'UA5910009027': 'Червоне',
  'UA5910009028': 'Четвертакове',
  'UA5910009029': 'Чигин',
  'UA5910009004': 'Голубівка',
  'UA5910009008': 'Карпеченкове',
  'UA5910009026': 'Хильчичі',
  'UA5910009009': 'Красноярське',
  'UA5910009010': 'Кренидівка',
  'UA5910009011': 'Кривоносівка',
  'UA5910009012': 'Кудоярове',
  'UA5910009013': 'Кустине',
  'UA5910009014': 'Лісне',
  'UA5910009015': 'Любахове',
  'UA5910009016': 'Люте',
  'UA5910009017': 'Мефедівка',
  'UA5910009018': 'Нововасилівка',
  'UA5910009019': 'Очкине',
  'UA5910009020': 'Поліське',
  'UA5910009021': 'Стягайлівка',
  'UA5910009022': 'Таборище',
  'UA5910009023': 'Українське',
  'UA5910009024': 'Улиця',
  'UA5910009025': 'Уралове',
  'UA5910009003': 'Василівське',
  'UA5910009006': 'Заріччя',
  'UA5910009005': 'Журавка',
  'UA5910009001': 'Зноб-Новгородське',
  'UA5910009007': 'Зноб-Трубчевська',
  'UA5908001001': 'Бездрик',
  'UA5908001004': 'Червона Діброва',
  'UA5908001003': 'Токарі',
  'UA5908001002': 'Зацарне',
  'UA5908003002': 'Атинське',
  'UA5908003003': 'Бабаківка',
  'UA5908003004': 'Безсалівка',
  'UA5908003001': 'Білопілля',
  'UA5908003005': 'Бубликове',
  'UA5908003006': 'Будки',
  'UA5908003050': 'Черванівка',
  'UA5908003014': 'Дігтярне',
  'UA5908003015': 'Дудченки',
  'UA5908003010': 'Ганнівка-Вирівська',
  'UA5908003012': 'Голишівське',
  'UA5908003013': 'Гуринівка',
  'UA5908003011': 'Гиріне',
  'UA5908003016': 'Іскрисківщина',
  'UA5908003018': 'Кандибине',
  'UA5908003019': 'Катеринівка',
  'UA5908003022': 'Котенки',
  'UA5908003021': 'Коваленки',
  'UA5908003023': 'Куянівка',
  'UA5908003020': 'Кисла Дубина',
  'UA5908003024': 'Макіївка',
  'UA5908003025': 'Мар\'янівка',
  'UA5908003026': 'Мелячиха',
  'UA5908003028': 'Мороча',
  'UA5908003029': 'Москаленки',
  'UA5908003027': 'Мирлоги',
  'UA5908003030': 'Нескучне',
  'UA5908003031': 'Нові Вирки',
  'UA5908003032': 'Новоандріївка',
  'UA5908003033': 'Новоіванівка',
  'UA5908003034': 'Новопетрівка',
  'UA5908003035': 'Ободи',
  'UA5908003036': 'Олексенки',
  'UA5908003037': 'Павлівка',
  'UA5908003038': 'Павлівське',
  'UA5908003054': 'Перемога',
  'UA5908003040': 'Рогізне',
  'UA5908003041': 'Руденкове',
  'UA5908003039': 'Рижівка',
  'UA5908003042': 'Садове',
  'UA5908003051': 'Шпиль',
  'UA5908003052': 'Штанівка',
  'UA5908003043': 'Смоляниківка',
  'UA5908003045': 'Сохани',
  'UA5908003044': 'Соляники',
  'UA5908003046': 'Старі Вирки',
  'UA5908003047': 'Степанівка',
  'UA5908003048': 'Стукалівка',
  'UA5908003049': 'Терещенки',
  'UA5908003007': 'Василівщина',
  'UA5908003008': 'Волфине',
  'UA5908003009': 'Воронівка',
  'UA5908003053': 'Янченки',
  'UA5908003017': 'Йосипове',
  'UA5908029002': 'Андріївка',
  'UA5908029003': 'Біловоди',
  'UA5908029001': 'Хотінь',
  'UA5908029008': 'Кіндратівка',
  'UA5908029009': 'Костянтинівка',
  'UA5908029010': 'Новомиколаївка',
  'UA5908029011': 'Олексіївка',
  'UA5908029012': 'Перше Травня',
  'UA5908029013': 'Писарівка',
  'UA5908029014': 'Степне',
  'UA5908029004': 'Веселівка',
  'UA5908029005': 'Водолаги',
  'UA5908029006': 'Володимирівка',
  'UA5908029007': 'Журавка',
  'UA5908009003': 'Бранцівка',
  'UA5908009039': 'Чернеччина',
  'UA5908009012': 'Думівка',
  'UA5908009009': 'Гапонівка',
  'UA5908009010': 'Глибне',
  'UA5908009011': 'Грабовське',
  'UA5908009038': 'Хмелівка',
  'UA5908009037': 'Хвойне',
  'UA5908009001': 'Краснопілля',
  'UA5908009014': 'Лісне',
  'UA5908009015': 'Лозове',
  'UA5908009041': 'Майське',
  'UA5908009017': 'Марченки',
  'UA5908009016': 'Мар\'їне',
  'UA5908009018': 'Мезенівка',
  'UA5908009020': 'Мозкове',
  'UA5908009019': 'Михайлівка',
  'UA5908009043': 'Михайлівське',
  'UA5908009042': 'Миропільське',
  'UA5908009021': 'Наумівка',
  'UA5908009022': 'Новодмитрівка',
  'UA5908009023': 'Новоолександрівка',
  'UA5908009024': 'Окіп',
  'UA5908009025': 'Осоївка',
  'UA5908009026': 'Петрушівка',
  'UA5908009027': 'Покровка',
  'UA5908009028': 'Попівка',
  'UA5908009029': 'Порозок',
  'UA5908009030': 'Проходи',
  'UA5908009031': 'Рясне',
  'UA5908009032': 'Самотоївка',
  'UA5908009033': 'Славгород',
  'UA5908009034': 'Степок',
  'UA5908009035': 'Таратутине',
  'UA5908009036': 'Тур\'я',
  'UA5908009002': 'Угроїди',
  'UA5908009004': 'Верхня Пожня',
  'UA5908009005': 'Веселе',
  'UA5908009008': 'Воропай',
  'UA5908009006': 'Виднівка',
  'UA5908009007': 'Високе',
  'UA5908009040': 'Ясенок',
  'UA5908009013': 'Земляне',
  'UA5908011002': 'Байрак',
  'UA5908011003': 'Барабашівка',
  'UA5908011004': 'Басівщина',
  'UA5908011005': 'Березів Яр',
  'UA5908011007': 'Боброве',
  'UA5908011008': 'Бойки',
  'UA5908011009': 'Боровенька',
  'UA5908011010': 'Будилка',
  'UA5908011011': 'Букати',
  'UA5908011012': 'Бурівка',
  'UA5908011006': 'Бишкінь',
  'UA5908011112': 'Черемухівка',
  'UA5908011113': 'Чернецьке',
  'UA5908011114': 'Чернишки',
  'UA5908011110': 'Червлене',
  'UA5908011111': 'Червоне',
  'UA5908011115': 'Чижове',
  'UA5908011034': 'Даценківка',
  'UA5908011035': 'Дігтярі',
  'UA5908011036': 'Дмитрівка',
  'UA5908011037': 'Дремлюги',
  'UA5908011038': 'Дружне',
  'UA5908011021': 'Галушки',
  'UA5908011022': 'Гамаліївка',
  'UA5908011023': 'Гарбарі',
  'UA5908011024': 'Гарбузівка',
  'UA5908011025': 'Гірки',
  'UA5908011026': 'Гостробури',
  'UA5908011027': 'Грабці',
  'UA5908011030': 'Грунь',
  'UA5908011031': 'Грушеве',
  'UA5908011028': 'Гринцеве',
  'UA5908011029': 'Грицини',
  'UA5908011032': 'Гудимівка',
  'UA5908011033': 'Гутницьке',
  'UA5908011041': 'Калюжне',
  'UA5908011042': 'Кам\'яне',
  'UA5908011043': 'Караван',
  'UA5908011044': 'Катеринівка',
  'UA5908011045': 'Кердилівщина',
  'UA5908011108': 'Харченки',
  'UA5908011109': 'Хилькове',
  'UA5908011046': 'Ключинівка',
  'UA5908011047': 'Корчани',
  'UA5908011048': 'Костів',
  'UA5908011049': 'Куданівка',
  'UA5908011051': 'Куличка',
  'UA5908011050': 'Кулики',
  'UA5908011052': 'Курган',
  'UA5908011053': 'Курилівка',
  'UA5908011001': 'Лебедин',
  'UA5908011057': 'Ляшки',
  'UA5908011055': 'Лободівщина',
  'UA5908011056': 'Лозово-Грушеве',
  'UA5908011054': 'Лифине',
  'UA5908011058': 'Майдаки',
  'UA5908011059': 'Малий Вистороп',
  'UA5908011060': 'Мартинці',
  'UA5908011061': 'Марусенкове',
  'UA5908011062': 'Межиріч',
  'UA5908011066': 'Московський Бобрик',
  'UA5908011065': 'Михайлівка',
  'UA5908011063': 'Мирне',
  'UA5908011123': 'Мирне',
  'UA5908011064': 'Миронівщина',
  'UA5908011124': 'Новопетрівка',
  'UA5908011067': 'Новоселівка',
  'UA5908011068': 'Новосільське',
  'UA5908011070': 'Олександрівка',
  'UA5908011071': 'Олексенкове',
  'UA5908011072': 'Олексіївка',
  'UA5908011069': 'Овдянське',
  'UA5908011074': 'Падалки',
  'UA5908011075': 'Панченки',
  'UA5908011076': 'Парфили',
  'UA5908011078': 'Пашкине',
  'UA5908011077': 'Патріотівка',
  'UA5908011073': 'Павленкове',
  'UA5908011079': 'Переліски',
  'UA5908011125': 'Першотравневе',
  'UA5908011081': 'Підопригори',
  'UA5908011082': 'Підсулля',
  'UA5908011083': 'Пісківка',
  'UA5908011080': 'Північне',
  'UA5908011084': 'Плетньове',
  'UA5908011085': 'Помірки',
  'UA5908011087': 'Протопопівщина',
  'UA5908011086': 'Пристайлове',
  'UA5908011088': 'Радчуки',
  'UA5908011089': 'Ревки',
  'UA5908011091': 'Рябушки',
  'UA5908011090': 'Руда',
  'UA5908011092': 'Савенки',
  'UA5908011093': 'Селище',
  'UA5908011094': 'Семенівка',
  'UA5908011119': 'Щетини',
  'UA5908011116': 'Шевченкове',
  'UA5908011117': 'Штепівка',
  'UA5908011118': 'Шумили',
  'UA5908011096': 'Сіренки',
  'UA5908011097': 'Слобода',
  'UA5908011098': 'Софіївка',
  'UA5908011099': 'Старонове',
  'UA5908011100': 'Стеблянки',
  'UA5908011101': 'Степне',
  'UA5908011102': 'Степове',
  'UA5908011103': 'Ступки',
  'UA5908011104': 'Супруни',
  'UA5908011095': 'Ситники',
  'UA5908011105': 'Токарі',
  'UA5908011106': 'Топчії',
  'UA5908011107': 'Тригуби',
  'UA5908011013': 'Валки',
  'UA5908011014': 'Василівка',
  'UA5908011016': 'Великі Луки',
  'UA5908011015': 'Великий Вистороп',
  'UA5908011017': 'Вершина',
  'UA5908011018': 'Вільшанка',
  'UA5908011019': 'Влізьки',
  'UA5908011020': 'Ворожба',
  'UA5908011120': 'Яроші',
  'UA5908011121': 'Яснопілля',
  'UA5908011039': 'Забуги',
  'UA5908011122': 'Залізничне',
  'UA5908011040': 'Зелений Гай',
  'UA5908013003': 'Аркавське',
  'UA5908013004': 'Бакша',
  'UA5908013005': 'Біликівка',
  'UA5908013006': 'Бобрик',
  'UA5908013007': 'Болотишине',
  'UA5908013008': 'Бутовщина',
  'UA5908013012': 'Ганнівка-Тернівська',
  'UA5908013013': 'Гостинне',
  'UA5908013039': 'Калинівка',
  'UA5908013015': 'Комарицьке',
  'UA5908013016': 'Курасове',
  'UA5908013040': 'Лідине',
  'UA5908013017': 'Лохня',
  'UA5908013018': 'Луциківка',
  'UA5908013019': 'Марківка',
  'UA5908013020': 'Машарі',
  'UA5908013021': 'Мукіївка',
  'UA5908013001': 'Миколаївка',
  'UA5908013022': 'Олександрівка',
  'UA5908013024': 'Пащенкове',
  'UA5908013023': 'Павленкове',
  'UA5908013025': 'Перше Травня',
  'UA5908013026': 'Першотравневе',
  'UA5908013027': 'Піщане',
  'UA5908013028': 'Птиче',
  'UA5908013029': 'Руда',
  'UA5908013030': 'Рудка',
  'UA5908013031': 'Самара',
  'UA5908013042': 'Шевченківка',
  'UA5908013032': 'Стрільцеве',
  'UA5908013033': 'Сульське',
  'UA5908013034': 'Супрунівка',
  'UA5908013035': 'Сушилине',
  'UA5908013041': 'Тімірязєвка',
  'UA5908013036': 'Товста',
  'UA5908013037': 'Тучне',
  'UA5908013002': 'Улянівка',
  'UA5908013009': 'Валіївка',
  'UA5908013010': 'Верхосулка',
  'UA5908013011': 'Веселе',
  'UA5908013014': 'Жолобок',
  'UA5908013038': 'Зоряне',
  'UA5908015002': 'Бондарівщина',
  'UA5908015003': 'Бурчак',
  'UA5908015008': 'Діброва',
  'UA5908015006': 'Графське',
  'UA5908015007': 'Гриценкове',
  'UA5908015009': 'Капітанівка',
  'UA5908015010': 'Кекине',
  'UA5908015011': 'Кровне',
  'UA5908015013': 'Лікарське',
  'UA5908015012': 'Линтварівка',
  'UA5908015014': 'Мар\'ївка',
  'UA5908015001': 'Миколаївка',
  'UA5908015015': 'Над\'ярне',
  'UA5908015016': 'Перехрестівка',
  'UA5908015017': 'Постольне',
  'UA5908015026': 'Рогізне',
  'UA5908015018': 'Руднівка',
  'UA5908015019': 'Северинівка',
  'UA5908015020': 'Склярівка',
  'UA5908015022': 'Софіївка',
  'UA5908015021': 'Соколине',
  'UA5908015023': 'Спаське',
  'UA5908015024': 'Степаненкове',
  'UA5908015004': 'Васюківщина',
  'UA5908015005': 'Вербове',
  'UA5908015025': 'Яструбине',
  'UA5908017002': 'Барилівка',
  'UA5908017005': 'Гнилиця',
  'UA5908017006': 'Грунівка',
  'UA5908017008': 'Мала Рибиця',
  'UA5908017001': 'Миропілля',
  'UA5908017009': 'Олександрія',
  'UA5908017010': 'Сінне',
  'UA5908017003': 'Велика Рибиця',
  'UA5908017004': 'Великий Прикіл',
  'UA5908017007': 'Запсілля',
  'UA5908019002': 'Барвінкове',
  'UA5908019004': 'Гірне',
  'UA5908019001': 'Нижня Сироватка',
  'UA5908019005': 'Старе Село',
  'UA5908019003': 'Вишневе',
  'UA5908021017': 'Амбари',
  'UA5908021002': 'Баїха',
  'UA5908021003': 'Барило',
  'UA5908021004': 'Безсокирне',
  'UA5908021005': 'Білани',
  'UA5908021006': 'Біловишневе',
  'UA5908021008': 'Головачі',
  'UA5908021009': 'Горобівка',
  'UA5908021012': 'Коршачина',
  'UA5908021013': 'Кравченкове',
  'UA5908021014': 'Максимівщина',
  'UA5908021015': 'Омельченки',
  'UA5908021001': 'Річки',
  'UA5908021016': 'Синяк',
  'UA5908021007': 'Вири',
  'UA5908021010': 'Зарічне',
  'UA5908021011': 'Зелене',
  'UA5908023003': 'Бровкове',
  'UA5908023004': 'Буцикове',
  'UA5908023010': 'Деркачі',
  'UA5908023011': 'Доценківка',
  'UA5908023028': 'Федірки',
  'UA5908023029': 'Філонівщина',
  'UA5908023008': 'Голубівка',
  'UA5908023009': 'Грамине',
  'UA5908023030': 'Харківщина',
  'UA5908023014': 'Коломийці',
  'UA5908023016': 'Любачеве',
  'UA5908023015': 'Лугове',
  'UA5908023017': 'Маньки',
  'UA5908023018': 'Москалівщина',
  'UA5908023019': 'Никонці',
  'UA5908023002': 'Низи',
  'UA5908023020': 'Облоги',
  'UA5908023021': 'Ополонське',
  'UA5908023022': 'Павлючки',
  'UA5908023023': 'Печище',
  'UA5908023001': 'Сад',
  'UA5908023031': 'Шапошникове',
  'UA5908023032': 'Шпилівка',
  'UA5908023025': 'Сіробабине',
  'UA5908023026': 'Сула',
  'UA5908023024': 'Симонівка',
  'UA5908023027': 'Терешківка',
  'UA5908023006': 'Великі Вільми',
  'UA5908023005': 'Великий Яр',
  'UA5908023007': 'Визирівка',
  'UA5908023033': 'Ясени',
  'UA5908023012': 'Єлисеєнкове',
  'UA5908023013': 'Зелена Роща',
  'UA5908025002': 'Білоусівка',
  'UA5908025018': 'Чернецьке',
  'UA5908025017': 'Червоний Кут',
  'UA5908025003': 'Глиняне',
  'UA5908025004': 'Головашівка',
  'UA5908025006': 'Кононенкове',
  'UA5908025007': 'Косівщина',
  'UA5908025008': 'Малі Вільми',
  'UA5908025009': 'Миловидівка',
  'UA5908025010': 'Надточієве',
  'UA5908025011': 'Новомихайлівка',
  'UA5908025012': 'Новосуханівка',
  'UA5908025013': 'Олександрівка',
  'UA5908025014': 'Підліснівка',
  'UA5908025015': 'Солідарне',
  'UA5908025001': 'Степанівка',
  'UA5908025016': 'Степне',
  'UA5908025005': 'Закумське',
  'UA5908027002': 'Битиця',
  'UA5908027010': 'Кардашівка',
  'UA5908027020': 'Хомине',
  'UA5908027011': 'Кирияківщина',
  'UA5908027012': 'Липняк',
  'UA5908027013': 'Микільське',
  'UA5908027014': 'Піщане',
  'UA5908027015': 'Пушкарівка',
  'UA5908027016': 'Радьківка',
  'UA5908027017': 'Рибці',
  'UA5908027021': 'Шевченкове',
  'UA5908027018': 'Стецьківка',
  'UA5908027001': 'Суми',
  'UA5908027019': 'Трохименкове',
  'UA5908027003': 'Вакалівщина',
  'UA5908027004': 'Велика Чернеччина',
  'UA5908027005': 'Верхнє Піщане',
  'UA5908027006': 'Вільшанка',
  'UA5908027008': 'Загірське',
  'UA5908027009': 'Зелений Гай',
  'UA5908027007': 'Житейське',
  'UA5908005004': 'Івахнівка',
  'UA5908005010': 'Кам\'яне',
  'UA5908005005': 'Малий Бобрик',
  'UA5908005006': 'Новоселиця',
  'UA5908005007': 'Стінка',
  'UA5908005002': 'Великий Бобрик',
  'UA5908005001': 'Верхня Сироватка',
  'UA5908005008': 'Юсупівка',
  'UA5908005009': 'Захарівське',
  'UA5908005003': 'Залізняк',
  'UA5908007012': 'Череватівка',
  'UA5908007011': 'Червоне',
  'UA5908007003': 'Ганнівське',
  'UA5908007004': 'Гезівка',
  'UA5908007005': 'Кальченки',
  'UA5908007006': 'Крижик',
  'UA5908007007': 'Миколаївка-Тернівська',
  'UA5908007008': 'Нагірнівка',
  'UA5908007009': 'Сергіївка',
  'UA5908007013': 'Шкуратівка',
  'UA5908007010': 'Цимбалівка',
  'UA5908007002': 'Воронине',
  'UA5908007001': 'Ворожба',
  'UA5908031002': 'Басівка',
  'UA5908031012': 'Іволжанське',
  'UA5908031009': 'Храпівщина',
  'UA5908031003': 'Корчаківка',
  'UA5908031013': 'Кияниця',
  'UA5908031004': 'Локня',
  'UA5908031014': 'Мала Корчаківка',
  'UA5908031015': 'Мар\'їне',
  'UA5908031005': 'Могриця',
  'UA5908031006': 'Нова Січ',
  'UA5908031007': 'Новеньке',
  'UA5908031008': 'Садки',
  'UA5908031011': 'Варачине',
  'UA5908031010': 'Яблунівка',
  'UA5908031001': 'Юнаківка',
  'UA6106003001': 'Більче-Золоте',
  'UA6106003003': 'Монастирок',
  'UA6106003004': 'Мушкарів',
  'UA6106003002': 'Мишків',
  'UA6106003005': 'Олексинці',
  'UA6106003006': 'Шершенівка',
  'UA6106003007': 'Юр\'ямпіль',
  'UA6106001002': 'Базар',
  'UA6106001001': 'Білобожниця',
  'UA6106001003': 'Білий Потік',
  'UA6106001004': 'Буданів',
  'UA6106001005': 'Джурин',
  'UA6106001006': 'Джуринська Слобідка',
  'UA6106001008': 'Калинівщина',
  'UA6106001009': 'Косів',
  'UA6106001010': 'Криволука',
  'UA6106001011': 'Мазурівка',
  'UA6106001012': 'Палашівка',
  'UA6106001013': 'Папірня',
  'UA6106001014': 'Полівці',
  'UA6106001016': 'Ромашівка',
  'UA6106001015': 'Ридодуби',
  'UA6106001017': 'Семаківці',
  'UA6106001018': 'Скомороше',
  'UA6106001007': 'Звиняч',
  'UA6106005002': 'Бабинці',
  'UA6106005001': 'Борщів',
  'UA6106005006': 'Глибочок',
  'UA6106005007': 'Грабівці',
  'UA6106005023': 'Худіївці',
  'UA6106005010': 'Констанція',
  'UA6106005011': 'Королівка',
  'UA6106005009': 'Козаччина',
  'UA6106005012': 'Кривче',
  'UA6106005013': 'Ланівці',
  'UA6106005014': 'Мушкатівка',
  'UA6106005015': 'Озеряни',
  'UA6106005016': 'Пилатківці',
  'UA6106005017': 'Пищатинці',
  'UA6106005018': 'Сапогів',
  'UA6106005026': 'Шупарка',
  'UA6106005025': 'Шишківці',
  'UA6106005019': 'Сков\'ятин',
  'UA6106005020': 'Слобідка-Мушкатівська',
  'UA6106005021': 'Стрілківці',
  'UA6106005024': 'Цигани',
  'UA6106005022': 'Тулин',
  'UA6106005003': 'Верхняківці',
  'UA6106005005': 'Вовківці',
  'UA6106005004': 'Висічка',
  'UA6106005008': 'Жилинці',
  'UA6106007002': 'Бариш',
  'UA6106007003': 'Берем\'яни',
  'UA6106007004': 'Білявинці',
  'UA6106007005': 'Бобулинці',
  'UA6106007006': 'Броварі',
  'UA6106007001': 'Бучач',
  'UA6106007008': 'Доброполе',
  'UA6106007009': 'Дуліби',
  'UA6106007018': 'Курдибанівка',
  'UA6106007017': 'Киданів',
  'UA6106007019': 'Ліщанці',
  'UA6106007020': 'Матеушівка',
  'UA6106007021': 'Нові Петликівці',
  'UA6106007022': 'Новосілка',
  'UA6106007024': 'Осівці',
  'UA6106007023': 'Озеряни',
  'UA6106007026': 'Передмістя',
  'UA6106007025': 'Переволока',
  'UA6106007028': 'Підлісся',
  'UA6106007027': 'Підзамочок',
  'UA6106007030': 'Помірці',
  'UA6106007031': 'Порохова',
  'UA6106007029': 'Пожежа',
  'UA6106007032': 'Пушкарі',
  'UA6106007033': 'Ріпинці',
  'UA6106007034': 'Рукомиш',
  'UA6106007035': 'Сороки',
  'UA6106007036': 'Старі Петликівці',
  'UA6106007007': 'Верб\'ятин',
  'UA6106007037': 'Язловець',
  'UA6106007012': 'Заліщики',
  'UA6106007013': 'Заривинці',
  'UA6106007015': 'Зелена',
  'UA6106007011': 'Жнибороди',
  'UA6106007010': 'Жизномир',
  'UA6106007016': 'Зубрець',
  'UA6106007014': 'Звенигород',
  'UA6106043003': 'Біла',
  'UA6106043002': 'Бичківці',
  'UA6106043001': 'Чортків',
  'UA6106043004': 'Горішня Вигнанка',
  'UA6106043005': 'Пастуше',
  'UA6106043006': 'Переходи',
  'UA6106043007': 'Росохач',
  'UA6106043008': 'Скородинці',
  'UA6106011002': 'Білинівка',
  'UA6106011003': 'Буцики',
  'UA6106011006': 'Глібів',
  'UA6106011001': 'Гримайлів',
  'UA6106011008': 'Калагарівка',
  'UA6106011010': 'Кокошинці',
  'UA6106011009': 'Козина',
  'UA6106011011': 'Красне',
  'UA6106011012': 'Крутилів',
  'UA6106011013': 'Кут',
  'UA6106011014': 'Лежанівка',
  'UA6106011015': 'Мала Лука',
  'UA6106011016': 'Малі Бірки',
  'UA6106011017': 'Монастириха',
  'UA6106011018': 'Новосілка',
  'UA6106011019': 'Оленівка',
  'UA6106011020': 'Паївка',
  'UA6106011021': 'Пізнанка',
  'UA6106011022': 'Раштівці',
  'UA6106011023': 'Саджівка',
  'UA6106011024': 'Ставки',
  'UA6106011025': 'Товсте',
  'UA6106011004': 'Вікно',
  'UA6106011005': 'Волиця',
  'UA6106011007': 'Зелене',
  'UA6106013002': 'Боднарівка',
  'UA6106013003': 'Босири',
  'UA6106013006': 'Городниця',
  'UA6106013001': 'Гусятин',
  'UA6106013008': 'Коцюбинчики',
  'UA6106013009': 'Кривеньке',
  'UA6106013010': 'Личківці',
  'UA6106013011': 'Постолівка',
  'UA6106013012': 'Самолусківці',
  'UA6106013017': 'Шидлівці',
  'UA6106013014': 'Сокиринці',
  'UA6106013015': 'Суходіл',
  'UA6106013013': 'Сидорів',
  'UA6106013016': 'Трибухівці',
  'UA6106013004': 'Васильків',
  'UA6106013005': 'Вільхівчик',
  'UA6106013007': 'Зелена',
  'UA6106021002': 'Гермаківка',
  'UA6106021001': 'Іване-Пусте',
  'UA6106021004': 'Пилипче',
  'UA6106021003': 'Залісся',
  'UA6106041004': 'Карашинці',
  'UA6106041010': 'Хлопівка',
  'UA6106041001': 'Хоростків',
  'UA6106041005': 'Клювинці',
  'UA6106041006': 'Малий Говилів',
  'UA6106041007': 'Перемилів',
  'UA6106041008': 'Сорока',
  'UA6106041009': 'Увисла',
  'UA6106041002': 'Великий Говилів',
  'UA6106041003': 'Верхівці',
  'UA6106023008': 'Чорнокінецька Воля',
  'UA6106023003': 'Давидківці',
  'UA6106023001': 'Колиндяни',
  'UA6106023004': 'Малі Чорнокінці',
  'UA6106023005': 'Пробіжна',
  'UA6106023006': 'Тарнавка',
  'UA6106023007': 'Товстеньке',
  'UA6106023002': 'Великі Чорнокінці',
  'UA6106025003': 'Гадинківці',
  'UA6106025001': 'Копичинці',
  'UA6106025005': 'Котівка',
  'UA6106025006': 'Майдан',
  'UA6106025007': 'Оришківці',
  'UA6106025008': 'Рудки',
  'UA6106025009': 'Сухостав',
  'UA6106025010': 'Теклівка',
  'UA6106025011': 'Тудорів',
  'UA6106025002': 'Вигода',
  'UA6106025012': 'Яблунів',
  'UA6106025004': 'Ємелівка',
  'UA6106027005': 'Діброва',
  'UA6106027004': 'Горигляди',
  'UA6106027001': 'Коропець',
  'UA6106027006': 'Садове',
  'UA6106027008': 'Стигла',
  'UA6106027007': 'Світле',
  'UA6106027002': 'Вербка',
  'UA6106027003': 'Вістря',
  'UA6106029002': 'Білівці',
  'UA6106029003': 'Боришківці',
  'UA6106029009': 'Дністрове',
  'UA6106029007': 'Дзвенигород',
  'UA6106029008': 'Дзвинячка',
  'UA6106029006': 'Горошова',
  'UA6106029022': 'Худиківці',
  'UA6106029013': 'Кудринці',
  'UA6106029014': 'Латківці',
  'UA6106029001': 'Мельниця-Подільська',
  'UA6106029015': 'Михайлівка',
  'UA6106029016': 'Михалків',
  'UA6106029017': 'Окопи',
  'UA6106029018': 'Панівці',
  'UA6106029019': 'Трубчин',
  'UA6106029020': 'Урожайне',
  'UA6106029021': 'Устя',
  'UA6106029005': 'Вільховець',
  'UA6106029004': 'Вигода',
  'UA6106029010': 'Завалля',
  'UA6106029011': 'Збручанське',
  'UA6106029012': 'Зелене',
  'UA6106031002': 'Бертники',
  'UA6106031003': 'Бобрівники',
  'UA6106031038': 'Чехів',
  'UA6106031011': 'Доброводи',
  'UA6106031012': 'Дубенка',
  'UA6106031006': 'Гончарівка',
  'UA6106031007': 'Горішня Слобідка',
  'UA6106031008': 'Горожанка',
  'UA6106031009': 'Гранітне',
  'UA6106031010': 'Григорів',
  'UA6106031019': 'Комарівка',
  'UA6106031020': 'Коржова',
  'UA6106031018': 'Ковалівка',
  'UA6106031021': 'Красіїв',
  'UA6106031022': 'Криниця',
  'UA6106031023': 'Лазарівка',
  'UA6106031025': 'Лядське',
  'UA6106031024': 'Лука',
  'UA6106031026': 'Маркова',
  'UA6106031027': 'Межигір\'я',
  'UA6106031001': 'Монастириська',
  'UA6106031029': 'Нова Гута',
  'UA6106031028': 'Низьколизи',
  'UA6106031030': 'Олеша',
  'UA6106031031': 'Підлісне',
  'UA6106031032': 'Рідколісся',
  'UA6106031034': 'Саджівка',
  'UA6106031033': 'Савелівка',
  'UA6106031035': 'Сеньків',
  'UA6106031039': 'Швейків',
  'UA6106031036': 'Тростянці',
  'UA6106031037': 'Устя-Зелене',
  'UA6106031004': 'Велеснів',
  'UA6106031005': 'Високе',
  'UA6106031040': 'Яргорів',
  'UA6106031014': 'Задарів',
  'UA6106031015': 'Залісся',
  'UA6106031016': 'Заставці',
  'UA6106031017': 'Затишне',
  'UA6106031013': 'Завадівка',
  'UA6106033010': 'Черкавщина',
  'UA6106033002': 'Долина',
  'UA6106033004': 'Капустинці',
  'UA6106033006': 'Мухавка',
  'UA6106033005': 'Милівці',
  'UA6106033001': 'Нагірянка',
  'UA6106033011': 'Шульганівка',
  'UA6106033007': 'Сосулівка',
  'UA6106033008': 'Стара Ягільниця',
  'UA6106033009': 'Улашківці',
  'UA6106033012': 'Ягільниця',
  'UA6106033003': 'Заболотівка',
  'UA6106035002': 'Бережанка',
  'UA6106035003': 'Бурдяківці',
  'UA6106035007': 'Дубівка',
  'UA6106035005': 'Гуштин',
  'UA6106035006': 'Гуштинка',
  'UA6106035010': 'Іванків',
  'UA6106035011': 'Лосяч',
  'UA6106035012': 'Нивра',
  'UA6106035013': 'Підпилип\'я',
  'UA6106035001': 'Скала-Подільська',
  'UA6106035014': 'Трійця',
  'UA6106035015': 'Турильче',
  'UA6106035004': 'Вербівка',
  'UA6106035008': 'Залуччя',
  'UA6106035009': 'Збриж',
  'UA6106037002': 'Ангелівка',
  'UA6106037003': 'Антонів',
  'UA6106037004': 'Буряківка',
  'UA6106037008': 'Дорогичівка',
  'UA6106037007': 'Головчинці',
  'UA6106037006': 'Гиньківці',
  'UA6106037024': 'Хмелева',
  'UA6106037009': 'Королівка',
  'UA6106037010': 'Кошилівці',
  'UA6106037011': 'Лисівці',
  'UA6106037012': 'Литячі',
  'UA6106037013': 'Нагоряни',
  'UA6106037014': 'Нирків',
  'UA6106037015': 'Поділля',
  'UA6106037016': 'Попівці',
  'UA6106037017': 'Рожанівка',
  'UA6106037018': 'Садки',
  'UA6106037026': 'Шутроминці',
  'UA6106037025': 'Шипівці',
  'UA6106037021': 'Слобідка',
  'UA6106037022': 'Солоне',
  'UA6106037019': 'Свершківці',
  'UA6106037020': 'Свидова',
  'UA6106037001': 'Товсте',
  'UA6106037023': 'Устечко',
  'UA6106037005': 'Ворвулинці',
  'UA6106039002': 'Мартинівка',
  'UA6106039003': 'Медведівці',
  'UA6106039004': 'Новоставці',
  'UA6106039005': 'Пилява',
  'UA6106039006': 'Пишківці',
  'UA6106039001': 'Трибухівці',
  'UA6106039007': 'Цвітова',
  'UA6106009008': 'Чабарівка',
  'UA6106009009': 'Чагарі',
  'UA6106009003': 'Коцюбинці',
  'UA6106009004': 'Крогулець',
  'UA6106009005': 'Нижбірок',
  'UA6106009006': 'Старий Нижбірок',
  'UA6106009007': 'Целіїв',
  'UA6106009001': 'Васильківці',
  'UA6106009002': 'Жабинці',
  'UA6106017002': 'Бедриківці',
  'UA6106017003': 'Бересток',
  'UA6106017004': 'Блищанка',
  'UA6106017012': 'Добрівляни',
  'UA6106017013': 'Дунів',
  'UA6106017014': 'Дуплиська',
  'UA6106017011': 'Дзвиняч',
  'UA6106017008': 'Глушка',
  'UA6106017009': 'Голігради',
  'UA6106017010': 'Городок',
  'UA6106017017': 'Іване-Золоте',
  'UA6106017018': 'Касперівці',
  'UA6106017028': 'Хартонівці',
  'UA6106017019': 'Колодрібка',
  'UA6106017020': 'Кулаківці',
  'UA6106017021': 'Лисичники',
  'UA6106017022': 'Новосілка',
  'UA6106017023': 'Печорна',
  'UA6106017029': 'Щитівці',
  'UA6106017025': 'Ставки',
  'UA6106017024': 'Синьків',
  'UA6106017026': 'Торське',
  'UA6106017027': 'Угриньківці',
  'UA6106017005': 'Вигода',
  'UA6106017007': 'Винятинці',
  'UA6106017006': 'Виноградне',
  'UA6106017030': 'Якубівка',
  'UA6106017001': 'Заліщики',
  'UA6106017015': 'Зелений Гай',
  'UA6106017016': 'Зозулинці',
  'UA6106015006': 'Шманьківчики',
  'UA6106015005': 'Шманьківці',
  'UA6106015004': 'Швайківці',
  'UA6106015003': 'Угринь',
  'UA6106015002': 'Залісся',
  'UA6106015001': 'Заводське',
  'UA6106019003': 'Губин',
  'UA6106019004': 'Космирин',
  'UA6106019005': 'Костільники',
  'UA6106019007': 'Млинки',
  'UA6106019006': 'Миколаївка',
  'UA6106019008': 'Набережне',
  'UA6106019009': 'Рублин',
  'UA6106019010': 'Русилів',
  'UA6106019011': 'Скоморохи',
  'UA6106019012': 'Сновидів',
  'UA6106019013': 'Сокілець',
  'UA6106019014': 'Соколів',
  'UA6106019015': 'Стінка',
  'UA6106019002': 'Возилів',
  'UA6106019001': 'Золотий Потік',
  'UA6102001002': 'Борщівка',
  'UA6102001001': 'Борсуки',
  'UA6102001011': 'Чайчинці',
  'UA6102001004': 'Мала Снігурівка',
  'UA6102001005': 'Маневе',
  'UA6102001006': 'Нападівка',
  'UA6102001007': 'Передмірка',
  'UA6102001008': 'Піщатинці',
  'UA6102001010': 'Снігурівка',
  'UA6102001009': 'Синівці',
  'UA6102001003': 'Великі Кусківці',
  'UA6102007002': 'Андруга',
  'UA6102007003': 'Білокриниця',
  'UA6102007004': 'Богданівка',
  'UA6102007005': 'Бонівка',
  'UA6102007043': 'Чугалі',
  'UA6102007015': 'Діброва',
  'UA6102007017': 'Духів',
  'UA6102007016': 'Дунаїв',
  'UA6102007014': 'Двірець',
  'UA6102007011': 'Гаї',
  'UA6102007012': 'Горинка',
  'UA6102007013': 'Града',
  'UA6102007021': 'Іква',
  'UA6102007020': 'Іванківці',
  'UA6102007022': 'Катеринівка',
  'UA6102007042': 'Хотівка',
  'UA6102007023': 'Кімната',
  'UA6102007024': 'Колосова',
  'UA6102007001': 'Кременець',
  'UA6102007025': 'Крижі',
  'UA6102007026': 'Кудлаївка',
  'UA6102007027': 'Куликів',
  'UA6102007028': 'Кушлин',
  'UA6102007029': 'Лішня',
  'UA6102007030': 'Малі Бережці',
  'UA6102007031': 'Новий Кокорів',
  'UA6102007032': 'Підгайці',
  'UA6102007033': 'Підлісне',
  'UA6102007034': 'Підлісці',
  'UA6102007035': 'Плоске',
  'UA6102007036': 'Попівці',
  'UA6102007038': 'Рудка',
  'UA6102007037': 'Рибча',
  'UA6102007040': 'Сапанів',
  'UA6102007039': 'Савчиці',
  'UA6102007044': 'Шпиколоси',
  'UA6102007041': 'Старий Кокорів',
  'UA6102007006': 'Великі Бережці',
  'UA6102007007': 'Великі Млинівці',
  'UA6102007008': 'Вербиця',
  'UA6102007009': 'Весела',
  'UA6102007010': 'Веселівка',
  'UA6102007019': 'Зеблази',
  'UA6102007018': 'Жолоби',
  'UA6102009002': 'Бережанка',
  'UA6102009003': 'Білозірка',
  'UA6102009004': 'Буглів',
  'UA6102009012': 'Грибова',
  'UA6102009013': 'Гриньки',
  'UA6102009016': 'Іванківці',
  'UA6102009019': 'Коростова',
  'UA6102009018': 'Коржківці',
  'UA6102009017': 'Козачки',
  'UA6102009020': 'Краснолука',
  'UA6102009021': 'Кутиска',
  'UA6102009001': 'Ланівці',
  'UA6102009023': 'Люлинці',
  'UA6102009022': 'Лопушне',
  'UA6102009024': 'Мала Білка',
  'UA6102009025': 'Мала Карначівка',
  'UA6102009026': 'Малі Кусківці',
  'UA6102009027': 'Мартишківці',
  'UA6102009029': 'Молотків',
  'UA6102009030': 'Москалівка',
  'UA6102009028': 'Михайлівка',
  'UA6102009031': 'Огризківці',
  'UA6102009032': 'Оришківці',
  'UA6102009033': 'Осники',
  'UA6102009034': 'Пахиня',
  'UA6102009035': 'Печірна',
  'UA6102009036': 'Плиска',
  'UA6102009039': 'Шушківці',
  'UA6102009037': 'Соколівка',
  'UA6102009038': 'Татаринці',
  'UA6102009005': 'Ванжулів',
  'UA6102009006': 'Велика Білка',
  'UA6102009007': 'Вербовець',
  'UA6102009008': 'Верещаки',
  'UA6102009010': 'Влащинці',
  'UA6102009011': 'Волиця',
  'UA6102009009': 'Вишгородок',
  'UA6102009041': 'Якимівці',
  'UA6102009040': 'Юськівці',
  'UA6102009015': 'Загірці',
  'UA6102009014': 'Жуківці',
  'UA6102011002': 'Башуки',
  'UA6102011005': 'Івання',
  'UA6102011006': 'Крутнів',
  'UA6102011001': 'Лопушне',
  'UA6102011007': 'Мала Горянка',
  'UA6102011008': 'Новий Олексинець',
  'UA6102011009': 'Раславка',
  'UA6102011010': 'Розтоки',
  'UA6102011011': 'Старий Олексинець',
  'UA6102011003': 'Велика Горянка',
  'UA6102011004': 'Волиця',
  'UA6102013002': 'Борщівка',
  'UA6102013003': 'Будки',
  'UA6102013007': 'Комарівка',
  'UA6102013006': 'Комарин',
  'UA6102013008': 'Лідихів',
  'UA6102013009': 'Лосятин',
  'UA6102013001': 'Почаїв',
  'UA6102013010': 'Ридомиль',
  'UA6102013011': 'Старий Почаїв',
  'UA6102013012': 'Старий Тараж',
  'UA6102013004': 'Валігури',
  'UA6102013005': 'Затишшя',
  'UA6102015002': 'Андрушівка',
  'UA6102015003': 'Антонівці',
  'UA6102015004': 'Башківці',
  'UA6102015006': 'Бірки',
  'UA6102015007': 'Боложівка',
  'UA6102015008': 'Бриків',
  'UA6102015005': 'Биківці',
  'UA6102015042': 'Ходаки',
  'UA6102015016': 'Коновиця',
  'UA6102015017': 'Кордишів',
  'UA6102015018': 'Круголець',
  'UA6102015020': 'Кутянка',
  'UA6102015019': 'Кути',
  'UA6102015021': 'Літовище',
  'UA6102015022': 'Людвище',
  'UA6102015023': 'Мала Іловиця',
  'UA6102015024': 'Малі Садки',
  'UA6102015025': 'Мирове',
  'UA6102015026': 'Новосілка',
  'UA6102015027': 'Новостав',
  'UA6102015028': 'Обич',
  'UA6102015029': 'Одерадівка',
  'UA6102015030': 'Онишківці',
  'UA6102015031': 'Переморівка',
  'UA6102015032': 'Потуторів',
  'UA6102015033': 'Рохманів',
  'UA6102015034': 'Руська Гута',
  'UA6102015044': 'Шумбар',
  'UA6102015001': 'Шумськ',
  'UA6102015036': 'Сошище',
  'UA6102015035': 'Соснівка',
  'UA6102015037': 'Стіжок',
  'UA6102015038': 'Сураж',
  'UA6102015039': 'Тетильківці',
  'UA6102015043': 'Цеценівка',
  'UA6102015040': 'Тилявка',
  'UA6102015041': 'Угорськ',
  'UA6102015009': 'Васьківці',
  'UA6102015010': 'Велика Іловиця',
  'UA6102015011': 'Вілія',
  'UA6102015013': 'Забара',
  'UA6102015014': 'Залісці',
  'UA6102015015': 'Залужжя',
  'UA6102015012': 'Жолобки',
  'UA6102003004': 'Гриньківці',
  'UA6102003005': 'Малі Дедеркали',
  'UA6102003006': 'Малі Загайці',
  'UA6102003007': 'Матвіївці',
  'UA6102003008': 'Мізюринці',
  'UA6102003009': 'Радошівка',
  'UA6102003010': 'Садки',
  'UA6102003013': 'Шкроботівка',
  'UA6102003011': 'Темногайці',
  'UA6102003012': 'Тури',
  'UA6102003001': 'Великі Дедеркали',
  'UA6102003002': 'Великі Загайці',
  'UA6102003003': 'Вовківці',
  'UA6102005002': 'Бакоти',
  'UA6102005003': 'Бодаки',
  'UA6102005004': 'Бутин',
  'UA6102005009': 'Діброва',
  'UA6102005008': 'Дзвиняча',
  'UA6102005027': 'Федьківці',
  'UA6102005007': 'Гнидава',
  'UA6102005028': 'Хотовиця',
  'UA6102005015': 'Коханівка',
  'UA6102005014': 'Котюжини',
  'UA6102005016': 'Кривчики',
  'UA6102005013': 'Кинахівці',
  'UA6102005017': 'Лози',
  'UA6102005019': 'Малі Вікнини',
  'UA6102005018': 'Малий Кунинець',
  'UA6102005021': 'Млинівці',
  'UA6102005020': 'Мишківці',
  'UA6102005022': 'Очеретне',
  'UA6102005023': 'Поляни',
  'UA6102005024': 'Раковець',
  'UA6102005025': 'Старий Вишнівець',
  'UA6102005026': 'Устечко',
  'UA6102005006': 'Великі Вікнини',
  'UA6102005005': 'Великий Кунинець',
  'UA6102005001': 'Вишнівець',
  'UA6102005010': 'Загороддя',
  'UA6102005011': 'Залісці',
  'UA6102005012': 'Зашляхом',
  'UA6104001002': 'Ангелівка',
  'UA6104001001': 'Байківці',
  'UA6104001014': 'Чернелів-Руський',
  'UA6104001005': 'Дубівці',
  'UA6104001003': 'Гаї-Гречинські',
  'UA6104001004': 'Гаї-Шевченківські',
  'UA6104001006': 'Курники',
  'UA6104001007': 'Лозова',
  'UA6104001008': 'Охримівці',
  'UA6104001009': 'Романівка',
  'UA6104001010': 'Романове Село',
  'UA6104001015': 'Шляхтинці',
  'UA6104001011': 'Соборне',
  'UA6104001012': 'Стегниківці',
  'UA6104001013': 'Ступки',
  'UA6104003002': 'Баранівка',
  'UA6104003001': 'Бережани',
  'UA6104003003': 'Біще',
  'UA6104003004': 'Гиновичі',
  'UA6104003008': 'Комарівка',
  'UA6104003009': 'Краснопуща',
  'UA6104003010': 'Куропатники',
  'UA6104003011': 'Лісники',
  'UA6104003012': 'Надрічне',
  'UA6104003013': 'Підлісне',
  'UA6104003014': 'Пліхів',
  'UA6104003015': 'Поручин',
  'UA6104003016': 'Посухів',
  'UA6104003017': 'Потутори',
  'UA6104003018': 'Рай',
  'UA6104003020': 'Шибалин',
  'UA6104003019': 'Урмань',
  'UA6104003021': 'Ясне',
  'UA6104003007': 'Залужжя',
  'UA6104003005': 'Жовнівка',
  'UA6104003006': 'Жуків',
  'UA6104005001': 'Біла',
  'UA6104005010': 'Чистилів',
  'UA6104005003': 'Дітківці',
  'UA6104005006': 'Ігровиця',
  'UA6104005005': 'Івачів Долішній',
  'UA6104005004': 'Івачів Горішній',
  'UA6104005009': 'Хомівка',
  'UA6104005007': 'Мшанець',
  'UA6104005008': 'Плотича',
  'UA6104005002': 'Великий Глибочок',
  'UA6104021002': 'Глещава',
  'UA6104021003': 'Ілавче',
  'UA6104021001': 'Іванівка',
  'UA6104021004': 'Лозівка',
  'UA6104021005': 'Сороцьке',
  'UA6104023002': 'Бартошівка',
  'UA6104023005': 'Будова',
  'UA6104023004': 'Будилів',
  'UA6104023003': 'Бишки',
  'UA6104023014': 'Дибще',
  'UA6104023010': 'Геленки',
  'UA6104023011': 'Глинна',
  'UA6104023012': 'Горби',
  'UA6104023013': 'Городище',
  'UA6104023020': 'Кальне',
  'UA6104023022': 'Конюхи',
  'UA6104023021': 'Козівка',
  'UA6104023001': 'Козова',
  'UA6104023023': 'Криве',
  'UA6104023024': 'Маковисько',
  'UA6104023025': 'Мала Плавуча',
  'UA6104023026': 'Медова',
  'UA6104023027': 'Млинці',
  'UA6104023028': 'Олесине',
  'UA6104023029': 'Плоске',
  'UA6104023030': 'Плотича',
  'UA6104023031': 'Потік',
  'UA6104023032': 'Сеньків',
  'UA6104023036': 'Щепанів',
  'UA6104023033': 'Теофіпілка',
  'UA6104023035': 'Ценів',
  'UA6104023034': 'Уритва',
  'UA6104023009': 'Вікторівка',
  'UA6104023008': 'Вівся',
  'UA6104023006': 'Вибудів',
  'UA6104023007': 'Вимислівка',
  'UA6104023019': 'Йосипівка',
  'UA6104023015': 'Заберізки',
  'UA6104023016': 'Залісся',
  'UA6104023018': 'Золочівка',
  'UA6104023017': 'Золота Слобода',
  'UA6104025002': 'Дмухівці',
  'UA6104025001': 'Козлів',
  'UA6104025003': 'Покропивна',
  'UA6104025004': 'Слобідка',
  'UA6104025005': 'Таурів',
  'UA6104027004': 'Денисів',
  'UA6104027005': 'Драгоманівка',
  'UA6104027003': 'Дворище',
  'UA6104027006': 'Ішків',
  'UA6104027001': 'Купчинці',
  'UA6104027007': 'Росохуватець',
  'UA6104027002': 'Веснівка',
  'UA6104027008': 'Яструбове',
  'UA6104029003': 'Бернадівка',
  'UA6104029006': 'Дарахів',
  'UA6104029002': 'Дружба',
  'UA6104029007': 'Дворіччя',
  'UA6104029010': 'Кам\'янка',
  'UA6104029020': 'Хмелівка',
  'UA6104029011': 'Конопківка',
  'UA6104029012': 'Кривки',
  'UA6104029013': 'Ладичин',
  'UA6104029001': 'Микулинці',
  'UA6104029014': 'Налужжя',
  'UA6104029015': 'Нова Брикуля',
  'UA6104029016': 'Різдвяни',
  'UA6104029017': 'Стара Брикуля',
  'UA6104029018': 'Струсів',
  'UA6104029019': 'Тютьків',
  'UA6104029004': 'Варваринці',
  'UA6104029005': 'Воля',
  'UA6104029008': 'Заздрість',
  'UA6104029009': 'Зубів',
  'UA6104031007': 'Демня',
  'UA6104031008': 'Дуляби',
  'UA6104031006': 'Двірці',
  'UA6104031004': 'Гайок',
  'UA6104031005': 'Гутисько',
  'UA6104031009': 'Куряни',
  'UA6104031010': 'Лапшин',
  'UA6104031001': 'Нараїв',
  'UA6104031011': 'Павлів',
  'UA6104031013': 'Підвисоке',
  'UA6104031014': 'Поточани',
  'UA6104031012': 'Писарівка',
  'UA6104031015': 'Рекшин',
  'UA6104031016': 'Рогачин',
  'UA6104031018': 'Шайбівка',
  'UA6104031017': 'Стриганці',
  'UA6104031002': 'Вербів',
  'UA6104031003': 'Волиця',
  'UA6104033002': 'Білківці',
  'UA6104033003': 'Богданівка',
  'UA6104033006': 'Данилівці',
  'UA6104033007': 'Кокутківці',
  'UA6104033008': 'Нестерівці',
  'UA6104033009': 'Осташівці',
  'UA6104033001': 'Озерна',
  'UA6104033010': 'Серединці',
  'UA6104033011': 'Сировари',
  'UA6104033012': 'Цебрів',
  'UA6104033005': 'Воробіївка',
  'UA6104033004': 'Висипівці',
  'UA6104033013': 'Яцківці',
  'UA6104037002': 'Білокриниця',
  'UA6104037003': 'Боків',
  'UA6104037004': 'Бронгалівка',
  'UA6104037034': 'Червень',
  'UA6104037008': 'Галич',
  'UA6104037009': 'Гнильче',
  'UA6104037011': 'Голендра',
  'UA6104037010': 'Голгоча',
  'UA6104037016': 'Лиса',
  'UA6104037017': 'Литвинів',
  'UA6104037020': 'Мозолівка',
  'UA6104037021': 'Мужилів',
  'UA6104037019': 'Михайлівка',
  'UA6104037018': 'Мирне',
  'UA6104037023': 'Носів',
  'UA6104037022': 'Новосілка',
  'UA6104037024': 'Пановичі',
  'UA6104037001': 'Підгайці',
  'UA6104037025': 'Поплави',
  'UA6104037026': 'Рудники',
  'UA6104037027': 'Середнє',
  'UA6104037028': 'Сільце',
  'UA6104037029': 'Сонячне',
  'UA6104037030': 'Старе Місто',
  'UA6104037031': 'Старий Литвинів',
  'UA6104037032': 'Степове',
  'UA6104037033': 'Угринів',
  'UA6104037005': 'Вага',
  'UA6104037006': 'Вербів',
  'UA6104037007': 'Волиця',
  'UA6104037036': 'Яблунівка',
  'UA6104037035': 'Юстинівка',
  'UA6104037013': 'Загайці',
  'UA6104037014': 'Заставче',
  'UA6104037015': 'Затурин',
  'UA6104037012': 'Завалів',
  'UA6104039004': 'Домаморич',
  'UA6104039003': 'Довжанка',
  'UA6104039005': 'Драганівка',
  'UA6104039001': 'Підгородне',
  'UA6104039007': 'Почапинці',
  'UA6104039002': 'Великий Ходачків',
  'UA6104039006': 'Забойки',
  'UA6104035002': 'Богданівка',
  'UA6104035022': 'Чернилівка',
  'UA6104035004': 'Дорофіївка',
  'UA6104035020': 'Фащівка',
  'UA6104035003': 'Галущинці',
  'UA6104035006': 'Іванівка',
  'UA6104035008': 'Качанівка',
  'UA6104035007': 'Кам\'янки',
  'UA6104035021': 'Хмелиська',
  'UA6104035009': 'Клебанівка',
  'UA6104035010': 'Коршилівка',
  'UA6104035012': 'Мовчанівка',
  'UA6104035011': 'Мислова',
  'UA6104035013': 'Оріховець',
  'UA6104035001': 'Підволочиськ',
  'UA6104035015': 'Росохуватець',
  'UA6104035014': 'Рожиськ',
  'UA6104035023': 'Шевченкове',
  'UA6104035016': 'Староміщина',
  'UA6104035017': 'Супранівка',
  'UA6104035018': 'Тарноруда',
  'UA6104035019': 'Турівка',
  'UA6104035005': 'Жеребки',
  'UA6104041002': 'Базниківка',
  'UA6104041003': 'Божиків',
  'UA6104041018': 'Червоне',
  'UA6104041006': 'Діброва',
  'UA6104041008': 'Котів',
  'UA6104041009': 'Кути',
  'UA6104041007': 'Квіткове',
  'UA6104041010': 'Літятин',
  'UA6104041011': 'Мечищів',
  'UA6104041012': 'Молохів',
  'UA6104041013': 'Надорожнів',
  'UA6104041014': 'Нова Гребля',
  'UA6104041015': 'Рибники',
  'UA6104041001': 'Саранчуки',
  'UA6104041019': 'Шумляни',
  'UA6104041016': 'Слов\'ятин',
  'UA6104041017': 'Тростянець',
  'UA6104041004': 'Вільховець',
  'UA6104041005': 'Волощина',
  'UA6104043002': 'Городниця',
  'UA6104043016': 'Хоптянка',
  'UA6104043004': 'Колодіївка',
  'UA6104043005': 'Криве',
  'UA6104043006': 'Магдалівка',
  'UA6104043007': 'Митниця',
  'UA6104043008': 'Новосілка',
  'UA6104043009': 'Остап\'є',
  'UA6104043010': 'Панасівка',
  'UA6104043011': 'Поділля',
  'UA6104043012': 'Полупанівка',
  'UA6104043013': 'Поплави',
  'UA6104043001': 'Скалат',
  'UA6104043014': 'Старий Скалат',
  'UA6104043015': 'Теклівка',
  'UA6104043003': 'Зарубинці',
  'UA6104045004': 'Гнилички',
  'UA6104045003': 'Гнилиці',
  'UA6104045006': 'Голошинці',
  'UA6104045005': 'Голотки',
  'UA6104045007': 'Гущанки',
  'UA6104045008': 'Климківці',
  'UA6104045010': 'Кошляки',
  'UA6104045009': 'Козярі',
  'UA6104045012': 'Лозівка',
  'UA6104045011': 'Лисичинці',
  'UA6104045013': 'Медин',
  'UA6104045014': 'Нове Село',
  'UA6104045015': 'Ободівка',
  'UA6104045016': 'Пальчинці',
  'UA6104045017': 'Пеньківці',
  'UA6104045018': 'Просівці',
  'UA6104045023': 'Щаснівка',
  'UA6104045022': 'Шельпаки',
  'UA6104045001': 'Скорики',
  'UA6104045019': 'Сухівці',
  'UA6104045020': 'Терпилівка',
  'UA6104045021': 'Токи',
  'UA6104045002': 'Воробіївка',
  'UA6104047002': 'Боричівка',
  'UA6104047005': 'Деренівка',
  'UA6104047007': 'Долина',
  'UA6104047006': 'Довге',
  'UA6104047004': 'Гумниська',
  'UA6104047011': 'Кобиловолоки',
  'UA6104047012': 'Кровинка',
  'UA6104047013': 'Ласківці',
  'UA6104047014': 'Лошнів',
  'UA6104047015': 'Малів',
  'UA6104047016': 'Млиниська',
  'UA6104047017': 'Мшанець',
  'UA6104047018': 'Нова Могильниця',
  'UA6104047019': 'Остальці',
  'UA6104047020': 'Острівець',
  'UA6104047021': 'Підгайчики',
  'UA6104047022': 'Підгора',
  'UA6104047023': 'Плебанівка',
  'UA6104047024': 'Романівка',
  'UA6104047025': 'Семенів',
  'UA6104047026': 'Слобідка',
  'UA6104047027': 'Стара Могильниця',
  'UA6104047028': 'Сущин',
  'UA6104047001': 'Теребовля',
  'UA6104047003': 'Вербівці',
  'UA6104047008': 'Залав\'є',
  'UA6104047009': 'Застіноче',
  'UA6104047010': 'Зеленче',
  'UA6104049011': 'Чернихів',
  'UA6104049003': 'Глядки',
  'UA6104049004': 'Городище',
  'UA6104049005': 'Іванківці',
  'UA6104049006': 'Кобзарівка',
  'UA6104049007': 'Курівці',
  'UA6104049008': 'Малашівці',
  'UA6104049009': 'Носівці',
  'UA6104049010': 'Плесківці',
  'UA6104049001': 'Тернопіль',
  'UA6104049002': 'Вертелка',
  'UA6104007002': 'Буцнів',
  'UA6104007013': 'Хатки',
  'UA6104007005': 'Лучка',
  'UA6104007006': 'Мар\'янівка',
  'UA6104007007': 'Миролюбівка',
  'UA6104007008': 'Мишковичі',
  'UA6104007009': 'Настасів',
  'UA6104007010': 'Острів',
  'UA6104007011': 'Петриків',
  'UA6104007012': 'Серединки',
  'UA6104007001': 'Велика Березовиця',
  'UA6104007003': 'Велика Лука',
  'UA6104007004': 'Йосипівка',
  'UA6104009002': 'Костянтинівка',
  'UA6104009003': 'Малий Ходачків',
  'UA6104009004': 'Смиківці',
  'UA6104009001': 'Великі Бірки',
  'UA6104011002': 'Баворів',
  'UA6104011003': 'Білоскірка',
  'UA6104011005': 'Дичків',
  'UA6104011004': 'Грабовець',
  'UA6104011009': 'Козівка',
  'UA6104011010': 'Красівка',
  'UA6104011008': 'Кип\'ячка',
  'UA6104011011': 'Прошова',
  'UA6104011012': 'Скоморохи',
  'UA6104011013': 'Смолянка',
  'UA6104011014': 'Теофілівка',
  'UA6104011015': 'Товстолуг',
  'UA6104011001': 'Великі Гаї',
  'UA6104011006': 'Застав\'є',
  'UA6104011007': 'Застінка',
  'UA6104013002': 'Білоголови',
  'UA6104013003': 'Білокриниця',
  'UA6104013004': 'Бліх',
  'UA6104013018': 'Чорний Ліс',
  'UA6104013017': 'Чистопади',
  'UA6104013006': 'Гаї-Розтоцькі',
  'UA6104013005': 'Гаї-за-Рудою',
  'UA6104013008': 'Мильне',
  'UA6104013009': 'Нетерпинці',
  'UA6104013010': 'Панасівка',
  'UA6104013011': 'Підберізці',
  'UA6104013012': 'Піщане',
  'UA6104013013': 'Ратищі',
  'UA6104013014': 'Ренів',
  'UA6104013015': 'Серетець',
  'UA6104013016': 'Тростянець',
  'UA6104013007': 'Загір\'я',
  'UA6104013001': 'Залізці',
  'UA6104015002': 'Базаринці',
  'UA6104015003': 'Болязуби',
  'UA6104015047': 'Чагарі-Збаразькі',
  'UA6104015048': 'Чернихівці',
  'UA6104015049': 'Чеснівський Раковець',
  'UA6104015050': 'Чорний Ліс',
  'UA6104015051': 'Чумалі',
  'UA6104015011': 'Діброва',
  'UA6104015013': 'Добромірка',
  'UA6104015012': 'Доброводи',
  'UA6104015007': 'Глинчуки',
  'UA6104015008': 'Гніздичне',
  'UA6104015009': 'Гори-Стрийовецькі',
  'UA6104015010': 'Грицівці',
  'UA6104015018': 'Іванчани',
  'UA6104015019': 'Івашківці',
  'UA6104015020': 'Капустинський Ліс',
  'UA6104015021': 'Капустинці',
  'UA6104015022': 'Карначівка',
  'UA6104015046': 'Хоми',
  'UA6104015024': 'Кобилля',
  'UA6104015025': 'Колодне',
  'UA6104015026': 'Красносільці',
  'UA6104015027': 'Кретівці',
  'UA6104015023': 'Киданці',
  'UA6104015028': 'Ліски',
  'UA6104015029': 'Максимівка',
  'UA6104015030': 'Мала Березовиця',
  'UA6104015031': 'Малий Глибочок',
  'UA6104015032': 'Мусорівці',
  'UA6104015034': 'Новий Роговець',
  'UA6104015035': 'Новики',
  'UA6104015033': 'Нижчі Луб\'янки',
  'UA6104015036': 'Олишківці',
  'UA6104015037': 'Опрілівці',
  'UA6104015038': 'Решнівка',
  'UA6104015039': 'Розношинці',
  'UA6104015052': 'Шили',
  'UA6104015053': 'Шили',
  'UA6104015054': 'Шимківці',
  'UA6104015042': 'Старий Збараж',
  'UA6104015043': 'Стриївка',
  'UA6104015041': 'Синягівка',
  'UA6104015040': 'Синява',
  'UA6104015044': 'Тарасівка',
  'UA6104015045': 'Травневе',
  'UA6104015004': 'Верняки',
  'UA6104015006': 'Вищі Луб\'янки',
  'UA6104015005': 'Витківці',
  'UA6104015014': 'Залужжя',
  'UA6104015015': 'Зарубинці',
  'UA6104015016': 'Заруддя',
  'UA6104015017': 'Зарудечко',
  'UA6104015001': 'Збараж',
  'UA6104017002': 'Августівка',
  'UA6104017003': 'Беримівці',
  'UA6104017004': 'Бзовиця',
  'UA6104017046': 'Футори',
  'UA6104017010': 'Гарбузів',
  'UA6104017011': 'Годів',
  'UA6104017012': 'Грабківці',
  'UA6104017013': 'Гукалівці',
  'UA6104017017': 'Івачів',
  'UA6104017019': 'Кабарівці',
  'UA6104017021': 'Кальне',
  'UA6104017020': 'Калинівка',
  'UA6104017047': 'Хоробрів',
  'UA6104017048': 'Хоростець',
  'UA6104017049': 'Храбузна',
  'UA6104017022': 'Корчунок',
  'UA6104017023': 'Коршилів',
  'UA6104017024': 'Красна',
  'UA6104017026': 'Кудобинці',
  'UA6104017025': 'Кудинівці',
  'UA6104017027': 'Лавриківці',
  'UA6104017028': 'Лопушани',
  'UA6104017029': 'Манаїв',
  'UA6104017030': 'Метенів',
  'UA6104017031': 'Млинівці',
  'UA6104017032': 'Монилівка',
  'UA6104017033': 'Мшана',
  'UA6104017034': 'Нище',
  'UA6104017036': 'Оліїв',
  'UA6104017035': 'Озерянка',
  'UA6104017037': 'Перепельники',
  'UA6104017038': 'Підгайчики',
  'UA6104017039': 'Плісняни',
  'UA6104017040': 'Погрібці',
  'UA6104017041': 'Присівці',
  'UA6104017042': 'Розгадів',
  'UA6104017043': 'Славна',
  'UA6104017044': 'Травотолоки',
  'UA6104017050': 'Цецівка',
  'UA6104017051': 'Цицори',
  'UA6104017045': 'Тустоголови',
  'UA6104017005': 'Велика Плавуча',
  'UA6104017006': 'Вільшанка',
  'UA6104017007': 'Вірлів',
  'UA6104017009': 'Волосівка',
  'UA6104017008': 'Вовчківці',
  'UA6104017053': 'Ярчівці',
  'UA6104017052': 'Ярославичі',
  'UA6104017018': 'Йосипівка',
  'UA6104017016': 'Заруддя',
  'UA6104017001': 'Зборів',
  'UA6104017014': 'Жабиня',
  'UA6104017015': 'Жуківці',
  'UA6104019002': 'Багатківці',
  'UA6104019003': 'Бенева',
  'UA6104019004': 'Бурканів',
  'UA6104019007': 'Гайворонка',
  'UA6104019009': 'Гончарки',
  'UA6104019008': 'Гвардійське',
  'UA6104019011': 'Котузів',
  'UA6104019012': 'Маловоди',
  'UA6104019013': 'Надрічне',
  'UA6104019014': 'Панталиха',
  'UA6104019015': 'Підруда',
  'UA6104019016': 'Раковець',
  'UA6104019017': 'Сапова',
  'UA6104019018': 'Семиківці',
  'UA6104019019': 'Сокільники',
  'UA6104019020': 'Соколів',
  'UA6104019021': 'Соснів',
  'UA6104019005': 'Вишеньки',
  'UA6104019006': 'Вишнівчик',
  'UA6104019010': 'Зарваниця',
  'UA6104019001': 'Золотники',
  'UA0504001002': 'Баланівка',
  'UA0504001001': 'Бершадь',
  'UA0504001003': 'Бирлівка',
  'UA0504001024': 'Флорине',
  'UA0504001007': 'Глинське',
  'UA0504001008': 'Голдашівка',
  'UA0504001010': 'Кошаринці',
  'UA0504001011': 'Красносілка',
  'UA0504001012': 'Крушинівка',
  'UA0504001009': 'Кидрасівка',
  'UA0504001013': 'Лісниче',
  'UA0504001014': 'Лугова',
  'UA0504001015': 'Мала Киріївка',
  'UA0504001016': 'Маньківка',
  'UA0504001017': 'Михайлівка',
  'UA0504001018': 'Осіївка',
  'UA0504001027': 'Партизанське',
  'UA0504001020': 'П\'ятківка',
  'UA0504001019': 'Поташня',
  'UA0504001021': 'Романівка',
  'UA0504001025': 'Шумилів',
  'UA0504001022': 'Сумівка',
  'UA0504001023': 'Устя',
  'UA0504001028': 'Устя',
  'UA0504001004': 'Велика Киріївка',
  'UA0504001005': 'Війтівка',
  'UA0504001006': 'Вовчок',
  'UA0504001026': 'Яланець',
  'UA0504001029': 'Яланець',
  'UA0504027002': 'Анютине',
  'UA0504027003': 'Білий Камінь',
  'UA0504027004': 'Бондурівка',
  'UA0504027005': 'Бритавка',
  'UA0504027001': 'Чечельник',
  'UA0504027008': 'Дохно',
  'UA0504027010': 'Каташин',
  'UA0504027011': 'Куренівка',
  'UA0504027012': 'Луги',
  'UA0504027013': 'Новоукраїнка',
  'UA0504027014': 'Попова Гребля',
  'UA0504027015': 'Рогізка',
  'UA0504027016': 'Тарасівка',
  'UA0504027017': 'Тартак',
  'UA0504027006': 'Василівка',
  'UA0504027007': 'Вербка',
  'UA0504027009': 'Жабокричка',
  'UA0504005002': 'Білки',
  'UA0504005019': 'Чортория',
  'UA0504005001': 'Дашів',
  'UA0504005004': 'Городок',
  'UA0504005005': 'Іванівка',
  'UA0504005006': 'Кальник',
  'UA0504005007': 'Кам\'яногірка',
  'UA0504005008': 'Кантелина',
  'UA0504005010': 'Копіївка',
  'UA0504005011': 'Криштопівка',
  'UA0504005023': 'Криштопівське',
  'UA0504005012': 'Купчинці',
  'UA0504005009': 'Китайгород',
  'UA0504005013': 'Леухи',
  'UA0504005014': 'Олексіївка',
  'UA0504005024': 'Первомайське',
  'UA0504005015': 'Петро-Марківка',
  'UA0504005016': 'Привільне',
  'UA0504005017': 'Росоховата',
  'UA0504005020': 'Шабельня',
  'UA0504005018': 'Слободище',
  'UA0504005022': 'Вербівка',
  'UA0504005003': 'Волошкове',
  'UA0504005021': 'Яструбинці',
  'UA0504007002': 'Березівка',
  'UA0504007003': 'Берізки-Бершадські',
  'UA0504007014': 'Чернятка',
  'UA0504007004': 'Дяківка',
  'UA0504007001': 'Джулинка',
  'UA0504007016': 'Кавкули',
  'UA0504007013': 'Хмарівка',
  'UA0504007006': 'М\'якохід',
  'UA0504007007': 'Серебрія',
  'UA0504007008': 'Серединка',
  'UA0504007015': 'Шляхова',
  'UA0504007009': 'Ставки',
  'UA0504007010': 'Теофілівка',
  'UA0504007011': 'Тернівка',
  'UA0504007012': 'Тирлівка',
  'UA0504007005': 'Завітне',
  'UA0504003002': 'Адамівка',
  'UA0504003003': 'Басаличівка',
  'UA0504003004': 'Бережне',
  'UA0504003005': 'Бондурі',
  'UA0504003006': 'Борсуки',
  'UA0504003007': 'Бубнівка',
  'UA0504003008': 'Бур\'яни',
  'UA0504003036': 'Чечелівка',
  'UA0504003012': 'Дмитренки',
  'UA0504003001': 'Гайсин',
  'UA0504003009': 'Гнатівка',
  'UA0504003010': 'Губник',
  'UA0504003039': 'Губник',
  'UA0504003011': 'Гунча',
  'UA0504003016': 'Карбівка',
  'UA0504003035': 'Харпачка',
  'UA0504003043': 'Хороша',
  'UA0504003018': 'Кіблич',
  'UA0504003019': 'Кочурів',
  'UA0504003020': 'Крутогорб',
  'UA0504003021': 'Куна',
  'UA0504003022': 'Кущинці',
  'UA0504003017': 'Кисляк',
  'UA0504003023': 'Ладижинські Хутори',
  'UA0504003041': 'Лісна Поляна',
  'UA0504003024': 'Мар\'янівка',
  'UA0504003025': 'Мелешків',
  'UA0504003042': 'Млинки',
  'UA0504003026': 'Новоселівка',
  'UA0504003027': 'Огіївка',
  'UA0504003029': 'Рахнівка',
  'UA0504003028': 'Рахни',
  'UA0504003030': 'Розівка',
  'UA0504003031': 'Семирічка',
  'UA0504003037': 'Шура-Бондурівська',
  'UA0504003032': 'Степашки',
  'UA0504003033': 'Тарасівка',
  'UA0504003034': 'Тимар',
  'UA0504003038': 'Ярмолинці',
  'UA0504003014': 'Заріччя',
  'UA0504003013': 'Жерденівка',
  'UA0504003015': 'Зятківці',
  'UA0504003040': 'Зятківці',
  'UA0504009013': 'Дубина',
  'UA0504009002': 'Гранів',
  'UA0504009003': 'Грузьке',
  'UA0504009001': 'Краснопілка',
  'UA0504009004': 'Кивачівка',
  'UA0504009006': 'Михайлівка',
  'UA0504009005': 'Митків',
  'UA0504009007': 'Нараївка',
  'UA0504009008': 'Степове',
  'UA0504009011': 'Тополівка',
  'UA0504009012': 'Цвіліхівка',
  'UA0504009009': 'Тишківка',
  'UA0504009010': 'Тишківська Слобода',
  'UA0504011010': 'Карбівське',
  'UA0504011002': 'Косанове',
  'UA0504011001': 'Кунка',
  'UA0504011003': 'Кузьминці',
  'UA0504011004': 'Мітлинці',
  'UA0504011005': 'Носівці',
  'UA0504011006': 'Павлівка',
  'UA0504011009': 'Щурівці',
  'UA0504011008': 'Шура-Мітлинецька',
  'UA0504011007': 'Сокільці',
  'UA0504011011': 'Трубочка',
  'UA0504013005': 'Губник',
  'UA0504013001': 'Ладижин',
  'UA0504013004': 'Лукашівка',
  'UA0504013006': 'Ружицьке',
  'UA0504013002': 'Василівка',
  'UA0504013003': 'Заозерне',
  'UA0504015002': 'Бережанка',
  'UA0504015004': 'Дубівка',
  'UA0504015005': 'Козинці',
  'UA0504015006': 'Мала Стратіївка',
  'UA0504015007': 'Нова Ободівка',
  'UA0504015001': 'Ободівка',
  'UA0504015008': 'Торканівка',
  'UA0504015009': 'Цибулівка',
  'UA0504015003': 'Верхівка',
  'UA0504015010': 'Верхівське',
  'UA0504017002': 'Берізки-Чечельницькі',
  'UA0504017003': 'Демівка',
  'UA0504017004': 'Любомирка',
  'UA0504017001': 'Ольгопіль',
  'UA0504017005': 'Стратіївка',
  'UA0504019018': 'Червоне',
  'UA0504019006': 'Джуринці',
  'UA0504019004': 'Городниця',
  'UA0504019005': 'Гута',
  'UA0504019020': 'Коржів',
  'UA0504019007': 'Коржівка',
  'UA0504019008': 'Мар\'янівка',
  'UA0504019009': 'Мельниківці',
  'UA0504019011': 'Нові Обиходи',
  'UA0504019010': 'Нижча Кропивна',
  'UA0504019012': 'Ометинці',
  'UA0504019001': 'Райгород',
  'UA0504019013': 'Рубіжне',
  'UA0504019014': 'Салинці',
  'UA0504019015': 'Самчинці',
  'UA0504019016': 'Семенки',
  'UA0504019017': 'Слобідка',
  'UA0504019002': 'Ситківці',
  'UA0504019003': 'Вища Кропивна',
  'UA0504019019': 'Юрківці',
  'UA0504021002': 'Антонівка',
  'UA0504021003': 'Брідок',
  'UA0504021006': 'Метанівка',
  'UA0504021007': 'Орлівка',
  'UA0504021008': 'Панчишине',
  'UA0504021009': 'Петрашівка',
  'UA0504021010': 'Побірка',
  'UA0504021011': 'Шевченка',
  'UA0504021012': 'Шиманівка',
  'UA0504021001': 'Соболівка',
  'UA0504021004': 'Велика Мочулка',
  'UA0504021005': 'Завадівка',
  'UA0504023002': 'Бджільна',
  'UA0504023026': 'Червона Долина',
  'UA0504023027': 'Червоний Кут',
  'UA0504023006': 'Іванів',
  'UA0504023007': 'Кам\'янки',
  'UA0504023008': 'Карабелівка',
  'UA0504023011': 'Комарівка',
  'UA0504023012': 'Костюківка',
  'UA0504023010': 'Кожухівка',
  'UA0504023029': 'Кублич',
  'UA0504023009': 'Кизими',
  'UA0504023030': 'Ленінка',
  'UA0504023013': 'Лозовата',
  'UA0504023014': 'Мала Мочулка',
  'UA0504023015': 'Марківка',
  'UA0504023016': 'Мишарівка',
  'UA0504023017': 'Погоріла',
  'UA0504023018': 'Пологи',
  'UA0504023020': 'Росоша',
  'UA0504023019': 'Розкошівка',
  'UA0504023031': 'Розкошівка',
  'UA0504023021': 'Саша',
  'UA0504023028': 'Шевченківка',
  'UA0504023022': 'Сокиряни',
  'UA0504023023': 'Степанівка',
  'UA0504023024': 'Стражгород',
  'UA0504023001': 'Теплик',
  'UA0504023025': 'Удич',
  'UA0504023003': 'Важне',
  'UA0504023004': 'Веселівка',
  'UA0504023005': 'Залужжя',
  'UA0504025002': 'Буди',
  'UA0504025020': 'Четвертинівка',
  'UA0504025007': 'Демківка',
  'UA0504025022': 'Демківське',
  'UA0504025006': 'Демидівка',
  'UA0504025023': 'Довжок',
  'UA0504025024': 'Дубина',
  'UA0504025021': 'Глибочанське',
  'UA0504025004': 'Глибочок',
  'UA0504025005': 'Гордіївка',
  'UA0504025008': 'Ілляшівка',
  'UA0504025009': 'Капустяни',
  'UA0504025011': 'Красногірка',
  'UA0504025010': 'Китайгород',
  'UA0504025026': 'Ладижинське',
  'UA0504025012': 'Летківка',
  'UA0504025013': 'Митківка',
  'UA0504025014': 'Олександрівка',
  'UA0504025015': 'Оляниця',
  'UA0504025016': 'Підлісне',
  'UA0504025017': 'Савинці',
  'UA0504025027': 'Северинівка',
  'UA0504025018': 'Скибинці',
  'UA0504025019': 'Тростянчик',
  'UA0504025001': 'Тростянець',
  'UA0504025003': 'Велика Стратіївка',
  'UA0504025025': 'Зелений Довжок',
  'UA0512001003': 'Білопілля',
  'UA0512001002': 'Бродецьке',
  'UA0512001008': 'Держанівка',
  'UA0512001017': 'Дубина',
  'UA0512001001': 'Глухівці',
  'UA0512001016': 'Глухівці',
  'UA0512001007': 'Гурівці',
  'UA0512001010': 'Кашперівка',
  'UA0512001011': 'Непедівка',
  'UA0512001012': 'Панасівка',
  'UA0512001013': 'Пляхова',
  'UA0512001014': 'Пузирки',
  'UA0512001015': 'Селище',
  'UA0512001004': 'Велике',
  'UA0512001005': 'Верболози',
  'UA0512001006': 'Вернигородок',
  'UA0512001009': 'Жежелів',
  'UA0512005002': 'Байківка',
  'UA0512005003': 'Грушківці',
  'UA0512005004': 'Гущинці',
  'UA0512005001': 'Іванів',
  'UA0512005006': 'Іванопіль',
  'UA0512005007': 'Кам\'яногірка',
  'UA0512005008': 'Майдан-Бобрик',
  'UA0512005014': 'Матяшівка',
  'UA0512005009': 'Пиків',
  'UA0512005010': 'Пиківська Слобідка',
  'UA0512005013': 'Шепіївка',
  'UA0512005011': 'Слобідка',
  'UA0512005012': 'Уладівка',
  'UA0512005005': 'Жигалівка',
  'UA0512007002': 'Адамівка',
  'UA0512007003': 'Антонопіль',
  'UA0512007004': 'Бережани',
  'UA0512007043': 'Черепашинці',
  'UA0512007044': 'Чернятин',
  'UA0512007042': 'Червоний Степ',
  'UA0512007012': 'Дружелюбівка',
  'UA0512007013': 'Дружне',
  'UA0512007008': 'Гарасимівка',
  'UA0512007009': 'Глинськ',
  'UA0512007010': 'Голендри',
  'UA0512007011': 'Голубівка',
  'UA0512007045': 'Гулівці',
  'UA0512007046': 'Калинівка Друга',
  'UA0512007001': 'Калинівка',
  'UA0512007041': 'Хомутинці',
  'UA0512007017': 'Корделівка',
  'UA0512007018': 'Котюжинці',
  'UA0512007019': 'Лемешівка',
  'UA0512007020': 'Лісова Лисіївка',
  'UA0512007021': 'Люлинці',
  'UA0512007022': 'Малі Кутища',
  'UA0512007024': 'Мізяків',
  'UA0512007025': 'Мізяківська Слобідка',
  'UA0512007026': 'Мончинці',
  'UA0512007023': 'Мирне',
  'UA0512007027': 'Нападівка',
  'UA0512007028': 'Нова Гребля',
  'UA0512007031': 'Панасівка',
  'UA0512007029': 'Павленки',
  'UA0512007030': 'Павлівка',
  'UA0512007047': 'Первомайське',
  'UA0512007033': 'Польова Лисіївка',
  'UA0512007034': 'Прилуцьке',
  'UA0512007032': 'Писарівка',
  'UA0512007035': 'Радівка',
  'UA0512007036': 'Райки',
  'UA0512007048': 'Рівнинне',
  'UA0512007037': 'Сальник',
  'UA0512007038': 'Софіївка',
  'UA0512007039': 'Тарасівка',
  'UA0512007040': 'Уладівське',
  'UA0512007005': 'Великі Кутища',
  'UA0512007006': 'Весела',
  'UA0512007007': 'Вишневе',
  'UA0512007049': 'Яблуневе',
  'UA0512007014': 'Забара',
  'UA0512007015': 'Загребельня',
  'UA0512007016': 'Заливанщина',
  'UA0512017002': 'Березна',
  'UA0512017003': 'Білий Рукав',
  'UA0512017004': 'Будків',
  'UA0512017039': 'Чудинівці',
  'UA0512017010': 'Думенки',
  'UA0512017038': 'Філіопіль',
  'UA0512017008': 'Голодьки',
  'UA0512017009': 'Гулі',
  'UA0512017012': 'Іванівці',
  'UA0512017001': 'Хмільник',
  'UA0512017014': 'Колибабинці',
  'UA0512017013': 'Кожухів',
  'UA0512017042': 'Красносілка',
  'UA0512017016': 'Крупин',
  'UA0512017017': 'Крутнів',
  'UA0512017015': 'Кривошиї',
  'UA0512017018': 'Куманівці',
  'UA0512017019': 'Курилівка',
  'UA0512017020': 'Кушелівка',
  'UA0512017021': 'Лелітка',
  'UA0512017023': 'Лісне',
  'UA0512017024': 'Лозова',
  'UA0512017025': 'Лука',
  'UA0512017022': 'Лисогірка',
  'UA0512017026': 'Малий Митник',
  'UA0512017027': 'Медведівка',
  'UA0512017028': 'Олександрівка',
  'UA0512017029': 'Осічок',
  'UA0512017030': 'Педоси',
  'UA0512017031': 'Порик',
  'UA0512017032': 'Сербанівка',
  'UA0512017040': 'Шевченка',
  'UA0512017041': 'Широка Гребля',
  'UA0512017033': 'Соколова',
  'UA0512017035': 'Сьомаки',
  'UA0512017034': 'Стара Гута',
  'UA0512017036': 'Теси',
  'UA0512017037': 'Томашпіль',
  'UA0512017005': 'Великий Митник',
  'UA0512017006': 'Вербівка',
  'UA0512017007': 'Вугли',
  'UA0512017011': 'Журавне',
  'UA0512009016': 'Флоріанівка',
  'UA0512009003': 'Іванківці',
  'UA0512009005': 'Кордишівка',
  'UA0512009006': 'Королівка',
  'UA0512009001': 'Козятин',
  'UA0512009004': 'Козятин',
  'UA0512009007': 'Махаринці',
  'UA0512009009': 'Прушинка',
  'UA0512009010': 'Пустоха',
  'UA0512009008': 'Пиковець',
  'UA0512009011': 'Рубанка',
  'UA0512009012': 'Сестринівка',
  'UA0512009014': 'Сокілець',
  'UA0512009013': 'Сигнал',
  'UA0512009015': 'Титусівка',
  'UA0512009002': 'Залізничне',
  'UA0512011002': 'Безіменне',
  'UA0512011018': 'Чернички',
  'UA0512011017': 'Хліборобне',
  'UA0512011004': 'Куманівка',
  'UA0512011005': 'Листопадівка',
  'UA0512011001': 'Махнівка',
  'UA0512011006': 'Мала Клітинка',
  'UA0512011007': 'Марківці',
  'UA0512011008': 'Медведівка',
  'UA0512011009': 'Молодіжне',
  'UA0512011010': 'Молотківці',
  'UA0512011011': 'Мшанець',
  'UA0512011012': 'Немиринці',
  'UA0512011013': 'Перемога',
  'UA0512011014': 'Поличинці',
  'UA0512011020': 'Садове',
  'UA0512011015': 'Тернівка',
  'UA0512011016': 'Туча',
  'UA0512011003': 'Вовчинець',
  'UA0512011019': 'Юрівка',
  'UA0512013002': 'Блажіївка',
  'UA0512013006': 'Дубові Махаринці',
  'UA0512013011': 'Коритувата',
  'UA0512013012': 'Красне',
  'UA0512013014': 'Лопатин',
  'UA0512013013': 'Лозівка',
  'UA0512013015': 'Малишівка',
  'UA0512013018': 'Мухувата',
  'UA0512013017': 'Михайлин',
  'UA0512013016': 'Миколаївка',
  'UA0512013001': 'Самгородок',
  'UA0512013020': 'Широка Гребля',
  'UA0512013019': 'Сошанське',
  'UA0512013003': 'Великий Степ',
  'UA0512013004': 'Вівсяники',
  'UA0512013005': 'Воскодавинці',
  'UA0512013010': 'Йосипівка',
  'UA0512013008': 'Збараж',
  'UA0512013007': 'Журбинці',
  'UA0512013009': 'Зозулинці',
  'UA0512015032': 'Чепелі',
  'UA0512015034': 'Чернятинці',
  'UA0512015033': 'Червоний Степ',
  'UA0512015035': 'Чеснівка',
  'UA0512015006': 'Дубина',
  'UA0512015005': 'Гнатівка',
  'UA0512015031': 'Хутори-Кривошиїнецькі',
  'UA0512015008': 'Клітенка',
  'UA0512015010': 'Кропивна',
  'UA0512015009': 'Крижанівка',
  'UA0512015011': 'Кустівці',
  'UA0512015036': 'Кустовецьке',
  'UA0512015013': 'Лозна',
  'UA0512015012': 'Лип\'ятин',
  'UA0512015014': 'Малий Острожок',
  'UA0512015015': 'Маркуші',
  'UA0512015017': 'Морозівка',
  'UA0512015016': 'Митинці',
  'UA0512015018': 'Осична',
  'UA0512015019': 'Пагурці',
  'UA0512015020': 'Петриківці',
  'UA0512015021': 'Подорожня',
  'UA0512015022': 'Пустовійти',
  'UA0512015024': 'Рогинці',
  'UA0512015023': 'Рибчинці',
  'UA0512015025': 'Сальниця',
  'UA0512015026': 'Скаржинці',
  'UA0512015027': 'Слобода-Кустовецька',
  'UA0512015028': 'Сміла',
  'UA0512015029': 'Ступник',
  'UA0512015030': 'Тараски',
  'UA0512015001': 'Уланів',
  'UA0512015002': 'Великий Острожок',
  'UA0512015004': 'Воронівці',
  'UA0512015003': 'Вишенька',
  'UA0512015007': 'Зозулинці',
  'UA0512003003': 'Держанівка',
  'UA0512003004': 'Дібрівка',
  'UA0512003005': 'Качанівка',
  'UA0512003006': 'Лісогірка',
  'UA0512003007': 'Мар\'янівка',
  'UA0512003008': 'Нова Сулківка',
  'UA0512003009': 'Ольгине',
  'UA0512003010': 'Семки',
  'UA0512003011': 'Софіївка',
  'UA0512003012': 'Сулківка',
  'UA0512003013': 'Терешпіль',
  'UA0512003014': 'Торчин',
  'UA0512003015': 'Українське',
  'UA0512003001': 'Війтівці',
  'UA0512003002': 'Володимирівка',
  'UA0508001001': 'Бабчинці',
  'UA0508001002': 'Бандишівка',
  'UA0508001003': 'Букатинка',
  'UA0508001007': 'Гамулівка',
  'UA0508001008': 'Майорщина',
  'UA0508001009': 'Мервинці',
  'UA0508001010': 'Моївка',
  'UA0508001011': 'Нове Життя',
  'UA0508001012': 'Новомикільськ',
  'UA0508001013': 'Степне',
  'UA0508001004': 'Вазлуївка',
  'UA0508001006': 'Вітрівка',
  'UA0508001005': 'Вила-Ярузькі',
  'UA0508009002': 'Абрамівська Долина',
  'UA0508009003': 'Березівка',
  'UA0508009004': 'Біляни',
  'UA0508009005': 'Борівка',
  'UA0508009001': 'Чернівці',
  'UA0508009012': 'Дубина',
  'UA0508009010': 'Гонтівка',
  'UA0508009011': 'Грабовець',
  'UA0508009014': 'Камінське',
  'UA0508009015': 'Коси',
  'UA0508009016': 'Котлубаївка',
  'UA0508009017': 'Лозове',
  'UA0508009018': 'Лужок',
  'UA0508009019': 'Мазурівка',
  'UA0508009020': 'Пелинівка',
  'UA0508009021': 'Ромашка',
  'UA0508009022': 'Саїнка',
  'UA0508009025': 'Шендерівка',
  'UA0508009023': 'Скалопіль',
  'UA0508009026': 'Скорячий Яр',
  'UA0508009024': 'Сокіл',
  'UA0508009027': 'Степок',
  'UA0508009028': 'Трактове',
  'UA0508009029': 'Туліїв',
  'UA0508009006': 'Весняне',
  'UA0508009008': 'Вівчарня',
  'UA0508009009': 'Володіївці',
  'UA0508009007': 'Вишневе',
  'UA0508009030': 'Ясне',
  'UA0508009013': 'Йосипівка',
  'UA0508005002': 'Бронниця',
  'UA0508005006': 'Грушка',
  'UA0508005005': 'Григорівка',
  'UA0508005007': 'Івонівка',
  'UA0508005008': 'Карпівка',
  'UA0508005022': 'Коштуля',
  'UA0508005023': 'Криштофівка',
  'UA0508005001': 'Могилів-Подільський',
  'UA0508005009': 'Немія',
  'UA0508005024': 'Нова Григорівка',
  'UA0508005025': 'Одая',
  'UA0508005011': 'Оленівка',
  'UA0508005010': 'Озаринці',
  'UA0508005012': 'Петрівка',
  'UA0508005013': 'Пилипи',
  'UA0508005015': 'Садківці',
  'UA0508005014': 'Садки',
  'UA0508005016': 'Серебрія',
  'UA0508005020': 'Шлишківці',
  'UA0508005017': 'Сказинці',
  'UA0508005018': 'Слобода-Шлишковецька',
  'UA0508005026': 'Сонячне',
  'UA0508005019': 'Суботівка',
  'UA0508005003': 'Вільне',
  'UA0508005004': 'Воєводчинці',
  'UA0508005021': 'Яруга',
  'UA0508007003': 'Бахтин',
  'UA0508007004': 'Бахтинок',
  'UA0508007002': 'Балківка',
  'UA0508007005': 'Берлядка',
  'UA0508007006': 'Біляни',
  'UA0508007007': 'Блакитне',
  'UA0508007016': 'Дерешова',
  'UA0508007045': 'Дігтярка',
  'UA0508007017': 'Долиняни',
  'UA0508007018': 'Дружба',
  'UA0508007014': 'Галайківці',
  'UA0508007015': 'Горай',
  'UA0508007022': 'Конищів',
  'UA0508007023': 'Котюжани',
  'UA0508007047': 'Котюжани',
  'UA0508007024': 'Красне',
  'UA0508007025': 'Кривохижинці',
  'UA0508007026': 'Кукурівка',
  'UA0508007027': 'Курашівці',
  'UA0508007048': 'Малий Обухів',
  'UA0508007029': 'Морозівка',
  'UA0508007001': 'Муровані Курилівці',
  'UA0508007028': 'Михайлівці',
  'UA0508007030': 'Наддністрянське',
  'UA0508007032': 'Новосілка',
  'UA0508007031': 'Нишівці',
  'UA0508007033': 'Обухів',
  'UA0508007034': 'Перекоринці',
  'UA0508007035': 'Петримани',
  'UA0508007036': 'Попелюхи',
  'UA0508007037': 'Посухів',
  'UA0508007038': 'Привітне',
  'UA0508007041': 'Рясне',
  'UA0508007039': 'Рівне',
  'UA0508007040': 'Роздолівка',
  'UA0508007042': 'Снітків',
  'UA0508007043': 'Степанки',
  'UA0508007049': 'Свидова',
  'UA0508007008': 'Вербовець',
  'UA0508007011': 'Вільшанка',
  'UA0508007012': 'Володимирівка',
  'UA0508007013': 'Воронівці',
  'UA0508007009': 'Виноградне',
  'UA0508007010': 'Вищеольчедаїв',
  'UA0508007044': 'Ягідне',
  'UA0508007050': 'Яр',
  'UA0508007019': 'Жван',
  'UA0508007020': 'Житники',
  'UA0508007021': 'Знаменівка',
  'UA0508007046': 'Золотогірка',
  'UA0508003002': 'Борщівці',
  'UA0508003004': 'Глибока Долина',
  'UA0508003005': 'Грабарівка',
  'UA0508003006': 'Кам\'янецькі Хутори',
  'UA0508003007': 'Конева',
  'UA0508003008': 'Кричанівка',
  'UA0508003009': 'Кукавка',
  'UA0508003024': 'Кукавка',
  'UA0508003010': 'Ломазів',
  'UA0508003012': 'Лучинчик',
  'UA0508003011': 'Лучинець',
  'UA0508003013': 'Немерче',
  'UA0508003025': 'Немерче',
  'UA0508003014': 'Нижчий Ольчедаїв',
  'UA0508003015': 'Підлісне',
  'UA0508003016': 'Плоске',
  'UA0508003017': 'Садова',
  'UA0508003018': 'Серебринці',
  'UA0508003019': 'Сліди',
  'UA0508003020': 'Струсове',
  'UA0508003021': 'Сугаки',
  'UA0508003022': 'Тарасівка',
  'UA0508003023': 'Тропове',
  'UA0508003001': 'Вендичани',
  'UA0508003003': 'Вінож',
  'UA0508011002': 'Безводне',
  'UA0508011003': 'Біла',
  'UA0508011004': 'Буша',
  'UA0508011008': 'Держанка',
  'UA0508011011': 'Добрянка',
  'UA0508011013': 'Дорошівка',
  'UA0508011012': 'Довжок',
  'UA0508011010': 'Дзюброве',
  'UA0508011009': 'Дзигівка',
  'UA0508011036': 'Франківка',
  'UA0508011006': 'Гальжбіївка',
  'UA0508011007': 'Гонорівка',
  'UA0508011014': 'Іванків',
  'UA0508011015': 'Качківка',
  'UA0508011037': 'Хмелівщина',
  'UA0508011016': 'Клембівка',
  'UA0508011017': 'Лаврівка',
  'UA0508011019': 'Михайлівка',
  'UA0508011018': 'Миронівка',
  'UA0508011020': 'Нечуївка',
  'UA0508011021': 'Оксанівка',
  'UA0508011022': 'Петрашівка',
  'UA0508011024': 'Підлісівка',
  'UA0508011025': 'Пороги',
  'UA0508011026': 'Придністрянське',
  'UA0508011027': 'Прилужне',
  'UA0508011023': 'Писарівка',
  'UA0508011028': 'Ратуш',
  'UA0508011029': 'Регляшинці',
  'UA0508011030': 'Русава',
  'UA0508011031': 'Северинівка',
  'UA0508011032': 'Слобода-Бушанська',
  'UA0508011033': 'Слобода-Підлісівська',
  'UA0508011034': 'Тростянець',
  'UA0508011038': 'Цекинівка',
  'UA0508011035': 'Улянівка',
  'UA0508011005': 'Велика Кісниця',
  'UA0508011001': 'Ямпіль',
  'UA0508013002': 'Бернашівка',
  'UA0508013004': 'Іракліївка',
  'UA0508013011': 'Хоньківці',
  'UA0508013005': 'Козлів',
  'UA0508013006': 'Кремінне',
  'UA0508013008': 'Лядова',
  'UA0508013007': 'Липчани',
  'UA0508013009': 'Нагоряни',
  'UA0508013010': 'Слобода-Яришівська',
  'UA0508013001': 'Яришів',
  'UA0508013013': 'Яструбна',
  'UA0508013012': 'Юрківці',
  'UA0508013003': 'Жеребилівка',
  'UA0510001002': 'Анциполівка',
  'UA0510001001': 'Брацлав',
  'UA0510001003': 'Бугаків',
  'UA0510001011': 'Довжок',
  'UA0510001007': 'Глинське',
  'UA0510001008': 'Грабовець',
  'UA0510001009': 'Гранітне',
  'UA0510001010': 'Гриненки',
  'UA0510001014': 'Монастирське',
  'UA0510001015': 'Новоселівка',
  'UA0510001018': 'Шура',
  'UA0510001016': 'Скрицьке',
  'UA0510001017': 'Сорокодуби',
  'UA0510001006': 'Вовчок',
  'UA0510001004': 'Вигнанка',
  'UA0510001005': 'Вишківці',
  'UA0510001019': 'Яструбиха',
  'UA0510001012': 'Забужжя',
  'UA0510001013': 'Зяньківці',
  'UA0510005002': 'Андріяшівка',
  'UA0510005007': 'Дахталія',
  'UA0510005010': 'Доярня',
  'UA0510005009': 'Долинка',
  'UA0510005008': 'Джугастра',
  'UA0510005025': 'Файгород',
  'UA0510005006': 'Гарячківка',
  'UA0510005021': 'Горби',
  'UA0510005001': 'Городківка',
  'UA0510005011': 'Кісниця',
  'UA0510005012': 'Кринички',
  'UA0510005013': 'Леонівка',
  'UA0510005014': 'Мар\'янівка',
  'UA0510005022': 'Мар\'янівське',
  'UA0510005015': 'Одаї',
  'UA0510005016': 'Петрунівка',
  'UA0510005023': 'Рудник',
  'UA0510005017': 'Савчине',
  'UA0510005019': 'Шуми',
  'UA0510005024': 'Суха Долина',
  'UA0510005018': 'Теклівка',
  'UA0510005020': 'Вербецьке',
  'UA0510005003': 'Вербка',
  'UA0510005005': 'Вільшанка',
  'UA0510005004': 'Висока Гребля',
  'UA0510007002': 'Голубече',
  'UA0510007007': 'Іллівка',
  'UA0510007008': 'Княжа Криниця',
  'UA0510007009': 'Красне',
  'UA0510007010': 'Красносілка',
  'UA0510007011': 'Крикливець',
  'UA0510007001': 'Крижопіль',
  'UA0510007012': 'Куниче',
  'UA0510007013': 'Левків',
  'UA0510007014': 'Павлівка',
  'UA0510007018': 'Шарапанівка',
  'UA0510007015': 'Соколівка',
  'UA0510007016': 'Сонячне',
  'UA0510007017': 'Тернівка',
  'UA0510007019': 'Вигода',
  'UA0510007004': 'Заболотне',
  'UA0510007005': 'Залісся',
  'UA0510007006': 'Зеленянка',
  'UA0510007003': 'Жабокрич',
  'UA0510009010': 'Чорномин',
  'UA0510009004': 'Дмитрашківка',
  'UA0510009003': 'Городище',
  'UA0510009005': 'Козлівка',
  'UA0510009012': 'Миколаївка',
  'UA0510009006': 'Миролюбівка',
  'UA0510009001': 'Піщанка',
  'UA0510009013': 'Попелюхи',
  'UA0510009002': 'Рудниця',
  'UA0510009008': 'Рудницьке',
  'UA0510009007': 'Рибки',
  'UA0510009009': 'Ставки',
  'UA0510009014': 'Трудове',
  'UA0510009011': 'Яворівка',
  'UA0510017002': 'Бурдії',
  'UA0510017003': 'Бушинка',
  'UA0510017006': 'Даньківка',
  'UA0510017025': 'Гута-Бушинська',
  'UA0510017007': 'Кленове',
  'UA0510017008': 'Левківці',
  'UA0510017009': 'Лопатинці',
  'UA0510017010': 'Мала Вулига',
  'UA0510017012': 'Печера',
  'UA0510017011': 'Петрашівка',
  'UA0510017013': 'Підлісівка',
  'UA0510017014': 'Польова Слобідка',
  'UA0510017015': 'Рахни-Лісові',
  'UA0510017016': 'Рогізна',
  'UA0510017001': 'Шпиків',
  'UA0510017021': 'Шпиківка',
  'UA0510017017': 'Сліди',
  'UA0510017026': 'Соснівка',
  'UA0510017018': 'Станіславка',
  'UA0510017019': 'Стрільники',
  'UA0510017020': 'Торків',
  'UA0510017004': 'Велика Вулига',
  'UA0510017005': 'Винокурня',
  'UA0510017024': 'Ярове',
  'UA0510017022': 'Юліямпіль',
  'UA0510017027': 'Юліямпільське',
  'UA0510017023': 'Юрківка',
  'UA0510011002': 'Болган',
  'UA0510011003': 'Брохвичі',
  'UA0510011014': 'Чабанове',
  'UA0510011015': 'Червона Поляна',
  'UA0510011005': 'Гонорівка',
  'UA0510011006': 'Грабарівка',
  'UA0510011008': 'Калинівка',
  'UA0510011011': 'Козацьке',
  'UA0510011009': 'Кукули',
  'UA0510011012': 'Палійове',
  'UA0510011013': 'П\'ятихатка',
  'UA0510011001': 'Студена',
  'UA0510011010': 'Трибусівка',
  'UA0510011004': 'Верхня Слобідка',
  'UA0510011007': 'Затишне',
  'UA0510013002': 'Антонівка',
  'UA0510013003': 'Благодатне',
  'UA0510013007': 'Гнатків',
  'UA0510013008': 'Горишківка',
  'UA0510013027': 'Горишківське',
  'UA0510013011': 'Калинка',
  'UA0510013012': 'Касянівка',
  'UA0510013014': 'Комаргород',
  'UA0510013028': 'Комаргородське',
  'UA0510013013': 'Кислицьке',
  'UA0510013015': 'Липівка',
  'UA0510013016': 'Нетребівка',
  'UA0510013017': 'Олександрівка',
  'UA0510013018': 'Паланка',
  'UA0510013019': 'Пеньківка',
  'UA0510013020': 'Пилипи-Борівські',
  'UA0510013021': 'Ракова',
  'UA0510013022': 'Рожнятівка',
  'UA0510013023': 'Русава',
  'UA0510013024': 'Стіна',
  'UA0510013001': 'Томашпіль',
  'UA0510013004': 'Вапнярки',
  'UA0510013005': 'Велика Русава',
  'UA0510013006': 'Вила',
  'UA0510013025': 'Яланець',
  'UA0510013026': 'Яришівка',
  'UA0510013010': 'Забіляни',
  'UA0510013009': 'Жолоби',
  'UA0510015003': 'Білоусівка',
  'UA0510015004': 'Богданівка',
  'UA0510015005': 'Бортники',
  'UA0510015008': 'Дранка',
  'UA0510015034': 'Федьківка',
  'UA0510015006': 'Ганнопіль',
  'UA0510015029': 'Гуральня',
  'UA0510015007': 'Гути',
  'UA0510015011': 'Калинівка',
  'UA0510015030': 'Капки',
  'UA0510015035': 'Хмельницьке',
  'UA0510015027': 'Холодівка',
  'UA0510015013': 'Клебань',
  'UA0510015014': 'Копіївка',
  'UA0510015015': 'Крищинці',
  'UA0510015012': 'Кинашів',
  'UA0510015002': 'Кирнасівка',
  'UA0510015019': 'Маяки',
  'UA0510015017': 'Маньківка',
  'UA0510015031': 'Маркове',
  'UA0510015018': 'Марусине',
  'UA0510015016': 'Мазурівка',
  'UA0510015020': 'Михайлівка',
  'UA0510015021': 'Нестерварка',
  'UA0510015022': 'Одаї',
  'UA0510015032': 'Пестеля',
  'UA0510015036': 'Щурі',
  'UA0510015028': 'Шура-Копіївська',
  'UA0510015023': 'Сільниця',
  'UA0510015024': 'Суворовське',
  'UA0510015025': 'Тарасівка',
  'UA0510015001': 'Тульчин',
  'UA0510015026': 'Тиманівка',
  'UA0510015033': 'Улянівка',
  'UA0510015010': 'Зарічне',
  'UA0510015009': 'Журавлівка',
  'UA0510003002': 'Антопіль',
  'UA0510003005': 'Колоденка',
  'UA0510003006': 'Марківка',
  'UA0510003001': 'Вапнярка',
  'UA0510003003': 'Вербова',
  'UA0510003004': 'Високе',
  'UA0502001001': 'Агрономічне',
  'UA0502001002': 'Бохоники',
  'UA0502001003': 'Горбанівка',
  'UA0502001004': 'Ільківка',
  'UA0502001005': 'Медвеже Вушко',
  'UA0502001006': 'Рівець',
  'UA0502007002': 'Борсків',
  'UA0502007005': 'Демидівка',
  'UA0502007001': 'Гнівань',
  'UA0502007004': 'Грижинці',
  'UA0502007006': 'Маянів',
  'UA0502007007': 'Могилівка',
  'UA0502007008': 'Потоки',
  'UA0502007009': 'Рижавка',
  'UA0502007010': 'Селище',
  'UA0502007011': 'Урожайне',
  'UA0502007003': 'Ворошилівка',
  'UA0502009002': 'Бабин',
  'UA0502009003': 'Борисівка',
  'UA0502009030': 'Червоне',
  'UA0502009007': 'Даньківка',
  'UA0502009008': 'Дашів',
  'UA0502009011': 'Іллінецьке',
  'UA0502009001': 'Іллінці',
  'UA0502009012': 'Кабатня',
  'UA0502009029': 'Хмельове',
  'UA0502009026': 'Хрінівка',
  'UA0502009013': 'Красненьке',
  'UA0502009015': 'Лугова',
  'UA0502009014': 'Лиса Гора',
  'UA0502009016': 'Неменка',
  'UA0502009018': 'Паріївка',
  'UA0502009019': 'Пархомівка',
  'UA0502009017': 'Павлівка',
  'UA0502009020': 'Райки',
  'UA0502009021': 'Романово-Хутір',
  'UA0502009027': 'Шевченкове',
  'UA0502009022': 'Слобідка',
  'UA0502009023': 'Сорока',
  'UA0502009024': 'Тягун',
  'UA0502009025': 'Уланівка',
  'UA0502009004': 'Василівка',
  'UA0502009006': 'В\'язовиця',
  'UA0502009005': 'Володимирівка',
  'UA0502009028': 'Якубівка',
  'UA0502009009': 'Жадани',
  'UA0502009010': 'Жорнище',
  'UA0502013002': 'Антонівка',
  'UA0502013003': 'Багринівці',
  'UA0502013004': 'Балин',
  'UA0502013005': 'Білозірка',
  'UA0502013006': 'Бірків',
  'UA0502013007': 'Бруслинів',
  'UA0502013016': 'Дяківці',
  'UA0502013011': 'Гавришівка',
  'UA0502013012': 'Гончарівка',
  'UA0502013013': 'Горбівці',
  'UA0502013014': 'Городище',
  'UA0502013015': 'Громадське',
  'UA0502013018': 'Івча',
  'UA0502013019': 'Кам\'янка',
  'UA0502013020': 'Кільянівка',
  'UA0502013021': 'Кулига',
  'UA0502013022': 'Кусиківці',
  'UA0502013001': 'Літин',
  'UA0502013023': 'Літинка',
  'UA0502013024': 'Літинські Хутори',
  'UA0502013025': 'Малинівка',
  'UA0502013026': 'Миколаївка',
  'UA0502013027': 'Новоселиця',
  'UA0502013028': 'Осолинка',
  'UA0502013029': 'Петрик',
  'UA0502013030': 'Садове',
  'UA0502013031': 'Селище',
  'UA0502013032': 'Соколівка',
  'UA0502013033': 'Сосни',
  'UA0502013034': 'Трибухи',
  'UA0502013035': 'Українка',
  'UA0502013008': 'Вербівка',
  'UA0502013010': 'Вінниківці',
  'UA0502013009': 'Вишенька',
  'UA0502013036': 'Яблунівка',
  'UA0502013017': 'Залужне',
  'UA0502015002': 'Іванівка',
  'UA0502015011': 'Хижинці',
  'UA0502015003': 'Лани',
  'UA0502015001': 'Лука-Мелешківська',
  'UA0502015004': 'Майдан-Чапельський',
  'UA0502015005': 'Парпурівці',
  'UA0502015007': 'Прибузьке',
  'UA0502015006': 'Пилява',
  'UA0502015008': 'Сокиринці',
  'UA0502015009': 'Студениця',
  'UA0502015010': 'Тютьки',
  'UA0502015012': 'Цвіжин',
  'UA0502015013': 'Яришівка',
  'UA0502011002': 'Берестівка',
  'UA0502011003': 'Білозерівка',
  'UA0502011004': 'Богданівка',
  'UA0502011009': 'Ганнівка',
  'UA0502011010': 'Гордіївка',
  'UA0502011013': 'Іваньки',
  'UA0502011031': 'Хороша',
  'UA0502011014': 'Королівка',
  'UA0502011034': 'Ксаверівка',
  'UA0502011017': 'Люлинці',
  'UA0502011015': 'Лозувата',
  'UA0502011016': 'Лукашівка',
  'UA0502011001': 'Липовець',
  'UA0502011035': 'Липовець',
  'UA0502011018': 'Нападівка',
  'UA0502011019': 'Нарцизівка',
  'UA0502011021': 'Очитків',
  'UA0502011020': 'Олександрівка',
  'UA0502011037': 'Підлісне',
  'UA0502011022': 'Пісочин',
  'UA0502011023': 'Попівка',
  'UA0502011036': 'Пилипенкове',
  'UA0502011024': 'Росоша',
  'UA0502011032': 'Щаслива',
  'UA0502011025': 'Скитка',
  'UA0502011026': 'Славна',
  'UA0502011027': 'Струтинка',
  'UA0502011028': 'Теклинівка',
  'UA0502011029': 'Троща',
  'UA0502011030': 'Улянівка',
  'UA0502011005': 'Вербівка',
  'UA0502011006': 'Вернянка',
  'UA0502011007': 'Війтівці',
  'UA0502011008': 'Вікентіївка',
  'UA0502011038': 'Ясенецьке',
  'UA0502011033': 'Ясенки',
  'UA0502011011': 'Зозів',
  'UA0502011012': 'Зозівка',
  'UA0502017002': 'Байраківка',
  'UA0502017003': 'Березівка',
  'UA0502017004': 'Боблів',
  'UA0502017005': 'Бондурівка',
  'UA0502017006': 'Будки',
  'UA0502017049': 'Чаульське',
  'UA0502017050': 'Чуків',
  'UA0502017015': 'Данилки',
  'UA0502017016': 'Дубмаслівка',
  'UA0502017017': 'Дубовець',
  'UA0502017011': 'Глинянець',
  'UA0502017012': 'Головеньки',
  'UA0502017013': 'Гостинне',
  'UA0502017014': 'Гунька',
  'UA0502017010': 'Гвоздів',
  'UA0502017053': 'Кароліна',
  'UA0502017023': 'Коровайна',
  'UA0502017021': 'Ковалівка',
  'UA0502017022': 'Козаківка',
  'UA0502017024': 'Криківці',
  'UA0502017025': 'Кудлаї',
  'UA0502017026': 'Лука',
  'UA0502017027': 'Мала Бушинка',
  'UA0502017028': 'Мар\'янівка',
  'UA0502017029': 'Медвежа',
  'UA0502017030': 'Межигірка',
  'UA0502017031': 'Монастирок',
  'UA0502017032': 'Мухівці',
  'UA0502017001': 'Немирів',
  'UA0502017034': 'Нова Миколаївка',
  'UA0502017033': 'Никифорівці',
  'UA0502017036': 'Олексіївка',
  'UA0502017037': 'Остапківці',
  'UA0502017035': 'Озеро',
  'UA0502017038': 'Перепеличчя',
  'UA0502017039': 'Подільське',
  'UA0502017040': 'Потоки',
  'UA0502017041': 'Рубань',
  'UA0502017042': 'Сажки',
  'UA0502017043': 'Селевинці',
  'UA0502017051': 'Шолудьки',
  'UA0502017044': 'Сокілець',
  'UA0502017045': 'Сорокотяжинці',
  'UA0502017046': 'Сподахи',
  'UA0502017047': 'Стрільчинці',
  'UA0502017048': 'Супрунівка',
  'UA0502017007': 'Велика Бушинка',
  'UA0502017009': 'Воробіївка',
  'UA0502017008': 'Вовчок',
  'UA0502017052': 'Язвинки',
  'UA0502017020': 'Йосипенки',
  'UA0502017018': 'Зарудинці',
  'UA0502017019': 'Зеленянка',
  'UA0502019002': 'Балабанівка',
  'UA0502019003': 'Бартошівка',
  'UA0502019004': 'Березівка',
  'UA0502019005': 'Богданівка',
  'UA0502019006': 'Бугаївка',
  'UA0502019044': 'Чагів',
  'UA0502019052': 'Чагівське',
  'UA0502019045': 'Чернявка',
  'UA0502019046': 'Човновиця',
  'UA0502019011': 'Дібровинці',
  'UA0502019012': 'Дівочина',
  'UA0502019043': 'Фронтівка',
  'UA0502019009': 'Ганнівка',
  'UA0502019010': 'Гоноратка',
  'UA0502019016': 'Каленівка',
  'UA0502019017': 'Кам\'яногірка',
  'UA0502019019': 'Кошлани',
  'UA0502019018': 'Кожанка',
  'UA0502019020': 'Лінеччина',
  'UA0502019021': 'Лопатинка',
  'UA0502019022': 'Мала Ростівка',
  'UA0502019023': 'Медівка',
  'UA0502019024': 'Мервин',
  'UA0502019025': 'Новоживотів',
  'UA0502019001': 'Оратів',
  'UA0502019026': 'Оратів',
  'UA0502019027': 'Оратівка',
  'UA0502019028': 'Осична',
  'UA0502019050': 'Озерне',
  'UA0502019029': 'Підвисоке',
  'UA0502019030': 'Прибережне',
  'UA0502019031': 'Рожична',
  'UA0502019032': 'Сабарівка',
  'UA0502019034': 'Скала',
  'UA0502019036': 'Скоморошки',
  'UA0502019035': 'Скибин',
  'UA0502019037': 'Сологубівка',
  'UA0502019051': 'Степове',
  'UA0502019038': 'Стрижаків',
  'UA0502019039': 'Ступки',
  'UA0502019033': 'Синарна',
  'UA0502019040': 'Тарасівка',
  'UA0502019041': 'Тарасівка',
  'UA0502019042': 'Угарове',
  'UA0502019007': 'Велика Ростівка',
  'UA0502019008': 'Вербівка',
  'UA0502019048': 'Яблуновиця',
  'UA0502019049': 'Якимівка',
  'UA0502019047': 'Юшківці',
  'UA0502019014': 'Закриниччя',
  'UA0502019015': 'Заруддя',
  'UA0502019013': 'Животівка',
  'UA0502021002': 'Адамівка',
  'UA0502021003': 'Андрушівка',
  'UA0502021004': 'Бабинці',
  'UA0502021006': 'Білашки',
  'UA0502021007': 'Борщагівка',
  'UA0502021010': 'Бухни',
  'UA0502021008': 'Булаї',
  'UA0502021009': 'Бурківці',
  'UA0502021005': 'Бистрик',
  'UA0502021054': 'Черемошне',
  'UA0502021016': 'Довгалівка',
  'UA0502021017': 'Довжок',
  'UA0502021015': 'Дзюньків',
  'UA0502021060': 'Філютка',
  'UA0502021014': 'Гопчиця',
  'UA0502021057': 'Григорівка',
  'UA0502021020': 'Іваньки',
  'UA0502021021': 'Круподеринці',
  'UA0502021022': 'Кулешів',
  'UA0502021023': 'Кур\'янці',
  'UA0502021024': 'Левківка',
  'UA0502021025': 'Ліщинці',
  'UA0502021026': 'Малинки',
  'UA0502021027': 'Мончин',
  'UA0502021028': 'Морозівка',
  'UA0502021029': 'Надросся',
  'UA0502021030': 'Новофастів',
  'UA0502021031': 'Обозівка',
  'UA0502021034': 'Очеретня',
  'UA0502021033': 'Ординці',
  'UA0502021032': 'Озерна',
  'UA0502021036': 'Паріївка',
  'UA0502021035': 'Павлівка',
  'UA0502021037': 'Педоси',
  'UA0502021038': 'Плисків',
  'UA0502021058': 'Погребище Друге',
  'UA0502021059': 'Погребище Перше',
  'UA0502021001': 'Погребище',
  'UA0502021039': 'Попівці',
  'UA0502021040': 'Розкопане',
  'UA0502021041': 'Саражинці',
  'UA0502021055': 'Ширмівка',
  'UA0502021043': 'Скибинці',
  'UA0502021044': 'Смаржинці',
  'UA0502021045': 'Сніжна',
  'UA0502021046': 'Сопин',
  'UA0502021047': 'Соснівка',
  'UA0502021048': 'Спичинці',
  'UA0502021049': 'Станилівка',
  'UA0502021050': 'Старостинці',
  'UA0502021051': 'Степанки',
  'UA0502021042': 'Свитинці',
  'UA0502021052': 'Талалаї',
  'UA0502021053': 'Травневе',
  'UA0502021011': 'Васильківці',
  'UA0502021012': 'Веселівка',
  'UA0502021013': 'Вишнівка',
  'UA0502021056': 'Юнашки',
  'UA0502021018': 'Задорожнє',
  'UA0502021019': 'Збаржівка',
  'UA0502023002': 'Бруслинівка',
  'UA0502023003': 'Дорожне',
  'UA0502023004': 'Лаврівка',
  'UA0502023005': 'Медвідка',
  'UA0502023006': 'Мізяківські Хутори',
  'UA0502023007': 'Пеньківка',
  'UA0502023008': 'Переорки',
  'UA0502023009': 'Підлісне',
  'UA0502023013': 'Славне',
  'UA0502023010': 'Сосонка',
  'UA0502023001': 'Стрижавка',
  'UA0502023011': 'Супрунів',
  'UA0502023012': 'Тютюнники',
  'UA0502025002': 'Гута-Шершнівська',
  'UA0502025003': 'Шершні',
  'UA0502025001': 'Сутиски',
  'UA0502029002': 'Біла',
  'UA0502029003': 'Брицьке',
  'UA0502029020': 'Чупринівка',
  'UA0502029006': 'Кобильня',
  'UA0502029011': 'Коханівка',
  'UA0502029008': 'Конюшівка',
  'UA0502029009': 'Косаківка',
  'UA0502029010': 'Костянтинівка',
  'UA0502029007': 'Козинці',
  'UA0502029022': 'Мала Біла',
  'UA0502029012': 'Нова Прилука',
  'UA0502029013': 'Нове',
  'UA0502029014': 'Пеньківка',
  'UA0502029015': 'Петрівка',
  'UA0502029016': 'Приборівка',
  'UA0502029021': 'Шендерівка',
  'UA0502029018': 'Соболівка',
  'UA0502029019': 'Стара Прилука',
  'UA0502029017': 'Сиваківці',
  'UA0502029001': 'Турбів',
  'UA0502029004': 'Вахнівка',
  'UA0502029005': 'Журава',
  'UA0502027026': 'Черемошне',
  'UA0502027005': 'Дубина',
  'UA0502027004': 'Дзвониха',
  'UA0502027003': 'Гришівці',
  'UA0502027010': 'Іскрівка',
  'UA0502027008': 'Іванківці',
  'UA0502027009': 'Івонівці',
  'UA0502027011': 'Канава',
  'UA0502027012': 'Кобелецьке',
  'UA0502027013': 'Колюхів',
  'UA0502027014': 'Красне',
  'UA0502027015': 'Краснянка',
  'UA0502027016': 'Круги',
  'UA0502027017': 'Курники',
  'UA0502027018': 'Майдан',
  'UA0502027019': 'Нове Місто',
  'UA0502027020': 'Онитківці',
  'UA0502027021': 'Пирогів',
  'UA0502027022': 'Рахни-Польові',
  'UA0502027023': 'Соколинці',
  'UA0502027024': 'Строїнці',
  'UA0502027001': 'Тиврів',
  'UA0502027025': 'Уяринці',
  'UA0502027002': 'Василівка',
  'UA0502027007': 'Зарванка',
  'UA0502027006': 'Жахнівка',
  'UA0502003002': 'Десна',
  'UA0502003005': 'Гавришівка',
  'UA0502003006': 'Малі Крушлинці',
  'UA0502003007': 'Писарівка',
  'UA0502003009': 'Щітки',
  'UA0502003008': 'Стадниця',
  'UA0502003003': 'Великі Крушлинці',
  'UA0502003001': 'Вінниця',
  'UA0502003004': 'Вінницькі Хутори',
  'UA0502005004': 'Довгополівка',
  'UA0502005019': 'Федорівка',
  'UA0502005003': 'Гуменне',
  'UA0502005006': 'Кальнишівка',
  'UA0502005007': 'Кліщів',
  'UA0502005008': 'Комарів',
  'UA0502005009': 'Кордишівка',
  'UA0502005010': 'Марківка',
  'UA0502005011': 'Михайлівка',
  'UA0502005012': 'Обідне',
  'UA0502005013': 'Олександрівка',
  'UA0502005014': 'Оленівка',
  'UA0502005015': 'Побережне',
  'UA0502005016': 'Потуш',
  'UA0502005020': 'Шендерів',
  'UA0502005021': 'Шевченка',
  'UA0502005017': 'Степанівка',
  'UA0502005018': 'Тростянець',
  'UA0502005002': 'Воловодівка',
  'UA0502005001': 'Вороновиця',
  'UA0502005005': 'Жабелівка',
  'UA0502031017': 'Березина',
  'UA0502031002': 'Дашківці',
  'UA0502031004': 'Іскриня',
  'UA0502031005': 'Ксаверівка',
  'UA0502031008': 'Лукашівка',
  'UA0502031007': 'Лисянка',
  'UA0502031006': 'Лисогора',
  'UA0502031009': 'Майдан',
  'UA0502031010': 'Махнівка',
  'UA0502031011': 'Микулинці',
  'UA0502031012': 'Некрасове',
  'UA0502031013': 'Пултівці',
  'UA0502031014': 'Ріжок',
  'UA0502031016': 'Широка Гребля',
  'UA0502031015': 'Слобода-Дашковецька',
  'UA0502031001': 'Якушинці',
  'UA0502031003': 'Зарванці',
  'UA0506001002': 'Адамівка',
  'UA0506001003': 'Антонівка',
  'UA0506001004': 'Балки',
  'UA0506001001': 'Бар',
  'UA0506001065': 'Бар',
  'UA0506001005': 'Біличин',
  'UA0506001006': 'Борщі',
  'UA0506001007': 'Бригидівка',
  'UA0506001008': 'Буцні',
  'UA0506001058': 'Чемериське',
  'UA0506001057': 'Чемериси-Барські',
  'UA0506001059': 'Черешневе',
  'UA0506001066': 'Діброва',
  'UA0506001012': 'Гайове',
  'UA0506001013': 'Гармаки',
  'UA0506001011': 'Гавришівка',
  'UA0506001014': 'Глинянка',
  'UA0506001015': 'Голубівка',
  'UA0506001016': 'Горяни',
  'UA0506001017': 'Гулі',
  'UA0506001022': 'Іванівці',
  'UA0506001067': 'Івановецьке',
  'UA0506001024': 'Каноницьке',
  'UA0506001056': 'Ходаки',
  'UA0506001028': 'Колосівка',
  'UA0506001029': 'Комарівці',
  'UA0506001068': 'Котова',
  'UA0506001027': 'Козарівка',
  'UA0506001030': 'Кузьминці',
  'UA0506001025': 'Квітка',
  'UA0506001026': 'Киянівка',
  'UA0506001032': 'Лядова',
  'UA0506001069': 'Лугове',
  'UA0506001031': 'Лука-Барська',
  'UA0506001033': 'Мальчівці',
  'UA0506001034': 'Мартинівка',
  'UA0506001035': 'Матейків',
  'UA0506001039': 'Міжлісся',
  'UA0506001040': 'Мурашка',
  'UA0506001036': 'Мигалівці',
  'UA0506001037': 'Мирне',
  'UA0506001038': 'Митки',
  'UA0506001041': 'Окладне',
  'UA0506001042': 'Павлівка',
  'UA0506001044': 'Підлісний Ялтушків',
  'UA0506001045': 'Пляцина',
  'UA0506001043': 'Пилипи',
  'UA0506001046': 'Регентівка',
  'UA0506001048': 'Сеферівка',
  'UA0506001047': 'Семенки',
  'UA0506001061': 'Шершні',
  'UA0506001060': 'Шевченка',
  'UA0506001063': 'Шпирки',
  'UA0506001062': 'Широке',
  'UA0506001070': 'Слобода',
  'UA0506001049': 'Слобода-Гулівська',
  'UA0506001050': 'Слобода-Ходацька',
  'UA0506001051': 'Слобода-Ялтушківська',
  'UA0506001052': 'Стасюки',
  'UA0506001053': 'Степанки',
  'UA0506001054': 'Терешки',
  'UA0506001055': 'Трудолюбівка',
  'UA0506001009': 'Васютинці',
  'UA0506001010': 'Войнашівка',
  'UA0506001064': 'Ялтушків',
  'UA0506001023': 'Йосипівці',
  'UA0506001019': 'Заможне',
  'UA0506001020': 'Затоки',
  'UA0506001018': 'Журавлівка',
  'UA0506001021': 'Зоряне',
  'UA0506003002': 'Аристівка',
  'UA0506003006': 'Деребчин',
  'UA0506003001': 'Джурин',
  'UA0506003005': 'Голинчинці',
  'UA0506003008': 'Калитинка',
  'UA0506003015': 'Хоменки',
  'UA0506003009': 'Мала Деребчинка',
  'UA0506003010': 'Нові Хоменки',
  'UA0506003011': 'Покутине',
  'UA0506003012': 'Садківці',
  'UA0506003013': 'Сапіжанка',
  'UA0506003014': 'Семенівка',
  'UA0506003016': 'Синьожупанники',
  'UA0506003003': 'Вербівка',
  'UA0506003004': 'Володимирка',
  'UA0506003007': 'Зведенівка',
  'UA0506007002': 'Барок',
  'UA0506007006': 'Грабівці',
  'UA0506007007': 'Губачівка',
  'UA0506007009': 'Каришків',
  'UA0506007021': 'Копай',
  'UA0506007001': 'Копайгород',
  'UA0506007010': 'Кошаринці',
  'UA0506007011': 'Лісове',
  'UA0506007012': 'Мар\'янівка',
  'UA0506007013': 'Переліски',
  'UA0506007014': 'Польове',
  'UA0506007015': 'Попівці',
  'UA0506007016': 'Примощаниця',
  'UA0506007019': 'Шевченкове',
  'UA0506007020': 'Шипинки',
  'UA0506007017': 'Супівка',
  'UA0506007018': 'Українське',
  'UA0506007003': 'Верешки',
  'UA0506007004': 'Верхівка',
  'UA0506007005': 'Володіївці',
  'UA0506007008': 'Зелене',
  'UA0506009002': 'Бабина Долина',
  'UA0506009003': 'Довжок',
  'UA0506009007': 'Федорівка',
  'UA0506009004': 'Клекотина',
  'UA0506009001': 'Мурафа',
  'UA0506009005': 'Михайлівка',
  'UA0506009006': 'Пеньківка',
  'UA0506009008': 'Юхимівка',
  'UA0506011015': 'Чернятин',
  'UA0506011002': 'Голубівка',
  'UA0506011018': 'Хатки',
  'UA0506011003': 'Кудіївці',
  'UA0506011004': 'Маньківці',
  'UA0506011016': 'Матейкове',
  'UA0506011005': 'Мельники',
  'UA0506011017': 'Мельники',
  'UA0506011006': 'Олександрівка',
  'UA0506011007': 'Повал',
  'UA0506011008': 'Рожепи',
  'UA0506011009': 'Сербинівці',
  'UA0506011001': 'Северинівка',
  'UA0506011019': 'Шевченкове',
  'UA0506011011': 'Слобода-Чернятинська',
  'UA0506011010': 'Слобода-Межирівська',
  'UA0506011012': 'Стодульці',
  'UA0506011013': 'Токарівка',
  'UA0506011014': 'Улянівка',
  'UA0506015002': 'Андріївка',
  'UA0506015003': 'Борівське',
  'UA0506015004': 'Будне',
  'UA0506015007': 'Дерев\'янки',
  'UA0506015008': 'Дубинки',
  'UA0506015006': 'Грелівка',
  'UA0506015005': 'Гибалівка',
  'UA0506015009': 'Івашківці',
  'UA0506015010': 'Калинівка',
  'UA0506015012': 'Конатківці',
  'UA0506015013': 'Копистирин',
  'UA0506015011': 'Козлівка',
  'UA0506015014': 'Кропивня',
  'UA0506015029': 'Лісничівка',
  'UA0506015015': 'Лозова',
  'UA0506015030': 'Лукашівка',
  'UA0506015016': 'Мальовниче',
  'UA0506015031': 'Мишівське',
  'UA0506015017': 'Носиківка',
  'UA0506015032': 'Оліхи',
  'UA0506015018': 'Пасинки',
  'UA0506015019': 'Перепільчинці',
  'UA0506015021': 'Плебанівка',
  'UA0506015023': 'Поляна',
  'UA0506015022': 'Політанки',
  'UA0506015020': 'Писарівка',
  'UA0506015024': 'Роля',
  'UA0506015025': 'Руданське',
  'UA0506015001': 'Шаргород',
  'UA0506015026': 'Слобода-Шаргородська',
  'UA0506015027': 'Сурогатка',
  'UA0506015028': 'Теклівка',
  'UA0506013002': 'Будьки',
  'UA0506013006': 'Демків',
  'UA0506013005': 'Гута-Мовчанська',
  'UA0506013007': 'Кам\'яногірка',
  'UA0506013008': 'Кацмазів',
  'UA0506013009': 'Лука-Мовчанська',
  'UA0506013010': 'Мовчани',
  'UA0506013015': 'Настасіївка',
  'UA0506013011': 'Носківці',
  'UA0506013012': 'Олексіївка',
  'UA0506013001': 'Станіславчик',
  'UA0506013013': 'Тарасівка',
  'UA0506013014': 'Телелинці',
  'UA0506013016': 'Травневе',
  'UA0506013003': 'Варжинка',
  'UA0506013004': 'Вознівці',
  'UA0506005003': 'Біликівці',
  'UA0506005002': 'Браїлів',
  'UA0506005032': 'Браїлів',
  'UA0506005004': 'Дубова',
  'UA0506005007': 'Кармалюкове',
  'UA0506005008': 'Коростівці',
  'UA0506005009': 'Курилівці',
  'UA0506005010': 'Леляки',
  'UA0506005013': 'Людавка',
  'UA0506005012': 'Лопатинці',
  'UA0506005011': 'Лисогірка',
  'UA0506005014': 'Майдан-Головчинський',
  'UA0506005015': 'Мала Жмеринка',
  'UA0506005016': 'Малі Коростівці',
  'UA0506005017': 'Мартинівка',
  'UA0506005018': 'Межирів',
  'UA0506005019': 'Новоселиця',
  'UA0506005020': 'Петрані',
  'UA0506005021': 'Петрівка',
  'UA0506005023': 'Почапинці',
  'UA0506005022': 'Подільське',
  'UA0506005024': 'Рів',
  'UA0506005030': 'Щучинці',
  'UA0506005025': 'Сідава',
  'UA0506005026': 'Слобода',
  'UA0506005027': 'Слобода-Носковецька',
  'UA0506005028': 'Сьомаки',
  'UA0506005029': 'Тартак',
  'UA0506005033': 'Василівка',
  'UA0506005034': 'Володимирівка',
  'UA0506005031': 'Ярошенка',
  'UA0506005001': 'Жмеринка',
  'UA0506005005': 'Жуківці',
  'UA0506005006': 'Зоринці',
  'UA0704001002': 'Боровне',
  'UA0704001003': 'Брониця',
  'UA0704001004': 'Бузаки',
  'UA0704001053': 'Черче',
  'UA0704001018': 'Добре',
  'UA0704001019': 'Дубровиця',
  'UA0704001051': 'Фаринки',
  'UA0704001014': 'Городок',
  'UA0704001015': 'Грудки',
  'UA0704001016': 'Гута-Боровенська',
  'UA0704001017': 'Гута-Камінська',
  'UA0704001022': 'Іваномисль',
  'UA0704001023': 'Каливиця',
  'UA0704001001': 'Камінь-Каширський',
  'UA0704001052': 'Хотешів',
  'UA0704001024': 'Клітицьк',
  'UA0704001025': 'Котуш',
  'UA0704001026': 'Краснилівка',
  'UA0704001027': 'Кримне',
  'UA0704001028': 'Мала Глуша',
  'UA0704001030': 'Малі Голоби',
  'UA0704001029': 'Малий Обзир',
  'UA0704001031': 'Мельники-Мостище',
  'UA0704001032': 'Мостище',
  'UA0704001033': 'Надрічне',
  'UA0704001034': 'Невір',
  'UA0704001035': 'Олексіївка',
  'UA0704001036': 'Оленине',
  'UA0704001037': 'Ольшани',
  'UA0704001038': 'Осівці',
  'UA0704001039': 'Острівок',
  'UA0704001040': 'Підбороччя',
  'UA0704001041': 'Підріччя',
  'UA0704001042': 'Підцир\'я',
  'UA0704001043': 'Піщане',
  'UA0704001044': 'Пнівне',
  'UA0704001045': 'Погулянка',
  'UA0704001046': 'Полиці',
  'UA0704001047': 'Раків Ліс',
  'UA0704001054': 'Щитинь',
  'UA0704001048': 'Соснівка',
  'UA0704001049': 'Стобихва',
  'UA0704001050': 'Теклине',
  'UA0704001005': 'Велика Глуша',
  'UA0704001006': 'Великий Обзир',
  'UA0704001007': 'Верхи',
  'UA0704001011': 'Воєгоща',
  'UA0704001012': 'Волиця',
  'UA0704001013': 'Ворокомле',
  'UA0704001008': 'Видерта',
  'UA0704001009': 'Видричі',
  'UA0704001010': 'Винімок',
  'UA0704001055': 'Яловацьк',
  'UA0704001021': 'Залазько',
  'UA0704001020': 'Житнівка',
  'UA0704003003': 'Березна Воля',
  'UA0704003002': 'Березичі',
  'UA0704003005': 'Бірки',
  'UA0704003006': 'Бучин',
  'UA0704003004': 'Бихів',
  'UA0704003014': 'Деревок',
  'UA0704003015': 'Діброва',
  'UA0704003016': 'Дольськ',
  'UA0704003012': 'Гірки',
  'UA0704003013': 'Гречища',
  'UA0704003038': 'Хоцунь',
  'UA0704003039': 'Хутомир',
  'UA0704003021': 'Лахвичі',
  'UA0704003001': 'Любешів',
  'UA0704003023': 'Любешівська Воля',
  'UA0704003025': 'Люб\'язь',
  'UA0704003024': 'Люботин',
  'UA0704003022': 'Лобна',
  'UA0704003026': 'Міжгайці',
  'UA0704003027': 'Мукошин',
  'UA0704003028': 'Нові Березичі',
  'UA0704003029': 'Підкормілля',
  'UA0704003030': 'Пожог',
  'UA0704003031': 'Проходи',
  'UA0704003032': 'Рудка',
  'UA0704003034': 'Седлище',
  'UA0704003035': 'Селісок',
  'UA0704003041': 'Шлапань',
  'UA0704003036': 'Судче',
  'UA0704003033': 'Сваловичі',
  'UA0704003040': 'Цир',
  'UA0704003037': 'Угриничі',
  'UA0704003007': 'Великий Курінь',
  'UA0704003008': 'Ветли',
  'UA0704003011': 'В\'язівне',
  'UA0704003010': 'Віл',
  'UA0704003009': 'Витуле',
  'UA0704003017': 'Залаззя',
  'UA0704003018': 'Залізниця',
  'UA0704003019': 'Заріка',
  'UA0704003020': 'Зарудчі',
  'UA0704005002': 'Бережниця',
  'UA0704005003': 'Будки',
  'UA0704005031': 'Чарторийськ',
  'UA0704005035': 'Череваха',
  'UA0704005036': 'Черськ',
  'UA0704005010': 'Довжиця',
  'UA0704005007': 'Граддя',
  'UA0704005008': 'Градиськ',
  'UA0704005009': 'Гута-Лісівська',
  'UA0704005012': 'Кам\'януха',
  'UA0704005033': 'Хряськ',
  'UA0704005014': 'Колодії',
  'UA0704005015': 'Комарове',
  'UA0704005016': 'Костюхнівка',
  'UA0704005013': 'Козлиничі',
  'UA0704005017': 'Кукли',
  'UA0704005018': 'Лісове',
  'UA0704005019': 'Майдан',
  'UA0704005020': 'Мала Ведмежка',
  'UA0704005021': 'Мала Яблунька',
  'UA0704005001': 'Маневичі',
  'UA0704005022': 'Набруска',
  'UA0704005023': 'Нова Руда',
  'UA0704005024': 'Нові Підцаревичі',
  'UA0704005025': 'Новосілки',
  'UA0704005026': 'Оконськ',
  'UA0704005027': 'Підгаття',
  'UA0704005028': 'Рудка',
  'UA0704005029': 'Северинівка',
  'UA0704005030': 'Софіянівка',
  'UA0704005032': 'Троянівка',
  'UA0704005034': 'Цміни',
  'UA0704005004': 'Велика Ведмежка',
  'UA0704005005': 'Велика Яблунька',
  'UA0704005006': 'Вовчицьк',
  'UA0704005011': 'Загорівка',
  'UA0704007002': 'Галузія',
  'UA0704007003': 'Городок',
  'UA0704007005': 'Карасин',
  'UA0704007006': 'Лишнівка',
  'UA0704007007': 'Нові Червища',
  'UA0704007001': 'Прилісне',
  'UA0704007008': 'Рудка-Червинська',
  'UA0704007009': 'Серхів',
  'UA0704007010': 'Старі Червища',
  'UA0704007011': 'Тоболи',
  'UA0704007004': 'Замостя',
  'UA0704009006': 'Качин',
  'UA0704009004': 'Карасин',
  'UA0704009005': 'Карпилівка',
  'UA0704009007': 'Личини',
  'UA0704009008': 'Нуйно',
  'UA0704009009': 'Олександрія',
  'UA0704009010': 'Радошинка',
  'UA0704009001': 'Сошичне',
  'UA0704009011': 'Ставище',
  'UA0704009012': 'Стобихівка',
  'UA0704009002': 'Залісся',
  'UA0704009003': 'Запруддя',
  'UA0706011001': 'Дубечне',
  'UA0706011002': 'Глухи',
  'UA0706011004': 'Кримне',
  'UA0706011005': 'Любохини',
  'UA0706011006': 'Лютка',
  'UA0706011007': 'Мокре',
  'UA0706011008': 'Рокита',
  'UA0706011009': 'Текля',
  'UA0706011010': 'Яревище',
  'UA0706011003': 'Залюття',
  'UA0706013002': 'Бахів',
  'UA0706013003': 'Буцинь',
  'UA0706013001': 'Дубове',
  'UA0706013005': 'Городище',
  'UA0706013006': 'Гредьки',
  'UA0706013007': 'Красноволя',
  'UA0706013008': 'Мислина',
  'UA0706013009': 'Облапи',
  'UA0706013010': 'Секунь',
  'UA0706013004': 'Вербка',
  'UA0706007002': 'Байківці',
  'UA0706007004': 'Борщівка',
  'UA0706007005': 'Бруховичі',
  'UA0706007003': 'Битень',
  'UA0706007008': 'Дарівка',
  'UA0706007009': 'Діброва',
  'UA0706007001': 'Голоби',
  'UA0706007011': 'Калиновник',
  'UA0706007012': 'Майдан',
  'UA0706007013': 'Малий Порськ',
  'UA0706007014': 'Мар\'янівка',
  'UA0706007015': 'Новий Мосир',
  'UA0706007016': 'Нужель',
  'UA0706007017': 'Партизанське',
  'UA0706007018': 'Погіньки',
  'UA0706007019': 'Поповичі',
  'UA0706007020': 'Радошин',
  'UA0706007022': 'Солотвин',
  'UA0706007023': 'Старий Мосир',
  'UA0706007021': 'Свидники',
  'UA0706007006': 'Великий Порськ',
  'UA0706007007': 'Вівчицьк',
  'UA0706007010': 'Жмудче',
  'UA0706009013': 'Черемошна Воля',
  'UA0706009001': 'Головне',
  'UA0706009002': 'Гуменці',
  'UA0706009003': 'Гупали',
  'UA0706009007': 'Крушинець',
  'UA0706009008': 'Масловець',
  'UA0706009009': 'Мшанець',
  'UA0706009010': 'Нудиже',
  'UA0706009012': 'Скрипиця',
  'UA0706009011': 'Сильне',
  'UA0706009014': 'Ясне',
  'UA0706009004': 'Заболоття',
  'UA0706009005': 'Заозерне',
  'UA0706009006': 'Згорани',
  'UA0706021002': 'Білашів',
  'UA0706021003': 'Будище',
  'UA0706021019': 'Черемошне',
  'UA0706021009': 'Дроздні',
  'UA0706021007': 'Гончий Брід',
  'UA0706021008': 'Грушівка',
  'UA0706021010': 'Іванівка',
  'UA0706021001': 'Колодяжне',
  'UA0706021011': 'Кричевичі',
  'UA0706021013': 'Любитів',
  'UA0706021012': 'Ломачанка',
  'UA0706021014': 'Перковичі',
  'UA0706021015': 'Рокитниця',
  'UA0706021016': 'Скулин',
  'UA0706021017': 'Стеблі',
  'UA0706021018': 'Уховецьк',
  'UA0706021005': 'Воля-Любитівська',
  'UA0706021004': 'Волошки',
  'UA0706021006': 'Ворона',
  'UA0706019002': 'Білин',
  'UA0706019007': 'Доротище',
  'UA0706019005': 'Гішин',
  'UA0706019006': 'Городилець',
  'UA0706019010': 'Клевецьк',
  'UA0706019011': 'Колодниця',
  'UA0706019001': 'Ковель',
  'UA0706019012': 'Лапні',
  'UA0706019013': 'Любче',
  'UA0706019014': 'Ружин',
  'UA0706019015': 'Тойкут',
  'UA0706019003': 'Воля',
  'UA0706019004': 'Воля-Ковельська',
  'UA0706019008': 'Заріччя',
  'UA0706019009': 'Зелена',
  'UA0706025009': 'Черкаси',
  'UA0706025002': 'Довгоноси',
  'UA0706025003': 'Калинівка',
  'UA0706025004': 'Краснодуб\'я',
  'UA0706025005': 'Кругель',
  'UA0706025001': 'Люблинець',
  'UA0706025006': 'Мощена',
  'UA0706025007': 'Нові Кошари',
  'UA0706025008': 'Старі Кошари',
  'UA0706027002': 'Бірки',
  'UA0706027003': 'Боремщина',
  'UA0706027015': 'Чорноплеси',
  'UA0706027006': 'Городнє',
  'UA0706027009': 'Красноволя',
  'UA0706027010': 'Куснища',
  'UA0706027001': 'Любомль',
  'UA0706027011': 'Лисняки',
  'UA0706027012': 'Підгородне',
  'UA0706027013': 'Почапи',
  'UA0706027014': 'Скиби',
  'UA0706027005': 'Вілька-Підгородненська',
  'UA0706027004': 'Вигнанка',
  'UA0706027007': 'Запілля',
  'UA0706027008': 'Застав\'є',
  'UA0706023003': 'Годовичі',
  'UA0706023006': 'Кличковичі',
  'UA0706023007': 'Комарів',
  'UA0706023001': 'Луків',
  'UA0706023008': 'Миляновичі',
  'UA0706023009': 'Новосілки',
  'UA0706023010': 'Окунин',
  'UA0706023011': 'Перевісся',
  'UA0706023012': 'Сомин',
  'UA0706023013': 'Тупали',
  'UA0706023014': 'Туровичі',
  'UA0706023002': 'Відути',
  'UA0706023004': 'Залісці',
  'UA0706023005': 'Зілове',
  'UA0706029002': 'Грив\'ятки',
  'UA0706029003': 'Гулівка',
  'UA0706029005': 'Козлиничі',
  'UA0706029006': 'Луківка',
  'UA0706029007': 'Озерне',
  'UA0706029008': 'Пісочне',
  'UA0706029001': 'Поворськ',
  'UA0706029011': 'Шкурат',
  'UA0706029009': 'Світле',
  'UA0706029010': 'Ситовичі',
  'UA0706029004': 'Заячівка',
  'UA0706031002': 'Береза',
  'UA0706031003': 'Броди',
  'UA0706031009': 'Долина',
  'UA0706031010': 'Доманове',
  'UA0706031007': 'Гірники',
  'UA0706031008': 'Годинь',
  'UA0706031014': 'Комарове',
  'UA0706031015': 'Конище',
  'UA0706031016': 'Кортеліси',
  'UA0706031017': 'Косиці',
  'UA0706031018': 'Краска',
  'UA0706031019': 'Млинове',
  'UA0706031020': 'Прохід',
  'UA0706031001': 'Ратне',
  'UA0706031024': 'Шменьки',
  'UA0706031021': 'Сільце',
  'UA0706031022': 'Сільця-Млинівські',
  'UA0706031023': 'Старостине',
  'UA0706031005': 'Височне',
  'UA0706031006': 'Витень',
  'UA0706031004': 'Вижично',
  'UA0706031012': 'Запоківне',
  'UA0706031013': 'Здомишель',
  'UA0706031011': 'Жиричі',
  'UA0706033002': 'Борове',
  'UA0706033003': 'Будники',
  'UA0706033006': 'Гороховище',
  'UA0706033007': 'Гуща',
  'UA0706033009': 'Локутки',
  'UA0706033010': 'Миловань',
  'UA0706033011': 'Новоугрузьке',
  'UA0706033012': 'Підлісся',
  'UA0706033013': 'Полапи',
  'UA0706033001': 'Рівне',
  'UA0706033014': 'Рогові Смоляри',
  'UA0706033015': 'Сокіл',
  'UA0706033016': 'Старовойтове',
  'UA0706033017': 'Столинські Смоляри',
  'UA0706033004': 'Вербівка',
  'UA0706033005': 'Вишнівка',
  'UA0706033008': 'Забужжя',
  'UA0706035002': 'Березники',
  'UA0706035003': 'Боровуха',
  'UA0706035004': 'Бродятине',
  'UA0706035005': 'Головище',
  'UA0706035017': 'Хабарище',
  'UA0706035010': 'Козовата',
  'UA0706035011': 'Мале Оріхове',
  'UA0706035012': 'Межисить',
  'UA0706035013': 'Під\'язівні',
  'UA0706035014': 'Почапи',
  'UA0706035001': 'Самари',
  'UA0706035015': 'Самари-Оріхові',
  'UA0706035018': 'Щитинська Воля',
  'UA0706035016': 'Теребовичі',
  'UA0706035019': 'Язавні',
  'UA0706035007': 'Залухів',
  'UA0706035006': 'Залисиця',
  'UA0706035008': 'Занивське',
  'UA0706035009': 'Заприп\'ять',
  'UA0706037002': 'Грабове',
  'UA0706037003': 'Комарове',
  'UA0706037004': 'Мильці',
  'UA0706037006': 'Нова Воля',
  'UA0706037005': 'Ниці',
  'UA0706037007': 'Підсинівка',
  'UA0706037001': 'Сереховичі',
  'UA0706037011': 'Шкроби',
  'UA0706037009': 'Соколище',
  'UA0706037010': 'Солов\'ї',
  'UA0706037008': 'Синове',
  'UA0706045002': 'Адамчуки',
  'UA0706045005': 'Гаївка',
  'UA0706045006': 'Голядин',
  'UA0706045007': 'Грабове',
  'UA0706045010': 'Кам\'янка',
  'UA0706045030': 'Хомичі',
  'UA0706045031': 'Хрипськ',
  'UA0706045011': 'Кошари',
  'UA0706045012': 'Красний Бір',
  'UA0706045013': 'Кропивники',
  'UA0706045014': 'Мельники',
  'UA0706045015': 'Омельне',
  'UA0706045016': 'Острів\'я',
  'UA0706045018': 'Пехи',
  'UA0706045017': 'Перешпа',
  'UA0706045019': 'Підманове',
  'UA0706045020': 'Піща',
  'UA0706045021': 'Плоске',
  'UA0706045022': 'Положеве',
  'UA0706045023': 'Прип\'ять',
  'UA0706045024': 'Пулемець',
  'UA0706045025': 'Пульмо',
  'UA0706045026': 'Ростань',
  'UA0706045027': 'Самійличі',
  'UA0706045001': 'Шацьк',
  'UA0706045029': 'Смоляри-Світязькі',
  'UA0706045028': 'Світязь',
  'UA0706045004': 'Вільшанка',
  'UA0706045003': 'Вілиця',
  'UA0706045008': 'Залісся',
  'UA0706045009': 'Затишшя',
  'UA0706039002': 'Біличі',
  'UA0706039006': 'Кукуріки',
  'UA0706039007': 'Лісняки',
  'UA0706039008': 'Паридуби',
  'UA0706039009': 'Рудня',
  'UA0706039001': 'Смідин',
  'UA0706039010': 'Сьомаки',
  'UA0706039003': 'Високе',
  'UA0706039005': 'Зачернеччя',
  'UA0706039004': 'Журавлине',
  'UA0706041002': 'Борзова',
  'UA0706041003': 'Брідки',
  'UA0706041004': 'Брунетівка',
  'UA0706041017': 'Черемшанка',
  'UA0706041016': 'Чевель',
  'UA0706041005': 'Галина Воля',
  'UA0706041015': 'Хотивель',
  'UA0706041006': 'Мельники',
  'UA0706041007': 'Мизове',
  'UA0706041008': 'Нова Вижва',
  'UA0706041009': 'Поліське',
  'UA0706041010': 'Рудка',
  'UA0706041011': 'Седлище',
  'UA0706041012': 'Смолярі',
  'UA0706041013': 'Стара Гута',
  'UA0706041001': 'Стара Вижівка',
  'UA0706041014': 'Сукачі',
  'UA0706043002': 'Блаженик',
  'UA0706043003': 'Бобли',
  'UA0706043052': 'Чорніїв',
  'UA0706043008': 'Дольськ',
  'UA0706043007': 'Дожва',
  'UA0706043009': 'Дуліби',
  'UA0706043006': 'Гаруша',
  'UA0706043011': 'Клюськ',
  'UA0706043012': 'Кульчин',
  'UA0706043013': 'Купичів',
  'UA0706043014': 'Кустичі',
  'UA0706043016': 'Літин',
  'UA0706043017': 'Ловища',
  'UA0706043015': 'Липа',
  'UA0706043018': 'Маковичі',
  'UA0706043022': 'Мочалки',
  'UA0706043020': 'Мокрець',
  'UA0706043021': 'Молодівка',
  'UA0706043019': 'Мировичі',
  'UA0706043024': 'Новий Двір',
  'UA0706043023': 'Нири',
  'UA0706043025': 'Обенижі',
  'UA0706043031': 'Охотники',
  'UA0706043027': 'Оса',
  'UA0706043028': 'Осекрів',
  'UA0706043029': 'Осереби',
  'UA0706043030': 'Осьмиговичі',
  'UA0706043026': 'Озеряни',
  'UA0706043033': 'Пересіка',
  'UA0706043032': 'Перевали',
  'UA0706043034': 'Поляна',
  'UA0706043035': 'Радовичі',
  'UA0706043036': 'Растів',
  'UA0706043037': 'Ревушки',
  'UA0706043039': 'Селець',
  'UA0706043040': 'Серебряниця',
  'UA0706043041': 'Серкізів',
  'UA0706043043': 'Соловичі',
  'UA0706043044': 'Ставок',
  'UA0706043045': 'Сушибаба',
  'UA0706043038': 'Свинарин',
  'UA0706043042': 'Синявка',
  'UA0706043046': 'Тагачин',
  'UA0706043047': 'Торговище',
  'UA0706043048': 'Туличів',
  'UA0706043050': 'Турія',
  'UA0706043001': 'Турійськ',
  'UA0706043051': 'Туропин',
  'UA0706043049': 'Туричани',
  'UA0706043004': 'Вербичне',
  'UA0706043005': 'Волиця',
  'UA0706043010': 'Задиби',
  'UA0706001002': 'Датинь',
  'UA0706001003': 'Дошне',
  'UA0706001001': 'Велимче',
  'UA0706001004': 'Запілля',
  'UA0706003002': 'Арсеновичі',
  'UA0706003003': 'Кашівка',
  'UA0706003004': 'Кривлин',
  'UA0706003005': 'Кухарі',
  'UA0706003006': 'Мельниця',
  'UA0706003007': 'Мирин',
  'UA0706003008': 'Підліси',
  'UA0706003009': 'Підріжжя',
  'UA0706003010': 'Рудка-Миринська',
  'UA0706003011': 'Сільце',
  'UA0706003012': 'Угли',
  'UA0706003001': 'Велицьк',
  'UA0706005002': 'Бабаці',
  'UA0706005003': 'Бережці',
  'UA0706005020': 'Чмикос',
  'UA0706005006': 'Глинянка',
  'UA0706005019': 'Хворостів',
  'UA0706005008': 'Коцюри',
  'UA0706005009': 'Ладинь',
  'UA0706005010': 'Машів',
  'UA0706005011': 'Мосир',
  'UA0706005012': 'Олеськ',
  'UA0706005013': 'Приріччя',
  'UA0706005014': 'Пустинка',
  'UA0706005015': 'Радехів',
  'UA0706005017': 'Руда',
  'UA0706005016': 'Римачі',
  'UA0706005021': 'Штунь',
  'UA0706005018': 'Терехи',
  'UA0706005001': 'Вишнів',
  'UA0706005005': 'Висоцьк',
  'UA0706005004': 'Вижгів',
  'UA0706005007': 'Замлиння',
  'UA0706015002': 'Гута',
  'UA0706015004': 'Тур',
  'UA0706015001': 'Заболоття',
  'UA0706015003': 'Заліси',
  'UA0706017002': 'Адамівка',
  'UA0706017010': 'Лучичі',
  'UA0706017011': 'Мельники-Річицькі',
  'UA0706017012': 'Окачеве',
  'UA0706017013': 'Підгір\'я',
  'UA0706017014': 'Піски-Річицькі',
  'UA0706017015': 'Поступель',
  'UA0706017016': 'Річиця',
  'UA0706017017': 'Щедрогір',
  'UA0706017004': 'Вужиськ',
  'UA0706017003': 'Видраниця',
  'UA0706017018': 'Якушів',
  'UA0706017001': 'Заброди',
  'UA0706017005': 'Задолина',
  'UA0706017006': 'Заіванове',
  'UA0706017007': 'Залюття',
  'UA0706017008': 'Замшани',
  'UA0706017009': 'Зоряне',
  'UA0708001002': 'Антонівка',
  'UA0708001001': 'Берестечко',
  'UA0708001003': 'Богунівка',
  'UA0708001004': 'Буркачі',
  'UA0708001009': 'Диковини',
  'UA0708001006': 'Гектари',
  'UA0708001007': 'Горішнє',
  'UA0708001008': 'Гумнище',
  'UA0708001011': 'Іванівка',
  'UA0708001012': 'Колмів',
  'UA0708001013': 'Кутрів',
  'UA0708001015': 'Лобачівка',
  'UA0708001014': 'Липа',
  'UA0708001016': 'Мерва',
  'UA0708001017': 'Новостав',
  'UA0708001018': 'Перемиль',
  'UA0708001019': 'Піски',
  'UA0708001020': 'Смолява',
  'UA0708001021': 'Старики',
  'UA0708001005': 'Волиця-Лобачівська',
  'UA0708001010': 'Зелене',
  'UA0708003002': 'Баїв',
  'UA0708003003': 'Баківці',
  'UA0708003001': 'Боратин',
  'UA0708003006': 'Гірка Полонка',
  'UA0708003007': 'Голишів',
  'UA0708003008': 'Городище',
  'UA0708003009': 'Коршів',
  'UA0708003010': 'Коршовець',
  'UA0708003011': 'Лаврів',
  'UA0708003012': 'Лучиці',
  'UA0708003013': 'Мстишин',
  'UA0708003014': 'Новостав',
  'UA0708003015': 'Оздів',
  'UA0708003016': 'Озеряни',
  'UA0708003017': 'Полонка',
  'UA0708003018': 'Промінь',
  'UA0708003019': 'Радомишль',
  'UA0708003020': 'Ратнів',
  'UA0708003022': 'Романівка',
  'UA0708003021': 'Рованці',
  'UA0708003023': 'Суховоля',
  'UA0708003024': 'Цеперів',
  'UA0708003004': 'Вербаїв',
  'UA0708003005': 'Вікторяни',
  'UA0708009002': 'Береськ',
  'UA0708009001': 'Доросині',
  'UA0708009006': 'Квітневе',
  'UA0708009007': 'Кияж',
  'UA0708009008': 'Немир',
  'UA0708009009': 'Раймісто',
  'UA0708009012': 'Щурин',
  'UA0708009010': 'Студині',
  'UA0708009011': 'Тристень',
  'UA0708009004': 'Вічині',
  'UA0708009003': 'Вітоніж',
  'UA0708009005': 'Ворончин',
  'UA0708009013': 'Ясенівка',
  'UA0708005003': 'Бережанка',
  'UA0708005019': 'Чаруків',
  'UA0708005007': 'Дубова Корчма',
  'UA0708005001': 'Городище',
  'UA0708005005': 'Григоровичі',
  'UA0708005006': 'Губин Перший',
  'UA0708005010': 'Колодеже',
  'UA0708005011': 'Мартинівка',
  'UA0708005012': 'Маруся',
  'UA0708005013': 'Михлин',
  'UA0708005014': 'Наталин',
  'UA0708005015': 'Несвіч',
  'UA0708005016': 'Ниви-Губинські',
  'UA0708005002': 'Сенкевичівка',
  'UA0708005017': 'Сергіївка',
  'UA0708005021': 'Шклинь Другий',
  'UA0708005020': 'Шклинь',
  'UA0708005018': 'Угринів',
  'UA0708005004': 'Вигуричі',
  'UA0708005009': 'Загаї',
  'UA0708005008': 'Жабче',
  'UA0708007002': 'Бистровиця',
  'UA0708007007': 'Десятина',
  'UA0708007008': 'Діброва',
  'UA0708007001': 'Горохів',
  'UA0708007041': 'Холонів',
  'UA0708007013': 'Ковбань',
  'UA0708007014': 'Козятин',
  'UA0708007015': 'Красів',
  'UA0708007016': 'Крижова',
  'UA0708007017': 'Кумовище',
  'UA0708007012': 'Квасів',
  'UA0708007018': 'Лемешів',
  'UA0708007019': 'Марковичі',
  'UA0708007020': 'Мирків',
  'UA0708007021': 'Мирне',
  'UA0708007022': 'Мислині',
  'UA0708007023': 'Новосілки',
  'UA0708007025': 'Охлопів',
  'UA0708007026': 'Ощів',
  'UA0708007024': 'Озерці',
  'UA0708007027': 'Печихвости',
  'UA0708007028': 'Підбереззя',
  'UA0708007029': 'Пірванче',
  'UA0708007030': 'Полюхне',
  'UA0708007031': 'Пустомити',
  'UA0708007032': 'Рачин',
  'UA0708007033': 'Сільце',
  'UA0708007034': 'Скабарівщина',
  'UA0708007035': 'Скобелка',
  'UA0708007036': 'Софіївка',
  'UA0708007037': 'Старостав',
  'UA0708007038': 'Стрільче',
  'UA0708007039': 'Терешківці',
  'UA0708007040': 'Уманці',
  'UA0708007003': 'Ватин',
  'UA0708007004': 'Ватинець',
  'UA0708007005': 'Вільхівка',
  'UA0708007006': 'Волиця-Дружкопільська',
  'UA0708007042': 'Ярівка',
  'UA0708007011': 'Зеленолужне',
  'UA0708007009': 'Журавники',
  'UA0708007010': 'Звиняче',
  'UA0708011002': 'Бодячів',
  'UA0708011023': 'Човниця',
  'UA0708011004': 'Дідовичі',
  'UA0708011005': 'Домашів',
  'UA0708011003': 'Гайове',
  'UA0708011022': 'Хопнів',
  'UA0708011001': 'Ківерці',
  'UA0708011010': 'Конопелька',
  'UA0708011011': 'Лички',
  'UA0708011013': 'Муравище',
  'UA0708011012': 'Микове',
  'UA0708011015': 'Омельне',
  'UA0708011016': 'Острів',
  'UA0708011014': 'Озеро',
  'UA0708011017': 'Славне',
  'UA0708011018': 'Словатичі',
  'UA0708011019': 'Сокиричі',
  'UA0708011020': 'Суськ',
  'UA0708011021': 'Тростянець',
  'UA0708011024': 'Яромель',
  'UA0708011007': 'Заброди',
  'UA0708011008': 'Завітне',
  'UA0708011006': 'Журавичі',
  'UA0708011009': 'Звози',
  'UA0708013002': 'Боровичі',
  'UA0708013027': 'Четвертня',
  'UA0708013028': 'Чорниж',
  'UA0708013004': 'Годомичі',
  'UA0708013005': 'Гораймівка',
  'UA0708013006': 'Грузятин',
  'UA0708013008': 'Калинівка',
  'UA0708013001': 'Колки',
  'UA0708013009': 'Копилля',
  'UA0708013010': 'Красноволя',
  'UA0708013011': 'Криничне',
  'UA0708013012': 'Куликовичі',
  'UA0708013013': 'Майдан-Липненський',
  'UA0708013014': 'Мала Осниця',
  'UA0708013015': 'Мар\'янівка',
  'UA0708013016': 'Матейки',
  'UA0708013017': 'Нічогівка',
  'UA0708013018': 'Новоукраїнка',
  'UA0708013019': 'Острови',
  'UA0708013020': 'Погулянка',
  'UA0708013021': 'Розничі',
  'UA0708013022': 'Рудники',
  'UA0708013023': 'Семки',
  'UA0708013025': 'Старосілля',
  'UA0708013024': 'Ситниця',
  'UA0708013026': 'Тельчі',
  'UA0708013003': 'Велика Осниця',
  'UA0708013007': 'Заріччя',
  'UA0708015002': 'Башова',
  'UA0708015003': 'Березолуки',
  'UA0708015001': 'Копачівка',
  'UA0708015005': 'Кременець',
  'UA0708015006': 'Кроватка',
  'UA0708015007': 'Любче',
  'UA0708015008': 'Олександрівка',
  'UA0708015009': 'Підгірне',
  'UA0708015010': 'Підліски',
  'UA0708015011': 'Тростянка',
  'UA0708015013': 'Уляники',
  'UA0708015012': 'Ужова',
  'UA0708015014': 'Яблунівка',
  'UA0708015004': 'Залісці',
  'UA0708017003': 'Антонівка',
  'UA0708017004': 'Боголюби',
  'UA0708017005': 'Богушівка',
  'UA0708017006': 'Брище',
  'UA0708017007': 'Буків',
  'UA0708017011': 'Дачне',
  'UA0708017010': 'Городок',
  'UA0708017017': 'Іванчиці',
  'UA0708017018': 'Клепачів',
  'UA0708017019': 'Княгининок',
  'UA0708017020': 'Кульчин',
  'UA0708017001': 'Луцьк',
  'UA0708017021': 'Липляни',
  'UA0708017024': 'Моташівка',
  'UA0708017023': 'Милуші',
  'UA0708017022': 'Милушин',
  'UA0708017025': 'Небіжка',
  'UA0708017026': 'Одеради',
  'UA0708017030': 'Охотин',
  'UA0708017029': 'Олександрівка',
  'UA0708017027': 'Озденіж',
  'UA0708017028': 'Озерце',
  'UA0708017031': 'Прилуцьке',
  'UA0708017002': 'Рокині',
  'UA0708017032': 'Сапогове',
  'UA0708017036': 'Шепель',
  'UA0708017034': 'Сьомаки',
  'UA0708017033': 'Сирники',
  'UA0708017035': 'Тарасове',
  'UA0708017008': 'Великий Омеляник',
  'UA0708017009': 'Всеволодівка',
  'UA0708017014': 'Заболотці',
  'UA0708017015': 'Забороль',
  'UA0708017012': 'Жабка',
  'UA0708017013': 'Жидичин',
  'UA0708017016': 'Зміїнець',
  'UA0708019003': 'Борочиче',
  'UA0708019002': 'Борисковичі',
  'UA0708019004': 'Брани',
  'UA0708019005': 'Бужани',
  'UA0708019007': 'Довгів',
  'UA0708019006': 'Галичани',
  'UA0708019012': 'Хмельницьке',
  'UA0708019001': 'Мар\'янівка',
  'UA0708019008': 'Новий Зборишів',
  'UA0708019009': 'Пильгани',
  'UA0708019010': 'Ржищів',
  'UA0708019014': 'Широке',
  'UA0708019011': 'Скригове',
  'UA0708019013': 'Цегів',
  'UA0708021017': 'Чемерин',
  'UA0708021003': 'Дерно',
  'UA0708021004': 'Дідичі',
  'UA0708021002': 'Горянівка',
  'UA0708021016': 'Хром\'яків',
  'UA0708021007': 'Котів',
  'UA0708021008': 'Личани',
  'UA0708021009': 'Метельне',
  'UA0708021010': 'Мощаниця',
  'UA0708021011': 'Носовичі',
  'UA0708021012': 'Одеради',
  'UA0708021001': 'Олика',
  'UA0708021013': 'Покащів',
  'UA0708021014': 'Путилівка',
  'UA0708021015': 'Ставок',
  'UA0708021006': 'Залісоче',
  'UA0708021005': 'Жорнище',
  'UA0708023002': 'Борохів',
  'UA0708023003': 'Ботин',
  'UA0708023009': 'Діброва',
  'UA0708023008': 'Гаразджа',
  'UA0708023020': 'Хорлупи',
  'UA0708023011': 'Крупа',
  'UA0708023012': 'Липини',
  'UA0708023013': 'Лище',
  'UA0708023014': 'Новокотів',
  'UA0708023015': 'Олександрія',
  'UA0708023016': 'Пальче',
  'UA0708023017': 'Піддубці',
  'UA0708023001': 'Підгайці',
  'UA0708023018': 'Романів',
  'UA0708023019': 'Струмівка',
  'UA0708023004': 'Верхівка',
  'UA0708023005': 'Веснянка',
  'UA0708023007': 'Воротнів',
  'UA0708023006': 'Вишнів',
  'UA0708023010': 'Звірів',
  'UA0708025003': 'Берегове',
  'UA0708025004': 'Богушівська Мар\'янівка',
  'UA0708025005': 'Бортяхівка',
  'UA0708025008': 'Дмитрівка',
  'UA0708025002': 'Дубище',
  'UA0708025009': 'Духче',
  'UA0708025012': 'Іванівка',
  'UA0708025013': 'Кобче',
  'UA0708025015': 'Корсині',
  'UA0708025014': 'Козин',
  'UA0708025016': 'Крижівка',
  'UA0708025018': 'Літогоща',
  'UA0708025019': 'Луків',
  'UA0708025017': 'Линівка',
  'UA0708025020': 'Малинівка',
  'UA0708025023': 'Михайлин',
  'UA0708025021': 'Мильськ',
  'UA0708025022': 'Мирославка',
  'UA0708025024': 'Навіз',
  'UA0708025025': 'Незвір',
  'UA0708025026': 'Носачевичі',
  'UA0708025027': 'Оленівка',
  'UA0708025028': 'Олешковичі',
  'UA0708025029': 'Ольганівка',
  'UA0708025030': 'Переспа',
  'UA0708025031': 'Пожарки',
  'UA0708025001': 'Рожище',
  'UA0708025032': 'Рудка-Козинська',
  'UA0708025033': 'Рудня',
  'UA0708025034': 'Сокіл',
  'UA0708025036': 'Топільне',
  'UA0708025037': 'Трилісці',
  'UA0708025035': 'Тихотин',
  'UA0708025006': 'Валер\'янівка',
  'UA0708025007': 'Вишеньки',
  'UA0708025010': 'Єлизаветин',
  'UA0708025011': 'Забара',
  'UA0708027002': 'Барвінок',
  'UA0708027003': 'Білосток',
  'UA0708027004': 'Буяни',
  'UA0708027024': 'Чорний Ліс',
  'UA0708027011': 'Дубичанське',
  'UA0708027008': 'Гать',
  'UA0708027010': 'Городині',
  'UA0708027009': 'Горзвин',
  'UA0708027023': 'Хорохорин',
  'UA0708027013': 'Кошів',
  'UA0708027014': 'Михайлівка',
  'UA0708027015': 'Романів',
  'UA0708027016': 'Садів',
  'UA0708027017': 'Сарнівка',
  'UA0708027018': 'Скірче',
  'UA0708027019': 'Смолигів',
  'UA0708027020': 'Тертки',
  'UA0708027001': 'Торчин',
  'UA0708027021': 'Усичі',
  'UA0708027022': 'Усичівські Будки',
  'UA0708027005': 'Верхи',
  'UA0708027006': 'Веселе',
  'UA0708027007': 'Воютин',
  'UA0708027012': 'Жуковець',
  'UA0708029002': 'Башлики',
  'UA0708029003': 'Берестяне',
  'UA0708029006': 'Дубище',
  'UA0708029004': 'Городище',
  'UA0708029005': 'Грем\'яче',
  'UA0708029008': 'Кадище',
  'UA0708029009': 'Карпилівка',
  'UA0708029015': 'Холоневичі',
  'UA0708029010': 'Клубочин',
  'UA0708029011': 'Липне',
  'UA0708029012': 'Ромашківка',
  'UA0708029014': 'Скреготівка',
  'UA0708029013': 'Сильне',
  'UA0708029001': 'Цумань',
  'UA0708029016': 'Яківці',
  'UA0708029007': 'Знамирівка',
  'UA0702007002': 'Долинка',
  'UA0702007003': 'Древині',
  'UA0702007004': 'Іванівка',
  'UA0702007001': 'Іваничі',
  'UA0702007005': 'Лугове',
  'UA0702007006': 'Менчичі',
  'UA0702007007': 'Мишів',
  'UA0702007008': 'Романівка',
  'UA0702007009': 'Соснина',
  'UA0702011002': 'Бермешів',
  'UA0702011003': 'Білопіль',
  'UA0702011004': 'Бубнів',
  'UA0702011005': 'Дорогиничі',
  'UA0702011027': 'Хорів',
  'UA0702011011': 'Колпитів',
  'UA0702011012': 'Конюхи',
  'UA0702011013': 'Коритниця',
  'UA0702011010': 'Козлів',
  'UA0702011014': 'Кремеш',
  'UA0702011015': 'Крухиничі',
  'UA0702011016': 'Кути',
  'UA0702011001': 'Локачі',
  'UA0702011017': 'Линів',
  'UA0702011018': 'Марковичі',
  'UA0702011019': 'Міжгір\'я',
  'UA0702011021': 'Новий Загорів',
  'UA0702011020': 'Низькі Цевеличі',
  'UA0702011023': 'П\'ятикори',
  'UA0702011022': 'Привітне',
  'UA0702011024': 'Роговичі',
  'UA0702011025': 'Старий Загорів',
  'UA0702011028': 'Цевеличі',
  'UA0702011026': 'Твориничі',
  'UA0702011009': 'Заячиці',
  'UA0702011006': 'Залужне',
  'UA0702011007': 'Замличі',
  'UA0702011008': 'Защитів',
  'UA0702009002': 'Біличі',
  'UA0702009005': 'Кречів',
  'UA0702009001': 'Литовеж',
  'UA0702009006': 'Мовники',
  'UA0702009003': 'Заболотці',
  'UA0702009004': 'Заставне',
  'UA0702013002': 'Благодатне',
  'UA0702013004': 'Гряди',
  'UA0702013003': 'Грибовиця',
  'UA0702013008': 'Хренів',
  'UA0702013005': 'Кропивщина',
  'UA0702013001': 'Нововолинськ',
  'UA0702013006': 'Низкиничі',
  'UA0702013007': 'Тишковичі',
  'UA0702015002': 'Білин',
  'UA0702015005': 'Гайки',
  'UA0702015006': 'Галинівка',
  'UA0702015007': 'Гевин',
  'UA0702015009': 'Красностав',
  'UA0702015010': 'Крать',
  'UA0702015011': 'Ліски',
  'UA0702015012': 'Людмильпіль',
  'UA0702015013': 'Маркелівка',
  'UA0702015015': 'Охнівка',
  'UA0702015001': 'Овадне',
  'UA0702015014': 'Овлочин',
  'UA0702015016': 'Писарева Воля',
  'UA0702015017': 'Руда',
  'UA0702015019': 'Ставки',
  'UA0702015020': 'Сусваль',
  'UA0702015018': 'Свійчів',
  'UA0702015003': 'Верба',
  'UA0702015004': 'Воля-Свійчівська',
  'UA0702015021': 'Ягідне',
  'UA0702015008': 'Замости',
  'UA0702017002': 'Бужковичі',
  'UA0702017004': 'Грушів',
  'UA0702017007': 'Клопочин',
  'UA0702017008': 'Колона',
  'UA0702017009': 'Луковичі',
  'UA0702017010': 'Милятин',
  'UA0702017011': 'Орищі',
  'UA0702017001': 'Павлівка',
  'UA0702017012': 'Переславичі',
  'UA0702017013': 'Радовичі',
  'UA0702017014': 'Риковичі',
  'UA0702017015': 'Самоволя',
  'UA0702017020': 'Щенятин',
  'UA0702017017': 'Старосілля',
  'UA0702017016': 'Старий Порицьк',
  'UA0702017018': 'Топилище',
  'UA0702017019': 'Трубки',
  'UA0702017003': 'Волиця',
  'UA0702017006': 'Завидів',
  'UA0702017005': 'Жашковичі',
  'UA0702019002': 'Бортнів',
  'UA0702019003': 'Будятичі',
  'UA0702019004': 'Бужанка',
  'UA0702019007': 'Іванів',
  'UA0702019008': 'Космівка',
  'UA0702019009': 'Лежниця',
  'UA0702019011': 'Млинище',
  'UA0702019012': 'Морозовичі',
  'UA0702019010': 'Михалє',
  'UA0702019013': 'Нова Лішня',
  'UA0702019014': 'Осмиловичі',
  'UA0702019015': 'Петрове',
  'UA0702019001': 'Поромів',
  'UA0702019016': 'Русовичі',
  'UA0702019018': 'Шахтарське',
  'UA0702019017': 'Стара Лішня',
  'UA0702019005': 'Верхнів',
  'UA0702019006': 'Волиця-Морозовицька',
  'UA0702021002': 'Амбуків',
  'UA0702021026': 'Чорників',
  'UA0702021004': 'Дарницьке',
  'UA0702021008': 'Ізов',
  'UA0702021024': 'Хотячів',
  'UA0702021025': 'Хрипаличі',
  'UA0702021009': 'Кладнів',
  'UA0702021010': 'Коритниця',
  'UA0702021011': 'Лудин',
  'UA0702021012': 'Микитичі',
  'UA0702021013': 'Новини',
  'UA0702021014': 'Пархоменкове',
  'UA0702021016': 'П\'ятидні',
  'UA0702021015': 'Полум\'яне',
  'UA0702021017': 'Рогожани',
  'UA0702021018': 'Рокитниця',
  'UA0702021019': 'Русів',
  'UA0702021020': 'Селіски',
  'UA0702021021': 'Стенжаричі',
  'UA0702021022': 'Тростянка',
  'UA0702021023': 'Турівка',
  'UA0702021001': 'Устилуг',
  'UA0702021003': 'Ворчин',
  'UA0702021005': 'Заболоття',
  'UA0702021006': 'Залужжя',
  'UA0702021007': 'Зоря',
  'UA0702001003': 'Дігтів',
  'UA0702001009': 'Федорівка',
  'UA0702001005': 'Ласків',
  'UA0702001006': 'Новосілки',
  'UA0702001007': 'Орані',
  'UA0702001008': 'Суходоли',
  'UA0702001001': 'Володимир',
  'UA0702001002': 'Вощатин',
  'UA0702001004': 'Заріччя',
  'UA0702003006': 'Гранатів',
  'UA0702003007': 'Губин',
  'UA0702003024': 'Холопичі',
  'UA0702003011': 'Квасовиця',
  'UA0702003012': 'Кисилин',
  'UA0702003013': 'Малий Окорськ',
  'UA0702003014': 'Маньків',
  'UA0702003016': 'Мовчанів',
  'UA0702003015': 'Михайлівка',
  'UA0702003017': 'Озютичі',
  'UA0702003018': 'Павловичі',
  'UA0702003019': 'Садівські Дубини',
  'UA0702003020': 'Семеринське',
  'UA0702003025': 'Шельвів',
  'UA0702003021': 'Сірнички',
  'UA0702003023': 'Тумин',
  'UA0702003022': 'Твердині',
  'UA0702003002': 'Великий Окорськ',
  'UA0702003003': 'Війниця',
  'UA0702003004': 'Вілька-Садівська',
  'UA0702003005': 'Войнин',
  'UA0702003026': 'Юнівка',
  'UA0702003009': 'Запуст',
  'UA0702003001': 'Затурці',
  'UA0702003008': 'Журавець',
  'UA0702003010': 'Зубильне',
  'UA0702005002': 'Бегета',
  'UA0702005003': 'Березовичі',
  'UA0702005004': 'Бобичі',
  'UA0702005005': 'Бубнів',
  'UA0702005027': 'Черчичі',
  'UA0702005028': 'Чесний Хрест',
  'UA0702005022': 'Фалемичі',
  'UA0702005007': 'Горичів',
  'UA0702005024': 'Хмелів',
  'UA0702005025': 'Хмелівка',
  'UA0702005026': 'Хобултова',
  'UA0702005023': 'Хворостів',
  'UA0702005009': 'Когильне',
  'UA0702005010': 'Льотниче',
  'UA0702005011': 'Марія-Воля',
  'UA0702005012': 'Маркостав',
  'UA0702005014': 'Міжлісся',
  'UA0702005013': 'Микуличі',
  'UA0702005015': 'Нехвороща',
  'UA0702005016': 'Октавин',
  'UA0702005017': 'Острівок',
  'UA0702005018': 'Підгайці',
  'UA0702005019': 'Поничів',
  'UA0702005020': 'Руснів',
  'UA0702005021': 'Селець',
  'UA0702005029': 'Шистів',
  'UA0702005006': 'Володимирівка',
  'UA0702005030': 'Яковичі',
  'UA0702005008': 'Житані',
  'UA0702005001': 'Зимне',
  'UA2102001002': 'Бадів',
  'UA2102001003': 'Бакош',
  'UA2102001004': 'Баркасово',
  'UA2102001001': 'Батьово',
  'UA2102001005': 'Батрадь',
  'UA2102001007': 'Данилівка',
  'UA2102001006': 'Горонглаб',
  'UA2102001009': 'Серне',
  'UA2102001008': 'Свобода',
  'UA2102003002': 'Бадалово',
  'UA2102003003': 'Балажер',
  'UA2102003004': 'Бене',
  'UA2102003001': 'Берегове',
  'UA2102003005': 'Боржава',
  'UA2102003015': 'Четфалва',
  'UA2102003017': 'Чома',
  'UA2102003016': 'Чикош-Горонда',
  'UA2102003008': 'Галабор',
  'UA2102003009': 'Гать',
  'UA2102003010': 'Геча',
  'UA2102003012': 'Кідьош',
  'UA2102003013': 'Мужієво',
  'UA2102003014': 'Оросієво',
  'UA2102003006': 'Вари',
  'UA2102003007': 'Велика Бакта',
  'UA2102003018': 'Яноші',
  'UA2102003011': 'Затишне',
  'UA2102013002': 'Арданово',
  'UA2102013003': 'Богаревиця',
  'UA2102013005': 'Дунковиця',
  'UA2102013001': 'Кам\'янське',
  'UA2102013008': 'Хмільник',
  'UA2102013006': 'Мідяниця',
  'UA2102013007': 'Сільце',
  'UA2102013004': 'Воловиця',
  'UA2102015009': 'Черна',
  'UA2102015003': 'Горбки',
  'UA2102015004': 'Гудя',
  'UA2102015008': 'Хижа',
  'UA2102015001': 'Королево',
  'UA2102015005': 'Новоселиця',
  'UA2102015006': 'Сасово',
  'UA2102015007': 'Теково',
  'UA2102015002': 'Веряця',
  'UA2102017002': 'Гетен',
  'UA2102017004': 'Каштаново',
  'UA2102017001': 'Косонь',
  'UA2102017005': 'Мале Попово',
  'UA2102017006': 'Попово',
  'UA2102017007': 'Рафайново',
  'UA2102017008': 'Шом',
  'UA2102017003': 'Запсонь',
  'UA2102019002': 'Ботар',
  'UA2102019015': 'Чепа',
  'UA2102019016': 'Чорнотисів',
  'UA2102019005': 'Дюла',
  'UA2102019012': 'Фертешолмаш',
  'UA2102019013': 'Форголань',
  'UA2102019004': 'Гетиня',
  'UA2102019014': 'Холмовець',
  'UA2102019007': 'Неветленфолу',
  'UA2102019008': 'Нове Клинове',
  'UA2102019010': 'Оклі Гедь',
  'UA2102019009': 'Оклі',
  'UA2102019001': 'Пийтерфолво',
  'UA2102019011': 'Тисобикень',
  'UA2102019003': 'Велика Паладь',
  'UA2102019006': 'Затисівка',
  'UA2102005002': 'Берегуйфалу',
  'UA2102005004': 'Квасово',
  'UA2102005005': 'Нижні Ремети',
  'UA2102005001': 'Великі Береги',
  'UA2102005003': 'Верхні Ремети',
  'UA2102007002': 'Астей',
  'UA2102007005': 'Дийда',
  'UA2102007003': 'Гуняді',
  'UA2102007004': 'Гут',
  'UA2102007006': 'Мала Бийгань',
  'UA2102007007': 'Мочола',
  'UA2102007001': 'Велика Бийгань',
  'UA2102009010': 'Чорний Потік',
  'UA2102009004': 'Карачин',
  'UA2102009005': 'Матійово',
  'UA2102009006': 'Нове Село',
  'UA2102009007': 'Перехрестя',
  'UA2102009008': 'Пушкіно',
  'UA2102009009': 'Руська Долина',
  'UA2102009011': 'Шаланки',
  'UA2102009002': 'Вербове',
  'UA2102009003': 'Вербовець',
  'UA2102009001': 'Вилок',
  'UA2102011002': 'Боржавське',
  'UA2102011003': 'Букове',
  'UA2102011006': 'Дротинці',
  'UA2102011013': 'Фанчиково',
  'UA2102011007': 'Мала Копаня',
  'UA2102011008': 'Олешник',
  'UA2102011009': 'Онок',
  'UA2102011010': 'Підвиноградів',
  'UA2102011011': 'Притисянське',
  'UA2102011014': 'Широке',
  'UA2102011012': 'Тросник',
  'UA2102011004': 'Велика Копаня',
  'UA2102011005': 'Великі Ком\'яти',
  'UA2102011001': 'Виноградів',
  'UA2112001001': 'Білки',
  'UA2112001004': 'Імстичово',
  'UA2112001005': 'Луково',
  'UA2112001006': 'Малий Раковець',
  'UA2112001002': 'Великий Раковець',
  'UA2112001003': 'Заболотне',
  'UA2112007002': 'Бронька',
  'UA2112007001': 'Довге',
  'UA2112007003': 'Каллів',
  'UA2112007004': 'Липецька Поляна',
  'UA2112007005': 'Ожоверх',
  'UA2112007006': 'Приборжавське',
  'UA2112007007': 'Слоповий',
  'UA2112007008': 'Суха',
  'UA2112009001': 'Драгово',
  'UA2112009006': 'Кічерели',
  'UA2112009007': 'Становець',
  'UA2112009002': 'Вільшани',
  'UA2112009003': 'Забереж',
  'UA2112009004': 'Забрідь',
  'UA2112009005': 'Золотарьово',
  'UA2112005002': 'Березово',
  'UA2112005004': 'Ділок',
  'UA2112005003': 'Гонцош',
  'UA2112005001': 'Горінчово',
  'UA2112005005': 'Кутлаш',
  'UA2112005006': 'Медвежий',
  'UA2112005007': 'Монастирець',
  'UA2112005008': 'Нижній Бистрий',
  'UA2112005009': 'Облаз',
  'UA2112005010': 'Посіч',
  'UA2112005011': 'Поточок',
  'UA2112005012': 'Противень',
  'UA2112005013': 'Ряпідь',
  'UA2112005016': 'Широке',
  'UA2112005014': 'Сюрюк',
  'UA2112005015': 'Тополин',
  'UA2112013002': 'Брід',
  'UA2112013020': 'Чорний Потік',
  'UA2112013004': 'Дешковиця',
  'UA2112013006': 'Дубрівка',
  'UA2112013005': 'Дуби',
  'UA2112013009': 'Ільниця',
  'UA2112013001': 'Іршава',
  'UA2112013008': 'Івашковиця',
  'UA2112013010': 'Климовиця',
  'UA2112013011': 'Кобалевиця',
  'UA2112013012': 'Крайня Мартинка',
  'UA2112013014': 'Локіть',
  'UA2112013013': 'Лоза',
  'UA2112013015': 'Мала Розтока',
  'UA2112013016': 'Осій',
  'UA2112013017': 'Підгірне',
  'UA2112013018': 'Смологовиця',
  'UA2112013019': 'Собатин',
  'UA2112013003': 'Велика Розтока',
  'UA2112013007': 'Загаття',
  'UA2112015002': 'Березники',
  'UA2112015001': 'Керецьки',
  'UA2112015003': 'Кушниця',
  'UA2112015004': 'Лисичово',
  'UA2112025002': 'Боронява',
  'UA2112025028': 'Чертіж',
  'UA2112025004': 'Данилово',
  'UA2112025007': 'Іза',
  'UA2112025008': 'Карповтлаш',
  'UA2112025001': 'Хуст',
  'UA2112025027': 'Хустець',
  'UA2112025009': 'Кіреші',
  'UA2112025010': 'Копашново',
  'UA2112025011': 'Кошельово',
  'UA2112025012': 'Крайнє',
  'UA2112025013': 'Крайниково',
  'UA2112025014': 'Крива',
  'UA2112025015': 'Кривий',
  'UA2112025018': 'Лунка',
  'UA2112025017': 'Липча',
  'UA2112025016': 'Липовець',
  'UA2112025019': 'Нанково',
  'UA2112025020': 'Нижнє Селище',
  'UA2112025021': 'Олександрівка',
  'UA2112025022': 'Осава',
  'UA2112025023': 'Поляна',
  'UA2112025024': 'Рокосово',
  'UA2112025025': 'Сокирниця',
  'UA2112025026': 'Стеблівка',
  'UA2112025003': 'Вертеп',
  'UA2112025005': 'Залом',
  'UA2112025006': 'Зарічне',
  'UA2112017002': 'Горб',
  'UA2112017001': 'Колочава',
  'UA2112017003': 'Косів Верх',
  'UA2112017004': 'Мерешор',
  'UA2112017005': 'Негровець',
  'UA2112019005': 'Діл',
  'UA2112019004': 'Голятин',
  'UA2112019008': 'Лісковець',
  'UA2112019010': 'Лопушне',
  'UA2112019009': 'Лозянський',
  'UA2112019011': 'Майдан',
  'UA2112019001': 'Міжгір\'я',
  'UA2112019012': 'Новоселиця',
  'UA2112019013': 'Підчумаль',
  'UA2112019014': 'Присліп',
  'UA2112019015': 'Рекіти',
  'UA2112019016': 'Репинне',
  'UA2112019017': 'Сойми',
  'UA2112019018': 'Сопки',
  'UA2112019019': 'Стригальня',
  'UA2112019020': 'Сухий',
  'UA2112019021': 'Тітківці',
  'UA2112019022': 'Торунь',
  'UA2112019002': 'Верхній Бистрий',
  'UA2112019003': 'Вучкове',
  'UA2112019007': 'Запереділля',
  'UA2112019006': 'Завийка',
  'UA2112021002': 'Буковець',
  'UA2112021004': 'Ізки',
  'UA2112021005': 'Келечин',
  'UA2112021006': 'Нижній Студений',
  'UA2112021007': 'Подобовець',
  'UA2112021008': 'Потік',
  'UA2112021001': 'Пилипець',
  'UA2112021009': 'Річка',
  'UA2112021010': 'Розтока',
  'UA2112021011': 'Тюшка',
  'UA2112021003': 'Верхній Студений',
  'UA2112023002': 'Береги',
  'UA2112023005': 'Свобода',
  'UA2112023001': 'Синевир',
  'UA2112023006': 'Синевирська Поляна',
  'UA2112023004': 'Загорб',
  'UA2112023003': 'Заверхня Кичера',
  'UA2112003003': 'Модьорош',
  'UA2112003004': 'Ракош',
  'UA2112003005': 'Шаян',
  'UA2112003002': 'Велятино',
  'UA2112003001': 'Вишково',
  'UA2112003006': 'Яблунівка',
  'UA2112011003': 'Гребля',
  'UA2112011004': 'Нижнє Болотне',
  'UA2112011002': 'Вільхівка',
  'UA2112011001': 'Заріччя',
  'UA2104025002': 'Бабичі',
  'UA2104025004': 'Брестів',
  'UA2104025003': 'Бистриця',
  'UA2104025014': 'Чабин',
  'UA2104025001': 'Чинадійово',
  'UA2104025006': 'Ділок',
  'UA2104025007': 'Дубино',
  'UA2104025008': 'Карпати',
  'UA2104025009': 'Косино',
  'UA2104025010': 'Лецовиця',
  'UA2104025011': 'Обава',
  'UA2104025012': 'Плоскановиця',
  'UA2104025013': 'Синяк',
  'UA2104025005': 'Вільховиця',
  'UA2104007001': 'Горонда',
  'UA2104007003': 'Страбичово',
  'UA2104007002': 'Жнятино',
  'UA2104011002': 'Бобовище',
  'UA2104011012': 'Череївці',
  'UA2104011003': 'Грибівці',
  'UA2104011005': 'Ільківці',
  'UA2104011001': 'Іванівці',
  'UA2104011006': 'Клячаново',
  'UA2104011007': 'Копинівці',
  'UA2104011008': 'Лохово',
  'UA2104011009': 'Микулівці',
  'UA2104011010': 'Ростов\'ятиця',
  'UA2104011013': 'Щасливе',
  'UA2104011011': 'Старе Давидково',
  'UA2104011004': 'Жуково',
  'UA2104013003': 'Герцівці',
  'UA2104013004': 'Грабово',
  'UA2104013006': 'Кленовець',
  'UA2104013007': 'Клочки',
  'UA2104013001': 'Кольчино',
  'UA2104013008': 'Коноплівці',
  'UA2104013009': 'Крите',
  'UA2104013010': 'Лісарня',
  'UA2104013011': 'Пузняківці',
  'UA2104013012': 'Тростяниця',
  'UA2104013002': 'Верхня Визниця',
  'UA2104013005': 'Жборівці',
  'UA2104015002': 'Барбово',
  'UA2104015004': 'Дерцен',
  'UA2104015005': 'Доробратово',
  'UA2104015017': 'Форнош',
  'UA2104015003': 'Горбок',
  'UA2104015008': 'Ключарки',
  'UA2104015009': 'Лавки',
  'UA2104015010': 'Макарьово',
  'UA2104015001': 'Мукачево',
  'UA2104015011': 'Негрово',
  'UA2104015013': 'Нове Давидково',
  'UA2104015012': 'Нижній Коропець',
  'UA2104015014': 'Павшино',
  'UA2104015015': 'Пістрялово',
  'UA2104015016': 'Ромочевиця',
  'UA2104015018': 'Шенборн',
  'UA2104015007': 'Залужжя',
  'UA2104015006': 'Завидово',
  'UA2104017003': 'Ганьковиця',
  'UA2104017001': 'Неліпино',
  'UA2104017004': 'Сасівка',
  'UA2104017002': 'Вовчий',
  'UA2104019002': 'Абранка',
  'UA2104019003': 'Біласовиця',
  'UA2104019008': 'Котельниця',
  'UA2104019010': 'Латірка',
  'UA2104019009': 'Лази',
  'UA2104019001': 'Нижні Ворота',
  'UA2104019011': 'Тишів',
  'UA2104019004': 'Верб\'яж',
  'UA2104019005': 'Верхні Ворота',
  'UA2104019007': 'Задільське',
  'UA2104019006': 'Завадка',
  'UA2104021002': 'Голубине',
  'UA2104021003': 'Оленьово',
  'UA2104021005': 'Пасіка',
  'UA2104021004': 'Павлово',
  'UA2104021006': 'Плоске',
  'UA2104021007': 'Плоский Потік',
  'UA2104021001': 'Поляна',
  'UA2104021008': 'Родниківка',
  'UA2104021009': 'Родникова Гута',
  'UA2104021010': 'Солочин',
  'UA2104021011': 'Сусково',
  'UA2104021012': 'Уклин',
  'UA2104021013': 'Яківське',
  'UA2104023010': 'Черник',
  'UA2104023002': 'Драчино',
  'UA2104023003': 'Дусино',
  'UA2104023004': 'Лопушанка',
  'UA2104023005': 'Мала Мартинка',
  'UA2104023006': 'Плав\'я',
  'UA2104023007': 'Росош',
  'UA2104023008': 'Стройне',
  'UA2104023001': 'Свалява',
  'UA2104023009': 'Тибава',
  'UA2104001002': 'Бенедиківці',
  'UA2104001015': 'Червеньово',
  'UA2104001016': 'Чопівці',
  'UA2104001004': 'Домбоки',
  'UA2104001005': 'Драгиня',
  'UA2104001007': 'Кайданово',
  'UA2104001008': 'Кальник',
  'UA2104001009': 'Кінлодь',
  'UA2104001010': 'Кузьмино',
  'UA2104001011': 'Медведівці',
  'UA2104001012': 'Ракошино',
  'UA2104001013': 'Руська Кучава',
  'UA2104001014': 'Руське',
  'UA2104001017': 'Шкуратівці',
  'UA2104001001': 'Великі Лучки',
  'UA2104001003': 'Вінкове',
  'UA2104001006': 'Зняцьово',
  'UA2104003002': 'Березинка',
  'UA2104003003': 'Буковинка',
  'UA2104003004': 'Гандеровиця',
  'UA2104003006': 'Кучава',
  'UA2104003007': 'Куштановиця',
  'UA2104003008': 'Лалово',
  'UA2104003009': 'Новоселиця',
  'UA2104003010': 'Софія',
  'UA2104003011': 'Станово',
  'UA2104003001': 'Верхній Коропець',
  'UA2104003012': 'Яблунів',
  'UA2104003005': 'Зубівка',
  'UA2104005002': 'Гукливий',
  'UA2104005003': 'Канора',
  'UA2104005004': 'Скотарське',
  'UA2104005001': 'Воловець',
  'UA2104009002': 'Буковець',
  'UA2104009005': 'Кічерний',
  'UA2104009006': 'Пашківці',
  'UA2104009007': 'Перехресний',
  'UA2104009008': 'Підполоззя',
  'UA2104009009': 'Розтока',
  'UA2104009010': 'Щербовець',
  'UA2104009003': 'Верхня Грабівниця',
  'UA2104009011': 'Ялове',
  'UA2104009004': 'Збини',
  'UA2104009001': 'Жденієво',
  'UA2106001001': 'Богдан',
  'UA2106001002': 'Бребоя',
  'UA2106001004': 'Говерла',
  'UA2106001005': 'Луги',
  'UA2106001006': 'Розтоки',
  'UA2106001003': 'Видричка',
  'UA2106005002': 'Білин',
  'UA2106005004': 'Ділове',
  'UA2106005007': 'Хмелів',
  'UA2106005005': 'Костилівка',
  'UA2106005006': 'Круглий',
  'UA2106005001': 'Рахів',
  'UA2106005003': 'Вільховатий',
  'UA2106003002': 'Кобилецька Поляна',
  'UA2106003005': 'Косівська Поляна',
  'UA2106003006': 'Луг',
  'UA2106003007': 'Плаюць',
  'UA2106003008': 'Росішка',
  'UA2106003009': 'Стримба',
  'UA2106003001': 'Великий Бичків',
  'UA2106003003': 'Верхнє Водяне',
  'UA2106003004': 'Водиця',
  'UA2106007007': 'Чорна Тиса',
  'UA2106007002': 'Кваси',
  'UA2106007003': 'Лазещина',
  'UA2106007004': 'Сітний',
  'UA2106007005': 'Стебний',
  'UA2106007006': 'Тростянець',
  'UA2106007001': 'Ясіня',
  'UA2108001001': 'Бедевля',
  'UA2108001002': 'Біловарці',
  'UA2108001004': 'Дібрівка',
  'UA2108001003': 'Глиняний',
  'UA2108001005': 'Руня',
  'UA2108003001': 'Буштино',
  'UA2108003009': 'Чумальово',
  'UA2108003003': 'Дулово',
  'UA2108003004': 'Кричово',
  'UA2108003005': 'Новобарово',
  'UA2108003006': 'Рівне',
  'UA2108003007': 'Росош',
  'UA2108003008': 'Теребля',
  'UA2108003002': 'Вонігове',
  'UA2108007001': 'Дубове',
  'UA2108007003': 'Калини',
  'UA2108007004': 'Красна',
  'UA2108007005': 'Нижній Дубовець',
  'UA2108007002': 'Вишній Дубовець',
  'UA2108009011': 'Фонтиняси',
  'UA2108009003': 'Ганичі',
  'UA2108009001': 'Нересниця',
  'UA2108009004': 'Новоселиця',
  'UA2108009005': 'Петрушів',
  'UA2108009006': 'Підплеша',
  'UA2108009007': 'Пригідь',
  'UA2108009012': 'Широкий Луг',
  'UA2108009008': 'Тарасівка',
  'UA2108009009': 'Терново',
  'UA2108009010': 'Тисалово',
  'UA2108009002': 'Вишоватий',
  'UA2108011002': 'Біла Церква',
  'UA2108011004': 'Добрік',
  'UA2108011003': 'Глибокий Потік',
  'UA2108011005': 'Нижня Апша',
  'UA2108011006': 'Пещера',
  'UA2108011007': 'Подішор',
  'UA2108011008': 'Середнє Водяне',
  'UA2108011001': 'Солотвино',
  'UA2108011009': 'Топчино',
  'UA2108013002': 'Грушово',
  'UA2108013003': 'Крива',
  'UA2108013001': 'Тересва',
  'UA2108015002': 'Лази',
  'UA2108015003': 'Округла',
  'UA2108015004': 'Руське Поле',
  'UA2108015001': 'Тячів',
  'UA2108015005': 'Тячівка',
  'UA2108017002': 'Бобове',
  'UA2108017004': 'Груники',
  'UA2108017005': 'Колодне',
  'UA2108017006': 'Мала Уголька',
  'UA2108017001': 'Угля',
  'UA2108017003': 'Велика Уголька',
  'UA2108019002': 'Лопухів',
  'UA2108019003': 'Німецька Мокра',
  'UA2108019004': 'Руська Мокра',
  'UA2108019001': 'Усть-Чорна',
  'UA2108005004': 'Добрянське',
  'UA2108005005': 'Ракове',
  'UA2108005006': 'Сасово',
  'UA2108005003': 'Вільхівчик',
  'UA2108005001': 'Вільхівці',
  'UA2108005002': 'Вільхівці-Лази',
  'UA2110001001': 'Баранинці',
  'UA2110001002': 'Барвінок',
  'UA2110001005': 'Довге Поле',
  'UA2110001004': 'Глибоке',
  'UA2110001010': 'Холмець',
  'UA2110001006': 'Нижнє Солотвино',
  'UA2110001007': 'Підгорб',
  'UA2110001008': 'Руські Комарівці',
  'UA2110001009': 'Стрипа',
  'UA2110001011': 'Циганівці',
  'UA2110001003': 'Великі Лази',
  'UA2110001012': 'Ярок',
  'UA2110027008': 'Червоне',
  'UA2110027001': 'Чоп',
  'UA2110027002': 'Есень',
  'UA2110027003': 'Петрівка',
  'UA2110027005': 'Соломоново',
  'UA2110027004': 'Соловка',
  'UA2110027006': 'Тисаашвань',
  'UA2110027007': 'Тисауйфалу',
  'UA2110007002': 'Буківцьово',
  'UA2110007009': 'Чорноголова',
  'UA2110007001': 'Дубриничі',
  'UA2110007004': 'Малий Березний',
  'UA2110007005': 'Мирча',
  'UA2110007006': 'Новоселиця',
  'UA2110007007': 'Пастілки',
  'UA2110007008': 'Смереково',
  'UA2110007003': 'Завосина',
  'UA2110025002': 'Ботфалва',
  'UA2110025001': 'Холмок',
  'UA2110025003': 'Кінчеш',
  'UA2110025004': 'Концово',
  'UA2110025005': 'Коритняни',
  'UA2110025006': 'Минай',
  'UA2110025007': 'Розівка',
  'UA2110025010': 'Шишлівці',
  'UA2110025008': 'Сторожниця',
  'UA2110025009': 'Тарнівці',
  'UA2110009003': 'Домашин',
  'UA2110009001': 'Кострина',
  'UA2110009004': 'Костринська Розтока',
  'UA2110009005': 'Люта',
  'UA2110009006': 'Сіль',
  'UA2110009002': 'Вишка',
  'UA2110011002': 'Гута',
  'UA2110011003': 'Кам\'яниця',
  'UA2110011004': 'Невицьке',
  'UA2110011001': 'Оноківці',
  'UA2110011005': 'Оріховиця',
  'UA2110013001': 'Перечин',
  'UA2110013004': 'Сімер',
  'UA2110013005': 'Сімерки',
  'UA2110013002': 'Ворочово',
  'UA2110013003': 'Зарічово',
  'UA2110015002': 'Андріївка',
  'UA2110015003': 'Анталовці',
  'UA2110015014': 'Чабанівка',
  'UA2110015015': 'Чертеж',
  'UA2110015007': 'Дубрівка',
  'UA2110015006': 'Гайдош',
  'UA2110015008': 'Ірлява',
  'UA2110015013': 'Худльово',
  'UA2110015009': 'Кибляри',
  'UA2110015011': 'Ляхівці',
  'UA2110015010': 'Лінці',
  'UA2110015012': 'Пацканьово',
  'UA2110015001': 'Середнє',
  'UA2110015004': 'Верхня Солотвина',
  'UA2110015005': 'Вовкове',
  'UA2110019002': 'Батфа',
  'UA2110019012': 'Часлівці',
  'UA2110019004': 'Галоч',
  'UA2110019005': 'Малі Геївці',
  'UA2110019006': 'Малі Селменці',
  'UA2110019007': 'Паладь-Комарівці',
  'UA2110019008': 'Палло',
  'UA2110019009': 'Ратівці',
  'UA2110019010': 'Руські Геївці',
  'UA2110019001': 'Сюрте',
  'UA2110019011': 'Тийглаш',
  'UA2110019003': 'Великі Геївці',
  'UA2110017004': 'Гусний',
  'UA2110017007': 'Лубня',
  'UA2110017008': 'Луг',
  'UA2110017001': 'Ставне',
  'UA2110017009': 'Стужиця',
  'UA2110017010': 'Сухий',
  'UA2110017011': 'Тихий',
  'UA2110017012': 'Ужок',
  'UA2110017002': 'Верховина-Бистра',
  'UA2110017003': 'Волосянка',
  'UA2110017006': 'Загорб',
  'UA2110017005': 'Жорнава',
  'UA2110021005': 'Лікіцари',
  'UA2110021006': 'Лумшори',
  'UA2110021004': 'Липовець',
  'UA2110021007': 'Маюрки',
  'UA2110021008': 'Мокра',
  'UA2110021009': 'Полянська Гута',
  'UA2110021010': 'Порошково',
  'UA2110021011': 'Раково',
  'UA2110021012': 'Свалявка',
  'UA2110021001': 'Тур\'ї Ремети',
  'UA2110021015': 'Тур\'я Пасіка',
  'UA2110021016': 'Тур\'я Поляна',
  'UA2110021017': 'Тур\'я-Бистра',
  'UA2110021014': 'Турички',
  'UA2110021013': 'Туриця',
  'UA2110021002': 'Вільшинки',
  'UA2110021003': 'Завбуч',
  'UA2110023001': 'Ужгород',
  'UA2110003002': 'Бегендяцька Пастіль',
  'UA2110003004': 'Княгиня',
  'UA2110003005': 'Костева Пастіль',
  'UA2110003006': 'Розтоцька Пастіль',
  'UA2110003007': 'Руський Мочар',
  'UA2110003008': 'Стричава',
  'UA2110003001': 'Великий Березний',
  'UA2110003003': 'Забрідь',
  'UA2110005005': 'Чомонин',
  'UA2110005002': 'Демечі',
  'UA2110005003': 'Мала Добронь',
  'UA2110005004': 'Тисаагтелек',
  'UA2110005001': 'Велика Добронь',
  'UA2302003001': 'Андріївка',
  'UA2302003002': 'Дахно',
  'UA2302003003': 'Дмитрівка',
  'UA2302003004': 'Долинське',
  'UA2302003005': 'Іванівка',
  'UA2302003006': 'Коза',
  'UA2302003007': 'Кримка',
  'UA2302003008': 'Новосільське',
  'UA2302003009': 'Оленівка',
  'UA2302003010': 'Сахно',
  'UA2302003013': 'Шевченка',
  'UA2302003014': 'Шевченкове',
  'UA2302003011': 'Софіївка',
  'UA2302003012': 'Успенівка',
  'UA2302001001': 'Андрівка',
  'UA2302001002': 'Новотроїцьке',
  'UA2302001003': 'Полоузівка',
  'UA2302001004': 'Трояни',
  'UA2302005002': 'Азовське',
  'UA2302005001': 'Бердянськ',
  'UA2302005003': 'Нововасилівка',
  'UA2302005004': 'Роза',
  'UA2302005005': 'Шовкове',
  'UA2302007001': 'Берестове',
  'UA2302007003': 'Довбине',
  'UA2302007002': 'Глодове',
  'UA2302007004': 'Калайтанівка',
  'UA2302007005': 'Малинівка',
  'UA2302007006': 'Миколаївка',
  'UA2302007007': 'Новоіванівка',
  'UA2302007008': 'Новосолдатське',
  'UA2302007009': 'Радивонівка',
  'UA2302007010': 'Сачки',
  'UA2302007011': 'Троїцьке',
  'UA2302015002': 'Балашівка',
  'UA2302015003': 'Благодатне',
  'UA2302015004': 'Богданівка',
  'UA2302015005': 'Бойове',
  'UA2302015001': 'Чернігівка',
  'UA2302015036': 'Чернігово-Токмачанськ',
  'UA2302015008': 'Довге',
  'UA2302015012': 'Ільїне',
  'UA2302015013': 'Калинівка',
  'UA2302015014': 'Кам\'янка',
  'UA2302015035': 'Хмельницьке',
  'UA2302015016': 'Котлярівка',
  'UA2302015040': 'Красне',
  'UA2302015017': 'Крижчене',
  'UA2302015015': 'Квіткове',
  'UA2302015018': 'Ланкове',
  'UA2302015019': 'Могиляни',
  'UA2302015020': 'Мокрий Став',
  'UA2302015022': 'Новоказанкувате',
  'UA2302015023': 'Новомихайлівка',
  'UA2302015024': 'Новополтавка',
  'UA2302015021': 'Нижній Токмак',
  'UA2302015025': 'Обіточне',
  'UA2302015026': 'Олександрівка',
  'UA2302015027': 'Петропавлівка',
  'UA2302015028': 'Пірчине',
  'UA2302015029': 'Просторе',
  'UA2302015030': 'Розівка',
  'UA2302015031': 'Салтичія',
  'UA2302015037': 'Широкий Яр',
  'UA2302015032': 'Степове',
  'UA2302015033': 'Стульневе',
  'UA2302015041': 'Стульневе',
  'UA2302015034': 'Тарасівка',
  'UA2302015038': 'Верхній Токмак Другий',
  'UA2302015039': 'Верхній Токмак Перший',
  'UA2302015006': 'Верхній Токмак',
  'UA2302015007': 'Владівка',
  'UA2302015009': 'Замістя',
  'UA2302015010': 'Зелений Яр',
  'UA2302015011': 'Зоря',
  'UA2302009002': 'Гюнівка',
  'UA2302009006': 'Нельгівка',
  'UA2302009011': 'Нельгівка',
  'UA2302009007': 'Підгірне',
  'UA2302009008': 'Радолівка',
  'UA2302009001': 'Софіївка',
  'UA2302009004': 'Єлисеївка',
  'UA2302009003': 'Єлизаветівка',
  'UA2302009010': 'Єлизаветівка',
  'UA2302009009': 'Юр\'ївка',
  'UA2302009005': 'Зеленівка',
  'UA2302011007': 'Бердянське',
  'UA2302011006': 'Червоне Поле',
  'UA2302011002': 'Деревецьке',
  'UA2302011003': 'Куликівське',
  'UA2302011004': 'Новопетрівка',
  'UA2302011001': 'Осипенко',
  'UA2302011005': 'Старопетрівка',
  'UA2302013002': 'Азов',
  'UA2302013003': 'Банівка',
  'UA2302013004': 'Борисівка',
  'UA2302013006': 'Інзівка',
  'UA2302013007': 'Калинівка',
  'UA2302013008': 'Комишуватка',
  'UA2302013009': 'Лозанівка',
  'UA2302013010': 'Лозуватка',
  'UA2302013011': 'Мануйлівка',
  'UA2302013012': 'Маринівка',
  'UA2302013019': 'Набережне',
  'UA2302013013': 'Новоолексіївка',
  'UA2302013014': 'Новопавлівка',
  'UA2302013015': 'Орлівка',
  'UA2302013016': 'Петрівка',
  'UA2302013020': 'Подспор\'є',
  'UA2302013017': 'Преслав',
  'UA2302013001': 'Приморськ',
  'UA2302013018': 'Райнівка',
  'UA2302013005': 'Вячеславка',
  'UA2308029001': 'Чкалове',
  'UA2308029003': 'Добровольчеське',
  'UA2308029002': 'Гоголівка',
  'UA2308029006': 'Калинівка',
  'UA2308029007': 'Корніївка',
  'UA2308029008': 'Красавич',
  'UA2308029009': 'Мусіївка',
  'UA2308029010': 'Новоукраїнка',
  'UA2308029004': 'Зелений Гай',
  'UA2308029005': 'Зелений Луг',
  'UA2308005001': 'Костянтинівка',
  'UA2308005002': 'Вознесенка',
  'UA2308003002': 'Атманай',
  'UA2308003004': 'Косих',
  'UA2308003001': 'Кирилівка',
  'UA2308003005': 'Лиманське',
  'UA2308003006': 'Нове',
  'UA2308003007': 'Охрімівка',
  'UA2308003008': 'Солоне',
  'UA2308003003': 'Вовче',
  'UA2308007001': 'Мелітополь',
  'UA2308009002': 'Арабка',
  'UA2308009003': 'Астраханка',
  'UA2308009004': 'Борисівка',
  'UA2308009001': 'Мирне',
  'UA2308009005': 'Новопилипівка',
  'UA2308009006': 'Оленівка',
  'UA2308009009': 'Соснівка',
  'UA2308009007': 'Свободне',
  'UA2308009008': 'Тихонівка',
  'UA2308011002': 'Данило-Іванівка',
  'UA2308011003': 'Долинське',
  'UA2308011012': 'Фруктове',
  'UA2308011004': 'Кирпичне',
  'UA2308011005': 'Мордвинівка',
  'UA2308011001': 'Нове',
  'UA2308011006': 'Піщанське',
  'UA2308011007': 'Ромашки',
  'UA2308011011': 'Садове',
  'UA2308011008': 'Тащенак',
  'UA2308011009': 'Удачне',
  'UA2308011010': 'Зелене',
  'UA2308013001': 'Новобогданівка',
  'UA2308013002': 'Першостепанівка',
  'UA2308013003': 'Привільне',
  'UA2308013004': 'Старобогданівка',
  'UA2308013005': 'Троїцьке',
  'UA2308013006': 'Відродження',
  'UA2308017002': 'Білівське',
  'UA2308017003': 'Братолюбівка',
  'UA2308017007': 'Матвіївка',
  'UA2308017008': 'Новоіванівка',
  'UA2308017001': 'Новоуспенівка',
  'UA2308017009': 'Таврія',
  'UA2308017004': 'Веселе',
  'UA2308017005': 'Восход',
  'UA2308017006': 'Запоріжжя',
  'UA2308015002': 'Бесідівка',
  'UA2308015020': 'Домузли',
  'UA2308015019': 'Федорівка',
  'UA2308015004': 'Ганнівка',
  'UA2308015005': 'Ганно-Опанлинка',
  'UA2308015006': 'Громівка',
  'UA2308015007': 'Іванівка',
  'UA2308015008': 'Калинівка',
  'UA2308015009': 'Маківка',
  'UA2308015010': 'Мар\'янівка',
  'UA2308015011': 'Миколаївка',
  'UA2308015012': 'Новомиколаївка',
  'UA2308015013': 'Новоолександрівка',
  'UA2308015014': 'Новоспаське',
  'UA2308015001': 'Нововасилівка',
  'UA2308015015': 'Оріхівка',
  'UA2308015016': 'Південне',
  'UA2308015017': 'Прудентове',
  'UA2308015018': 'Розівка',
  'UA2308015003': 'Воскресенка',
  'UA2308019014': 'Чкалове',
  'UA2308019006': 'Дівнинське',
  'UA2308019007': 'Дунаївка',
  'UA2308019004': 'Георгіївка',
  'UA2308019005': 'Гірсівка',
  'UA2308019008': 'Ігорівка',
  'UA2308019009': 'Миронівка',
  'UA2308019010': 'Надеждине',
  'UA2308019011': 'Нечкине',
  'UA2308019012': 'Новокостянтинівка',
  'UA2308019001': 'Олександрівка',
  'UA2308019013': 'Степанівка Перша',
  'UA2308019002': 'Вікторівка',
  'UA2308019003': 'Волна',
  'UA2308021002': 'Братське',
  'UA2308021004': 'Мар\'янівка',
  'UA2308021006': 'Молодіжне',
  'UA2308021005': 'Миколаївка',
  'UA2308021007': 'Новомиколаївка',
  'UA2308021008': 'Олександрівка',
  'UA2308021009': 'Підгірне',
  'UA2308021001': 'Плодородне',
  'UA2308021010': 'Показне',
  'UA2308021011': 'Радісне',
  'UA2308021012': 'Солодке',
  'UA2308021003': 'Зразкове',
  'UA2308023002': 'Бабанівка',
  'UA2308023003': 'Білорічанське',
  'UA2308023004': 'Богданівка',
  'UA2308023005': 'Ботієве',
  'UA2308023009': 'Дмитрівка',
  'UA2308023010': 'Добрівка',
  'UA2308023008': 'Гамівка',
  'UA2308023011': 'Новоіванівка',
  'UA2308023012': 'Новопокровка',
  'UA2308023013': 'Петрівка',
  'UA2308023001': 'Приазовське',
  'UA2308023014': 'Приморський Посад',
  'UA2308023018': 'Шевченка',
  'UA2308023015': 'Степанівка Друга',
  'UA2308023016': 'Строганівка',
  'UA2308023017': 'Таврійське',
  'UA2308023007': 'Володимирівка',
  'UA2308023006': 'Вишневе',
  'UA2308025006': 'Лазурне',
  'UA2308025008': 'Маяк',
  'UA2308025019': 'Малий Утлюг',
  'UA2308025007': 'Мар\'ївка',
  'UA2308025009': 'Новгородківка',
  'UA2308025011': 'Новоякимівка',
  'UA2308025010': 'Новомиколаївка',
  'UA2308025012': 'Обільне',
  'UA2308025013': 'Першотравневе',
  'UA2308025014': 'Південне',
  'UA2308025015': 'Полянівка',
  'UA2308025016': 'Рівне',
  'UA2308025001': 'Семенівка',
  'UA2308025017': 'Східне',
  'UA2308025020': 'Степне',
  'UA2308025018': 'Тамбовка',
  'UA2308025021': 'Трудове',
  'UA2308025002': 'Верховина',
  'UA2308025003': 'Високе',
  'UA2308025004': 'Зеленчук',
  'UA2308025005': 'Золота Долина',
  'UA2308027002': 'Берегове',
  'UA2308027016': 'Федорівка',
  'UA2308027005': 'Кам\'янське',
  'UA2308027006': 'Лугове',
  'UA2308027007': 'Орлове',
  'UA2308027008': 'Північне',
  'UA2308027011': 'Промінь',
  'UA2308027010': 'Прилуківка',
  'UA2308027009': 'Привільне',
  'UA2308027017': 'Широкий Лан',
  'UA2308027013': 'Спаське',
  'UA2308027012': 'Світлодолинське',
  'UA2308027001': 'Терпіння',
  'UA2308027014': 'Травневе',
  'UA2308027015': 'Українське',
  'UA2308027003': 'Відрадне',
  'UA2308027004': 'Волошкове',
  'UA2308027018': 'Ясне',
  'UA2308027019': 'Зарічне',
  'UA2308001002': 'Авангард',
  'UA2308001003': 'Далеке',
  'UA2308001005': 'Мала Михайлівка',
  'UA2308001006': 'Менчикури',
  'UA2308001007': 'Новоолександрівка',
  'UA2308001008': 'Озерне',
  'UA2308001009': 'Піскошине',
  'UA2308001010': 'Широке',
  'UA2308001001': 'Веселе',
  'UA2308001011': 'Ясна Поляна',
  'UA2308001004': 'Єлизаветівка',
  'UA2308031002': 'Андріївка',
  'UA2308031003': 'Богатир',
  'UA2308031028': 'Червоне',
  'UA2308031029': 'Чорноземне',
  'UA2308031011': 'Давидівка',
  'UA2308031012': 'Дружба',
  'UA2308031009': 'Ганнівка',
  'UA2308031010': 'Гвардійське',
  'UA2308031033': 'Максима Горького',
  'UA2308031016': 'Мала Тернівка',
  'UA2308031018': 'Михайлівське',
  'UA2308031017': 'Мирне',
  'UA2308031019': 'Новоданилівка',
  'UA2308031020': 'Олександрівка',
  'UA2308031021': 'Перемога',
  'UA2308031034': 'Переможне',
  'UA2308031022': 'Петрівка',
  'UA2308031023': 'Радивонівка',
  'UA2308031024': 'Розівка',
  'UA2308031031': 'Шелюги',
  'UA2308031030': 'Шевченка',
  'UA2308031025': 'Степове',
  'UA2308031026': 'Таврійське',
  'UA2308031035': 'Трудове',
  'UA2308031027': 'Тимофіївка',
  'UA2308031004': 'Велика Тернівка',
  'UA2308031005': 'Весняне',
  'UA2308031008': 'В\'язівка',
  'UA2308031007': 'Володимирівка',
  'UA2308031006': 'Вовчанське',
  'UA2308031001': 'Якимівка',
  'UA2308031036': 'Якимівське',
  'UA2308031013': 'Єлизаветівка',
  'UA2308031032': 'Юр\'ївка',
  'UA2308031014': 'Зернове',
  'UA2308031015': 'Зірка',
  'UA2310001002': 'Бережне',
  'UA2310001003': 'Березівка',
  'UA2310001004': 'Більманка',
  'UA2310001021': 'Червоне Озеро',
  'UA2310001022': 'Червоноселівка',
  'UA2310001010': 'Дружне',
  'UA2310001011': 'Дубове',
  'UA2310001007': 'Гоголівка',
  'UA2310001008': 'Грузьке',
  'UA2310001009': 'Гусарка',
  'UA2310001001': 'Кам\'янка',
  'UA2310001012': 'Мар\'янівка',
  'UA2310001013': 'Мирське',
  'UA2310001014': 'Новоукраїнка',
  'UA2310001015': 'Очеретувате',
  'UA2310001016': 'Пробудження',
  'UA2310001017': 'Самійлівка',
  'UA2310001019': 'Сміле',
  'UA2310001018': 'Світле',
  'UA2310001020': 'Трудове',
  'UA2310001005': 'Вершина',
  'UA2310001006': 'Веселоіванівське',
  'UA2310029002': 'Балочки',
  'UA2310029003': 'Бурлацьке',
  'UA2310029010': 'Чкалова',
  'UA2310029001': 'Федорівка',
  'UA2310029009': 'Хліборобне',
  'UA2310029005': 'Красноселівка',
  'UA2310029006': 'Межиріч',
  'UA2310029007': 'Новоселівка',
  'UA2310029011': 'Шевченка',
  'UA2310029008': 'Тернове',
  'UA2310029004': 'Золота Поляна',
  'UA2310007030': 'Чарівне',
  'UA2310007031': 'Червоне',
  'UA2310007005': 'Дорожнянка',
  'UA2310007004': 'Гуляйпільське',
  'UA2310007001': 'Гуляйполе',
  'UA2310007010': 'Красногірське',
  'UA2310007011': 'Марфопіль',
  'UA2310007012': 'Мирне',
  'UA2310007013': 'Нове',
  'UA2310007015': 'Новогригорівка',
  'UA2310007016': 'Новоіванівка',
  'UA2310007017': 'Новомиколаївка',
  'UA2310007018': 'Новоуспенівське',
  'UA2310007014': 'Нововасилівське',
  'UA2310007019': 'Обратне',
  'UA2310007020': 'Павлівка',
  'UA2310007021': 'Привільне',
  'UA2310007023': 'Рівнопілля',
  'UA2310007022': 'Рибне',
  'UA2310007025': 'Солодке',
  'UA2310007026': 'Староукраїнка',
  'UA2310007027': 'Степанівка',
  'UA2310007024': 'Святопетрівка',
  'UA2310007028': 'Темирівка',
  'UA2310007029': 'Успенівка',
  'UA2310007003': 'Веселе',
  'UA2310007032': 'Яблукове',
  'UA2310007006': 'Загірне',
  'UA2310007002': 'Залізничне',
  'UA2310007007': 'Затишшя',
  'UA2310007008': 'Зелене',
  'UA2310007009': 'Зелений Гай',
  'UA2310009002': 'Білоцерківка',
  'UA2310009003': 'Благовіщенка',
  'UA2310009009': 'Черешневе',
  'UA2310009001': 'Комиш-Зоря',
  'UA2310009004': 'Ланцеве',
  'UA2310009005': 'Новокам\'янка',
  'UA2310009006': 'Руденка',
  'UA2310009010': 'Шевченківське',
  'UA2310009007': 'Тернове',
  'UA2310009008': 'Труженка',
  'UA2310013002': 'Білогір\'я',
  'UA2310013003': 'Лугівське',
  'UA2310013001': 'Мала Токмачка',
  'UA2310013004': 'Новопокровка',
  'UA2310011003': 'Левадне',
  'UA2310011004': 'Любимівка',
  'UA2310011001': 'Малинівка',
  'UA2310011005': 'Новодарівка',
  'UA2310011007': 'Новоукраїнське',
  'UA2310011006': 'Новозлатопіль',
  'UA2310011009': 'Охотниче',
  'UA2310011008': 'Ольгівське',
  'UA2310011010': 'Полтавка',
  'UA2310011011': 'Приютне',
  'UA2310011012': 'Ремівка',
  'UA2310011013': 'Степове',
  'UA2310011002': 'Вишневе',
  'UA2310015002': 'Балкове',
  'UA2310015003': 'Благодатне',
  'UA2310015008': 'Долина',
  'UA2310015007': 'Грушівка',
  'UA2310015006': 'Гришине',
  'UA2310015010': 'Козолугівка',
  'UA2310015011': 'Курошани',
  'UA2310015012': 'Лагідне',
  'UA2310015013': 'Левадне',
  'UA2310015014': 'Любимівка',
  'UA2310015015': 'Могутнє',
  'UA2310015001': 'Молочанськ',
  'UA2310015016': 'Мостове',
  'UA2310015017': 'Новомиколаївка',
  'UA2310015019': 'Розкішне',
  'UA2310015018': 'Рибалівка',
  'UA2310015020': 'Світле',
  'UA2310015021': 'Ударник',
  'UA2310015022': 'Українка',
  'UA2310015004': 'Веселе',
  'UA2310015005': 'Виноградне',
  'UA2310015009': 'Запоріжжя',
  'UA2310015023': 'Зоряне',
  'UA2310017002': 'Копані',
  'UA2310017003': 'Мирне',
  'UA2310017004': 'Нестерянка',
  'UA2310017005': 'Новоандріївка',
  'UA2310017006': 'Новоданилівка',
  'UA2310017007': 'Новопавлівка',
  'UA2310017001': 'Оріхів',
  'UA2310017008': 'Щербаки',
  'UA2310019002': 'Андріївське',
  'UA2310019003': 'Багате',
  'UA2310019004': 'Басань',
  'UA2310019022': 'Чумацьке',
  'UA2310019007': 'Дмитрівське',
  'UA2310019006': 'Григорівка',
  'UA2310019009': 'Інженерне',
  'UA2310019008': 'Івана Франка',
  'UA2310019010': 'Костянтинівка',
  'UA2310019011': 'Нова Дача',
  'UA2310019013': 'Новофедорівка',
  'UA2310019012': 'Новокарлівка',
  'UA2310019014': 'Ожерельне',
  'UA2310019015': 'Павлівське',
  'UA2310019001': 'Пологи',
  'UA2310019016': 'Пологи',
  'UA2310019017': 'Решетилівське',
  'UA2310019018': 'Романівське',
  'UA2310019019': 'Семенівка',
  'UA2310019023': 'Шевченкове',
  'UA2310019020': 'Тарасівка',
  'UA2310019021': 'Українське',
  'UA2310019005': 'Вербове',
  'UA2310021013': 'Червона Криниця',
  'UA2310021014': 'Червоний Яр',
  'UA2310021006': 'Микільське',
  'UA2310021007': 'Новоселівка',
  'UA2310021008': 'Новосолошине',
  'UA2310021009': 'Обще',
  'UA2310021010': 'Омельник',
  'UA2310021001': 'Преображенка',
  'UA2310021015': 'Широке',
  'UA2310021011': 'Свобода',
  'UA2310021012': 'Тимошівка',
  'UA2310021002': 'Василівське',
  'UA2310021003': 'Васинівка',
  'UA2310021004': 'Вільнянка',
  'UA2310021005': 'Єгорівка',
  'UA2310023002': 'Антонівка',
  'UA2310023027': 'Азов',
  'UA2310023003': 'Багатівка',
  'UA2310023004': 'Біловеж',
  'UA2310023026': 'Форойс',
  'UA2310023012': 'Іванівка',
  'UA2310023013': 'Кобильне',
  'UA2310023014': 'Кузнецівка',
  'UA2310023016': 'Луганське',
  'UA2310023015': 'Листвянка',
  'UA2310023017': 'Маринопіль',
  'UA2310023028': 'Мирне',
  'UA2310023018': 'Надійне',
  'UA2310023019': 'Новгород',
  'UA2310023020': 'Новодворівка',
  'UA2310023022': 'Новомлинівка',
  'UA2310023021': 'Новозлатопіль',
  'UA2310023023': 'Першотравневе',
  'UA2310023001': 'Розівка',
  'UA2310023025': 'Солодководне',
  'UA2310023024': 'Святотроїцьке',
  'UA2310023005': 'Верхівка',
  'UA2310023007': 'Вільне',
  'UA2310023006': 'Вишнювате',
  'UA2310023008': 'Запорізьке',
  'UA2310023009': 'Зеленопіль',
  'UA2310023010': 'Зоря',
  'UA2310023011': 'Зоряне',
  'UA2310025004': 'Діброва',
  'UA2310025007': 'Олексіївка',
  'UA2310025001': 'Смирнове',
  'UA2310025008': 'Титове',
  'UA2310025002': 'Верхньодрагунське',
  'UA2310025003': 'Вершина Друга',
  'UA2310025005': 'Зелений Гай',
  'UA2310025006': 'Зразкове',
  'UA2310027024': 'Червоногірка',
  'UA2310027025': 'Чистопілля',
  'UA2310027022': 'Фабричне',
  'UA2310027004': 'Ільченкове',
  'UA2310027003': 'Іванівка',
  'UA2310027023': 'Харкове',
  'UA2310027005': 'Кутузівка',
  'UA2310027006': 'Лугівка',
  'UA2310027007': 'Мирне',
  'UA2310027008': 'Нове',
  'UA2310027009': 'Новопрокопівка',
  'UA2310027011': 'Очеретувате',
  'UA2310027010': 'Остриківка',
  'UA2310027012': 'Покровське',
  'UA2310027013': 'Пшеничне',
  'UA2310027014': 'Рівне',
  'UA2310027015': 'Роботине',
  'UA2310027016': 'Садове',
  'UA2310027026': 'Шевченкове',
  'UA2310027017': 'Скелювате',
  'UA2310027018': 'Снігурівка',
  'UA2310027019': 'Солодка Балка',
  'UA2310027001': 'Токмак',
  'UA2310027020': 'Трудове',
  'UA2310027021': 'Урожайне',
  'UA2310027002': 'Заможне',
  'UA2310005002': 'Кінські Роздори',
  'UA2310005003': 'Лозове',
  'UA2310005004': 'Магедове',
  'UA2310005001': 'Воскресенка',
  'UA2310003005': 'Добропілля',
  'UA2310003006': 'Долинка',
  'UA2310003004': 'Гірке',
  'UA2310003007': 'Копані',
  'UA2310003008': 'Криничне',
  'UA2310003009': 'Нове Запоріжжя',
  'UA2310003010': 'Оленокостянтинівка',
  'UA2310003011': 'Прилуки',
  'UA2310003012': 'Рівне',
  'UA2310003013': 'Цвіткове',
  'UA2310003002': 'Варварівка',
  'UA2310003003': 'Верхня Терса',
  'UA2310003001': 'Воздвижівка',
  'UA2304001001': 'Благовіщенка',
  'UA2304001002': 'Гуртове',
  'UA2304001003': 'Іванівка',
  'UA2304001004': 'Новодніпровка',
  'UA2304001005': 'Подове',
  'UA2304001007': 'Шляхове',
  'UA2304001006': 'Цвіткове',
  'UA2304009001': 'Дніпрорудне',
  'UA2304009003': 'Маячка',
  'UA2304009002': 'Златопіль',
  'UA2304011001': 'Енергодар',
  'UA2304013001': 'Кам\'янка-Дніпровська',
  'UA2304013003': 'Новоолексіївка',
  'UA2304013002': 'Велика Знам\'янка',
  'UA2304015002': 'Балки',
  'UA2304015001': 'Мала Білозерка',
  'UA2304015004': 'Новобілозерка',
  'UA2304015005': 'Орлянське',
  'UA2304015006': 'Тополине',
  'UA2304015007': 'Улянівка',
  'UA2304015003': 'Відножине',
  'UA2304015008': 'Ясна Поляна',
  'UA2304017003': 'Бурчак',
  'UA2304017001': 'Михайлівка',
  'UA2304017005': 'Нововолодимирівка',
  'UA2304017006': 'Першотравневе',
  'UA2304017007': 'Петрівка',
  'UA2304017002': 'Пришиб',
  'UA2304017008': 'Розівка',
  'UA2304017009': 'Слов\'янка',
  'UA2304017010': 'Смиренівка',
  'UA2304017011': 'Тарсалак',
  'UA2304017012': 'Тимошівка',
  'UA2304017004': 'Вовківка',
  'UA2304019002': 'Абрикосівка',
  'UA2304019003': 'Барвінівка',
  'UA2304019031': 'Чорноземне',
  'UA2304019010': 'Дніпровка',
  'UA2304019013': 'Кавунівка',
  'UA2304019014': 'Кохане',
  'UA2304019016': 'Любимівка',
  'UA2304019015': 'Лиманівка',
  'UA2304019017': 'Нове Поле',
  'UA2304019018': 'Новогорівка',
  'UA2304019019': 'Новолюбимівка',
  'UA2304019020': 'Переможне',
  'UA2304019021': 'Рівне',
  'UA2304019001': 'Роздол',
  'UA2304019022': 'Садове',
  'UA2304019032': 'Шевченка',
  'UA2304019023': 'Солов\'ївка',
  'UA2304019024': 'Степове',
  'UA2304019025': 'Суворе',
  'UA2304019026': 'Таврія',
  'UA2304019027': 'Тракторне',
  'UA2304019029': 'Трудолюбимівка',
  'UA2304019028': 'Трудовик',
  'UA2304019030': 'Українка',
  'UA2304019008': 'Водне',
  'UA2304019009': 'Володимирівка',
  'UA2304019004': 'Виноградівка',
  'UA2304019006': 'Вишневе',
  'UA2304019007': 'Вишнівка',
  'UA2304019005': 'Високе',
  'UA2304019011': 'Завітне',
  'UA2304019012': 'Зелений Гай',
  'UA2304021003': 'Лобкове',
  'UA2304021004': 'Лук\'янівське',
  'UA2304021005': 'Малі Щербаки',
  'UA2304021006': 'Павлівка',
  'UA2304021008': 'П\'ятихатки',
  'UA2304021007': 'Приморське',
  'UA2304021001': 'Степногірськ',
  'UA2304021009': 'Степове',
  'UA2304021002': 'Жереб\'янки',
  'UA2304003005': 'Долинка',
  'UA2304003003': 'Гладке',
  'UA2304003004': 'Грозове',
  'UA2304003007': 'Кам\'янське',
  'UA2304003008': 'Коновалова',
  'UA2304003009': 'Лісне',
  'UA2304003010': 'Лугове',
  'UA2304003011': 'Переможне',
  'UA2304003012': 'Першотравневе',
  'UA2304003013': 'Підгірне',
  'UA2304003014': 'Плавні',
  'UA2304003017': 'Шевченка',
  'UA2304003018': 'Широке',
  'UA2304003015': 'Скельки',
  'UA2304003016': 'Тернувате',
  'UA2304003001': 'Василівка',
  'UA2304003002': 'Верхня Криниця',
  'UA2304003006': 'Зелений Гай',
  'UA2304005002': 'Гюнівка',
  'UA2304005004': 'Качкарівка',
  'UA2304005005': 'Новопетрівка',
  'UA2304005001': 'Велика Білозерка',
  'UA2304005003': 'Зелена Балка',
  'UA2304007002': 'Дніпровка',
  'UA2304007003': 'Мічуріна',
  'UA2304007005': 'Новоукраїнка',
  'UA2304007004': 'Нововодяне',
  'UA2304007006': 'Примірне',
  'UA2304007007': 'Степове',
  'UA2304007001': 'Водяне',
  'UA2304007008': 'Заповітне',
  'UA2306001002': 'Біленьке Перше',
  'UA2306001001': 'Біленьке',
  'UA2306001009': 'Червонодніпровка',
  'UA2306001003': 'Канівське',
  'UA2306001004': 'Лисогірка',
  'UA2306001005': 'Мар\'ївка',
  'UA2306001006': 'Новосергіївка',
  'UA2306001007': 'Смоляне',
  'UA2306001008': 'Уділенське',
  'UA2306005002': 'Бабурка',
  'UA2306005001': 'Долинське',
  'UA2306005009': 'Канцерівка',
  'UA2306005007': 'Хортиця',
  'UA2306005004': 'Нове Запоріжжя',
  'UA2306005005': 'Новослобідка',
  'UA2306005003': 'Нижня Хортиця',
  'UA2306005006': 'Розумівка',
  'UA2306005008': 'Високогірне',
  'UA2306009002': 'Блакитне',
  'UA2306009006': 'Дружне',
  'UA2306009007': 'Дудникове',
  'UA2306009005': 'Григорівське',
  'UA2306009028': 'Калинівка',
  'UA2306009001': 'Комишуваха',
  'UA2306009011': 'Кущове',
  'UA2306009029': 'Кирпотине',
  'UA2306009012': 'Магдалинівка',
  'UA2306009013': 'Новобойківське',
  'UA2306009018': 'Новояковлівка',
  'UA2306009014': 'Новоіванівка',
  'UA2306009015': 'Новомихайлівка',
  'UA2306009016': 'Новорозівка',
  'UA2306009030': 'Новотавричеське',
  'UA2306009017': 'Новотроїцьке',
  'UA2306009019': 'Одарівка',
  'UA2306009020': 'Оленівка',
  'UA2306009025': 'Щасливе',
  'UA2306009021': 'Славне',
  'UA2306009022': 'Тарасівка',
  'UA2306009023': 'Трудолюбівка',
  'UA2306009024': 'Трудооленівка',
  'UA2306009003': 'Веселе',
  'UA2306009004': 'Вільне',
  'UA2306009026': 'Ясна Поляна',
  'UA2306009010': 'Запасне',
  'UA2306009027': 'Зарічне',
  'UA2306009008': 'Жовта Круча',
  'UA2306009009': 'Жовтеньке',
  'UA2306011002': 'Балабине',
  'UA2306011001': 'Кушугум',
  'UA2306011003': 'Малокатеринівка',
  'UA2306013003': 'Бекарівка',
  'UA2306013004': 'Дружелюбівка',
  'UA2306013012': 'Гасанівка',
  'UA2306013002': 'Кам\'яне',
  'UA2306013005': 'Купріянівка',
  'UA2306013006': 'Мала Купріянівка',
  'UA2306013001': 'Матвіївка',
  'UA2306013007': 'Новоіванівське',
  'UA2306013008': 'Новософіївка',
  'UA2306013009': 'Троянди',
  'UA2306013010': 'Українка',
  'UA2306013011': 'Яковлеве',
  'UA2306015002': 'Андріївка',
  'UA2306015003': 'Богатирівка',
  'UA2306015009': 'Георгіївське',
  'UA2306015011': 'Криничне',
  'UA2306015012': 'Люцерна',
  'UA2306015001': 'Михайлівка',
  'UA2306015013': 'Нагірне',
  'UA2306015014': 'Сергіївка',
  'UA2306015015': 'Соколівка',
  'UA2306015004': 'Василівське',
  'UA2306015005': 'Вільноандріївка',
  'UA2306015006': 'Вільногрушівське',
  'UA2306015007': 'Вільнокур\'янівське',
  'UA2306015008': 'Вільноуланівське',
  'UA2306015010': 'Запорізьке',
  'UA2306017002': 'Антонівка',
  'UA2306017003': 'Благовіщенське',
  'UA2306017004': 'Богданівка',
  'UA2306017008': 'Геленджик',
  'UA2306017011': 'Колос',
  'UA2306017010': 'Козаче',
  'UA2306017012': 'Крутий Яр',
  'UA2306017013': 'Максимівка',
  'UA2306017016': 'Московка',
  'UA2306017001': 'Михайло-Лукашеве',
  'UA2306017014': 'Микільське',
  'UA2306017015': 'Миролюбівка',
  'UA2306017020': 'Новофедорівка',
  'UA2306017019': 'Новомихайлівське',
  'UA2306017018': 'Новомиргородка',
  'UA2306017017': 'Нововасилівське',
  'UA2306017021': 'Привільне',
  'UA2306017022': 'Райське',
  'UA2306017026': 'Широке',
  'UA2306017023': 'Терсянка',
  'UA2306017024': 'Трудолюбівка',
  'UA2306017025': 'Укромне',
  'UA2306017005': 'Васильківське',
  'UA2306017006': 'Веселівське',
  'UA2306017007': 'Володимирівка',
  'UA2306017009': 'Зелений Гай',
  'UA2306019002': 'Берестове',
  'UA2306019003': 'Благодатне',
  'UA2306019004': 'Богданівка',
  'UA2306019005': 'Богунівка',
  'UA2306019013': 'Дубовий Гай',
  'UA2306019014': 'Дудникове',
  'UA2306019010': 'Голубкове',
  'UA2306019011': 'Горлицьке',
  'UA2306019012': 'Граничне',
  'UA2306019018': 'Іванівське',
  'UA2306019019': 'Кам\'янка',
  'UA2306019020': 'Кам\'янувате',
  'UA2306019021': 'Каштанівка',
  'UA2306019023': 'Кринівка',
  'UA2306019022': 'Київське',
  'UA2306019024': 'Листівка',
  'UA2306019025': 'Мар\'янівка',
  'UA2306019028': 'Михайлівське',
  'UA2306019027': 'Миколай-Поле',
  'UA2306019026': 'Миколаївка Друга',
  'UA2306019029': 'Ніженка',
  'UA2306019030': 'Нове Поле',
  'UA2306019033': 'Новогригорівка',
  'UA2306019034': 'Новоіванківка',
  'UA2306019035': 'Новокасянівка',
  'UA2306019001': 'Новомиколаївка',
  'UA2306019036': 'Новосолоне',
  'UA2306019037': 'Новоукраїнка',
  'UA2306019031': 'Нововікторівка',
  'UA2306019032': 'Нововолодимирівка',
  'UA2306019038': 'Олексіївка',
  'UA2306019039': 'Островське',
  'UA2306019040': 'Петропавлівка',
  'UA2306019041': 'Петропавлівське',
  'UA2306019042': 'Підгірне',
  'UA2306019044': 'Родинське',
  'UA2306019045': 'Розівка',
  'UA2306019043': 'Рибальське',
  'UA2306019046': 'Садове',
  'UA2306019047': 'Сергіївка',
  'UA2306019053': 'Шевченківське',
  'UA2306019049': 'Софіївка',
  'UA2306019048': 'Сорочине',
  'UA2306019050': 'Сторчове',
  'UA2306019051': 'Тернівка',
  'UA2306019052': 'Терсянка',
  'UA2306019054': 'Трудове',
  'UA2306019006': 'Веселий Гай',
  'UA2306019007': 'Вікторівка',
  'UA2306019008': 'Вільне',
  'UA2306019009': 'Воскресенка',
  'UA2306019015': 'Заливне',
  'UA2306019016': 'Зелена Діброва',
  'UA2306019017': 'Зелене',
  'UA2306021003': 'Григорівка',
  'UA2306021001': 'Новоолександрівка',
  'UA2306021005': 'Новооленівка',
  'UA2306021007': 'Річне',
  'UA2306021002': 'Веселянка',
  'UA2306021006': 'Юльївка',
  'UA2306021004': 'Запорожець',
  'UA2306023002': 'Берестове',
  'UA2306023003': 'Біляївка',
  'UA2306023010': 'Любомирівка',
  'UA2306023012': 'Новоселівка',
  'UA2306023013': 'Новоукраїнка',
  'UA2306023011': 'Нововасилівка',
  'UA2306023001': 'Павлівське',
  'UA2306023014': 'Першозванівка',
  'UA2306023015': 'Поди',
  'UA2306023016': 'Резедівка',
  'UA2306023017': 'Роздолля',
  'UA2306023018': 'Семененкове',
  'UA2306023021': 'Шевченкове',
  'UA2306023019': 'Солоне',
  'UA2306023020': 'Спасівка',
  'UA2306023004': 'Василівка',
  'UA2306023006': 'Вівчарне',
  'UA2306023005': 'Вишневе',
  'UA2306023007': 'Задорожнє',
  'UA2306023008': 'Зелене',
  'UA2306023009': 'Значкове',
  'UA2306025007': 'Дніпровка',
  'UA2306025004': 'Гнаровське',
  'UA2306025005': 'Грушівка',
  'UA2306025006': 'Губенське',
  'UA2306025008': 'Круглик',
  'UA2306025009': 'Орлівське',
  'UA2306025010': 'Перун',
  'UA2306025001': 'Петро-Михайлівка',
  'UA2306025011': 'Петро-Свистунове',
  'UA2306025012': 'Придолинівка',
  'UA2306025016': 'Шевченка',
  'UA2306025013': 'Тарасівка',
  'UA2306025014': 'Тернівка',
  'UA2306025015': 'Улянівка',
  'UA2306025002': 'Великодубове',
  'UA2306025003': 'Вербове',
  'UA2306025017': 'Якимівка',
  'UA2306025018': 'Ясинувате',
  'UA2306033002': 'Августинівка',
  'UA2306033032': 'Червоний Яр',
  'UA2306033007': 'Дніпрельстан',
  'UA2306033008': 'Дніпрові Хвилі',
  'UA2306033009': 'Долинівка',
  'UA2306033031': 'Федорівка',
  'UA2306033006': 'Гурського',
  'UA2306033012': 'Івангород',
  'UA2306033013': 'Крилівське',
  'UA2306033014': 'Лемешинське',
  'UA2306033015': 'Лукашеве',
  'UA2306033016': 'Малишівка',
  'UA2306033018': 'Морозівка',
  'UA2306033017': 'Миколай-Поле',
  'UA2306033019': 'Надія',
  'UA2306033021': 'Новодніпровка',
  'UA2306033022': 'Новопетрівка',
  'UA2306033023': 'Новоселище',
  'UA2306033020': 'Нововознесенка',
  'UA2306033024': 'Петропавлівка',
  'UA2306033025': 'Петропіль',
  'UA2306033028': 'Придніпровське',
  'UA2306033026': 'Привільне',
  'UA2306033027': 'Привітне',
  'UA2306033029': 'Ручаївка',
  'UA2306033001': 'Широке',
  'UA2306033035': 'Сонячне',
  'UA2306033030': 'Світанок',
  'UA2306033003': 'Веселе',
  'UA2306033034': 'Відрадне',
  'UA2306033004': 'Водяне',
  'UA2306033005': 'Володимирівське',
  'UA2306033033': 'Яворницьке',
  'UA2306033010': 'Зеленопілля',
  'UA2306033011': 'Зоряне',
  'UA2306027005': 'Черепівське',
  'UA2306027007': 'Івано-Ганнівка',
  'UA2306027002': 'Лежине',
  'UA2306027003': 'Наталівка',
  'UA2306027004': 'Новостепнянське',
  'UA2306027008': 'Ростуще',
  'UA2306027006': 'Шевченківське',
  'UA2306027001': 'Степне',
  'UA2306029002': 'Любимівка',
  'UA2306029001': 'Таврійське',
  'UA2306029003': 'Юрківка',
  'UA2306031002': 'Барвінівка',
  'UA2306031003': 'Бойкове',
  'UA2306031005': 'Данилівка',
  'UA2306031009': 'Косівцеве',
  'UA2306031010': 'Лісне',
  'UA2306031011': 'Любицьке',
  'UA2306031012': 'Мирівка',
  'UA2306031013': 'Придорожнє',
  'UA2306031014': 'Різдвянка',
  'UA2306031015': 'Самійлівка',
  'UA2306031016': 'Світла Долина',
  'UA2306031001': 'Тернувате',
  'UA2306031004': 'Василькове',
  'UA2306031006': 'Зарічне',
  'UA2306031007': 'Зірниця',
  'UA2306031008': 'Зорівка',
  'UA2306003002': 'Аграфенівка',
  'UA2306003008': 'Дерезівка',
  'UA2306003006': 'Гарасівка',
  'UA2306003007': 'Грізне',
  'UA2306003009': 'Козаківське',
  'UA2306003010': 'Любимівка',
  'UA2306003011': 'Новогупалівка',
  'UA2306003012': 'Новотроїцьке',
  'UA2306003013': 'Петрівське',
  'UA2306003014': 'Скелювате',
  'UA2306003015': 'Смородине',
  'UA2306003003': 'Веселотернувате',
  'UA2306003005': 'Вільнянка',
  'UA2306003001': 'Вільнянськ',
  'UA2306003004': 'Вишняки',
  'UA2306003016': 'Якимівське',
  'UA2306007001': 'Запоріжжя',
  'UA1802001001': 'Андрушівка',
  'UA1802001002': 'Антопіль',
  'UA1802001003': 'Бровки Другі',
  'UA1802001004': 'Бровки Перші',
  'UA1802001006': 'Гальчин',
  'UA1802001007': 'Гарапівка',
  'UA1802001009': 'Городківка',
  'UA1802001008': 'Городище',
  'UA1802001010': 'Града',
  'UA1802001013': 'Камені',
  'UA1802001014': 'Лебединці',
  'UA1802001015': 'Лісівка',
  'UA1802001016': 'Любимівка',
  'UA1802001017': 'Мала П\'ятигірка',
  'UA1802001018': 'Міньківці',
  'UA1802001019': 'Мостове',
  'UA1802001020': 'Нехворощ',
  'UA1802001021': 'Нова Котельня',
  'UA1802001022': 'Павелки',
  'UA1802001023': 'Тарасівка',
  'UA1802001005': 'Волосів',
  'UA1802001024': 'Ярешки',
  'UA1802001025': 'Яроповичі',
  'UA1802001012': 'Зарубинці',
  'UA1802001011': 'Жерделі',
  'UA1802003001': 'Бердичів',
  'UA1802003002': 'Підгородне',
  'UA1802003003': 'Скраглівка',
  'UA1802017001': 'Червоне',
  'UA1802017003': 'Глинівці',
  'UA1802017005': 'Котівка',
  'UA1802017006': 'Крилівка',
  'UA1802017007': 'Малі Мошківці',
  'UA1802017002': 'Великі Мошківці',
  'UA1802017004': 'Забара',
  'UA1802007002': 'Агатівка',
  'UA1802007005': 'Дубова',
  'UA1802007003': 'Гальчин',
  'UA1802007001': 'Гришківці',
  'UA1802007004': 'Гвіздава',
  'UA1802007017': 'Хмельове',
  'UA1802007016': 'Хмелище',
  'UA1802007007': 'Кукільня',
  'UA1802007009': 'Новий Солотвин',
  'UA1802007008': 'Никонівка',
  'UA1802007010': 'Осикове',
  'UA1802007011': 'Половецьке',
  'UA1802007012': 'Рея',
  'UA1802007013': 'Скаківка',
  'UA1802007015': 'Сьомаки',
  'UA1802007014': 'Старий Солотвин',
  'UA1802007006': 'Журбинці',
  'UA1802009003': 'Безпечна',
  'UA1802009004': 'Бурківці',
  'UA1802009002': 'Іванопіль',
  'UA1802009001': 'Краснопіль',
  'UA1802009006': 'Лихосілка',
  'UA1802009007': 'Медведиха',
  'UA1802009008': 'Молочки',
  'UA1802009009': 'Мотрунки',
  'UA1802009010': 'Носівки',
  'UA1802009011': 'Певна',
  'UA1802009012': 'Польова Слобідка',
  'UA1802009017': 'Поштове',
  'UA1802009013': 'Радісне',
  'UA1802009014': 'Степок',
  'UA1802009015': 'Стетківці',
  'UA1802009016': 'Суслівка',
  'UA1802009005': 'Жеребки',
  'UA1802011002': 'Андріяшівка',
  'UA1802011003': 'Бенедівка',
  'UA1802011005': 'Богданівка',
  'UA1802011006': 'Буряки',
  'UA1802011004': 'Бистрик',
  'UA1802011008': 'Гардишівка',
  'UA1802011010': 'Костянтинівка',
  'UA1802011011': 'Кустин',
  'UA1802011012': 'Лемеші',
  'UA1802011013': 'Лісова Слобідка',
  'UA1802011014': 'Лісове',
  'UA1802011015': 'Любомирка',
  'UA1802011016': 'Маркуші',
  'UA1802011017': 'Мартинівка',
  'UA1802011018': 'Мирне',
  'UA1802011019': 'Обухівка',
  'UA1802011020': 'Озадівка',
  'UA1802011001': 'Райгородок',
  'UA1802011021': 'Романівка',
  'UA1802011007': 'Велика П\'ятигірка',
  'UA1802011009': 'Житинці',
  'UA1802013002': 'Березянка',
  'UA1802013004': 'Білилівка',
  'UA1802013003': 'Бистрик',
  'UA1802013034': 'Чехова',
  'UA1802013033': 'Черемуха',
  'UA1802013011': 'Дерганівка',
  'UA1802013009': 'Голубівка',
  'UA1802013010': 'Городок',
  'UA1802013036': 'Городоцьке',
  'UA1802013017': 'Карабчиїв',
  'UA1802013018': 'Княжики',
  'UA1802013019': 'Кордонівка',
  'UA1802013020': 'Котелянка',
  'UA1802013021': 'Мар\'янівка',
  'UA1802013022': 'Мовчанівка',
  'UA1802013037': 'Мовчанівське',
  'UA1802013023': 'Мусіївка',
  'UA1802013024': 'Немиринці',
  'UA1802013025': 'Огіївка',
  'UA1802013026': 'Плоска',
  'UA1802013027': 'Прибережне',
  'UA1802013028': 'Причепівка',
  'UA1802013029': 'Рогачі',
  'UA1802013001': 'Ружин',
  'UA1802013030': 'Сахни',
  'UA1802013031': 'Топори',
  'UA1802013032': 'Трубіївка',
  'UA1802013005': 'Вербівка',
  'UA1802013006': 'Верхівня',
  'UA1802013008': 'Вільнопілля',
  'UA1802013007': 'Вишневе',
  'UA1802013035': 'Ягнятин',
  'UA1802013016': 'Йосипівка',
  'UA1802013012': 'Заріччя',
  'UA1802013013': 'Зарудинці',
  'UA1802013014': 'Зелене',
  'UA1802013015': 'Зоряне',
  'UA1802015004': 'Дубівка',
  'UA1802015006': 'Іванківці',
  'UA1802015014': 'Хажин',
  'UA1802015008': 'Красівка',
  'UA1802015007': 'Кикишівка',
  'UA1802015009': 'Малі Гадомці',
  'UA1802015010': 'Нова Олександрівка',
  'UA1802015011': 'Садки',
  'UA1802015001': 'Семенівка',
  'UA1802015012': 'Сингаївка',
  'UA1802015013': 'Терехове',
  'UA1802015002': 'Великі Гадомці',
  'UA1802015003': 'Великі Низгірці',
  'UA1802015005': 'Закутинці',
  'UA1802019008': 'Берізки',
  'UA1802019002': 'Демчин',
  'UA1802019003': 'Катеринівка',
  'UA1802019004': 'Малосілка',
  'UA1802019005': 'Мирославка',
  'UA1802019006': 'Райки',
  'UA1802019001': 'Швайківка',
  'UA1802019007': 'Слободище',
  'UA1802005002': 'Бистріївка',
  'UA1802005009': 'Чорнорудка',
  'UA1802005003': 'Крилівка',
  'UA1802005004': 'Лісове',
  'UA1802005005': 'Мала Чернявка',
  'UA1802005006': 'Малі Низгірці',
  'UA1802005007': 'Нова Чорнорудка',
  'UA1802005013': 'Першотравневе',
  'UA1802005008': 'Роставиця',
  'UA1802005010': 'Шпичинці',
  'UA1802005001': 'Вчорайше',
  'UA1802005011': 'Ярославка',
  'UA1802005012': 'Звиняче',
  'UA1806001001': 'Білокоровичі',
  'UA1806001002': 'Бучмани',
  'UA1806001003': 'Нові Білокоровичі',
  'UA1806025002': 'Барвінки',
  'UA1806025003': 'Будницьке',
  'UA1806025026': 'Червоний Лан',
  'UA1806025001': 'Чоповичі',
  'UA1806025005': 'Головки',
  'UA1806025006': 'Гутянське',
  'UA1806025010': 'Кам\'янка',
  'UA1806025012': 'Крушники',
  'UA1806025013': 'Кутище',
  'UA1806025011': 'Квітневе',
  'UA1806025015': 'Лідівка',
  'UA1806025016': 'Лісове',
  'UA1806025014': 'Липляни',
  'UA1806025017': 'Маклаївка',
  'UA1806025018': 'Новобратське',
  'UA1806025019': 'Омелянівка',
  'UA1806025021': 'Пристанційне',
  'UA1806025020': 'Писарівка',
  'UA1806025022': 'Репище',
  'UA1806025027': 'Шевченкове',
  'UA1806025023': 'Скурати',
  'UA1806025024': 'Стримівщина',
  'UA1806025025': 'Тишів',
  'UA1806025004': 'Владівка',
  'UA1806025009': 'Йосипівка',
  'UA1806025007': 'Загребля',
  'UA1806025008': 'Заліски',
  'UA1806003001': 'Гладковичі',
  'UA1806003003': 'Гладковицька Кам\'янка',
  'UA1806003004': 'Гусарівка',
  'UA1806003006': 'Кам\'янівка',
  'UA1806003007': 'Личмани',
  'UA1806003011': 'Магдин',
  'UA1806003002': 'Першотравневе',
  'UA1806003008': 'Радчиці',
  'UA1806003009': 'Сташки',
  'UA1806003010': 'Товкачі',
  'UA1806003005': 'Збраньківці',
  'UA1806005002': 'Березівка',
  'UA1806005003': 'Боровиця',
  'UA1806005006': 'Давидки',
  'UA1806005007': 'Дружбівка',
  'UA1806005001': 'Горщик',
  'UA1806005009': 'Краснопіль',
  'UA1806005010': 'Піски',
  'UA1806005005': 'Видень',
  'UA1806005004': 'Вигів',
  'UA1806005008': 'Жупанівка',
  'UA1806007006': 'Десятини',
  'UA1806007007': 'Добринь',
  'UA1806007003': 'Граби',
  'UA1806007004': 'Губенкове',
  'UA1806007005': 'Гута-Добринь',
  'UA1806007001': 'Іршанськ',
  'UA1806007009': 'Ковалі',
  'UA1806007010': 'Красносілка',
  'UA1806007011': 'Мелені',
  'UA1806007012': 'Полянка',
  'UA1806007014': 'Шершні',
  'UA1806007013': 'Старики',
  'UA1806007002': 'Винарівка',
  'UA1806007008': 'Ємилівка',
  'UA1806009002': 'Барди',
  'UA1806009004': 'Бехи',
  'UA1806009003': 'Берестовець',
  'UA1806009005': 'Болярка',
  'UA1806009006': 'Булахівка',
  'UA1806009012': 'Діброва',
  'UA1806009013': 'Дідковичі',
  'UA1806009014': 'Домолоч',
  'UA1806009010': 'Горбачі',
  'UA1806009011': 'Грозине',
  'UA1806009017': 'Іскорость',
  'UA1806009018': 'Каленське',
  'UA1806009040': 'Ходаки',
  'UA1806009041': 'Холосне',
  'UA1806009042': 'Хотинівка',
  'UA1806009019': 'Клочеве',
  'UA1806009021': 'Корма',
  'UA1806009001': 'Коростень',
  'UA1806009020': 'Кожухівка',
  'UA1806009022': 'Купеч',
  'UA1806009023': 'Майданівка',
  'UA1806009024': 'Мала Зубівщина',
  'UA1806009025': 'Мединівка',
  'UA1806009026': 'Межирічка',
  'UA1806009027': 'Михайлівка',
  'UA1806009028': 'Немирівка',
  'UA1806009030': 'Новаки',
  'UA1806009029': 'Нивки',
  'UA1806009032': 'Обиходівка',
  'UA1806009031': 'Обиходи',
  'UA1806009033': 'Плещівка',
  'UA1806009034': 'Розтяжин',
  'UA1806009035': 'Сарновичі',
  'UA1806009043': 'Шатрище',
  'UA1806009037': 'Соболівка',
  'UA1806009044': 'Сокорики',
  'UA1806009038': 'Стремигород',
  'UA1806009039': 'Субине',
  'UA1806009036': 'Сингаї',
  'UA1806009007': 'Васьковичі',
  'UA1806009008': 'Великий Ліс',
  'UA1806009009': 'Вороневе',
  'UA1806009015': 'Злобичі',
  'UA1806009016': 'Зубівщина',
  'UA1806011003': 'Березовий Груд',
  'UA1806011004': 'Бобричі',
  'UA1806011005': 'Бовсуни',
  'UA1806011006': 'Буда',
  'UA1806011007': 'Будо-Літки',
  'UA1806011049': 'Червона Волока',
  'UA1806011013': 'Діброва',
  'UA1806011012': 'Глухова',
  'UA1806011017': 'Іванівка',
  'UA1806011018': 'Калинівка',
  'UA1806011019': 'Кам\'яна Гірка',
  'UA1806011020': 'Красносілка',
  'UA1806011021': 'Красностав',
  'UA1806011022': 'Кремне',
  'UA1806011023': 'Крупчатка',
  'UA1806011024': 'Леонівка',
  'UA1806011026': 'Літки',
  'UA1806011027': 'Лугинки',
  'UA1806011001': 'Лугини',
  'UA1806011025': 'Липники',
  'UA1806011028': 'Малахівка',
  'UA1806011029': 'Малий Дивлин',
  'UA1806011030': 'Миколаївка',
  'UA1806011002': 'Миролюбів',
  'UA1806011031': 'Нова Рудня',
  'UA1806011032': 'Нові Новаки',
  'UA1806011033': 'Новосілка',
  'UA1806011034': 'Осни',
  'UA1806011035': 'Остапи',
  'UA1806011036': 'Підостапи',
  'UA1806011037': 'Повч',
  'UA1806011038': 'Путиловичі',
  'UA1806011039': 'Радогоща',
  'UA1806011041': 'Рудня-Повчанська',
  'UA1806011040': 'Рудня-Жеревці',
  'UA1806011042': 'Солов\'ї',
  'UA1806011043': 'Станційне',
  'UA1806011044': 'Старі Новаки',
  'UA1806011045': 'Старосілля',
  'UA1806011046': 'Степанівка',
  'UA1806011047': 'Теснівка',
  'UA1806011048': 'Топільня',
  'UA1806011008': 'Великий Дивлин',
  'UA1806011009': 'Великий Ліс',
  'UA1806011010': 'Вербівка',
  'UA1806011011': 'Волошине',
  'UA1806011015': 'Запілля',
  'UA1806011016': 'Зарічка',
  'UA1806011014': 'Жеревці',
  'UA1806013003': 'Баранівка',
  'UA1806013004': 'Березине',
  'UA1806013005': 'Білий Берег',
  'UA1806013008': 'Бучки',
  'UA1806013006': 'Будо-Вороб\'ї',
  'UA1806013007': 'Буки',
  'UA1806013019': 'Діброва',
  'UA1806013020': 'Дружне',
  'UA1806013071': 'Федорівка',
  'UA1806013072': 'Фортунатівка',
  'UA1806013015': 'Гамарня',
  'UA1806013016': 'Горинь',
  'UA1806013002': 'Гранітне',
  'UA1806013017': 'Гуска',
  'UA1806013018': 'Гута-Логанівська',
  'UA1806013026': 'Іванівка',
  'UA1806013027': 'Клітня',
  'UA1806013028': 'Королівка',
  'UA1806013029': 'Косня',
  'UA1806013030': 'Ксаверів',
  'UA1806013031': 'Лісна Колона',
  'UA1806013034': 'Любовичі',
  'UA1806013032': 'Луки',
  'UA1806013033': 'Лумля',
  'UA1806013001': 'Малин',
  'UA1806013035': 'Малинівка',
  'UA1806013036': 'Мар\'ятин',
  'UA1806013037': 'Морозівка',
  'UA1806013038': 'Недашки',
  'UA1806013045': 'Нянівка',
  'UA1806013040': 'Нова Діброва',
  'UA1806013039': 'Нова Гута',
  'UA1806013041': 'Нова Рутвянка',
  'UA1806013042': 'Нове Життя',
  'UA1806013043': 'Нові Вороб\'ї',
  'UA1806013044': 'Новоселиця',
  'UA1806013046': 'Ободівка',
  'UA1806013047': 'Першотравневе',
  'UA1806013050': 'П\'ятидуб',
  'UA1806013049': 'Привітне',
  'UA1806013048': 'Пиріжки',
  'UA1806013051': 'Різня',
  'UA1806013052': 'Рубанка',
  'UA1806013054': 'Рудня-Городищенська',
  'UA1806013055': 'Рудня-Калинівка',
  'UA1806013053': 'Рудня-Вороб\'ївська',
  'UA1806013056': 'Рутвянка',
  'UA1806013057': 'Савлуки',
  'UA1806013073': 'Щербатівка',
  'UA1806013060': 'Слобідка',
  'UA1806013061': 'Соснівка',
  'UA1806013062': 'Стара Гута',
  'UA1806013063': 'Старі Вороб\'ї',
  'UA1806013064': 'Стасева',
  'UA1806013065': 'Студень',
  'UA1806013058': 'Свиридівка',
  'UA1806013059': 'Сичівка',
  'UA1806013066': 'Тарасівка',
  'UA1806013067': 'Тростяниця',
  'UA1806013068': 'Трудолюбівка',
  'UA1806013069': 'Українка',
  'UA1806013070': 'Устинівка',
  'UA1806013014': 'В\'юнище',
  'UA1806013011': 'Візня',
  'UA1806013012': 'Вороб\'ївщина',
  'UA1806013013': 'Ворсівка',
  'UA1806013009': 'Вишів',
  'UA1806013010': 'Вишнянка',
  'UA1806013075': 'Яблунівка',
  'UA1806013076': 'Ялцівка',
  'UA1806013077': 'Ярочище',
  'UA1806013021': 'Єлівка',
  'UA1806013074': 'Юрівка',
  'UA1806013023': 'Забране',
  'UA1806013024': 'Зелений Гай',
  'UA1806013022': 'Жабоч',
  'UA1806013025': 'Зибин',
  'UA1806015002': 'Бабиничі',
  'UA1806015004': 'Батьківщина',
  'UA1806015003': 'Базар',
  'UA1806015005': 'Болотниця',
  'UA1806015006': 'Бродник',
  'UA1806015007': 'Буда-Голубієвичі',
  'UA1806015008': 'Булів',
  'UA1806015064': 'Червоне',
  'UA1806015019': 'Давидки',
  'UA1806015014': 'Ганнівка',
  'UA1806015015': 'Голубієвичі',
  'UA1806015016': 'Грезля',
  'UA1806015017': 'Гута-Ксаверівська',
  'UA1806015018': 'Гуто-Мар\'ятин',
  'UA1806015025': 'Калинівка',
  'UA1806015026': 'Карпилівка',
  'UA1806015063': 'Христинівка',
  'UA1806015027': 'Клочки',
  'UA1806015028': 'Колосівка',
  'UA1806015029': 'Ласки',
  'UA1806015030': 'Латаші',
  'UA1806015034': 'Любарка',
  'UA1806015033': 'Лозниця',
  'UA1806015031': 'Липлянщина',
  'UA1806015032': 'Листвинівка',
  'UA1806015035': 'Межиліска',
  'UA1806015036': 'Мотійки',
  'UA1806015001': 'Народичі',
  'UA1806015037': 'Недашківка',
  'UA1806015041': 'Норинці',
  'UA1806015038': 'Нова Радча',
  'UA1806015039': 'Новий Дорогинь',
  'UA1806015040': 'Ноздрище',
  'UA1806015042': 'Одруби',
  'UA1806015043': 'Оржів',
  'UA1806015044': 'Осика',
  'UA1806015045': 'Радча',
  'UA1806015046': 'Ровба',
  'UA1806015047': 'Розсохівське',
  'UA1806015048': 'Рубежівка',
  'UA1806015049': 'Рудня-Базарська',
  'UA1806015050': 'Рудня-Кам\'янка',
  'UA1806015051': 'Савченки',
  'UA1806015052': 'Селець',
  'UA1806015054': 'Славенщина',
  'UA1806015055': 'Славковиці',
  'UA1806015056': 'Слобода-В\'язівка',
  'UA1806015057': 'Снитище',
  'UA1806015058': 'Стара Радча',
  'UA1806015059': 'Старий Дорогинь',
  'UA1806015060': 'Старий Кужіль',
  'UA1806015061': 'Сухарівка',
  'UA1806015053': 'Сингаї',
  'UA1806015062': 'Тичків',
  'UA1806015009': 'Васьківці',
  'UA1806015010': 'Великі Міньки',
  'UA1806015013': 'В\'язівка',
  'UA1806015012': 'Вільхова',
  'UA1806015011': 'Вила',
  'UA1806015065': 'Яжберень',
  'UA1806015023': 'Закусили',
  'UA1806015024': 'Залісся',
  'UA1806015022': 'Заводне',
  'UA1806015020': 'Жерев',
  'UA1806015021': 'Журавлинка',
  'UA1806019005': 'Андріївка',
  'UA1806019006': 'Артинськ',
  'UA1806019007': 'Бацеве',
  'UA1806019008': 'Болярка',
  'UA1806019009': 'Будки',
  'UA1806019011': 'Держанівка',
  'UA1806019002': 'Діброва',
  'UA1806019003': 'Дружба',
  'UA1806019012': 'Джерело',
  'UA1806019019': 'Калинівка',
  'UA1806019020': 'Кам\'янка',
  'UA1806019052': 'Хмелівка',
  'UA1806019053': 'Хочине',
  'UA1806019023': 'Копище',
  'UA1806019024': 'Корощине',
  'UA1806019022': 'Ковалівка',
  'UA1806019021': 'Кишин',
  'UA1806019025': 'Ліски',
  'UA1806019026': 'Лісове',
  'UA1806019027': 'Лопатичі',
  'UA1806019056': 'Лугове',
  'UA1806019028': 'Майдан',
  'UA1806019029': 'Майдан-Копищенський',
  'UA1806019031': 'Млинок',
  'UA1806019030': 'Михайлівка',
  'UA1806019004': 'Новоозерянка',
  'UA1806019032': 'Обище',
  'UA1806019001': 'Олевськ',
  'UA1806019033': 'Озеряни',
  'UA1806019034': 'Перга',
  'UA1806019036': 'Пояски',
  'UA1806019035': 'Покровське',
  'UA1806019037': 'Радовель',
  'UA1806019038': 'Рудня',
  'UA1806019039': 'Рудня-Бистра',
  'UA1806019044': 'Рудня-Хочинська',
  'UA1806019041': 'Рудня-Озерянська',
  'UA1806019042': 'Рудня-Перганська',
  'UA1806019043': 'Рудня-Радовельська',
  'UA1806019040': 'Рудня-Замисловицька',
  'UA1806019045': 'Сарнівка',
  'UA1806019046': 'Сердюки',
  'UA1806019054': 'Шебедиха',
  'UA1806019057': 'Сновидовичі',
  'UA1806019047': 'Соснівка',
  'UA1806019048': 'Стовпинка',
  'UA1806019049': 'Сущани',
  'UA1806019050': 'Тепениця',
  'UA1806019051': 'Устинівка',
  'UA1806019010': 'Варварівка',
  'UA1806019055': 'Юрове',
  'UA1806019015': 'Забороче',
  'UA1806019016': 'Замисловичі',
  'UA1806019013': 'Жубровичі',
  'UA1806019014': 'Журжевичі',
  'UA1806019017': 'Зольня',
  'UA1806019018': 'Зубковичі',
  'UA1806017003': 'Барвінкове',
  'UA1806017002': 'Базарівка',
  'UA1806017004': 'Бережесть',
  'UA1806017005': 'Білокамінка',
  'UA1806017006': 'Бірківське',
  'UA1806017007': 'Богданівка',
  'UA1806017008': 'Бондарі',
  'UA1806017009': 'Бондарівка',
  'UA1806017087': 'Черепин',
  'UA1806017088': 'Черепинки',
  'UA1806017021': 'Дівошин',
  'UA1806017022': 'Довгиничі',
  'UA1806017023': 'Дубовий Гай',
  'UA1806017024': 'Думинське',
  'UA1806017018': 'Гаєвичі',
  'UA1806017019': 'Гошів',
  'UA1806017020': 'Гуничі',
  'UA1806017029': 'Ігнатпіль',
  'UA1806017030': 'Камінь',
  'UA1806017086': 'Хлупляни',
  'UA1806017032': 'Клинець',
  'UA1806017033': 'Колосівка',
  'UA1806017034': 'Коптівщина',
  'UA1806017035': 'Кораки',
  'UA1806017037': 'Корчівка',
  'UA1806017036': 'Коренівка',
  'UA1806017038': 'Красносілка',
  'UA1806017031': 'Кирдани',
  'UA1806017040': 'Левковичі',
  'UA1806017039': 'Левковицький Млинок',
  'UA1806017041': 'Лукішки',
  'UA1806017044': 'Мала Чернігівка',
  'UA1806017042': 'Мала Фосня',
  'UA1806017043': 'Мала Хайча',
  'UA1806017046': 'Малі Мошки',
  'UA1806017045': 'Малий Кобилин',
  'UA1806017047': 'Мамеч',
  'UA1806017049': 'Млини',
  'UA1806017050': 'Мочульня',
  'UA1806017051': 'Мощаниця',
  'UA1806017048': 'Мишковичі',
  'UA1806017052': 'Нагоряни',
  'UA1806017053': 'Невгоди',
  'UA1806017057': 'Норинськ',
  'UA1806017056': 'Новосілки',
  'UA1806017054': 'Нивки',
  'UA1806017055': 'Нижня Рудня',
  'UA1806017058': 'Оленичі',
  'UA1806017059': 'Острів',
  'UA1806017060': 'Острови',
  'UA1806017001': 'Овруч',
  'UA1806017063': 'Папірня',
  'UA1806017062': 'Павлюківка',
  'UA1806017061': 'Павловичі',
  'UA1806017065': 'Підруддя',
  'UA1806017064': 'Підвелідники',
  'UA1806017066': 'Піщаниця',
  'UA1806017067': 'Покалів',
  'UA1806017068': 'Поліське',
  'UA1806017069': 'Полохачів',
  'UA1806017070': 'Потаповичі',
  'UA1806017072': 'Прилуки',
  'UA1806017071': 'Привар',
  'UA1806017073': 'Раківщина',
  'UA1806017074': 'Рудня',
  'UA1806017075': 'Рудня',
  'UA1806017076': 'Семени',
  'UA1806017077': 'Середня Рудня',
  'UA1806017089': 'Шоломки',
  'UA1806017079': 'Скребеличі',
  'UA1806017080': 'Слобода',
  'UA1806017081': 'Слобода-Новоселицька',
  'UA1806017082': 'Слобода-Шоломківська',
  'UA1806017083': 'Смоляне',
  'UA1806017084': 'Стугівщина',
  'UA1806017078': 'Сирківщина',
  'UA1806017085': 'Теклівка',
  'UA1806017012': 'Велика Чернігівка',
  'UA1806017010': 'Велика Фосня',
  'UA1806017011': 'Велика Хайча',
  'UA1806017014': 'Великі Мошки',
  'UA1806017013': 'Великий Кобилин',
  'UA1806017015': 'Верхня Рудня',
  'UA1806017016': 'Веселівка',
  'UA1806017017': 'Виступовичі',
  'UA1806017090': 'Ясенець',
  'UA1806017091': 'Яцковичі',
  'UA1806017025': 'Заболоть',
  'UA1806017026': 'Заріччя',
  'UA1806017027': 'Заськи',
  'UA1806017028': 'Збраньки',
  'UA1806021002': 'Антоновичі',
  'UA1806021003': 'Бігунь',
  'UA1806021004': 'Білка',
  'UA1806021032': 'Чабан',
  'UA1806021035': 'Черевки',
  'UA1806021033': 'Червонка',
  'UA1806021034': 'Червоносілка',
  'UA1806021009': 'Дуби',
  'UA1806021008': 'Городець',
  'UA1806021011': 'Іллімка',
  'UA1806021014': 'Кошечки',
  'UA1806021012': 'Кованка',
  'UA1806021013': 'Козулі',
  'UA1806021015': 'Красилівка',
  'UA1806021017': 'Лучанки',
  'UA1806021016': 'Листвин',
  'UA1806021018': 'Мацьки',
  'UA1806021019': 'Можари',
  'UA1806021020': 'Нова Рудня',
  'UA1806021021': 'Нові Велідники',
  'UA1806021022': 'Переброди',
  'UA1806021023': 'Побичі',
  'UA1806021024': 'Прибитки',
  'UA1806021025': 'Рокитне',
  'UA1806021026': 'Селезівка',
  'UA1806021001': 'Словечне',
  'UA1806021028': 'Сорокопень',
  'UA1806021029': 'Старі Велідники',
  'UA1806021027': 'Сирниця',
  'UA1806021030': 'Тхорин',
  'UA1806021031': 'Усове',
  'UA1806021005': 'Верпа',
  'UA1806021006': 'Возлякове',
  'UA1806021007': 'Возничі',
  'UA1806021010': 'Задорожок',
  'UA1806023002': 'Березневе',
  'UA1806023003': 'Білка',
  'UA1806023004': 'Білошиці',
  'UA1806023005': 'Бондарівка',
  'UA1806023045': 'Броди',
  'UA1806023009': 'Гулянка',
  'UA1806023013': 'Іванівка',
  'UA1806023014': 'Іванопіль',
  'UA1806023015': 'Калинівка',
  'UA1806023016': 'Кам\'яна Гора',
  'UA1806023044': 'Ходачки',
  'UA1806023017': 'Ковалівщина',
  'UA1806023018': 'Ковбащина',
  'UA1806023019': 'Красногірка',
  'UA1806023020': 'Кропивня',
  'UA1806023021': 'Купище',
  'UA1806023022': 'Лісівщина',
  'UA1806023023': 'Лісобуда',
  'UA1806023024': 'Мойсіївка',
  'UA1806023025': 'Мошківка',
  'UA1806023046': 'Нова Ушиця',
  'UA1806023026': 'Новина',
  'UA1806023028': 'Охотівка',
  'UA1806023027': 'Олександрівка',
  'UA1806023029': 'Першотравневе',
  'UA1806023030': 'Поліське',
  'UA1806023031': 'Пугачівка',
  'UA1806023033': 'Розівка',
  'UA1806023034': 'Рудня',
  'UA1806023035': 'Рудня',
  'UA1806023036': 'Рудня-Білківська',
  'UA1806023037': 'Рудня-Ушомирська',
  'UA1806023032': 'Ришавка',
  'UA1806023038': 'Садибне',
  'UA1806023039': 'Сантарка',
  'UA1806023040': 'Ставище',
  'UA1806023041': 'Струмок',
  'UA1806023042': 'Сушки',
  'UA1806023001': 'Ушомир',
  'UA1806023043': 'Ушиця',
  'UA1806023006': 'Велень',
  'UA1806023007': 'Веселівка',
  'UA1806023008': 'Вишневе',
  'UA1806023011': 'Заріччя',
  'UA1806023012': 'Зарубинка',
  'UA1806023010': 'Жабче',
  'UA1804001001': 'Андрушки',
  'UA1804001005': 'Харліївка',
  'UA1804001003': 'Красногірка',
  'UA1804001004': 'Макарівка',
  'UA1804001006': 'Новопаволоцьке',
  'UA1804001002': 'Василівка',
  'UA1804003002': 'Барашівка',
  'UA1804003001': 'Березівка',
  'UA1804003003': 'Богданівка',
  'UA1804003004': 'Болярка',
  'UA1804003005': 'Бондарці',
  'UA1804003016': 'Черемошне',
  'UA1804003008': 'Давидівка',
  'UA1804003009': 'Дубовець',
  'UA1804003011': 'Іванівка',
  'UA1804003012': 'Мусіївка',
  'UA1804003013': 'Нова Василівка',
  'UA1804003014': 'Рудківка',
  'UA1804003015': 'Садки',
  'UA1804003006': 'Василівка',
  'UA1804003007': 'Вигода',
  'UA1804003010': 'Заможне',
  'UA1804005003': 'Болячів',
  'UA1804005001': 'Брусилів',
  'UA1804005002': 'Биків',
  'UA1804005008': 'Долинівка',
  'UA1804005009': 'Дубрівка',
  'UA1804005007': 'Дивин',
  'UA1804005012': 'Карабачин',
  'UA1804005034': 'Хом\'янка',
  'UA1804005033': 'Хомутець',
  'UA1804005014': 'Костівці',
  'UA1804005013': 'Ковганівка',
  'UA1804005015': 'Краківщина',
  'UA1804005016': 'Лазарівка',
  'UA1804005017': 'Малинівка',
  'UA1804005018': 'Мар\'янівка',
  'UA1804005019': 'Містечко',
  'UA1804005020': 'Морозівка',
  'UA1804005021': 'Нові Озеряни',
  'UA1804005023': 'Осівці',
  'UA1804005022': 'Озера',
  'UA1804005025': 'Покришів',
  'UA1804005026': 'Привороття',
  'UA1804005024': 'Пилипонка',
  'UA1804005027': 'Романівка',
  'UA1804005028': 'Скочище',
  'UA1804005037': 'Скочище',
  'UA1804005029': 'Соболівка',
  'UA1804005030': 'Соловіївка',
  'UA1804005032': 'Старицьке',
  'UA1804005031': 'Ставище',
  'UA1804005005': 'Вільшка',
  'UA1804005006': 'Водотиї',
  'UA1804005004': 'Високе',
  'UA1804005035': 'Яструбенька',
  'UA1804005036': 'Яструбна',
  'UA1804005011': 'Йосипівка',
  'UA1804005010': 'Западня',
  'UA1804059003': 'Андріївка',
  'UA1804059004': 'Бежів',
  'UA1804059005': 'Браженка',
  'UA1804059001': 'Черняхів',
  'UA1804059011': 'Дівочки',
  'UA1804059037': 'Федорівка',
  'UA1804059009': 'Ганнопіль',
  'UA1804059002': 'Головине',
  'UA1804059010': 'Горбулів',
  'UA1804059013': 'Клітище',
  'UA1804059015': 'Корчівка',
  'UA1804059014': 'Коростелівка',
  'UA1804059016': 'Мала Горбаша',
  'UA1804059017': 'Малинівка',
  'UA1804059018': 'Мокренщина',
  'UA1804059019': 'Науменка',
  'UA1804059020': 'Нераж',
  'UA1804059022': 'Нові Сали',
  'UA1804059021': 'Нові Жадьки',
  'UA1804059023': 'Новосілка',
  'UA1804059025': 'Очеретянка',
  'UA1804059024': 'Осівка',
  'UA1804059026': 'Пекарщина',
  'UA1804059027': 'Плехів',
  'UA1804059028': 'Росівка',
  'UA1804059029': 'Рудня',
  'UA1804059030': 'Сали',
  'UA1804059032': 'Селець',
  'UA1804059033': 'Селянщина',
  'UA1804059034': 'Славів',
  'UA1804059035': 'Сліпчиці',
  'UA1804059036': 'Стирти',
  'UA1804059031': 'Свидя',
  'UA1804059006': 'Велика Горбаша',
  'UA1804059007': 'Видибор',
  'UA1804059008': 'Вишневе',
  'UA1804059012': 'Жадьки',
  'UA1804061004': 'Бабушки',
  'UA1804061005': 'Бартуха',
  'UA1804061006': 'Будичани',
  'UA1804061001': 'Чуднів',
  'UA1804061009': 'Дацьки',
  'UA1804061010': 'Дідківці',
  'UA1804061011': 'Довбиші',
  'UA1804061012': 'Дреники',
  'UA1804061013': 'Дриглів',
  'UA1804061014': 'Дубище',
  'UA1804061007': 'Городище',
  'UA1804061008': 'Грем\'яче',
  'UA1804061016': 'Карвинівка',
  'UA1804061018': 'Княжин',
  'UA1804061019': 'Короченки',
  'UA1804061021': 'Красногірка',
  'UA1804061022': 'Красносілка',
  'UA1804061020': 'Красноволиця',
  'UA1804061017': 'Кихті',
  'UA1804061023': 'Малі Коровинці',
  'UA1804061025': 'М\'ясківка',
  'UA1804061024': 'Михайленки',
  'UA1804061027': 'П\'ятка',
  'UA1804061026': 'Пилипівка',
  'UA1804061028': 'Рачки',
  'UA1804061029': 'Рижів',
  'UA1804061030': 'Станіславівка',
  'UA1804061031': 'Стовпів',
  'UA1804061032': 'Судачівка',
  'UA1804061034': 'Тютюнники',
  'UA1804061033': 'Турчинівка',
  'UA1804061002': 'Вакуленчук',
  'UA1804061003': 'Великі Коровинці',
  'UA1804061035': 'Ясногірка',
  'UA1804061015': 'Йосипівка',
  'UA1804015002': 'Березина',
  'UA1804015004': 'Гадзинка',
  'UA1804015001': 'Глибочиця',
  'UA1804015005': 'Городенка',
  'UA1804015006': 'Калинівка',
  'UA1804015007': 'Клітчин',
  'UA1804015008': 'Кмитів',
  'UA1804015009': 'Левків',
  'UA1804015010': 'Малі Кошарища',
  'UA1804015011': 'Нова Вигода',
  'UA1804015012': 'Руденька',
  'UA1804015013': 'Студениця',
  'UA1804015003': 'Великі Кошарища',
  'UA1804017002': 'Біла Криниця',
  'UA1804017001': 'Городок',
  'UA1804017003': 'Осів',
  'UA1804055002': 'Глибочок',
  'UA1804055003': 'Горіхове',
  'UA1804055001': 'Харитонівка',
  'UA1804055004': 'Кулішівка',
  'UA1804055005': 'Мамрин',
  'UA1804055006': 'Машина',
  'UA1804055007': 'Осикове',
  'UA1804055008': 'Осиковий Копець',
  'UA1804055009': 'Розкидайлівка',
  'UA1804055010': 'Рудня',
  'UA1804055013': 'Шахворостівка',
  'UA1804055011': 'Смолівка',
  'UA1804055012': 'Трикопці',
  'UA1804057002': 'Березівка',
  'UA1804057003': 'Будо-Рижани',
  'UA1804057057': 'Червоногранітне',
  'UA1804057054': 'Човнова',
  'UA1804057012': 'Данилівка',
  'UA1804057013': 'Дашинка',
  'UA1804057011': 'Давидівка',
  'UA1804057014': 'Дворище',
  'UA1804057006': 'Гайки',
  'UA1804057007': 'Галинівка',
  'UA1804057008': 'Грабівка',
  'UA1804057009': 'Грабняк',
  'UA1804057010': 'Грушки',
  'UA1804057021': 'Іванівка',
  'UA1804057022': 'Калинівка',
  'UA1804057023': 'Катеринівка',
  'UA1804057001': 'Хорошів',
  'UA1804057024': 'Комарівка',
  'UA1804057025': 'Копанівка',
  'UA1804057026': 'Копелянка',
  'UA1804057027': 'Коритище',
  'UA1804057028': 'Краївщина',
  'UA1804057029': 'Красногірка',
  'UA1804057030': 'Кропивенка',
  'UA1804057031': 'Крук',
  'UA1804057032': 'Курганці',
  'UA1804057034': 'Ліски',
  'UA1804057033': 'Лизник',
  'UA1804057035': 'Мар\'янівка',
  'UA1804057036': 'Михайлівка',
  'UA1804057037': 'Невирівка',
  'UA1804057038': 'Олішівка',
  'UA1804057039': 'Омелівка',
  'UA1804057041': 'Полівська Гута',
  'UA1804057042': 'Поромівка',
  'UA1804057040': 'Писарівка',
  'UA1804057043': 'Радичі',
  'UA1804057045': 'Рудня-Шляхова',
  'UA1804057044': 'Рижани',
  'UA1804057055': 'Шадура',
  'UA1804057046': 'Сколобів',
  'UA1804057047': 'Солодирі',
  'UA1804057048': 'Ставки',
  'UA1804057049': 'Стебниця',
  'UA1804057050': 'Суховоля',
  'UA1804057051': 'Теренці',
  'UA1804057052': 'Топорище',
  'UA1804057053': 'Торчин',
  'UA1804057005': 'Волянщина',
  'UA1804057004': 'Вишняківка',
  'UA1804057056': 'Яблунівка',
  'UA1804057017': 'Закомірня',
  'UA1804057016': 'Заздрівка',
  'UA1804057018': 'Зелений Гай',
  'UA1804057015': 'Жовтнівка',
  'UA1804057019': 'Знам\'янка',
  'UA1804057020': 'Зубринка',
  'UA1804023002': 'Білки',
  'UA1804023001': 'Корнин',
  'UA1804023010': 'Корнинське',
  'UA1804023003': 'Королівка',
  'UA1804023005': 'Лучин',
  'UA1804023004': 'Лисівка',
  'UA1804023007': 'Мохначка',
  'UA1804023006': 'Мишерине',
  'UA1804023008': 'Сущанка',
  'UA1804023009': 'Турбівка',
  'UA1804025002': 'Антонівка',
  'UA1804025003': 'Більківці',
  'UA1804025004': 'Бобрик',
  'UA1804025005': 'Борок',
  'UA1804025006': 'Браженець',
  'UA1804025012': 'Голубівка',
  'UA1804025013': 'Грубське',
  'UA1804025018': 'Колодязьки',
  'UA1804025001': 'Коростишів',
  'UA1804025017': 'Козак',
  'UA1804025019': 'Красилівка',
  'UA1804025020': 'Кропивня',
  'UA1804025016': 'Квітневе',
  'UA1804025021': 'Онишпіль',
  'UA1804025022': 'Продубіївка',
  'UA1804025023': 'Радівка',
  'UA1804025024': 'Семенівка',
  'UA1804025029': 'Щигліївка',
  'UA1804025026': 'Струцівка',
  'UA1804025025': 'Стрижівка',
  'UA1804025027': 'Теснівка',
  'UA1804025028': 'Царівка',
  'UA1804025009': 'Віленька',
  'UA1804025010': 'Вільня',
  'UA1804025011': 'Вільнянка',
  'UA1804025007': 'Видумка',
  'UA1804025008': 'Вишневе',
  'UA1804025014': 'Єлизаветівка',
  'UA1804025015': 'Здвижка',
  'UA1804027002': 'Андріївка',
  'UA1804027003': 'Березова Гать',
  'UA1804027004': 'Білка',
  'UA1804027025': 'Червоносілка',
  'UA1804027006': 'Грузливець',
  'UA1804027008': 'Калиновий Гай',
  'UA1804027023': 'Ходорівка',
  'UA1804027001': 'Курне',
  'UA1804027027': 'Курне',
  'UA1804027009': 'Молодіжне',
  'UA1804027010': 'Муравня',
  'UA1804027011': 'Новини',
  'UA1804027012': 'Павлівка',
  'UA1804027013': 'Підлісне',
  'UA1804027014': 'Рудокопи',
  'UA1804027026': 'Шереметів',
  'UA1804027015': 'Слобідка',
  'UA1804027016': 'Соколів',
  'UA1804027017': 'Стара Рудня',
  'UA1804027018': 'Старий Майдан',
  'UA1804027019': 'Стрибіж',
  'UA1804027020': 'Теньківка',
  'UA1804027021': 'Тетірка',
  'UA1804027024': 'Цвітянка',
  'UA1804027022': 'Улашанівка',
  'UA1804027005': 'Великий Луг',
  'UA1804027007': 'Зелена Діброва',
  'UA1804021003': 'Дехтярка',
  'UA1804021005': 'Кошляки',
  'UA1804021001': 'Квітневе',
  'UA1804021006': 'Почуйки',
  'UA1804021007': 'Романівка',
  'UA1804021008': 'Ставище',
  'UA1804021002': 'Велика Чернявка',
  'UA1804021009': 'Яблунівка',
  'UA1804021004': 'Єрчики',
  'UA1804029002': 'Авратин',
  'UA1804029003': 'Березівка',
  'UA1804029005': 'Борушківці',
  'UA1804029004': 'Бичева',
  'UA1804029018': 'Демківці',
  'UA1804029019': 'Дослідне',
  'UA1804029047': 'Филинці',
  'UA1804029013': 'Гізівщина',
  'UA1804029014': 'Глезне',
  'UA1804029015': 'Горопаї',
  'UA1804029017': 'Громада',
  'UA1804029016': 'Гринівці',
  'UA1804029021': 'Іванківці',
  'UA1804029025': 'Коростки',
  'UA1804029024': 'Коваленки',
  'UA1804029026': 'Кутище',
  'UA1804029022': 'Квітневе',
  'UA1804029023': 'Кириївка',
  'UA1804029001': 'Любар',
  'UA1804029027': 'Липне',
  'UA1804029028': 'Мала Деревичка',
  'UA1804029029': 'Малий Браталів',
  'UA1804029030': 'Меленці',
  'UA1804029032': 'Мотовилівка',
  'UA1804029031': 'Михайлівка',
  'UA1804029033': 'Нова Чортория',
  'UA1804029034': 'Новий Любар',
  'UA1804029035': 'Озерне',
  'UA1804029036': 'Панасівка',
  'UA1804029037': 'Пединка',
  'UA1804029038': 'Перетік',
  'UA1804029040': 'Провалівка',
  'UA1804029039': 'Привітів',
  'UA1804029041': 'Рогізна',
  'UA1804029043': 'Семенівка',
  'UA1804029042': 'Северинівка',
  'UA1804029044': 'Стара Чортория',
  'UA1804029045': 'Старий Любар',
  'UA1804029046': 'Стрижівка',
  'UA1804029006': 'Велика Волиця',
  'UA1804029008': 'Великі Деревичі',
  'UA1804029009': 'Великобраталівське',
  'UA1804029007': 'Великий Браталів',
  'UA1804029010': 'Веселка',
  'UA1804029011': 'Вигнанка',
  'UA1804029012': 'Виноградівка',
  'UA1804029048': 'Юрівка',
  'UA1804029020': 'Житинці',
  'UA1804031002': 'Дертка',
  'UA1804031003': 'Колодяжне',
  'UA1804031004': 'Мала Козара',
  'UA1804031001': 'Миропіль',
  'UA1804031005': 'Олександрівка',
  'UA1804031006': 'Паволочка',
  'UA1804031007': 'Печанівка',
  'UA1804031008': 'Пилипо-Кошара',
  'UA1804033015': 'Фасова',
  'UA1804033003': 'Гацьківка',
  'UA1804033004': 'Ісаківка',
  'UA1804033005': 'Кам\'яний Брід',
  'UA1804033016': 'Хичів',
  'UA1804033006': 'Краснорічка',
  'UA1804033007': 'Кропивня',
  'UA1804033008': 'Луковець',
  'UA1804033009': 'Небіж',
  'UA1804033001': 'Нова Борова',
  'UA1804033011': 'Рудня-Фасова',
  'UA1804033010': 'Рудня-Гацьківка',
  'UA1804033012': 'Старий Бобрик',
  'UA1804033013': 'Томашівка',
  'UA1804033014': 'Турчинка',
  'UA1804033002': 'Валки',
  'UA1804033018': 'Ягодинка Друга',
  'UA1804033017': 'Ягодинка',
  'UA1804035011': 'Двірець',
  'UA1804035007': 'Гай',
  'UA1804035008': 'Глибочок',
  'UA1804035009': 'Головенка',
  'UA1804035010': 'Городище',
  'UA1804035002': 'Гуйва',
  'UA1804035013': 'Іванківці',
  'UA1804035014': 'Крути',
  'UA1804035001': 'Новогуйвинське',
  'UA1804035015': 'Озерянка',
  'UA1804035003': 'Озерне',
  'UA1804035016': 'Павленківка',
  'UA1804035017': 'Пряжів',
  'UA1804035018': 'Роздольне',
  'UA1804035019': 'Рудня-Городище',
  'UA1804035020': 'Ружки',
  'UA1804035021': 'Сінгури',
  'UA1804035022': 'Соснівка',
  'UA1804035023': 'Ставецьке',
  'UA1804035024': 'Троянів',
  'UA1804035004': 'Вертокиївка',
  'UA1804035006': 'Волиця',
  'UA1804035005': 'Вишневе',
  'UA1804035012': 'Залізня',
  'UA1804037004': 'Довжик',
  'UA1804037006': 'Іванків',
  'UA1804037007': 'Івановичі',
  'UA1804037008': 'Кам\'янка',
  'UA1804037009': 'Крученець',
  'UA1804037010': 'Ксаверівка',
  'UA1804037011': 'Некраші',
  'UA1804037012': 'Новопіль',
  'UA1804037013': 'Новоселиця',
  'UA1804037014': 'Окілок',
  'UA1804037001': 'Оліївка',
  'UA1804037015': 'Перемога',
  'UA1804037016': 'Піщанка',
  'UA1804037020': 'Щербини',
  'UA1804037018': 'Сонячне',
  'UA1804037017': 'Світин',
  'UA1804037019': 'Троковичі',
  'UA1804037003': 'Вільськ',
  'UA1804037002': 'Вишпіль',
  'UA1804037021': 'Ялинівка',
  'UA1804037005': 'Зороків',
  'UA1804039002': 'Бухалівщина',
  'UA1804039004': 'Голуб\'ятин',
  'UA1804039005': 'Кам\'янка',
  'UA1804039022': 'Ходорків',
  'UA1804039006': 'Котлярка',
  'UA1804039007': 'Криве',
  'UA1804039008': 'Липки',
  'UA1804039009': 'Маркова Волиця',
  'UA1804039010': 'Миролюбівка',
  'UA1804039011': 'Новоселиця',
  'UA1804039013': 'Парипси',
  'UA1804039012': 'Паволоч',
  'UA1804039014': 'Піски',
  'UA1804039001': 'Попільня',
  'UA1804039015': 'Попільня',
  'UA1804039016': 'Пустельники',
  'UA1804039017': 'Рудка',
  'UA1804039018': 'Саверці',
  'UA1804039019': 'Сокільча',
  'UA1804039020': 'Соколів Брід',
  'UA1804039021': 'Строків',
  'UA1804039003': 'Великі Лісівці',
  'UA1804039023': 'Відродження',
  'UA1804041003': 'Будилівка',
  'UA1804041002': 'Буглаки',
  'UA1804041008': 'Дітинець',
  'UA1804041009': 'Дорогунь',
  'UA1804041010': 'Дубовик',
  'UA1804041006': 'Гришківка',
  'UA1804041007': 'Гута-Потіївка',
  'UA1804041013': 'Клен',
  'UA1804041015': 'Моделів',
  'UA1804041016': 'Морогівка',
  'UA1804041014': 'Мирне',
  'UA1804041017': 'Нова Буда',
  'UA1804041018': 'Нова Мар\'янівка',
  'UA1804041019': 'Новосілка',
  'UA1804041020': 'Облітки',
  'UA1804041001': 'Потіївка',
  'UA1804041021': 'Прибуток',
  'UA1804041022': 'Стара Буда',
  'UA1804041023': 'Стара Гребля',
  'UA1804041024': 'Теклянівка',
  'UA1804041005': 'Вівче',
  'UA1804041004': 'Вихля',
  'UA1804041011': 'Заміри',
  'UA1804041012': 'Заньки',
  'UA1804043002': 'Бабичівка',
  'UA1804043003': 'Будище',
  'UA1804043004': 'Буряківка',
  'UA1804043034': 'Чехівці',
  'UA1804043033': 'Чернявка',
  'UA1804043010': 'Гута-Юстинівка',
  'UA1804043012': 'Івановичі',
  'UA1804043013': 'Колодіївка',
  'UA1804043014': 'Корчівка',
  'UA1804043015': 'Кошелівка',
  'UA1804043016': 'Липівка',
  'UA1804043017': 'Мартинівка',
  'UA1804043018': 'Мирне',
  'UA1804043019': 'Нарцизівка',
  'UA1804043020': 'Неборівка',
  'UA1804043021': 'Новий Завод',
  'UA1804043023': 'Очеретянка',
  'UA1804043022': 'Олізарка',
  'UA1804043024': 'Поплавка',
  'UA1804043025': 'Пулино-Гута',
  'UA1804043001': 'Пулини',
  'UA1804043026': 'Радецька Болярка',
  'UA1804043027': 'Радецьке Будище',
  'UA1804043028': 'Сколобів',
  'UA1804043029': 'Стара Олександрівка',
  'UA1804043030': 'Тартачок',
  'UA1804043031': 'Трудове',
  'UA1804043032': 'Ужівка',
  'UA1804043005': 'Веселе',
  'UA1804043006': 'Веснянка',
  'UA1804043009': 'В\'язовець',
  'UA1804043008': 'В\'юнки',
  'UA1804043007': 'Видумка',
  'UA1804043036': 'Ягодинка',
  'UA1804043037': 'Ялинівка',
  'UA1804043038': 'Ясенівка',
  'UA1804043039': 'Ясна Поляна',
  'UA1804043040': 'Ясногірка',
  'UA1804043035': 'Юлянівка',
  'UA1804043011': 'Зелена Поляна',
  'UA1804045002': 'Березці',
  'UA1804045004': 'Білка',
  'UA1804045005': 'Борщів',
  'UA1804045006': 'Брід',
  'UA1804045003': 'Бистріївка',
  'UA1804045048': 'Чайківка',
  'UA1804045049': 'Чудин',
  'UA1804045045': 'Філонівка',
  'UA1804045010': 'Глухів Другий',
  'UA1804045011': 'Глухів Перший',
  'UA1804045009': 'Глиниця',
  'UA1804045012': 'Городчин',
  'UA1804045013': 'Гута-Забілоцька',
  'UA1804045017': 'Кайтанівка',
  'UA1804045046': 'Ходори',
  'UA1804045047': 'Хомівка',
  'UA1804045020': 'Кочерів',
  'UA1804045019': 'Котівка',
  'UA1804045021': 'Краснобірка',
  'UA1804045022': 'Красносілка',
  'UA1804045023': 'Кримок',
  'UA1804045018': 'Кичкирі',
  'UA1804045024': 'Лутівка',
  'UA1804045025': 'Мала Рача',
  'UA1804045026': 'Мар\'янівка',
  'UA1804045027': 'Меделівка',
  'UA1804045028': 'Меньківка',
  'UA1804045029': 'Мірча',
  'UA1804045030': 'Негребівка',
  'UA1804045031': 'Нова Юрівка',
  'UA1804045032': 'Осички',
  'UA1804045033': 'Переміжжя',
  'UA1804045035': 'Поташня',
  'UA1804045034': 'Пилиповичі',
  'UA1804045001': 'Радомишль',
  'UA1804045036': 'Раївка',
  'UA1804045037': 'Раковичі',
  'UA1804045038': 'Рудня-Городецька',
  'UA1804045039': 'Русанівка',
  'UA1804045040': 'Спірне',
  'UA1804045041': 'Ставки',
  'UA1804045042': 'Став-Слобода',
  'UA1804045043': 'Таборище',
  'UA1804045044': 'Товсте',
  'UA1804045007': 'Велика Рача',
  'UA1804045008': 'Верлок',
  'UA1804045015': 'Забілоччя',
  'UA1804045016': 'Заболоть',
  'UA1804045014': 'Журавлинка',
  'UA1804047003': 'Білки',
  'UA1804047004': 'Борятин',
  'UA1804047005': 'Бубни',
  'UA1804047006': 'Булдичів',
  'UA1804047002': 'Биківка',
  'UA1804047047': 'Червоні Хатки',
  'UA1804047012': 'Годиха',
  'UA1804047013': 'Голубин',
  'UA1804047014': 'Гордіївка',
  'UA1804047011': 'Гвіздярня',
  'UA1804047017': 'Іванівщина',
  'UA1804047018': 'Камінь',
  'UA1804047045': 'Химрич',
  'UA1804047044': 'Хижинці',
  'UA1804047019': 'Корчівка',
  'UA1804047020': 'Костянтинівка',
  'UA1804047021': 'Лісна Рудня',
  'UA1804047022': 'Мала Токарівка',
  'UA1804047023': 'Мані',
  'UA1804047024': 'Межирічка',
  'UA1804047026': 'Монастирок',
  'UA1804047025': 'Микільськ',
  'UA1804047029': 'Новохатки',
  'UA1804047028': 'Новопрутівка',
  'UA1804047027': 'Нивна',
  'UA1804047030': 'Ольшанка',
  'UA1804047031': 'Омильне',
  'UA1804047032': 'Прутівка',
  'UA1804047033': 'Разіне',
  'UA1804047001': 'Романів',
  'UA1804047034': 'Романівка',
  'UA1804047035': 'Садки',
  'UA1804047036': 'Сарнівка',
  'UA1804047048': 'Шевченка',
  'UA1804047038': 'Соболівка',
  'UA1804047039': 'Старочуднівська Гута',
  'UA1804047040': 'Сульжинівка',
  'UA1804047037': 'Синява',
  'UA1804047041': 'Тевеліївка',
  'UA1804047042': 'Товща',
  'UA1804047046': 'Цеберка',
  'UA1804047043': 'Улянівка',
  'UA1804047007': 'Велика Козара',
  'UA1804047009': 'Вільха',
  'UA1804047010': 'Врублівка',
  'UA1804047008': 'Вила',
  'UA1804047049': 'Яблунівка',
  'UA1804047050': 'Ягодинка',
  'UA1804047051': 'Ясногород',
  'UA1804047016': 'Залужне',
  'UA1804047015': 'Жовтий Брід',
  'UA1804049008': 'Буймир',
  'UA1804049002': 'Бистрі',
  'UA1804049021': 'Червоний Степок',
  'UA1804049004': 'Грабівка',
  'UA1804049006': 'Іванків',
  'UA1804049007': 'Кодня',
  'UA1804049009': 'Леонівка',
  'UA1804049011': 'Ліщин',
  'UA1804049010': 'Лісівщина',
  'UA1804049012': 'Лука',
  'UA1804049014': 'Млинище',
  'UA1804049015': 'Мошківка',
  'UA1804049013': 'Миролюбівка',
  'UA1804049016': 'Піски',
  'UA1804049017': 'Скоморохи',
  'UA1804049018': 'Слобода-Селець',
  'UA1804049001': 'Станишівка',
  'UA1804049019': 'Тарасівка',
  'UA1804049020': 'Туровець',
  'UA1804049003': 'Вершина',
  'UA1804049005': 'Зарічани',
  'UA1804051015': 'Червоний Гай',
  'UA1804051016': 'Червоний Ровець',
  'UA1804051003': 'Городське',
  'UA1804051004': 'Гуменники',
  'UA1804051005': 'Кам\'яний Брід',
  'UA1804051006': 'Кашперівка',
  'UA1804051007': 'Козіївка',
  'UA1804051008': 'Минійки',
  'UA1804051009': 'Новогородецьке',
  'UA1804051010': 'Садове',
  'UA1804051011': 'Слобідка',
  'UA1804051012': 'Смиківка',
  'UA1804051001': 'Старосільці',
  'UA1804051013': 'Торчин',
  'UA1804051014': 'Травневе',
  'UA1804051002': 'Високий Камінь',
  'UA1804053002': 'Буки',
  'UA1804053004': 'Дениші',
  'UA1804053005': 'Катеринівка',
  'UA1804053006': 'Корчак',
  'UA1804053007': 'Михайлівка',
  'UA1804053008': 'Нова Рудня',
  'UA1804053009': 'Перлявка',
  'UA1804053010': 'Покостівка',
  'UA1804053011': 'Руденька',
  'UA1804053012': 'Рудня-Пошта',
  'UA1804053013': 'Старошийка',
  'UA1804053001': 'Тетерівка',
  'UA1804053014': 'Тригір\'я',
  'UA1804053015': 'Улянівка',
  'UA1804053003': 'Висока Піч',
  'UA1804011002': 'Бейзимівка',
  'UA1804011005': 'Дібрівка',
  'UA1804011004': 'Галіївка',
  'UA1804011006': 'Карпівці',
  'UA1804011007': 'Кілки',
  'UA1804011008': 'Мала Волиця',
  'UA1804011009': 'Подолянці',
  'UA1804011011': 'Шевченка',
  'UA1804011010': 'Троща',
  'UA1804011001': 'Вільшанка',
  'UA1804011003': 'Волосівка',
  'UA1804011012': 'Ясопіль',
  'UA1804013002': 'Борок',
  'UA1804013003': 'Івниця',
  'UA1804013004': 'Корчмище',
  'UA1804013008': 'Новоівницьке',
  'UA1804013005': 'Стара Котельня',
  'UA1804013006': 'Старосілля',
  'UA1804013007': 'Степок',
  'UA1804013001': 'Волиця',
  'UA1804009004': 'Ірша',
  'UA1804009005': 'Макалевичі',
  'UA1804009006': 'Межирічка',
  'UA1804009007': 'Садки',
  'UA1804009002': 'Веприн',
  'UA1804009003': 'Вирва',
  'UA1804009001': 'Вишевичі',
  'UA1804007002': 'Городище',
  'UA1804007004': 'Осники',
  'UA1804007005': 'Щеніїв',
  'UA1804007001': 'Високе',
  'UA1804007003': 'Забріддя',
  'UA1804019002': 'Вереси',
  'UA1804019001': 'Житомир',
  'UA1808001001': 'Баранівка',
  'UA1808001004': 'Берестівка',
  'UA1808001005': 'Будисько',
  'UA1808001011': 'Деревищина',
  'UA1808001009': 'Глибочок',
  'UA1808001010': 'Гриньки',
  'UA1808001015': 'Іванівка',
  'UA1808001017': 'Кашперівка',
  'UA1808001018': 'Климентіївка',
  'UA1808001019': 'Лісове',
  'UA1808001020': 'Марківка',
  'UA1808001022': 'Млини',
  'UA1808001021': 'Мирославль',
  'UA1808001024': 'Острожок',
  'UA1808001023': 'Озерянка',
  'UA1808001002': 'Першотравенськ',
  'UA1808001003': 'Полянка',
  'UA1808001025': 'Рогачів',
  'UA1808001026': 'Рудня',
  'UA1808001027': 'Середня',
  'UA1808001029': 'Смолдирів',
  'UA1808001030': 'Смолка',
  'UA1808001031': 'Стара Гута',
  'UA1808001032': 'Суємці',
  'UA1808001028': 'Ситисько',
  'UA1808001033': 'Табори',
  'UA1808001007': 'Вірля',
  'UA1808001008': 'Володимирівка',
  'UA1808001006': 'Вишнівка',
  'UA1808001035': 'Ялишів',
  'UA1808001034': 'Явне',
  'UA1808001016': 'Йосипівка',
  'UA1808001013': 'Зеремля',
  'UA1808001012': 'Жари',
  'UA1808001014': 'Зрубок',
  'UA1808003003': 'Антонівка',
  'UA1808003001': 'Бараші',
  'UA1808003004': 'Бастова Рудня',
  'UA1808003005': 'Березівка',
  'UA1808003007': 'Бобриця',
  'UA1808003006': 'Бобрицька Болярка',
  'UA1808003008': 'Будо-Бобриця',
  'UA1808003014': 'Дуга',
  'UA1808003011': 'Ганнопіль',
  'UA1808003012': 'Гута-Бобрицька',
  'UA1808003013': 'Гута-Зеленицька',
  'UA1808003020': 'Кам\'янка',
  'UA1808003035': 'Хотиж',
  'UA1808003023': 'Крем\'янка',
  'UA1808003022': 'Киянка',
  'UA1808003021': 'Киселівка',
  'UA1808003024': 'Майдан',
  'UA1808003025': 'Михайлівка',
  'UA1808003026': 'Неділище',
  'UA1808003027': 'Новоолександрівка',
  'UA1808003028': 'Новосілка',
  'UA1808003029': 'Ольхівка',
  'UA1808003030': 'Рясне',
  'UA1808003036': 'Шевченкове',
  'UA1808003032': 'Сімаківка',
  'UA1808003033': 'Сорочень',
  'UA1808003031': 'Синявка',
  'UA1808003034': 'Усолуси',
  'UA1808003009': 'Верби',
  'UA1808003010': 'Вікторівка',
  'UA1808003002': 'Яблунець',
  'UA1808003016': 'Єлизаветпіль',
  'UA1808003015': 'Євгенівка',
  'UA1808003019': 'Йосипівка',
  'UA1808003017': 'Зелениця',
  'UA1808003018': 'Зорянка',
  'UA1808005002': 'Абрамок',
  'UA1808005003': 'Барвинівка',
  'UA1808005004': 'Болярка',
  'UA1808005005': 'Броники',
  'UA1808005010': 'Дубівка',
  'UA1808005031': 'Федорівка',
  'UA1808005011': 'Кам\'яний Майдан',
  'UA1808005012': 'Катюха',
  'UA1808005013': 'Кропивня',
  'UA1808005014': 'Кущове',
  'UA1808005015': 'Лагульськ',
  'UA1808005016': 'Лебедівка',
  'UA1808005017': 'Малинівка',
  'UA1808005018': 'Марушівка',
  'UA1808005019': 'Миколаївка',
  'UA1808005020': 'Несолонь',
  'UA1808005021': 'Нова Романівка',
  'UA1808005022': 'Новозелене',
  'UA1808005023': 'Перемога',
  'UA1808005024': 'Поліянівка',
  'UA1808005001': 'Романівка',
  'UA1808005025': 'Слобода-Романівська',
  'UA1808005027': 'Тальківка',
  'UA1808005026': 'Тальки',
  'UA1808005028': 'Теснівка',
  'UA1808005029': 'Тупальці',
  'UA1808005030': 'Ужачин',
  'UA1808005006': 'Варварівка',
  'UA1808005007': 'Вершниця',
  'UA1808005009': 'Владин',
  'UA1808005008': 'Вироби',
  'UA1808005032': 'Яблуневе',
  'UA1808005033': 'Яворівка',
  'UA1808021002': 'Берегове',
  'UA1808021001': 'Чижівка',
  'UA1808021008': 'Дібрівське',
  'UA1808021009': 'Карпилівка',
  'UA1808021010': 'Катеринівка',
  'UA1808021026': 'Ходурки',
  'UA1808021011': 'Красилівка',
  'UA1808021012': 'Красилівське',
  'UA1808021014': 'Курчиця',
  'UA1808021013': 'Курчицька Гута',
  'UA1808021015': 'Мала Цвіля',
  'UA1808021017': 'Мар\'янівка',
  'UA1808021016': 'Маринівка',
  'UA1808021019': 'Мойсіївка',
  'UA1808021020': 'Морозівка',
  'UA1808021018': 'Михіївка',
  'UA1808021021': 'Радичі',
  'UA1808021022': 'Рихальське',
  'UA1808021023': 'Сербо-Слобідка',
  'UA1808021024': 'Стара Гута',
  'UA1808021025': 'Таращанка',
  'UA1808021003': 'Варварівка',
  'UA1808021004': 'Вербівка',
  'UA1808021005': 'Вересівка',
  'UA1808021007': 'Вірівка',
  'UA1808021006': 'Вишківка',
  'UA1808021027': 'Яблунівка',
  'UA1808009004': 'Адамівка',
  'UA1808009016': 'Червонодвірка',
  'UA1808009005': 'Дібрівка',
  'UA1808009006': 'Дорогань',
  'UA1808009001': 'Довбиш',
  'UA1808009002': 'Кам\'яний Брід',
  'UA1808009009': 'Лісова Поляна',
  'UA1808009010': 'Любарська Гута',
  'UA1808009003': 'Мар\'янівка',
  'UA1808009011': 'Наталія',
  'UA1808009013': 'Осичне',
  'UA1808009012': 'Ожгів',
  'UA1808009014': 'Тартак',
  'UA1808009015': 'Турова',
  'UA1808009007': 'Жовте',
  'UA1808009008': 'Зятинець',
  'UA1808011001': 'Дубрівка',
  'UA1808011003': 'Глинянка',
  'UA1808011012': 'Хижівка',
  'UA1808011008': 'Красуля',
  'UA1808011009': 'Мокре',
  'UA1808011010': 'Радулин',
  'UA1808011011': 'Стовпи',
  'UA1808011002': 'Вересна',
  'UA1808011004': 'Забара',
  'UA1808011005': 'Закриниччя',
  'UA1808011006': 'Зелений Гай',
  'UA1808011007': 'Зірка',
  'UA1808007002': 'Анастасівка',
  'UA1808007004': 'Брониця',
  'UA1808007003': 'Броницька Гута',
  'UA1808007013': 'Червона Воля',
  'UA1808007005': 'Дубники',
  'UA1808007001': 'Городниця',
  'UA1808007006': 'Кленова',
  'UA1808007008': 'Лучиця',
  'UA1808007007': 'Липине',
  'UA1808007009': 'Мала Анастасівка',
  'UA1808007011': 'Перелісянка',
  'UA1808007010': 'Перелісок',
  'UA1808007012': 'Прихід',
  'UA1808017003': 'Дублинки',
  'UA1808017005': 'Калинівка',
  'UA1808017006': 'Косенів',
  'UA1808017007': 'Крайня Деражня',
  'UA1808017008': 'Партизанське',
  'UA1808017001': 'Піщів',
  'UA1808017009': 'Повчине',
  'UA1808017010': 'Середня Деражня',
  'UA1808017011': 'Суховоля',
  'UA1808017002': 'Велика Деражня',
  'UA1808017004': 'Жеребилівка',
  'UA1808019002': 'Гульськ',
  'UA1808019003': 'Івашківка',
  'UA1808019004': 'Кануни',
  'UA1808019006': 'Киянка',
  'UA1808019005': 'Кикова',
  'UA1808019007': 'Немильня',
  'UA1808019001': 'Стриєва',
  'UA1808019008': 'Сусли',
  'UA1808019009': 'Українське',
  'UA1808023002': 'Будища',
  'UA1808023004': 'Гірки',
  'UA1808023006': 'Кам\'янка',
  'UA1808023008': 'Колодянка',
  'UA1808023009': 'Коритища',
  'UA1808023007': 'Кожушки',
  'UA1808023010': 'Лідівка',
  'UA1808023011': 'Мала Горбаша',
  'UA1808023012': 'Орепи',
  'UA1808023013': 'Тернівка',
  'UA1808023014': 'Токарів',
  'UA1808023003': 'Велика Горбаша',
  'UA1808023001': 'Ярунь',
  'UA1808023005': 'Жолобне',
  'UA1808013002': 'Адамове',
  'UA1808013003': 'Андрієвичі',
  'UA1808013004': 'Аполлонівка',
  'UA1808013005': 'Березники',
  'UA1808013006': 'Болярка',
  'UA1808013007': 'Брідок',
  'UA1808013070': 'Червоний Бір',
  'UA1808013071': 'Чміль',
  'UA1808013016': 'Дібрівка',
  'UA1808013014': 'Ганнівка',
  'UA1808013015': 'Горбове',
  'UA1808013022': 'Ілляшівка',
  'UA1808013023': 'Кам\'яногірка',
  'UA1808013069': 'Хутір-Мокляки',
  'UA1808013026': 'Кочичине',
  'UA1808013024': 'Королівка',
  'UA1808013025': 'Косяк',
  'UA1808013027': 'Красногірка',
  'UA1808013028': 'Кривотин',
  'UA1808013029': 'Куліші',
  'UA1808013030': 'Лебідь',
  'UA1808013031': 'Лісове',
  'UA1808013033': 'Льонівка',
  'UA1808013032': 'Лука',
  'UA1808013034': 'Мала Глумча',
  'UA1808013037': 'Малоглумчанка',
  'UA1808013035': 'Малий Кривотин',
  'UA1808013036': 'Малий Яблунець',
  'UA1808013038': 'Медведеве',
  'UA1808013040': 'Мокляки',
  'UA1808013039': 'Миколаївка',
  'UA1808013041': 'Нараївка',
  'UA1808013042': 'Непізнаничі',
  'UA1808013044': 'Нові Серби',
  'UA1808013043': 'Нитине',
  'UA1808013045': 'Омелуша',
  'UA1808013046': 'Осівка',
  'UA1808013047': 'Осова',
  'UA1808013048': 'Паранине',
  'UA1808013049': 'Підлуби',
  'UA1808013050': 'Покощеве',
  'UA1808013051': 'Полоничеве',
  'UA1808013052': 'Просіка',
  'UA1808013053': 'Рогівка',
  'UA1808013054': 'Руденька',
  'UA1808013055': 'Рудня-Іванівська',
  'UA1808013056': 'Рудня-Миколаївка',
  'UA1808013057': 'Садки',
  'UA1808013058': 'Серби',
  'UA1808013060': 'Середи',
  'UA1808013059': 'Сергіївка',
  'UA1808013063': 'Спаське',
  'UA1808013065': 'Старі Непізнаничі',
  'UA1808013066': 'Старі Серби',
  'UA1808013064': 'Старий Хмерин',
  'UA1808013067': 'Степанівка',
  'UA1808013061': 'Симони',
  'UA1808013062': 'Ситне',
  'UA1808013068': 'Тайки',
  'UA1808013008': 'Велика Глумча',
  'UA1808013009': 'Велика Цвіля',
  'UA1808013010': 'Великий Яблунець',
  'UA1808013011': 'Вільшанка',
  'UA1808013012': 'Вірівка',
  'UA1808013013': 'Володимирівка',
  'UA1808013072': 'Яменець',
  'UA1808013001': 'Ємільчине',
  'UA1808013017': 'Забаро-Давидівка',
  'UA1808013018': 'Запруда',
  'UA1808013019': 'Заровенка',
  'UA1808013020': 'Здоровець',
  'UA1808013021': 'Зосимівка',
  'UA1808015002': 'Анета',
  'UA1808015003': 'Багате',
  'UA1808015004': 'Борисівка',
  'UA1808015008': 'Дідовичі',
  'UA1808015006': 'Городище',
  'UA1808015007': 'Груд',
  'UA1808015009': 'Майстрів',
  'UA1808015010': 'Майстрова Воля',
  'UA1808015011': 'Маковиці',
  'UA1808015012': 'Наталівка',
  'UA1808015013': 'Олександрівка',
  'UA1808015014': 'Пилиповичі',
  'UA1808015015': 'Степове',
  'UA1808015005': 'Великий Молодьків',
  'UA1808015001': 'Звягель',
  'UA0102003': 'Ароматненська',
  'UA0102005': 'Бахчисарайська',
  'UA0102015': 'Долинненська',
  'UA0102013': 'Голубинська',
  'UA0102025': 'Каштанівська',
  'UA0102027': 'Красномацька',
  'UA0102029': 'Куйбишевська',
  'UA0102033': 'Піщанівська',
  'UA0102035': 'Плодівська',
  'UA0102037': 'Поштівська',
  'UA0102039': 'Скалистівська',
  'UA0102041': 'Табачненська',
  'UA0102045': 'Тінистівська',
  'UA0102047': 'Углівська',
  'UA0102009': 'Верхоріченська',
  'UA0102011': 'Вілінська',
  'UA0102017': 'Залізничненська',
  'UA0102019': 'Зеленівська',
  'UA0104001': 'Ароматнівська',
  'UA0104003': 'Багатівська',
  'UA0104005': 'Білогірська',
  'UA0104071': 'Чкаловська',
  'UA0104073': 'Чорнопільська',
  'UA0104011': 'Дрофинська',
  'UA0104027': 'Іванівська',
  'UA0104029': 'Ізобільненська',
  'UA0104031': 'Кісточківська',
  'UA0104033': 'Кримськорозівська',
  'UA0104035': 'Криничненська',
  'UA0104037': 'Курська',
  'UA0104039': 'Листвинська',
  'UA0104041': 'Мельнична',
  'UA0104047': 'Мічурінська',
  'UA0104049': 'Муромська',
  'UA0104045': 'Михайлівська',
  'UA0104043': 'Митрофанівська',
  'UA0104053': 'Новогригорівська',
  'UA0104055': 'Новожилівська',
  'UA0104051': 'Нижньогірська',
  'UA0104059': 'Охотська',
  'UA0104057': 'Омелянівська',
  'UA0104061': 'Пшеничненська',
  'UA0104063': 'Русаківська',
  'UA0104065': 'Садова',
  'UA0104069': 'Цвіточненська',
  'UA0104067': 'Уварівська',
  'UA0104007': 'Василівська',
  'UA0104009': 'Вишенська',
  'UA0104075': 'Якимівська',
  'UA0104017': 'Зеленогірська',
  'UA0104019': 'Земляничненська',
  'UA0104013': 'Желябовська',
  'UA0104015': 'Жемчужинська',
  'UA0104023': 'Зоркінська',
  'UA0104025': 'Зуйська',
  'UA0104021': 'Зибинська',
  'UA0106001': 'Азовська',
  'UA0106051': 'Чайкинська',
  'UA0106005': 'Джанкойська',
  'UA0106013': 'Ізумруднівська',
  'UA0106015': 'Кіндратівська',
  'UA0106017': 'Кримківська',
  'UA0106019': 'Лобанівська',
  'UA0106021': 'Луганська',
  'UA0106023': 'Майська',
  'UA0106025': 'Маслівська',
  'UA0106027': 'Медведівська',
  'UA0106029': 'Мирнівська',
  'UA0106031': 'Новокримська',
  'UA0106033': 'Пахарівська',
  'UA0106035': 'Побєдненська',
  'UA0106037': 'Просторненська',
  'UA0106041': 'Рощинська',
  'UA0106039': 'Розкішненська',
  'UA0106045': 'Стальненська',
  'UA0106043': 'Світлівська',
  'UA0106047': 'Табачненська',
  'UA0106049': 'Цілинна',
  'UA0106003': 'Вільненська',
  'UA0106053': 'Ярківська',
  'UA0106055': 'Яркополенська',
  'UA0106057': 'Яснополянська',
  'UA0106007': 'Єрмаківська',
  'UA0106011': 'Зарічненська',
  'UA0106009': 'Завіто-Ленінська',
  'UA0118001': 'Абрикосівська',
  'UA0118003': 'Берегова',
  'UA0118073': 'Чапаєвська',
  'UA0118075': 'Чорноземненська',
  'UA0118011': 'Дачнівська',
  'UA0118013': 'Дмитрівська',
  'UA0118071': 'Феодосійська',
  'UA0118009': 'Грушівська',
  'UA0118021': 'Іллічівська',
  'UA0118023': 'Кіровська',
  'UA0118025': 'Коктебельська',
  'UA0118029': 'Краснофлотська',
  'UA0118027': 'Красногвардійська',
  'UA0118031': 'Льговська',
  'UA0118033': 'Міжріченська',
  'UA0118035': 'Морська',
  'UA0118037': 'Насипнівська',
  'UA0118039': 'Некрасовська',
  'UA0118041': 'Новосвітська',
  'UA0118043': 'Орджонікідзевська',
  'UA0118045': 'Партизанська',
  'UA0118047': 'Первомайська',
  'UA0118053': 'Прудівська',
  'UA0118051': 'Приморська',
  'UA0118049': 'Привітненська',
  'UA0118055': 'Пушкінська',
  'UA0118077': 'Щебетовська',
  'UA0118061': 'Сонячнодолинська',
  'UA0118059': 'Совєтська',
  'UA0118063': 'Старокримська',
  'UA0118065': 'Судацька',
  'UA0118057': 'Синицинська',
  'UA0118067': 'Токарєвська',
  'UA0118069': 'Урожайнівська',
  'UA0118005': 'Веселівська',
  'UA0118007': 'Владиславівська',
  'UA0118079': 'Яркополенська',
  'UA0118017': 'Завітненська',
  'UA0118015': 'Журавська',
  'UA0118019': 'Золотополенська',
  'UA0110001': 'Багерівська',
  'UA0110003': 'Батальненська',
  'UA0110005': 'Бєлінська',
  'UA0110051': 'Челядінівська',
  'UA0110053': 'Чистопільська',
  'UA0110011': 'Глазівська',
  'UA0110013': 'Горностаївська',
  'UA0110017': 'Іллічівська',
  'UA0110019': 'Калинівська',
  'UA0110021': 'Керченська',
  'UA0110023': 'Кіровська',
  'UA0110025': 'Красногірська',
  'UA0110027': 'Ленінська',
  'UA0110029': 'Ленінська',
  'UA0110031': 'Лугівська',
  'UA0110035': 'Марфівська',
  'UA0110033': 'Мар\'ївська',
  'UA0110037': 'Мисівська',
  'UA0110039': 'Новомиколаївська',
  'UA0110041': 'Октябрська',
  'UA0110043': 'Останінська',
  'UA0110045': 'Приозернівська',
  'UA0110047': 'Семисотська',
  'UA0110055': 'Щолкінська',
  'UA0110049': 'Уварівська',
  'UA0110009': 'Войковська',
  'UA0110007': 'Виноградненська',
  'UA0110015': 'Завітненська',
  'UA0112001': 'Абрикосівська',
  'UA0112003': 'Амурська',
  'UA0112071': 'Черновська',
  'UA0112011': 'Гришинська',
  'UA0112009': 'Гвардійська',
  'UA0112015': 'Калінінська',
  'UA0112017': 'Калінінська',
  'UA0112019': 'Клепинінська',
  'UA0112021': 'Колодязянська',
  'UA0112023': 'Кормівська',
  'UA0112025': 'Котельниківська',
  'UA0112027': 'Красногвардійська',
  'UA0112029': 'Краснознам\'янська',
  'UA0112031': 'Крестянівська',
  'UA0112033': 'Ленінська',
  'UA0112035': 'Мар\'янівська',
  'UA0112037': 'Найдьонівська',
  'UA0112039': 'Новопокровська',
  'UA0112041': 'Октябрська',
  'UA0112043': 'Октябрська',
  'UA0112045': 'Олександрівська',
  'UA0112047': 'Олексіївська',
  'UA0112049': 'Островська',
  'UA0112053': 'Первомайська',
  'UA0112055': 'Петрівська',
  'UA0112051': 'П\'ятихатська',
  'UA0112057': 'Полтавська',
  'UA0112059': 'Правдівська',
  'UA0112061': 'Рівнівська',
  'UA0112063': 'Сарибашівська',
  'UA0112065': 'Стахановська',
  'UA0112067': 'Степнівська',
  'UA0112069': 'Сусанінська',
  'UA0112005': 'Войковська',
  'UA0112007': 'Восходненська',
  'UA0112073': 'Янтарненська',
  'UA0112013': 'Зернівська',
  'UA0114001': 'Армянська',
  'UA0114003': 'Березівська',
  'UA0114005': 'Ботанічна',
  'UA0114007': 'Братська',
  'UA0114053': 'Чернишівська',
  'UA0114051': 'Філатівська',
  'UA0114015': 'Іллінська',
  'UA0114017': 'Ішунська',
  'UA0114019': 'Ковильнівська',
  'UA0114021': 'Красноармійська',
  'UA0114023': 'Красноперекопська',
  'UA0114025': 'Кукушкінська',
  'UA0114027': 'Магазинська',
  'UA0114029': 'Новопавлівська',
  'UA0114031': 'Новоселівська',
  'UA0114033': 'Орлівська',
  'UA0114035': 'Почетненська',
  'UA0114037': 'Роздольненська',
  'UA0114039': 'Руч\'ївська',
  'UA0114041': 'Серебрянська',
  'UA0114043': 'Славнівська',
  'UA0114045': 'Слов\'янська',
  'UA0114047': 'Совхозненська',
  'UA0114049': 'Суворовська',
  'UA0114011': 'Воїнська',
  'UA0114009': 'Вишнівська',
  'UA0114013': 'Зиминська',
  'UA0116043': 'Чистенська',
  'UA0116005': 'Добрівська',
  'UA0116007': 'Донська',
  'UA0116003': 'Гресівська',
  'UA0116001': 'Гвардійська',
  'UA0116011': 'Кольчугинська',
  'UA0116013': 'Мазанська',
  'UA0116019': 'Молодіжненська',
  'UA0116015': 'Миколаївська',
  'UA0116017': 'Мирнівська',
  'UA0116021': 'Новоандріївська',
  'UA0116023': 'Новоселівська',
  'UA0116027': 'Перовська',
  'UA0116025': 'Первомайська',
  'UA0116029': 'Пожарська',
  'UA0116031': 'Родниківська',
  'UA0116047': 'Шкільненська',
  'UA0116045': 'Широківська',
  'UA0116033': 'Сімферопольська',
  'UA0116035': 'Скворцівська',
  'UA0116037': 'Трудівська',
  'UA0116039': 'Укромнівська',
  'UA0116041': 'Урожайнівська',
  'UA0116009': 'Журавлівська',
  'UA0120001': 'Алупкинська',
  'UA0120003': 'Алуштинська',
  'UA0120029': 'Фороська',
  'UA0120005': 'Гаспринська',
  'UA0120007': 'Гурзуфська',
  'UA0120009': 'Ізобільненська',
  'UA0120011': 'Кореїзька',
  'UA0120013': 'Лівадійська',
  'UA0120015': 'Лучистівська',
  'UA0120017': 'Маломаяцька',
  'UA0120019': 'Малоріченська',
  'UA0120021': 'Масандрівська',
  'UA0120023': 'Партенітська',
  'UA0120025': 'Привітненська',
  'UA0120027': 'Сімеїзька',
  'UA0120031': 'Ялтинська',
  'UA0108077': 'Чорноморська',
  'UA0108011': 'Далеківська',
  'UA0108013': 'Добрушинська',
  'UA0108075': 'Фрунзенська',
  'UA0108009': 'Геройська',
  'UA0108021': 'Іванівська',
  'UA0108023': 'Кіровська',
  'UA0108025': 'Кольцовська',
  'UA0108027': 'Крайненська',
  'UA0108031': 'Красноярська',
  'UA0108029': 'Краснополянська',
  'UA0108033': 'Кримська',
  'UA0108035': 'Ліснівська',
  'UA0108037': 'Медведівська',
  'UA0108043': 'Міжводненська',
  'UA0108045': 'Молочненська',
  'UA0108039': 'Мирнівська',
  'UA0108041': 'Митяївська',
  'UA0108053': 'Новофедорівська',
  'UA0108047': 'Новоіванівська',
  'UA0108049': 'Новоозернівська',
  'UA0108051': 'Новосільська',
  'UA0108061': 'Охотниківська',
  'UA0108055': 'Окунівська',
  'UA0108057': 'Оленівська',
  'UA0108059': 'Оріхівська',
  'UA0108063': 'Ромашкінська',
  'UA0108065': 'Сакська',
  'UA0108079': 'Штормівська',
  'UA0108069': 'Стовпівська',
  'UA0108071': 'Суворовська',
  'UA0108067': 'Сизівська',
  'UA0108073': 'Уютненська',
  'UA0108001': 'Вересаєвська',
  'UA0108003': 'Веселівська',
  'UA0108007': 'Воробйовська',
  'UA0108005': 'Виноградівська',
  'UA0108015': 'Євпаторійська',
  'UA0108017': 'Заозерненська',
  'UA0108019': 'Зернівська',
  'UA7108001': 'Балаклеївська',
  'UA7108003': 'Березняківська',
  'UA7108005': 'Білозірська',
  'UA7108007': 'Бобрицька',
  'UA7108009': 'Будищенська',
  'UA7108049': 'Черкаська',
  'UA7108047': 'Червонослобідська',
  'UA7108051': 'Чигиринська',
  'UA7108011': 'Городищенська',
  'UA7108013': 'Кам\'янська',
  'UA7108015': 'Канівська',
  'UA7108017': 'Корсунь-Шевченківська',
  'UA7108019': 'Леськівська',
  'UA7108021': 'Ліплявська',
  'UA7108023': 'Медведівська',
  'UA7108027': 'Мліївська',
  'UA7108029': 'Мошнівська',
  'UA7108025': 'Михайлівська',
  'UA7108031': 'Набутівська',
  'UA7108033': 'Ротмістрівська',
  'UA7108035': 'Руськополянська',
  'UA7108037': 'Сагунівська',
  'UA7108039': 'Смілянська',
  'UA7108041': 'Степанецька',
  'UA7108043': 'Степанківська',
  'UA7108045': 'Тернівська',
  'UA7106001': 'Бабанська',
  'UA7106003': 'Баштечківська',
  'UA7106005': 'Буцька',
  'UA7106007': 'Дмитрушківська',
  'UA7106011': 'Іваньківська',
  'UA7106023': 'Христинівська',
  'UA7106013': 'Ладижинська',
  'UA7106015': 'Маньківська',
  'UA7106017': 'Монастирищенська',
  'UA7106019': 'Паланська',
  'UA7106021': 'Уманська',
  'UA7106009': 'Жашківська',
  'UA7104019': 'Чорнобаївська',
  'UA7104007': 'Драбівська',
  'UA7104005': 'Гельмязівська',
  'UA7104013': 'Іркліївська',
  'UA7104015': 'Новодмитрівська',
  'UA7104017': 'Піщанська',
  'UA7104021': 'Шрамківська',
  'UA7104001': 'Великохутірська',
  'UA7104003': 'Вознесенська',
  'UA7104009': 'Золотоніська',
  'UA7104011': 'Зорівська',
  'UA7102001': 'Бужанська',
  'UA7102015': 'Катеринопільська',
  'UA7102017': 'Лип\'янська',
  'UA7102019': 'Лисянська',
  'UA7102021': 'Матусівська',
  'UA7102023': 'Мокрокалигірська',
  'UA7102025': 'Селищенська',
  'UA7102031': 'Шевченківська',
  'UA7102033': 'Шполянська',
  'UA7102027': 'Стеблівська',
  'UA7102029': 'Тальнівська',
  'UA7102003': 'Ватутінська',
  'UA7102007': 'Вільшанська',
  'UA7102009': 'Водяницька',
  'UA7102005': 'Виноградська',
  'UA7102011': 'Єрківська',
  'UA7102013': 'Звенигородська',
  'UA7410001': 'Березнянська',
  'UA7410039': 'Чернігівська',
  'UA7410007': 'Деснянська',
  'UA7410009': 'Добрянська',
  'UA7410003': 'Гончарівська',
  'UA7410005': 'Городнянська',
  'UA7410011': 'Іванівська',
  'UA7410017': 'Кіптівська',
  'UA7410019': 'Козелецька',
  'UA7410021': 'Куликівська',
  'UA7410013': 'Киїнська',
  'UA7410015': 'Киселівська',
  'UA7410023': 'Любецька',
  'UA7410025': 'Михайло-Коцюбинська',
  'UA7410027': 'Новобілоуська',
  'UA7410029': 'Олишівська',
  'UA7410031': 'Остерська',
  'UA7410033': 'Ріпкинська',
  'UA7410035': 'Седнівська',
  'UA7410037': 'Тупичівська',
  'UA7402009': 'Холминська',
  'UA7402001': 'Корюківська',
  'UA7402003': 'Менська',
  'UA7402005': 'Сновська',
  'UA7402007': 'Сосницька',
  'UA7404003': 'Бахмацька',
  'UA7404001': 'Батуринська',
  'UA7404005': 'Бобровицька',
  'UA7404007': 'Борзнянська',
  'UA7404013': 'Дмитрівська',
  'UA7404015': 'Комарівська',
  'UA7404017': 'Крутівська',
  'UA7404019': 'Лосинівська',
  'UA7404021': 'Макіївська',
  'UA7404023': 'Мринська',
  'UA7404025': 'Ніжинська',
  'UA7404029': 'Носівська',
  'UA7404027': 'Новобасанська',
  'UA7404031': 'Плисківська',
  'UA7404033': 'Талалаївська',
  'UA7404009': 'Вертіївська',
  'UA7404011': 'Височанська',
  'UA7406001': 'Коропська',
  'UA7406003': 'Новгород-Сіверська',
  'UA7406005': 'Понорницька',
  'UA7406007': 'Семенівська',
  'UA7408003': 'Ічнянська',
  'UA7408005': 'Ладанська',
  'UA7408007': 'Линовицька',
  'UA7408009': 'Малодівицька',
  'UA7408011': 'Парафіївська',
  'UA7408013': 'Прилуцька',
  'UA7408015': 'Срібнянська',
  'UA7408017': 'Сухополов\'янська',
  'UA7408019': 'Талалаївська',
  'UA7408001': 'Варвинська',
  'UA7408021': 'Яблунівська',
  'UA7306001': 'Боянська',
  'UA7306059': 'Чагорська',
  'UA7306061': 'Чернівецька',
  'UA7306063': 'Чудейська',
  'UA7306013': 'Герцаївська',
  'UA7306015': 'Глибоцька',
  'UA7306017': 'Горішньошеровецька',
  'UA7306021': 'Кадубовецька',
  'UA7306023': 'Кам\'янецька',
  'UA7306025': 'Кам\'янська',
  'UA7306027': 'Карапчівська',
  'UA7306029': 'Кіцманська',
  'UA7306031': 'Кострижівська',
  'UA7306033': 'Красноїльська',
  'UA7306035': 'Магальська',
  'UA7306037': 'Мамаївська',
  'UA7306039': 'Неполоковецька',
  'UA7306041': 'Новоселицька',
  'UA7306043': 'Острицька',
  'UA7306045': 'Петровецька',
  'UA7306047': 'Ставчанська',
  'UA7306049': 'Сторожинецька',
  'UA7306051': 'Сучевенська',
  'UA7306053': 'Тарашанська',
  'UA7306055': 'Тереблеченська',
  'UA7306057': 'Топорівська',
  'UA7306003': 'Ванчиковецька',
  'UA7306005': 'Великокучурівська',
  'UA7306007': 'Веренчанська',
  'UA7306009': 'Вікнянська',
  'UA7306011': 'Волоківська',
  'UA7306065': 'Юрковецька',
  'UA7306019': 'Заставнівська',
  'UA7304003': 'Кельменецька',
  'UA7304019': 'Хотинська',
  'UA7304005': 'Клішковецька',
  'UA7304007': 'Лівинецька',
  'UA7304009': 'Мамалигівська',
  'UA7304011': 'Недобоївська',
  'UA7304013': 'Новодністровська',
  'UA7304015': 'Рукшинська',
  'UA7304017': 'Сокирянська',
  'UA7304001': 'Вашковецька',
  'UA7302001': 'Банилівська',
  'UA7302003': 'Берегометська',
  'UA7302005': 'Брусницька',
  'UA7302011': 'Конятинська',
  'UA7302013': 'Путильська',
  'UA7302015': 'Селятинська',
  'UA7302017': 'Усть-Путильська',
  'UA7302007': 'Вашківецька',
  'UA7302009': 'Вижницька',
  'UA1202033': 'Чумаківська',
  'UA1202001': 'Дніпровська',
  'UA1202003': 'Китайгородська',
  'UA1202007': 'Ляшківська',
  'UA1202005': 'Любимівська',
  'UA1202011': 'Могилівська',
  'UA1202009': 'Миколаївська',
  'UA1202013': 'Новоолександрівська',
  'UA1202015': 'Новопокровська',
  'UA1202017': 'Обухівська',
  'UA1202019': 'Петриківська',
  'UA1202021': 'Підгородненська',
  'UA1202025': 'Слобожанська',
  'UA1202027': 'Солонянська',
  'UA1202029': 'Сурсько-Литовська',
  'UA1202023': 'Святовасилівська',
  'UA1202031': 'Царичанська',
  'UA1204001': 'Божедарівська',
  'UA1204015': 'Кам\'янська',
  'UA1204017': 'Криничанська',
  'UA1204019': 'Лихівська',
  'UA1204021': 'П\'ятихатська',
  'UA1204023': 'Саксаганська',
  'UA1204003': 'Верхівцівська',
  'UA1204005': 'Верхньодніпровська',
  'UA1204009': 'Вільногірська',
  'UA1204007': 'Вишнівська',
  'UA1204013': 'Затишнянська',
  'UA1204011': 'Жовтоводська',
  'UA1206001': 'Апостолівська',
  'UA1206011': 'Девладівська',
  'UA1206005': 'Глеюватська',
  'UA1206007': 'Гречаноподівська',
  'UA1206009': 'Грушівська',
  'UA1206015': 'Карпівська',
  'UA1206017': 'Криворізька',
  'UA1206019': 'Лозуватська',
  'UA1206023': 'Новолатівська',
  'UA1206025': 'Новопільська',
  'UA1206021': 'Нивотрудівська',
  'UA1206029': 'Широківська',
  'UA1206027': 'Софіївська',
  'UA1206003': 'Вакулівська',
  'UA1206013': 'Зеленодольська',
  'UA1208015': 'Червоногригорівська',
  'UA1208001': 'Марганецька',
  'UA1208003': 'Мирівська',
  'UA1208005': 'Нікопольська',
  'UA1208007': 'Першотравневська',
  'UA1208009': 'Покровська',
  'UA1208011': 'Покровська',
  'UA1208013': 'Томаківська',
  'UA1210013': 'Черкаська',
  'UA1210015': 'Чернеччинська',
  'UA1210001': 'Губиниська',
  'UA1210003': 'Личківська',
  'UA1210005': 'Магдалинівська',
  'UA1210007': 'Новомосковська',
  'UA1210009': 'Перещепинська',
  'UA1210011': 'Піщанська',
  'UA1212001': 'Богданівська',
  'UA1212005': 'Межиріцька',
  'UA1212007': 'Павлоградська',
  'UA1212009': 'Тернівська',
  'UA1212011': 'Троїцька',
  'UA1212003': 'Вербківська',
  'UA1212013': 'Юр\'ївська',
  'UA1214001': 'Брагинівська',
  'UA1214007': 'Дубовиківська',
  'UA1214011': 'Іларіонівська',
  'UA1214013': 'Маломихайлівська',
  'UA1214015': 'Межівська',
  'UA1214017': 'Миколаївська',
  'UA1214019': 'Новопавлівська',
  'UA1214021': 'Першотравенська',
  'UA1214023': 'Петропавлівська',
  'UA1214025': 'Покровська',
  'UA1214027': 'Раївська',
  'UA1214029': 'Роздорська',
  'UA1214033': 'Славгородська',
  'UA1214035': 'Слов\'янська',
  'UA1214031': 'Синельниківська',
  'UA1214037': 'Українська',
  'UA1214003': 'Васильківська',
  'UA1214005': 'Великомихайлівська',
  'UA1214009': 'Зайцівська',
  'UA1402001': 'Бахмутська',
  'UA1402013': 'Часовоярська',
  'UA1402007': 'Сіверська',
  'UA1402009': 'Соледарська',
  'UA1402005': 'Світлодарська',
  'UA1402011': 'Торецька',
  'UA1402003': 'Званівська',
  'UA1408001': 'Амвросіївська',
  'UA1408003': 'Донецька',
  'UA1408005': 'Іловайська',
  'UA1408009': 'Харцизька',
  'UA1408007': 'Макіївська',
  'UA1408011': 'Ясинуватська',
  'UA1406015': 'Чистяківська',
  'UA1406005': 'Дебальцівська',
  'UA1406003': 'Горлівська',
  'UA1406013': 'Хрестівська',
  'UA1406017': 'Шахтарська',
  'UA1406011': 'Сніжнянська',
  'UA1406001': 'Вуглегірська',
  'UA1406007': 'Єнакієвська',
  'UA1406009': 'Жданівська',
  'UA1410001': 'Бойківська',
  'UA1410003': 'Докучаєвська',
  'UA1410005': 'Кальміуська',
  'UA1410007': 'Новоазовська',
  'UA1410009': 'Старобешівська',
  'UA1412001': 'Андріївська',
  'UA1412023': 'Черкаська',
  'UA1412003': 'Дружківська',
  'UA1412005': 'Іллінівська',
  'UA1412007': 'Костянтинівська',
  'UA1412009': 'Краматорська',
  'UA1412011': 'Лиманська',
  'UA1412013': 'Миколаївська',
  'UA1412015': 'Новодонецька',
  'UA1412017': 'Олександрівська',
  'UA1412021': 'Слов\'янська',
  'UA1412019': 'Святогірська',
  'UA1414001': 'Кальчицька',
  'UA1414003': 'Мангушська',
  'UA1414005': 'Маріупольська',
  'UA1414007': 'Нікольська',
  'UA1414009': 'Сартанська',
  'UA1416001': 'Авдіївська',
  'UA1416003': 'Білозерська',
  'UA1416007': 'Добропільська',
  'UA1416005': 'Гродівська',
  'UA1416009': 'Криворізька',
  'UA1416011': 'Курахівська',
  'UA1416013': 'Мар\'їнська',
  'UA1416015': 'Мирноградська',
  'UA1416017': 'Новогродівська',
  'UA1416019': 'Очеретинська',
  'UA1416021': 'Покровська',
  'UA1416023': 'Селидівська',
  'UA1416027': 'Шахівська',
  'UA1416025': 'Удачненська',
  'UA1404015': 'Хлібодарівська',
  'UA1404007': 'Комарська',
  'UA1404009': 'Мирненська',
  'UA1404011': 'Ольгинська',
  'UA1404013': 'Старомлинівська',
  'UA1404001': 'Великоновосілківська',
  'UA1404003': 'Волноваська',
  'UA1404005': 'Вугледарська',
  'UA2604001': 'Більшівцівська',
  'UA2604003': 'Богородчанська',
  'UA2604005': 'Букачівська',
  'UA2604007': 'Бурштинська',
  'UA2604013': 'Дубовецька',
  'UA2604011': 'Дзвиняцька',
  'UA2604009': 'Галицька',
  'UA2604019': 'Івано-Франківська',
  'UA2604021': 'Лисецька',
  'UA2604023': 'Обертинська',
  'UA2604025': 'Олешанська',
  'UA2604027': 'Рогатинська',
  'UA2604029': 'Солотвинська',
  'UA2604031': 'Старобогородчанська',
  'UA2604035': 'Тлумацька',
  'UA2604033': 'Тисменицька',
  'UA2604037': 'Угринівська',
  'UA2604039': 'Ямницька',
  'UA2604015': 'Єзупільська',
  'UA2604017': 'Загвіздянська',
  'UA2606001': 'Болехівська',
  'UA2606003': 'Брошнів-Осадська',
  'UA2606013': 'Долинська',
  'UA2606015': 'Дубівська',
  'UA2606017': 'Калуська',
  'UA2606019': 'Новицька',
  'UA2606021': 'Перегінська',
  'UA2606023': 'Рожнятівська',
  'UA2606025': 'Спаська',
  'UA2606005': 'Верхнянська',
  'UA2606011': 'Войнилівська',
  'UA2606007': 'Вигодська',
  'UA2606009': 'Витвицька',
  'UA2608025': 'Чернелицька',
  'UA2608003': 'Городенківська',
  'UA2608001': 'Гвіздецька',
  'UA2608007': 'Коломийська',
  'UA2608009': 'Коршівська',
  'UA2608011': 'Матеївецька',
  'UA2608013': 'Нижньовербізька',
  'UA2608015': 'Отинійська',
  'UA2608017': 'Печеніжинська',
  'UA2608021': 'П\'ядицька',
  'UA2608019': 'Підгайчиківська',
  'UA2608023': 'Снятинська',
  'UA2608005': 'Заболотівська',
  'UA2610001': 'Косівська',
  'UA2610003': 'Космацька',
  'UA2610005': 'Кутська',
  'UA2610007': 'Рожнівська',
  'UA2610009': 'Яблунівська',
  'UA2612003': 'Делятинська',
  'UA2612005': 'Ланчинська',
  'UA2612007': 'Надвірнянська',
  'UA2612009': 'Пасічнянська',
  'UA2612011': 'Переріслянська',
  'UA2612013': 'Поляницька',
  'UA2612001': 'Ворохтянська',
  'UA2612015': 'Яремчанська',
  'UA2602001': 'Білоберізька',
  'UA2602003': 'Верховинська',
  'UA2602005': 'Зеленська',
  'UA6302001': 'Богодухівська',
  'UA6302007': 'Коломацька',
  'UA6302009': 'Краснокутська',
  'UA6302003': 'Валківська',
  'UA6302005': 'Золочівська',
  'UA6314015': 'Чкаловська',
  'UA6314017': 'Чугуївська',
  'UA6314005': 'Малинівська',
  'UA6314007': 'Новопокровська',
  'UA6314009': 'Печенізька',
  'UA6314011': 'Слобожанська',
  'UA6314013': 'Старосалтівська',
  'UA6314001': 'Вовчанська',
  'UA6314003': 'Зміївська',
  'UA6304001': 'Балаклійська',
  'UA6304003': 'Барвінківська',
  'UA6304005': 'Борівська',
  'UA6304007': 'Донецька',
  'UA6304009': 'Ізюмська',
  'UA6304011': 'Куньєвська',
  'UA6304013': 'Оскільська',
  'UA6304015': 'Савинська',
  'UA6312001': 'Безлюдівська',
  'UA6312007': 'Дергачівська',
  'UA6312027': 'Харківська',
  'UA6312011': 'Люботинська',
  'UA6312009': 'Липецька',
  'UA6312013': 'Малоданилівська',
  'UA6312015': 'Мереф\'янська',
  'UA6312017': 'Нововодолазька',
  'UA6312021': 'Пісочинська',
  'UA6312019': 'Південноміська',
  'UA6312023': 'Роганська',
  'UA6312025': 'Солоницівська',
  'UA6312029': 'Циркунівська',
  'UA6312005': 'Вільхівська',
  'UA6312003': 'Височанська',
  'UA6306003': 'Кегичівська',
  'UA6306005': 'Красноградська',
  'UA6306007': 'Наталинська',
  'UA6306009': 'Сахновщинська',
  'UA6306011': 'Старовірівська',
  'UA6306001': 'Зачепилівська',
  'UA6308005': 'Дворічанська',
  'UA6308007': 'Кіндрашівська',
  'UA6308009': 'Куп\'янська',
  'UA6308011': 'Курилівська',
  'UA6308013': 'Петропавлівська',
  'UA6308015': 'Шевченківська',
  'UA6308001': 'Великобурлуцька',
  'UA6308003': 'Вільхуватська',
  'UA6310001': 'Біляївська',
  'UA6310003': 'Близнюківська',
  'UA6310005': 'Лозівська',
  'UA6310007': 'Олексіївська',
  'UA6310009': 'Первомайська',
  'UA6502001': 'Бериславська',
  'UA6502003': 'Борозенська',
  'UA6502009': 'Калинівська',
  'UA6502011': 'Кочубеївська',
  'UA6502013': 'Милівська',
  'UA6502017': 'Новоолександрівська',
  'UA6502019': 'Новорайська',
  'UA6502015': 'Нововоронцовська',
  'UA6502021': 'Тягинська',
  'UA6502005': 'Великоолександрівська',
  'UA6502007': 'Високопільська',
  'UA6504001': 'Генічеська',
  'UA6504003': 'Іванівська',
  'UA6504007': 'Новотроїцька',
  'UA6504005': 'Нижньосірогозька',
  'UA6506001': 'Асканія-Нова',
  'UA6506029': 'Чаплинська',
  'UA6506007': 'Горностаївська',
  'UA6506011': 'Каховська',
  'UA6506027': 'Хрестівська',
  'UA6506013': 'Костянтинівська',
  'UA6506015': 'Любимівська',
  'UA6506017': 'Новокаховська',
  'UA6506019': 'Присиваська',
  'UA6506021': 'Рубанівська',
  'UA6506025': 'Таврійська',
  'UA6506023': 'Тавричанська',
  'UA6506003': 'Великолепетиська',
  'UA6506005': 'Верхньорогачицька',
  'UA6506009': 'Зеленопідська',
  'UA6510001': 'Білозерська',
  'UA6510017': 'Чорнобаївська',
  'UA6510007': 'Дар\'ївська',
  'UA6510015': 'Херсонська',
  'UA6510009': 'Музиківська',
  'UA6510011': 'Олешківська',
  'UA6510013': 'Станіславська',
  'UA6510003': 'Великокопанівська',
  'UA6510005': 'Виноградівська',
  'UA6510019': 'Ювілейна',
  'UA6508001': 'Бехтерська',
  'UA6508017': 'Чулаківська',
  'UA6508005': 'Долматівська',
  'UA6508003': 'Голопристанська',
  'UA6508007': 'Каланчацька',
  'UA6508009': 'Лазурненська',
  'UA6508011': 'Мирненська',
  'UA6508013': 'Новомиколаївська',
  'UA6508015': 'Скадовська',
  'UA6802029': 'Чемеровецька',
  'UA6802005': 'Дунаєвецька',
  'UA6802001': 'Гуківська',
  'UA6802003': 'Гуменецька',
  'UA6802011': 'Кам\'янець-Подільська',
  'UA6802013': 'Китайгородська',
  'UA6802015': 'Маківська',
  'UA6802017': 'Новодунаєвецька',
  'UA6802019': 'Новоушицька',
  'UA6802021': 'Орининська',
  'UA6802023': 'Слобідсько-Кульчієвецька',
  'UA6802025': 'Смотрицька',
  'UA6802027': 'Староушицька',
  'UA6802009': 'Закупненська',
  'UA6802007': 'Жванецька',
  'UA6804001': 'Антонінська',
  'UA6804049': 'Чорноострівська',
  'UA6804015': 'Деражнянська',
  'UA6804013': 'Городоцька',
  'UA6804011': 'Гвардійська',
  'UA6804047': 'Хмельницька',
  'UA6804021': 'Красилівська',
  'UA6804023': 'Летичівська',
  'UA6804025': 'Лісовогринівецька',
  'UA6804027': 'Меджибізька',
  'UA6804029': 'Миролюбненська',
  'UA6804031': 'Наркевицька',
  'UA6804033': 'Розсошанська',
  'UA6804035': 'Сатанівська',
  'UA6804051': 'Щиборівська',
  'UA6804037': 'Солобковецька',
  'UA6804039': 'Старокостянтинівська',
  'UA6804041': 'Староостропільська',
  'UA6804043': 'Старосинявська',
  'UA6804045': 'Теофіпольська',
  'UA6804003': 'Війтовецька',
  'UA6804005': 'Віньковецька',
  'UA6804009': 'Волочиська',
  'UA6804007': 'Вовковинецька',
  'UA6804053': 'Ярмолинецька',
  'UA6804017': 'Заслучненська',
  'UA6804019': 'Зіньківська',
  'UA6806001': 'Берездівська',
  'UA6806003': 'Білогірська',
  'UA6806005': 'Ганнопільська',
  'UA6806007': 'Грицівська',
  'UA6806009': 'Ізяславська',
  'UA6806011': 'Крупецька',
  'UA6806013': 'Ленковецька',
  'UA6806015': 'Михайлюцька',
  'UA6806017': 'Нетішинська',
  'UA6806019': 'Плужненська',
  'UA6806021': 'Полонська',
  'UA6806023': 'Понінківська',
  'UA6806025': 'Сахновецька',
  'UA6806033': 'Шепетівська',
  'UA6806027': 'Славутська',
  'UA6806029': 'Судилківська',
  'UA6806031': 'Улашанівська',
  'UA6806035': 'Ямпільська',
  'UA3502001': 'Благовіщенська',
  'UA3502005': 'Гайворонська',
  'UA3502007': 'Голованівська',
  'UA3502011': 'Надлацька',
  'UA3502013': 'Новоархангельська',
  'UA3502015': 'Перегонівська',
  'UA3502017': 'Підвисоцька',
  'UA3502019': 'Побузька',
  'UA3502003': 'Вільшанська',
  'UA3502009': 'Заваллівська',
  'UA3504001': 'Аджамська',
  'UA3504003': 'Бобринецька',
  'UA3504009': 'Дмитрівська',
  'UA3504011': 'Долинська',
  'UA3504007': 'Гурівська',
  'UA3504015': 'Катеринівська',
  'UA3504017': 'Кетрисанівська',
  'UA3504019': 'Компаніївська',
  'UA3504021': 'Кропивницька',
  'UA3504023': 'Новгородківська',
  'UA3504025': 'Олександрівська',
  'UA3504027': 'Первозванівська',
  'UA3504029': 'Соколівська',
  'UA3504031': 'Суботцівська',
  'UA3504033': 'Устинівська',
  'UA3504005': 'Великосеверинівська',
  'UA3504013': 'Знам\'янська',
  'UA3506005': 'Добровеличківська',
  'UA3506001': 'Ганнівська',
  'UA3506003': 'Глодоська',
  'UA3506009': 'Маловисківська',
  'UA3506011': 'Мар\'янівська',
  'UA3506013': 'Новомиргородська',
  'UA3506015': 'Новоукраїнська',
  'UA3506017': 'Піщанобрідська',
  'UA3506019': 'Помічнянська',
  'UA3506021': 'Рівнянська',
  'UA3506023': 'Смолінська',
  'UA3506025': 'Тишківська',
  'UA3506007': 'Злинська',
  'UA3508003': 'Новопразька',
  'UA3508005': 'Олександрійська',
  'UA3508007': 'Онуфріївська',
  'UA3508009': 'Пантаївська',
  'UA3508011': 'Петрівська',
  'UA3508013': 'Попельнастівська',
  'UA3508015': 'Приютівська',
  'UA3508017': 'Світловодська',
  'UA3508001': 'Великоандрусівська',
  'UA8000000': 'Київ',
  'UA3202001': 'Білоцерківська',
  'UA3202025': 'Фурсівська',
  'UA3202005': 'Гребінківська',
  'UA3202007': 'Ковалівська',
  'UA3202009': 'Маловільшанська',
  'UA3202011': 'Медвинська',
  'UA3202013': 'Рокитнянська',
  'UA3202015': 'Сквирська',
  'UA3202017': 'Ставищенська',
  'UA3202019': 'Таращанська',
  'UA3202021': 'Тетіївська',
  'UA3202023': 'Узинська',
  'UA3202003': 'Володарська',
  'UA3204001': 'Бориспільська',
  'UA3204007': 'Дівичківська',
  'UA3204005': 'Гірська',
  'UA3204011': 'Переяславська',
  'UA3204013': 'Пристолична',
  'UA3204015': 'Студениківська',
  'UA3204017': 'Ташанська',
  'UA3204019': 'Циблівська',
  'UA3204003': 'Вороньківська',
  'UA3204021': 'Яготинська',
  'UA3204009': 'Золочівська',
  'UA3206001': 'Баришівська',
  'UA3206003': 'Березанська',
  'UA3206005': 'Броварська',
  'UA3206013': 'Калинівська',
  'UA3206015': 'Калитянська',
  'UA3206007': 'Великодимерська',
  'UA3206009': 'Зазимська',
  'UA3206011': 'Згурівська',
  'UA3208001': 'Білогородська',
  'UA3208003': 'Бородянська',
  'UA3208005': 'Борщагівська',
  'UA3208007': 'Бучанська',
  'UA3208013': 'Дмитрівська',
  'UA3208011': 'Гостомельська',
  'UA3208015': 'Ірпінська',
  'UA3208017': 'Коцюбинська',
  'UA3208019': 'Макарівська',
  'UA3208021': 'Немішаївська',
  'UA3208023': 'Пісківська',
  'UA3208009': 'Вишнева',
  'UA3200000': 'Чорнобильська зона відчуження',
  'UA3214003': 'Боярська',
  'UA3214001': 'Бишівська',
  'UA3214017': 'Чабанівська',
  'UA3214015': 'Фастівська',
  'UA3214005': 'Гатненська',
  'UA3214007': 'Глевахівська',
  'UA3214009': 'Калинівська',
  'UA3214011': 'Кожанська',
  'UA3214013': 'Томашівська',
  'UA3212001': 'Богуславська',
  'UA3212017': 'Феодосіївська',
  'UA3212005': 'Кагарлицька',
  'UA3212007': 'Козинська',
  'UA3212009': 'Миронівська',
  'UA3212011': 'Обухівська',
  'UA3212013': 'Ржищівська',
  'UA3212015': 'Українська',
  'UA3212003': 'Васильківська',
  'UA3210003': 'Димерська',
  'UA3210005': 'Іванківська',
  'UA3210007': 'Петрівська',
  'UA3210009': 'Пірнівська',
  'UA3210011': 'Поліська',
  'UA3210013': 'Славутицька',
  'UA3210001': 'Вишгородська',
  'UA4402001': 'Алчевська',
  'UA4402005': 'Кадіївська',
  'UA4402003': 'Зимогір\'ївська',
  'UA4404001': 'Довжанська',
  'UA4404003': 'Сорокинська',
  'UA4406001': 'Луганська',
  'UA4406003': 'Лутугинська',
  'UA4406005': 'Молодогвардійська',
  'UA4408001': 'Антрацитівська',
  'UA4408005': 'Хрустальненська',
  'UA4408003': 'Ровеньківська',
  'UA4416003': 'Новоайдарська',
  'UA4416001': 'Нижньотеплівська',
  'UA4416009': 'Щастинська',
  'UA4416007': 'Широківська',
  'UA4416005': 'Станично-Луганська',
  'UA4412001': 'Гірська',
  'UA4412003': 'Кремінська',
  'UA4412005': 'Лисичанська',
  'UA4412007': 'Попаснянська',
  'UA4412009': 'Рубіжанська',
  'UA4412011': 'Сєвєродонецька',
  'UA4414003': 'Білолуцька',
  'UA4414001': 'Біловодська',
  'UA4414013': 'Чмирівська',
  'UA4414005': 'Марківська',
  'UA4414007': 'Міловська',
  'UA4414009': 'Новопсковська',
  'UA4414015': 'Шульгинська',
  'UA4414011': 'Старобільська',
  'UA4410001': 'Білокуракинська',
  'UA4410003': 'Коломийчиська',
  'UA4410005': 'Красноріченська',
  'UA4410007': 'Лозно-Олександрівська',
  'UA4410009': 'Нижньодуванська',
  'UA4410011': 'Сватівська',
  'UA4410013': 'Троїцька',
  'UA4612001': 'Белзька',
  'UA4612013': 'Червоноградська',
  'UA4612005': 'Добротвірська',
  'UA4612007': 'Лопатинська',
  'UA4612009': 'Радехівська',
  'UA4612011': 'Сокальська',
  'UA4612003': 'Великомостівська',
  'UA4602001': 'Бориславська',
  'UA4602003': 'Дрогобицька',
  'UA4602005': 'Меденицька',
  'UA4602007': 'Східницька',
  'UA4602009': 'Трускавецька',
  'UA4606001': 'Бібрська',
  'UA4606009': 'Давидівська',
  'UA4606011': 'Добросинсько-Магерівська',
  'UA4606005': 'Глинянська',
  'UA4606007': 'Городоцька',
  'UA4606019': 'Кам\'янка-Бузька',
  'UA4606021': 'Комарнівська',
  'UA4606023': 'Куликівська',
  'UA4606025': 'Львівська',
  'UA4606027': 'Мурованська',
  'UA4606029': 'Новояричівська',
  'UA4606031': 'Оброшинська',
  'UA4606033': 'Перемишлянська',
  'UA4606035': 'Підберізцівська',
  'UA4606037': 'Пустомитівська',
  'UA4606039': 'Рава-Руська',
  'UA4606045': 'Щирецька',
  'UA4606041': 'Сокільницька',
  'UA4606043': 'Солонківська',
  'UA4606003': 'Великолюбінська',
  'UA4606013': 'Жовківська',
  'UA4606015': 'Жовтанецька',
  'UA4606017': 'Зимноводівська',
  'UA4608001': 'Бісковицька',
  'UA4608003': 'Боринська',
  'UA4608005': 'Добромильська',
  'UA4608021': 'Хирівська',
  'UA4608007': 'Новокалинівська',
  'UA4608009': 'Ралівська',
  'UA4608011': 'Рудківська',
  'UA4608013': 'Самбірська',
  'UA4608015': 'Старосамбірська',
  'UA4608017': 'Стрілківська',
  'UA4608019': 'Турківська',
  'UA4610001': 'Гніздичівська',
  'UA4610003': 'Грабовецько-Дулібівська',
  'UA4610027': 'Ходорівська',
  'UA4610009': 'Козівська',
  'UA4610013': 'Моршинська',
  'UA4610011': 'Миколаївська',
  'UA4610015': 'Новороздільська',
  'UA4610017': 'Розвадівська',
  'UA4610019': 'Сколівська',
  'UA4610021': 'Славська',
  'UA4610023': 'Стрийська',
  'UA4610025': 'Тростянецька',
  'UA4610007': 'Журавненська',
  'UA4610005': 'Жидачівська',
  'UA4614001': 'Івано-Франківська',
  'UA4614003': 'Мостиська',
  'UA4614005': 'Новояворівська',
  'UA4614009': 'Шегинівська',
  'UA4614007': 'Судововишнянська',
  'UA4614011': 'Яворівська',
  'UA4604001': 'Бродівська',
  'UA4604003': 'Буська',
  'UA4604009': 'Красненська',
  'UA4604011': 'Підкамінська',
  'UA4604013': 'Поморянська',
  'UA4604005': 'Заболотцівська',
  'UA4604007': 'Золочівська',
  'UA4802001': 'Баштанська',
  'UA4802003': 'Березнегуватська',
  'UA4802009': 'Горохівська',
  'UA4802011': 'Інгульська',
  'UA4802013': 'Казанківська',
  'UA4802015': 'Новобузька',
  'UA4802017': 'Привільненська',
  'UA4802023': 'Широківська',
  'UA4802019': 'Снігурівська',
  'UA4802021': 'Софіївська',
  'UA4802005': 'Вільнозапорізька',
  'UA4802007': 'Володимирівська',
  'UA4806001': 'Березанська',
  'UA4806035': 'Чорноморська',
  'UA4806007': 'Галицинівська',
  'UA4806009': 'Коблівська',
  'UA4806011': 'Костянтинівська',
  'UA4806013': 'Куцурубська',
  'UA4806017': 'Мішково-Погорілівська',
  'UA4806015': 'Миколаївська',
  'UA4806019': 'Нечаянська',
  'UA4806021': 'Новоодеська',
  'UA4806025': 'Очаківська',
  'UA4806023': 'Ольшанська',
  'UA4806027': 'Первомайська',
  'UA4806029': 'Радсадівська',
  'UA4806037': 'Шевченківська',
  'UA4806031': 'Степівська',
  'UA4806033': 'Сухоєланецька',
  'UA4806003': 'Веснянська',
  'UA4806005': 'Воскресенська',
  'UA4808001': 'Арбузинська',
  'UA4808003': 'Благодатненська',
  'UA4808007': 'Кам\'яномостівська',
  'UA4808009': 'Кривоозерська',
  'UA4808011': 'Мигіївська',
  'UA4808013': 'Первомайська',
  'UA4808015': 'Синюхинобрідська',
  'UA4808005': 'Врадіївська',
  'UA4804001': 'Братська',
  'UA4804003': 'Бузька',
  'UA4804009': 'Доманівська',
  'UA4804011': 'Дорошівська',
  'UA4804015': 'Мостівська',
  'UA4804017': 'Новомар\'ївська',
  'UA4804019': 'Олександрівська',
  'UA4804021': 'Прибужанівська',
  'UA4804023': 'Прибузька',
  'UA4804005': 'Веселинівська',
  'UA4804007': 'Вознесенська',
  'UA4804013': 'Єланецька',
  'UA4804025': 'Южноукраїнська',
  'UA5102001': 'Андрієво-Іванівська',
  'UA5102003': 'Березівська',
  'UA5102029': 'Чогодарівська',
  'UA5102009': 'Іванівська',
  'UA5102011': 'Коноплянська',
  'UA5102013': 'Курісовська',
  'UA5102015': 'Миколаївська',
  'UA5102017': 'Новокальчевська',
  'UA5102019': 'Петровірівська',
  'UA5102021': 'Раухівська',
  'UA5102023': 'Розквітівська',
  'UA5102031': 'Ширяївська',
  'UA5102025': 'Старомаяківська',
  'UA5102027': 'Стрюківська',
  'UA5102005': 'Великобуялицька',
  'UA5102007': 'Знам\'янська',
  'UA5104001': 'Білгород-Дністровська',
  'UA5104003': 'Дивізійська',
  'UA5104005': 'Кароліно-Бугазька',
  'UA5104007': 'Кулевчанська',
  'UA5104009': 'Лиманська',
  'UA5104011': 'Маразліївська',
  'UA5104013': 'Мологівська',
  'UA5104015': 'Петропавлівська',
  'UA5104017': 'Плахтіївська',
  'UA5104019': 'Саратська',
  'UA5104021': 'Сергіївська',
  'UA5104031': 'Шабівська',
  'UA5104023': 'Старокозацька',
  'UA5104025': 'Татарбунарська',
  'UA5104027': 'Тузлівська',
  'UA5104029': 'Успенівська',
  'UA5106001': 'Арцизька',
  'UA5106003': 'Болградська',
  'UA5106005': 'Бородінська',
  'UA5106009': 'Городненська',
  'UA5106011': 'Криничненська',
  'UA5106013': 'Кубейська',
  'UA5106015': 'Павлівська',
  'UA5106017': 'Тарутинська',
  'UA5106019': 'Теплицька',
  'UA5106007': 'Василівська',
  'UA5108003': 'Ізмаїльська',
  'UA5108005': 'Кілійська',
  'UA5108007': 'Ренійська',
  'UA5108009': 'Саф\'янівська',
  'UA5108011': 'Суворовська',
  'UA5108001': 'Вилківська',
  'UA5110001': 'Авангардівська',
  'UA5110003': 'Біляївська',
  'UA5110037': 'Чорноморська',
  'UA5110039': 'Чорноморська',
  'UA5110015': 'Дачненська',
  'UA5110013': 'Дальницька',
  'UA5110017': 'Доброславська',
  'UA5110035': 'Фонтанська',
  'UA5110019': 'Красносільська',
  'UA5110021': 'Маяківська',
  'UA5110023': 'Нерубайська',
  'UA5110027': 'Одеська',
  'UA5110025': 'Овідіопольська',
  'UA5110029': 'Таїровська',
  'UA5110031': 'Теплодарська',
  'UA5110033': 'Усатівська',
  'UA5110005': 'Великодальницька',
  'UA5110007': 'Великодолинська',
  'UA5110009': 'Вигодянська',
  'UA5110011': 'Визирська',
  'UA5110043': 'Яськівська',
  'UA5110041': 'Южненська',
  'UA5112001': 'Ананьївська',
  'UA5112003': 'Балтська',
  'UA5112005': 'Долинська',
  'UA5112009': 'Кодимська',
  'UA5112011': 'Куяльницька',
  'UA5112013': 'Любашівська',
  'UA5112015': 'Окнянська',
  'UA5112017': 'Піщанська',
  'UA5112019': 'Подільська',
  'UA5112021': 'Савранська',
  'UA5112023': 'Слобідська',
  'UA5112007': 'Зеленогірська',
  'UA5114009': 'Лиманська',
  'UA5114011': 'Новоборисівська',
  'UA5114013': 'Роздільнянська',
  'UA5114015': 'Степанівська',
  'UA5114017': 'Цебриківська',
  'UA5114001': 'Великомихайлівська',
  'UA5114003': 'Великоплосківська',
  'UA5114007': 'Захарівська',
  'UA5114005': 'Затишанська',
  'UA5302001': 'Глобинська',
  'UA5302003': 'Горішньоплавнівська',
  'UA5302005': 'Градизька',
  'UA5302007': 'Кам\'янопотоківська',
  'UA5302009': 'Козельщинська',
  'UA5302011': 'Кременчуцька',
  'UA5302013': 'Новогалещинська',
  'UA5302015': 'Оболонська',
  'UA5302017': 'Омельницька',
  'UA5302019': 'Піщанська',
  'UA5302021': 'Пришибська',
  'UA5302023': 'Семенівська',
  'UA5304013': 'Чорнухинська',
  'UA5304001': 'Гребінківська',
  'UA5304011': 'Хорольська',
  'UA5304003': 'Лубенська',
  'UA5304005': 'Новооржицька',
  'UA5304007': 'Оржицька',
  'UA5304009': 'Пирятинська',
  'UA5306001': 'Білоцерківська',
  'UA5306009': 'Гадяцька',
  'UA5306011': 'Гоголівська',
  'UA5306015': 'Комишнянська',
  'UA5306017': 'Краснолуцька',
  'UA5306021': 'Лютенська',
  'UA5306019': 'Лохвицька',
  'UA5306023': 'Миргородська',
  'UA5306025': 'Петрівсько-Роменська',
  'UA5306027': 'Ромоданівська',
  'UA5306029': 'Сенчанська',
  'UA5306031': 'Сергіївська',
  'UA5306033': 'Шишацька',
  'UA5306003': 'Великобагачанська',
  'UA5306005': 'Великобудищанська',
  'UA5306007': 'Великосорочинська',
  'UA5306013': 'Заводська',
  'UA5308001': 'Білицька',
  'UA5308045': 'Чутівська',
  'UA5308007': 'Драбинівська',
  'UA5308005': 'Диканська',
  'UA5308011': 'Карлівська',
  'UA5308013': 'Кобеляцька',
  'UA5308015': 'Коломацька',
  'UA5308017': 'Котелевська',
  'UA5308019': 'Ланнівська',
  'UA5308023': 'Мачухівська',
  'UA5308021': 'Мартинівська',
  'UA5308025': 'Машівська',
  'UA5308027': 'Михайлівська',
  'UA5308029': 'Нехворощанська',
  'UA5308031': 'Новосанжарська',
  'UA5308033': 'Новоселівська',
  'UA5308035': 'Опішнянська',
  'UA5308037': 'Полтавська',
  'UA5308039': 'Решетилівська',
  'UA5308047': 'Щербанівська',
  'UA5308041': 'Скороходівська',
  'UA5308043': 'Терешківська',
  'UA5308003': 'Великорублівська',
  'UA5308009': 'Зіньківська',
  'UA5604001': 'Бокіймівська',
  'UA5604003': 'Боремельська',
  'UA5604009': 'Демидівська',
  'UA5604011': 'Дубенська',
  'UA5604013': 'Козинська',
  'UA5604015': 'Крупецька',
  'UA5604019': 'Млинівська',
  'UA5604017': 'Мирогощанська',
  'UA5604021': 'Острожецька',
  'UA5604023': 'Підлозцівська',
  'UA5604025': 'Повчанська',
  'UA5604027': 'Привільненська',
  'UA5604029': 'Радивилівська',
  'UA5604031': 'Семидубська',
  'UA5604033': 'Смизька',
  'UA5604035': 'Тараканівська',
  'UA5604005': 'Варковицька',
  'UA5604007': 'Вербська',
  'UA5604037': 'Ярославицька',
  'UA5606001': 'Бабинська',
  'UA5606003': 'Березнівська',
  'UA5606005': 'Білокриницька',
  'UA5606007': 'Бугринська',
  'UA5606019': 'Деражненська',
  'UA5606021': 'Дядьковицька',
  'UA5606013': 'Головинська',
  'UA5606015': 'Городоцька',
  'UA5606017': 'Гощанська',
  'UA5606029': 'Клеванська',
  'UA5606031': 'Корецька',
  'UA5606033': 'Корнинська',
  'UA5606035': 'Костопільська',
  'UA5606039': 'Малолюбашанська',
  'UA5606037': 'Малинська',
  'UA5606041': 'Мізоцька',
  'UA5606043': 'Олександрійська',
  'UA5606045': 'Острозька',
  'UA5606047': 'Рівненська',
  'UA5606051': 'Шпанівська',
  'UA5606049': 'Соснівська',
  'UA5606009': 'Великомежиріцька',
  'UA5606011': 'Великоомелянська',
  'UA5606025': 'Здолбунівська',
  'UA5606023': 'Здовбицька',
  'UA5606027': 'Зорянська',
  'UA5608001': 'Березівська',
  'UA5608007': 'Дубровицька',
  'UA5608009': 'Клесівська',
  'UA5608011': 'Миляцька',
  'UA5608013': 'Немовицька',
  'UA5608015': 'Рокитнівська',
  'UA5608017': 'Сарненська',
  'UA5608019': 'Старосільська',
  'UA5608021': 'Степанська',
  'UA5608003': 'Вирівська',
  'UA5608005': 'Висоцька',
  'UA5602001': 'Антонівська',
  'UA5602009': 'Каноницька',
  'UA5602011': 'Локницька',
  'UA5602013': 'Полицька',
  'UA5602015': 'Рафалівська',
  'UA5602003': 'Вараська',
  'UA5602005': 'Володимирецька',
  'UA5602007': 'Зарічненська',
  'UA8500000': 'Севастополь',
  'UA5902001': 'Бочечківська',
  'UA5902003': 'Буринська',
  'UA5902005': 'Дубов\'язівська',
  'UA5902007': 'Конотопська',
  'UA5902009': 'Кролевецька',
  'UA5902011': 'Новослобідська',
  'UA5902013': 'Попівська',
  'UA5902015': 'Путивльська',
  'UA5904001': 'Боромлянська',
  'UA5904015': 'Чернеччинська',
  'UA5904017': 'Чупахівська',
  'UA5904005': 'Грунська',
  'UA5904009': 'Комишанська',
  'UA5904007': 'Кириківська',
  'UA5904011': 'Охтирська',
  'UA5904013': 'Тростянецька',
  'UA5904003': 'Великописарівська',
  'UA5906001': 'Андріяшівська',
  'UA5906015': 'Хмелівська',
  'UA5906005': 'Коровинська',
  'UA5906007': 'Липоводолинська',
  'UA5906009': 'Недригайлівська',
  'UA5906011': 'Роменська',
  'UA5906013': 'Синівська',
  'UA5906003': 'Вільшанська',
  'UA5910001': 'Березівська',
  'UA5910005': 'Дружбівська',
  'UA5910007': 'Есманьська',
  'UA5910003': 'Глухівська',
  'UA5910013': 'Середино-Будська',
  'UA5910015': 'Шалигинська',
  'UA5910017': 'Шосткинська',
  'UA5910011': 'Свеська',
  'UA5910019': 'Ямпільська',
  'UA5910009': 'Зноб-Новгородська',
  'UA5908001': 'Бездрицька',
  'UA5908003': 'Білопільська',
  'UA5908029': 'Хотінська',
  'UA5908009': 'Краснопільська',
  'UA5908011': 'Лебединська',
  'UA5908013': 'Миколаївська',
  'UA5908015': 'Миколаївська',
  'UA5908017': 'Миропільська',
  'UA5908019': 'Нижньосироватська',
  'UA5908021': 'Річківська',
  'UA5908023': 'Садівська',
  'UA5908025': 'Степанівська',
  'UA5908027': 'Сумська',
  'UA5908005': 'Верхньосироватська',
  'UA5908007': 'Ворожбянська',
  'UA5908031': 'Юнаківська',
  'UA6106003': 'Більче-Золотецька',
  'UA6106001': 'Білобожницька',
  'UA6106005': 'Борщівська',
  'UA6106007': 'Бучацька',
  'UA6106043': 'Чортківська',
  'UA6106011': 'Гримайлівська',
  'UA6106013': 'Гусятинська',
  'UA6106021': 'Іване-Пустенська',
  'UA6106041': 'Хоростківська',
  'UA6106023': 'Колиндянська',
  'UA6106025': 'Копичинецька',
  'UA6106027': 'Коропецька',
  'UA6106029': 'Мельнице-Подільська',
  'UA6106031': 'Монастириська',
  'UA6106033': 'Нагірянська',
  'UA6106035': 'Скала-Подільська',
  'UA6106037': 'Товстенська',
  'UA6106039': 'Трибухівська',
  'UA6106009': 'Васильковецька',
  'UA6106017': 'Заліщицька',
  'UA6106015': 'Заводська',
  'UA6106019': 'Золотопотіцька',
  'UA6102001': 'Борсуківська',
  'UA6102007': 'Кременецька',
  'UA6102009': 'Лановецька',
  'UA6102011': 'Лопушненська',
  'UA6102013': 'Почаївська',
  'UA6102015': 'Шумська',
  'UA6102003': 'Великодедеркальська',
  'UA6102005': 'Вишнівецька',
  'UA6104001': 'Байковецька',
  'UA6104003': 'Бережанська',
  'UA6104005': 'Білецька',
  'UA6104021': 'Іванівська',
  'UA6104023': 'Козівська',
  'UA6104025': 'Козлівська',
  'UA6104027': 'Купчинецька',
  'UA6104029': 'Микулинецька',
  'UA6104031': 'Нараївська',
  'UA6104033': 'Озернянська',
  'UA6104037': 'Підгаєцька',
  'UA6104039': 'Підгороднянська',
  'UA6104035': 'Підволочиська',
  'UA6104041': 'Саранчуківська',
  'UA6104043': 'Скалатська',
  'UA6104045': 'Скориківська',
  'UA6104047': 'Теребовлянська',
  'UA6104049': 'Тернопільська',
  'UA6104007': 'Великоберезовицька',
  'UA6104009': 'Великобірківська',
  'UA6104011': 'Великогаївська',
  'UA6104013': 'Залозецька',
  'UA6104015': 'Збаразька',
  'UA6104017': 'Зборівська',
  'UA6104019': 'Золотниківська',
  'UA0504001': 'Бершадська',
  'UA0504027': 'Чечельницька',
  'UA0504005': 'Дашівська',
  'UA0504007': 'Джулинська',
  'UA0504003': 'Гайсинська',
  'UA0504009': 'Краснопільська',
  'UA0504011': 'Кунківська',
  'UA0504013': 'Ладижинська',
  'UA0504015': 'Ободівська',
  'UA0504017': 'Ольгопільська',
  'UA0504019': 'Райгородська',
  'UA0504021': 'Соболівська',
  'UA0504023': 'Теплицька',
  'UA0504025': 'Тростянецька',
  'UA0512001': 'Глуховецька',
  'UA0512005': 'Іванівська',
  'UA0512007': 'Калинівська',
  'UA0512017': 'Хмільницька',
  'UA0512009': 'Козятинська',
  'UA0512011': 'Махнівська',
  'UA0512013': 'Самгородоцька',
  'UA0512015': 'Уланівська',
  'UA0512003': 'Жданівська',
  'UA0508001': 'Бабчинецька',
  'UA0508009': 'Чернівецька',
  'UA0508005': 'Могилів-Подільська',
  'UA0508007': 'Мурованокуриловецька',
  'UA0508003': 'Вендичанська',
  'UA0508011': 'Ямпільська',
  'UA0508013': 'Яришівська',
  'UA0510001': 'Брацлавська',
  'UA0510005': 'Городківська',
  'UA0510007': 'Крижопільська',
  'UA0510009': 'Піщанська',
  'UA0510017': 'Шпиківська',
  'UA0510011': 'Студенянська',
  'UA0510013': 'Томашпільська',
  'UA0510015': 'Тульчинська',
  'UA0510003': 'Вапнярська',
  'UA0502001': 'Агрономічна',
  'UA0502007': 'Гніванська',
  'UA0502009': 'Іллінецька',
  'UA0502013': 'Літинська',
  'UA0502015': 'Лука-Мелешківська',
  'UA0502011': 'Липовецька',
  'UA0502017': 'Немирівська',
  'UA0502019': 'Оратівська',
  'UA0502021': 'Погребищенська',
  'UA0502023': 'Стрижавська',
  'UA0502025': 'Сутисківська',
  'UA0502029': 'Турбівська',
  'UA0502027': 'Тиврівська',
  'UA0502003': 'Вінницька',
  'UA0502005': 'Вороновицька',
  'UA0502031': 'Якушинецька',
  'UA0506001': 'Барська',
  'UA0506003': 'Джуринська',
  'UA0506007': 'Копайгородська',
  'UA0506009': 'Мурафська',
  'UA0506011': 'Северинівська',
  'UA0506015': 'Шаргородська',
  'UA0506013': 'Станіславчицька',
  'UA0506005': 'Жмеринська',
  'UA0704001': 'Камінь-Каширська',
  'UA0704003': 'Любешівська',
  'UA0704005': 'Маневицька',
  'UA0704007': 'Прилісненська',
  'UA0704009': 'Сошичненська',
  'UA0706011': 'Дубечненська',
  'UA0706013': 'Дубівська',
  'UA0706007': 'Голобська',
  'UA0706009': 'Головненська',
  'UA0706021': 'Колодяжненська',
  'UA0706019': 'Ковельська',
  'UA0706025': 'Люблинецька',
  'UA0706027': 'Любомльська',
  'UA0706023': 'Луківська',
  'UA0706029': 'Поворська',
  'UA0706031': 'Ратнівська',
  'UA0706033': 'Рівненська',
  'UA0706035': 'Самарівська',
  'UA0706037': 'Сереховичівська',
  'UA0706045': 'Шацька',
  'UA0706039': 'Смідинська',
  'UA0706041': 'Старовижівська',
  'UA0706043': 'Турійська',
  'UA0706001': 'Велимченська',
  'UA0706003': 'Велицька',
  'UA0706005': 'Вишнівська',
  'UA0706015': 'Заболоттівська',
  'UA0706017': 'Забродівська',
  'UA0708001': 'Берестечківська',
  'UA0708003': 'Боратинська',
  'UA0708009': 'Доросинівська',
  'UA0708005': 'Городищенська',
  'UA0708007': 'Горохівська',
  'UA0708011': 'Ківерцівська',
  'UA0708013': 'Колківська',
  'UA0708015': 'Копачівська',
  'UA0708017': 'Луцька',
  'UA0708019': 'Мар\'янівська',
  'UA0708021': 'Олицька',
  'UA0708023': 'Підгайцівська',
  'UA0708025': 'Рожищенська',
  'UA0708027': 'Торчинська',
  'UA0708029': 'Цуманська',
  'UA0702007': 'Іваничівська',
  'UA0702011': 'Локачинська',
  'UA0702009': 'Литовезька',
  'UA0702013': 'Нововолинська',
  'UA0702015': 'Оваднівська',
  'UA0702017': 'Павлівська',
  'UA0702019': 'Поромівська',
  'UA0702021': 'Устилузька',
  'UA0702001': 'Володимир-Волинська',
  'UA0702003': 'Затурцівська',
  'UA0702005': 'Зимнівська',
  'UA2102001': 'Батівська',
  'UA2102003': 'Берегівська',
  'UA2102013': 'Кам\'янська',
  'UA2102015': 'Королівська',
  'UA2102017': 'Косоньська',
  'UA2102019': 'Пийтерфолвівська',
  'UA2102005': 'Великоберезька',
  'UA2102007': 'Великобийганська',
  'UA2102009': 'Вилоцька',
  'UA2102011': 'Виноградівська',
  'UA2112001': 'Білківська',
  'UA2112007': 'Довжанська',
  'UA2112009': 'Драгівська',
  'UA2112005': 'Горінчівська',
  'UA2112013': 'Іршавська',
  'UA2112015': 'Керецьківська',
  'UA2112025': 'Хустська',
  'UA2112017': 'Колочавська',
  'UA2112019': 'Міжгірська',
  'UA2112021': 'Пилипецька',
  'UA2112023': 'Синевирська',
  'UA2112003': 'Вишківська',
  'UA2112011': 'Зарічанська',
  'UA2104025': 'Чинадіївська',
  'UA2104007': 'Горондівська',
  'UA2104011': 'Івановецька',
  'UA2104013': 'Кольчинська',
  'UA2104015': 'Мукачівська',
  'UA2104017': 'Неліпинська',
  'UA2104019': 'Нижньоворітська',
  'UA2104021': 'Полянська',
  'UA2104023': 'Свалявська',
  'UA2104001': 'Великолучківська',
  'UA2104003': 'Верхньокоропецька',
  'UA2104005': 'Воловецька',
  'UA2104009': 'Жденіївська',
  'UA2106001': 'Богданська',
  'UA2106005': 'Рахівська',
  'UA2106003': 'Великобичківська',
  'UA2106007': 'Ясінянська',
  'UA2108001': 'Бедевлянська',
  'UA2108003': 'Буштинська',
  'UA2108007': 'Дубівська',
  'UA2108009': 'Нересницька',
  'UA2108011': 'Солотвинська',
  'UA2108013': 'Тересвянська',
  'UA2108015': 'Тячівська',
  'UA2108017': 'Углянська',
  'UA2108019': 'Усть-Чорнянська',
  'UA2108005': 'Вільховецька',
  'UA2110001': 'Баранинська',
  'UA2110027': 'Чопська',
  'UA2110007': 'Дубриницько-Малоберезнянська',
  'UA2110025': 'Холмківська',
  'UA2110009': 'Костринська',
  'UA2110011': 'Оноківська',
  'UA2110013': 'Перечинська',
  'UA2110015': 'Середнянська',
  'UA2110019': 'Сюртівська',
  'UA2110017': 'Ставненська',
  'UA2110021': 'Тур\'є-Реметівська',
  'UA2110023': 'Ужгородська',
  'UA2110003': 'Великоберезнянська',
  'UA2110005': 'Великодобронська',
  'UA2302003': 'Андріївська',
  'UA2302001': 'Андрівська',
  'UA2302005': 'Бердянська',
  'UA2302007': 'Берестівська',
  'UA2302015': 'Чернігівська',
  'UA2302009': 'Коларівська',
  'UA2302011': 'Осипенківська',
  'UA2302013': 'Приморська',
  'UA2308029': 'Чкаловська',
  'UA2308005': 'Костянтинівська',
  'UA2308003': 'Кирилівська',
  'UA2308007': 'Мелітопольська',
  'UA2308009': 'Мирненська',
  'UA2308011': 'Новенська',
  'UA2308013': 'Новобогданівська',
  'UA2308017': 'Новоуспенівська',
  'UA2308015': 'Нововасилівська',
  'UA2308019': 'Олександрівська',
  'UA2308021': 'Плодородненська',
  'UA2308023': 'Приазовська',
  'UA2308025': 'Семенівська',
  'UA2308027': 'Терпіннівська',
  'UA2308001': 'Веселівська',
  'UA2308031': 'Якимівська',
  'UA2310001': 'Більмацька',
  'UA2310029': 'Федорівська',
  'UA2310007': 'Гуляйпільська',
  'UA2310009': 'Комиш-Зорянська',
  'UA2310013': 'Малотокмачанська',
  'UA2310011': 'Малинівська',
  'UA2310015': 'Молочанська',
  'UA2310017': 'Оріхівська',
  'UA2310019': 'Пологівська',
  'UA2310021': 'Преображенська',
  'UA2310023': 'Розівська',
  'UA2310025': 'Смирновська',
  'UA2310027': 'Токмацька',
  'UA2310005': 'Воскресенська',
  'UA2310003': 'Воздвижівська',
  'UA2304001': 'Благовіщенська',
  'UA2304009': 'Дніпрорудненська',
  'UA2304011': 'Енергодарська',
  'UA2304013': 'Кам\'янсько-Дніпровська',
  'UA2304015': 'Малобілозерська',
  'UA2304017': 'Михайлівська',
  'UA2304019': 'Роздольська',
  'UA2304021': 'Степногірська',
  'UA2304003': 'Василівська',
  'UA2304005': 'Великобілозерська',
  'UA2304007': 'Водянська',
  'UA2306001': 'Біленьківська',
  'UA2306005': 'Долинська',
  'UA2306009': 'Комишуваська',
  'UA2306011': 'Кушугумська',
  'UA2306013': 'Матвіївська',
  'UA2306015': 'Михайлівська',
  'UA2306017': 'Михайло-Лукашівська',
  'UA2306019': 'Новомиколаївська',
  'UA2306021': 'Новоолександрівська',
  'UA2306023': 'Павлівська',
  'UA2306025': 'Петро-Михайлівська',
  'UA2306033': 'Широківська',
  'UA2306027': 'Степненська',
  'UA2306029': 'Таврійська',
  'UA2306031': 'Тернуватська',
  'UA2306003': 'Вільнянська',
  'UA2306007': 'Запорізька',
  'UA1802001': 'Андрушівська',
  'UA1802003': 'Бердичівська',
  'UA1802017': 'Червоненська',
  'UA1802007': 'Гришковецька',
  'UA1802009': 'Краснопільська',
  'UA1802011': 'Райгородоцька',
  'UA1802013': 'Ружинська',
  'UA1802015': 'Семенівська',
  'UA1802019': 'Швайківська',
  'UA1802005': 'Вчорайшенська',
  'UA1806001': 'Білокоровицька',
  'UA1806025': 'Чоповицька',
  'UA1806003': 'Гладковицька',
  'UA1806005': 'Горщиківська',
  'UA1806007': 'Іршанська',
  'UA1806009': 'Коростенська',
  'UA1806011': 'Лугинська',
  'UA1806013': 'Малинська',
  'UA1806015': 'Народицька',
  'UA1806019': 'Олевська',
  'UA1806017': 'Овруцька',
  'UA1806021': 'Словечанська',
  'UA1806023': 'Ушомирська',
  'UA1804001': 'Андрушківська',
  'UA1804003': 'Березівська',
  'UA1804005': 'Брусилівська',
  'UA1804059': 'Черняхівська',
  'UA1804061': 'Чуднівська',
  'UA1804015': 'Глибочицька',
  'UA1804017': 'Городоцька',
  'UA1804055': 'Харитонівська',
  'UA1804057': 'Хорошівська',
  'UA1804023': 'Корнинська',
  'UA1804025': 'Коростишівська',
  'UA1804027': 'Курненська',
  'UA1804021': 'Квітнева',
  'UA1804029': 'Любарська',
  'UA1804031': 'Миропільська',
  'UA1804033': 'Новоборівська',
  'UA1804035': 'Новогуйвинська',
  'UA1804037': 'Оліївська',
  'UA1804039': 'Попільнянська',
  'UA1804041': 'Потіївська',
  'UA1804043': 'Пулинська',
  'UA1804045': 'Радомишльська',
  'UA1804047': 'Романівська',
  'UA1804049': 'Станишівська',
  'UA1804051': 'Старосілецька',
  'UA1804053': 'Тетерівська',
  'UA1804011': 'Вільшанська',
  'UA1804013': 'Волицька',
  'UA1804009': 'Вишевицька',
  'UA1804007': 'Високівська',
  'UA1804019': 'Житомирська',
  'UA1808001': 'Баранівська',
  'UA1808003': 'Барашівська',
  'UA1808005': 'Брониківська',
  'UA1808021': 'Чижівська',
  'UA1808009': 'Довбиська',
  'UA1808011': 'Дубрівська',
  'UA1808007': 'Городницька',
  'UA1808017': 'Піщівська',
  'UA1808019': 'Стриївська',
  'UA1808023': 'Ярунська',
  'UA1808013': 'Ємільчинська',
  'UA1808015': 'Звягельська',
  'UA0102': 'Бахчисарайський',
  'UA0104': 'Білогірський',
  'UA0106': 'Джанкойський',
  'UA0118': 'Феодосійський',
  'UA0110': 'Керченський',
  'UA0112': 'Красногвардійський',
  'UA0114': 'Красноперекопський',
  'UA0116': 'Сімферопольський',
  'UA0120': 'Ялтинський',
  'UA0108': 'Євпаторійський',
  'UA7108': 'Черкаський',
  'UA7106': 'Уманський',
  'UA7104': 'Золотоніський',
  'UA7102': 'Звенигородський',
  'UA7410': 'Чернігівський',
  'UA7402': 'Корюківський',
  'UA7404': 'Ніжинський',
  'UA7406': 'Новгород-Сіверський',
  'UA7408': 'Прилуцький',
  'UA7306': 'Чернівецький',
  'UA7304': 'Дністровський',
  'UA7302': 'Вижницький',
  'UA1202': 'Дніпровський',
  'UA1204': 'Кам’янський',
  'UA1206': 'Криворізький',
  'UA1208': 'Нікопольський',
  'UA1210': 'Новомосковський',
  'UA1212': 'Павлоградський',
  'UA1214': 'Синельниківський',
  'UA1402': 'Бахмутський',
  'UA1408': 'Донецький',
  'UA1406': 'Горлівський',
  'UA1410': 'Кальміуський',
  'UA1412': 'Краматорський',
  'UA1414': 'Маріупольський',
  'UA1416': 'Покровський',
  'UA1404': 'Волноваський',
  'UA2604': 'Івано-Франківський',
  'UA2606': 'Калуський',
  'UA2608': 'Коломийський',
  'UA2610': 'Косівський',
  'UA2612': 'Надвірнянський',
  'UA2602': 'Верховинський',
  'UA6302': 'Богодухівський',
  'UA6314': 'Чугуївський',
  'UA6304': 'Ізюмський',
  'UA6312': 'Харківський',
  'UA6306': 'Красноградський',
  'UA6308': 'Куп\'янський',
  'UA6310': 'Лозівський',
  'UA6502': 'Бериславський',
  'UA6504': 'Генічеський',
  'UA6506': 'Каховський',
  'UA6510': 'Херсонський',
  'UA6508': 'Скадовський',
  'UA6802': 'Кам\'янець-Подільський',
  'UA6804': 'Хмельницький',
  'UA6806': 'Шепетівський',
  'UA3502': 'Голованівський',
  'UA3504': 'Кропивницький',
  'UA3506': 'Новоукраїнський',
  'UA3508': 'Олександрійський',
  'UA8000': 'Київ',
  'UA3202': 'Білоцерківський',
  'UA3204': 'Бориспільський',
  'UA3206': 'Броварський',
  'UA3208': 'Бучанський',
  'UA3200': 'Чорнобильська зона відчуження',
  'UA3214': 'Фастівський',
  'UA3212': 'Обухівський',
  'UA3210': 'Вишгородський',
  'UA4402': 'Алчевський',
  'UA4404': 'Довжанський',
  'UA4406': 'Луганський',
  'UA4408': 'Ровеньківський',
  'UA4416': 'Щастинський',
  'UA4412': 'Сєвєродонецький',
  'UA4414': 'Старобільський',
  'UA4410': 'Сватівський',
  'UA4612': 'Червоноградський',
  'UA4602': 'Дрогобицький',
  'UA4606': 'Львівський',
  'UA4608': 'Самбірський',
  'UA4610': 'Стрийський',
  'UA4614': 'Яворівський',
  'UA4604': 'Золочівський',
  'UA4802': 'Баштанський',
  'UA4806': 'Миколаївський',
  'UA4808': 'Первомайський',
  'UA4804': 'Вознесенський',
  'UA5102': 'Березівський',
  'UA5104': 'Білгород-Дністровський',
  'UA5106': 'Болградський',
  'UA5108': 'Ізмаїльський',
  'UA5110': 'Одеський',
  'UA5112': 'Подільський',
  'UA5114': 'Роздільнянський',
  'UA5302': 'Кременчуцький',
  'UA5304': 'Лубенський',
  'UA5306': 'Миргородський',
  'UA5308': 'Полтавський',
  'UA5604': 'Дубенський',
  'UA5606': 'Рівненський',
  'UA5608': 'Сарненський',
  'UA5602': 'Вараський',
  'UA8500': 'Севастополь',
  'UA5902': 'Конотопський',
  'UA5904': 'Охтирський',
  'UA5906': 'Роменський',
  'UA5910': 'Шосткинський',
  'UA5908': 'Сумський',
  'UA6106': 'Чортківський',
  'UA6102': 'Кременецький',
  'UA6104': 'Тернопільський',
  'UA0504': 'Гайсинський',
  'UA0512': 'Хмільницький',
  'UA0508': 'Могилів-Подільський',
  'UA0510': 'Тульчинський',
  'UA0502': 'Вінницький',
  'UA0506': 'Жмеринський',
  'UA0704': 'Камінь-Каширський',
  'UA0706': 'Ковельський',
  'UA0708': 'Луцький',
  'UA0702': 'Володимирський',
  'UA2102': 'Берегівський',
  'UA2112': 'Хустський',
  'UA2104': 'Мукачівський',
  'UA2106': 'Рахівський',
  'UA2108': 'Тячівський',
  'UA2110': 'Ужгородський',
  'UA2302': 'Бердянський',
  'UA2308': 'Мелітопольський',
  'UA2310': 'Пологівський',
  'UA2304': 'Василівський',
  'UA2306': 'Запорізький',
  'UA1802': 'Бердичівський',
  'UA1806': 'Коростенський',
  'UA1804': 'Житомирський',
  'UA1808': 'Звягельський',
  'UA01': 'Автономна Республіка Крим',
  'UA71': 'Черкаська',
  'UA74': 'Чернігівська',
  'UA73': 'Чернівецька',
  'UA12': 'Дніпропетровська',
  'UA14': 'Донецька',
  'UA26': 'Івано-Франківська',
  'UA63': 'Харківська',
  'UA65': 'Херсонська',
  'UA68': 'Хмельницька',
  'UA35': 'Кіровоградська',
  'UA80': 'Київ',
  'UA32': 'Київська',
  'UA44': 'Луганська',
  'UA46': 'Львівська',
  'UA48': 'Миколаївська',
  'UA51': 'Одеська',
  'UA53': 'Полтавська',
  'UA56': 'Рівненська',
  'UA85': 'Севастополь',
  'UA59': 'Сумська',
  'UA61': 'Тернопільська',
  'UA05': 'Вінницька',
  'UA07': 'Волинська',
  'UA21': 'Закарпатська',
  'UA23': 'Запорізька',
  'UA18': 'Житомирська',
}

module.exports = uk