// src/eventBus.js
import Vue from 'vue';
export const EventBus = new Vue();

/**
 * Utility to send a snackbar message via EventBus.
 * @param {Object} messageObj - Configuration for the snackbar message.
 * @param {string} messageObj.message - The message to display.
 * @param {string} [messageObj.color="info"] - The color of the snackbar (info, success, warning, error).
 * @param {number} [messageObj.timeout=3000] - How long the snackbar stays visible (in milliseconds).
 * @param {string} [messageObj.group=null] - Group name for grouped snackbar behavior.
 */
EventBus.sendSnackbarMessage = function (messageObj) {
  const defaultConfig = {
    color: "info",
    timeout: 3000,
    group: null,
  };

  // Merge the provided configuration with defaults
  const snackbarConfig = { ...defaultConfig, ...messageObj };

  // Emit the snackbar event
  this.$emit("snackbar:add", snackbarConfig);
};