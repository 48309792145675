<template>
  <div>
    <v-snackbar v-for="(snackbar, index) in snackbars" :key="index" v-model="snackbar.show" :timeout="snackbar.timeout"
      :color="snackbar.color" class="mb-2" :style="{ top: `${calculateTop(index)}px` }">
      {{ snackbar.message }}
      <span v-if="snackbar.elapsedTime != null && snackbar.elapsedTime != undefined">
        ({{ formatElapsedTime(snackbar.elapsedTime) }})
      </span>
      <template v-slot:action>
        <v-btn color="white" text @click="closeSnackbar(index)">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { EventBus } from "../events/eventBus";

// info, warning, error, and success

export default {
  name: "NotificationSnackbar",
  data: () => ({
    snackbars: [], // Array to hold multiple snackbar messages
    defaultSnackbar: {
      message: "Default message",
      show: true,
      timeout: 3000,
      color: "info",
      group: null, // Default group is null (no group)
      startTime: null,
      elapsedTime: 0,
      endTime: null,
    },
  }),
  methods: {
    addSnackbar(config) {
      // const snackbar = {
      //   ...this.defaultSnackbar,
      //   ...config,
      //   startTime: Date.now(),
      //   elapsedTime: 0,
      //   endTime: null,
      //   show: true,
      // };
      const snackbar = {
        message: config.message ?? "Default message",
        show: true,
        timeout: config.timeout ?? 3000,
        color: config.color ?? "info",
        group: config.group ?? null, // Group logic
        startTime: Date.now(),
        elapsedTime: config.elapsedTime ?? null,
        endTime: null,
      };

      console.log('snackbar', snackbar);

      // Ensure group behavior
      if (snackbar.group) {
        // Stop timers for existing snackbars in the same group
        this.snackbars.forEach((existing) => {
          if (existing.group === snackbar.group && !existing.endTime) {
            existing.endTime = Date.now();
          }
        });
      }

      // Add a unique snackbar
      this.snackbars.push({ ...snackbar });

      // Ensure reactivity
      this.snackbars = [...this.snackbars];
    },
    closeSnackbar(index) {
      // this hide the snackbar if i need it for later
      this.snackbars[index].show = false;

      // this removes it which is better for memory management 
      this.snackbars.splice(index, 1);
      // Ensure reactivity
      this.snackbars = [...this.snackbars];
    },
    calculateTop(index, position) {
      console.log(position);

      return -60 * (this.snackbars.length - 1 - index);
      // below code does not work in my case, i need to revisit the calculation
      // const visibleSnackbars = this.snackbars.filter((snack) => snack.show && snack.position === position);
      // const offset = 60; // Vertical offset per snackbar

      // // Calculate position based on the visible index
      // return offset * visibleSnackbars.findIndex((snack) => snack === this.snackbars[index]);
    },
    updateElapsedTime() {
      const currentTime = Date.now();
      this.snackbars.forEach((snackbar, index) => {
        if (!snackbar.endTime && snackbar.elapsedTime != null) {
          snackbar.elapsedTime = Math.floor((currentTime - snackbar.startTime) / 1000); // Elapsed time in seconds
        }

        // Check if the snackbar has timed out
        if (snackbar.timeout > 0 && currentTime - snackbar.startTime >= snackbar.timeout) {
          this.closeSnackbar(index);
        }
      });
      // const currentTime = Date.now();
      // this.snackbars.forEach((snackbar) => {
      //   if (!snackbar.endTime && snackbar.elapsedTime != null) {
      //     snackbar.elapsedTime = Math.floor((currentTime - snackbar.startTime) / 1000); // Elapsed time in seconds
      //   }
      // });
    },
    formatElapsedTime(seconds) {
      if (seconds < 60) return `${seconds}s`;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m ${remainingSeconds}s`;
    },
  },
  mounted() {
    // Listen to snackbar events from the EventBus
    EventBus.$on("snackbar:add", this.addSnackbar);

    // Update the elapsed time every second
    this.interval = setInterval(this.updateElapsedTime, 1000);
  },
  beforeDestroy() {
    EventBus.$off("snackbar:add", this.addSnackbar);
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/* Base Snackbar Styles */
.v-snackbar {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  color: white;
  max-width: 80%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Snackbar Color Variants */
.v-snackbar.info {
  background-color: #2196f3;
  /* Blue for info */
}

.v-snackbar.warning {
  background-color: #ff9800;
  /* Orange for warnings */
}

.v-snackbar.error {
  background-color: #f44336;
  /* Red for errors */
}

.v-snackbar.success {
  background-color: #4caf50;
  /* Green for success */
}

/* Optional Styling */
.v-snackbar span {
  font-weight: bold;
}

.mb-2 {
  margin-bottom: 16px;
}
</style>
