<template>
  <v-app>
    <NotificationSnackbar />
    <!-- 
    <v-snackbar v-model="showAdminSnackbar" :timeout="-1">
      {{ adminMessage }}
      <template v-slot:action>
        <v-btn text color="white" @click="showAdminSnackbar = false">Close</v-btn>
      </template>
</v-snackbar>
-->
    <!-- Your snackbar content -->
    <v-app-bar app dense v-show="shouldShowMenuBar">
      <div class="d-flex align-center" :class="{ 'ml-2': $vuetify.breakpoint.mobile }">
        <v-img @click="$router.push('/')" alt="Shelter Cluster Logo"
          class="shrink mr-2 cursor-pointer hidden-sm-and-down" contain src="/img/GSC-Logo-FINAL.png" width="160" />
        <v-img @click="$router.push('/')" alt="Shelter Cluster Logo" class="shrink mr-2 cursor-pointer hidden-md-and-up"
          contain src="/img/GSC-Logo-FINAL.png" width="38" />
        <div @click="$router.push('/')" class="headline pl-2 ml-2 cursor-pointer"
          style="border-left: 1px solid #7f1416; color: #7f1416;">SIDAR</div>
        <div v-show="trainingMode !== 'no'" class="headline pl-2 ml-2 cursor-pointer"
          style="border-left: 1px solid #7f1416; color: #7f1416;">{{ $t('Training mode') }} </div>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="username" text v-bind="attrs" color="#7f1416" v-on="on">
            <span class="mr-4 hidden-sm-and-down">{{ $i18n.locale === 'en' ? user.name : user.nameUkr ?? user.name
              }}</span>
            <v-avatar size="30px" color="grey lighten-3">
              <img :src="userimage" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$router.push('/user')">
            <v-list-item-title>{{ $t('Edit user') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon depressed size="x-large" color="#7f1416" @click="$router.push('/guide')">
        <v-icon>mdi-help</v-icon>
      </v-btn>
      <v-btn icon depressed class="mr-4" size="x-large" color="#7f1416" @click="$router.push('/updates')">
        <v-icon>mdi-newspaper</v-icon>
      </v-btn>
      <v-btn color="red darken-4" outlined depressed :class="{ 'mr-2': $vuetify.breakpoint.mobile }"
        @click="$i18n.locale = $i18n.locale === 'en' ? 'uk' : 'en'">
        {{ $i18n.locale }}
      </v-btn>
    </v-app-bar>
    <v-breadcrumbs :items="breadcrumbs" style="margin-top: 48px;" v-show="username">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :disabled="item.disabled" :to="item.href">
        {{ $t(item.text) }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-system-bar v-show="!$network.online" height="30" class="red pl-6 white--text">{{ $t('Offline mode')
      }}</v-system-bar>
    <v-main class="main-container"
      :style="{ 'padding-top': username ? '12px' : '60px', 'background-image': username ? '' : `url('login.jpg')` }"
      style="background: #f5f5f5">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="bg-indigo-lighten-1 text-center d-flex flex-column">
      <div class="pt-0">
        SIDAR {{ this.version }}
      </div>
    </v-footer>
  </v-app>
</template>

<style>
@font-face {
  font-family: "ProximaNova Regular";
  src: url("~@/assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova Light";
  src: url("~@/assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova Semibold";
  src: url("~@/assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova Bold";
  src: url("~@/assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova Extrabold";
  src: url("~@/assets/fonts/ProximaNova-Extrabld.woff");
}

@font-face {
  font-family: "ProximaNova Black";
  src: url("~@/assets/fonts/ProximaNova-Black.woff");
}

* {
  font-family: "ProximaNova Regular" !important;
}

.v-breadcrumbs a {
  color: #7f1416 !important;
}

.v-breadcrumbs a.v-breadcrumbs__item.v-breadcrumbs__item--disabled {
  color: #999 !important;
}

.main-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #EFF3F8;
}

.cursor-pointer {
  cursor: pointer;
}

/* Mobile layout */

.v-data-table__mobile-row {
  flex-direction: column;
  text-align: left !important;
  align-items: baseline !important;
  padding: 0 !important;
}

.v-data-table__mobile-table-row {
  margin: 0em 0px 1.5em;
  padding-bottom: 1em;
  border-bottom: 2px solid #CCC;
}

.v-data-table__mobile-row__header {
  margin-bottom: 0.4em;
  margin-top: 1em;
}

.v-data-table__mobile-row li {
  text-align: left;
}

.v-data-table__mobile-row__cell {
  width: 100%;
  text-align: left !important;
}

.v-data-table-header.v-data-table-header-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {

  .v-toolbar__content {
    padding: 0 !important;
  }

  .v-data-footer {
    margin: 0px -20px;
  }

  .v-data-footer__select {
    display: none !important;
  }

  .v-data-footer {
    padding-top: 12px !important;
    justify-content: center;
  }

}
</style>

<script>
import { mapGetters } from 'vuex'
import NotificationSnackbar from './components/NotificationSnackbar.vue';

// Library to handle the socket communication (for import purposes)
// import { io } from 'socket.io-client'
// const SOCKET_URL = process.env.VUE_APP_STATIC ? process.env.VUE_APP_STATIC : 'http://localhost:3001'

export default {
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [
      { text: this.$t('Home'), disabled: true, href: '/' }
    ])
    this.$i18n.locale = localStorage.getItem('sidar-lang') || 'en'
  },
  name: 'App',
  components: {
    NotificationSnackbar
  },
  computed: {
    ...mapGetters([
      'user',
      'username',
      'userimage',
      'breadcrumbs'
    ]),
    trainingMode() {
      return process.env.VUE_APP_DEVELOPMENT_MODE
    },
    version() {
      return process.env.VUE_APP_VERSION
    },

    // This uses Vuex's getter for the user object
    user() {
      return this.$store.getters.user;
    },

    // This checks if the current page is the login page
    isLoginPage() {
      return this.$route.path === '/login';
    },

    // This computed property combines both conditions
    shouldShowMenuBar() {
      // Show the menu only if the user is logged in and it's not the login page
      return this.user && !this.isLoginPage;
    }
  },
  data: () => ({
    value: false,
    links: [
      { icon: 'mdi-package-variant', text: 'Activity', path: 'activity' },
      { icon: 'mdi-database-edit-outline', text: 'Data', path: 'data' },
      { icon: 'mdi-chart-box-outline', text: 'Reporting', path: 'reports' },
    ],
    avatar: false,
    // showAdminSnackbar: false,
    // adminMessage: '',
    // snackbars: [] // Array to hold multiple snackbar messages
  }),
  mounted() {
    console.log('app mounted');

    // // Initialize socket connection
    // this.socket = io.connect(SOCKET_URL, {
    //   reconnectionDelayMax: 10000,
    //   withCredentials: true,
    //   auth: { token: this.$auth.accessToken }
    // });

    // // Set up admin message listener
    // this.socket.on('adminMessage', (payload) => {
    //   console.log('Admin message received from adminMessage:', payload);
    //   if (payload.key === 'adminMessage') {
    //     this.addSnackbar(payload);
    //   }
    //   this.addSnackbar(payload);
    // });

    // this.socket.on('message', ({ key, value }) => {
    //   console.log('Admin message received from message:', value);
    //   if (key === 'adminMessage') {
    //     this.addSnackbar(value);
    //   }
    // });
  },
  methods: {
    // addSnackbar(message) {
    //   console.log('addSnackbar called');

    //   this.snackbars.push({ message, show: true });
    //   // Ensure reactivity
    //   this.snackbars = [...this.snackbars];
    // },
    // closeSnackbar(index) {
    //   this.snackbars[index].show = false;
    // },
    async logout() {
      try {
        await this.$axios('/auth/logout');
      } catch (error) {
        console.log('remote logout failed');
        console.log(error?.response?.message);
      }
      console.log('logging out');
      this.$store.dispatch('setUser', {})
      this.$broadcastToken(null)
      this.$router.push('/login')
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      localStorage.setItem('sidar-lang', val)
    }
  }
};
</script>
